<div *ngIf="reloaded&&!orientationError">
  <div *ngIf="canAccessDirectory">
    <!-- all folders except for unsorted folder    -->
    <app-folder-group *ngFor="let folderGroup of folderGroups" [hidden]="folderGroup.isLoanApp" (eventEmitted)="onEvent($event)" [folderGroup]="folderGroup" [tran]="tran">
    </app-folder-group>
  </div>

  <div class="mt-1 mb-1">
    <app-view-folder (eventEmitted)="onEvent($event)" [folder]="unsortedFolder" [tran]="tran">
    </app-view-folder>
  </div>
</div>
