<mat-card *ngIf="reloaded">
  <mat-card-title>{{title}}</mat-card-title>

  <mat-card-content>
    <div class='p-form' name="form" ngNoForm>
      <div class="row mt-4">
        <div class="col">
          <input *ngIf="multipleFiles" type="file" multiple (change)="onChange($event)" class="w-100">
          <input *ngIf="!multipleFiles" type="file" (change)="onChange($event)" class="w-100">
        </div>
      </div>

      <form [formGroup]="form" *ngIf="isDescription" class="mt-3" autocomplete="off">
        <mat-form-field class="w-100">
          <input type="text" formControlName="description" matInput placeholder="File description" data-lpignore="true" autocomplete="off" required maxlength="128">
          <mat-error *ngIf="description.hasError('required')">
            Description is required
          </mat-error>
          <mat-error *ngIf="description.hasError('pattern')">
            Description is invalid
          </mat-error>
        </mat-form-field>
      </form>

      <div [hidden]="!isUploading || error" class="row upload spinner mt-4">
        <div class="col">
          <img src='/api/images/spinner_white.gif'>
        </div>
      </div>

      <div class="mt-2" *ngIf="getFiles().length">
        <div class="fw-bold">Files to upload:</div>
        <div *ngFor="let file of getFiles()">{{file}}</div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button (click)="onSubmit()" [disabled]="!canUpload" class="btn btn-lg btn-primary" type="submit">Upload</button>
          <button [hidden]="disableCancel===true" (click)="cancelUpload()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
