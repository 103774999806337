import {ModelBase} from './model-base';

export class CC extends ModelBase {
  invoice_id: number;
  amount: string;
  cc_number: string;
  cc_exp_month: string;
  cc_exp_year: string;
  cc_code: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }
}
