import {Component, Injector, OnInit} from '@angular/core';
import {Address} from '../../../../models/address';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-addresses',
  templateUrl: './view-segment-addresses.component.html',
})
export class ViewSegmentAddressesComponent extends CompBaseComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'addresses';
    this.section = this.applicant;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Address.deserializeArray(this.loan_app[this.applicant].addresses);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
