import {ModelBase} from './model-base';

export class CompEvent extends ModelBase {
  id: number;
  code: string;
  level: number;
  description: string;
  due_after: string; // date in string format mm/dd/yyy
  due_by: string; // date in string format mm/dd/yyy
  expires_on: string; // date in string format mm/dd/yyy
  occurred_on: string; // date in string format mm/dd/yyy
  is_comp: boolean;

  constructor(object: any) {
    super();
    if (!object) {
      return;
    }
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }

    if (this.code === 'lock') {
      this.due_by = this.expires_on;
    }
  }

  static deserializeArray(arr: Array<any>): Array<CompEvent> {
    const result = [];
    for (const obj of arr) {
      const newCompEvent = new CompEvent(obj);
      result.push(newCompEvent);
    }

    return result;
  }

  static getKeyEvents(report: any): any {
    let level0Count = 0, level1Count = 0, level2Count = 0, level3Count = 0, level4Count = 0, level5Count = 0;
    const compEvents = CompEvent.deserializeArray(Object.values(report));

    Object.values(compEvents).forEach((event) => {
      switch (event['level']) {
        case 0:
          level0Count++;
          break;
        case 1:
          level1Count++;
          break;
        case 2:
          level2Count++;
          break;
        case 3:
          level3Count++;
          break;
        case 4:
          level4Count++;
          break;
        case 5:
          level5Count++;
          break;
      }
    });

    const result = {};
    result['levelCounts'] = [level0Count, level1Count, level2Count, level3Count, level4Count, level5Count];
    result['startDate'] = report && report['start'] ? report['start']['occurred_on'] : null;
    result['creditAuthDate'] = report && report['credit_auth'] ? report['credit_auth']['occurred_on'] : null;
    result['loanAppDate'] = report && report['form_1003'] ? report['form_1003']['occurred_on'] : null;
    result['leDate'] = report && report['loan_estimate'] ? report['loan_estimate']['occurred_on'] : null;
    result['cdDate'] = report && report['closing_disclosure'] ? report['closing_disclosure']['occurred_on'] : null;
    result['lockDate'] = report && report['lock'] ? report['lock']['expires_on'] : null;
    result['isStarted'] = compEvents.length > 0 && !!compEvents[0]['occurred_on'];

    return result;
  }

  toServerPayload(): any {
    return {};
  }
}

