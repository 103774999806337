import {Component, Injector, OnInit} from '@angular/core';
import {Document} from '../../../../../models/document';
import {CompBaseComponent} from '../../../../comp-base.component';

@Component({
  selector: 'app-view-credit-reports',
  templateUrl: './view-credit-reports.component.html',
})
export class ViewCreditReportsComponent extends CompBaseComponent implements OnInit {
  public creditDocs: Array<Document>;
  public loanAppDoc: Document;
  public isCreditDocs: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    if (event.type === 'ChangeMode') {
      this.mode = event.mode;
      return;
    }
  }

  // comment: getting the loan application document
  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    const tran = this.tranService.getCurrentTranSync();
    const docs = tran['documents'].filter((elt) => {
      return elt.dtype === 256;
    }).map((elt) => new Document(elt));
    // reverse sort created_at so that most recent is higher
    this.creditDocs = docs.sort((x, y) => x['created_at'] === y['created_at'] ? 0 : (x['created_at'] < y['created_at'] ? 1 : -1));
    this.isCreditDocs = this.creditDocs.length > 0;
    this.loanAppService.getCurrentLoanDocument()
      .then((doc: Document) => {
        this.loanAppDoc = doc;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      })
      .catch((data) => {
        console.log('FAILED'); // todo
        this.reloaded = true;
      });
  }
}
