import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {EmailService} from '../../../services/email.service';
import {FormUtil} from '../../form-util';
import {CommComponent} from "../comm.component";
import {Tran} from "../../../models/tran";

@Component({
  selector: 'app-email',
  template: '',
})
export class EmailComponent extends CommComponent implements OnInit {
  public currentTran: Tran;
  // controls for send an invite email to a new user
  public firstName: FormControl;
  public lastName: FormControl;
  public email: FormControl;
  public context: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.currentTran = this.tranService.getCurrentTranSync();
    this.isContacts = this.contacts && this.contacts.length > 0;
    this.emailService = this.injector.get(EmailService);
  }

  createFormControls(): void {
    this.contactArray = new FormArray([]);
    this.contacts.forEach(() => this.contactArray.push(new FormControl()));
  }

  createForm(): void {
    this.form = new FormGroup({
      contacts: this.contactArray
    });
  }

  canSend(): boolean {
    return this.contactArray.controls.some((elt, index) => {
      return elt.value;
    });
  }

  async sendOrientationEmail(): Promise<void> {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const notifyEmails = [];
    this.contactArray.controls.forEach((control, index) => {
      if (control.value) {
        notifyEmails.push(this.contacts[index]);
      }
    });

    const emails: Array<Promise<any>> = [];
    notifyEmails.forEach((contact) => {
      const payload = {
        contact_id: contact.id,
        email_code: 'new_transaction',
        tran_id: this.tran ? this.tran.id : null
      };
      emails.push(this.emailService.sendEmail(payload));
    });

    try {
      await Promise.all(emails);
      FormUtil.genSuccessMessage(this.sharedService, 'Email successfully sent');
      this.eventEmitted.emit({type: 'Reload'});
    } catch (data) {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to send email', data);
    }
  }

  sendUserRegistrationEmail(): Promise<void> {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const tran = this.tranService.getCurrentTranSync();
    const tran_id = tran ? tran.id : null;
    const payload = {
      first: this.firstName.value,
      last: this.lastName.value,
      email_code: 'user_registration_request',
      tran_id: tran_id,
      email: this.email.value
    };
    this.emailService.sendEmail(payload)
      .then(() => {
        const message = `The Invite email was successfully queued for sending. You should get an email notification after they have created their account`;
        FormUtil.genSuccessMessage(this.sharedService, message, false);
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to send Invite User email', data);
      });
  }
}
