<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Timeline</mat-card-title>

  <mat-card-content>
    <p>
      The timeline feature displays completed and outstanding workflow tasks.
      You can zoom in and out, move it forward and backward in time, using the arrow keys or by dragging it.
    </p>

    <strong>Completed Workflow Tasks</strong>

    <p>
      Completed workflow tasks are displayed with
      <span class="fw-bold">black</span> headers.
      Their start and end dates should correspond to the timestamps when the loan officer performed the tasks.
    </p>

    <strong>Uncompleted Workflow Tasks</strong>
    <p>
      Uncompleted workflow tasks are displayed with
      <span class="fw-bold text-danger">red</span> headers.
      When a loan is created, it is pre-configured with boiler-plate task completion dates.
      The loan officer can modify the projected dates according to the loan circumstances.
    </p>
  </mat-card-content>
</mat-card>
