/*
 The timeout implementation is taken from
 https://hackedbychinese.github.io/ng2-idle/
 We have not yet implemented keepalive functions
*/
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {SharedService} from '../layouts/shared-service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';

@Injectable()
export class TimeoutService {
  // timeout related properties
  public static IDLE_SECONDS = 30;
  // timeout after 120 minutes
  public static TIMEOUT_SECONDS = 7200;
  public static TIMEOUT_WARNING = 300;

  // idle related properties
  public idleState = 'Not started.';
  public timedOut = false;

  constructor(private idle: Idle,
              public userService: UserService,
              public sharedService: SharedService
  ) {
  }

  // The only purpose of this method is to ensure that the service is loaded
  init(): void {
  }

  startIdleWatch(): void {
    // sets an idle timeout of IDLE_SECONDS seconds, for testing purposes.
    this.idle.setIdle(TimeoutService.IDLE_SECONDS);

    // sets a timeout period of TIMEOUT_SECONDS seconds. after TIMEOUT_SECONDS seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(TimeoutService.TIMEOUT_SECONDS);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
    });

    this.idle.onTimeout.subscribe(() => {
      console.log('you have timeout => 10 seconds');
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.timeoutSession();
      this.stopIdleWatch();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
        if (countdown <= TimeoutService.TIMEOUT_WARNING) {
          console.log('You will time out in ' + countdown + ' seconds!');
        }
      }
    );

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stopIdleWatch(): void {
    this.idle.stop();
    this.idleState = 'Stopped.';
    this.timedOut = false;
  }

  timeoutSession() {
    this.userService.logoutSession()
      .then(() => {
        window.location.href = '';
      });
  }
}
