import {Component, Injector, OnInit} from '@angular/core';
import {Party} from '../../../../models/party';
import {PartyComponent} from '../party/party.component';

@Component({
  selector: 'app-add-party',
  templateUrl: '../party/edit-segment-party.component.html',
})
export class AddPartyComponent extends PartyComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Party({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
