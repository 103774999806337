<mat-card *ngIf="mode=='View'" class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row mb-2">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1">
      <div class="fw-bold col-lg-6">Party</div>
      <div class="col-lg-6">{{item.getRole()}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1">
      <div class="fw-bold col-lg-6">Full name</div>
      <div class="col-lg-6">{{item.full_name}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1">
      <div class="fw-bold col-lg-6">Party vesting</div>
      <div class="col-lg-6">{{item.getVestingType()}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1" *ngIf="item.vesting_type==='Other'">
      <div class="fw-bold col-lg-6">Explain other vesting</div>
      <div class="col-lg-6">{{item.other_description}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1" *ngIf="item.role==='entity'">
      <div class="fw-bold col-lg-6">Trust type</div>
      <div class="col-lg-6">{{item.getTrustType()}}</div>
    </div>

  </mat-card-content>
</mat-card>
