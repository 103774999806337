import {Component, Injector, OnInit} from '@angular/core';
import {CalcComponent} from '../calc/calc.component';
import {LoanCalc} from '../../../models/loan-calc';

@Component({
  selector: 'app-view-calc-rate',
  templateUrl: './view-calc-rate.component.html',
})
export class ViewCalcRateComponent extends CalcComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loan = new LoanCalc({});
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.loan) {
      this.loan = event.loan;
    }
    super.onEvent(event);
  }
}
