import {Component, Injector, OnInit} from '@angular/core';
import {LoMcrComponent} from '../lo-mcr/lo-mcr.component';
import {FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-view-segment-lo-mcr',
  templateUrl: './view-segment-lo-mcr.component.html',
})
export class ViewSegmentLoMcrComponent extends LoMcrComponent implements OnInit {
  public mcrTitle: string;
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createForm();
    this.mcrTitle = `Loan Officer MCR for Q${this.mcr.quarter} ${this.mcr.year}`;
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  populateMcr(): void {
    this.mcrService.populateMcr(this.mcr.id)
      .then((mcr) => {
        if (mcr.content['trans'].length > 0) {
          FormUtil.genSuccessMessage(this.sharedService, 'MCR successfully populated with relevant loans');
        } else {
          FormUtil.genWarningMessage(this.sharedService, 'No loans found for this MCR');
        }
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to populate MCR with loans', data);
      });
  }

  back(): void {
    this.router.navigate(['/loans/lo-mcr/list']);
  }
}
