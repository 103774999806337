<mat-card *ngIf="reloaded" class="mt-1">
  <mat-card-title>{{op == 'Add' ? 'Create User' : 'Edit User'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="prefix" matInput placeholder="Prefix">
            <mat-error *ngIf="prefix.hasError('required')">
              Prefix is required
            </mat-error>
            <mat-error *ngIf="prefix.hasError('minlength')">
              Prefix is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="first" matInput placeholder="First Name">
            <mat-error *ngIf="first.hasError('required')">
              First name is required
            </mat-error>
            <mat-error *ngIf="first.hasError('minlength')">
              First name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="middle" matInput placeholder="Middle Name">
            <mat-error *ngIf="middle.hasError('required')">
              Middle name is required
            </mat-error>
            <mat-error *ngIf="middle.hasError('minlength')">
              Middle name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="last" matInput placeholder="Last Name">
            <mat-error *ngIf="last.hasError('required')">
              Last name is required
            </mat-error>
            <mat-error *ngIf="last.hasError('minlength')">
              Last name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="suffix" matInput placeholder="Suffix">
            <mat-error *ngIf="suffix.hasError('required')">
              Suffix is required
            </mat-error>
            <mat-error *ngIf="suffix.hasError('minlength')">
              Suffix is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="email" matInput placeholder="Email">
            <mat-error *ngIf="email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="email.hasError('email')">
              Email is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input appPhoneOnly formControlName="cell_phone" matInput placeholder="Phone" maxlength="{{MAX_LEN.PHONE}}">
            <mat-hint>format: xxx-xxx-xxxx</mat-hint>
            <mat-error *ngIf="cell_phone.hasError('required')">
              Phone is required
            </mat-error>
            <mat-error *ngIf="cell_phone.hasError('pattern')">
              Phone is invalid
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="mobile_carrier_name" placeholder="Select carrier">
              <mat-option *ngFor="let car of carriers" [value]="car.key">{{car.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mobile_carrier_name.hasError('required')">
              Mobile carrier name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="password" matInput placeholder="Password">
            <mat-error *ngIf="password.hasError('required')">
              Password is required
            </mat-error>
            <mat-error *ngIf="password.hasError('pattern')">
              Password is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h5>User Role(s)</h5>
        </div>
        <div class="col-12">
          <mat-checkbox formControlName="adminRole">Admin</mat-checkbox>
          <mat-checkbox formControlName="presenterRole">Presenter</mat-checkbox>
          <mat-checkbox formControlName="borrowerRole">Borrower</mat-checkbox>
          <mat-checkbox formControlName="loanOfficerRole">Loan officer</mat-checkbox>
          <mat-checkbox formControlName="agentRole">Agent</mat-checkbox>
          <mat-checkbox formControlName="auxRole">Auxiliary</mat-checkbox>
          <mat-checkbox formControlName="processorRole">Processor</mat-checkbox>
          <mat-checkbox formControlName="reAgentRole">Real Estate Agent</mat-checkbox>
          <mat-checkbox formControlName="otherRole">Other</mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h5>User Service(s)</h5>
        </div>
        <div class="col-12">
          <mat-checkbox formControlName="borrowerService">Borrower</mat-checkbox>
          <mat-checkbox formControlName="sauService">ezUploads</mat-checkbox>
          <mat-checkbox formControlName="mcrUserService">MCR Wizard</mat-checkbox>
          <mat-checkbox formControlName="loanService">POLOS</mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h5>UID Status</h5>
        </div>
        <div class="col-12">
          <mat-checkbox formControlName="status">Active</mat-checkbox>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="add()" *ngIf="op=='Add'" class="btn btn-lg btn-primary">Create</button>
          <button (click)="update()" *ngIf="op=='Edit'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="reload()" class="ms-2 btn btn-lg btn-light">Reset</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
