import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {Organization} from '../../../../models/organization';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {OrganizationService} from '../../../../services/organization.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-edit-segment-user-org',
  templateUrl: './edit-segment-user-org.component.html',
})
export class EditSegmentUserOrgComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Organization;
  @Input() public op;
  public orgService: OrganizationService;
  public router: Router;

  // form and form controls
  form: FormGroup;
  name: FormControl;
  description: FormControl;
  nmls: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.orgService = this.injector.get(OrganizationService);
    // this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.op === 'Add') {
      const user = this.userService.getUserSync();
      const item = new Organization({nmls: user.user_configuration.company_nmls});
      this.createFormControls(item);
    } else {
      this.createFormControls(this.item);
    }
    this.createForm();
  }

  createFormControls(item: Organization) {
    this.name = new FormControl(item.name, AppValidators.present);
    this.description = new FormControl(item.description);
    this.nmls = new FormControl(item.nmls);
    this.nmls.disable();
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
      nmls: this.nmls,
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    this.orgService.updateOrganization(this.item.id, payload)
      .then(() => {
        return this.userService.getDefaultOrganization(true);
      })
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update organization', data);
      });
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    payload['nmls'] = this.nmls.value; // nmls value is not part of the form value because it was disabled
    this.orgService.createOrganization(payload)
      .then(() => {
        return this.userService.refreshUser();
      })
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization successfully created');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create organization', data);
        this.eventEmitted.emit({type: 'Reload'});
      });
  }
}
