export const MAX_LEN = {
  EMAIL: 320,
  VL_DESC: 512,
  L_DESC: 256,
  DESC: 128,
  S_DESC: 64,
  XS_DESC: 32, // IS IT USED?
  POLOS_PW: 32, // IS IT USED?
  FULL_ADDR: 256,
  DOC_NUM: 16,
  GEN_ID: 16,
  PHONE: 14,
  PHONE_EX: 6,
  URL: 512,
  NAME_PART: 35,
  ADDR_PART: 35,
  GEN_NAME: 70,
  CURR: 15,
  ZIP: 5,
  ZIP_FOUR: 4,
  NMLS_ID: 12,
  STATE_ABBR: 2,
  COUNTRY_CODE: 2,
  STATE_ABBRS: 150,
  CONTACT_MESSAGE: 1024,
  MONEY: 15,
  DATE: 10
}
