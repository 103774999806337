<div *ngIf="reloaded && userOrg">
  <mat-card *ngIf="mode==='View' || mode==='Add' ">
    <mat-card-title>

      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'Organization Users'" [help]="true" [op]="'add'">
        <div>
          <b>Types of Loan Officers</b>
        </div>
        There are two types of organization loan officers that you can manage.
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            The first type is an existing loan officer with a POLOS account.
            <b>An existing loan officer can log in to POLOS.</b>
          </li>
          <li>
            The other type is an auxiliary loan officer that you create only for the purpose of generating the Mortgage Call Report (MCR).
            <b>An auxiliary loan officer cannot log in to POLOS.</b>
          </li>
        </ul>
        <b>Managing Existing Loan Officers</b>
        You can add loan officers to your organization if
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            they have the same company NMLS ID as your organization's, and
          </li>
          <li>
            they are not a member of another organization (this is a rare situation).
          </li>
        </ul>
        <b>Managing Unregistered Loan Officers</b>
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            You can add auxiliary loan officers to your organization for the
            <b>sole purpose of generating your organization MCR</b>.
          </li>
          <li>
            When you add/edit an auxiliary loan officer account, add/edit the state(s) where they
            and your company are licensed to operate.
          </li>
        </ul>
      </app-segment-header>

    </mat-card-title>
    <mat-card-content>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Org Role</th>
            <th>System Role</th>
            <th>Name</th>
            <th>NMLS</th>
            <th>Email</th>
            <th>States Licensed</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of userOrg.users" class="hand-pointer" (click)="editUser($event,user)" title="Click to edit">
            <td>{{user.getOrgRoleString()}}</td>
            <td>{{user.getRolesString()}}</td>
            <td>{{user.full_name}}</td>
            <td>{{user.nmls}}</td>
            <td>{{user.email}}</td>
            <td>
              <div>
                {{user.getStates()}}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

  <app-add-segment-org-user *ngIf="mode==='Add'" (eventEmitted)="onEvent($event)" [item]="userOrg"></app-add-segment-org-user>

  <app-edit-segment-org-user *ngIf="mode==='EditUser'" (eventEmitted)="onEvent($event)" [user]="user" [item]="userOrg"></app-edit-segment-org-user>
</div>
