import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {SiteComponent} from '../site.component';
import {DeviceService} from '../../../services/device.service';
import {globals} from "../../../shared/globals/globals";

@Component({
  selector: 'app-index',
  templateUrl: './shop.component.html',
  styleUrls: ['../site.scss']
})
export class ShopComponent extends SiteComponent implements OnInit, OnDestroy {
  public isMobile: boolean;
  authServerCreateAccountBorrower: string;
  authServerCreateAccountAncillary: string;
  authServerCreateAccountEZMCR: string;
  authServerCreateAccountPOLOS: string;
  authServerCreateAccountProcessor: string;
  authServerCreateAccountAssistant: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authServerCreateAccountBorrower = `${globals.getAuthServer()}/auth/create_account/borrower`;
    this.authServerCreateAccountAncillary = `${globals.getAuthServer()}/auth/create_account/ancillary`;
    this.authServerCreateAccountEZMCR = `${globals.getAuthServer()}/auth/create_account/mcr`;
    this.authServerCreateAccountPOLOS = `${globals.getAuthServer()}/auth/create_account/polos`;
    this.authServerCreateAccountProcessor = `${globals.getAuthServer()}/auth/create_account/processor`;
    this.authServerCreateAccountAssistant = `${globals.getAuthServer()}/auth/create_account/assistant`;
    this.isMobile = DeviceService.isMobile;
    this.pageTitle = 'PrudentCO';
    super.ngOnInit();
    this.reload();
  }

  ngOnDestroy(): void {
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.homeHighlights
      });
    }, 0);
  }
}
