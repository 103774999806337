<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Aliases'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>List other names that you may have used in the past to receive credit.</li>
          <li>You can edit or delete aliases.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-alias (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-alias>

    <div *ngFor="let item of items">
      <app-view-alias (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-alias>
    </div>
  </mat-card>
</div>
