<mat-card class="mt-2 mb-1">
  <mat-card-title>Transaction Details&nbsp;
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" class="pb-2">
      <div *ngIf="isPurchaseLoan" class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Purchase price</mat-label>
            <input appDecimalOnly formControlName="purchase_price" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Purchase price" type="number" required>
            <mat-error *ngIf="purchase_price.hasError('pattern')">
              Purchase price is invalid
            </mat-error>
            <mat-error *ngIf="purchase_price.hasError('required')">
              Purchase price is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Repairs</mat-label>
            <input appDecimalOnly formControlName="repairs" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Repairs" type="text" appAutoFocus>
            <mat-error *ngIf="repairs.hasError('pattern')">
              Repairs is invalid
            </mat-error>
            <mat-error *ngIf="repairs.hasError('required')">
              Repairs is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isRefinanceLoan">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Subject property payoff</mat-label>
              <input appDecimalOnly formControlName="refinance" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Subject property payoff" type="text" required>
              <mat-error *ngIf="refinance.hasError('pattern')">
                Subject property payoff is invalid
              </mat-error>
              <mat-error *ngIf="refinance.hasError('required')">
                Subject property payoff is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Non-property debts payoff</mat-label>
              <input appDecimalOnly formControlName="non_property_debt_payoff" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Non-property debts payoff" type="text">
              <mat-error *ngIf="non_property_debt_payoff.hasError('pattern')">
                Non-property debts payoff is invalid
              </mat-error>
              <mat-error *ngIf="non_property_debt_payoff.hasError('required')">
                Non-property debts payoff is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Excessive financing concessions</mat-label>
              <input appDecimalOnly formControlName="excessive_financing" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Excessive financing concessions" type="text">
              <mat-error *ngIf="excessive_financing.hasError('pattern')">
                Excessive financing concessions is invalid
              </mat-error>
              <mat-error *ngIf="excessive_financing.hasError('required')">
                Excessive financing concessions is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-radio-group aria-label="Cash from/to borrower" formControlName="from_borrower">
            <mat-radio-button [checked]="from_borrower.value===true" [value]="true">Cash from borrower</mat-radio-button>
            <mat-radio-button [checked]="from_borrower.value===false" [value]="false">Cash to borrower</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>{{getCashFromToBorrowerString()}}</mat-label>
            <input appDecimalOnly formControlName="cash_from_to_borrower" matInput maxlength="{{MAX_LEN.CURR}}"
              placeholder={{getCashFromToBorrowerString()}} type="text" [required]="from_borrower.value!==null">
            <mat-error *ngIf="cash_from_to_borrower.hasError('pattern')">
              {{getCashFromToBorrowerString()}} is invalid
            </mat-error>
            <mat-error *ngIf="cash_from_to_borrower.hasError('required')">
              {{getCashFromToBorrowerString()}} is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Estimated prepaid items</mat-label>
            <input appDecimalOnly formControlName="prepaid_items" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Estimated prepaid items" type="text">
            <mat-error *ngIf="prepaid_items.hasError('pattern')">
              Estimated prepaid items is invalid
            </mat-error>
            <mat-error *ngIf="prepaid_items.hasError('required')">
              Estimated prepaid items is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Estimated closing costs</mat-label>
            <input appDecimalOnly formControlName="closing_costs" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Estimated closing costs" type="text">
            <mat-error *ngIf="closing_costs.hasError('pattern')">
              Estimated closing costs is invalid
            </mat-error>
            <mat-error *ngIf="closing_costs.hasError('required')">
              Estimated closing costs is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>MI and Funding Fee</mat-label>
            <input appDecimalOnly formControlName="funding_fee" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="MI and Funding Fee" type="text">
            <mat-error *ngIf="funding_fee.hasError('pattern')">
              MI and Funding Fee is invalid
            </mat-error>
            <mat-error *ngIf="funding_fee.hasError('required')">
              MI and Funding Fee is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Discount dollar amount</mat-label>
            <input appDecimalOnly formControlName="discount" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Discount dollar amount" type="text">
            <mat-error *ngIf="discount.hasError('pattern')">
              Discount dollar amount is invalid
            </mat-error>
            <mat-error *ngIf="discount.hasError('required')">
              Discount dollar amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Subordinate financing</mat-label>
            <input appDecimalOnly formControlName="subordinate_financing" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Subordinate financing" type="text">
            <mat-error *ngIf="subordinate_financing.hasError('pattern')">
              Subordinate financing is invalid
            </mat-error>
            <mat-error *ngIf="subordinate_financing.hasError('required')">
              Subordinate financing is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isPurchaseLoan" class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Total seller credits amount</mat-label>
            <input appDecimalOnly formControlName="closing_costs_seller" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Total seller credits amount" type="text">
            <mat-error *ngIf="closing_costs_seller.hasError('pattern')">
              Total seller credits amount is invalid
            </mat-error>
            <mat-error *ngIf="closing_costs_seller.hasError('required')">
              Total seller credits amount is required
            </mat-error>
          </mat-form-field>
        </div>
        `
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>MI and Funding Fee financed</mat-label>
            <input appDecimalOnly formControlName="funding_financed" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="MI and Funding Fee financed" type="text">
            <mat-error *ngIf="funding_financed.hasError('pattern')">
              MI and Funding Fee financed is invalid
            </mat-error>
            <mat-error *ngIf="funding_financed.hasError('required')">
              MI and Funding Fee financed is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
      <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
      <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
    </form>
  </mat-card-content>
</mat-card>
