<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date" required>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Document date is unrealistic
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Document date format is invalid
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <h5>The Broker</h5>

  <div class="row">
    <div class="col">
      <mat-radio-group aria-label="acting_dual_capacity" formControlName="acting_dual_capacity">
        The broker
        <mat-radio-button [checked]="acting_dual_capacity.value===true" [value]="true">IS</mat-radio-button>
        <mat-radio-button [checked]="acting_dual_capacity.value===false" [value]="false">IS NOT</mat-radio-button>
        acting in a dual capacity as a real estate broker and a mortgage broker
      </mat-radio-group>
      <mat-error *ngIf="submitted&&acting_dual_capacity.hasError('required')">
        Select your response
      </mat-error>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-radio-group aria-label="broker_accepting_consideration" formControlName="broker_accepting_consideration">
        The broker
        <mat-radio-button [checked]="broker_accepting_consideration.value===true" [value]="true">IS</mat-radio-button>
        <mat-radio-button [checked]="broker_accepting_consideration.value===false" [value]="false">IS NOT</mat-radio-button>
        accepting a consideration for directly or indirectly placing a loan with a mortgage lender
      </mat-radio-group>
      <mat-error *ngIf="submitted&&broker_accepting_consideration.hasError('required')">
        Select your response
      </mat-error>
    </div>
  </div>


  <h5>The Customer</h5>

  <div class="row">
    <div class="col">
      <mat-radio-group aria-label="customer_consent_dual_capacity" formControlName="customer_consent_dual_capacity">
        The customer
        <mat-radio-button [checked]="customer_consent_dual_capacity.value===true" [value]="true">DOES</mat-radio-button>
        <mat-radio-button [checked]="customer_consent_dual_capacity.value===false" [value]="false">DOES NOT</mat-radio-button>
        consent to the Broker acting in a dual capacity
      </mat-radio-group>
      <mat-error *ngIf="submitted&&customer_consent_dual_capacity.hasError('required')">
        Select your response
      </mat-error>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-radio-group aria-label="customer_consent_consideration" formControlName="customer_consent_consideration">
        The customer
        <mat-radio-button [checked]="customer_consent_consideration.value===true" [value]="true">DOES</mat-radio-button>
        <mat-radio-button [checked]="customer_consent_consideration.value===false" [value]="false">DOES NOT</mat-radio-button>
        consent to the Broker receiving consideration for directly or indirectly placing a loan with a mortgage lender
      </mat-radio-group>
      <mat-error *ngIf="submitted&&customer_consent_consideration.hasError('required')">
        Select your response
      </mat-error>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genCODual()" class="btn btn-lg btn-primary">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</form>
