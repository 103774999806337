<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Party</mat-label>
            <mat-select formControlName="role" placeholder="Select party" required>
              <mat-option *ngFor="let role of roles" [value]="role.key">{{role.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="role.hasError('required')">
              Party is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Full name</mat-label>
            <input formControlName="full_name" matInput placeholder="Full name" maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="full_name.hasError('required')">
              Full name is required
            </mat-error>
            <mat-error *ngIf="full_name.hasError('pattern')">
              Full name is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Vesting type</mat-label>
            <mat-select formControlName="vesting_type" placeholder="Select vesting type">
              <mat-option *ngFor="let vesting_type of vesting_types" [value]="vesting_type.key">{{vesting_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="vesting_type.hasError('required')">
              Vesting type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="vesting_type.value=='Other'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Explain other vesting</mat-label>
            <input formControlName="other_description" matInput placeholder="Explain other vesting" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="other_description.hasError('required')">
              Explain other vesting is required
            </mat-error>
            <mat-error *ngIf="other_description.hasError('pattern')">
              Explain other vesting is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="role.value=='entity'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Trust type</mat-label>
            <mat-select formControlName="trust_type" placeholder="Select trust type">
              <mat-option *ngFor="let trust_type of trust_types" [value]="trust_type.key">{{trust_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="trust_type.hasError('required')">
              Trust type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
