import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-view-loan-record',
  templateUrl: './view-loan-record.component.html',
})
export class ViewLoanRecordComponent extends CompBaseComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  edit() {
    if (this.canUpdateLoanRecord) {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
    }
  }
}
