import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Note} from '../../../models/note';
import {CompBaseComponent} from '../../comp-base.component';
import {NoteService} from '../../../services/note.service';
import {FormUtil} from '../../form-util';
import {Router} from '@angular/router';

@Component({
  selector: 'app-note',
  template: '',
})
export class NoteComponent extends CompBaseComponent implements OnInit {
  @Input() note: Note;
  public tranId: number;
  public noteService: NoteService;

  // form and form controls
  public form: FormGroup;
  public title: FormControl;
  public body: FormControl;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.noteService = this.injector.get(NoteService);
  }

  createFormControls(note) {
    this.title = new FormControl(note.title, AppValidators.present);
    this.body = new FormControl(note.body, AppValidators.present);
  }

  createForm() {
    this.form = new FormGroup({
      title: this.title,
      body: this.body,
    });
  }

  cancel(): void {
    this.router.navigate(['/loan/view-notes']);
  }

  delete(): void {
    this.noteService.deletePost(this.tran.id, this.note.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Note successfully deleted');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete note', data);
        this.cancel();
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const payload = this.form.value;
    payload['tran_id'] = +this.tranId;

    this.noteService.createPost(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Note successfully created');
        this.router.navigate(['/loan/view-notes']);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create note', data);
        this.cancel();
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const payload = this.form.value;

    this.noteService.updatePost(this.note.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Note successfully updated');
        this.router.navigate(['/loan/view-notes']);
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update note', data);
        this.cancel();
      });
  }
}
