import {Component, Injector, OnInit} from '@angular/core';
import {Income} from '../../../../models/income';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-incomes',
  templateUrl: './view-segment-incomes.component.html',

})
export class ViewSegmentIncomesComponent extends CompBaseComponent implements OnInit {
  public priItems: Array<any>;
  public secItems: Array<any>;
  public otherItems: Array<any>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'incomes';
    this.section = this.applicant;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Income.deserializeArray(this.loan_app[this.applicant].incomes);
    this.priItems = this.items.filter((elt) => elt.income_type === 'P');
    this.secItems = this.items.filter((elt) => elt.income_type === 'S');
    this.otherItems = this.items.filter((elt) => elt.income_type !== 'P' && elt.income_type !== 'S');
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
