import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {FormControl, FormGroup} from '@angular/forms';
import {UploadFileService} from "../../services/upload-file.service";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
})
export class UploadFileComponent implements OnInit {
  @Input() uploadType: string;
  @Input() actionUrl: string;
  @Input() title: string;
  @Input() disableCancel: boolean;
  @Input() isDescription: boolean;
  @Input() multipleFiles = false;
  @Output() uploadComplete = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() error = new EventEmitter();
  @Output() uploadSubmit = new EventEmitter();
  filename: string;
  isUploading = false;
  canUpload = false;
  uploadMessage = null;
  public form: FormGroup;
  public description: FormControl;
  public fileList: Array<any>;
  public reloaded: boolean;

  constructor(private sharedService: SharedService, private uploadFileService: UploadFileService) {
  }

  ngOnInit() {
    this.reset();
  }

  onChange(ev: any) {
    this.fileList = ev.target.files;
    this.resetUpload();
    Array.from(this.fileList).forEach((elt) => {
      const regex = /(\.[a-z]{3,4})$/;
      if (!(elt.name)) {
        return;
      }
      const matches = regex.exec(elt.name.toLowerCase());
      const ext = matches && matches.length > 0 ? matches[1] : null;
      let event: any;
      switch (this.uploadType) {
        case 'pdf':
          if (!/^(\.pdf)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a PDF file!';
          }
          break;
        case 'xml':
          if (!/^(\.xml)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload an XML file!';
          }
          break;
        case 'mismo':
          if (!/^(\.xml)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a MISMO 3.4 XML file!';
          }
          break;
        case 'fnma':
          if (!/^(.txt|\.fnm|\.fnma)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a Fannie Mae 3.2 file (txt, fnm, or fnma)!';
          }
          break;
        case 'image':
          if (!/^(.jpeg|\.jpg|\.png|\.gif|\.ico|\.webp)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload an image file (jpeg/jpg, png, gif, webp, or ico)!';
          }
          break;
        case 'image_pdf':
          if (!/^(.jpeg|\.jpg|\.pdf|\.png|\.webp)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a PDF or image (pdf, jpeg, jpg, png, webp)!';
          }
        case 'all':
          if (!/^(.jpeg|\.jpg|\.png|\.gif|\.ico|\.pdf|\.xml|\.webp)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a PDF, image (pdf, jpeg/jpg, png, gif, or ico), or XML file (xml)!';
          }
        case 'pdf-image':
          if (!/^(.jpeg|\.jpg|\.png|\.gif|\.ico|\.pdf|\.webp)$/i.test(ext)) {
            this.uploadMessage = '<strong>Error:</strong> Must upload a PDF or image (pdf, jpeg/jpg, png, gif, or ico)!';
          }
      }
      if (this.uploadMessage) {
        event = {
          type: 'Message',
          text: this.uploadMessage,
          color: 'danger',
          close: true,
          fadeOut: false
        };
        this.sharedService.emitChange(event);
        this.cancel.emit();
        return;
      }
    })
    this.canUpload = true;
  }

  getFiles(): Array<String> {
    if (this.fileList) {
      return Array.from(this.fileList).map((elt) => elt.name)
    }
    return [];
  }

  reset(): void {
    this.reloaded = false;
    this.resetUpload();
    setTimeout(() => {
      this.reloaded = true;
    }, 100);
  }

  resetUpload(): void {
    this.uploadMessage = null;
    this.createFormControls();
    this.createForm();
    this.isUploading = false;
  }

  createFormControls() {
    this.description = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      description: this.description,
    });
  }

  cancelUpload() {
    this.cancel.emit();
    this.fileList = []
    this.reset();
  }

  onSubmit() {
    this.uploadSubmit.emit({description: this.description ? this.description.value : ''})
    this.isUploading = true;
    const fileArr = Array.from(this.fileList);
    let promiseArr = []
    Array.from(this.fileList).forEach((file) => {
      promiseArr.push(this.uploadFileService.upload(file, this.actionUrl));
    });
    Promise.all(promiseArr).then((values) => {
      this.uploadComplete.emit({});
    }).catch((error) => {
      const event = {
        type: 'Message',
        text: error,
        color: 'danger',
        close: true,
        fadeOut: false
      };
      this.error.emit(event)
    })
  }
}
