<mat-card *ngIf="reloaded">
  <mat-card-title>Order List</mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Order ID</th>
          <th>Entered by</th>
          <th>Amount</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="viewOrder($event,order)" *ngFor="let order of orders" class="hand-pointer">
          <td>{{order.id}}</td>
          <td>{{order.entered_by_name}} (UserID: {{order.entered_by_user_id}})</td>
          <td>{{order.amount}}</td>
          <td>{{order.created_at | date: 'medium'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>
