import {Component, Injector, OnInit} from '@angular/core';
import {RaceComponent} from '../race/race.component';

@Component({
  selector: 'app-view-race',
  templateUrl: './view-race.component.html',
})
export class ViewRaceComponent extends RaceComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
