<mat-card class="mt-2 mb-1">
  <mat-card-title>ARM Data</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Index (percent)</mat-label>
            <input appDecimalThreeOnly formControlName="index" matInput maxlength="7" placeholder="Index (percent)" type="text" appAutoFocus required>
            <mat-error *ngIf="index.hasError('required')">
              Index is required
            </mat-error>
            <mat-error *ngIf="index.hasError('pattern')">
              Index is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Index type</mat-label>
            <mat-select formControlName="index_type_new" placeholder="Select index type">
              <mat-option *ngFor="let index_type_new of index_types_new" [value]="index_type_new.key">{{index_type_new.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="index_type_new.hasError('required')">
              Index type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" [hidden]="index_type_new.value !== 'Other'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other index type</mat-label>
            <mat-select formControlName="other_description" placeholder="Select other index type">
              <mat-option *ngFor="let other_description of index_types_other" [value]="other_description.key">{{other_description.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="other_description.hasError('required')">
              Other index type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Index margin (percent)</mat-label>
            <input appDecimalThreeOnly formControlName="index_margin" matInput maxlength="7" placeholder="Index margin (percent)" type="text">
            <mat-error *ngIf="index_margin.hasError('required')">
              Index margin is required
            </mat-error>
            <mat-error *ngIf="index_margin.hasError('pattern')">
              Index margin is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Qualifying rate (percent)</mat-label>
            <input appDecimalOnly formControlName="qualifying_rate" matInput maxlength="7" placeholder="Qualifying rate (percent)" type="text">
            <mat-error *ngIf="qualifying_rate.hasError('required')">
              Qualifying rate is required
            </mat-error>
            <mat-error *ngIf="qualifying_rate.hasError('pattern')">
              Qualifying rate is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>First adjustment period count in months</mat-label>
            <input appNumberOnly formControlName="first_adjustment_months_no" matInput maxlength="3" placeholder="First adjustment period count in months" type="text">
            <mat-error *ngIf="first_adjustment_months_no.hasError('required')">
              First adjustment period count is required
            </mat-error>
            <mat-error *ngIf="first_adjustment_months_no.hasError('pattern')">
              First adjustment period count is invalid
            </mat-error>
            <mat-hint>(e.g., 60 for five years)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Subsequent adjustment period count in months</mat-label>
            <input appNumberOnly formControlName="subsequent_adjustment_months_no" matInput maxlength="3" placeholder="Subsequent adjustment period count in months" type="text">
            <mat-error *ngIf="subsequent_adjustment_months_no.hasError('required')">
              Subsequent adjustment period count is required
            </mat-error>
            <mat-error *ngIf="subsequent_adjustment_months_no.hasError('pattern')">
              Subsequent adjustment period count is invalid
            </mat-error>
            <mat-hint>(e.g., 12 for one year)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
