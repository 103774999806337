import {Component, Injector, OnInit} from '@angular/core';
import {ConfigComponent} from '.././config/config.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigItem} from "../../../models/config-item";

@Component({
  selector: 'app-edit-segment-config',
  templateUrl: '../config/edit-segment-config.component.html',
})
export class EditSegmentConfigComponent extends ConfigComponent implements OnInit {
  op = 'Edit';

  constructor(public injector: Injector, public route: ActivatedRoute, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.configService.getConfigItem(params['config_key'])
        .then((configItem: ConfigItem) => {
          this.item = configItem;
          this.createFormControls(this.item);
          this.createForm();
          this.reloaded = true;
        });
    });
  }

  cancel(): void {
    this.router.navigate(['admin/configs']);
  }
}
