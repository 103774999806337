import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExtraLayoutComponent} from './layouts/extra/extra.component';

import {ViewMLOConfigurationComponent} from './content/account/mlo-configuration/view-mlo-configuration/view-mlo-configuration.component';
import {ResetPasswordComponent} from './content/account/reset-password/reset-password.component';
import {ViewPhotoComponent} from './content/account/photo/view-photo/view-photo.component';
import {ViewLoanListComponent} from './content/loans/view-loan-list/view-loan-list.component';

import {Page404Component} from './pages/extra-pages/page-404/page-404.component';
import {Page500Component} from './pages/extra-pages/page-500/page-500.component';
import {Page401Component} from './pages/extra-pages/page-401/page-401.component';
import {PageSignOutComponent} from './pages/extra-pages/sign-out/sign-out.component';
import {UserService} from './services/user.service';
import {ViewLoanAppComponent} from './content/loan-app/view-loan-app/view-loan-app.component';
import {ViewContactsComponent} from './content/account/contacts/view-contacts/view-contacts.component';

import {ViewWorkflowTabsComponent} from './content/workflow/view-workflow-tabs/view-workflow-tabs.component';
import {ViewTasksTabsComponent} from './content/tasks/view-tasks-tabs/view-tasks-tabs.component';

import {ViewTimelineComponent} from './content/timeline/view-timeline/view-timeline.component';
import {ViewNotesComponent} from './content/notes/view-notes/view-notes.component';
import {ViewCompEventsComponent} from './content/compliance/view-comp-events/view-comp-events.component';

import {NewLoanComponent} from './content/loans/new-loan/new-loan.component';

import {ViewDossierTabsComponent} from './content/dossier/view-dossier-tabs/view-dossier-tabs.component';
import {ViewDashboardComponent} from './content/loan-dashboard/view-dashboard/view-dashboard.component';
import {HighlightsComponent} from './content/home/highlights/highlights.component';
import {ViewUserListComponent} from './content/admin/user/view-user-list/view-user-list.component';
import {FindUsersComponent} from './content/admin/user/find-users/find-users.component';
import {AdminEditUserComponent} from './content/admin/user/edit-user/admin-edit-user.component';
import {AdminAddUserComponent} from './content/admin/user/add-user/admin-add-user.component';
import {ViewPermissionTabsComponent} from './content/permissions/view-permission-tabs/view-permission-tabs.component';
import {ViewDashboardTabsComponent} from './content/dashboard/view-dashboard-tabs/view-dashboard-tabs.component';
import {StepByStepComponent} from './content/home/step-by-step/step-by-step.component';
import {SiteLayoutComponent} from './content/home/site-layout/site-layout.component';
import {ViewSysAlertsComponent} from './content/alerts/view-sys-alerts/view-sys-alerts.component';
import {LOInfoComponent} from './content/home/lo-info/lo-info.component';
import {PageDeviceComponent} from './pages/extra-pages/device/device.component';
import {PageVerifyDeviceComponent} from './pages/extra-pages/verify-device/verify-device.component';
import {ViewInviteComponent} from './content/account/invite/view-invite/view-invite.component';
import {FindOrganizationsComponent} from './content/admin/organization/find-organizations/find-organizations.component';
import {ViewOrganizationListComponent} from './content/admin/organization/view-organization-list/view-organization-list.component';
import {AddOrganizationComponent} from './content/admin/organization/add-organization/add-organization.component';
import {EditOrganizationComponent} from './content/admin/organization/edit-organization/edit-organization.component';
import {ViewOrganizationComponent} from './content/admin/organization/view-organization/view-organization.component';
import {ViewUserOrgComponent} from './content/account/user-org/view-user-org/view-user-org.component';
import {ViewOrgLoansComponent} from './content/org/./loans/view-org-loans.component';
import {ViewMcrListComponent} from './content/loans/mcr/view-mcr-list/view-mcr-list.component';
import {ViewLoMcrComponent} from './content/loans/mcr/view-lo-mcr/view-lo-mcr.component';
import {ViewOrgMcrsComponent} from './content/org/mcrs/view-org-mcrs/view-org-mcrs.component';
import {ViewOrgMcrComponent} from './content/org/mcrs/view-org-mcr/view-org-mcr.component';
import {PageUploadPdfComponent} from './pages/extra-pages/upload-pdf/upload-pdf.component';
import {MaintenanceComponent} from './content/maintenance/maintenance.component';
import {ViewSegmentConditionsComponent} from './content/conditions/view-segment-conditions/view-segment-conditions.component';
import {ViewSecuredUploadsTabsComponent} from './content/ez-uploads/secured-uploads/view-secured-uploads-tabs/view-secured-uploads-tabs.component';
import {PageUnsubscribeComponent} from './pages/extra-pages/unsubscribe/unsubscribe.component';
import {WrapperLayoutComponent} from './layouts/wrapper/wrapper.component';
import {ContactUsComponent} from './content/site/contact/contact-us.component';
import {OurStoryComponent} from './content/site/about/our-story/our-story.component';
import {POLOSFeaturesComponent} from './content/site/about/polos-features/polos-features.component';
import {POLOSVideoComponent} from './content/site/about/polos-video/polos-video.component';
import {TermsComponent} from './content/site/about/terms/terms.component';
import {PrivacyComponent} from './content/site/about/privacy/privacy.component';
import {ViewCalcMonthlyComponent} from './content/calc/view-calc-monthly/view-calc-monthly.component';
import {ViewCalcRateComponent} from './content/calc/view-calc-rate/view-calc-rate.component';
import {ViewCalcAmorComponent} from './content/calc/view-calc-amor/view-calc-amor.component';
import {ViewCalcAPRComponent} from './content/calc/view-calc-apr/view-calc-apr.component';
import {ViewLoanMcrComponent} from './content/org/loan-mcrs/view-loan-mcr/view-loan-mcr.component';
import {ViewOrgInstructionsComponent} from './content/home/view-org-instructions/view-org-instructions.component';
import {AdminResetPwComponent} from './content/admin/user/reset-pw/admin-reset-pw.component';
import {AdminViewUserComponent} from './content/admin/user/view-user/admin-view-user.component';
import {FindAgentOrganizationsComponent} from './content/agent/organization/find-agent-organizations/find-agent-organizations.component';
import {AddAgentOrganizationComponent} from './content/agent/organization/add-agent-organization/add-agent-organization.component';
import {EditAgentOrganizationComponent} from './content/agent/organization/edit-agent-organization/edit-agent-organization.component';
import {ViewAgentOrganizationListComponent} from './content/agent/organization/view-agent-organization-list/view-agent-organization-list.component';
import {ViewAgentOrganizationComponent} from './content/agent/organization/view-agent-organization/view-agent-organization.component';
import {ViewLoanMcrsContainerComponent} from './content/org/loan-mcrs/./view-loan-mcrs-container/view-loan-mcrs-container.component';
import {ViewAgentPermissionsComponent} from './content/agent/permissions/view-agent-permissions/view-agent-permissions.component';
import {McrServiceComponent} from './content/site/mcr/mcr-service.component';
import {ViewSandboxLoansComponent} from './content/loans/view-sandbox-loans/view-sandbox-loans.component';
import {NewSandboxLoanComponent} from './content/loans/new-sandbox-loan/new-sandbox-loan.component';
import {FindTransComponent} from './content/admin/tran/find-trans/find-trans.component';
import {ViewTranListComponent} from './content/admin/tran/view-tran-list/view-tran-list.component';
import {AdminViewTranComponent} from './content/admin/tran/view-tran/admin-view-tran.component';
import {AdminEditTranComponent} from './content/admin/tran/edit-tran/admin-edit-tran.component';
import {ViewAccountComponent} from './content/account/settings/view-account/view-account.component';
import {ViewProfileComponent} from './content/account/branding/view-profile/view-profile.component';
import {EditSegmentNoteComponent} from './content/notes/edit-segment-note/edit-segment-note.component';
import {AddNoteComponent} from './content/notes/add-note/add-note.component';
import {EditSegmentConditionComponent} from './content/conditions/edit-segment-condition/edit-segment-condition.component';
import {AddConditionComponent} from './content/conditions/add-condition/add-condition.component';
import {LoginFromAuthComponent} from './content/login/login-from-auth.component';
import {DeleteUserComponent} from './content/admin/user/delete-user/delete-user.component';
import {ViewPitchComponent} from './content/pitch/view-pitch/view-pitch.component';
import {ViewConvertibleComponent} from "./content/pitch/view-convertible/view-convertible.component";
import {ViewSegmentConfigsComponent} from "./content/configs/view-segment-configs/view-segment-configs.component";
import {AddConfigComponent} from "./content/configs/add-config/add-config.component";
import {EditSegmentConfigComponent} from "./content/configs/edit-segment-config/edit-segment-config.component";
import {ViewEmailsComponent} from "./content/comm/view-emails/view-emails.component";
import {ViewMessagesComponent} from "./content/comm/view-messages/view-messages.component";
import {ViewServicesComponent} from "./content/account/services/view-services/view-services.component";
import {EzUploadsInstructionsComponent} from "./content/ez-uploads/ez-uploads-instructions/ez-uploads-instructions.component";
import {ServicesComponent} from "./content/home/services/services.component";
import {ViewWizardComponent} from "./content/org/view-wizard/view-wizard.component";
import {SendSocketMessageComponent} from "./content/admin/user/send-socket-message/send-socket-message.component";
import {DNDComponent} from "./shared/dnd/dnd.component";
import {ConfigDocumentComponent} from "./content/dossier/gendoc/config-document/config-document.component";
import {ViewWorksheetTabsComponent} from "./content/dossier/gendoc/fee-worksheet/view-worksheet-tabs/view-worksheet-tabs.component";
import {ViewEstimateTabsComponent} from "./content/dossier/gendoc/loan-estimate/view-estimate-tabs/view-estimate-tabs.component";
import {LandingComponent} from "./content/site/landing/landing.component";
import {ShopComponent} from "./content/site/shop/shop.component";

const defaultRoutes: Routes = [
  {
    path: 'site',
    children: [
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
      },
      {
        path: 'index',
        pathMatch: 'full',
        component: LandingComponent,
      },
      {
        path: 'shop',
        pathMatch: 'full',
        component: ShopComponent,
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactUsComponent,
      },
      {
        path: 'about/our-story',
        pathMatch: 'full',
        component: OurStoryComponent,
      },
      {
        path: 'about/polos-features',
        pathMatch: 'full',
        component: POLOSFeaturesComponent,
      },
      {
        path: 'about/polos-video',
        pathMatch: 'full',
        component: POLOSVideoComponent,
      },
      {
        path: 'terms',
        pathMatch: 'full',
        component: TermsComponent,
      },
      {
        path: 'privacy',
        pathMatch: 'full',
        component: PrivacyComponent,
      },
      {
        path: 'mcr-service',
        pathMatch: 'full',
        component: McrServiceComponent,
      },
      {
        path: 'calc/monthly',
        pathMatch: 'full',
        component: ViewCalcMonthlyComponent
      },
      {
        path: 'calc/amor',
        pathMatch: 'full',
        component: ViewCalcAmorComponent
      },
      {
        path: 'calc/rate',
        pathMatch: 'full',
        component: ViewCalcRateComponent
      },
      {
        path: 'calc/apr',
        pathMatch: 'full',
        component: ViewCalcAPRComponent
      },
    ]
  },
  {
    path: 'calc',
    children: [
      {
        path: '',
        redirectTo: 'monthly',
        pathMatch: 'full'
      },
      {
        path: 'monthly',
        pathMatch: 'full',
        component: ViewCalcMonthlyComponent
      },
      {
        path: 'amor',
        pathMatch: 'full',
        component: ViewCalcAmorComponent
      },
      {
        path: 'rate',
        pathMatch: 'full',
        component: ViewCalcRateComponent
      },
      {
        path: 'apr',
        pathMatch: 'full',
        component: ViewCalcAPRComponent
      },
    ]
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        redirectTo: 'services',
        pathMatch: 'full'
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactUsComponent,
      },
      {
        path: 'terms',
        pathMatch: 'full',
        component: TermsComponent,
      },
      {
        path: 'privacy',
        pathMatch: 'full',
        component: PrivacyComponent,
      },
      {
        path: 'instructions',
        component: ViewOrgInstructionsComponent,
        pathMatch: 'full',
      },
      {
        path: 'highlights',
        pathMatch: 'full',
        component: HighlightsComponent,
      },
      {
        path: 'services',
        pathMatch: 'full',
        component: ServicesComponent,
      },
      {
        path: 'step-by-step',
        pathMatch: 'full',
        component: StepByStepComponent,
      },
      {
        path: 'lo-info',
        pathMatch: 'full',
        component: LOInfoComponent,
      },
      {
        path: 'site-layout',
        pathMatch: 'full',
        component: SiteLayoutComponent,
      },
    ]
  },
  {
    path: 'account',
    children: [
      {
        path: '',
        redirectTo: 'settings',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        component: ViewAccountComponent,
        pathMatch: 'full',
      },
      {
        path: 'services',
        component: ViewServicesComponent,
        pathMatch: 'full',
      },
      {
        path: 'secured-uploads',
        component: ViewSecuredUploadsTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'mlo-configuration',
        component: ViewMLOConfigurationComponent,
        pathMatch: 'full',
      },
      {
        path: 'user-organization',
        component: ViewUserOrgComponent,
        pathMatch: 'full',
      },
      {
        path: 'branding',
        component: ViewProfileComponent,
        pathMatch: 'full',
      },
      {
        path: 'invite',
        component: ViewInviteComponent,
        pathMatch: 'full',
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-photo',
        component: ViewPhotoComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-contacts',
        component: ViewContactsComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'ez-uploads',
    children: [
      {
        path: 'secured-uploads',
        component: ViewSecuredUploadsTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'ez-uploads-instructions',
        pathMatch: 'full',
        component: EzUploadsInstructionsComponent
      },
    ]
  },
  {
    path: 'org',
    children: [
      {
        path: '',
        redirectTo: 'loans',
        pathMatch: 'full'
      },
      {
        path: 'loans',
        component: ViewOrgLoansComponent,
        pathMatch: 'full',
      },
      {
        path: 'mcr/list',
        component: ViewOrgMcrsComponent,
        pathMatch: 'full',
      },
      {
        path: 'loan-mcr',
        component: ViewLoanMcrComponent,
        pathMatch: 'full',
      },
      {
        path: 'loan-mcrs',
        component: ViewLoanMcrsContainerComponent,
        pathMatch: 'full',
      },
      {
        path: 'org-mcr',
        component: ViewOrgMcrComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'loans',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'loan-officer-dashboard',
        component: ViewDashboardTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: ViewLoanListComponent,
        pathMatch: 'full',
      },
      {
        path: 'lo-mcr/list',
        component: ViewMcrListComponent,
        pathMatch: 'full',
      },
      {
        path: 'lo-mcr',
        component: ViewLoMcrComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-loan',
        component: NewLoanComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'sandbox',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: ViewSandboxLoansComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-loan',
        component: NewSandboxLoanComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'loan',
    children: [
      {
        path: 'view-dashboard',
        component: ViewDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'conditions',
        component: ViewSegmentConditionsComponent,
        pathMatch: 'full',
      },
      {
        path: 'add-condition',
        component: AddConditionComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-condition/:id',
        component: ViewSegmentConditionsComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-condition/:id',
        component: EditSegmentConditionComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-directory',
        component: ViewDossierTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-worksheet',
        component: ViewWorksheetTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-estimate',
        component: ViewEstimateTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-loan-app',
        component: ViewLoanAppComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-workflow',
        component: ViewWorkflowTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-tasks',
        component: ViewTasksTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-messages',
        component: ViewMessagesComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-emails',
        component: ViewEmailsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-comp-events',
        component: ViewCompEventsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-timeline',
        component: ViewTimelineComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-access-control',
        component: ViewPermissionTabsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-notes',
        component: ViewNotesComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-note/:id',
        component: EditSegmentNoteComponent,
        pathMatch: 'full',
      },
      {
        path: 'add-note/:id',
        component: AddNoteComponent,
        pathMatch: 'full',
      },
      {
        path: 'config-doc',
        component: ConfigDocumentComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        redirectTo: 'find-users',
        pathMatch: 'full'
      },
      {
        path: 'configs',
        component: ViewSegmentConfigsComponent,
        pathMatch: 'full',
      },
      {
        path: 'add-config',
        component: AddConfigComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-config',
        component: EditSegmentConfigComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-pitch',
        component: ViewPitchComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-convertible',
        component: ViewConvertibleComponent,
        pathMatch: 'full',
      },
      {
        path: 'find-users',
        component: FindUsersComponent,
        pathMatch: 'full',
      },
      {
        path: 'find-trans',
        component: FindTransComponent,
        pathMatch: 'full',
      },
      {
        path: 'user-list',
        component: ViewUserListComponent,
        pathMatch: 'full',
      },
      {
        path: 'tran-list',
        component: ViewTranListComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-user/:id',
        component: AdminEditUserComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-tran/:id',
        component: AdminEditTranComponent,
        pathMatch: 'full',
      },
      {
        path: 'delete-user',
        component: DeleteUserComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-user/:id',
        component: AdminViewUserComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-tran/:id',
        component: AdminViewTranComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-user',
        component: AdminAddUserComponent,
        pathMatch: 'full',
      },
      {
        path: 'find-organizations',
        component: FindOrganizationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'organization-list',
        component: ViewOrganizationListComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-organization',
        component: AddOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-organization/:id',
        component: EditOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-organization/:id',
        component: ViewOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'alerts',
        component: ViewSysAlertsComponent,
        pathMatch: 'full',
      },
      {
        path: 'reset-pw',
        component: AdminResetPwComponent,
        pathMatch: 'full',
      },
      {
        path: 'send-socket-message',
        component: SendSocketMessageComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'agent',
    children: [
      {
        path: '',
        redirectTo: 'find-organizations',
        pathMatch: 'full'
      },
      {
        path: 'find-organizations',
        component: FindAgentOrganizationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'organization-list',
        component: ViewAgentOrganizationListComponent,
        pathMatch: 'full',
      },
      {
        path: 'new-organization',
        component: AddAgentOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit-organization/:id',
        component: EditAgentOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-organization/:id',
        component: ViewAgentOrganizationComponent,
        pathMatch: 'full',
      },
      {
        path: 'loan-mcrs',
        component: ViewLoanMcrsContainerComponent,
        pathMatch: 'full',
      },
      {
        path: 'org-mcrs',
        component: ViewOrgMcrsComponent,
        pathMatch: 'full',
      },
      {
        path: 'view-access-control',
        component: ViewAgentPermissionsComponent,
        pathMatch: 'full',
      },

    ]
  },
  {
    path: 'mcr',
    children: [
      {
        path: 'view-wizard',
        component: ViewWizardComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'dnd',
    children: [
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
      },
      {
        path: 'index',
        component: DNDComponent,
        pathMatch: 'full',
      },
    ]
  },

];

const extraRoutes: Routes = [
  {
    path: 'sign-out',
    component: PageSignOutComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'unsubscribe',
    component: PageUnsubscribeComponent
  },
  {
    path: 'device',
    component: PageDeviceComponent
  },
  {
    path: 'verify-device',
    component: PageVerifyDeviceComponent
  },
  {
    path: 'upload-pdf',
    component: PageUploadPdfComponent
  },
  {
    path: 'page-404',
    component: Page404Component
  },
  {
    path: 'page-401',
    component: Page401Component
  },
  {
    path: 'page-500',
    component: Page500Component
  }
];

const routes: Routes = [
  {
    path: '',
    component: WrapperLayoutComponent,
    children: defaultRoutes,
    canActivate: [UserService],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pages',
    component: ExtraLayoutComponent,
    children: extraRoutes
  },
  {
    path: 'login_from_auth',
    component: LoginFromAuthComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/pages/page-404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
