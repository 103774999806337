import {ModelBase} from './model-base';
import {Tran} from './tran';

export class OrgTran extends ModelBase {
  id: number;
  index: number;
  name: string;
  description: string;
  status: number;
  owner_id: number;
  owner_full_name: string;
  user_permission: number;
  created_at: string;
  updated_at: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }

  static deserializeArray(arr: Array<any>): Array<OrgTran> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new OrgTran(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getUserAccess(): string {
    return ModelBase.toPermissionString((this.user_permission));
  }

  getStatusString(): string {
    const eltFound = Tran.statuses.find((elt) => elt.key === this.status);
    return eltFound ? eltFound.value : 'N/A';
  }

  isActive(): boolean {
    return this.status === Tran.STATUS.ACTIVE;
  }

  isTerminated(): boolean {
    return this.status === Tran.STATUS.COMPLETED;
  }
}
