import {Component, Injector, OnInit} from '@angular/core';
import {ErrorCheckComponent} from '../error-check/error-check.component';
import $ from 'jquery';

@Component({
  selector: 'app-edit-segment-error-check',
  templateUrl: '../error-check/edit-segment-error-check.component.html',
})
export class EditSegmentErrorCheckComponent extends ErrorCheckComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setErrorLevel();
    this.createFormControls();
    this.createForm();
    this.restoreErrorCheckObject();
  }

  close(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Reload'});
  }

}
