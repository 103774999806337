<nav *ngIf="reloaded" class="navbar navbar-expand-lg navbar-light bg-light pr-font-20">
  <div class="container-fluid">
    <h4 *ngIf="!isMobile" class="page-title d-inline">
      <i (click)="ToggleVerticalNavBar()" class="fas fa-bars"></i>&nbsp;{{title}}
    </h4>
    <h2 *ngIf="isMobile" class="page-title d-inline" style="font-size: 1.3rem">
      <i (click)="ToggleVerticalNavBar()" class="fas fa-bars"></i>&nbsp;{{title}}
    </h2>

    <div *ngIf="!isMobile" class="col text-end text-truncate my-auto">
      <span class="subtitle">{{getSubtitle()}}&nbsp;</span>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-calculator"></i>
          </a>
          <ul class="dropdown-menu  pr-font-16" aria-labelledby="navbarScrollingDropdown">
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/monthly')" aria-label="Calculate Monthly Payment" href="/site/calc/monthly">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Monthly Payment</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/rate')" aria-label="Calculate Monthly Payment" href="/site/calc/rate">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Interest Rate</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/amor')" aria-label="Calculate Amortization Table" href="/site/calc/amor">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Amortization</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/apr')" aria-label="Calculate APR" href="/site/calc/apr">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>APR</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" [hidden]="!displaySync">
          <a (click)="refreshLoan($event)" class="nav-link" href="#" matTooltip="{{reloadToolTip}}" aria-label="Reload loan">
            <span class="fas fa-redo"></span>
          </a>
        </li>
        <li class="nav-item" [hidden]="!displaySync">
          <a (click)="toggleHelp($event)" class="nav-link" href="#" matTooltip="Open Contextual Help"
            aria-label="Open Contextual Help">
            <span class="fas fa-question-circle"></span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="thumbnail-img" src="{{user.getThumbnailPath()}}" alt="Contact thumbnail" style="height: 25px;">
          </a>
          <ul class="dropdown-menu dropdown-menu-end pr-font-16" aria-labelledby="navbarScrollingDropdown">
            <li>
              <span class="dropdown-item fw-bold">{{user.full_name}}</span>
            </li>
            <li>
              <span class="dropdown-item">{{user.getRolesString()}}</span>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event, '/account/settings')" aria-label="Access Account Settings" href="/account/settings"><i
                class="icon fa fa-cog"></i> My Account</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/pages/sign-out" aria-label="Sign out" href="/pages/sign-out"><i class="icon fas fa-sign-out-alt"></i> Sign Out</a>
            </li>
          </ul>
        </li>
        <li style="width: 25px;">
        </li>
      </ul>
    </div>
  </div>
</nav>
