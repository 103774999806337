export class DataService {

  static getCarriers() {
    return [
      {key: 'aliant-canada', value: 'Aliant (Canada)'},
      {key: 'alltel', value: 'Alltel'},
      {key: 'ameritech', value: 'Ameritech'},
      {key: 'att', value: 'AT&T'},
      {key: 'beeline-ua', value: 'Beeline'},
      {key: 'bell-atlantic', value: 'Bell Atlantic'},
      {key: 'bellmobility-canada', value: 'Bell Mobility'},
      {key: 'bellsouthmobility', value: 'Bellsouth Mobility'},
      {key: 'blueskyfrog', value: 'BlueSkyFrog'},
      {key: 'boost', value: 'Boost Mobile'},
      {key: 'bpl-mobile', value: 'BPL Mobile'},
      {key: 'cellularsouth', value: 'Cellular South'},
      {key: 'claro-brazil', value: 'Claro (Brazil)'},
      {key: 'claro-nicaragua', value: 'Claro (Nicaragua)'},
      {key: 'comcast', value: 'Comcast PCS'},
      {key: 'cricket', value: 'Cricket'},
      {key: 'du-arab-emirates', value: 'Du (UAE)'},
      {key: 'e-plus-germany', value: 'E-Plus (Germany)'},
      {key: 'etisalat-arab-emirates', value: 'Etisalat (UAE)'},
      {key: 'fido-canada', value: 'Fido'},
      {key: 'kajeet', value: 'kajeet'},
      {key: 'manitobatelecom-canada', value: 'Manitoba Telecom'},
      {key: 'metropcs', value: 'Metro PCS'},
      {key: 'mobinil-egypt', value: 'Mobinil'},
      {key: 'mobistar-belgium', value: 'Mobistar (Belgium)'},
      {key: 'mobitel', value: 'Mobitel'},
      {key: 'movistar-spain', value: 'Movistar (Spain)'},
      {key: 'nextel', value: 'Nextel'},
      {key: 'northerntel-canada', value: 'NorthernTel (Canada)'},
      {key: 'o2-germany', value: 'o2 (Germany)'},
      {key: 'o2-uk', value: 'o2 (UK)'},
      {key: 'orange-mumbai', value: 'Orange (Mumbai)'},
      {key: 'orange-netherlands', value: 'Orange (Netherlands)'},
      {key: 'orange-uk', value: 'Orange (UK)'},
      {key: 'powertel', value: 'Powertel'},
      {key: 'pscwireless', value: 'PSC Wireless'},
      {key: 'qwest', value: 'Qwest'},
      {key: 'rogers-canada', value: 'Rogers (Canada)'},
      {key: 'rogers-wireless', value: 'Rogers Wireless'},
      {key: 'sasktel-canada', value: 'SaskTel (canada)'},
      {key: 'sfr-france', value: 'SFR (France)'},
      {key: 'southernlink', value: 'Southern Link'},
      {key: 'sprint', value: 'Sprint PCS'},
      {key: 'suncom', value: 'Suncom'},
      {key: 't-mobile', value: 'T-Mobile'},
      {key: 't-mobile-austria', value: 'T-Mobile (Austria)'},
      {key: 't-mobile-germany', value: 'T-Mobile (Netherlands)'},
      {key: 't-mobile-uk', value: 'T-Mobile (UK)'},
      {key: 'telebec-canada', value: 'Telebec (Canada)'},
      {key: 'telefonica-spain', value: 'Telefonica (Spain)'},
      {key: 'telus-canada', value: 'Telus (Canada)'},
      {key: 'telus-mobility', value: 'Telus Mobility'},
      {key: 'tracfone', value: 'Tracfone'},
      {key: 'verizon', value: 'Verizon Wireless'},
      {key: 'virgin', value: 'Virgin Mobile'},
      {key: 'virgin-canada', value: 'Virgin (Canada)'},
      {key: 'vodafone-egypt', value: 'Vodafone (Egypt)'},
      {key: 'vodafone-germany', value: 'Vodafone (Germany)'},
      {key: 'vodafone-italy', value: 'Vodafone (Italy)'},
      {key: 'vodafone-jp-chuugoku', value: 'Vodafone (Japan - Chuugoku)'},
      {key: 'vodafone-jp-hokkaido', value: 'Vodafone (Japan - Hokkaido)'},
      {key: 'vodafone-jp-hokuriko', value: 'Vodafone (Japan - Hokuriko)'},
      {key: 'vodafone-jp-kansai', value: 'Vodafone (Japan - Kansai)'},
      {key: 'vodafone-jp-kanto', value: 'Vodafone (Japan - Kanto)'},
      {key: 'vodafone-jp-koushin', value: 'Vodafone (Japan - Koushin)'},
      {key: 'vodafone-jp-kyuushu', value: 'Vodafone (Japan - Kyuushu)'},
      {key: 'vodafone-jp-niigata', value: 'Vodafone (Japan - Niigata)'},
      {key: 'vodafone-jp-okinawa', value: 'Vodafone (Japan - Okinawa)'},
      {key: 'vodafone-jp-osaka', value: 'Vodafone (Japan - Osaka)'},
      {key: 'vodafone-jp-shikoku', value: 'Vodafone (Japan - Shikoku)'},
      {key: 'vodafone-jp-tokyo', value: 'Vodafone (Japan - Tokyo)'},
      {key: 'vodafone-jp-touhoku', value: 'Vodafone (Japan - Touhoku)'},
      {key: 'vodafone-jp-toukai', value: 'Vodafone (Japan - Toukai)'},
      {key: 'vodafone-spain', value: 'Vodafone (Japan - Spain)'},
      {key: 'vodafone-uk', value: 'Vodafone (UK)'}
    ];
  }

  static getARMAmortizationTypes() {
    return ['AdjustableRate', 'GraduatedPaymentARM'];
  }

  static getFixedTypes() {
    return ['Fixed'];
  }

  static getLoanPurposeCodes() {
    return {
      'MortgageModification': 'Mortgage Modification',
      'Other': 'Other',
      'Purchase': 'Purchase',
      'Refinance': 'Refinance',
      'Unknown': 'Unknown',
    };
  }

  static getResidenceTypes() {
    return {
      'Current': 'Present residence',
      'Mailing': 'Mailing address',
      'Prior': 'Former address'
    };
  }
}
