import {Component, Injector, OnInit} from '@angular/core';
import {Contact} from '../../../../models/contact';
import {FormControl, FormGroup} from '@angular/forms';
import {Tran} from '../../../../models/tran';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-contacts',
  templateUrl: './view-segment-contacts.component.html',
})
export class ViewSegmentContactsComponent extends CompBaseComponent implements OnInit {
  static tranOnly = false;
  static displayHidden = false;
  public filteredItems: Array<Contact> = [];
  public canCreateContact: boolean;
  public isContacts: boolean;
  public tran: Tran;

  // form and form controls
  public form: FormGroup;
  public tranOnly: FormControl;
  public displayHidden: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isContacts = this.items && (this.items.length > 0);
    this.canCreateContact = this.permissionService.execCan('CanCreateContact');
    this.createFormControls();
    this.createForm();
    this.loadContacts();
  }

  // note: if borrower has no transactions, getCurrentTran will currently error out
  // for this reason we flag reloaded = true on failure
  loadContacts(): void {
    this.reloaded = false;
    this.filteredItems = [];
    this.tranService.getCurrentTran(false)
      .then((tran) => {
        this.tran = tran;
      })
      .catch((data) => {
        console.log('FAILED: loadContacts failed');
      }).finally(() => {
      if (this.tran && ViewSegmentContactsComponent.tranOnly) {
        const tranContactIds = this.tran.contacts.map((elt) => elt.id);
        this.filteredItems = this.items.filter((elt) => {
          return ViewSegmentContactsComponent.displayHidden ? tranContactIds.indexOf(elt.id) >= 0 : tranContactIds.indexOf(elt.id) >= 0 && !elt.hidden;
        });
        this.reloaded = true;
        return;
      }
      this.filteredItems = this.items.filter((elt) => {
        return ViewSegmentContactsComponent.displayHidden ? true : !elt.hidden;
      });
      this.reloaded = true;
    });
  }

  createFormControls(): void {
    this.tranOnly = new FormControl(ViewSegmentContactsComponent.tranOnly);
    this.displayHidden = new FormControl(ViewSegmentContactsComponent.displayHidden);
  }

  createForm(): void {
    this.form = new FormGroup({
      tranOnly: this.tranOnly,
      displayHidden: this.displayHidden
    });
  }

  onFilterChange(): void {
    // tranOnly must always be false if there is no current transaction.
    ViewSegmentContactsComponent.tranOnly = this.tran ? this.tranOnly.value : false;
    ViewSegmentContactsComponent.displayHidden = this.displayHidden.value;
    this.loadContacts();
  }
}
