<mat-card *ngIf="reloaded" class="mt-1">
  <mat-card-title>{{op=='Add'?(userType.value==='AUX'?'Include an Unregistered Loan Officer (with POLOS)':'Include a Registered Loan Officer(with POLOS)') : 'Edit Loan Officer'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <mat-radio-group *ngIf="op==='Add'" aria-label="user_type_label" formControlName="userType">
        <mat-radio-button [checked]="userType.value==='AUX'" value='AUX'>Unregistered Loan Officer (with POLOS) </mat-radio-button>
        <mat-radio-button [checked]="userType.value==='LO'" value='LO'>Registered Loan Officer (with POLOS) </mat-radio-button>
      </mat-radio-group>

      <div class="row" *ngIf="op==='EditUser' && user.isLoanOfficer()">
        <div class="col-lg-6">
          <b>Name: </b> {{user.full_name}}
        </div>
      </div>

      <div class="row mb-2 mt-2" *ngIf="canEditOrgRole()&&op==='EditUser'">
        <div class="col-lg-2">
          <mat-label>Organization role</mat-label>
          <mat-select formControlName="orgRole" placeholder="Select organization role">
            <mat-option *ngFor="let org_role of org_roles" [value]="org_role.key">{{org_role.value}}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row" *ngIf="canEditUser()">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>First name</mat-label>
            <input formControlName="first" matInput placeholder="First name" autofocus>
            <mat-error *ngIf="first.hasError('required')">
              First name is required
            </mat-error>
            <mat-error *ngIf="first.hasError('minlength')">
              First name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="canEditUser()">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Last name</mat-label>
            <input formControlName="last" matInput placeholder="Last name">
            <mat-error *ngIf="last.hasError('required')">
              Last name is required
            </mat-error>
            <mat-error *ngIf="last.hasError('minlength')">
              Last name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="canEditUser()">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Email">
            <mat-error *ngIf="email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              Email is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>NMLS ID</mat-label>
            <input formControlName="nmls" matInput placeholder="NMLS ID" autofocus>
            <mat-error *ngIf="nmls.hasError('required')">
              NMLS ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="canEditUser()">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>States licensed</mat-label>
            <input formControlName="states" appUpperCase matInput placeholder="States licensed">
            <mat-error *ngIf="states.hasError('required')">
              States licenses is required
            </mat-error>
            <mat-hint>
              E.g., AZ, CA
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="addFreeLO()" *ngIf="op=='Add'&&userType.value==='AUX'" class="btn btn-lg btn-primary">Add</button>
          <button (click)="addLO()" *ngIf="op=='Add'&&userType.value==='LO'" class="btn btn-lg btn-primary">Add</button>
          <button (click)="update()" *ngIf="op=='EditUser'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="remove()" [hidden]="user.isOrgAdmin()" *ngIf="op=='EditUser'" class="ms-2 btn btn-lg btn-danger">Delete</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
