import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';
import {Folder} from '../../../models/folder';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
})
export class FolderComponent extends CompBaseComponent implements OnInit {
  @Input() folder: Folder;
  public displayFolder: boolean;
  public docs: Array<Document>;
  public mode: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.displayFolder = this.folder.selected;
    this.mode = 'View';
    this.tranService.getTranDocuments(this.tran.id)
      .then((documents) => {
        this.docs = documents.filter((elt) => elt.transaction_item_id === this.folder.id);
      });
  }

  // comment: do not percolate change mode
  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }

    super.onEvent(event);
  }

  cancel(): void {
    this.mode = 'View';
  }
}
