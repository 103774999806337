import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {globals} from '../../../../shared/globals/globals';
import {Employment} from '../../../../models/employment';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-employment',
  templateUrl: './edit-segment-employment.component.html',
})
export class EditSegmentEmploymentComponent extends CompBaseComponent implements OnInit {
  @Input() public employmentType: string;
  @Input() public item: Employment;
  public employment_statuses = Employment.employment_statuses;
  public minEmpStartDate = globals.date.minEmpStartDate;
  public maxEmpStartDate = new Date();
  public header: string;

  // form and form controls
  public form: FormGroup;
  public employer_name: FormControl;
  public address: FormControl;
  public address2: FormControl;
  public city: FormControl;
  public state: FormControl;
  public zip: FormControl;
  public zip_four: FormControl;
  public country: FormControl;
  public self_employed: FormControl;
  public ownership: FormControl;
  public employed: FormControl;
  public income_or_loss: FormControl;
  public position: FormControl;
  public business_phone: FormControl;
  public profession_years_no: FormControl;
  public profession_months_no: FormControl;
  public start_date: FormControl;
  public employment_status: FormControl;
  public na: FormControl;
  public seasonal_income_indicator: FormControl;
  public foreign_income_indicator: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = this.employmentType === 'primary' ? 'employment' : 'employment2';
    this.section = this.applicant;
    if (this.employmentType === 'primary') {
      this.header = 'Primary Employment';
    } else {
      this.header = 'Secondary Employment';
    }
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.employment_status = new FormControl(item.employment_status);
    this.employer_name = new FormControl(item.employer_name);
    this.address = new FormControl(item.address);
    this.address2 = new FormControl(item.address2);
    this.city = new FormControl(item.city);
    this.state = new FormControl(item.state, Validators.pattern(globals.pattern.state));
    this.country = new FormControl(item.country, Validators.pattern(globals.pattern.country));
    this.zip = new FormControl(item.zip, Validators.pattern(globals.pattern.zip));
    this.zip_four = new FormControl(item.zip_four, Validators.pattern(globals.pattern.zip_four));
    this.self_employed = new FormControl(item.self_employed);
    this.ownership = new FormControl(item.ownership);
    this.employed = new FormControl(item.employed);
    this.income_or_loss = new FormControl(item.income_or_loss);
    this.position = new FormControl(item.position);
    this.business_phone = new FormControl(item.business_phone, Validators.pattern(globals.pattern.phone));
    this.profession_years_no = new FormControl(item.profession_years_no,
      Validators.compose([Validators.min(0)]));
    this.profession_months_no = new FormControl(item.profession_months_no,
      Validators.compose([Validators.min(0), Validators.max(11)]));
    this.start_date = new FormControl(item.start_date);
    this.na = new FormControl(item.na);
    this.seasonal_income_indicator = new FormControl(item.seasonal_income_indicator);
    this.foreign_income_indicator = new FormControl(item.foreign_income_indicator);
  }

  createForm(): void {
    this.form = new FormGroup({
      employment_status: this.employment_status,
      employer_name: this.employer_name,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      zip_four: this.zip_four,
      country: this.country,
      self_employed: this.self_employed,
      income_or_loss: this.income_or_loss,
      ownership: this.ownership,
      employed: this.employed,
      position: this.position,
      business_phone: this.business_phone,
      profession_years_no: this.profession_years_no,
      profession_months_no: this.profession_months_no,
      start_date: this.start_date,
      na: this.na,
      seasonal_income_indicator: this.seasonal_income_indicator,
      foreign_income_indicator: this.foreign_income_indicator,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Employment(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
