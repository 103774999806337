import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';
import {Organization} from '../../../../models/organization';
import {AdminService} from '../../../../services/admin.service';

@Component({
  selector: 'app-view-organization-list',
  templateUrl: './view-organization-list.component.html',
})
export class ViewOrganizationListComponent extends CompBaseComponent implements OnInit {
  public organizations: Array<Organization>;
  public adminService: AdminService;
  public pageTitle = 'Organization List';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.findOrganizations(params);
    });
  }

  findOrganizations(params): void {
    this.reloaded = false;
    this.adminService.findOrganization(params['name'], params['organization_id'] || null)
      .then((orgs) => this.organizations = orgs)
      .then(() => this.reloaded = true);
  }

  viewOrganization(event: any, org: Organization): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-organization', org.id]);
  }

  // comment: not used for the time being
  editOrganization(event: any, org: Organization): void {
    event.preventDefault();
    this.router.navigate(['/admin/edit-organization', org.id]);
  }
}
