<div class="art-bg">
  <div class="row justify-content-md-center">
    <div class="col-lg-6 text-center m-2 art-blue">
      <h3 class="fw-bold">Get Started!</h3>
    </div>
  </div>

  <div class="row mt-0" [ngStyle]="{margin: 'auto', width: isMobile ? '100%' : '80%'}">
    <!--   BORROWER   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">Borrower</h3>
              <div class="pr-font-16">
                If you are a borrower choose this option
              </div>
            </div>

            <div class="text-center mt-5 mb-4 pr-font-larger fw-bold">
              Free
            </div>
            <div class="pr-font-large text-center">
              Access our collaborative and quickest loan process on the market
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-4">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountBorrower}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!--   PROCESSOR   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">Processor</h3>
              <div class="pr-font-16">
                Our collaborative solution for processors
              </div>
            </div>

            <div class="text-center mt-5 mb-4">
              <span class="pr-font-larger fw-bold">$50</span>/month
            </div>
            <div class="pr-font-large text-center">
              Collaborate with all stakeholders to swiftly process the loan
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-3">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountProcessor}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!--   POLOS   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">MLO</h3>
              <div class="pr-font-16">
                Get access to all POLOS features
              </div>
            </div>

            <div class="text-center mt-5 mb-4">
              <span class="pr-font-larger fw-bold">$50</span>/month
            </div>
            <div class="pr-font-large text-center">
              Access the most complete and collaborative LOS on the markert
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-3">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountPOLOS}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row mt-0" [ngStyle]="{margin: 'auto', width: isMobile ? '100%' : '80%'}">
    <!--   LOAN ASSISTANT   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">Loan Assistant</h3>
              <div class="pr-font-16">
                Our sharing solution for loan assistants
              </div>
            </div>

            <div class="text-center mt-5 mb-4 pr-font-larger fw-bold">
              Free
            </div>
            <div class="pr-font-large text-center">
              Collaborate with your loan officer from everywhere
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-4">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountAssistant}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!--   MCR WIZARD   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">MCR Wizard</h3>
              <div class="pr-font-16">
                The best MCR solution on the market
              </div>
            </div>
            <div class="text-center mt-5 mb-4">
              <span class="pr-font-larger fw-bold">$25</span>/month
            </div>
            <div class="pr-font-large text-center">
              Support for multiple MLOs and states requiring minimal time for each loan
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-3">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountEZMCR}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!--   ANCILLARY   -->
    <div class="col-lg-4">
      <div class="p-3 h-100">
        <mat-card class="h-100" appearance="raised">
          <mat-card-content>
            <div class="text-center pb-3 pt-3 ps-3 pe-3" style="background-color:rgba(81, 169, 227, 0.2)">
              <h3 class="fw-bold art-blue">Ancillary</h3>
              <div class="pr-font-16">
                For real estate agents and title officers
              </div>
            </div>

            <div class="text-center mt-5 mb-4">
              <span class="pr-font-larger fw-bold">Free</span>
            </div>
            <div class="pr-font-large text-center">
              Get loan updates in real time and view the loan timeline
            </div>
          </mat-card-content>
          <mat-card-actions class="mt-3">
            <div class="text-center w-100">
              <a class="fw-bold bg-dark-blue pr-font-large btn btn-lg btn-primary btn-block" title="Create a Borrower account" href="{{authServerCreateAccountAncillary}}">
                Start
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

<div class="pt-5">
</div>
</div>
