<mat-card *ngIf="reloaded">
  <mat-card-title>Organization List</mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Org Type</th>
          <th>NMLS</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="viewOrganization($event,org)" *ngFor="let org of organizations" class="hand-pointer">
          <td>{{org.id}}</td>
          <td>{{org.name}}</td>
          <td>{{org.description}}</td>
          <td>{{org.getOrgType()}}</td>
          <td>{{org.nmls}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>
