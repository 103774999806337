<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row" *ngIf="op==='Add'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Income type</mat-label>
            <mat-select (selectionChange)="onEmpTypeChange()" formControlName="income_type" placeholder="Select income type" required>
              <mat-option *ngFor="let code of income_types"
                [value]="code.key">{{code.value}}
              </mat-option>
              <mat-error *ngIf="code_ex.hasError('required')">
                Income type is required
              </mat-error>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Income source</mat-label>
            <mat-select formControlName="code_ex" placeholder="Select income source" required>
              <mat-option *ngFor="let code of sources[index]"
                [value]="code.key">{{code.value}}
              </mat-option>
              <mat-error *ngIf="code_ex.hasError('required')">
                Income type is required
              </mat-error>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="code_ex.value==='Other'">
        <div class="col-lg-12">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other income description</mat-label>
            <input formControlName="other_description" matInput placeholder="Describe other income" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="other_description.hasError('required')">
              Other description is required
            </mat-error>
            <mat-error *ngIf="other_description.hasError('pattern')">
              Other description is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Amount</mat-label>
            <input appDecimalOnly formControlName="amount" matInput placeholder="Amount" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="amount.hasError('required')">
              Amount is required
            </mat-error>
            <mat-error *ngIf="amount.hasError('pattern')">
              Amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
