import {ModelBase} from './model-base';

export class Permission extends ModelBase {
  acl_permission: number;
  acl_id: string;
  acl_roles: number;
  acl_full_name: string;
  acl_email: string;
  acl_user_id: number;
  // generated property
  roleDescription: string;
  thumbnail: string;
  dirAccess: boolean;
  rolesArray: Array<number>;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'acl_permission' || prop === 'acl_roles' || prop === 'acl_user_id') {
        this[prop] = this.getExplicitNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
    this.rolesArray = ModelBase.getRolesArray(this.acl_roles)
    this.roleDescription = ModelBase.getRolesString(this.acl_roles);

    if (this.isBorrower() || this.isLoanProfessional()) {
      this.dirAccess = (this.acl_permission & ModelBase.PERMISSION.DIR) > 0;
    } else {
      this.dirAccess = false;
    }
  }

  static deserializeArray(arr: Array<any>): Array<Permission> {
    const result = [];
    for (const obj of arr) {
      const newPermission = new Permission(obj);
      result.push(newPermission);
    }

    return result;
  }

  toFullNameAndEmail(): string {
    return `${this.acl_full_name} (${this.acl_email})`;
  }

  toPermissionString(): string {
    return ModelBase.toPermissionString(this.acl_permission);
  }

  isLoanProfessional(): boolean {
    return this.rolesArray.includes(ModelBase.ROLES.LOAN_OFFICER) || this.rolesArray.includes(ModelBase.ROLES.PROCESSOR) || this.rolesArray.includes(ModelBase.ROLES.LOAN_ASSISTANT);
  }

  isBorrower(): boolean {
    return this.rolesArray.includes(ModelBase.ROLES.BORROWER);
  }

  // todo: there is not need to this code. consider removing it.
  toServerPayload(): any {
    return {
      acl_permission: this.toNumber(this.acl_permission),
      acl_id: this.acl_id,
      acl_roles: this.toNumber(this.acl_roles),
      acl_full_name: this.acl_full_name,
      acl_email: this.acl_email,
      acl_user_id: this.toNumber(this.acl_user_id)
    };
  }
}
