import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {PopupComplianceComponent} from '../popup-compliance/popup-compliance.component';

@Component({
  selector: 'app-popup-compliance-container',
  templateUrl: './popup-compliance-container.component.html',
  styleUrls: ['./popup-compliance-container.component.scss']
})
export class PopupComplianceContainerComponent implements OnInit {
  dialogRef: any;
  popupEnabled: boolean;

  constructor(public dialog: MatDialog, private dom: DomSanitizer) {
  }

  ngOnInit() {
    this.popupEnabled = true;
  }

  popCompDialog(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(PopupComplianceComponent, {
      // hasBackdrop: false,
      width: '75%',
      height: '750px',
      maxHeight: '750px',
      panelClass: 'popupDialog',
    });
    this.popupEnabled = false;
    this.dialogRef.afterClosed().subscribe(result => {
      this.popupEnabled = true;
    });
  }
}
