import {Component, OnInit} from '@angular/core';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'page-401',
  templateUrl: './page-401.component.html',
  styleUrls: ['./page-401.component.scss']
})
export class Page401Component implements OnInit {
  public signInUrl: string;
  constructor() {
  }

  ngOnInit() {
    this.signInUrl = `${globals.getAuthServer()}/auth/signin_with_device`
  }
}
