<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Appraisal'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Please provide information about the property appraised value and how it was produced (actual/estimated).</li>
        <li>
          <span class="fw-bold">Appraisal ID.</span>
          A unique identifier assigned by a party for all appraisal data delivered to the party for this loan.
          The party assigning the identifier can be provided using the IdentifierOwnerURI associated with a MISMO identifier.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="isPurchaseLoan">
      <div class="col-lg-6 fw-bold">Seller offered below market financing?</div>
      <div class="col-lg-6" *ngIf="item.seller_below_market_financing!==null">{{item.seller_below_market_financing | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.seller_below_market_financing==null">(no response)</div>
    </div>

    <div *ngIf="isRefinanceLoan" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Existing mortgage owner</div>
      <div class="col-lg-6">{{item.getExistingMortgageOwnerCode()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Appraisal type</div>
      <div class="col-lg-6">{{item.getAppraisalType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Property appraised value</div>
      <div class="col-lg-6">{{item.property_appraised_value | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Appraisal ID</div>
      <div class="col-lg-6">{{item.appraisal_identifier}}</div>
    </div>
  </mat-card-content>
</mat-card>
