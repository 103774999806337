<mat-card *ngIf="reloaded">
  <mat-card-title>User List</mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="w-10">ID</th>
          <th class="w-15">Full Name</th>
          <th class="w-15">Role</th>
          <th class="w-15">Email</th>
          <th class="w-15">Cell Phone</th>
          <th class="w-15">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="editUser($event,user)" *ngFor="let user of users" class="hand-pointer">
          <td>{{user.id}}</td>
          <td>{{user.full_name}}</td>
          <td>{{user.getRolesString()}}</td>
          <td>{{user.email}}</td>
          <td>{{user.cell_phone}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>
