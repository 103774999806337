<div *ngIf="reloaded">
  <app-box *ngIf="!isContacts" [close]="true" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No contacts!</span>
    You can receive and send messages if you have contacts
    <a class="pr-blue-bold" routerLink="/account/view-contacts">(Account/Contacts)</a>.
  </app-box>

  <div *ngIf="isMessages">
    <app-view-segment-message *ngFor="let message of messages" (eventEmitted)="onEvent($event)" [message]="message" [type]="type"></app-view-segment-message>
  </div>

  <app-box *ngIf="!isMessages" [close]="true" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No messages!</span>
  </app-box>
</div>
