export class Help {
  public static helpKeys = {
    admin: 'admin',
    account: 'account',
    accountContacts: 'accountContacts',
    accountPhoto: 'accountPhoto',
    accountSettings: 'accountSettings',
    accountPassword: 'accountPassword',
    accountMLO: 'accountMLO',
    accountOrg: 'accountOrg',
    accountBranding: 'accountBranding',
    accountInvite: 'accountInvite',
    accountServices: 'accountServices',
    accountSubscriptions: 'accountSubscriptions',
    accountInvoices: 'accountInvoices',
    ezUploads: 'ezUploads',
    homeAbout: 'homeAbout',
    homeServices: 'homeServices',
    homeHighlights: 'homeHighlights',
    homeStep: 'homeStep',
    homeOfficer: 'homeOfficer',
    homeMap: 'homeMap',
    any: 'any',
    loanDashboard: 'loanDashboard',
    directory: 'directory',
    loanApp: 'loanApp',
    loansList: 'loansList',
    loMCRList: 'loMCRList',
    loansDashboard: 'loansDashboard',
    loansNew: 'loansNew',
    message: 'message',
    emails: 'emails',
    note: 'note',
    permission: 'permission',
    task: 'task',
    timeline: 'timeline',
    workflow: 'workflow',
    compliance: 'compliance',
    conditions: 'conditions',
    orgLoans: 'orgLoans'
  };
}
