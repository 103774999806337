`<!-- this segment applies only to construction or refinance loans -->
<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Loan Data'" [help]="true" [op]="'edit'">
      Note that inapplicable fields are purposely hidden.
      <ul>
        <li>
          <span class="fw-bold">Property acquired year.</span>
          For construction loan: calendar year when the site of the home to be built was purchased.
          For refinance loan: calendar year when property was acquired.
        </li>
        <li>
          <span class="fw-bold">Site original cost.</span>
          For construction loan: original cost of acquiring the site on which the home will be built.
          For refinance loan: original cost of home.
        </li>
        <li>
          <span class="fw-bold">Lot value.</span>
          For construction loan: market value of the site at the time of application for the loan.
        </li>
        <li>
          <span class="fw-bold">Construction improvement cost.</span>
          For construction loan: total cost of improvements to be made to the site.
        </li>
        <li>
          <span class="fw-bold">Home improvements cost.</span>
          Required only for Cash-Out/Home Improvement refinance.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="isConstructionLoan || isRefinanceLoan" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Property acquired date</div>
      <div class="col-lg-6">{{item.property_acquired_date | date}}</div>
    </div>

    <div *ngIf="isRefinanceLoan">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Refinance purpose</div>
        <div class="col-lg-6">{{item.getRefinancePurposeCode()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Refinance cash-out type</div>
        <div class="col-lg-6">{{item.getRefinanceCashOutType()}}</div>
      </div>
    </div>

    <div *ngIf="item.refinance_purpose_code_ex == 'HomeImprovement'">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Improvements status</div>
        <div class="col-lg-6">{{item.getImprovementStatus()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Home improvements cost</div>
        <div class="col-lg-6">{{item.home_improvements_cost | currency}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
