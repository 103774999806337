import {Component, Injector, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {FormGroup} from '@angular/forms';
import {CompBaseComponent} from "../../../comp-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Order} from "../../../../models/order";
import {AdminService} from "../../../../services/admin.service";
import {Payment} from "../../../../models/payment";

@Component({
  selector: 'app-admin-view-order',
  templateUrl: '../admin-view-order.component.html',
})
export class AdminViewOrderComponent extends CompBaseComponent implements OnInit {
  public route: ActivatedRoute;
  public router: Router;
  public orderId: number;
  public adminService: AdminService;
  pageTitle = 'View Order';
  order: Order;
  payments: Array<Payment>;
  form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.adminService = this.injector.get(AdminService);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);

    this.route.paramMap.subscribe((map) => {
      this.orderId = map['params']['id'];
      this.reload();
    });
  }

  createFormControls() {
  }

  createForm() {
    this.form = new FormGroup({});
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.adminService.findOrder(this.orderId)
      .then((order) => {
        this.order = order;
        return this.adminService.findPayments(this.order.id)
      }).then((payments) => {
        this.payments = payments;
        this.reloaded = true;
      })
  }
}
