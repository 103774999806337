<div [hidden]="!generating">
  <mat-spinner [diameter]="68" class="ms-5 mb-3"></mat-spinner>
</div>

<app-box *ngIf="reloaded&&!isDefaultLoanApp" [type]="'warning'" class="mt-2">
  You cannot generate the URLA document because there is
  <span class="fw-bold">no default loan application</span>. You can create a loan application and flag it as default from
  <strong>Loan Applications</strong>.
</app-box>

<div *ngIf="reloaded">
  <app-segment-header (eventEmitted)="onEvent($event)" [isCard]="false" [enable]="canUpdateDocument" [header]="'Borrower Grouping'" [help]="true" [op]="''" style="padding-left: 0 !important">
    The Borrower Grouping determines the specific URLA form(s) used for creating PDFs

    <h5>Grouping</h5>
    When there are two or more borrowers, the borrower grouping determines who share assets and liabilities.
    The first borrower in each group receives the complete URLA form, which includes assets and liabilities information for both borrowers.
    The other borrower receives the URLA Additional form.<br/>

    <h5>How to group</h5>
    The default borrower grouping is set at
    <b>Current Loan/Shared Data/Borrower Setup</b>.
    However, you have the option to override the default grouping by
    dragging-and-dropping borrowers from the borrower list into the designated groups.
  </app-segment-header>

  <app-dnd [borrowerObjTable]="borrowerObjTable" [borrowerGroupsIn]="borrowerGroupsIn" (borrowerGroupsOut)="onOutGroups($event)"></app-dnd>

  <h5>Select Borrowers</h5>

  <div *ngIf="isDefaultLoanApp" [hidden]="generating">
    <form [formGroup]="form" class="mt-0">
      <label formArrayName="borrowersSelected" *ngFor="let p of checkboxArray.controls; let i = index">
        <input type="checkbox" [formControlName]="i">
        {{borrowersByKeyName[i]['value']}} &nbsp;&nbsp;
      </label>
    </form>
  </div>

  <h5>Select Non-State Documents</h5>
  <app-select-document *ngFor="let fType of getUSForms()" (formSelectionChange)="onFormSelectionChange($event)" [formType]="fType.forms" [formTitle]="fType.value" [formID]="fType.ID"></app-select-document>

  <h5>Select State Documents</h5>
  <app-select-document *ngFor="let fType of getStateForms()" (formSelectionChange)="onFormSelectionChange($event)" [formType]="fType.forms" [formTitle]="fType.value" [formID]="fType.ID"></app-select-document>

  <div *ngIf="!getStateForms() || getStateForms().length === 0">
    <app-box class="w-50" [close]="false">No State Forms</app-box>
  </div>

  <div class="row mt-1">
    <div class="col">
      <button (click)="genMultipleDocs($event)" [disabled]="!isFormValid()" class="btn btn-lg btn-primary">Generate</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</div>
