<div *ngIf="reloaded" class="mt-2">
  <mat-card>
    <mat-card-title>
      {{title}}
    </mat-card-title>
    <mat-card-content>
      <app-view-worksheet-tabs (eventEmitted)="onEvent($event)" *ngIf="formType==='FEE_WORKSHEET'" [folderId]="folderId" [tran]="tran"></app-view-worksheet-tabs>
      <app-view-estimate-tabs (eventEmitted)="onEvent($event)" *ngIf="formType==='CFPB_LE'" [folderId]="folderId" [tran]="tran"></app-view-estimate-tabs>
      <app-generate-reqs (eventEmitted)="onEvent($event)" *ngIf="formType==='REQS'" [folderId]="folderId" [tran]="tran" [stateArr]="stateArr"></app-generate-reqs>
      <app-generate-mismo (eventEmitted)="onEvent($event)" *ngIf="formType==='MISMO'" [formType]="'MISMO'" [folderId]="folderId" [tran]="tran"></app-generate-mismo>
      <app-generate-urla (eventEmitted)="onEvent($event)" *ngIf="formType==='URLA'" [folderId]="folderId" [tran]="tran"></app-generate-urla>
      <app-edit-ecoa-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='ECOA'" [formType]="'ECOA'" [folderId]="folderId" [tran]="tran"></app-edit-ecoa-doc>
      <app-edit-credit-auth-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='CRAUTH'" [formType]="'CRAUTH'" [folderId]="folderId" [tran]="tran"></app-edit-credit-auth-doc>
      <app-edit-mbfa-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='BFA'" [formType]="'BFA'" [folderId]="folderId" [tran]="tran"></app-edit-mbfa-doc>
      <app-edit-anti-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='STEER'" [formType]="'STEER'" [folderId]="folderId" [tran]="tran"></app-edit-anti-doc>
      <app-edit-patriot-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='PATRIOT'" [formType]="'PATRIOT'" [folderId]="folderId" [tran]="tran"></app-edit-patriot-doc>
      <app-edit-co-tnb-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='CO_TNB'" [formType]="'CO_TNB'" [folderId]="folderId" [tran]="tran"></app-edit-co-tnb-doc>
      <app-edit-co-dual-doc (eventEmitted)="onEvent($event)" *ngIf="formType==='CO_DUAL'" [formType]="'CO_DUAL'" [folderId]="folderId" [tran]="tran"></app-edit-co-dual-doc>
    </mat-card-content>
  </mat-card>
</div>
