import {Component, Injector, OnInit} from '@angular/core';
import {ViewLoanAppTabComponent} from '../view-loan-app-tab.component';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-view-tools',
  templateUrl: './view-tools.component.html',
})
export class ViewToolsComponent extends ViewLoanAppTabComponent implements OnInit {
  public static currentSeg = globals.segment.tab4Instructions;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloadTab();
  }

  reloadSegment(): void {
    this.currentSeg = ViewToolsComponent.currentSeg;
  }

  setSeg(event: Event, segment: string): void {
    event.preventDefault();
    this.currentSeg = ViewToolsComponent.currentSeg = segment;
    setTimeout(() => {
      globals.scrollTop();
      this.setMenuOpen(false);
    }, 0);
  }
}
