import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Document} from '../models/document';

@Injectable()
export class SboxService {

  constructor(private httpService: HttpService) {
  }

  public getUserAnonDocs(): Promise<Array<Document>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`account/anon_docs`)
        .then((data) => {
          resolve(Document.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public deleteAnonDoc(docId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`account/delete_anon_doc/${docId}`)
        .then((data) => {
          resolve(true);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public moveDocToLoan(docId, tranId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`documents/move_to_tran/${docId}`, {tran_id: tranId})
        .then((data) => {
          resolve(true);
        })
        .catch(() => {
          reject(null);
        });
    });
  }
}
