import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {LoanEstimateBaseComponent} from "../loan-estimate-base.component";
import {FormUtil} from "../../../../form-util";

@Component({
  selector: 'app-edit-segment-estimate',
  templateUrl: './edit-segment-estimate.component.html',
})
export class EditSegmentEstimateComponent extends LoanEstimateBaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public inputArr: Array<any>;
  public form: FormGroup;
  ccArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  createFormControls(): void {
    this.ccArray = new FormArray<any>([]);
    this.sections.forEach((section) => {
      this.savedDoc[section] ||= [];
    })
    this.inputArr = this.amendObjectProperties(this.savedDoc[this.section], this.loanEstimateStock[this.section]);

    for (let i = 0; i < this.inputArr.length; i++) {
      let val = this.inputArr[i]['value']
      let not_initialized = [null,""]
      if (this.inputArr[i]["type"] === 'date') {
        val = (val && val.length >= 10) ? moment(val, 'MM/DD/YYYY').toDate() : moment().toDate();
      }
      if (this.inputArr[i]["key"] == "loan_identifier" && not_initialized.includes(val)) {
        val = this.txSummary.lenderCaseNo
      }
      else if (this.inputArr[i]["key"] == "loan_amount" && not_initialized.includes(val)) {
        val = this.txSummary.loanAmount;
      }
      else if (this.inputArr[i]["key"] == "purchase_price" && not_initialized.includes(val)) {
        val = this.txSummary.propertyValue;
      }
      else if (this.inputArr[i]["key"] == "loan_interest_rate" && not_initialized.includes(val)) {
        val = this.txSummary.interestRate;
      }
      else if (this.inputArr[i]["key"] == "loan_duration_months" && not_initialized.includes(val)) {
        val = this.txSummary.periodsNo;
      }
      else if (this.inputArr[i]["key"] == "construction_improvements_cost" && not_initialized.includes(val)) {
        val = this.txSummary.homeImprovementCost;
      }
      else if (this.inputArr[i]["key"] == "loan_product" && not_initialized.includes(val)) {
        val = this.txSummary.loanProduct;
      }
      else if (this.inputArr[i]["key"] == "loan_io_months" && not_initialized.includes(val)) {
        val = this.txSummary.interestOnlyMonths;
      }
      else if (this.inputArr[i]["key"] == "loan_rate_fixed_months" && not_initialized.includes(val)) {
        val = this.txSummary.fixedInterestMonths;
      }
      else if (this.inputArr[i]["key"] == "loan_subsequent_adjustment_months_no" && not_initialized.includes(val)) {
        val = this.txSummary.subsequentAdjustmentMonthsNo;
      }
      this.ccArray.push(new FormControl(val));
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      ccArray: this.ccArray
    });
  }

  amendObjectProperties(target: Array<any>, source: Array<any>): Array<any> {
    if (!target) {
      target = source;
      return target;
    }
    source.forEach((elt) => {
      const found = target.find(elt2 => elt2 && (elt2.key === elt.key));
      if (!found) {
        target.push(elt);
      }
    })
    return target;
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  async genLoanEstimate(): Promise<true> {
    if (!this.form.valid) {
      return;
    }
    const formData = this.savedDoc;
    this.ccArray.controls.forEach((ctl, index: number) => {
      if (this.inputArr[index]["type"] === 'date') {
        this.inputArr[index]['value'] = ctl.value ? moment(ctl.value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
      } else {
        this.inputArr[index]['value'] = ctl.value;
      }
    })
    try {
      await this.saveForm(formData);
      this.eventService.eventEmitter.emit({type: 'Reload'});
      //FormUtil.genSuccessMessage(this.sharedService, `Loan Estimate ${JSON.stringify(formData)} successfully saved`);
      FormUtil.genSuccessMessage(this.sharedService, `Loan Estimate successfully saved`);
      return Promise.resolve(true);
    } catch (data) {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to save form', data);
      return Promise.reject(false);
    }
  }
}
