<div *ngIf="reloaded">
  <button (click)="back()" class="mb-3 btn btn-secondary" type="button">
    <i class="icon fa fa-chevron-left"></i>&nbsp;Back
  </button>

  <ul class="nav nav-tabs" id="dossier_tabs">
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.general)" [ngClass]="{active:tabKey===tabKeys.general}"
        [attr.aria-selected]="tabKey===tabKeys.general" class="nav-link" data-toggle="tab" id="general_tab">
        General
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.transaction_details)" [ngClass]="{active:tabKey===tabKeys.transaction_details}"
        [attr.aria-selected]="tabKey===tabKeys.transaction_details" class="nav-link" data-toggle="tab" id="transaction_details_tab">
        Transaction Details
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.loan_terms)" [ngClass]="{active:tabKey===tabKeys.loan_terms}"
        [attr.aria-selected]="tabKey===tabKeys.loan_terms" class="nav-link" data-toggle="tab" id="loan_terms_tab">
        Loan Details
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.lender)" [ngClass]="{active:tabKey===tabKeys.lender}"
         [attr.aria-selected]="tabKey===tabKeys.lender" class="nav-link" data-toggle="tab" id="lender_tab">
        Loan Details
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.other_payments)" [ngClass]="{active:tabKey===tabKeys.other_payments}"
        [attr.aria-selected]="tabKey===tabKeys.other_payments" class="nav-link" data-toggle="tab" id="other_payments_tab">
        Other Payments
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.title)" [ngClass]="{active:tabKey===tabKeys.title}"
        [attr.aria-selected]="tabKey===tabKeys.title" class="nav-link" data-toggle="tab" id="title_tab">
        Title
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.taxes_and_fees)" [ngClass]="{active:tabKey===tabKeys.taxes_and_fees}"
        [attr.aria-selected]="tabKey===tabKeys.taxes_and_fees" class="nav-link" data-toggle="tab" id="taxes_and_fees_tab">
        Taxes & Fees
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.insurance)" [ngClass]="{active:tabKey===tabKeys.insurance}"
        [attr.aria-selected]="tabKey===tabKeys.insurance" class="nav-link" data-toggle="tab" id="insurance_tab">
        Insurance
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.escrow)" [ngClass]="{active:tabKey===tabKeys.escrow}"
         [attr.aria-selected]="tabKey===tabKeys.escrow" class="nav-link" data-toggle="tab" id="escrow_tab">
        Escrow
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.origination)" [ngClass]="{active:tabKey===tabKeys.origination}"
        [attr.aria-selected]="tabKey===tabKeys.origination" class="nav-link" data-toggle="tab" id="origination_tab">
        Origination
      </a>
    </li>
    <li class="nav-item">
      <a (click)="setTabKey($event,tabKeys.other)" [ngClass]="{active:tabKey===tabKeys.other}"
        [attr.aria-selected]="tabKey===tabKeys.other" class="nav-link" data-toggle="tab" id="other_tab">
        Other Fees
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':tabKey===tabKeys.general}" aria-labelledby="general_tab" class="tab-pane fade" id="general" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.general" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.transaction_details}" aria-labelledby="transaction_details_tab" class="tab-pane fade" id="transaction_details" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.transaction_details" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.loan_terms}" aria-labelledby="loan_terms_tab" class="tab-pane fade" id="loan_terms" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.loan_terms" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.lender}" aria-labelledby="lender_tab" class="tab-pane fade" id="lender" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.lender" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.other_payments}" aria-labelledby="other_payments_tab" class="tab-pane fade" id="other_payments" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.other_payments" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.insurance}" aria-labelledby="insurance_tab" class="tab-pane fade" id="insurance" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.insurance" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.escrow}" aria-labelledby="escrow_tab" class="tab-pane fade" id="escrow" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.escrow" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.title}" aria-labelledby="title_tab" class="tab-pane fade" id="title" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.title" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.taxes_and_fees}" aria-labelledby="taxes_and_fees_tab" class="tab-pane fade" id="taxes_and_fees" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.taxes_and_fees" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.other}" aria-labelledby="other_tab" class="tab-pane fade" id="other" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.other" [tran]="tran"></app-view-worksheet>
    </div>
    <div [ngClass]="{'show active':tabKey===tabKeys.origination}" aria-labelledby="origination_tab" class="tab-pane fade" id="origination" role="tabpanel">
      <app-view-worksheet (eventEmitted)="onEvent($event)" [section]="tabKeys.origination" [tran]="tran"></app-view-worksheet>
    </div>
  </div>
</div>
