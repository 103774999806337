import {Component, Injector, OnInit} from '@angular/core';
import {SupportComponent} from '../support/support.component';

@Component({
  selector: 'app-edit-segment-support',
  templateUrl: '../support/edit-segment-support.component.html',
})
export class EditSegmentSupportComponent extends SupportComponent implements OnInit {
  op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
