<div class="mb-1">
  <mat-card-title>
    <div class="d-flex my-auto">
      <div>
        <button (click)="add()" *ngIf="canUpdateTransaction" mat-mini-fab title="Create folder">
          <mat-icon aria-label="Add">add</mat-icon>
        </button>&nbsp;
      </div>
      <div *ngIf="canUpdateTransaction" (click)="edit()" mat-mini-fab class="my-auto">
        <button (click)="add()" *ngIf="canUpdateTransaction" mat-mini-fab title="Select folders">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>&nbsp;
      </div>
      <div class="my-auto">
        {{folderGroup.name}}&nbsp;
      </div>
      <div *ngIf="folderGroup.selected" style="margin-top: 6px">
        <button [hidden]="!collapsed" class="btn btn-sm text-primary" (click)="toggle()"><i class="fas fa-eye"></i>&nbsp;Show Folders</button>
        <button [hidden]="collapsed" class="btn btn-sm text-primary" (click)="toggle()"><i class="fas fa-eye-slash"></i>&nbsp;Hide Folders</button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content [hidden]="collapsed">
    <app-add-folder (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'" [group_id]="folderGroup.id" [tran]="tran"></app-add-folder>

    <div *ngFor="let folder of folders">
      <app-folder (eventEmitted)="onEvent($event)" [folder]="folder" [tran]="tran"></app-folder>
    </div>
  </mat-card-content>
</div>
