<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Account Photo
  </mat-card-title>

  <mat-card-content>
    <p>
      To upload a photo:
    </p>

    <ul>
      <li>
        Click the Upload button.
      </li>
      <li>
        Select a portrait image from your computer's file system.
      </li>
      <li>
        Click Upload.
      </li>
      <li>
        To replace an existing photo, delete the current photo and upload a new one.
      </li>
    </ul>
    <p>
      For best results upload a rectangular photo.
    </p>
  </mat-card-content>
</mat-card>
