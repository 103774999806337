<mat-card *ngIf="reloaded" class="mt-1 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'View Order'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Order ID</div>
          <div class="col-lg-8">{{order.id}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">User Account ID</div>
          <div class="col-lg-8">{{order.user_account_id}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">User Full Name</div>
          <div class="col-lg-8">{{order.user_full_name}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Granted by User Id</div>
          <div class="col-lg-8">{{order.granted_by_user_id}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Granted by Name</div>
          <div class="col-lg-8">{{order.granted_by_name}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Subscription Type</div>
          <div class="col-lg-8">{{order.getSubType()}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Paid</div>
          <div class="col-lg-8">{{order.paid | booleanToString}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Months Number</div>
          <div class="col-lg-8">{{order.months_no}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Starts on</div>
          <div class="col-lg-8">{{order.starts_on}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Expires on</div>
          <div class="col-lg-8">{{order.expires_on}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Created at</div>
          <div class="col-lg-8">{{order.created_at}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Updated at</div>
          <div class="col-lg-8">{{order.updated_at}}</div>
        </div>
      </div>
    </div>


    <div>
      <h4>Order Payments</h4>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>ID</th>
            <th>Order ID</th>
            <th>Auth.Net ID</th>
            <th>Amount</th>
            <th>Paid on</th>
            <th>Error No</th>
            <th>Error Description</th>
            <th>Created at</th>
            <th>Updated at</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let payment of payments">
            <td>{{payment.id}}</td>
            <td>{{payment.order_id}}</td>
            <td>{{payment.authnet_transaction_id}}</td>
            <td>{{payment.amount}}</td>
            <td>{{payment.paid_on}}</td>
            <td>{{payment.error_no}}</td>
            <td>{{payment.error_description}}</td>
            <td>{{payment.created_at | date:'medium'}}</td>
            <td>{{payment.updated_at | date:'medium'}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </mat-card-content>
</mat-card>
