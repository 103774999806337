<mat-card *ngIf="reloaded">
  <mat-card-title>Tran List</mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Owner</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="editTran($event,tran)" *ngFor="let tran of trans" class="hand-pointer">
          <td>{{tran.id}}</td>
          <td>{{tran.name}}</td>
          <td>{{tran.description}}</td>
          <td>{{tran.owner_full_name}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>
