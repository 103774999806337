import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {TranService} from './tran.service';

@Injectable()
export class TraceService {

  constructor(private httpService: HttpService,
              private tranService: TranService) {
  }

  public updateTrace(traceId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`traces/${traceId}`, payload)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public hideTrace(traceId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`traces/hide_trace/${traceId}`, {})
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public showTrace(traceId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`traces/show_trace/${traceId}`, {})
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }
}
