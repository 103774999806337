import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {Folder} from '../../../models/folder';

@Component({
  selector: 'app-view-folder',
  templateUrl: './view-folder.component.html',
  styleUrls: ['./view-folder.component.scss']
})
export class ViewFolderComponent extends CompBaseComponent implements OnInit {
  @Input() folder: Folder;
  public displayFolder: boolean;
  public docs: Array<Document>;
  public mode: string;
  public actionUrl: string;
  public canUpdateTransaction: boolean;
  public isHoverDiv: boolean;
  public isCutAndPaste: boolean;
  public maxDispChars: number;
  public maxDescChars: number;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    const maxChars = this.isMobile ? 64 : 128;
    this.maxDispChars = Math.min(maxChars, this.folder.display.length);
    this.maxDescChars = this.isMobile ? 0 : Math.min(this.maxDispChars + this.folder.description.length, maxChars) - this.maxDispChars;
    this.displayFolder = this.folder.selected;
    this.actionUrl = `trans/file_upload?transaction_item_id=${this.folder.id}&tran_id=${this.tran.id}`;
    this.mode = 'View';
    let docs;
    this.tranService.getTranDocuments(this.tran.id)
      .then((documents) => {
        if (this.folder.id === 'US') {
          docs = documents.filter((elt) => !elt.transaction_item_id || elt.transaction_item_id === 'US');
        } else {
          docs = documents.filter((elt) => elt.transaction_item_id === this.folder.id);
        }
        // sort by date-time descending
        docs.sort((x, y) => {
          if (x.updated_at.getTime() === y.updated_at.getTime()) {
            return 0;
          }
          if (x.updated_at.getTime() <= y.updated_at.getTime()) {
            return 1;
          }
          return -1;
        });
        this.docs = docs;
      });
  }

  upload(): void {
    this.mode = 'Upload';
  }

  cancel(): void {
    this.mode = 'View';
  }

  edit(): void {
    if (this.canUpdateTransaction) {
      super.edit();
    }
  }

  onUploadComplete(): void {
    this.tranService.getCurrentTran(true)
      .then(() => {
        this.mode = 'View';
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  updateStatus(event: any, status: number): void {
    event.preventDefault();
    this.tranService.updateTransactionItemStatus(this.tran.id, this.folder.id, status)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Folder status successfully updated');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update folder status', data);
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  onEnter(event: any): void {
    this.isHoverDiv = true;
    // comment: enable cut-and-paste if can update transaction
    this.isCutAndPaste = this.canUpdateTransaction && CompBaseComponent.fromDocId && (CompBaseComponent.fromDocId > 0) && (this.folder.id !== CompBaseComponent.fromFolderId);
  }

  onLeave(event: any): void {
    this.isHoverDiv = false;
    this.isCutAndPaste = false;
  }

  paste(event: any): void {
    event.preventDefault();

    this.docService.moveDocument(+CompBaseComponent.fromDocId, this.folder.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Document successfully moved');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to move document', data);
        this.eventEmitted.emit({type: 'Reload'});
      });
  }
}
