import {ModelBase} from './model-base';

export class Condition extends ModelBase {
  id: number;
  name: string;
  description: string;
  comments: string;
  done: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'done') {
        this[prop] = super.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Condition> {
    const result = [];
    for (const obj of arr) {
      const item = new Condition(obj);
      result.push(item);
    }

    return result;
  }

  toServerPayload(): any {
    return {
      id: this.toNumber(this.id),
      name: this.name,
      description: this.description,
      comments: this.comments,
      done: this.done
    };
  }
}
