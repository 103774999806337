import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Document} from '../models/document';
import {TranService} from './tran.service';

@Injectable()
export class DocumentService {

  constructor(private httpService: HttpService, private tranService: TranService) {
  }

  generateMultipleDocs(doc_id: number, payload: any) {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`documents/gen_docs/${doc_id}`, payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(doc);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  generateMISMO(docId: number, payload: any) {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`documents/gen_mismo/${docId}`, payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(doc);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  generateCFADoc(docId: number): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`documents/gen_cfa/${docId}`, [], false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(doc);
        })
        .catch((data) => {
          reject(false);
        });
    });
  }

  createDocument(payload: any) {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post('documents', payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(doc);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  saveForm(payload: any): Promise<true> {
    return new Promise((resolve, reject) => {
      this.httpService.post('documents/save_form', payload, false)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  generatePatriot(payload: any): Promise<true> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post('documents/gen_patriot', payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  updateDocument(docId: number, payload: any): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.put('documents/' + docId, payload)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((data) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // the loan application calling this method is doing a force get ...
  updateDocumentComments(documentId, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const comments = typeof (payload) === 'object' ? payload : {};
      this.httpService.put('documents/' + documentId, {comments: comments})
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(false);
        });
    });
  }

  deleteDocument(documentId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('documents/' + documentId)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  copyDocument(documentId): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post('documents/copy_document/' + documentId, null)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((data) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  moveDocument(documentId, targetFolderId): Promise<boolean> {
    const payload = {icn_id: targetFolderId};
    return new Promise((resolve, reject) => {
      this.httpService.put('documents/update_icn/' + documentId, payload)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
