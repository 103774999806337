import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {InvoiceService} from '../../../../services/invoice.service';
import {Invoice} from '../../../../models/invoice';
import {UserAccount} from "../../../../models/user-account";
import {ModelBase} from "../../../../models/model-base";

@Component({
  selector: 'app-view-invoices',
  templateUrl: './view-invoices.component.html',
})
export class ViewInvoicesComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Account Status/Invoices';
  private invoiceService: InvoiceService;
  public invoices: Array<Invoice>;
  public user: User;
  public userAccount: UserAccount;
  public uaStatuses = ModelBase.UAB_STATUS;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.invoiceService = this.injector.get(InvoiceService);
    this.reload();
    this.reloadOnSync();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountInvoices
      });
    }, 0);
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.userService.refreshUser()
      .then(() => {
        this.user = this.userService.getUserSync();
        this.userAccount = this.user.user_account;
        this.invoiceService.getUserInvoices(this.user.id)
          .then((invoices) => {
            this.invoices = invoices;
            this.reloaded = true;
          });
      });
  }
}
