import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoanData} from '../../../../models/loan-data';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-loan-data',
  templateUrl: './edit-segment-loan-data.component.html',
})
export class EditSegmentLoanDataComponent extends CompBaseComponent implements OnInit {
  @Input() public item: LoanData;
  public readonly refinance_cash_out_types = LoanData.refinance_cash_out_types;
  public readonly refinance_purpose_codes_ex = LoanData.refinance_purpose_codes_ex;
  public readonly improvements_statuses_ex = LoanData.improvements_statuses_ex;
  public isConstructionLoan: boolean;
  public isRefinanceLoan: boolean;
  public minDate = globals.date.minEmpStartDate;
  public maxDate = globals.date.maxEmpEndDate;

  // form and form controls
  form: FormGroup;
  lot_acquired_year: FormControl;
  property_acquired_date: FormControl;
  refinance_purpose_code_ex: FormControl;
  refinance_cash_out_type: FormControl;
  improvements_made_ex: FormControl;
  home_improvements_cost: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'loan_data';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
    this.reload();
  }

  createFormControls(item): void {
    const yearNow = (new Date()).getFullYear();
    this.lot_acquired_year = new FormControl(item.lot_acquired_year,
      Validators.compose([Validators.min(globals.date.minYearBuilt), Validators.max(globals.date.maxYearBuilt)]));
    this.refinance_purpose_code_ex = new FormControl(item.refinance_purpose_code_ex);
    this.refinance_cash_out_type = new FormControl(item.refinance_cash_out_type);
    this.improvements_made_ex = new FormControl(item.improvements_made_ex);
    this.home_improvements_cost = new FormControl(item.home_improvements_cost);
    this.property_acquired_date = new FormControl(item.property_acquired_date);
  }

  createForm(): void {
    this.form = new FormGroup({
      lot_acquired_year: this.lot_acquired_year,
      refinance_purpose_code_ex: this.refinance_purpose_code_ex,
      refinance_cash_out_type: this.refinance_cash_out_type,
      improvements_made_ex: this.improvements_made_ex,
      home_improvements_cost: this.home_improvements_cost,
      property_acquired_date: this.property_acquired_date
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new LoanData(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();
    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    this.isConstructionLoan = this.analyzerService.isConstructionLoan(this.doc.recursive_attributes);
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
  }
}
