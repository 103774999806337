<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Access Control</mat-card-title>

  <mat-card-content>
    <p>
      POLOS protects you from some inadvertent access errors. For example:
    </p>

    <ul>
      <li>
        Some functions are only available to the loan owner (such as assigning access).
      </li>
      <li>
        Some functions are only available to loan officers.
      </li>
      <li>
        Some functions are only available to borrowers and loan officers.
      </li>
      <li>
        Real estate agents can only see the Timeline and communicate through system and text messages.
      </li>
      <li>
        Update access to the loan (not the loan application) can only be granted to loan officers or processors.
      </li>
    </ul>

    <h5>Loan Access</h5>

    <p>
      Only the loan owner can grant access to loan resources (through this facility).
    </p>

    <strong>Grant Loan Access</strong>
    <ul>
      <li>Click the Add (plus) button next to the loan name.</li>
      <li>Select a contact from the drop-down list.</li>
      <li>Choose Read or Read/Update Access.</li>
      <li>Check the All Resources checkbox, if you want to grant the same access to all of the loan resources.</li>
      <li>Click Grant.</li>
    </ul>

    <strong>Modify Loan Access</strong>
    <ul>
      <li>Click the loan access line.</li>
      <li>Change access.</li>
      <li>Check the All Resources checkbox, if you want to grant the same access to all of the loan resources.</li>
      <li>Click Update.</li>
    </ul>

    <strong>Delete Loan Access</strong>
    <ul>
      <li>Click the Delete (trash) button on the loan access line.</li>
    </ul>

    <strong>About Read Access</strong>
    <p>
      With Read Access, a user can view the resource. For example with
      Read Access a user can:
    </p>
    <ul>
      <li>Display loan properties (name, description).</li>
      <li>View the loan application.</li>
      <li>List documents and images.</li>
      <li>Upload documents or images to any loan folder.</li>
      <li>View task status and history (loan officer/processor only).</li>
      <li>View Timeline.</li>
    </ul>

    <strong>Read/Update Access</strong>
    <p>
      With Read/Update Access the user has Read Access to the resource (see above) and additionally can:
    </p>
    <ul>
      <li>Edit loan application (loan officer/processor/borrower).</li>
      <li>Edit loan record (loan officer/processor only).</li>
      <li>Select or create loan folders (loan officer/processor only).</li>
      <li>Edit any loan folder name (loan officer/processor only).</li>
      <li>Modify loan folder status (loan officer/processor only).</li>
      <li>Delete loan folders (loan officer/processor only).</li>
      <li>Modify the workflow (loan officer/processor only).</li>
      <li>Update the workflow tasks (loan officer/processor only).</li>
    </ul>

    <p>
      As a rule, you should carefully grant Read/Update Access to the loan.
    </p>

    <h5>Loan Application, Document, and Image Access</h5>

    <p>
      The procedure for granting access to the loan application, a document, or an image is identical.
    </p>

    <strong>Grant Loan Application, Document, or Image Access</strong>
    <ul>
      <li>Click the Add (plus) button next to the resource name.</li>
      <li>Select a contact from the drop-down list.</li>
      <li>Choose the appropriate access and click Grant.</li>
    </ul>

    <strong>Modify Document Access</strong>
    <ul>
      <li>Click the relevant access line.</li>
      <li>Select new access and click Update</li>
    </ul>

    <strong>Delete Document Access</strong>
    <ul>
      <li>Click the Delete (trash) button on the document access line.</li>
    </ul>

    <h5>Timeline Access</h5>

    <p>
      The main purpose of the Timeline access function is to assign real estate agents limited access to the workflow Timeline.
      The procedure for granting, modifying, or deleting workflow access is identical to any of the loan resources.
    </p>

    <strong>Read Access</strong>
    <p>
      A user with Read Access can view the workflow timeline.
    </p>

    <strong>Read/Update Access</strong>
    <p>
      A user with Read/Update Access to the loan can modify the workflow and hence change the Timeline.
    </p>

  </mat-card-content>
</mat-card>
