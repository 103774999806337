<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Product Characteristics'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <span class="fw-bold">FYI.</span> Fannie Mae DU accepts only fully amortizing (no negative amortization) loan payment schedule.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pb-1 pt-1  border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is there a prepayment penalty?</div>
      <div class="col-lg-6" *ngIf="item.prepayment_penalty_indicator!==null">{{item.prepayment_penalty_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.prepayment_penalty_indicator===null">(no response)</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray" *ngIf="item.prepayment_penalty_indicator===true">
      <div class="col-lg-6 fw-bold">Prepayment penalty expiration months count</div>
      <div class="col-lg-6">{{item.prepayment_months_no}}</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this an interest only loan?</div>
      <div class="col-lg-6" *ngIf="item.interest_only!==null">{{item.interest_only | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.interest_only===null">(no response)</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray" *ngIf="item.interest_only===true">
      <div class="col-lg-6 fw-bold">Interest only term month count</div>
      <div class="col-lg-6">{{item.interest_only_months_no}}</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this a negative amortization loan?</div>
      <div class="col-lg-6" *ngIf="item.negative_amortization_indicator!==null">{{item.negative_amortization_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.negative_amortization_indicator===null">(no response)</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray" *ngIf="item.negative_amortization_indicator===true">
      <div class="col-lg-6 fw-bold">Negative amortization type</div>
      <div class="col-lg-6">{{item.getNegativeAmortizationType()}}</div>
    </div>

    <div class="row pb-1 pt-1  border-bottom hover-gray">
      <div class="col-lg-6">
        <span class="fw-bold">Initial fixed-period month count</span> (if applicable)
      </div>
      <div class="col-lg-6">{{item.fixed_period_months_no}}</div>
    </div>
  </mat-card-content>
</mat-card>
