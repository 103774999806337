import {ModelBase} from './model-base';

export class Income extends ModelBase {
  // the list of all income sources
  public static incomes = [
    {key: 'AccessoryUnitIincome', value: 'Accessory Unit Income'},
    {key: 'Alimony', value: 'Alimony'},
    {key: 'AutomobileAllowance', value: 'Automobile Allowance'}, // ent
    {key: 'Base', value: 'Base'}, // base
    {key: 'BoarderIncome', value: 'Boarder Income'},
    {key: 'Bonus', value: 'Bonus'},
    {key: 'BorrowerEstimatedTotalMonthlyIncome', value: 'Borrower Estimated Total Monthly Income'},
    {key: 'CapitalGains', value: 'Capital Gains'},
    {key: 'ChildSupport', value: 'Child Support'},
    {key: 'Commissions', value: 'Commissions'}, // emp commissions
    {key: 'ContractBasis', value: 'Contract Basis'},
    {key: 'DefinedContributionPlan', value: 'Defined Contribution Plan'},
    {key: 'Disability', value: 'Disability'},
    {key: 'DividendsInterest', value: 'Dividends Interest'},
    {key: 'EmploymentRelatedAccount', value: 'Employment Related Account'},
    {key: 'FosterCare', value: 'Foster Care'},
    {key: 'HousingAllowance', value: 'Housing Allowance'}, // ent
    {key: 'HousingChoiceVoucherProgram', value: 'Housing Choice Voucher Program'},
    {key: 'MilitaryBasePay', value: 'Military Base Pay'}, // base
    {key: 'MilitaryClothesAllowance', value: 'Military Clothes Allowance'}, // ent
    {key: 'MilitaryCombatPay', value: 'Military Combat Pay'}, // emp base
    {key: 'MilitaryFlightPay', value: 'Military Flight Pay'}, // emp base
    {key: 'MilitaryHazardPay', value: 'Military Hazard Pay'}, // emp base
    {key: 'MilitaryOverseasPay', value: 'Military Overseas Pay'}, // base
    {key: 'MilitaryPropPay', value: 'Military Prop Pay'}, // emp base
    {key: 'MilitaryQuartersAllowance', value: 'Military Quarters Allowance'}, // emp ent
    {key: 'MilitaryRationsAllowance', value: 'Military Rations Allowance'}, // emp ent
    {key: 'MilitaryVariableHousingAllowance', value: 'Military Variable Housing Allowance'}, // emp ent
    {key: 'MiscellaneousIncome', value: 'Miscellaneous Income'},
    {key: 'MortgageCreditCertificate', value: 'Mortgage Credit Certificate'},
    {key: 'MortgageDifferential', value: 'Mortgage Differential'},
    {key: 'NetRentalIncome', value: 'Net Rental Income'},
    {key: 'NonBorrowerContribution', value: 'Non-Borrower Contribution'},
    {key: 'NonBorrowerHouseholdIncome', value: 'Non-Borrower Household Income'},
    {key: 'NotesReceivableInstallment', value: 'Notes Receivable Installment'},
    {key: 'Other', value: 'Other'},
    {key: 'Overtime', value: 'Overtime'}, // emp overtime
    {key: 'Pension', value: 'Pension'},
    {key: 'ProposedGrossRentForSubjectProperty', value: 'Proposed Gross Rent For Subject Property'},
    {key: 'PublicAssistance', value: 'Public Assistance'},
    {key: 'RealEstateOwnedGrossRentalIncome', value: 'Real Estate Owned Gross Rental Income'},
    {key: 'Royalties', value: 'Royalties'},
    {key: 'SelfEmploymentIncome', value: 'Self Employment Income'},
    {key: 'SelfEmploymentLoss', value: 'Self Employment Loss'},
    {key: 'SeparateMaintenance', value: 'Separate Maintenance'},
    {key: 'SocialSecurity', value: 'Social Security'},
    {key: 'SubjectPropertyNetCashFlow', value: 'Subject Property Net Cash Flow'},
    {key: 'TemporaryLeave', value: 'Temporary Leave'},
    {key: 'TipIncome', value: 'Tip Income'},
    {key: 'TrailingCoBorrowerIncome', value: 'Trailing Co-Borrower Income'},
    {key: 'Trust', value: 'Trust'},
    {key: 'Unemployment', value: 'Unemployment'},
    {key: 'VABenefitsNonEducational', value: 'VA Benefits Non-Educational'},
    {key: 'WorkersCompensation', value: 'Workers Compensation'},
  ];

  // The list of other income sources. It relates to non-employment income.
  public static other_income_sources = [
    {key: 'Alimony', value: 'Alimony'},
    {key: 'AutomobileAllowance', value: 'Automobile Allowance'},
    {key: 'BoarderIncome', value: 'Boarder Income'},
    {key: 'CapitalGains', value: 'Capital Gains'},
    {key: 'ChildSupport', value: 'Child Support'},
    {key: 'Disability', value: 'Disability'},
    {key: 'FosterCare', value: 'Foster Care'},
    {key: 'HousingAllowance', value: 'Housing Allowance'},
    {key: 'DividendsInterest', value: 'Dividends Interest'},
    {key: 'MilitaryClothesAllowance', value: 'Military Clothes Allowance'},
    {key: 'MilitaryQuartersAllowance', value: 'Military Quarters Allowance'},
    {key: 'MilitaryRationsAllowance', value: 'Military Rations Allowance'},
    {key: 'MilitaryVariableHousingAllowance', value: 'Military Variable Housing Allowance'},
    {key: 'MortgageCreditCertificate', value: 'Mortgage Credit Certificate'},
    {key: 'MortgageDifferential', value: 'Mortgage Differential'},
    {key: 'NotesReceivableInstallment', value: 'Notes Receivable Installment'},
    {key: 'Other', value: 'Other'},
    {key: 'PublicAssistance', value: 'Public Assistance'},
    {key: 'Pension', value: 'Pension'},
    {key: 'Royalties', value: 'Royalties'},
    {key: 'SeparateMaintenance', value: 'Separate Maintenance'},
    {key: 'SocialSecurity', value: 'Social Security'},
    {key: 'Trust', value: 'Trust'},
    {key: 'Unemployment', value: 'Unemployment'},
    {key: 'VABenefitsNonEducational', value: 'VA Benefits Non-Educational'},
  ];

  // The list of all employment-related income
  public static employment_sources = [
    {key: 'AccessoryUnitIincome', value: 'Accessory Unit Income'},
    {key: 'Base', value: 'Base'},
    {key: 'Bonus', value: 'Bonus'},
    // {key: 'BorrowerEstimatedTotalMonthlyIncome', value: 'BorrowerEstimatedTotalMonthlyIncome'},
    {key: 'Commissions', value: 'Commissions'},
    {key: 'ContractBasis', value: 'Contract Basis'},
    {key: 'DefinedContributionPlan', value: 'Defined Contribution Plan'},
    {key: 'EmploymentRelatedAccount', value: 'Employment Related Account'},
    {key: 'HousingChoiceVoucherProgram', value: 'Housing Choice Voucher Program'},
    {key: 'MilitaryBasePay', value: 'Military Base Pay'},
    // {key: 'MilitaryClothesAllowance', value: 'Military Clothes Allowance'},
    {key: 'MilitaryCombatPay', value: 'Military Combat Pay'},
    {key: 'MilitaryFlightPay', value: 'Military Flight Pay'},
    {key: 'MilitaryHazardPay', value: 'Military Hazard Pay'},
    {key: 'MilitaryOverseasPay', value: 'Military Overseas Pay'},
    {key: 'MilitaryPropPay', value: 'Military Prop Pay'},
    // {key: 'MilitaryQuartersAllowance', value: 'Military Quarters Allowance'},
    // {key: 'MilitaryRationsAllowance', value: 'Military Rations Allowance'},
    // {key: 'MilitaryVariableHousingAllowance', value: 'Military Variable Housing Allowance'},
    {key: 'MiscellaneousIncome', value: 'Miscellaneous Income'},
    // {key: 'NetRentalIncome', value: 'NetRentalIncome'},
    // {key: 'NonBorrowerContribution', value: 'NonBorrowerContribution'},
    // {key: 'NonBorrowerHouseholdIncome', value: 'NonBorrowerHouseholdIncome'},
    {key: 'Overtime', value: 'Overtime'},
    // {key: 'ProposedGrossRentForSubjectProperty', value: 'ProposedGrossRentForSubjectProperty'},
    // {key: 'RealEstateOwnedGrossRentalIncome', value: 'RealEstateOwnedGrossRentalIncome'},
    // {key: 'SelfEmploymentIncome', value: 'SelfEmploymentIncome'},
    // {key: 'SelfEmploymentLoss', value: 'SelfEmploymentLoss'},
    // {key: 'SubjectPropertyNetCashFlow', value: 'SubjectPropertyNetCashFlow'},
    {key: 'TemporaryLeave', value: 'Temporary Leave'},
    {key: 'TipIncome', value: 'Tip Income'},
    // {key: 'TrailingCoBorrowerIncome', value: 'TrailingCoBorrowerIncome'},
    {key: 'WorkersCompensation', value: 'Workers Compensation'},
  ];

  public static income_types = [
    {key: 'P', value: 'Primary Employment'},
    {key: 'S', value: 'Secondary Employment'},
    {key: 'O', value: 'Other'},
  ];

  index: number;
  amount: string; // decimal (15 char)
  code_ex: string;
  income_type: string;
  other_description: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'amount') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Income> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Income(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getIncome(): string {
    const found = Income.incomes.find((elt) => elt.key === this.code_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      code_ex: this.code_ex,
      amount: this.toServerDecimal(this.amount),
      income_type: this.income_type,
      other_description: this.other_description
    };
  }
}
