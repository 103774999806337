import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Organization} from '../../../../models/organization';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-view-segment-user-org',
  templateUrl: './view-segment-user-org.component.html',
})
export class ViewSegmentUserOrgComponent extends CompBaseComponent implements OnInit {
  @Input() item: Organization;
  public user: User;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();

  }

  editOrg(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }
}
