import {ModelBase} from './model-base';

export class Folder extends ModelBase {
  description: string;
  display: string;
  dtype: number;
  id: string;
  name: string;
  selected: boolean;
  status: number;
  stock: boolean;
  // generated properties
  groupName: string;
  groupId: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
    this.display = this.display && this.display.length ? this.display : this.name;
  }

  static deserializeArray(arr: Array<any>): Array<Folder> {
    const result = [];
    for (const obj of arr) {
      result.push(new Folder(obj));
    }

    return result;
  }
}
