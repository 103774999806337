import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {FormControl, FormGroup} from "@angular/forms";
import {ModelBase} from "../../../../models/model-base";
import {FormUtil} from "../../../form-util";
import {Router} from "@angular/router";

@Component({
  selector: 'app-view-services',
  templateUrl: './view-services.component.html',
})
export class ViewServicesComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Your Services';
  public user: User;
  public userServices: Array<string>;
  public missingServices: Array<any>;
  public services = ModelBase.SERVICES;
  // form and form controls
  public sauSvc: FormControl;
  public mcrSvc: FormControl;
  public loansSvc: FormControl;
  public form: FormGroup;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.reload(false);
    this.reloadOnSync();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountServices
      });
    }, 0);
  }

  createFormControls(): void {
    this.sauSvc = new FormControl();
    this.mcrSvc = new FormControl();
    this.loansSvc = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
        sauSvc: this.sauSvc,
        mcrSvc: this.mcrSvc,
        loansSvc: this.loansSvc,
      }
    )
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    this.user = this.userService.getUserSync();
    this.userServices = this.user.getServicesArray();
    this.missingServices = this.user.getMissingServices();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  getServices(): number {
    let newServices = this.user.services;
    if (this.sauSvc.value) {
      newServices += ModelBase.SERVICES.EZUPLOADS;
    }
    if (this.mcrSvc.value) {
      newServices += ModelBase.SERVICES.EZMCR;
    }
    if (this.loansSvc.value) {
      newServices += ModelBase.SERVICES.POLOS;
    }
    return newServices;
  }

  addServices(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    this.userService.addServices(this.getServices())
      .then(() => {
        return this.userService.refreshUser();
      })
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Service(s) successfully added');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create order', errorData);
      });
  }

  navigateToAddServices(): void {
    this.router.navigate(['/account/add-services'])
      .catch(() => {
        console.log('ERROR navigating to add order.');
      })
  }
}
