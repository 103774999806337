<form [formGroup]="form" class="mt-2">
  <div class="clearfix">
    <div class="row">
      <div class="col-lg-9">
        <div class="d-inline">
          <mat-checkbox (change)="onFilterChange()" formControlName="errorLevelInfo">Info</mat-checkbox>&nbsp;&nbsp;
        </div>

        <div class="d-inline">
          <mat-checkbox (change)="onFilterChange()" class="ms-3" formControlName="errorLevelWarning">
            Warning
          </mat-checkbox>
        </div>

        <div class="d-inline">
          <mat-checkbox (change)="onFilterChange()" class="ms-3" formControlName="errorLevelDanger">
            Error
          </mat-checkbox>
        </div>
      </div>
      <div class="col-lg-3 text-end">
        <a (click)="close($event)" *ngIf="isMobile" title="Close"><i class="text-dark fas fa-times-circle pr-font-large"></i></a>
        <button (click)="close($event)" *ngIf="!isMobile" class="btn btn-lg btn-primary">
          <i class="fas fa-times-circle"></i>&nbsp;Close
        </button>
      </div>
    </div>
  </div>

  <div class="row pt-1 pb-2 border-bottom">
    <div class="col-lg-2 ">
      <a (click)="setOrderBy($event,'errorLevel')" class="fw-bold" href=""
        style="color:black">Level</a>
      <small>&nbsp;<i [ngClass]="getSortIconClass('errorLevel')"></i></small>
    </div>

    <div class="col-lg-2 ">
      <a (click)="setOrderBy($event,'sectionName')" class="fw-bold" href=""
        style="color:black">Section</a>
      <small>&nbsp;<i [ngClass]="getSortIconClass('sectionName')"></i></small>
    </div>

    <div class="col-lg-2 ">
      <a (click)="setOrderBy($event,'segmentName')" class="fw-bold" href=""
        style="color:black">Segment</a>
      <small>&nbsp;<i [ngClass]="getSortIconClass('segmentName')"></i></small>
    </div>

    <div class="col-lg-6">
      <a (click)="setOrderBy($event,'text')" class="fw-bold" href="" style="color:black">Message</a>
      <small>&nbsp;<i [ngClass]="getSortIconClass('text')"></i></small>
    </div>
  </div>

  <div *ngFor="let message of getFilteredErrors()"
    [ngClass]="getTableRowClass(message)" class="row pt-2 pb-2 border-bottom hover-gray">
    <div class="col-lg-2 ">
      <strong>{{getLevelByCode(message.errorLevel)}}</strong>
    </div>

    <div class="col-lg-2 ">
      <strong>{{message.sectionName}}</strong>
    </div>

    <div class="hand-pointer col-lg-2 ">
      <strong (click)="navigateToSegment(message)" title="Click to Edit Segment">{{message.segmentName}}</strong>
    </div>

    <div class="col-lg-6">
      <strong>{{message.text}}</strong>
    </div>
  </div>

  <app-box [hidden]="getFilteredErrors().length" [type]="'warning'" class="mt-2">
    No filtered errors!
  </app-box>
</form>
