<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on My Loans
  </mat-card-title>
  <mat-card-content>
    <h5>My Loans</h5>

    <p>
      My Loans displays all the loans that you can access.
      There are three checkboxes above the loan list let you filter out the loans by their status:
      active, inactive, or completed.
    </p>
  </mat-card-content>
</mat-card>
