<mat-card class="mt-2 mb-1">
  <mat-card-title>Military&nbsp;
  </mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="update()" [formGroup]="form" novalidate>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col">
          <label id="service_label">
            <strong>
              Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?
            </strong>
          </label>
        </div>

        <div class="col">
          <mat-radio-group aria-label="service_label" (change)="onChange($event, 'service')">
            <mat-radio-button [checked]="service.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="service.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      `
      <div *ngIf="service.value===true">
        <div class="row pb-3">
          <div class="col">
            <mat-checkbox formControlName="active_duty">Active duty</mat-checkbox>
          </div>
        </div>

        <div class="row pb-3" [hidden]="!active_duty.value">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Projected expiration date of service/tour</mat-label>
              <input [matDatepicker]="$any(datePicker)" [min]="minDate" formControlName="expiration_date" matInput
                placeholder="Projected expiration date of service/tour">
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="expiration_date.hasError('matDatepickerMin') || expiration_date.hasError('matDatepickerMax')">
                Expiration date is in the past
              </mat-error>
              <mat-error *ngIf="expiration_date.hasError('matDatepickerParse')">
                Expiration date is invalid
              </mat-error>
              <mat-error *ngIf="expiration_date.hasError('required')">
                Expiration date is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col">
            <mat-checkbox formControlName="veteran">Veteran: currently retired, discharged, or separated from service</mat-checkbox>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col">
            <mat-checkbox formControlName="never_activated">Only period of service was as a non-activated member of the Reserve or National Guard</mat-checkbox>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col">
            <mat-checkbox formControlName="surviving">Surviving spouse</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>
