import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';
import {FormUtil} from '../../../../form-util';
import {CreditReportService} from '../../../../../services/credit-report.service';
import {Document} from '../../../../../models/document';
import {CreditReport} from '../../../../../models/credit-report';

@Component({
  selector: 'app-view-segment-credit-report',
  templateUrl: './view-segment-credit-report.component.html',
})
export class ViewSegmentCreditReportComponent extends CompBaseComponent implements OnInit {
  public creditReportService: CreditReportService;
  public loanAppDoc: Document;
  public isCreditReport: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.creditReportService = this.injector.get(CreditReportService);
    this.reset();
  }

  reset() {
    this.reloaded = false;
    this.tran = this.tranService.getCurrentTranSync();
    this.loanAppService.getCurrentLoanDocument()
      .then((doc) => {
        this.loanAppDoc = doc;
        this.isCreditReport = this.doc.meta.status === 'Completed';
        this.reloaded = true;
      })
      .catch((data) => {
        console.log('FAILED');
        this.reloaded = true;
      });
  }

  onEvent(event: any): void {
    if (event.type === 'Delete') {
      if (confirm('Are you sure?') === false) {
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
        return;
      }

      this.reloaded = false;
      this.creditReportService.deleteDocument(this.doc.id)
        .then(() => {
          FormUtil.genSuccessMessage(this.sharedService, 'Credit document deleted');
          this.eventEmitted.emit({type: 'Reload'});
          this.reloaded = true;
        })
        .catch((data) => {
          FormUtil.genErrorMessage(this.sharedService, 'Failed to delete credit document', data);
          this.reloaded = true;
        });
      this.mode = 'View';
      return;
    }

    this.eventEmitted.emit(event);
  }

  manage(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Manage'});
  }

  poll(event: any): void {
    event.preventDefault();
    this.reloaded = false;
    this.creditReportService.updateCreditReport(this.doc.id, this.loanAppDoc.id, this.tran.id)
      .then((doc) => {
        let message = null;
        if (doc.meta.status === 'Completed') {
          message = 'Credit report was successfully generated';
          FormUtil.genSuccessMessage(this.sharedService, message);
        } else {
          message = 'Credit report is not ready yet. Keep on checking Credit Report Status';
          FormUtil.genInfoMessage(this.sharedService, message);
        }
        this.eventEmitted.emit({type: 'Reload'});
        this.reloaded = true;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate credit report document', data);
        this.reloaded = true;
      });
    this.mode = 'View';
    return;
  }

  getCreditPreparedFor(): string {
    return CreditReport.getCreditPreparedFor(this.doc.meta['section']).value;
  }

  formatCreditScores(values): string {
    if (Array.isArray(values)) {
      return values.join(', ');
    }
    return values;
  }
}
