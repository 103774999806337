<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Folder name</mat-label>
        <input formControlName="display" matInput placeholder="Folder name" maxlength="{{MAX_LEN.GEN_NAME}}" required>
        <mat-error *ngIf="display.hasError('required')">
          Folder name is required
        </mat-error>
        <mat-error *ngIf="display.hasError('minlength')">
          Folder name is too short
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Folder description</mat-label>
        <input formControlName="description" matInput placeholder="Folder description" maxlength="{{MAX_LEN.DESC}}">
        <mat-error *ngIf="description.hasError('required')">
          Folder description is required
        </mat-error>
        <mat-error *ngIf="description.hasError('minlength')">
          Folder description is too short
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <button (click)="update()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Update</button>
      <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
    </div>
  </div>
</form>
