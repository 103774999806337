import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-view-sandbox-tran',
  templateUrl: './view-sandbox-tran.component.html',
})
export class ViewSandboxTranComponent extends CompBaseComponent implements OnInit {
  @Input() tran: Tran;
  sandboxTrans: Array<Tran>;
  form: FormGroup;
  sandboxTranId = null;
  sandboxTran: Tran;
  docCheckboxArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit(false);
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.sandboxTranId = null;
    this.createFormControls();
    this.createForm();
    this.sandboxTran = null;
    this.sandboxTrans = [];
    this.tranService.getTrans(false)
      .then((trans) => {
        trans.forEach((tran) => {
          if (tran.isSandboxTran()) {
            this.sandboxTrans.push(tran);
          }
          this.reloaded = true;
        });
      });
  }

  createFormControls() {
    this.docCheckboxArray = new FormArray([]);
  }

  createForm() {
    this.form = new FormGroup({
      docCheckboxArray: this.docCheckboxArray
    });
  }

  onSelectedTran(tranId: number): void {
    this.sandboxTranId = tranId;
    this.docCheckboxArray.clear();
    this.tranService.getTranById(this.sandboxTranId)
      .then((tran: Tran) => {
        this.sandboxTran = tran;
        this.sandboxTran.documents.forEach((elt) => {
          const formControl = new FormControl();
          formControl['docId'] = elt.id;
          formControl['name'] = elt.name;
          formControl['dtype'] = elt.getDocumentTypeString();
          this.docCheckboxArray.push(formControl);
        });
      })
      .catch(() => {
      });
  }

  isDocs(): boolean {
    return this.docCheckboxArray.controls.some((control, index) => {
      if (control.value) {
        return true;
      }
    });
  }

  copySandboxDocs(): void {
    const docIds = [];
    this.docCheckboxArray.controls.forEach((control, index) => {
      if (control.value) {
        docIds.push(control['docId']);
      }
    });

    if (docIds.length === 0) {
      FormUtil.genErrorMessage(this.sharedService, 'No documents selected', null);
      return;
    }

    this.tranService.copySandboxTran(this.sandboxTranId, this.tran.id, docIds)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Sandbox loan documents copied successfully');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({mode: 'ChangeMode', type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to copy sandbox loan documents', data);
      });
  }

  cancel(): void {
    this.reload();
  }
}
