<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Military'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>It the Military segment does not apply to you, respond with No to the first question.</li>
        <li>Otherwise, fill out this form accurately and completely.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?</div>
      <div class="col-lg-6 my-auto" *ngIf="item.service!==null">{{item.service | booleanToString}}</div>
      <div class="col-lg-6 my-auto" *ngIf="item.service===null">(no response)</div>
    </div>

    <div *ngIf="item.service===true">
      <div class="row  pt-1 pb-1 border-bottom hover-gray" *ngIf="item.active_duty===true">
        <div class="col-lg-6 fw-bold">Active duty</div>
        <div class="col-lg-6">{{item.active_duty | booleanToString}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.active_duty===true">
        <div class="col-lg-6 fw-bold">Projected expiration date of service/tour</div>
        <div class="col-lg-6" *ngIf="item.expiration_date">{{item.expiration_date | date: 'MM/dd/yyyy'}}</div>
        <div class="col-lg-6" *ngIf="!item.expiration_date">(not specified)</div>
      </div>

      <div class="row  pt-1 pb-1 border-bottom hover-gray" *ngIf="item.veteran===true">
        <div class="col-lg-6 fw-bold">Veteran: currently retired, discharged, or separated from service</div>
        <div class="col-lg-6">{{item.veteran | booleanToString}}</div>
      </div>

      <div class="row  pt-1 pb-1 border-bottom hover-gray" *ngIf="item.never_activated===true">
        <div class="col-lg-6 fw-bold">Only period of service was as a non-activated member of the Reserve or National Guard</div>
        <div class="col-lg-6">{{item.never_activated | booleanToString}}</div>
      </div>

      <div class="row  pt-1 pb-1 border-bottom hover-gray" *ngIf="item.surviving===true">
        <div class="col-lg-6 fw-bold">Surviving spouse</div>
        <div class="col-lg-6">{{item.surviving | booleanToString}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
