<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Property Information'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <span class="fw-bold">Number of units.</span>
          Refers to a multi-unit property. For example, for a duplex enter 2.
        </li>
        <li>
          <span class="fw-bold">Zip four.</span>
          Please supply zip four. You can figure out the property's zip four using the USPS tool (https://tools.usps.com/).
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Address</div>
      <div class="col-lg-6">{{item.property_address}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Apt/Unit/Suite</div>
      <div class="col-lg-6">{{item.property_address2}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">City</div>
      <div class="col-lg-6">{{item.property_city}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">State</div>
      <div class="col-lg-6">{{item.property_state}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Zip</div>
      <div class="col-lg-6">{{item.property_zip}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Zip four</div>
      <div class="col-lg-6">{{item.property_zip_four}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">County</div>
      <div class="col-lg-6">{{item.property_county}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">MSA Identifier</div>
      <div class="col-lg-6">{{item.msa_id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Census Tract Identifier</div>
      <div class="col-lg-6">{{item.census_id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Number of units financed</div>
      <div class="col-lg-6">{{item.units_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Year built</div>
      <div class="col-lg-6">{{item.year_built}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Construction method</div>
      <div class="col-lg-6">{{item.getPropertyType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.property_type==='Other'">
      <div class="col-lg-6 fw-bold">Construction method explain</div>
      <div class="col-lg-6">{{item.property_type_other}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Attachment type</div>
      <div class="col-lg-6">{{item.attachment_type}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.property_type == 'Other'">
      <div class="col-lg-6 fw-bold">Is this a multi-family dwelling (5 or more units)?</div>
      <div class="col-lg-6" *ngIf="item.multi_family!==null">{{item.multi_family | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.multi_family===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is the property located in a project per trading partner guidelines?</div>
      <div class="col-lg-6" *ngIf="item.in_project!==null">{{item.in_project | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.in_project===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is the property located in a PUD?</div>
      <div class="col-lg-6" *ngIf="item.in_project!==null">{{item.in_pud | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.in_project===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col fw-bold pr-font-large">
        Property in Project
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Project design type</div>
      <div class="col-lg-6">{{item.getProjectDesignType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Project legal structure</div>
      <div class="col-lg-6">{{item.getProjectLegalStructure()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col fw-bold pr-font-large">
        Native American or Hawaiian Land Types
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Native American/Hawaiian land type</div>
      <div class="col-lg-6">{{item.getLandType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.land_type==='Other'">
      <div class="col-lg-6 fw-bold">Land type other description</div>
      <div class="col-lg-6">{{item.land_type_other_description}}</div>
    </div>
  </mat-card-content>
</mat-card>
