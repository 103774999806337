<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Loan Dashboard
  </mat-card-title>
  <mat-card-content>
    <p>
      Loan Dashboard displays the loan highlights. It is visible once a loan application has been created and
      flagged as the default loan application.
    </p>
  </mat-card-content>
</mat-card>
