<div *ngIf="reloaded" [ngSwitch]="helpTitle">
  <app-help-account-contacts *ngSwitchCase="helpKeys.accountContacts"></app-help-account-contacts>
  <app-help-account-settings *ngSwitchCase="helpKeys.accountSettings"></app-help-account-settings>
  <app-help-account-photo *ngSwitchCase="helpKeys.accountPhoto"></app-help-account-photo>
  <app-help-account-password *ngSwitchCase="helpKeys.accountPassword"></app-help-account-password>
  <app-help-account-mlo *ngSwitchCase="helpKeys.accountMLO"></app-help-account-mlo>
  <app-help-account-branding *ngSwitchCase="helpKeys.accountBranding"></app-help-account-branding>
  <app-help-account-invite *ngSwitchCase="helpKeys.accountInvite"></app-help-account-invite>
  <app-help-home-map *ngSwitchCase="helpKeys.homeMap"></app-help-home-map>
  <app-help-home-officer *ngSwitchCase="helpKeys.homeOfficer"></app-help-home-officer>
  <app-help-home-step *ngSwitchCase="helpKeys.homeStep"></app-help-home-step>
  <app-help-home-highlights *ngSwitchCase="helpKeys.homeHighlights"></app-help-home-highlights>
  <app-help-any *ngSwitchCase="helpKeys.any"></app-help-any>
  <app-help-loan-dashboard *ngSwitchCase="helpKeys.loanDashboard"></app-help-loan-dashboard>
  <app-help-loans-dashboard *ngSwitchCase="helpKeys.loansDashboard"></app-help-loans-dashboard>
  <app-help-loans-new *ngSwitchCase="helpKeys.loansNew"></app-help-loans-new>
  <app-help-directory *ngSwitchCase="helpKeys.directory"></app-help-directory>
  <app-help-loan-app *ngSwitchCase="helpKeys.loanApp"></app-help-loan-app>
  <app-help-loans-list *ngSwitchCase="helpKeys.loansList"></app-help-loans-list>
  <app-help-lo-mcr-list *ngSwitchCase="helpKeys.loMCRList"></app-help-lo-mcr-list>
  <app-help-message *ngSwitchCase="helpKeys.message"></app-help-message>
  <app-help-emails *ngSwitchCase="helpKeys.emails"></app-help-emails>
  <app-help-note *ngSwitchCase="helpKeys.note"></app-help-note>
  <app-help-permission *ngSwitchCase="helpKeys.permission"></app-help-permission>
  <app-help-task *ngSwitchCase="helpKeys.task"></app-help-task>
  <app-help-timeline *ngSwitchCase="helpKeys.timeline"></app-help-timeline>
  <app-help-workflow *ngSwitchCase="helpKeys.workflow"></app-help-workflow>
  <app-help-compliance *ngSwitchCase="helpKeys.compliance"></app-help-compliance>
  <app-help-conditions *ngSwitchCase="helpKeys.conditions"></app-help-conditions>
  <app-help-account-org *ngSwitchCase="helpKeys.accountOrg"></app-help-account-org>
  <app-help-org-loans *ngSwitchCase="helpKeys.orgLoans"></app-help-org-loans>
  <app-help-ez-uploads *ngSwitchCase="helpKeys.ezUploads"></app-help-ez-uploads>
  <app-help-home-services *ngSwitchCase="helpKeys.homeServices"></app-help-home-services>
  <app-help-account-services *ngSwitchCase="helpKeys.accountServices"></app-help-account-services>
  <app-help-account-invoices *ngSwitchCase="helpKeys.accountInvoices"></app-help-account-invoices>
  <app-help-account-subscriptions *ngSwitchCase="helpKeys.accountSubscriptions"></app-help-account-subscriptions>
</div>
