import {Component, Injector, Input, OnInit} from '@angular/core';
import {LoanData} from '../../../../models/loan-data';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-loan-data',
  templateUrl: './view-segment-loan-data.component.html',

})
export class ViewSegmentLoanDataComponent extends CompBaseComponent implements OnInit {
  @Input() item: LoanData;
  public isConstructionLoan: boolean;
  public isRefinanceLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isConstructionLoan = this.analyzerService.isConstructionLoan(this.doc.recursive_attributes);
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
  }
}
