import {Component, Injector, OnInit} from '@angular/core';
import {DependentComponent} from '../dependent/dependent.component';

@Component({
  selector: 'app-view-segment-dependent',
  templateUrl: './view-segment-dependent.component.html',

})
export class ViewSegmentDependentComponent extends DependentComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
