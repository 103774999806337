import {Component, Injector, OnInit} from '@angular/core';
import {AssetComponent} from '../asset/asset.component';
import {Asset} from '../../../../models/asset';

@Component({
  selector: 'app-add-asset',
  templateUrl: '../asset/edit-segment-asset.component.html',
})
export class AddAssetComponent extends AssetComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Asset({}));
    this.createForm();
    this.setParties();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
