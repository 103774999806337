<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="mcrTitle" [help]="true">
      <p>
        From this form you can populate the MCR with relevant loans and generate the loan officer MCR report.
      </p>
      <ul>
        <li>To populate the loan officer MCR with relevant loans, click Populate MCR.</li>
        <li>To generate the loan officer MCR report, click Generate Report.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>

    <div [hidden]="isReport">
      <div class="fw-bold">Loans Associated with My MCR</div>

      <div *ngFor="let tran of mcr.trans" (click)="navigateToTranMCR(tran)" class="row pt-1 pb-1 border-bottom hover-gray hand-pointer">
        <div class="col-lg-2">
          {{tran.id}}
        </div>
        <div class="col-lg-4">
          {{tran.name}}
        </div>
        <div class="col-lg-6">
          {{tran.description}}
        </div>
      </div>

      <form [formGroup]="form" novalidate class="mt-2">
        <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>

        <!-- STATE -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="md-icon-left w-100">
              <input formControlName="state" matInput appUpperCase placeholder="State abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
              <mat-error *ngIf="state.hasError('required')">
                State is required
              </mat-error>
              <mat-error *ngIf="state.hasError('pattern')">
                State is invalid
              </mat-error>
              <mat-hint>State Abbr. E.g., WA</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="isReport" class="alert show alert-dismissible" role="alert">
      <app-mcr-report [mcr]="mcr" [state]="state.value" (eventEmitted)="onEvent($event)"></app-mcr-report>
    </div>

    <div [hidden]="isReport" class="mt-3">
      <button (click)="genReport()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="button" title="Generate Report" aria-label="Generate Report">Generate Report</button>
      <button (click)="view()" type="button" class="ms-2 btn btn-lg btn-primary" title="Done" aria-label="Done">Done</button>
      <button (click)="deleteMcr()" type="button" class="ms-2 btn btn-lg btn-danger" title="Delete MCR" aria-label="Delete MCR">Delete</button>
    </div>
  </mat-card-content>
</mat-card>
