import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {Router} from '@angular/router';
import {Order} from "../../../../models/order";
import {CompBaseComponent} from "../../../comp-base.component";
import {OrderService} from "../../../../services/order.service";
import {ModelBase} from "../../../../models/model-base";
import {AppValidators} from "../../../../shared/validators/app-validators.validator";
import {User} from "../../../../models/user";

@Component({
  selector: 'app-order',
  templateUrl: './edit-segment-order.component.html',
})
export class OrderComponent extends CompBaseComponent implements OnInit {
  @Input() item: Order;
  public user: User;
  public subscription_terms = ModelBase.subscription_terms;
  public servicesHash: {value: string, key: number}[];
  public orderService: OrderService;
  public op: string;
  // form and form controls
  public form: FormGroup;
  public subscription_term: FormControl;
  public services: FormControl;
  public amount: FormControl;
  public order_type: FormControl;
  public entered_by_user_id: FormControl;
  public entered_by_name: FormControl;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    if (this.user.isBorrower()) {
      this.servicesHash = ModelBase.borrowerServices;
    } else {
      this.servicesHash = ModelBase.services;
    }
    this.orderService = this.injector.get(OrderService);
  }

  createFormControls(oli: any): void {
    this.subscription_term = new FormControl(null, AppValidators.present)
    this.services = new FormControl(oli.services, AppValidators.present)
    this.order_type = new FormControl(ModelBase.ORDER_TYPE.SUBSCRIPTION)
    this.entered_by_user_id = new FormControl()
    this.entered_by_name = new FormControl(ModelBase.ENTERED_BY.SYSTEM)
  }

  createForm(): void {
    this.form = new FormGroup({
      subscription_term: this.subscription_term,
      services: this.services,
      order_type: this.order_type,
      entered_by_user_id: this.entered_by_user_id,
      entered_by_name: this.entered_by_name,
    });
  }

  createAddServicesFormControls(oli: any): void {
    this.services = new FormControl(oli.services, AppValidators.present)
    this.order_type = new FormControl(ModelBase.ORDER_TYPE.SUBSCRIPTION)
    this.entered_by_user_id = new FormControl()
    this.entered_by_name = new FormControl(ModelBase.ENTERED_BY.SYSTEM)
  }

  createAddServicesForm(): void {
    this.form = new FormGroup({
      services: this.services,
      order_type: this.order_type,
      entered_by_user_id: this.entered_by_user_id,
      entered_by_name: this.entered_by_name,
    });
  }

  delete(): void {
    this.orderService.deleteOrder(this.item.id)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  createOrder(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const payload = {
      order_type: ModelBase.ORDER_TYPE.SUBSCRIPTION,
      entered_by_user_id: null,
      entered_by_name: ModelBase.ENTERED_BY.SYSTEM,
      services: this.services.value,
      subscription_term: this.subscription_term.value,
    }
    this.orderService.createOrder(payload)
      .then(() => {
        return this.userService.refreshUser();
      })
      .then(() => {
        this.eventService.eventEmitter.emit({
          type: 'Reload',
          component: 'account'
        });
        return this.router.navigate(['/account/invoices']);
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create order', errorData);
        console.log(errorData)
      });
  }

  addServices(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    this.userService.addServices(this.services.value)
      .then(() => {
        return this.userService.refreshUser();
      })
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
        return this.router.navigate(['/account/invoices']);
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create order', errorData);
        console.log(errorData)
      });
  }

  navigateToAddServices(): void {
    this.router.navigate(['/account/add-services'])
      .catch(() => {
        console.log('ERROR navigating to add order.');
      })
  }
}
