import {Component, Injector, OnInit} from '@angular/core';
import {TransmittalData} from '../../../../models/transmittal-data';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-transmittal-data',
  templateUrl: './view-transmittal-data.component.html',
})
export class ViewTransmittalDataComponent extends CompBaseComponent implements OnInit {
  public item: TransmittalData;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new TransmittalData(this.doc.recursive_attributes['transmittal_data']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
