import {Component, Injector, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-edit-loan-record',
  templateUrl: './edit-loan-record.component.html',
})
export class EditLoanRecordComponent extends CompBaseComponent implements OnInit {
  public pageTitle: string;
  public statuses = Tran.statuses;

  // form and form controls
  public form: FormGroup;
  public name: FormControl;
  public description: FormControl;
  public status: FormControl;

  public isTerminated: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isTerminated = this.tran.isTerminated();
    this.pageTitle = this.tran.name;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.directory
      });
    }, 0);
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl(this.tran.name, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.description = new FormControl(this.tran.description);
    this.status = new FormControl(this.tran.status, AppValidators.present);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
      status: this.status
    });
  }

  update(): void {
    this.tranService.updateTran(this.tran.id, this.form.value)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan record successfully updated');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan record', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }
}
