import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {SysAlert} from '../../../models/sys-alert';
import {SysAlertService} from '../../../services/sys-alert.service';

@Component({
  selector: 'app-sys-alert',
  template: '',
})
export class SysAlertComponent extends CompBaseComponent implements OnInit {
  @Input() SysAlert: SysAlert;
  public sysAlertService: SysAlertService;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.sysAlertService = this.injector.get(SysAlertService);
  }
}
