import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {Workflow} from '../../../models/workflow';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-view-segment-reset-workflow',
  templateUrl: './view-segment-reset-workflow.component.html',
})
export class ViewSegmentResetWorkflowComponent extends CompBaseComponent implements OnInit {
  @Input() public tran: Tran;
  public workflow: Workflow;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.workflow = this.tran.workflow;
    this.tranService.getCurrentTran(false)
      .then((tran: Tran) => {
        this.tran = tran;
        this.workflow = this.tran.workflow;
        this.reloaded = true;
      });
  }

  changeMode(mode: string): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: mode});
  }

  resetFutureTasks(): void {
    const payload = {
      ended_at: new Date()
    };

    this.workflowService.resetFutureTraces(this.tran.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Uncompleted tasks successfully reset');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to reset unstarted tasks', data);
        this.cancel();
        return;
      });
  }
}
