import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {PropertyInformation} from '../../../../models/property-information';
import {globals} from '../../../../shared/globals/globals';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-property-information',
  templateUrl: './edit-segment-property-information.component.html',
})
export class EditSegmentPropertyInformationComponent extends CompBaseComponent implements OnInit {
  public readonly legal_structures = PropertyInformation.legal_structures;
  public readonly design_types = PropertyInformation.design_types;
  public readonly land_types = PropertyInformation.land_types;
  public readonly property_types = PropertyInformation.property_types;
  public readonly attachment_types = PropertyInformation.attachment_types;
  @Input() public item: PropertyInformation;

  // form and form controls
  form: FormGroup;
  property_address: FormControl;
  property_address2: FormControl;
  property_city: FormControl;
  property_state: FormControl;
  property_zip: FormControl;
  property_zip_four: FormControl;
  property_county: FormControl;
  units_no: FormControl;
  year_built: FormControl;
  property_type: FormControl;
  property_type_other: FormControl;
  multi_family: FormControl;
  in_project: FormControl;
  in_pud: FormControl;
  attachment_type: FormControl;
  msa_id: FormControl;
  census_id: FormControl;
  land_type: FormControl;
  land_type_other_description: FormControl;
  design_type: FormControl;
  legal_structure: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'property_information';
    this.section = 'loan_information';
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    const yearNow = (new Date).getFullYear();
    this.property_address = new FormControl(item.property_address, AppValidators.present);
    this.property_address2 = new FormControl(item.property_address2);
    this.property_city = new FormControl(item.property_city, AppValidators.present);
    this.property_state = new FormControl(item.property_state,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.state)]));
    this.property_zip = new FormControl(item.property_zip,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.zip)]));
    this.property_zip_four = new FormControl(item.property_zip_four, Validators.pattern(globals.pattern.zip_four));
    this.property_county = new FormControl(item.property_county, AppValidators.present);
    this.units_no = new FormControl(item.units_no,
      Validators.compose([AppValidators.present, Validators.min(1), Validators.max(999)]));
    this.year_built = new FormControl(item.year_built,
      Validators.compose([AppValidators.present, Validators.min(globals.date.minYearBuilt), Validators.max(globals.date.maxYearBuilt)]));
    this.property_type = new FormControl(item.property_type, AppValidators.present);
    this.property_type_other = new FormControl(item.property_type_other);
    this.multi_family = new FormControl(item.multi_family);
    this.in_project = new FormControl(item.in_project);
    this.in_pud = new FormControl(item.in_pud);
    this.attachment_type = new FormControl(item.attachment_type);
    this.msa_id = new FormControl(item.msa_id);
    this.census_id = new FormControl(item.census_id, Validators.pattern(globals.pattern.censusTract));
    this.land_type = new FormControl(item.land_type);
    this.land_type_other_description = new FormControl(item.land_type_other_description);
    this.design_type = new FormControl(item.design_type);
    this.legal_structure = new FormControl(item.legal_structure);
  }

  createForm(): void {
    this.form = new FormGroup({
      property_address: this.property_address,
      property_address2: this.property_address2,
      property_city: this.property_city,
      property_state: this.property_state,
      property_zip: this.property_zip,
      property_zip_four: this.property_zip_four,
      property_county: this.property_county,
      units_no: this.units_no,
      year_built: this.year_built,
      property_type: this.property_type,
      property_type_other: this.property_type_other,
      multi_family: this.multi_family,
      in_project: this.in_project,
      in_pud: this.in_pud,
      attachment_type: this.attachment_type,
      msa_id: this.msa_id,
      census_id: this.census_id,
      land_type: this.land_type,
      land_type_other_description: this.land_type_other_description,
      design_type: this.design_type,
      legal_structure: this.legal_structure,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new PropertyInformation(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
