import {AbstractControl} from '@angular/forms';

// comments:
// for type number, the value must be defined and non-zero
// for type boolean, the value must be either true or false. Any other value including undefined and null will return an error.
// for type string, the trimmed value must be positive
export class AppValidators {
  static present(control: AbstractControl) {
    const value = control.value;
    if (typeof value === 'boolean') {
      if (typeof value === 'undefined'){
        return {required: 'value is not present'};
      }
      return null;
    }

    if (typeof value === 'string') {
      return (value.trim().length === 0) ? {required: 'value is white space or empty'} : null;
    }
    return !value ? {required: 'value is not present'} : null;
  }
}
