<mat-card *ngIf="reloaded" class="mt-1">
  <mat-card-title>{{op=='Add'?'Create an Organization':'Edit Organization'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="name" matInput placeholder="Name" maxlength="{{MAX_LEN.GEN_NAME}}" autofocus>
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="description" matInput placeholder="Description" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
              Description is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="nmls" matInput placeholder="NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
            <mat-error *ngIf="nmls.hasError('required')">
              NMLS is required
            </mat-error>
            <mat-error *ngIf="nmls.hasError('minlength')">
              NMLS is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="add()" *ngIf="op=='Add'" class="btn btn-lg btn-primary">Create</button>
          <button (click)="update()" *ngIf="op=='Edit'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="reload()" class="ms-2 btn btn-lg btn-light">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
