import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-line-header',
  templateUrl: './line-header.component.html',
})
export class LineHeaderComponent implements OnInit {
  @Input() enable = true;
  @Input() op: string;
  @Input() title: string;
  @Output() eventEmitted = new EventEmitter();
  @ViewChild('lineHeader', {static: true}) private lineHeaderElement: ElementRef;

  constructor() {
  }

  edit(event: any) {
    event.preventDefault();
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }

  delete(event: any) {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Delete'});
  }

  ngOnInit() {
  }
}
