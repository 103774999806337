import {Component, Injector, OnInit} from '@angular/core';
import {CalcComponent} from '../calc/calc.component';

@Component({
  selector: 'app-view-segment-calc-rate',
  templateUrl: './view-segment-calc-rate.component.html',
})
export class ViewSegmentCalcRateComponent extends CalcComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  edit(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit', loan: this.loan});
  }
}
