import {Component, Injector, OnInit} from '@angular/core';
import {SegmentWorkflowComponent} from '../segment-workflow/segment-workflow.component';

@Component({
  selector: 'app-view-segment-workflow',
  templateUrl: './view-segment-workflow.component.html',
})
export class ViewSegmentWorkflowComponent extends SegmentWorkflowComponent implements OnInit {
  public header: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return super.ngOnInit()
        .then(() => {
            const stateDisplay = this.currentTrace ? this.currentTrace.state_display : 'New';
            this.header = `WORKFLOW <span class="font-weight-bold">@ ${stateDisplay}</span>`;
            this.reloaded = true;
            resolve(true);
          }
        )
        .catch(() => {
          reject(false);
        });
    });
  }

  changeMode(mode: string): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: mode});
  }
}
