import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-find-users',
  templateUrl: './find-users.component.html',
})
export class FindUsersComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Find Users';

  // form and form controls
  public form: FormGroup;
  public first: FormControl;
  public last: FormControl;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.first = new FormControl('');
    this.last = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      first: this.first,
      last: this.last
    });
  }

  findUsers(): void {
    this.router.navigate(['/admin/user-list'], {queryParams: this.form.value});
  }

  reset(): void {
    this.form.reset();
  }
}
