import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {SysAlert} from '../models/sys-alert';
import {CacheService} from './cache.service';

@Injectable()
export class SysAlertService {
  public sysAlerts: Array<SysAlert>;

  constructor(private httpService: HttpService,
              private cacheService: CacheService) {
  }

  getSysAlerts(force: boolean, payload: any): Promise<Array<SysAlert>> {
    let sysAlerts = this.cacheService.getSeg('sysAlert', 'sysAlerts');
    if (!force && sysAlerts) {
      return Promise.resolve(sysAlerts);
    }

    return new Promise((resolve, reject) => {
      this.httpService.get('sys_alerts', payload)
        .then((data) => {
          sysAlerts = SysAlert.deserializeArray(data);
          this.cacheService.setSeg('sysAlert', 'sysAlerts', sysAlerts);
          resolve(sysAlerts);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateSysAlert(sysAlertId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`sys_alerts/${sysAlertId}`, payload)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(false);
        });
    });
  }
}
