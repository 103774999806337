import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompEvent} from '../../../models/comp-event';
import {CompBaseComponent} from '../../comp-base.component';
import {ComplianceService} from '../../../services/compliance.service';
import moment from 'moment';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-comp-event',
  template: '',
})
export class CompEventComponent extends CompBaseComponent implements OnInit {
  @Input() item: CompEvent;
  public readonly dateFormat = 'MM/DD/YYYY';
  public complianceService: ComplianceService;

  // form and form groups
  public form: FormGroup;
  public occurred_on: FormControl;
  public expires_on: FormControl;

  public constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.complianceService = this.injector.get(ComplianceService);
  }

  createFormControls(): void {
    const occurred_on = moment(this.item.occurred_on, this.dateFormat).toDate();
    const expires_on = moment(this.item.expires_on, this.dateFormat).toDate();
    this.occurred_on = new FormControl(occurred_on);
    this.expires_on = new FormControl(expires_on);
  }

  createForm(): void {
    this.form = new FormGroup({
      occurred_on: this.occurred_on,
      expires_on: this.expires_on
    });
  }

  clear(): void {
    this.occurred_on.setValue(null);
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    let momentDate, dateString, payload;

    if (this.item.code === 'lock') {
      momentDate = moment(this.expires_on.value, this.dateFormat);
      dateString = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
      payload = {expires_on: dateString};
    } else {
      momentDate = moment(this.occurred_on.value, this.dateFormat);
      dateString = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
      payload = {occurred_on: dateString};
    }

    this.complianceService.updateCompEvent(this.item.id, payload)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        FormUtil.genSuccessMessage(this.sharedService, 'Compliance event successfully updated');
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update compliance event', data);
        this.cancel();
      });
  }
}
