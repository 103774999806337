import {ModelBase} from './model-base';

export class PropertyInformation extends ModelBase {
  public static design_types: Array<any> = [
    {key: '', value: 'N/A'},
    {key: 'GardenProject', value: 'Garden Project'},
    {key: 'HighriseProject', value: 'Highrise Project'},
    {key: 'MidriseProject', value: 'Midrise Project'},
    {key: 'Other', value: 'Other'},
    {key: 'TownhouseRowhouse', value: 'Townhouse Row House'},
  ];
  public static legal_structures: Array<any> = [
    {key: '', value: 'N/A'},
    {key: 'CommonInterestApartment', value: 'Common Interest Apartment'},
    {key: 'Condominium', value: 'Condominium'},
    {key: 'Cooperative', value: 'Cooperative'},
    {key: 'Unknown', value: 'Unknown'}
  ];

  public static land_types: Array<any> = [
    {key: '', value: 'N/A'},
    {key: 'FeeSimple', value: 'Fee Simple'},
    {key: 'HawaiianHomeLands', value: 'Hawaiian Home Lands'},
    {key: 'IndividualTrustLand', value: 'Individual Trust Land'},
    {key: 'Other', value: 'Other'},
    {key: 'TribalTrustLand', value: 'Tribal Trust Land'}
  ];

  public static property_types: Array<any> = [
    {key: 'Manufactured', value: 'Manufactured'},
    {key: 'MobileHome', value: 'Mobile Home'},
    {key: 'Modular', value: 'Modular'},
    {key: 'OnFrameModular', value: 'On-Frame Modular'},
    {key: 'Other', value: 'Other'},
    {key: 'SiteBuilt', value: 'Site-Built'},
  ];

  public static attachment_types: Array<any> = [
    {key: 'Attached', value: 'Attached'},
    {key: 'Detached', value: 'Detached'},
  ];

  property_address: string;
  property_address2: string;
  property_city: string;
  property_state: string;
  property_zip: string;
  property_zip_four: string;
  property_county: string;
  units_no: string;
  year_built: string;
  property_type: string;
  property_type_other: string;
  multi_family: boolean;
  in_project: boolean;
  in_pud: boolean;
  attachment_type: boolean;
  msa_id: string;
  census_id: string;
  land_type: string;
  land_type_other_description: string;
  design_type: string;
  legal_structure: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'units_no') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'multi_family') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'in_project' || prop === 'in_pud') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
    this.legal_structure = this.legal_structure || '';
    this.design_type = this.design_type || '';
    this.land_type = this.land_type || '';
  }

  isManufactured(): boolean {
    return this.property_type === 'Manufactured';
  }

  getPropertyType(): string {
    const found = PropertyInformation.property_types.find((elt) => elt.key === this.property_type);
    return found ? found['value'] : null;
  }

  getLandType(): string {
    const found = PropertyInformation.land_types.find((elt) => elt.key === this.land_type);
    return found ? found['value'] : null;
  }

  getProjectDesignType(): string {
    const found = PropertyInformation.design_types.find((elt) => elt.key === this.design_type);
    return found ? found['value'] : null;
  }

  getProjectLegalStructure(): string {
    const found = PropertyInformation.legal_structures.find((elt) => elt.key === this.legal_structure);
    return found ? found['value'] : null;
  }

  isOneToFour(): boolean {
    return this.units_no && (+this.units_no <= 4);
  }

  isMultiFamily(): boolean {
    return this.units_no && (+this.units_no > 4);
  }

  toServerPayload(): any {
    return {
      property_address: this.property_address,
      property_address2: this.property_address2,
      property_city: this.property_city,
      property_state: this.property_state,
      property_zip: this.property_zip,
      property_zip_four: this.property_zip_four,
      property_county: this.property_county,
      units_no: this.toNumber(this.units_no),
      year_built: this.toNumber(this.year_built),
      property_type: this.property_type,
      property_type_other: this.property_type_other,
      multi_family: ModelBase.toServerExplicitBoolean(this.multi_family),
      in_project: ModelBase.toServerExplicitBoolean(this.in_project),
      in_pud: ModelBase.toServerExplicitBoolean(this.in_pud),
      attachment_type: this.attachment_type,
      census_id: this.census_id,
      msa_id: this.msa_id,
      land_type: this.land_type,
      land_type_other_description: this.land_type_other_description,
      design_type: this.design_type,
      legal_structure: this.legal_structure
    };
  }
}
