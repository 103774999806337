import {Component, Injector, OnInit} from '@angular/core';
import {DeclarationExplanationComponent} from '../declaration-explanation/declaration-explanation.component';

@Component({
  selector: 'app-view-declaration-explanation',
  templateUrl: './view-declaration-explanation.component.html',
})
export class ViewDeclarationExplanationComponent extends DeclarationExplanationComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
