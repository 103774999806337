import {Component, Injector, Input, OnInit} from '@angular/core';
import {ProductIdentification} from '../../../../models/product-identification';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-product-identification',
  templateUrl: './view-segment-product-identification.component.html',
})
export class ViewSegmentProductIdentificationComponent extends CompBaseComponent implements OnInit {
  @Input() item: ProductIdentification;

  public isARMLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isARMLoan = this.analyzerService.isARMLoan(this.doc.recursive_attributes);
  }
}
