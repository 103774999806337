<mat-card class="mt-2 mb-1">
  <mat-card-title>{{title}}</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>SSN</mat-label>
            <input appSSNOnly formControlName="ssn" matInput placeholder="SSN" type="text" required>
          </mat-form-field>
          <mat-error *ngIf="submitted && ssn.hasError('required')">
            SSN is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>First name</mat-label>
            <input formControlName="first" matInput placeholder="First name" type="text" maxlength="{{MAX_LEN.NAME_PART}}" required>
          </mat-form-field>
          <mat-error *ngIf="submitted && first.hasError('required')">
            First name is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Middle name</mat-label>
            <input type="text" formControlName="middle" matInput placeholder="Middle name" maxlength="{{MAX_LEN.NAME_PART}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && middle.hasError('required')">
            Middle name is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Last name</mat-label>
            <input type="text" formControlName="last" matInput placeholder="Last name" maxlength="{{MAX_LEN.NAME_PART}}" required>
          </mat-form-field>
          <mat-error *ngIf="submitted && last.hasError('required')">
            Last name is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Suffix</mat-label>
            <input formControlName="suffix" matInput placeholder="Suffix" type="text" maxlength="{{MAX_LEN.NAME_PART}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && suffix.hasError('required')">
            Suffix is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>DOB</mat-label>
            <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="dob" matInput placeholder="DOB" required>
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="dob.hasError('matDatepickerParse')">
              DOB format is invalid
            </mat-error>
            <mat-error *ngIf="submitted && dob.hasError('required')">
              DOB is required
            </mat-error>
            <mat-error *ngIf="submitted && dob.hasError('matDatepickerMin')">
              DOB is unrealistic
            </mat-error>
            <mat-error *ngIf="submitted && dob.hasError('matDatepickerMax')">
              DOB is invalid (too recent or in the future)
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Marital status</mat-label>
            <mat-select formControlName="marital_status_ex" placeholder="Select marital status" required>
              <mat-option *ngFor="let marital_status of marital_statuses_ex" [value]="marital_status.key">{{marital_status.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && marital_status_ex.hasError('required')">
              Marital status is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="marital_status_ex.value === 'Unmarried'">
        <div class="row">
          <div class="col-lg-6">
            <mat-hint>
              Is there a person who is not your legal spouse but who currently has real property rights similar to those of a legal spouse?
            </mat-hint>
            <mat-radio-group [ngClass]="{'text-danger': true}" aria-label="involved in domestic relationship?" formControlName="domestic_relationship_indicator" required>
              <mat-radio-button [checked]="domestic_relationship_indicator.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="domestic_relationship_indicator.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mb-2" *ngIf="submitted && domestic_relationship_indicator.hasError('required')">
              Domestic relationship indicator is required
            </mat-error>
          </div>
        </div>

        <div class="row" *ngIf="domestic_relationship_indicator.value===true">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Domestic relationship type</mat-label>
              <mat-select formControlName="domestic_relationship_type" placeholder="Select domestic relationship type" required>
                <mat-option *ngFor="let domestic_relationship_type of domestic_relationships" [value]="domestic_relationship_type.key">{{domestic_relationship_type.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="submitted && domestic_relationship_type.hasError('required')">
                Marital status is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="domestic_relationship_indicator.value===true&&domestic_relationship_type.value==='Other'">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Domestic relationship other description</mat-label>
              <input formControlName="domestic_relationship_other_description" [required]="domestic_relationship_type.value==='Other'" matInput placeholder="Domestic relationship other description" type="text" maxlength="{{MAX_LEN.DESC}}">
              <mat-error class="mb-1" *ngIf="submitted && domestic_relationship_other_description.hasError('required')">
                Domestic relationship other description is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="domestic_relationship_indicator.value===true">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Domestic relationship state</mat-label>
              <input formControlName="domestic_relationship_state_code" appUpperCase appAlphaOnly matInput placeholder="Domestic relationship state Abbr." type="text" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            </mat-form-field>
            <mat-error *ngIf="submitted && domestic_relationship_state_code.hasError('required')">
              Domestic relationship state abbreviation is required
            </mat-error>
            <mat-error *ngIf="submitted && domestic_relationship_state_code.hasError('pattern')">
              Domestic relationship state is invalid.
            </mat-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Home phone</mat-label>
            <input appPhoneOnly formControlName="home_phone" matInput placeholder="Home phone" type="text" maxlength="{{MAX_LEN.PHONE}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && home_phone.hasError('required')">
            Home phone is required
          </mat-error>
          <mat-error *ngIf="submitted && home_phone.hasError('pattern')">
            Home phone number is invalid.
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Cell phone</mat-label>
            <input appPhoneOnly formControlName="cell_phone" matInput placeholder="Cell phone" type="text" maxlength="{{MAX_LEN.PHONE}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && cell_phone.hasError('required')">
            Cell phone is required
          </mat-error>
          <mat-error *ngIf="submitted && cell_phone.hasError('pattern')">
            Cell phone number is invalid.
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="w-100">
            <mat-label>Work phone</mat-label>
            <input appPhoneOnly formControlName="work_phone" matInput placeholder="Work phone" type="text" maxlength="{{MAX_LEN.PHONE}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && work_phone.hasError('required')">
            Work phone is required
          </mat-error>
          <mat-error *ngIf="submitted && work_phone.hasError('pattern')">
            Work phone number is invalid.
          </mat-error>
        </div>
        <div class="col-lg-2">
          <mat-form-field class="w-100">
            <mat-label>Extension</mat-label>
            <input appNumberOnly formControlName="work_phone_extension" matInput placeholder="Extension" type="text" maxlength="{{MAX_LEN.PHONE_EX}}">
          </mat-form-field>
          <mat-error *ngIf="submitted && work_phone_extension.hasError('required')">
            Extension is required
          </mat-error>
          <mat-error *ngIf="submitted && work_phone_extension.hasError('pattern')">
            Extension is invalid.
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Email</mat-label>
            <input type="text" formControlName="email" matInput placeholder="Email" maxlength="{{MAX_LEN.EMAIL}}">
            <mat-error *ngIf="submitted && email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="submitted && email.hasError('pattern')">
              Email is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray">
        <div class="col-lg-4 my-auto">
          Community property state considerations?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Community property state considerations?" formControlName="community_state">
            <mat-radio-button [checked]="community_state.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="community_state.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language_code" placeholder="Select your language">
              <mat-option *ngFor="let language_code of language_codes" [value]="language_code.key">{{language_code.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && language_code.hasError('required')">
              Language code is required
            </mat-error>
            <mat-hint>The language you communicate with your loan officer</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="language_code.value==='OTH'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Other language</mat-label>
            <input type="text" formControlName="other_language" matInput placeholder="Other language name" maxlength="{{MAX_LEN.XS_DESC}}">
            <mat-error *ngIf="submitted && other_language.hasError('required')">
              Other language name is required
            </mat-error>
            <mat-error *ngIf="submitted && other_language.hasError('pattern')">
              Other language name is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pt-1 pb-1">
        <div class="col fw-bold pr-font-large">
          Government Loan Only
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>CAIVRS identifier</mat-label>
            <input type="text" formControlName="caivrs_identifier" matInput placeholder="CAIVRS identifier" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="submitted && caivrs_identifier.hasError('required')">
              CAIVRS Identifier is required
            </mat-error>
            <mat-error *ngIf="submitted && caivrs_identifier.hasError('pattern')">
              CAIVRS Identifier is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
