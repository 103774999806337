import {Component, Injector, Input, OnInit} from '@angular/core';
import {NewMortgage} from '../../../../models/new-mortgage';
import {NewMortgageComponent} from '../new-mortgage/new-mortgage.component';

@Component({
  selector: 'app-view-segment-new-mortgage',
  templateUrl: './view-segment-new-mortgage.component.html',

})
export class ViewSegmentNewMortgageComponent extends NewMortgageComponent implements OnInit {
  @Input() item: NewMortgage;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
