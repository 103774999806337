import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-sandbox-loans',
  templateUrl: './view-sandbox-loans.component.html',
})
export class ViewSandboxLoansComponent extends CompBaseComponent implements OnInit, OnDestroy {
  static checkbox = {
    activeTrans: true,
    inactiveTrans: false,
    terminatedTrans: false
  };
  public trans: Array<Tran>;
  public filteredTrans: Array<Tran>;
  public pageTitle = 'My Sandbox Loans';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.loansList
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload(false);
    this.reloadOnSync();
  }

  createFormControls() {
  }

  createForm() {
    this.form = new FormGroup({});
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.tranService.getTrans(force)
      .then((trans) => {
        this.trans = trans;
        this.filteredTrans = this.trans.filter((elt) => {
          return elt.isSandboxTran();
        });
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  onFilterChange(event: any): void {
    this.reload();
  }

  navigateToTran(tran: Tran): void {
    const user = this.userService.getUserSync();
    this.tranService.getTran(tran.id)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'trans'});
      })
      .then(() => {
        if (user.isBorrower()) {
          this.router.navigate(['/loan/view-directory']);
          return;
        }
      });
  }
}
