<mat-card *ngIf="reloaded">
  <mat-card-title>View Organization</mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{organization.name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Type</div>
      <div class="col-lg-8">{{organization.getOrgType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{organization.description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">NMLS</div>
      <div class="col-lg-8">{{organization.nmls}}</div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button (click)="editOrganization($event)" class="btn btn-lg btn-primary">Edit</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'Organization Users'" [help]="true" [op]="'add'">
      <b>Managing Your Organization Loan Officers</b>
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          You can add loan officers to your organization if
          <ul>
            <li>
              they have the same company NMLS ID as your organization's, and
            </li>
            <li>
              they are not a member of another organization.
            </li>
          </ul>
        </li>
        <li>
          The organization admin can remove a user from their organization. Thereafter, the user can be added to
          another organization by the organization Admin.
        </li>
      </ul>
      <b>Managing Free Loan Officer Accounts</b>
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          You can add free loan officer accounts to your organization for the
          <b>sole purpose of generating your organization MCR</b>.
        </li>
        <li>
          Click the Add (plus) button to add a new free loan officer account.
        </li>
        <li>
          When you add/update a free loan officer account, add/update the state(s) where they
          <u>and</u> your company are licensed
          to operate.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Sys Role</th>
          <th>Org Role</th>
          <th>Name</th>
          <th>States Licensed</th>
          <th>Email</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="editUser($event,user)" *ngFor="let user of organization.users" class="hand-pointer">
          <td>{{user.id}}</td>
          <td>{{user.getRolesString()}}</td>
          <td>{{user.getOrgRoleString()}}</td>
          <td>{{user.full_name}}</td>
          <td>{{user.getStates()}}</td>
          <td>{{user.isLoanOfficer() ? user.email : 'N/A'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>

  <app-add-segment-org-user *ngIf="mode==='Add'" (eventEmitted)="onEvent($event)" [item]="organization"></app-add-segment-org-user>

  <app-edit-segment-org-user *ngIf="mode==='EditUser'" (eventEmitted)="onEvent($event)" [user]="user" [item]="organization"></app-edit-segment-org-user>
</mat-card>
