import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
})
export class BoxComponent implements OnInit {
  @ViewChild('box', {static: true}) private boxElement: ElementRef;
  @HostBinding('style.width') boxWidth = '100%';
  @HostBinding('style.display') boxDisplay = 'inline-block';
  @Input() type: string;
  @Input() close = true;
  @Input() fadeOut = false;
  @Output() closed = new EventEmitter();
  alertClass: string;

  constructor() {
  }

  ngOnInit() {
    this.alertClass = this.type ? 'alert-' + this.type : 'alert-secondary';
    const nativeElt = this.boxElement.nativeElement;
    if (!this.fadeOut) {
      return;
    }
    setTimeout(() => {
      $(nativeElt).fadeOut(3000, () => {
        $(nativeElt).parent().remove();
      });
    }, 3000);
  }

  closeBox(event: any): void {
    event.preventDefault();
    const nativeElt = this.boxElement.nativeElement;
    $(nativeElt).parent().remove();
    this.closed.emit();
  }
}
