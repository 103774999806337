import {Component, Injector, OnInit} from '@angular/core';
import {NewMortgageComponent} from '../new-mortgage/new-mortgage.component';

@Component({
  selector: 'app-edit-segment-new-mortgage',
  templateUrl: '../new-mortgage/edit-segment-new-mortgage.component.html',
})
export class EditSegmentNewMortgageComponent extends NewMortgageComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
