import {Component, Injector, OnInit} from '@angular/core';
import {FinancialRatioComponent} from '../financial-ratio/financial-ratio.component';

@Component({
  selector: 'app-edit-segment-financial-ratio',
  templateUrl: '../financial-ratio/edit-segment-financial-ratio.component.html',
})
export class EditSegmentFinancialRatioComponent extends FinancialRatioComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.qualifyLoan = Object.keys(this.ql).length ? Object.assign({}, this.ql) : this.resetFinancialRatios();
    if (this.qualifyLoan) {
      this.createFormControls();
      this.createForm();
    }
    this.reloaded = true;
  }

  compute(event: any) {
    event.preventDefault();
    this.setValuesFromForm();
    this.computeRatios(this.qualifyLoan);
    this.eventEmitted.emit({type: 'Compute', payload: Object.assign({}, this.qualifyLoan)});
  }

  setValuesFromForm(): void {
    this.qualifyLoan.monthlyLoanPayment = this.monthlyLoanPayment.value;
    this.qualifyLoan.monthlyRealEstateTaxes = this.monthlyRealEstateTaxes.value;
    this.qualifyLoan.monthlyHazardInsurance = this.monthlyHazardInsurance.value;
    this.qualifyLoan.monthlyMortgageInsurance = this.monthlyMortgageInsurance.value;
  }

  reset(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Reload'});
  }
}
