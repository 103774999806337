import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Invoice} from '../../../../models/invoice';
import {InvoiceService} from '../../../../services/invoice.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-admin-invoice',
  templateUrl: './admin-edit-invoice.component.html',
})
export class AdminInvoiceComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Invoice;
  public op: string;
  public invoiceId: number;
  public invoiceService: InvoiceService;
  public invoice_statuses = Invoice.status_table;
  form: FormGroup;
  amount: FormControl;
  status: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.invoiceService = this.injector.get(InvoiceService);
    super.ngOnInit();
  }

  createFormControls(item: Invoice) {
    this.amount = new FormControl(item.amount);
    this.status = new FormControl(item.status);
  }

  createForm() {
    this.form = new FormGroup({
      amount: this.amount,
      status: this.status,
    });
  }
}
