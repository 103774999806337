import {Component, Injector, OnInit} from '@angular/core';
import {AddressComponent} from '../address/address.component';
import {Address} from '../../../../models/address';

@Component({
  selector: 'app-add-address',
  templateUrl: '../address/edit-segment-address.component.html',
})
export class AddAddressComponent extends AddressComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Address({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
