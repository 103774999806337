import {Component, Injector, Input, OnInit} from '@angular/core';
import {Race} from '../../../../models/race';
import {RaceComponent} from '../race/race.component';

@Component({
  selector: 'app-view-segment-race',
  templateUrl: './view-segment-race.component.html',
})
export class ViewSegmentRaceComponent extends RaceComponent implements OnInit {
  @Input() item: Race;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
