<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Borrower name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="borrower_name" matInput placeholder="Borrower name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="borrower_name.hasError('required')">
          Borrower name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row" [hidden]="borrowerCount<=1">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Co-Borrower name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="co_borrower_name" matInput placeholder="Co-Borrower name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="co_borrower_name.hasError('required')">
          Co-Borrower name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row" [hidden]="borrowerCount<=2">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Borrower 3 name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="borrower3_name" matInput placeholder="Borrower 3 name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="borrower3_name.hasError('required')">
          Borrower 3 name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row" [hidden]="borrowerCount<=3">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Borrower 4 name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="borrower4_name" matInput placeholder="Borrower 4 name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="borrower4_name.hasError('required')">
          Borrower 4 name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>ECOA address</mat-label>
        <input formControlName="ecoa_address" matInput placeholder="ECOA address" maxlength="{{MAX_LEN.FULL_ADDR}}" required>
        <mat-error *ngIf="ecoa_address.hasError('required')">
          ECOA address is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date" required>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Document date is unrealistic
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Document date format is invalid
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genECOA()" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</form>
