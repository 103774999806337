import {Component, Injector, OnInit} from '@angular/core';
import {AdminUserComponent} from '../admin-user.component';
import {Help} from '../../../../models/help';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-add-user',
  templateUrl: '../admin-edit-user.component.html',
})
export class AdminAddUserComponent extends AdminUserComponent implements OnInit {
  pageTitle = 'New User';
  user: any;

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Add';
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.createFormControls(new User({}));
    this.createForm();
    this.reloaded = true;
  }

  cancel(): void {
    this.router.navigate(['/admin/find-users']);
  }
}
