import {Component, Injector, Input, OnInit} from '@angular/core';
import {ConfigComponent} from '.././config/config.component';
import {Router} from '@angular/router';
import {ConfigItem} from "../../../models/config-item";

@Component({
  selector: 'app-view-segment-config',
  templateUrl: './view-segment-config.component.html',
})
export class ViewSegmentConfigComponent extends ConfigComponent implements OnInit {
  @Input() item: ConfigItem;

  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Edit') {
      this.router.navigate(['admin/edit-config'], {queryParams: {config_key: this.item.config_key}});
      return;
    }

    super.onEvent(event);
  }
}
