import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../../../form-util';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-co-tnb-doc',
  templateUrl: './edit-co-tnb-doc.component.html',
})
export class EditCOTnbDocComponent extends GenerateDocumentBaseComponent implements OnInit {
  public txSummary: any;
  public is_co_borrower: boolean;
  public submitted: boolean;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  @Input() folderId: string;

  // form and form controls
  public form: FormGroup;
  public mlo_name: FormControl;
  public mlo_nmls: FormControl;
  public mlo_license: FormControl;
  public doc_date: FormControl;
  public new_purchase: FormControl;
  public disclosed_income: FormControl;
  public refi_lower_interest: FormControl;
  public refi_lower_monthly_payment: FormControl;
  public refi_fixed_rate: FormControl;
  public refi_amortization: FormControl;
  public refi_mi: FormControl;
  public refi_debt_consolidation: FormControl;
  public refi_arm: FormControl;
  public refi_other: FormControl;
  public refi_other_text: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.formType = 'CO_TNB';
  }

  ngOnInit() {
    super.ngOnInit();
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.submitted = false;
    this.generating = false;
    this.is_co_borrower = this.txSummary.isCoBorrower;
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.mlo_name = new FormControl(this.txSummary.mloFullName);
    this.mlo_nmls = new FormControl(this.txSummary.mloNMLS);
    this.mlo_license = new FormControl(this.txSummary.mloStateLicenseNumber);
    this.new_purchase = new FormControl(this.savedDoc['new_purchase'], AppValidators.present);
    this.disclosed_income = new FormControl(this.savedDoc['disclosed_income']);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
    this.refi_lower_interest = new FormControl(this.savedDoc['refi_lower_interest']);
    this.refi_lower_monthly_payment = new FormControl(this.savedDoc['refi_lower_monthly_payment']);
    this.refi_fixed_rate = new FormControl(this.savedDoc['refi_fixed_rate']);
    this.refi_amortization = new FormControl(this.savedDoc['refi_amortization']);
    this.refi_mi = new FormControl(this.savedDoc['refi_mi']);
    this.refi_debt_consolidation = new FormControl(this.savedDoc['refi_debt_consolidation']);
    this.refi_arm = new FormControl(this.savedDoc['refi_arm']);
    this.refi_other = new FormControl(this.savedDoc['refi_other']);
    this.refi_other_text = new FormControl(this.savedDoc['refi_other_text']);
  }

  createForm(): void {
    this.form = new FormGroup({
      mlo_name: this.mlo_name,
      mlo_nmls: this.mlo_nmls,
      mlo_license: this.mlo_license,
      new_purchase: this.new_purchase,
      disclosed_income: this.disclosed_income,
      doc_date: this.doc_date,
      refi_lower_interest: this.refi_lower_interest,
      refi_lower_monthly_payment: this.refi_lower_monthly_payment,
      refi_fixed_rate: this.refi_fixed_rate,
      refi_amortization: this.refi_amortization,
      refi_mi: this.refi_mi,
      refi_debt_consolidation: this.refi_debt_consolidation,
      refi_arm: this.refi_arm,
      refi_other: this.refi_other,
      refi_other_text: this.refi_other_text,
    });
  }

  async genCOTNB(): Promise<boolean> {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const refinanceBenefitSelected = this.refi_lower_interest.value ||
      this.refi_lower_monthly_payment.value ||
      this.refi_fixed_rate.value ||
      this.refi_amortization.value ||
      this.refi_mi.value ||
      this.refi_debt_consolidation.value ||
      this.refi_arm.value ||
      this.refi_other.value;

    if (!this.new_purchase.value && !refinanceBenefitSelected) {
      FormUtil.genErrorMessage(this.sharedService, 'You must choose at least one tangible net benefit', null);
      return;
    }

    if (this.new_purchase.value) {
      this.refi_lower_interest.setValue(false);
      this.refi_lower_monthly_payment.setValue(false);
      this.refi_fixed_rate.setValue(false);
      this.refi_amortization.setValue(false);
      this.refi_mi.setValue(false);
      this.refi_debt_consolidation.setValue(false);
      this.refi_arm.setValue(false);
      this.refi_other.setValue(false);
    }

    this.generating = true;
    const formData = this.form.value;
    formData['doc_date'] = moment(this.doc_date.value).format('MM/DD/YYYY');
    formData['is_co_borrower'] = this.txSummary.isCoBorrower;
    formData['lender_case_no'] = this.txSummary.lenderCaseNo;
    formData['icn_id'] = this.folderId || null;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
