import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {UserConfiguration} from '../../../../models/user-configuration';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-view-segment-user-configuration',
  templateUrl: './view-segment-user-configuration.component.html',
})
export class ViewSegmentUserConfigurationComponent extends CompBaseComponent implements OnInit {
  @Input() item: UserConfiguration;
  public states: Array<any>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.states = Object.keys(this.item.licenses);
  }

  enableAddStateLicense(event: any): void {
    event.preventDefault();
    this.mode = 'Add';
  }

  addStateLicense(state: string, license: string): void {
    const updated = this.item;
    updated.licenses[state] = license;
    updated.id = this.item.id;
    this.userService.updateUserConfiguration(updated)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'MLO configuration successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update MLO configuration', data);
        this.cancel();
      });
  }

  delete(event: any, state: string): void {
    event.preventDefault();
    const updated = this.item;
    delete updated.licenses[state];
    updated.id = this.item.id;
    this.userService.updateUserConfiguration(updated)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'State license successfully deleted');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete state license', data);
        this.cancel();
      });
  }

  onEvent(event: any): void {
    if (event.type === 'NewLicense') {
      this.addStateLicense(event.state, event.license);
      return;
    }
    super.onEvent(event);
  }
}
