import {Component, Injector, Input, OnInit} from '@angular/core';
import {Concession} from '../../../../models/concession';
import {ConcessionComponent} from '../concession/concession.component';

@Component({
  selector: 'app-view-segment-concession',
  templateUrl: './view-segment-concession.component.html',

})
export class ViewSegmentConcessionComponent extends ConcessionComponent implements OnInit {
  @Input() item: Concession;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
