<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Your Services
  </mat-card-title>

  <mat-card-content>
    <p>
      A display of all services you have access to.
    </p>
  </mat-card-content>
</mat-card>
