<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>MLO NMLS ID</mat-label>
        <input [readonly]="true" class="text-primary" appNumberOnly formControlName="mlo_nmls" matInput placeholder="MLO NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
        <mat-error *ngIf="mlo_nmls.hasError('required')">
          MLO NMLS ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>MLO state license number</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_license" matInput placeholder="MLO state license number" maxlength="{{MAX_LEN.GEN_ID}}">
        <mat-error *ngIf="mlo_license.hasError('required')">
          MLO state license number is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date" required>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Document date is unrealistic
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Document date format is invalid
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <h5>Loan purpose</h5>

  <div class="row">
    <div class="col-lg-4">
      <mat-radio-group aria-label="new_purchase" formControlName="new_purchase">
        <mat-radio-button [checked]="new_purchase.value===true" [value]="true">New Purchase</mat-radio-button>
        <mat-radio-button [checked]="new_purchase.value===false" [value]="false">Refinance</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="submitted&&new_purchase.hasError('required')">
        Loan purpose is required
      </mat-error>
    </div>
  </div>

  <div *ngIf="new_purchase.value===false">
    <h5>Check Tangible Net Benefits for this Refinance Loan</h5>
    <div>
      <mat-checkbox formControlName="refi_lower_interest">The new loan will have a lower interest rate or APR.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_lower_monthly_payment">The new loan will have a lower monthly payment.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_fixed_rate">The new loan is a fixed rate loan.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_amortization">The new loan will have a shorter amortization schedule.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_mi">The new loan will eliminate the need for private mortgage insurance.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_debt_consolidation">Cash out refinance: The new loan will consolidate other loans or current debt.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_arm">The new loan is an Adjustable Rate Loan.</mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="refi_other">Other tangible net benefit.</mat-checkbox>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <mat-form-field *ngIf="refi_other.value===true" class="w-100">
          <textarea cdkAutosizeMaxRows="2" cdkAutosizeMinRows="2" maxlength="{{MAX_LEN.DESC}}"
            cdkTextareaAutosize
            formControlName="refi_other_text"
            matInput
            placeholder="Other tangible net benefit" required>
          </textarea>
          <mat-error *ngIf="refi_other_text.hasError('required')">
            Other tangible net benefit is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <h5>Disclosed Income</h5>
    <mat-checkbox formControlName="disclosed_income">Income is sufficient to make mortgage payments.</mat-checkbox>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genCOTNB()" class="btn btn-lg btn-primary">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</form>
