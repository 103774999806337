<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Concession type</mat-label>
            <mat-select formControlName="concession_type" placeholder="Select concession type" required>
              <mat-option *ngFor="let concession_type of concession_types" [value]="concession_type.key">{{concession_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="concession_type.hasError('required')">
              Concession type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Concession amount</mat-label>
            <input appDecimalOnly formControlName="concession_amount" matInput placeholder="Concession amount" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="concession_amount.hasError('required')">
              Concession amount is required
            </mat-error>
            <mat-error *ngIf="concession_amount.hasError('pattern')">
              Concession amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
