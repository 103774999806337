import {ModelBase} from './model-base';
import {TranMcr} from './tran-mcr';
import {Permission} from './permission';

export class Mcr extends ModelBase {
  public static TYPE = {
    LO: 1,
    ORG: 2,
    TRAN: 4
  };
  public static quarters = [
    {key: 1, value: 'Q1'},
    {key: 2, value: 'Q2'},
    {key: 3, value: 'Q3'},
    {key: 4, value: 'Q4'},
  ];
  public static years = [
    {key: 2020, value: '2020'},
    {key: 2021, value: '2021'},
    {key: 2022, value: '2022'},
    {key: 2023, value: '2023'},
    {key: 2024, value: '2024'},
  ];

  id: number;
  mcr_type: number;
  state: string;
  tran_id: number;
  owner_id: number;
  owner_full_name: string;
  organization_id: number;
  encrypted: boolean;
  encrypt_version: string;
  name: string;
  description: string;
  year: number;
  quarter: number;
  content: string;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  trans: Array<any>;
  users: Array<any>;
  permissions: Array<Permission>;
  // derived properties
  tranMcr: TranMcr;

  constructor(object: any) {
    super();
    if (object) {
      for (const prop of Object.keys(object)) {
        if (prop === 'deleted_at' || prop === 'created_at' || prop === 'updated_at') {
          this[prop] = this.toJSDate(object[prop]);
        } else if (prop === 'permissions') {
          this[prop] = Permission.deserializeArray(object[prop]);
        } else {
          this[prop] = object[prop];
        }
      }
      if (this.mcr_type === Mcr.TYPE.TRAN) {
        this.tranMcr = this.content && this.content['mcr'] ? (new TranMcr(this.content['mcr'])) : new TranMcr({tran_id: this.tran_id});
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Mcr> {
    const result = [];
    if (!arr) {
      return result;
    }
    for (const obj of arr) {
      const item = Mcr.deserialize(obj);
      result.push(item);
    }

    return result;
  }

  static deserialize(obj): Mcr {
    return new Mcr(obj);
  }

  isOrgMcr(): boolean {
    return this.mcr_type === Mcr.TYPE.ORG;
  }

  isLoMcr(): boolean {
    return this.mcr_type === Mcr.TYPE.LO;
  }

  isTranMcr(): boolean {
    return this.mcr_type === Mcr.TYPE.TRAN;
  }

  getSortValue(): string {
    return this.owner_full_name + this.name;
  }
}
