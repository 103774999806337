import {Component, Injector, Input, OnInit} from '@angular/core';
import {RealEstate} from '../../../../models/real-estate';
import {RealEstateComponent} from '../real-estate/real-estate.component';

@Component({
  selector: 'app-view-real-estate',
  templateUrl: './view-real-estate.component.html',
})
export class ViewRealEstateComponent extends RealEstateComponent implements OnInit {
  @Input() item: RealEstate;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
