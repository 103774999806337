<div class="row">
  <div class="col">
    <div class="ms-5 mt-1">
      <a aria-label="Logo Image" class="hand-pointer" href='/'>
        <img alt="Logo" src='/assets/img/prudentco-logo-small.png'
          style="height: 70px;width:auto;">
      </a>
    </div>
  </div>
</div>
