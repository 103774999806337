import {Component, Injector, Input, OnInit} from '@angular/core';
import {Information} from '../../../../models/information';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-information',
  templateUrl: './view-segment-information.component.html',

})
export class ViewSegmentInformationComponent extends CompBaseComponent implements OnInit {
  @Input() item: Information;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'information';
    this.section = this.applicant;
  }
}
