import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fnmaDateToString'
})
export class FnmaDateToStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    const year = value.substr(0, 4);
    const month = value.substr(4, 2);
    const day = value.substr(6, 2);
    const date = moment(`${year}-${month}-${day}`);

    return date.format('MM/DD/YYYY');
  }
}
