import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {FolderGroup} from '../../../models/folder-group';
import {Folder} from '../../../models/folder';

@Component({
  selector: 'app-view-folder-group',
  templateUrl: './view-folder-group.component.html',
})
export class ViewFolderGroupComponent extends CompBaseComponent implements OnInit {
  @Input() folderGroup: FolderGroup;
  public folders: Array<Folder>;
  public mode = 'View';
  public canUpdateTransaction: boolean;
  public collapsed: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.collapsed = false;
    this.reload();
  }

  add(): void {
    this.mode = 'Add';
  }

  cancel(): void {
    this.reload();
  }

  edit(): void {
    if (this.canUpdateTransaction) {
      super.edit();
    }
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }

  // re-populate items from original input folderGroup
  reload(force?: boolean): void {
    this.folders = this.folderGroup.items;
  }
}
