import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {UserConfiguration} from '../../../../models/user-configuration';

@Component({
  selector: 'app-view-user-configuration',
  templateUrl: './view-user-configuration.component.html',
})
export class ViewUserConfigurationComponent extends CompBaseComponent implements OnInit {
  @Input() public item: UserConfiguration;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
