import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Message} from '../models/message';
import {CacheService} from './cache.service';

@Injectable()
export class MessageService {

  constructor(private httpService: HttpService, private cacheService: CacheService) {
    this.cacheService.resetSeg('message');
  }

  getMessages(force): Promise<Array<Message>> {
    const messages = this.getMessagesSync();
    if (!force && messages) {
      return Promise.resolve(messages);
    }

    return new Promise((resolve, reject) => {
      this.httpService.get('messages')
        .then((data) => {
          const messages = Message.deserializeArray(data);
          this.cacheService.setSeg('message', 'messages', messages);
          resolve(messages);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  sendMessage(payload): Promise<Message> {
    return new Promise((resolve, reject) => {
      this.httpService.post('messages', payload)
        .then((data) => {
          this.cacheService.resetSeg('message', 'messages');
          resolve(new Message(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  deleteMessage(messageId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('messages/' + messageId)
        .then((data) => {
          this.cacheService.resetSeg('message', 'messages');
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  sortMessagesReverse(): void {
    if (this.getMessagesSync().length === 0) {
      return;
    }

    this.getMessagesSync().sort((x, y) => {
      if (x.created_at === y.created_at) {
        return 0;
      }
      if (x.created_at <= y.created_at) {
        return 1;
      }

      return -1;
    });
  }

  public getMessagesSync(): Array<any> {
    return this.cacheService.getSeg('message', 'messages');
  }
}
