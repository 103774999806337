import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Income} from '../../../../models/income';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-income',
  template: '',
})
export class IncomeComponent extends CompBaseComponent implements OnInit {
  @Input() item: Income;
  public incomes = Income.incomes;
  public income_types = Income.income_types;
  public employment_sources = Income.employment_sources;
  public other_income_sources = Income.other_income_sources;
  public sources = [[], this.employment_sources, this.other_income_sources];
  public index = 0;

  // form and form fields
  public form: FormGroup;
  public code_ex: FormControl;
  public amount: FormControl;
  public income_type: FormControl;
  public other_description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'incomes';
    this.section = this.applicant;
  }

  onEmpTypeChange(): void {
    this.setIncomeSources();
  }

  setIncomeSources(): void {
    const val = this.income_type.value;
    if (val === 'P' || val === 'S') {
      this.index = 1;
    } else {
      this.index = 2;
    }
  }

  createFormControls(item: Income): void {
    this.code_ex = new FormControl(item.code_ex, AppValidators.present);
    this.amount = new FormControl(item.amount,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
    this.income_type = new FormControl(item.income_type);
    this.other_description = new FormControl(item.other_description);
    this.setIncomeSources();
  }

  createForm(): void {
    this.form = new FormGroup({
      code_ex: this.code_ex,
      amount: this.amount,
      income_type: this.income_type,
      other_description: this.other_description,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Income(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Income(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
