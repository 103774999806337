import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Organization} from '../../../../models/organization';
import {OrganizationService} from '../../../../services/organization.service';
import {FormUtil} from '../../../form-util';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-view-user-org',
  templateUrl: './view-user-org.component.html',
})
export class ViewUserOrgComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'User Organization';
  public userOrg: Organization;
  public userOrgInfo: Organization;
  public orgService: OrganizationService;
  public userOrgInfoRetrieved: boolean;
  public isCompanyNmls: boolean;
  public user: User;
  public canDeleteLogo: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.orgService = this.injector.get(OrganizationService);
    this.reload(false);
    this.reloadOnSync();
    if (this.isTitle) {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
        });
      }, 0);
    }
  }

  uploadLogo(event: any): void {
    event.preventDefault();
    this.mode = 'Upload';
  }

  onCancel() {
    this.mode = 'View';
  }

  deleteLogo(): void {
    this.orgService.deleteOrgLogo()
      .then(() => this.userService.refreshUser())
      .then(() => this.reload(true))
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload'});
        this.eventEmitted.emit({type: 'Reload'});
      }).catch((data) => {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to delete logo', data);
      this.eventEmitted.emit({type: 'Reload'});
    });
  }

  onUploadComplete(): void {
    this.tranService.getCurrentTran(true)
      .then(() => this.userService.refreshUser())
      .then(() => this.reload(true))
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload'});
        this.eventEmitted.emit({type: 'Reload'});
      }).catch((data) => {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to upload logo', data);
      this.eventEmitted.emit({type: 'Reload'});
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    if (typeof force === 'undefined') {
      force = true;
    }
    this.mode = 'View';
    this.reloaded = false;
    this.user = this.userService.getUserSync();
    const companyNmls = this.user.getCompanyNmls();
    this.isCompanyNmls = companyNmls && (companyNmls.length > 0);
    const orgId = this.user.organization_id;
    if (!orgId) {
      this.userOrg = null;
    }
    this.userService.getDefaultOrganization(force)
      .then((org) => {
        this.userOrg = org;
        this.getUserOrgInfo()
          .then((rc) => {
            if (this.userOrg && this.userOrg.logo_url) {
              this.canDeleteLogo = this.userOrg.logo_url.indexOf('prudentco') < 0;
            } else {
              this.canDeleteLogo = false;
            }
            this.mode = rc ? 'View' : 'Add';
            setTimeout(() => {
              this.reloaded = true;
            }, 0);
          })
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to get organization', data);
      });
  }

  displayCreate(event: any): void {
    event.preventDefault();
    this.mode = 'Add';
  }

  async getUserOrgInfo(): Promise<boolean> {
    this.userOrgInfoRetrieved = true;
    return this.orgService.getUserOrgInfo()
      .then((userOrgInfo) => {
        this.userOrgInfo = userOrgInfo;
        this.userOrgInfoRetrieved = true;
        return Promise.resolve(true);
      })
      .catch((data) => {
        this.userOrgInfo = null;
        this.userOrgInfoRetrieved = true;
        return Promise.resolve(false);
      })
  }
}
