import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appDecimalThreeOnly]'
})
export class DecimalThreeOnlyDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d{1,3}(\.\d{0,3})?$/g);
  // Allow key codes for special events: Backspace, tab, end, home, dash, left and right arrows, and delete
  private specialKeys: Array<string> = ['Enter', 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // allow also all meta keys (they are browser dependent)
    if (this.specialKeys.indexOf(event.key) >= 0 || event.metaKey) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
