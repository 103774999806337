<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Information</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Mortgage type</mat-label>
            <mat-select formControlName="mortgage_type_ex" placeholder="Select mortgage type" required>
              <mat-option *ngFor="let mortgage_type of mortgage_types_ex" [value]="mortgage_type.key">{{mortgage_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mortgage_type_ex.hasError('required')">
              Mortgage type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Amortization type</mat-label>
            <mat-select formControlName="amortization_type_ex" placeholder="Select amortization type">
              <mat-option *ngFor="let amortization_type of amortization_types_ex" [value]="amortization_type.key">{{amortization_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="amortization_type_ex.hasError('required')">
              Amortization type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="amortization_type_ex.value ==='Other'" class="row">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>Other amortization type explanation</mat-label>
            <input type="text" [required]="amortization_type_ex.value==='Other'" formControlName="amortization_type_explain" matInput placeholder="Explain other amortization type" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="amortization_type_explain.hasError('required')">
              Explain other amortization type
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Agency case number</mat-label>
            <input type="text" formControlName="agency_case_no" matInput placeholder="Agency case number" maxlength="30">
            <mat-error *ngIf="agency_case_no.hasError('required')">
              Agency case number is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Lender case number</mat-label>
            <input type="text" formControlName="lender_case_no" matInput placeholder="Lender case number" maxlength="15">
            <mat-error *ngIf="lender_case_no.hasError('required')">
              Lender case number is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Base loan amount</mat-label>
            <input type="text" appDecimalOnly formControlName="loan_amount" matInput placeholder="Base loan amount" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="loan_amount.hasError('required')">
              Base loan amount is required
            </mat-error>
            <mat-hint>
              The base loan amount (without upfront MIP or financed funding fee)
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Interest rate</mat-label>
            <input type="text" appDecimalThreeOnly formControlName="interest_rate" matInput placeholder="Interest rate" maxlength="7" required>
            <mat-error *ngIf="interest_rate.hasError('required')">
              Interest rate is required
            </mat-error>
            <mat-error *ngIf="interest_rate.hasError('pattern')">
              Interest rate is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Amortization period</mat-label>
            <mat-select formControlName="amortization_period_type" placeholder="Select amortization period">
              <mat-option *ngFor="let amortization_period of amortization_period_types" [value]="amortization_period.key">{{amortization_period.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="amortization_period_type.hasError('required')">
              Amortization period is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Number of periods</mat-label>
            <input type="text" appNumberOnly formControlName="periods_no" matInput placeholder="Number of periods" maxlength="3" required>
            <mat-error *ngIf="periods_no.hasError('required')">
              Number of periods is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isRefinanceLoan">
        <div class="row pt-1 pb-3">
          <div class="col fw-bold pr-font-large">
            Government Loan only
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Government refinance type</mat-label>
              <mat-select formControlName="gov_refinance_type" placeholder="Select government refinance type">
                <mat-option *ngFor="let gov_refinance_type of refinance_types" [value]="gov_refinance_type.key">{{gov_refinance_type.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="amortization_period_type.hasError('required')">
                Government refinance type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="gov_refinance_type.value==='Other'">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Other refinance description</mat-label>
              <input type="text" formControlName="other_description" matInput placeholder="Describe other refinance" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="other_description.hasError('required')">
                Other refinance description is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
