<app-card *ngIf="true" [align]="'center'" [title]="'Site Under Maintenance'">
  <span class="material-icons text-danger">highlight_off</span>

  <p class="text-danger">
    <strong>Sorry for the inconvenience!</strong>
  </p>
  <p>
    No worries, POLOS will be online soon.
  </p>
</app-card>
