import {Component, Injector, OnInit} from '@angular/core';
import {AliasComponent} from '../alias/alias.component';

@Component({
  selector: 'app-view-alias',
  templateUrl: './view-alias.component.html',
})
export class ViewAliasComponent extends AliasComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
