import {ModelBase} from './model-base';

export class Concession extends ModelBase {
  public static concession_types = [
    {key: 'Automobile', value: 'Automobile'},
    {key: 'ClosingCosts', value: 'Closing Costs'},
    {key: 'Downpayment', value: 'Down Payment'},
    {key: 'InteriorDecorationAllowance', value: 'Interior Decoration Allowance'},
    {key: 'Other', value: 'Other'},
    {key: 'Prepaids', value: 'Prepaids'},
    {key: 'Repairs', value: 'Repairs'},
    {key: 'ThirdPartyFinancingDiscounts', value: 'Third Party Financing Discounts'},
    {key: 'Unknown', value: 'Unknown'},
  ];

  index: number;
  concession_type: string;
  concession_amount: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'concession_amount') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Concession> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Concession(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getConcessionType(): string {
    const found = Concession.concession_types.find((elt) => elt.key === this.concession_type);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      concession_type: this.concession_type,
      concession_amount: this.toServerDecimal(this.concession_amount)
    };
  }
}
