import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Document} from '../../../models/document';

@Component({
  selector: 'app-view-workflow',
  templateUrl: './view-workflow.component.html',
})
export class ViewWorkflowComponent extends CompBaseComponent implements OnInit {
  @Input() doc: Document;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = true;
  }
}
