import {ModelBase} from './model-base';

export class LoanCharacteristics extends ModelBase {
  public static lien_type_codes_ex: Array<any> = [
    {key: 'FirstLien', value: 'First Lien'},
    {key: 'SecondLien', value: 'Second Lien'},
    {key: 'ThirdLien', value: 'Third Lien'},
    {key: 'FourthLien', value: 'Fourth Lien'},
    {key: 'Other', value: 'Other'},
  ];
  public static qm_types: Array<any> = [
    {key: 'Standard', value: 'Standard (QM)'},
    {key: 'NotAQualifiedMortgage', value: 'Not a Qualified Mortgage (Non-QM)'},
    {key: 'NotApplicable', value: 'Not Applicable (Not Subject to QM)'},
  ];

  public static maturity_types: Array<any> = [
    {key: 'Biweekly', value: 'Bi-Weekly'},
    {key: 'Day', value: 'Day'},
    {key: 'Month', value: 'Month'},
    {key: 'Quarter', value: 'Quarter'},
    {key: 'Semimonthly', value: 'Semi-Monthly'},
    {key: 'Week', value: 'Week'},
    {key: 'Year', value: 'Year'},
  ];

  lien_type_code_ex: string;
  balloon_indicator: boolean;
  balloon_payment_amount: string;
  homebuyer_education_completion_indicator: boolean;
  maximum_lifetime_rate_increase: string;
  mi_coverage_percent: string;
  apr_spread: string;
  hoepa_indicator: boolean;
  qm_type: string;
  is_reverse: boolean;
  maturity_periods_no: string;
  maturity_type: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'maximum_lifetime_rate_increase'
        || prop === 'mi_coverage_percent'
        || prop === 'apr_spread'
        || prop === 'maturity_periods_no'
      ) {
        this[prop] = super.getNumberProp(prop, object);
      } else if (prop === 'balloon_indicator'
        || prop === 'homebuyer_education_completion_indicator'
        || prop === 'hoepa_indicator'
        || prop === 'is_reverse'
      ) {
        this[prop] = super.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getMaximumLifetimeRateIncrease(): number {
    return this.maximum_lifetime_rate_increase ? +this.maximum_lifetime_rate_increase / 100 : null;
  }

  getMICoveragePercent(): number {
    return this.mi_coverage_percent ? +this.mi_coverage_percent / 100 : null;
  }

  getAPRSpread(): number {
    return this.apr_spread ? +this.apr_spread / 100 : null;
  }

  getLienTypeCode(): string {
    const found = LoanCharacteristics.lien_type_codes_ex.find((elt) => elt.key === this.lien_type_code_ex);
    return found ? found['value'] : '';
  }

  getMaturityType(): string {
    const found = LoanCharacteristics.maturity_types.find((elt) => elt.key === this.maturity_type);
    return found ? found['value'] : '';
  }

  getQMType(): string {
    const found = LoanCharacteristics.qm_types.find((elt) => elt.key === this.qm_type);
    return found ? found['value'] : '';
  }

  isHOEPA(): boolean {
    return this.hoepa_indicator;
  }

  isFirstLien(): boolean {
    return this.lien_type_code_ex === 'FirstLien';
  }

  isSubordinateLien(): boolean {
    return this.lien_type_code_ex !== 'FirstLien' && this.lien_type_code_ex !== 'Other';
  }

  isOtherLien(): boolean {
    return this.lien_type_code_ex === 'Other';
  }

  toServerPayload(): any {
    return {
      lien_type_code_ex: this.lien_type_code_ex,
      balloon_indicator: ModelBase.toServerExplicitBoolean(this.balloon_indicator),
      maturity_type: this.maturity_type,
      maturity_periods_no: this.toServerString(this.maturity_periods_no),
      balloon_payment_amount: this.toServerDecimal(this.balloon_payment_amount),
      homebuyer_education_completion_indicator: ModelBase.toServerExplicitBoolean(this.homebuyer_education_completion_indicator),
      maximum_lifetime_rate_increase: this.toServerPercentThree(this.maximum_lifetime_rate_increase),
      mi_coverage_percent: this.toServerPercentThree(this.mi_coverage_percent),
      apr_spread: this.toServerPercentTwo(this.apr_spread),
      hoepa_indicator: ModelBase.toServerExplicitBoolean(this.hoepa_indicator),
      qm_type: this.qm_type,
      is_reverse: ModelBase.toServerExplicitBoolean(this.is_reverse),
    }
  }
}
