<app-box *ngIf="canUpdateComment" [type]="alertType" class="mb-3" [close]="false">
  <div class="hand-pointer d-inline">
    <a (click)="editNote($event)" class="text-primary pr-font-large"><i class="fa fa-edit"></i></a>
  </div>

  <div *ngIf="!comment" class="d-inline ms-2">
    You can leave here a sticky note.
  </div>

  <div *ngIf="comment" class="d-inline-block ms-2" [innerHTML]="comment | nl2br">
  </div>
</app-box>

<app-box *ngIf="(!canUpdateComment)&&comment" [type]="alertType" [close]="false" class="mb-3">
  <div [innerHTML]="comment | nl2br" class="pl-1"></div>
</app-box>
