import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TransmittalData} from '../../../../models/transmittal-data';
import {globals} from '../../../../shared/globals/globals';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-buydown',
  templateUrl: './edit-segment-buydown.component.html',
})
export class EditSegmentBuydownComponent extends CompBaseComponent implements OnInit {
  @Input() public item: TransmittalData;

  // form and form controls
  form: FormGroup;
  buydown_rate: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'transmittal_data';
    this.section = 'lo';
    this.reload();
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item: TransmittalData): void {
    this.buydown_rate = new FormControl(item.buydown_rate, Validators.pattern(globals.pattern.percentThree));
  }

  createForm(): void {
    this.form = new FormGroup({
      buydown_rate: this.buydown_rate,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const item =  Object.assign({}, this.item);
    item['buydown_rate'] = this.buydown_rate.value;
    const updated: TransmittalData = new TransmittalData(item);
    const data: any = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
  }
}
