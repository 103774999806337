import {ModelBase} from './model-base';

export class ConfigItem extends ModelBase {
  public id: number;
  public config_key: string;
  public config_value: string;

  constructor(object: any) {
    super();
    if (!object) {
      return;
    }
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }

  static deserializeArray(arr: Array<any>): Array<ConfigItem> {
    const result = [];
    for (const obj of arr) {
      const item = new ConfigItem(obj);
      result.push(item);
    }

    return result;
  }
}
