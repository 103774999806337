import {Component, Injector, OnInit} from '@angular/core';
import {AddressComponent} from '../address/address.component';

@Component({
  selector: 'app-view-address',
  templateUrl: './view-address.component.html',
})
export class ViewAddressComponent extends AddressComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
