import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {LoanPurpose} from '../../../../models/loan-purpose';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-loan-purpose',
  templateUrl: './edit-segment-loan-purpose.component.html',
})
export class EditSegmentLoanPurposeComponent extends CompBaseComponent implements OnInit {
  public readonly ownership_types_ex = LoanPurpose.ownership_types_ex;
  public readonly loan_purpose_codes_ex = LoanPurpose.loan_purpose_codes_ex;
  public readonly property_residence_types_ex = LoanPurpose.property_residence_types_ex;
  @Input() public item: LoanPurpose;

  // form and form controls
  form: FormGroup;
  loan_purpose_code_ex: FormControl;
  loan_purpose_explain: FormControl;
  property_current_residence_type_ex: FormControl;
  property_residence_type_ex: FormControl;
  fha_secondary_residence: FormControl;
  existing_energy_lien: FormControl;
  expected_rental: FormControl;
  expected_net_rental: FormControl;
  mixed_use: FormControl;
  ownership_type_ex: FormControl;
  leasehold_expiration_date: FormControl;
  properties_count: FormControl;
  construction_loan_indicator: FormControl;
  public minDate = new Date();
  public submitted = false;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'loan_purpose';
    this.section = 'loan_information';
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    const yearNow = (new Date).getFullYear();
    this.loan_purpose_code_ex = new FormControl(item.loan_purpose_code_ex, AppValidators.present);
    this.loan_purpose_explain = new FormControl(item.loan_purpose_explain);
    this.property_residence_type_ex = new FormControl(item.property_residence_type_ex, AppValidators.present);
    this.property_current_residence_type_ex = new FormControl(item.property_current_residence_type_ex);
    this.fha_secondary_residence = new FormControl(item.fha_secondary_residence);
    this.existing_energy_lien = new FormControl(item.existing_energy_lien);
    this.mixed_use = new FormControl(item.mixed_use, AppValidators.present);
    this.expected_rental = new FormControl(item.expected_rental);
    this.expected_net_rental = new FormControl(item.expected_net_rental);
    this.ownership_type_ex = new FormControl(item.ownership_type_ex, AppValidators.present);
    this.leasehold_expiration_date = new FormControl(item.leasehold_expiration_date, Validators.min(yearNow));
    this.properties_count = new FormControl(item.properties_count, Validators.pattern(globals.pattern.propertiesCount));
    this.construction_loan_indicator = new FormControl(item.construction_loan_indicator);
  }

  createForm(): void {
    this.form = new FormGroup({
      loan_purpose_code_ex: this.loan_purpose_code_ex,
      loan_purpose_explain: this.loan_purpose_explain,
      property_residence_type_ex: this.property_residence_type_ex,
      property_current_residence_type_ex: this.property_current_residence_type_ex,
      fha_secondary_residence: this.fha_secondary_residence,
      existing_energy_lien: this.existing_energy_lien,
      mixed_use: this.mixed_use,
      expected_rental: this.expected_rental,
      expected_net_rental: this.expected_net_rental,
      ownership_type_ex: this.ownership_type_ex,
      leasehold_expiration_date: this.leasehold_expiration_date,
      properties_count: this.properties_count,
      construction_loan_indicator: this.construction_loan_indicator
    });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new LoanPurpose(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
