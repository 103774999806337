<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Account Settings
  </mat-card-title>

  <mat-card-content>
    <div>
      The following settings can be viewed and/or modified:
    </div>

    <ul>
      <li>Basic account settings such as name.</li>
      <li>Your role in the system (view only).</li>
      <li>Your account service(s) (view only).</li>
      <li>Text messaging phone settings.</li>
    </ul>
  </mat-card-content>
</mat-card>
