import {Component, Injector, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {Router} from '@angular/router';
import {ConfigurationService} from "../../../services/configuration.service";
import {ConfigComponent} from "../config/config.component";

@Component({
  selector: 'app-view-segment-configs',
  templateUrl: './view-segment-configs.component.html',

})
export class ViewSegmentConfigsComponent extends ConfigComponent implements OnInit {
  public pageTitle = 'POLOS Online Config';
  public configService: ConfigurationService;

  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.configService = this.injector.get(ConfigurationService);
    if (this.isMobile) {
      this.pageTitle = 'Conditions';
    }
    this.reload();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.conditions
      });
    }, 0);

  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Add') {
      this.router.navigate(['admin/add-config']);
      return;
    }
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(): void {
    this.reloaded = false;
    this.mode = 'View';
    this.items = [];

    this.configService.getConfigItems()
      .then((configItems) => {
        this.items = configItems;
        this.reloaded = true;
      });
  }
}
