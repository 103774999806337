import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {Help} from '../../../../models/help';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {AgentOrganizationComponent} from '../organization.component';

@Component({
  selector: 'app-edit-agent-organization',
  templateUrl: '../edit-agent-organization/edit-agent-organization.component.html',
})
export class AddAgentOrganizationComponent extends AgentOrganizationComponent implements OnInit {
  pageTitle = 'New Organization';

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Add';
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = true;
  }

  createFormControls() {
    this.name = new FormControl(null, AppValidators.present);
    this.description = new FormControl(null);
    this.nmls = new FormControl(null, AppValidators.present);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
      nmls: this.nmls,
    });
  }
}
