import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Tran} from '../../../models/tran';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-view-loan',
  templateUrl: './view-loan.component.html',
})
export class ViewLoanComponent extends CompBaseComponent implements OnInit {
  public mode: string;

  constructor(public injector: Injector,
              private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
    if (this.tran) {
      this.reload(false);
    }
  }

  reload(force: boolean): void {
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        this.mode = 'View';
        this.tran = tran;
      });
  }
}
