<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="title" [help]="true" [op]="'edit'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            If you live in a community state, then respond Yes to the community property state question.
            Otherwise, please consult with your loan officer.
          </li>
          <li>
            <span class="fw-bold">Community states:</span>&nbsp;Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington and Wisconsin.
          </li>
          <li>
            <span class="fw-bold">CAIVRS Identifier:</span>&nbsp;Applicable only to government loans such as FHA.
            Your loan officer will fill-in data for this field.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <mat-card-content>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">SSN</div>
        <div class="col-lg-6">{{item.ssn | ssn}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">First name</div>
        <div class="col-lg-6">{{item.first}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Middle name</div>
        <div class="col-lg-6">{{item.middle}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Last name</div>
        <div class="col-lg-6">{{item.last}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Suffix</div>
        <div class="col-lg-6">{{item.suffix}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">DOB</div>
        <div class="col-lg-6">{{item.dob | date:'MM/dd/yyyy'}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Marital Status</div>
        <div class="col-lg-6">{{item.marital_status_ex}}</div>
      </div>

      <div *ngIf="item.marital_status_ex==='Unmarried'">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Domestic Relationship?</div>
          <div class="col-lg-6">{{item.domestic_relationship_indicator | booleanToString}}</div>
        </div>

        <div *ngIf="item.domestic_relationship_indicator">
          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-6 fw-bold">Domestic Relationship Type</div>
            <div class="col-lg-6">{{item.domestic_relationship_type}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-6 fw-bold">Domestic Relationship Other Description</div>
            <div class="col-lg-6">{{item.domestic_relationship_other_description}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-6 fw-bold">Domestic Relationship State</div>
            <div class="col-lg-6">{{item.domestic_relationship_state_code}}</div>
          </div>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Number of Dependents</div>
        <div class="col-lg-6">{{item.dependents_no}}
          <small>(from Dependents form)</small>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Home phone</div>
        <div class="col-lg-6">{{item.home_phone | fnmaPhone}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Cell phone</div>
        <div class="col-lg-6">{{item.cell_phone | fnmaPhone}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Work phone</div>
        <div class="col-lg-6" [hidden]="!item.work_phone">{{item.work_phone | fnmaPhone}}&nbsp;Ext. {{item.work_phone_extension}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Email</div>
        <div class="col-lg-6">{{item.email}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Community property state considerations?</div>
        <div class="col-lg-6" *ngIf="item.community_state!==null">{{item.community_state | booleanToString}}</div>
        <div class="col-lg-6" *ngIf="item.community_state===null">(no response)</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Language</div>
        <div class="col-lg-6">{{item.getLanguage()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.language_code==='OTH'">
        <div class="col-lg-6 fw-bold">Other language</div>
        <div class="col-lg-6">{{item.other_language}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col fw-bold pr-font-large">
          Government Loan Only
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">CAIVRS Identifier</div>
        <div class="col-lg-6">{{item.caivrs_identifier}}</div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
