import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';
import {Tran} from '../../../../../models/tran';
import {Mcr} from '../../../../../models/mcr';
import {FormUtil} from '../../../../form-util';

@Component({
  selector: 'app-view-mcr',
  templateUrl: './view-mcr.component.html',
})
export class ViewMcrComponent extends CompBaseComponent implements OnInit {
  public mcr: Mcr;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.mode = 'View';
    this.reloaded = false;
    this.tranService.getCurrentTran()
      .then((tran: Tran) => {
          this.tran = tran;
          return this.loanAppService.getCurrentLoanDocument();
        }
      )
      .then((doc) => {
          this.doc = doc;
          return this.mcrService.getTranMcr(this.tran.id);
        }
      )
      .then((mcr: Mcr) => {
        this.mcr = mcr;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      })
      .catch((data) => {
        // a 404 is expected if there is no existing MCR
        if (data.status !== 404) {
          FormUtil.genErrorMessage(this.sharedService, 'System error: MCR', data);
        }
        this.mcr = null;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
