import {Component, Injector, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {Customer} from "../../../../models/customer";

@Component({
  selector: 'app-view-segment-customers',
  templateUrl: './view-segment-customers.component.html',
})
export class ViewSegmentCustomersComponent extends CompBaseComponent implements OnInit {
  public customers: Array<Customer> = [];
  public canCreateCustomer: boolean;
  public isCustomers: boolean;

  // form and form controls
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isCustomers = this.items && (this.items.length > 0);
    //todo:
    this.canCreateCustomer = this.permissionService.execCan('CanCreateContact');
    this.createFormControls();
    this.createForm();
    this.loadCustomers();
  }

  // note: if borrower has no transactions, getCurrentTran will currently error out
  // for this reason we flag reloaded = true on failure
  loadCustomers(): void {
    this.reloaded = false;
    this.customers = [];
    this.tranService.getCurrentTran(false)
      .then((tran) => {
        this.tran = tran;
      })
      .catch((data) => {
        console.log('FAILED: loadCustomers failed');
      }).finally(() => {
      if (this.tran) {
        this.customers = this.items.filter((elt) => {
          return true;
        });
        this.reloaded = true;
        return;
      }
      this.customers = this.items.filter((elt) => {
        return true;
      });
      this.reloaded = true;
    });
  }

  createFormControls(): void {
  }

  createForm(): void {
    this.form = new FormGroup({});
  }
}
