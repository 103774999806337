import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DeclarationExplanation} from '../../../../models/declaration-explanation';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {Declarations} from '../../../../models/declarations';

@Component({
  selector: 'app-declaration-explanation',
  template: '',
})
export class DeclarationExplanationComponent extends CompBaseComponent implements OnInit {
  @Input() explanation: DeclarationExplanation;
  @Input() response: boolean;
  @Input() item: DeclarationExplanation;

  public form: FormGroup;
  public declaration_explanation: FormControl;
  public declaration_text = Declarations.declaration_text;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'declaration_explanations';
    this.section = this.applicant;
  }

  createFormControls(item: DeclarationExplanation): void {
    const explanation = item.declaration_explanation;
    this.declaration_explanation = new FormControl(explanation);
  }

  createForm(): void {
    this.form = new FormGroup({
      declaration_explanation: this.declaration_explanation,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new DeclarationExplanation(this.form.value);
    updated.declaration_type_code = this.item.declaration_type_code;
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
