import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Liability} from '../../../../models/liability';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {RealEstate} from '../../../../models/real-estate';

@Component({
  selector: 'app-liability',
  template: '',
})
export class LiabilityComponent extends CompBaseComponent implements OnInit {
  public liability_types = Liability.liability_types;
  public mortgage_types = Liability.mortgage_types;

  // form and form fields
  public form: FormGroup;
  creditor_name: FormControl;
  account_no: FormControl;
  monthly_payment_amount: FormControl;
  months_left: FormControl;
  unpaid_balance: FormControl;
  will_be_paid: FormControl;
  reo_asset_id: FormControl;
  omit_from_credit_report: FormControl;
  mortgage_type: FormControl;
  credit_limit: FormControl;
  liability_type_ex: FormControl;
  other_description: FormControl;
  liability_includes_ti: FormControl;

  // required fields
  @Input() item: Liability;

  public reo_assets: Array<any> = [];

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'liabilities';
    this.section = this.applicant;
    this.reload();
  }

  // todo: check which fields are required
  createFormControls(item: Liability): void {
    this.liability_type_ex = new FormControl(item.liability_type_ex, AppValidators.present);
    this.creditor_name = new FormControl(item.creditor_name);
    this.account_no = new FormControl(item.account_no);
    this.monthly_payment_amount = new FormControl(item.monthly_payment_amount);
    this.months_left = new FormControl(item.months_left);
    this.unpaid_balance = new FormControl(item.unpaid_balance, AppValidators.present);
    this.will_be_paid = new FormControl(item.will_be_paid);
    this.reo_asset_id = new FormControl(item.reo_asset_id);
    this.omit_from_credit_report = new FormControl(item.omit_from_credit_report);
    this.mortgage_type = new FormControl(item.mortgage_type);
    this.credit_limit = new FormControl(item.credit_limit);
    this.other_description = new FormControl(item.other_description);
    this.liability_includes_ti = new FormControl(item.liability_includes_ti);
  }

  createForm(): void {
    this.form = new FormGroup({
      liability_type_ex: this.liability_type_ex,
      creditor_name: this.creditor_name,
      account_no: this.account_no,
      monthly_payment_amount: this.monthly_payment_amount,
      months_left: this.months_left,
      unpaid_balance: this.unpaid_balance,
      will_be_paid: this.will_be_paid,
      reo_asset_id: this.reo_asset_id,
      omit_from_credit_report: this.omit_from_credit_report,
      mortgage_type: this.mortgage_type,
      credit_limit: this.credit_limit,
      other_description: this.other_description,
      liability_includes_ti: this.liability_includes_ti
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Liability(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Liability(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    const res = this.doc.recursive_attributes.la_real_estates;
    if (!this.reo_assets.length) {
      res.forEach((elt) => {
        const re = new RealEstate(elt);
        this.reo_assets.push({key: re.reo_asset_id, value: `${re.reo_asset_id}: ${re.getFullAddress()}`});
        this.reo_assets.sort((x, y): number => {
          return +x.key - +y.key;
        });
      });
    }
  }
}
