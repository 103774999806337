import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {HomeComponent} from '../home.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
})
export class ServicesComponent extends HomeComponent implements OnInit, OnDestroy {
  public pageTitle = `About Your Services`

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.reloadHome()
      .then(() => {
        setTimeout(() => {
          this.sharedService.emitChange({
            type: 'ChangePage',
            title: this.pageTitle,
            help: Help.helpKeys.homeServices
          });
          this.reloaded = true;
        }, 0);
      });
  }
}
