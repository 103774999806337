<mat-card class="mt-2 mb-2">
  <mat-card-title>
    Add State License
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>
      <div class="row mt-3">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>State Abbreviation</mat-label>
            <input formControlName="state" appUpperCase matInput placeholder="State Abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            <mat-error *ngIf="state.hasError('pattern')">
              State name is invalid
            </mat-error>
            <mat-error *ngIf="state.hasError('required')">
              State name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>State license</mat-label>
            <input formControlName="license" matInput placeholder="State license" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="license.hasError('pattern')">
              License is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-6">
          <button (click)="add()" [disabled]="!form.valid" class="btn btn-lg btn-primary" aria-label="Add">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
