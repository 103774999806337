<div class="row h-100">
  <div class="col-lg-4 mx-auto">
    <app-card [title]="'MCR Service Now Available!'" [alignTitle]="'center'" *ngIf="!sent">
      <mat-card class="mt-1 mb-1">
        <mat-card-title>
          MCR Service Now Available!
        </mat-card-title>

        <mat-card-content>
          <p>
            PrudentCO is offering a new service for the production of Mortgage Call Report (MCR).
            Your mortgage company assigns a person who provides us
            with basic loan information. We generate the MCR for you, ready to upload to NMLS.
          </p>
          <p>
            Have a quick chat with our team to learn more:
          </p>
          <form [formGroup]="form" novalidate>
            <div class="row">
              <div class="col">
                <mat-form-field class="md-icon-left w-100">
                  <mat-label>Name</mat-label>
                  <input formControlName="name" matInput placeholder="Name" maxlength="70" appAutoFocus>
                  <mat-error *ngIf="name.hasError('required')">
                    Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="md-icon-left w-100">
                  <mat-label>Email</mat-label>
                  <input formControlName="email" matInput placeholder="Email" maxlength="320" required>
                  <mat-error *ngIf="email.hasError('required')">
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="email.hasError('email')">
                    Email is invalid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="md-icon-left w-100">
                  <mat-label>Phone number</mat-label>
                  <input appPhoneOnly formControlName="phone_number" matInput placeholder="Phone number" maxlength="14">
                  <mat-error *ngIf="phone_number.hasError('required')">
                    Phone number is required
                  </mat-error>
                  <mat-error *ngIf="email.hasError('pattern')">
                    Phone number is invalid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <button (click)="submit($event)" class="btn btn-lg btn-primary" [disabled]="!form.valid">Submit</button>
          </form>
        </mat-card-content>
      </mat-card>
    </app-card>

    <app-card *ngIf="sent" [title]="'Message Received'" [alignTitle]="'center'">
      <p>
        We have received your request and will be in touch shortly.
        You can also contact us directly.<br>
      </p>

      <div>
        <a class="btn btn-primary" href="/">OK</a>
      </div>
    </app-card>
  </div>
</div>


<!--<p>myForm value: {{ form.value | json }}</p>-->
<!--<p>myForm status: {{ form.status | json }}</p>-->
