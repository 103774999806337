import {Component, Injector, OnInit} from '@angular/core';
import {Document} from '../../../../../models/document';
import {CompBaseComponent} from '../../../../comp-base.component';

@Component({
  selector: 'app-view-financial-ratios',
  templateUrl: './view-financial-ratios.component.html',
})
export class ViewFinancialRatiosComponent extends CompBaseComponent implements OnInit {
  public ql: any;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.ql = {};
    this.reload();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
  }

  // comment: getting the loan application document
  reload(force?: boolean): void {
    this.reloaded = false;
    this.loanAppService.getCurrentLoanDocument()
      .then((doc: Document) => {
        this.doc = doc;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
