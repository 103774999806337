import {ModelBase} from './model-base';

export class TransmittalData extends ModelBase {
  public static appraisal_types_ex: Array<any> = [
    {key: 'Actual', value: 'Actual'},
    {key: 'Estimated', value: 'Estimated'},
  ];

  public static existing_mortgage_owner_codes_ex: Array<any> = [
    {key: 'FNM', value: 'Fannie Mae'},
    {key: 'FRE', value: 'Freddie Mac'},
    {key: 'Other', value: 'Seller/Other'},
    {key: 'Unknown', value: 'Unknown'},
  ];
  seller_below_market_financing: boolean;
  property_appraised_value: string;
  buydown_rate: string;
  appraisal_type_ex: string;
  existing_mortgage_owner_code_ex: string; // condition: only if refinance // todo: need to test with a refinance loan
  appraisal_identifier: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'property_appraised_value' || prop === 'buydown_rate') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'seller_below_market_financing') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getBuyDownRate(): number {
    return this.buydown_rate ? +this.buydown_rate / 100 : null;
  }

  getExistingMortgageOwnerCode(): string {
    const found = TransmittalData.existing_mortgage_owner_codes_ex.find((elt) => elt.key === this.existing_mortgage_owner_code_ex);
    return found ? found['value'] : '';
  }

  getAppraisalType(): string {
    const found = TransmittalData.appraisal_types_ex.find((elt) => elt.key === this.appraisal_type_ex);
    return found ? found['value'] : null;
  }

  toServerPayload(): any {
    return {
      seller_below_market_financing: ModelBase.toServerExplicitBoolean(this.seller_below_market_financing),
      existing_mortgage_owner_code_ex: this.existing_mortgage_owner_code_ex,
      property_appraised_value: this.toServerDecimal(this.property_appraised_value),
      buydown_rate: this.toServerPercentThree(this.buydown_rate),
      appraisal_type_ex: this.appraisal_type_ex,
      appraisal_identifier: this.appraisal_identifier
    };
  }
}
