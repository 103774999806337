<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="mt-5 ms-5 mb-3"></mat-spinner>
</div>

<mat-card class="mt-2 mb-1" *ngIf="!isMobile&&reloaded&&!generating">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" *ngIf="!generating" [header]="'Cash Flow Analysis (CFA) - Fannie Mae Form 1084'" [enable]="false" [help]="true" class="w-100">
      This worksheet may be used to prepare a written evaluation of the analysis of income related to self-employment. The
      purpose of this analysis is to determine the amount of stable and continuous income that will be available to the
      borrower for loan qualifying purposes.
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          To edit a row, click the row's caption.
          This causes the first field of that row to get focus.
          If you click a specific field, then that field gets the focus.
        </li>
        <li>
          To record a row, click Enter.
        </li>
        <li>
          To revert any row entry, either click the row caption or on any another row.
        </li>
        <li>
          <b>You must save the document</b> whenever you are done editing.
        </li>
        <li>
          To generate the Fannie Mae's CFA document click Generate CFA Doc after
          <b>saving the document</b>.
          The generated PDF document will be placed in
          <a class="pr-blue-bold" routerLink="/loan/view-directory">Current Loan/Repository/Documents</a>.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="true" [hash]="hash" [hashEntry]="'borrower_name'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'business_name'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="true" [hash]="hash" [hashEntry]="'year'"></app-view-cfa-entry>
    <!--    <h5 class="mt-1 mb-1">Income from Self-Employment</h5>-->
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="true" [hash]="hash" [hashEntry]="'1'"></app-view-cfa-entry>
    <div class="mt-1 mb-1 fw-bold">Schedule B – Interest and Ordinary Dividends</div>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'2.a'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'2.b'"></app-view-cfa-entry>
    <div class="mt-1 mb-1 fw-bold">Schedule C – Profit or Loss from Business: Sole Proprietorship</div>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.a'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.b'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.c'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.d'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.e'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.f'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'3.g'"></app-view-cfa-entry>
    <div class="mt-1 mb-1 fw-bold">Schedule D – Capital Gains and Losses</div>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'4.a'"></app-view-cfa-entry>
    <div class="mt-1 mb-1 fw-bold">Schedule E – Supplemental Income and Loss</div>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'5.a'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'5.b'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'5.c'"></app-view-cfa-entry>
    <div class="mt-1 mb-1 fw-bold">Schedule F – Profit or Loss from Farming</div>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.a'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.b'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.c'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.d'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.e'"></app-view-cfa-entry>
    <app-view-cfa-entry (eventEmitted)="onEvent($event)" [bold]="false" [hash]="hash" [hashEntry]="'6.f'"></app-view-cfa-entry>
    <div *ngIf="reloaded" class="row hover-gray">
      <div class="col-lg-6 pt-1 pb-1 hand-pointer">
        <b>{{hash['total']['caption']}}</b>
      </div>
      <div class="col-lg-2 pt-1 pb-1 border-bottom text-end">
        <b>{{hash['total']['field1'] | currency}}</b>
      </div>
      <div class="col-lg-2 pt-1 pb-1">
      </div>
      <div class="col-lg-2 pt-1 pb-1 border-bottom text-end">
        <b>{{hash['total']['field2'] | currency}}</b>
      </div>
    </div>
    <div>
      <button (click)="save()" class="btn btn-lg btn-primary">Save</button>
      <button (click)="generateCFADoc()" class="ms-2 btn btn-lg btn-light">Generate CFA Doc</button>
    </div>
  </mat-card-content>
</mat-card>

<app-box *ngIf="isMobile" [close]="false" [type]="'warning'" class="pt-2">
  <app-segment-header (eventEmitted)="onEvent($event)" *ngIf="!generating" [header]="'Cash Flow Analysis (CFA)'" [enable]="false" [help]="false" class="w-100">
  </app-segment-header>
  <strong>Sorry, Cash Flow Analysis (CFA) is not available in mobile mode!</strong>
</app-box>
