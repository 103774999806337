import {ModelBase} from './model-base';

export class DownPayment extends ModelBase {
  public static funds_sources = [
    {key: 'Borrower', value: 'Borrower'},
    {key: 'Builder', value: 'Builder'},
    {key: 'CommunityNonProfit', value: 'Community Non-Profit'},
    {key: 'Employer', value: 'Employer'},
    {key: 'FederalAgency', value: 'Federal Agency'},
    {key: 'Institutional', value: 'Institutional'},
    {key: 'Lender', value: 'Lender'},
    {key: 'LocalAgency', value: 'Local Agency'},
    {key: 'NonOriginatingFinancialInstitution', value: 'Non Originating Financial Institution'},
    {key: 'NonParentRelative', value: 'Non Parent Relative'},
    {key: 'Other', value: 'Other'},
    {key: 'Parent', value: 'Parent'},
    {key: 'PropertySeller', value: 'Property Seller'},
    {key: 'Relative', value: 'Relative'},
    {key: 'ReligiousNonProfit', value: 'Religious Non-Profit'},
    {key: 'RuralHousingService', value: 'Rural Housing Service'},
    {key: 'StateAgency', value: 'State Agency'},
    {key: 'Unknown', value: 'Unknown'},
    {key: 'UnmarriedPartner', value: 'Unmarried Partner'},
    {key: 'UnrelatedFriend', value: 'Unrelated Friend'},
  ];

  index: number;
  down_payment_explanation: string;
  down_payment_amount: string;
  funds_source: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'down_payment_amount') {
        this[prop] = this.getNumberProp(prop, object);
      }
      this[prop] = object[prop];
    }
  }

  static deserializeArray(arr: Array<any>): Array<DownPayment> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new DownPayment(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getFundsSource(): string {
    const fundsSource = DownPayment.funds_sources.find((elt) => elt.key === this.funds_source);
    return fundsSource ? fundsSource.value : null;
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      funds_source: this.funds_source,
      down_payment_explanation: this.down_payment_explanation,
      down_payment_amount: this.toServerDecimal(this.down_payment_amount),
    };
  }
}
