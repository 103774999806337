import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {LoanInformation} from '../../../../models/loan-information';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-loan-information',
  templateUrl: './edit-segment-loan-information.component.html',
})
export class EditSegmentLoanInformationComponent extends CompBaseComponent implements OnInit {
  public refinance_types = LoanInformation.refinance_types;
  public mortgage_types_ex = LoanInformation.mortgage_types_ex;
  public amortization_types_ex = LoanInformation.amortization_types_ex;
  public amortization_period_types = LoanInformation.amortization_period_types;
  public isRefinanceLoan: boolean;
  @Input() public item: LoanInformation;

  // form and form controls
  form: FormGroup;
  mortgage_type_ex: FormControl;
  agency_case_no: FormControl;
  lender_case_no: FormControl;
  loan_amount: FormControl;
  interest_rate: FormControl;
  periods_no: FormControl;
  amortization_type_ex: FormControl;
  amortization_type_explain: FormControl;
  amortization_period_type: FormControl;
  gov_refinance_type: FormControl;
  other_description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'loan_information';
    this.section = 'lo';
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.mortgage_type_ex = new FormControl(item.mortgage_type_ex, AppValidators.present);
    this.agency_case_no = new FormControl(item.agency_case_no);
    this.lender_case_no = new FormControl(item.lender_case_no);
    this.loan_amount = new FormControl(item.loan_amount, AppValidators.present);
    this.interest_rate = new FormControl(item.interest_rate, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalThree)]));
    this.periods_no = new FormControl(item.periods_no, AppValidators.present);
    this.amortization_type_ex = new FormControl(item.amortization_type_ex, AppValidators.present);
    this.amortization_type_explain = new FormControl(item.amortization_type_explain);
    this.amortization_period_type = new FormControl(item.amortization_period_type, AppValidators.present);
    this.gov_refinance_type = new FormControl(item.gov_refinance_type);
    this.other_description = new FormControl(item.other_description);
  }

  createForm(): void {
    this.form = new FormGroup({
      mortgage_type_ex: this.mortgage_type_ex,
      agency_case_no: this.agency_case_no,
      lender_case_no: this.lender_case_no,
      loan_amount: this.loan_amount,
      interest_rate: this.interest_rate,
      periods_no: this.periods_no,
      amortization_type_ex: this.amortization_type_ex,
      amortization_type_explain: this.amortization_type_explain,
      amortization_period_type: this.amortization_period_type,
      other_description: this.other_description,
      gov_refinance_type: this.gov_refinance_type,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new LoanInformation(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
