<div class="row mb-1">
  <div class="col">
    <div (click)="toggleMenu($event)" *ngIf="displayBars" class="d-inline">
      <i class="fas fa-bars"></i>
    </div>
    <div *ngIf="enable" class="d-inline" [ngClass]="{'ps-2':displayBars}">
      <button [hidden]="!isAdd" (click)="onClick('add')" mat-mini-fab aria-label="Add"
        style="position:relative;top: -4px !important;">
        <mat-icon aria-label="Add">add</mat-icon>
      </button>
      <button [hidden]="!isEdit" (click)="onClick('edit')" class="ms-2" mat-mini-fab aria-label="Edit"
        style="position:relative;top: -4px !important;">
        <mat-icon aria-label="Edit">edit</mat-icon>
      </button>
    </div>
    <div *ngIf="isCard" [innerHTML]="safeHeader" (click)="onClick('header')" [ngClass]="{'pr-link-like': clickable, 'hand-pointer':clickable, 'mobile-segment-header':isMobile,'ps-2':displayBars||enable}" style="display:inline;position:relative;top:-5px;"></div>
    <div *ngIf="!isCard" [innerHTML]="safeHeader" (click)="onClick('header')" [ngClass]="{'pr-link-like': clickable, 'hand-pointer':clickable, 'mobile-segment-header':isMobile,'ps-2':(displayBars||enable) && (op && (op.length > 0))}" class="pr-font-20" style="display:inline;position:relative;top:-5px;"></div>
    <div [hidden]="!help" style="display: inline;position:relative;top: 4px;float:right;">
      <a (click)="toggleHelp($event)" aria-label="Open Contextual Help" class="" href="#">
        <span class="fas fa-question-circle" style="font-size: 24px;"></span>
      </a>
    </div>
  </div>
</div>

<div (click)="toggleHelp($event)"
  [hidden]="!(help&&displayHelp)"
  class="segment-header-box mt-2 mb-2"
>
  <ng-content></ng-content>
</div>
