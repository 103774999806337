<mat-card class="mt-2 mb-1">
  <mat-card-title>Fannie Mae Institution ID
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" class="pb-2">
      <div class="row pb-3">
        <div class="col">
          <button (click)="populate()" class="btn btn-lg btn-warning" type="button">Populate from Account</button>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Fannie Mae institution ID</mat-label>
            <input type="text" formControlName="institution_id" matInput placeholder="Fannie Mae institution ID" appAutoFocus maxlength="6">
            <mat-error *ngIf="institution_id.hasError('required')">
              Fannie Mae institution ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
