<mat-card>
  <mat-card-title>Find Users</mat-card-title>
  <mat-card-content>
    <form (submit)="findUsers()" [formGroup]="form">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="first" matInput placeholder="First name" appAutoFocus>
            <mat-error *ngIf="first.hasError('required')">
              First name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="last" matInput placeholder="Last name">
            <mat-error *ngIf="last.hasError('required')">
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button class="btn btn-lg btn-primary" type="submit">Find Users</button>
      <button (click)="reset()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
    </form>
  </mat-card-content>
</mat-card>
