import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../../comp-base.component';
import {Document} from '../../../../../models/document';
import {MortgageCalculatorService} from '../../../../../services/mortgage-calculator.service';
import {ModelBase} from '../../../../../models/model-base';

@Component({
  selector: 'app-financial-ratio',
  template: '',
})
export class FinancialRatioComponent extends CompBaseComponent implements OnInit {
  @Input() doc: Document;
  @Input() ql: any;
  public loan_app: any;
  public mortgageCalculatorService: MortgageCalculatorService;
  public qualifyLoan: any;
  public error: boolean;

  // form and form controls
  public form: FormGroup;
  public monthlyLoanPayment: FormControl;
  public monthlyRealEstateTaxes: FormControl;
  public monthlyHazardInsurance: FormControl;
  public monthlyMortgageInsurance: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mortgageCalculatorService = this.injector.get(MortgageCalculatorService);
    this.loan_app = this.doc['recursive_attributes'];
  }

  createFormControls(): void {
    this.monthlyLoanPayment = new FormControl(this.qualifyLoan.monthlyLoanPayment);
    this.monthlyRealEstateTaxes = new FormControl(this.qualifyLoan.monthlyRealEstateTaxes);
    this.monthlyHazardInsurance = new FormControl(this.qualifyLoan.monthlyHazardInsurance);
    this.monthlyMortgageInsurance = new FormControl(this.qualifyLoan.monthlyMortgageInsurance);
  }

  createForm(): void {
    this.form = new FormGroup({
      monthlyLoanPayment: this.monthlyLoanPayment,
      monthlyRealEstateTaxes: this.monthlyRealEstateTaxes,
      monthlyHazardInsurance: this.monthlyHazardInsurance,
      monthlyMortgageInsurance: this.monthlyMortgageInsurance
    });
  }

  resetFinancialRatios(): any | null {
    const ql: any = {};
    const loanApp = this.loan_app;
    const tn = ModelBase.toNumber;

    //
    // INCOME
    //
    // income not from rental income
    ql.monthlyIncome = this.analyzerService.computeMonthlyIncome(loanApp);  // excluding rental income
    // rental income from existing real estate owned
    ql.monthlyNetRealEstateIncome = this.analyzerService.computeMonthlyNetRealEstateIncome(loanApp); // net rental income from real estate entries
    // expected real estate income (new purchase only)
    ql.expectedMonthlyNetRentalIncome = this.analyzerService.computeExpectedMonthlyNetRentalIncome(loanApp);
    // total rental income
    ql.monthlyRentalIncome = tn(ql.expectedMonthlyNetRentalIncome) + tn(ql.monthlyNetRealEstateIncome);
    // TOTAL monthly income
    ql.totalMonthlyIncome = ql.monthlyIncome + ql.monthlyRentalIncome;

    //
    // EXPENSES AND PAYMENTS
    //
    ql.monthlyLoanPayment = this.computeMonthlyPayment(loanApp).toFixed(2);
    ql.monthlyRealEstateTaxes = this.analyzerService.computeMonthlyRealEstateTaxes(loanApp).toFixed(2);
    ql.monthlyHazardInsurance = this.analyzerService.computeMonthlyHazardInsurance(loanApp).toFixed(2);
    ql.monthlyMortgageInsurance = this.analyzerService.computeMonthlyMortgageInsurance(loanApp).toFixed(2);
    ql.monthlySupportExpenses = this.analyzerService.computeMonthlySupportExpenses(loanApp);
    ql.monthlyOtherExpenses = this.analyzerService.computeMonthlyOtherExpenses(loanApp);
    ql.monthlyLiabilityExpenses = this.analyzerService.computeMonthlyLiabilityExpenses(loanApp);
    ql.totalMonthlyExpensesAndPayments = tn(ql.monthlySupportExpenses) + tn(ql.monthlyOtherExpenses) + tn(ql.monthlyLiabilityExpenses);

    //
    // ASSETS
    //
    ql.gifts = this.analyzerService.computeGifts(loanApp);
    // non-real estate assets
    ql.assets = this.analyzerService.computeAssets(loanApp);
    ql.realEstateAssets = this.analyzerService.computeRealEstateAssets(loanApp);
    ql.totalAssets = ql.assets + ql.realEstateAssets + ql.gifts;

    //
    // LIABILITIES
    //
    const loanAmount = tn(loanApp.loan_information['loan_amount']);
    ql.liabilities = this.analyzerService.computeLiabilities(loanApp) + loanAmount;

    // LTV & Net Worth
    ql.loanToValue = this.analyzerService.computeLoanToValue(loanApp);
    ql.netWorth = ql.totalAssets - ql.liabilities; // (not really useful)

    // if loan to value is null or monthly income is null (or very small)
    // then computations are of no value
    if (!ql.loanToValue || !ql.monthlyIncome || (ql.monthlyIncome <= 0.1)) {
      this.error = true;
      return null;
    }

    // compute PITI and ratios
    this.computeRatios(ql);
    return ql;
  }

  computeMonthlyPayment(loanApp) {
    const loanInfo = loanApp.loan_information;
    if (loanInfo['periods_no'] && loanInfo['interest_rate'] && loanInfo['loan_amount']) {
      const monthsNo = +loanInfo['periods_no'];
      const interestRate = loanInfo['interest_rate'];
      const loanAmount = loanInfo['loan_amount'];
      return MortgageCalculatorService.computeMonthlyPayment(loanAmount, 0, interestRate, monthsNo);
    }

    return 0;
  }

  // compute PITI and ratios
  computeRatios(ql) {
    const tn = ModelBase.toNumber;
    ql.error = ql.totalMonthlyIncome <= 1.0;
    ql.piti = tn(ql.monthlyLoanPayment) + tn(ql.monthlyRealEstateTaxes) + tn(ql.monthlyHazardInsurance) + tn(ql.monthlyMortgageInsurance);
    ql.frontRatio = tn(ql.totalMonthlyIncome) > 0 ? tn(ql.piti) / tn(ql.totalMonthlyIncome) : 0;
    ql.totalMonthlyExpenses = tn(ql.piti) + tn(ql.totalMonthlyExpensesAndPayments);
    ql.backRatio = tn(ql.totalMonthlyIncome) > 0 ? (tn(ql.piti) + tn(ql.totalMonthlyExpensesAndPayments)) / tn(ql.totalMonthlyIncome) : 0;
  }
}
