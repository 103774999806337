<div *ngIf="reloaded">
  <app-box *ngIf="!workflow.started" class="mt-3" [type]="'warning'">
    There are no Tasks because the
    <span class="fw-bold">workflow has not yet started!</span>
    You can start the loan workflow from
    <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
  </app-box>

  <form [formGroup]="form" class="mb-2 mt-2" *ngIf="workflow.started">
    <div class="row">
      <div class="col-lg-4">
        <mat-checkbox (change)="onFilterChange()" formControlName="displayHidden">Display hidden tasks</mat-checkbox>
      </div>

      <div class="col-lg-4">
        <mat-checkbox (change)="onFilterChange()" formControlName="displayUnvisited">Display unstarted tasks</mat-checkbox>
      </div>
    </div>
  </form>

  <div *ngIf="workflow.started">
    <app-view-task (eventEmitted)="onEvent($event)" *ngFor="let trace of filteredTraces" [title]="trace.state_display" [trace]="trace" [currentState]="currentState"></app-view-task>
  </div>
</div>
