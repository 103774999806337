import {ModelBase} from './model-base';
import {User} from './user';

export class Organization extends ModelBase {
  id: number;
  index: number;
  name: string;
  description: string;
  nmls: string;
  organization_type: number;
  users: Array<User>;
  admin: User;
  created_at: Date;
  updated_at: Date;
  logo_path: string;
  // generated properties
  logo_url: string;
  is_logo: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'users') {
        this[prop] = User.deserializeArray(object[prop]);
      } else if (prop === 'updated_at' || prop === 'created_at') {
            this[prop] = new Date(object[prop]);
      } else if (prop === 'admin') {
        if (object[prop]) {
          this[prop] = new User(object[prop]);
        } else {
          this[prop] = null;
        }
      } else {
        this[prop] = object[prop];
      }
    }
    this.logo_url = this.getLogoUrl();
    this.is_logo = this.isLogo();
  }

  static deserializeArray(arr: Array<any>): Array<Organization> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Organization(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  public getOrgType(): string {
    return ModelBase.getOrgType(this.organization_type);
  }

  public isAuxiliary(): boolean {
    return this.organization_type === ModelBase.ORG_TYPES.AUX;
  }

  isLogo(): boolean {
    return this.logo_path && this.logo_path.length > 0;
  }

  getLogoUrl(): string {
    return 'api' + this.logo_path;
  }
}
