import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-generate-mismo',
  templateUrl: './generate-mismo.component.html',
})
export class GenerateMISMOComponent extends GenerateDocumentBaseComponent implements OnInit {
  public isDefaultLoanApp: boolean;
  public doc: Document;
  @Input() folderId: string;

  // form and form controls
  public form: FormGroup;
  public include_borrower: FormControl;
  public include_co_borrower: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  createFormControls(): void {
    this.include_borrower = new FormControl(true);
    this.include_co_borrower = new FormControl(true);
  }

  createForm(): void {
    this.form = new FormGroup({
      include_borrower: this.include_borrower,
      include_co_borrower: this.include_co_borrower,
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.doc = this.tran.getDefaultLoanApp();
    this.isDefaultLoanApp = !!this.doc;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  genMISMO(event): void {
    const formData = this.form.value;
    formData.icn_id = this.folderId;
    event.preventDefault();
    this.generating = true;
    this.docService.generateMISMO(this.doc.id, this.form.value)
      .then(() => {
        const message = this.folderId ? 'Document successfully generated' : 'Document successfully generated and moved to Unsorted Documents folder';
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.router.navigate(['/loan/view-directory']);
        this.generating = false;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate document', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
        this.generating = false;
      });
  }
}
