import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable()
export class PingService {
  public static PING_INTERVAL_MINUTES = 1;

  constructor(private httpService: HttpService) {
    setInterval(() => {
      this.ping()
        .then((data) => {
        })
        .catch((data) => {
          console.log('Failed: ping_error', data);
          // 02/07/2020 do not redirect to server 500
          // window.location.href = '/site/index';
        });
    }, 1000 * 60 * PingService.PING_INTERVAL_MINUTES);
  }

  // The only purpose of this method is to ensure that the service is loaded
  init(): void {
  }

  // Rails needs to know that this is json because ping can also render plain text
  public ping(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.get('ping', true)
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
