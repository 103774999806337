import {Component, Injector, OnInit} from '@angular/core';
import {PitchService} from '../../../services/pitch.service';
import {SharedService} from '../../../layouts/shared-service';
import {CompBaseComponent} from "../../comp-base.component";
import {User} from "../../../models/user";
import {Organization} from "../../../models/organization";
import {ProcessService} from "../../../services/processr.service";

@Component({
  selector: 'app-view-wizard',
  templateUrl: './view-wizard.component.html',
  styleUrls: ['view-wizard.component.scss'],
})
export class ViewWizardComponent extends CompBaseComponent implements OnInit {
  pageTitle: string = 'MCR Wizard';
  public processService: ProcessService;
  public organization: Organization;
  public isHidden = false;
  public seg: string;
  public user: User;
  public helpSubjects = {
    '0': 'MCR Wizard',
    '1': 'MLO Config',
    '2': 'Organization & Users',
    '3': 'Loan MCRs',
    '4': 'Create Organization MCR',
    '5': 'Review State MCR',
    '6': 'Generate MCR XML',
    '7': 'Upload to NMLS',
  }

  constructor(public injector: Injector, public sharedService: SharedService, private pitchService: PitchService) {
    super(injector);
    this.processService = this.injector.get(ProcessService);
    this.seg = ProcessService.task;
  }

  ngOnInit() {
    this.user = this.userService.getUserSync();
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.updateUser()
      .then(() => {
        this.processService.updateProcess(this.user, this.organization);
        this.reloaded = true;
      })
      .then(() => {
        if (this.isTitle) {
          setTimeout(() => {
            this.sharedService.emitChange({
              type: 'ChangePage',
              title: this.pageTitle,
            });
          }, 0);
        }
      })
  }

  async updateUser(): Promise<boolean> {
    await this.userService.refreshUser();
    this.user = this.userService.getUserSync();
    this.organization = await this.userService.getDefaultOrganization(true);
    return Promise.resolve(true);
  }

  progressTask(): void {
    this.reloaded = false;
    this.updateUser()
      .then(() => {
        this.processService.updateProcess(this.user, this.organization);
        this.seg = this.processService.progressTask();
        this.manageHelp();
      })
  }

  regressTask(): void {
    this.reloaded = false;
    this.updateUser()
      .then(() => {
        this.seg = this.processService.regressTask();
      }).then(() => {
      this.manageHelp();
    })
  }

  toggleHelp(event: any): void {
    event.preventDefault();
    this.isHidden = !this.isHidden;
  }

  manageHelp(): void {
    this.pageTitle = this.helpSubjects[this.seg];
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
      });
      this.reloaded = true;
    }, 0);
  }
}
