import {ModelBase} from './model-base';

export class DeclarationExplanation extends ModelBase {
  static declaration_codes = [
    {key: '91', value: 'judgments'},
    {key: '92', value: 'bankrupt'},
    {key: '93', value: 'foreclosures'},
    {key: '94', value: 'law_suits'},
    {key: '95', value: 'default_loans'},
    {key: '96', value: 'delinquent_federal_loans'},
    {key: '97', value: 'child_support'},
    {key: '98', value: 'down_payment_borrowed'},
    {key: '99', value: 'endorser_loans'},
    {key: '01', value: 'relationship'},
    {key: '02', value: 'new_mortgage'},
    {key: '03', value: 'new_credit'},
    {key: '04', value: 'short_sale'},
    {key: '06', value: 'deed_in_lieu'},
  ];

  index: number;
  declaration_type_code: string; // 2 char
  declaration_explanation: string; // 255 chars
  declarationTypeValue: string; // only used internally in UI code

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
    this.declarationTypeValue = this.getDeclarationValue();
  }

  static get_declaration_value(key): string {
    const found = DeclarationExplanation.declaration_codes.find((elt) => elt.key === key);
    return found ? found['value'] : '';
  }

  static deserializeArray(arr: Array<any>): Array<DeclarationExplanation> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new DeclarationExplanation(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  static createDeclarationExplanationFromCode(code: string): DeclarationExplanation {
    const declarationExplanation = new DeclarationExplanation({declaration_explanation: null});
    const val = DeclarationExplanation.get_declaration_value(code);
    declarationExplanation.declaration_type_code = code;
    declarationExplanation.declarationTypeValue = val;
    return declarationExplanation;
  }

  getDeclarationValue(): string {
    const found = DeclarationExplanation.declaration_codes.find((elt) => elt.key === this.declaration_type_code);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      declaration_type_code: this.declaration_type_code,
      declaration_explanation: this.declaration_explanation,
    };
  }
}
