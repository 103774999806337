<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row" *ngIf="op==='Add'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Race</mat-label>
            <mat-select formControlName="race_ex" placeholder="Select race" required>
              <mat-option *ngFor="let code of getRemainingRaceCodes()" [value]="code.key">{{code.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="race_ex.hasError('required')">
              Race is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-2" *ngIf="op==='Edit'">
        <div class="col-lg-6">
          <span class="fw-bold">Race:</span>&nbsp;{{item.getRaceShort()}}
        </div>
      </div>

      <div *ngIf="race_ex.value=='AmericanIndianOrAlaskaNative'">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Principal tribe</mat-label>
              <input formControlName="indian_description" matInput placeholder="Principal tribe" type="text" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="indian_description.hasError('required')">
                Principal tribe is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="race_ex.value=='Asian'">
        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="china">China</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="india">India</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="japan">Japan</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="korea">Korea</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="philippines">Philippines</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="vietnam">Vietnam</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="other_asian">Other Asian</mat-checkbox>
          </div>
        </div>

        <div class="row" [hidden]="other_asian.value!=true">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Other Asian</mat-label>
              <input formControlName="asian_description" matInput placeholder="Other Asian" type="text" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="asian_description.hasError('required')">
                Other Asian is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="race_ex.value=='NativeHawaiianOrOtherPacificIslander'">
        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="guam">Guam</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="hawaii">Hawaii</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="samoa">Samoa</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="other_islander">Other Pacific Islander</mat-checkbox>
          </div>
        </div>

        <div class="row" [hidden]="other_islander.value!=true">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Other Pacific Islander</mat-label>
              <input formControlName="islander_description" matInput placeholder="Other Pacific Islander" type="text" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="islander_description.hasError('required')">
                Other Pacific Islander is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
