import {ModelBase} from './model-base';

export class RealEstate extends ModelBase {
  public static property_dispositions_ex = [
    {key: 'PendingSale', value: 'Pending Sale'},
    {key: 'Retain', value: 'Retained'},
    {key: 'Sold', value: 'Sold'},
  ];
  public static occupancy_types = [
    {key: 'Investment', value: 'Investment'},
    {key: 'PrimaryResidence', value: 'Primary Residence'},
    {key: 'SecondHome', value: 'Second Home'},
    {key: 'Other', value: 'Other'},
  ];

  index: number;
  reo_asset_id: string;
  property_address: string;
  property_address2: string;
  property_city: string;
  property_state: string;
  property_zip: string;
  property_zip_four: string;
  property_country: string;
  property_disposition_ex: string;
  market_value: string;
  estimated_market_value: string;
  liens_amount: string;
  gross_rental_income: string;
  monthly_expenses: string;
  net_rental_income: string;
  property_secure_loan: boolean;
  occupancy: string;
  current_occupancy: string;
  occupancy_other_description: string;
  party_ids: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'property_secure_loan') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'market_value' ||
        prop === 'estimated_market_value' ||
        prop === 'liens_amount' ||
        prop === 'gross_rental_income' ||
        prop === 'monthly_expenses' ||
        prop === 'net_rental_income'
      ) {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<RealEstate> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new RealEstate(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getPropertyDisposition(): string {
    const found = RealEstate.property_dispositions_ex.find((elt) => elt.key === this.property_disposition_ex);
    return found ? found['value'] : '';
  }

  getOccupancy(): string {
    const found = RealEstate.occupancy_types.find((elt) => elt.key === this.occupancy);
    return found ? found['value'] : '';
  }

  getCurrentOccupancy(): string {
    const found = RealEstate.occupancy_types.find((elt) => elt.key === this.current_occupancy);
    return found ? found['value'] : '';
  }

  getFullAddress(): string {
    return super.getFullAddress(this.property_address, this.property_address2, this.property_city, this.property_state, this.property_zip, this.property_zip_four, this.property_country);
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      property_address: this.property_address,
      property_address2: this.property_address2,
      property_city: this.property_city,
      property_state: this.property_state,
      property_zip: this.property_zip,
      property_zip_four: this.property_zip_four,
      property_country: this.property_country,
      property_disposition_ex: this.property_disposition_ex,
      estimated_market_value: this.toServerDecimal(this.estimated_market_value),
      market_value: this.toServerDecimal(this.market_value),
      liens_amount: this.toServerDecimal(this.liens_amount),
      gross_rental_income: this.toServerDecimal(this.gross_rental_income),
      monthly_expenses: this.toServerDecimal(this.monthly_expenses),
      net_rental_income: this.toServerDecimal(this.net_rental_income),
      property_secure_loan: ModelBase.toServerExplicitBoolean(this.property_secure_loan),
      occupancy: this.occupancy,
      current_occupancy: this.current_occupancy,
      occupancy_other_description: this.occupancy_other_description,
      party_ids: this.party_ids
    };
  }
}
