import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Mcr} from '../../../../models/mcr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error-check',
  template: '',
})
export class LoMcrComponent extends CompBaseComponent implements OnInit {
  public readonly quarters = Mcr.quarters;
  public readonly years = Mcr.years;
  public router: Router;
  @Input() mcr: Mcr;

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  view(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  manage(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Manage'});
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  navigateToTranMCR(tran: any): void {
    const user = this.userService.getUserSync();
    this.tranService.getTran(tran.id)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'mcr'});
      })
      .then(() => {
        if (user.isLoanProfessional()) {
          this.router.navigate(['/loan/view-loan-app'], {queryParams: {fn: 'mcr'}});
          return;
        }
      });
  }
}
