import {Component, Injector, Input, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-account',
  templateUrl: './view-segment-account.component.html',
})
export class ViewSegmentAccountComponent extends CompBaseComponent implements OnInit {
  @Input() item: User;

  constructor(public injector: Injector) {
    super(injector);
  }
}
