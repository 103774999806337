import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';
import {InvoiceService} from '../../../../services/invoice.service';
import {Invoice} from '../../../../models/invoice';

@Component({
  selector: 'app-admin-view-invoices',
  templateUrl: './admin-view-invoices.component.html',
})
export class AdminViewInvoicesComponent extends CompBaseComponent implements OnInit {
  public invoices: Array<Invoice>;
  public invoiceService: InvoiceService;
  public pageTitle = 'Invoices';
  public userId: number;
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.invoiceService = this.injector.get(InvoiceService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.userId = params['user_id'];
      this.getInvoices();
    });
  }

  onEvent(ev: any): void {
    if (ev.type === 'ChangeMode' && ev.mode === 'Add') {
      this.router.navigate(['/admin/new-invoice']);
      return;
    }
    super.onEvent(ev);
  }

  delete(ev: any, invoice): void {
    ev.preventDefault();
    if (confirm('Are you sure?') === false) {
      return;
    }
    this.reloaded = false;
    this.invoiceService.deleteInvoice(invoice.id).then(() => {
      return this.invoiceService.getInvoices(this.userId);
    }).then((invoices) => {
      return this.invoices = invoices;
    }).then(() => this.reloaded = true);
  }

  getInvoices(): void {
    this.reloaded = false;
    this.invoiceService.getInvoices(this.userId)
      .then((invoices) => this.invoices = invoices)
      .then(() => this.reloaded = true);
  }

  navigateToInvoice(event: any, invoice: Invoice): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-invoice', invoice.id]);
  }
}
