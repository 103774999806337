import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {UserService} from './user.service';
import {Trace} from '../models/trace';
import {TranService} from './tran.service';
import {Message} from '../models/message';
import {Tran} from '../models/tran';
import {CacheService} from './cache.service';
import {MessageService} from './message.service';

@Injectable()
export class DashboardService {

  constructor(private httpService: HttpService,
              private messageService: MessageService,
              private cacheService: CacheService,
              private userService: UserService,
              private tranService: TranService,
  ) {
    this.cacheService.resetSeg('dashboard');
  }

  public getComplianceDashboard(force?: boolean): Promise<Array<any>> {
    const complianceReport = this.cacheService.getSeg('dashboard', 'complianceReport');

    if (!force && complianceReport) {
      return Promise.resolve(complianceReport);
    }

    const user = this.userService.getUserSync();
    return new Promise((resolve, reject) => {
      this.httpService.get(`dashboard/compliance_dash/${user.id}`)
        .then((data) => {
          this.cacheService.setSeg('dashboard', 'complianceReport', data);
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getWorkflowDashboard(force?: boolean): Promise<Array<any>> {
    const workflowReport = this.cacheService.getSeg('dashboard', 'workflowReport');

    if (!force && workflowReport) {
      return Promise.resolve(workflowReport);
    }

    const user = this.userService.getUserSync();
    return new Promise((resolve, reject) => {
      this.httpService.get(`dashboard/workflow_dash/${user.id}`)
        .then((data) => {
          this.cacheService.setSeg('dashboard', 'workflowReport', data);
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getMessagesDashboard(force): Promise<Array<Message>> {
    const messages = this.cacheService.getSeg('dashboard', 'messages');

    if (!force && messages) {
      return Promise.resolve(messages);
    }

    return new Promise((resolve, reject) => {
      let tranIds;
      this.tranService.getTrans(force)
        .then((trans) => {
            //filter out non-active transactions
            tranIds = trans ? trans
              .filter((elt) => elt.status === Tran.STATUS.ACTIVE)
              .map((elt) => elt.id) : [];
            return this.httpService.get('messages');
          }
        ).then((data) => {
        const filtered = data.filter((elt: any) => elt.tran ? tranIds.indexOf(elt.tran.id) >= 0 : false)
          .map(elt => {
            return {
              tran: elt.tran,
              messages: Message.deserializeArray(elt.messages)
            };
          });
        this.cacheService.setSeg('dashboard', 'messages', filtered);
        resolve(filtered);
      }).catch((data) => {
        reject(data);
      });
    });
  }
}
