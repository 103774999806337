import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {UIModule} from '../components/ui.module';

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BoxComponent} from './box/box.component';
import {MessagesComponent} from './messages/messages.component';
import {PhonePipe} from './pipes/phone.pipe';
import {SsnPipe} from './pipes/ssn.pipe';
import {FnmaPhonePipe} from './pipes/fnma-phone.pipe';
import {FnmaDateToStringPipe} from './pipes/fnma-date-to-string.pipe';
import {FnmaBooleanToStringPipe} from './pipes/fnma-boolean-to-string.pipe';
import {BooleanToStringPipe} from './pipes/boolean-to-string.pipe';
import {FnmaMaritalStatusToStringPipe} from './pipes/fnma-marital-status-to-string.pipe';
import {Nl2BrPipe} from './pipes/nl2br.pipe';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {DecimalOnlyDirective} from './directives/decimal-only.directive';
import {DecimalThreeOnlyDirective} from './directives/decimal-three-only.directive';
import {NumberOnlyDirective} from './directives/number-only.directive';
import {SsnOnlyDirective} from './directives/ssn-only.directive';
import {PhoneOnlyDirective} from './directives/phone-only.directive';
import {AlphaNumericOnlyDirective} from './directives/alpha-numberic-only.directive';
import {TimeOnlyDirective} from './directives/time-only.directive';

import {SegmentHeaderComponent} from './segment-header/segment-header.component';
import {LineHeaderComponent} from './line-header/line-header.component';
import {ReadMoreComponent} from './read-more/read-more.component';

import {DateOnlyDirective} from './directives/date-only.directive';
import {OrientationHelperComponent} from './orientation-helper/orientation-helper.component';
import {AlphaOnlyDirective} from './directives/alpha-only.directive';
import {YearQuarterPipe} from './pipes/year-quarter.pipe';
import {McrReportComponent} from './mcr/mcr-report/mcr-report.component';
import {MinusSignToParens} from './pipes/minus-sign-to-parens.pipe';
import {BackButtonDirective} from './directives/back-button.directive';
import {ServiceContentComponent} from './service-content/service-content.component';
import {UpperCaseDirective} from './directives/upper-case.directive';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {PopupDialogComponent} from './popup-dialog/popup-dialog.component';
import {PopupLinkComponent} from './popup-link/popup-link.component';
import {TermsContentComponent} from './terms-content/terms-content.component';
import {SimpleNavbarComponent} from "./simple-navbar/simple-navbar.component";
import {EzUploadLogoComponent} from "./ez-upload-logo/ez-upload-logo.component";
import {POLOSLogoComponent} from "./polos-logo/polos-logo.component";
import {TrimOnBlurDirective} from "./directives/trim-on-blur.directive";
import {OrderByPipe} from "./pipes/order-by.pipe";
import {SocketMessengerComponent} from "./socket-messenger/socket-messenger.component";
import {DNDComponent} from "./dnd/dnd.component";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {YesNoOnlyDirective} from "./directives/yes-or-no-only.directive";

@NgModule({
  declarations: [
    SegmentHeaderComponent,
    ReadMoreComponent,
    LineHeaderComponent,
    BoxComponent,
    PopupDialogComponent,
    SimpleNavbarComponent,
    PopupLinkComponent,

    MessagesComponent,
    PhonePipe,
    YearQuarterPipe,
    FnmaPhonePipe,
    MinusSignToParens,
    Nl2BrPipe,
    SsnPipe,
    FnmaDateToStringPipe,
    FnmaBooleanToStringPipe,
    BooleanToStringPipe,
    OrderByPipe,
    FnmaMaritalStatusToStringPipe,
    UploadFileComponent,
    NumberOnlyDirective,
    DecimalOnlyDirective,
    DecimalThreeOnlyDirective,
    YesNoOnlyDirective,
    SsnOnlyDirective,
    PhoneOnlyDirective,
    AlphaNumericOnlyDirective,
    AlphaOnlyDirective,
    TimeOnlyDirective,
    UpperCaseDirective,
    DateOnlyDirective,
    BackButtonDirective,
    OrientationHelperComponent,
    McrReportComponent,
    ServiceContentComponent,
    TermsContentComponent,
    AutoFocusDirective,
    EzUploadLogoComponent,
    POLOSLogoComponent,
    TrimOnBlurDirective,
    SocketMessengerComponent,
    DNDComponent

  ],
  exports: [
    SegmentHeaderComponent,
    PopupDialogComponent,
    SimpleNavbarComponent,
    PopupLinkComponent,
    ReadMoreComponent,
    LineHeaderComponent,
    BoxComponent,
    MessagesComponent,
    PhonePipe,
    YearQuarterPipe,
    FnmaPhonePipe,
    MinusSignToParens,
    Nl2BrPipe,
    SsnPipe,
    FnmaDateToStringPipe,
    FnmaBooleanToStringPipe,
    BooleanToStringPipe,
    OrderByPipe,
    FnmaMaritalStatusToStringPipe,
    UploadFileComponent,
    NumberOnlyDirective,
    DecimalOnlyDirective,
    DateOnlyDirective,
    BackButtonDirective,
    DecimalThreeOnlyDirective,
    YesNoOnlyDirective,
    SsnOnlyDirective,
    PhoneOnlyDirective,
    AlphaOnlyDirective,
    AlphaNumericOnlyDirective,
    TimeOnlyDirective,
    UpperCaseDirective,
    OrientationHelperComponent,
    McrReportComponent,
    ServiceContentComponent,
    TermsContentComponent,
    AutoFocusDirective,
    TrimOnBlurDirective,
    EzUploadLogoComponent,
    POLOSLogoComponent,
    SocketMessengerComponent,
    DNDComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatRadioModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    UIModule,
    CdkDropList,
    CdkDrag
  ]
})
export class SharedModule {
}
