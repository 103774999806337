import {Component, Injector, Input, OnInit} from '@angular/core';
import {Hmda} from '../../../../models/hmda';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-hmda',
  templateUrl: './view-segment-hmda.component.html',

})
export class ViewSegmentHmdaComponent extends CompBaseComponent implements OnInit {
  @Input() item: Hmda;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'hmda';
    this.section = this.applicant;
  }
}
