import {Component, Injector, OnInit} from '@angular/core';
import {SecEmployment} from '../../../../models/sec-employment';
import {SecEmploymentComponent} from '../sec-employment/sec-employment.component';

@Component({
  selector: 'app-add-sec-employment',
  templateUrl: '../sec-employment/edit-segment-sec-employment.component.html',
})
export class AddSecEmploymentComponent extends SecEmploymentComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new SecEmployment({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
