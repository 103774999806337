<div *ngIf="reloaded&&canReadDashboard">
  <div *ngIf="isDefaultLoanApp">
    <mat-card *ngIf="txSummary.errors" class="mt-1 mb-1">
      <mat-card-title class="text-danger">Warning</mat-card-title>
      <mat-card-content>
        <div *ngIf="txSummary.noDefaultLoanApp">
          <div class="col-lg-4 fw-bold">No default loan application</div>
          <div class="col-lg-8">At least one loan application was created for this loan but none is flagged as default.</div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-1 mb-1">
      <mat-card-title>Loan Application</mat-card-title>
      <mat-card-content>
        <div>
          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Borrower name</div>
            <div class="col-lg-8">{{txSummary.borrowerFullName}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Co-Borrower?</div>
            <div class="col-lg-8">{{txSummary.isCoBorrower | booleanToString}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Property address</div>
            <div class="col-lg-8">{{txSummary.propertyAddress}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Loan purpose</div>
            <div class="col-lg-8">{{txSummary.loanPurpose}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Property appraised value</div>
            <div class="col-lg-8">{{txSummary.propertyValue ? (+txSummary.propertyValue | currency) : ''}}</div>
          </div>

          <div class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Loan amount</div>
            <div class="col-lg-8">{{txSummary.loanAmount ? (+txSummary.loanAmount | currency) : ''}}</div>
          </div>

          <div *ngIf="txSummary.loanPurpose && txSummary.loanPurpose!=='Purchase'" class="row pt-1 pb-1 border-bottom hover-gray">
            <div class="col-lg-4 fw-bold">Down payment</div>
            <div class="col-lg-8">N/A</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-1 mb-1" *ngIf="canUpdateLoanApps">
      <mat-card-title>Compliance Events</mat-card-title>
      <mat-card-content>
        <div *ngIf="isStarted">
          <div class="row pt-1 hover-gray pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Completed on time</div>
            <div class="col-lg-2 pr-success fw-bold text-center">{{levelCounts[1]}}</div>
          </div>

          <div class="row pt-1 hover-gray pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Due by after today</div>
            <div class="col-lg-2 pr-light fw-bold text-center">{{levelCounts[2]}}</div>
          </div>

          <div class="row pt-1 hover-gray pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Due by today</div>
            <div class="col-lg-2 pr-warning fw-bold text-center">{{levelCounts[3]}}</div>
          </div>

          <div class="row pt-1 hover-gray pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Completed but not on time</div>
            <div class="col-lg-2 pr-danger fw-bold text-center">{{levelCounts[4]}}</div>
          </div>

          <div class="row pt-1 hover-gray pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Missed</div>
            <div class="col-lg-2 pr-fatal fw-bold text-center">{{levelCounts[5]}}</div>
          </div>

          <div class="row hover-gray pt-1 pb-1">
            <div class="col-lg-4 fw-bold border-bottom">Compliance not applicable</div>
            <div class="col-lg-2 pr-light-gray fw-bold text-center">{{levelCounts[0]}}</div>
          </div>
        </div>
        <app-box *ngIf="!isStarted" [close]="false" [type]="'warning'">
          There are no compliance events because the
          <span class="fw-bold">workflow has not yet started!</span>
          You can start the loan workflow from
          <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
        </app-box>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-1 mb-1" *ngIf="canUpdateLoanApps">
      <mat-card-title>Access Control</mat-card-title>
      <mat-card-content>
        <div class="row pt-1 pb-1  border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">
            Owner
          </div>
          <div class="col-lg-8">
            {{txSummary.txOwner}}
          </div>
        </div>

        <div class="row pt-1 pb-1  border-bottom hover-gray">
          <div class="col-lg-4 my-auto">
            <span class="fw-bold">Users who can access the loan</span>
          </div>
          <div class="col-lg-8">
            <div *ngFor="let permission of txSummary.txPermissions" class="row pt-1 pb-1 hover-gray">
              <div class="col">
                Name: {{permission.acl_full_name}}
              </div>
              <div class="col">
                Role: {{permission.roleDescription}}
              </div>
              <div class="col">
                Access: {{permission.acl_permission == 4 ? 'Read' : 'Read/Update'}}
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 my-auto fw-bold">
            Users who can access the loan application
          </div>
          <div class="col-lg-8">
            <div *ngFor="let permission of txSummary.loanAppPermissions" class="row pt-1 pb-1 hover-gray">
              <div class="col">
                {{permission.acl_full_name}}
              </div>
              <div class="col">
                {{permission.roleDescription}}
              </div>
              <div class="col">
                {{permission.acl_permission == 4 ? 'Read' : 'Read/Update'}}
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <app-box *ngIf="!isLoanApps" class="mt-3" [type]="'warning'" [close]="false">
    The dashboard cannot be displayed as the loan application is missing.
  </app-box>

  <app-box *ngIf="isLoanApps&&!isDefaultLoanApp" class="mt-3" [type]="'warning'" [close]="false">
    There is
    <span class="fw-bold">no default loan application!</span>
    You can set the default loan application from Repository/Loan Applications.
  </app-box>
</div>
