import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {User} from '../../../../models/user';
import {SboxService} from '../../../../services/sbox.service';
import {FormUtil} from '../../../form-util';
import {Document} from '../../../../models/document';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-view-secured-uploads',
  templateUrl: './view-secured-uploads.component.html',
})
export class ViewSecuredUploadsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'ezUploads';
  public user: User;
  public sboxService: SboxService;
  public anonDocs: Array<Document>;
  public downloading: boolean;

  constructor(public injector: Injector, public sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.sboxService = this.injector.get(SboxService);
    this.reload(false);
    this.reloadOnSync();
    if (this.isMobile) {
      this.pageTitle = 'ezUploads';
    }
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.ezUploads
      });
    }, 0);
    this.eventEmitted.emit({type: 'Reload'});
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.mode = 'View';
    this.reloaded = false;
    this.user = this.userService.getUserSync();
    this.sboxService.getUserAnonDocs()
      .then((pdfs) => {
        this.anonDocs = pdfs;
        this.reloaded = true;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to get docs', data);
      });
  }

  // Not used: saving this function for the future when we start supporting Loans
  moveDocToLoan(event: any, docId): void {
    const currentTran = this.tranService.getCurrentTranSync();
    if (confirm(`Are you sure you want to move this file to loan (${currentTran.id}): "${currentTran.name}"?`) === false) {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      return;
    }

    event.preventDefault();
    const tran = this.tranService.getCurrentTranSync();
    if (!tran || !tran.id) {
      FormUtil.genErrorMessage(this.sharedService, 'There is no current loan. Select a loan from My Loans and then Move to Loan', null);
      return;
    }

    this.sboxService.moveDocToLoan(docId, tran.id)
      .then((rc) => {
        return this.tranService.getCurrentTran(true);
      })
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, `Document moved to loan: ${tran.name}. You can find it under Current Loan/Repository/Documents`, false);
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to move document to loan', null);
      });
  }
}
