import {ModelBase} from './model-base';

export class Information extends ModelBase {
  public static genders: Array<any> = [
    {key: 'Male', value: 'Male'},
    {key: 'Female', value: 'Female'},
  ];
  public static ethnicities_ex: Array<any> = [
    {key: 'HispanicOrLatino', value: 'Hispanic or Latino'},
    {key: 'NotHispanicOrLatino', value: 'Not Hispanic or Latino'},
  ];

  no_information_sex: boolean; // 1 char Y/N
  no_information_ethnicity: boolean; // 1 char Y/N
  mexico: boolean;
  puerto_rico: boolean;
  cuba: boolean;
  other: boolean;
  other_description: string;
  ethnicity_ex: string;
  gender: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'no_information_sex' || prop === 'no_information_ethnicity'
        || prop === 'mexico' || prop === 'puerto_rico' || prop === 'cuba' || prop === 'other') {
        this[prop] = super.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getEthnicity(): string {
    const found = Information.ethnicities_ex.find((elt) => elt.key === this.ethnicity_ex);
    if (!found) {
      return null;
    }
    let ethnicity = found['value'];
    const subs = [];
    if (this.cuba) {
      subs.push('Cuban');
    }
    if (this.mexico) {
      subs.push('Mexican');
    }
    if (this.puerto_rico) {
      subs.push('Puerto Rican');
    }
    if (this.other) {
      if (this.other_description) {
        subs.push(this.other_description);
      } else {
        subs.push('Other');
      }
    }
    if (subs.length > 0) {
      ethnicity += (' (' + subs.join(', ') + ')');
    }

    return ethnicity;
  }

  getGender(): string {
    const found = Information.genders.find((elt) => elt.key === this.gender);
    return found ? found['value'] : null;
  }

  toServerPayload(): any {
    return {
      ethnicity_ex: this.ethnicity_ex,
      gender: this.gender,
      no_information_sex: ModelBase.toServerBoolean(this.no_information_sex),
      mexico: ModelBase.toServerBoolean(this.mexico),
      puerto_rico: ModelBase.toServerBoolean(this.puerto_rico),
      cuba: ModelBase.toServerBoolean(this.cuba),
      other: ModelBase.toServerBoolean(this.other),
      other_description: this.other_description,
      no_information_ethnicity: ModelBase.toServerBoolean(this.no_information_ethnicity),
    };
  }
}
