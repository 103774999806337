<mat-card *ngIf="reloaded">
  <mat-card-title>
    ezUploads
  </mat-card-title>

  <mat-card-content *ngIf="hasEzUploadsService">
    <h5>
      To send an upload file request:
    </h5>

    <ul>
      <li>Navigate to <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.</li>
      <li>Select the ezUploads Request tab.</li>
      <li>Provide the contact information of your customer and the files you are requesting.</li>
      <li>Click Send Request.</li>
    </ul>

    <h5>
      To receive uploaded files:
    </h5>

    <ul>
      <li>Navigate to <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.</li>
      <li>For each uploaded file, verify that it is indeed what you asked for.
        The <b>"Requester"</b> and <b>"Uploader"</b> texts are the descriptions that you and your customer supplied, respectively.
      </li>
      <li>If the file is a PDF, click the PDF icon to download it.
        If the file is an image, click the image icon and then save it. Use the browser's "save image" function (Save Image As if you use Chrome) to save the image.
      </li>
      <li>When you're done downloading the file, delete it.</li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="hasPOLOSService">
    <div>
      If you are new to POLOS, please take a few minutes to get started with POLOS.
      The following steps will make you productive quickly.
    </div>

    <h5>Account Setup</h5>

    <ul>
      <li>
        Enter your cell phone number and the mobile carrier name.
        This will enable your contacts to send you text messages.
      </li>
      <li>Verify that your role is
        <strong>Loan Officer</strong> and your status is Current.
      </li>
      <li>
        Upload your portrait photo.
        Your photo will display on any contact list including your borrower's.
      </li>
      <li>
        Fill out Account/MLO Configuration.
        It provides defaults for the loan application and generated documents.
      </li>
    </ul>

    <h5>Contact Setup</h5>
    To grant a user access to any of the loan resources, they
    <strong>must be in your contact list</strong>.

    <ul>
      <li>In Account/Contacts, add contacts using their email address or usernames (usually, a string like 'john.doe' for John Doe)</li>
    </ul>

    <h5>Create Your First Loan</h5>

    <div>
      Create your first loan at Loans/New Loan.
    </div>

    <h5>Create Your First Loan Application</h5>

    <div>
      Think of Current Loan/Repository as a repository for the loan records.
      This is where you define loan folders, upload, download, generate documents, and create loan applications.
      To create a loan application:
    </div>

    <ul>
      <li>Go to Current Loan/Repository.</li>
      <li>Click the Loan Applications tab.</li>
      <li>Click the Add (plus) button, supply a name, and click Create.</li>
    </ul>

    <h5>Start the Workflow</h5>

    <div>
      The POLOS workflow is a powerful facility that integrates workflow with compliance.
      When you update the workflow you
      <span class="fw-bold">should</span> also update compliance events.
      You should start the workflow when you are ready to work on a loan in earnest.
      To start the workflow:
    </div>

    <ul>
      <li>
        Go to Current Loan/Workflow.
        The caption should display "Workflow &#64; New".
      </li>
      <li>Click the Edit (pencil) button.</li>
      <li>Select Start and click Update.</li>
    </ul>
    <h5>Ready to Roll</h5>

    <div>
      This is for a quick start.
      At this point, navigate through the loan facilities to familiarize yourself with its many functions.
      Finally, visit Access Control and learn how to grant access to your borrower, processor, or real estate agent.
    </div>

    <h5>Last Word</h5>

    <div>
      Contextual Help is available anywhere in POLOS.
      On any page, hit the question mark icon (<span class="far fa-question-circle"></span>) to access Help.
      To close Help, hit anywhere on the Help panel or on the Help icon.
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="isBorrower">
    <div>
      If you are new to POLOS, please take a few minutes to get started with POLOS.
    </div>

    <h5>Account Setup</h5>

    <ul>
      <li>
        Enter your cell phone and mobile carrier name.
        This will enable your loan officer to send you text messages.
      </li>
      <li>Verify that your role is
        <strong>Borrower</strong> and your status is Current.
      </li>
      <li>
        Upload your portrait photo.
        Your photo will display on any contact list (including your loan officer's) and
        help your loan officer identify you.
      </li>
    </ul>

    <h5>Contact List</h5>
    Your loan officer should appear in your contact list.
    If it does not, ask your loan officer to add you to their contact list using your email.

    <h5>Your Loan</h5>

    <div>
      If your loan officer created your loan and grant you access to it, you should be able to access it at Loans/My Loans.
    </div>

    <h5>Your Loan Application</h5>

    <div>
      If your loan officer created your loan application and grant you access to it, you should be able to access it at Current Loan/Loan Application.
    </div>

    <h5>Last Word</h5>

    <div>
      Contextual Help is available anywhere in POLOS.
      On any page, hit the question mark icon (<span class="far fa-question-circle"></span>) to access Help.
      To close Help, hit anywhere on the Help panel or click again on the Help icon.
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="isREAgent">
    <div>
      If you are new to POLOS, please take a few minutes to get started with POLOS.
    </div>

    <h5>Account Setup</h5>

    <ul>
      <li>Enter your cell phone and mobile carrier name.
        This will enable your contacts to send you text messages.
      </li>
      <li>Verify that your role is
        <strong>Real Estate Agent</strong> and your status is Current.
      </li>
      <li>
        Upload your portrait photo.
        This will help the loan officer identify you.
      </li>
    </ul>

    <h5>Contact List</h5>
    The loan officer should appear in your contact list (refer to Account/Contacts).
    Otherwise, please contact the loan officer and ask them to add you to their contact list using your email.

    <h5>Your Loan</h5>

    <div>
      If the loan officer created a loan and granted you access to it, you should be able to view it in Loans/My Loans
      and access Current Loan/Timeline and Current Loan/Messages.
    </div>

    <h5>Last Word</h5>

    <div>
      Contextual Help is available anywhere in POLOS.
      On any page, hit the question mark icon (<span class="far fa-question-circle"></span>) to access Help.
      To close Help, click again on the Help icon on anywhere on the Help panel.
    </div>
  </mat-card-content>
</mat-card>
