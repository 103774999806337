import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Borrower} from '../../../../models/borrower';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-borrower',
  templateUrl: './edit-segment-borrower.component.html',
})
export class EditSegmentBorrowerComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Borrower;
  public title: string;
  public readonly language_codes = Borrower.language_codes;
  public readonly marital_statuses_ex = Borrower.martial_statuses_ex;
  public readonly domestic_relationships = Borrower.domestic_relationships;
  public borrowersTable: {
    key: string,
    value: string
  }[];

  // form and form controls
  public form: FormGroup;
  public ssn: FormControl;
  public first: FormControl;
  public middle: FormControl;
  public last: FormControl;
  public suffix: FormControl;
  public email: FormControl;
  public dob: FormControl;
  public home_phone: FormControl;
  public cell_phone: FormControl;
  public work_phone: FormControl;
  public work_phone_extension: FormControl;
  public marital_status_ex: FormControl;
  public domestic_relationship_indicator: FormControl;
  public domestic_relationship_type: FormControl;
  public domestic_relationship_other_description: FormControl;
  public domestic_relationship_state_code: FormControl;
  public community_state: FormControl;
  public caivrs_identifier: FormControl;
  public language_code: FormControl;
  public other_language: FormControl;
  public minDate = globals.date.minPersonDOB;
  public maxDate = globals.date.maxPersonDOB;
  public submitted = false;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    let applicantAbbr = '';
    try {
      applicantAbbr = 'b' + this.applicant.split('_')[1].toString();
    } catch {
    }
    this.borrowersTable = this.analyzerService.getBorrowerTable(this.doc.recursive_attributes);
    this.borrowersTable = this.borrowersTable.filter((elt) => {
      return elt.key !== applicantAbbr;
    });
    this.borrowersTable.push({key: 'na', value: 'Not Married to Any Borrower'})
    this.segment = this.applicant;
    this.section = this.applicant;
    switch (this.applicant) {
      case 'borrower_1':
        this.title = 'Borrower';
        break;
      case 'borrower_2':
        this.title = 'Co-Borrower';
        break;
      case 'borrower_3':
        this.title = 'Borrower 3';
        break;
      case 'borrower_4':
        this.title = 'Borrower 4';
        break;
    }
    this.reload();
  }

  createFormControls(item): void {
    this.first = new FormControl(item.first, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.middle = new FormControl(item.middle);
    this.last = new FormControl(item.last, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.suffix = new FormControl(item.suffix);
    this.email = new FormControl(item.email, Validators.pattern(globals.pattern.email));
    this.ssn = new FormControl(item.ssn, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.ssn)]));
    this.dob = new FormControl(item.dob, AppValidators.present);
    this.home_phone = new FormControl(item.home_phone, Validators.pattern(globals.pattern.phone));
    this.cell_phone = new FormControl(item.cell_phone, Validators.pattern(globals.pattern.phone));
    this.work_phone = new FormControl(item.work_phone, Validators.pattern(globals.pattern.phone));
    this.work_phone_extension = new FormControl(item.work_phone_extension);
    this.marital_status_ex = new FormControl(item.marital_status_ex);
    this.domestic_relationship_indicator = new FormControl(item.domestic_relationship_indicator);
    this.domestic_relationship_type = new FormControl(item.domestic_relationship_type);
    this.domestic_relationship_other_description = new FormControl(item.domestic_relationship_other_description);
    this.domestic_relationship_state_code = new FormControl(item.domestic_relationship_state_code, Validators.pattern(globals.pattern.state));
    this.community_state = new FormControl(item.community_state);
    this.caivrs_identifier = new FormControl(item.caivrs_identifier);
    this.language_code = new FormControl(item.language_code);
    this.other_language = new FormControl(item.other_language);
  }

  createForm(): void {
    this.form = new FormGroup({
      first: this.first,
      middle: this.middle,
      last: this.last,
      suffix: this.suffix,
      email: this.email,
      ssn: this.ssn,
      dob: this.dob,
      home_phone: this.home_phone,
      cell_phone: this.cell_phone,
      work_phone: this.work_phone,
      work_phone_extension: this.work_phone_extension,
      marital_status_ex: this.marital_status_ex,
      domestic_relationship_indicator: this.domestic_relationship_indicator,
      domestic_relationship_type: this.domestic_relationship_type,
      domestic_relationship_other_description: this.domestic_relationship_other_description,
      domestic_relationship_state_code: this.domestic_relationship_state_code,
      community_state: this.community_state,
      caivrs_identifier: this.caivrs_identifier,
      language_code: this.language_code,
      other_language: this.other_language,
    });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.value;
    const updated = new Borrower(formValue);
    const data = {
      segment: this.segment,
      section: this.section
    };
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    this.createFormControls(this.item);
    this.createForm();
  }
}
