import {Component, Injector, OnInit} from '@angular/core';
import {Credit} from '../../../../models/credit';
import {CreditComponent} from '../credit/credit.component';

@Component({
  selector: 'app-add-credit',
  templateUrl: '../credit/edit-segment-credit.component.html',
})
export class AddCreditComponent extends CreditComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Credit({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
