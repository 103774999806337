import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../comp-base.component';
import {Help} from '../../../models/help';
import {OrganizationService} from '../../../services/organization.service';
import {OrgTran} from '../../../models/org-tran';

@Component({
  selector: 'app-view-org-loans',
  templateUrl: './view-org-loans.component.html',
})
// Comment: TODO: this component is no longer used
export class ViewOrgLoansComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public orgService: OrganizationService;
  public isOrg: boolean;
  public trans: Array<OrgTran>;
  public filteredTrans: Array<OrgTran>;
  public pageTitle = 'Organization Loans';

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    if (this.isMobile) {
      this.pageTitle = 'Org Loans';
    }
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.orgLoans
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.orgService = this.injector.get(OrganizationService);
    this.reload(false);
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const user = this.userService.getUserSync();
    this.isOrg = !!user.organization_id;
    if (!user.organization_id) {
      this.reloaded = true;
      return;
    }
    this.orgService.getOrgTrans(user.organization_id)
      .then((trans) => {
        this.trans = trans;
        this.filteredTrans = this.trans.filter((elt) => {
          return elt.status === Tran.STATUS.ACTIVE;
        }).sort((x, y) => x['created_at'] === y['created_at'] ? 0 : (x['created_at'] < y['created_at'] ? 1 : -1));
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  onFilterChange(event: any): void {
    this.reload();
  }

  navigateToTran(tran: OrgTran): void {
    if (tran.user_permission === null || tran.user_permission === 0) {
      return;
    }
    const user = this.userService.getUserSync();
    this.tranService.getTran(tran.id)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'org'});
      })
      .then(() => {
        if (user.isLoanProfessional()) {
          this.router.navigate(['/loan/view-dashboard']);
          return;
        }
        if (user.isBorrower()) {
          this.router.navigate(['/loan/view-directory']);
          return;
        }
        if (user.isRealEstateAgent()) {
          this.router.navigate(['/loan/view-timeline']);
          return;
        }
      });
  }
}
