import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../comp-base.component';
import {Document} from '../../models/document';

@Component({
  selector: 'app-loan-app-tab',
  template: ''
})
export class ViewLoanAppTabComponent extends CompBaseComponent implements OnInit {
  public currentSeg: string;
  public isDownPayment: boolean;
  public applicantTitle: string;
  public doc: any;

  public isARMLoan: boolean;
  public isConstructionLoan: boolean;
  public isRefinanceLoan: boolean;
  public isPurchaseLoan: boolean;
  public isConstructionOrRefinanceLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setMenuOpen(true);
    this.sharedService.eventEmitted.subscribe((event) => {
      switch (event.type) {
        case 'ToggleNestedMenu':
          this.setMenuOpen(!this.isOpen);
          break;
        case 'ReloadLoanAppTab':
          this.setMenuOpen(true);
          this.reloadTab();
          break;
        case 'ReloadLoanAppSegment':
          this.setMenuOpen(false);
          break;
      }
    });
  }

  reloadTab(): void {
    this.reloadSegment();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loanAppService.getCurrentLoanDocument()
      .then((doc: Document) => {
        if (doc) {
          this.doc = doc;
          switch (this.applicant) {
            case 'borrower_1':
              this.applicantTitle = 'Borrower';
              break;
            case 'borrower_2':
              this.applicantTitle = 'Co-Borrower';
              break;
            case 'borrower_3':
              this.applicantTitle = 'Borrower 3';
              break;
            case 'borrower_4':
              this.applicantTitle = 'Borrower 4';
              break;
          }
          this.isDownPayment = this.analyzerService.isDownPayment(this.doc['recursive_attributes']);
          this.isARMLoan = this.analyzerService.isARMLoan(this.doc.recursive_attributes);
          this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
          this.isPurchaseLoan = this.analyzerService.isPurchaseLoan(this.doc.recursive_attributes);
          this.isConstructionLoan = this.analyzerService.isConstructionLoan(this.doc.recursive_attributes);
          this.isConstructionOrRefinanceLoan = this.isConstructionLoan || this.isRefinanceLoan;
          this.reloaded = true;
        }
      });
  }

  public reloadSegment(): void {
  }

  public setMenuOpen(isOpen: boolean) {
    this.isOpen = CompBaseComponent.isOpen = isOpen;
  }
}
