import {Component, Injector, OnInit} from '@angular/core';
import {NewMortgageComponent} from '../new-mortgage/new-mortgage.component';

@Component({
  selector: 'app-view-new-mortgage',
  templateUrl: './view-new-mortgage.component.html',
})
export class ViewNewMortgageComponent extends NewMortgageComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
