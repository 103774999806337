<mat-card *ngIf="mode=='View'" class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Down payment source</div>
      <div class="col-lg-6">{{item.getFundsSource()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Down payment amount</div>
      <div class="col-lg-6">{{item.down_payment_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.funds_source==='Other'">
      <div class="col-lg-6 fw-bold">Down payment explanation</div>
      <div class="col-lg-6">{{item.down_payment_explanation}}</div>
    </div>
  </mat-card-content>
</mat-card>
