<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on MLO Configuration
  </mat-card-title>

  <mat-card-content>
    <p>
      The MLO configuration settings are used as default values for generating loan documents.
    </p>

    <ul>
      <li>
        <span class="fw-bold">NMLS:</span> The loan officer's NMLS ID.
      </li>
      <li>
        <span class="fw-bold">State license number:</span> The loan officer's state license number.
      </li>
      <li>
        <span class="fw-bold">Company NMLS:</span> The loan officer's company's NMLS ID.
      </li>
      <li>
        <span class="fw-bold">FNMA Institution fields:</span>
        These fields are applicable to a loan officer who submits directly to Fannie Mae AUS.
        Ignore these fields, if you submit to AUS through your lender.
      </li>
      <li>
        <span class="fw-bold">Compensation fields:</span>
        These fields are used to calculate the loan officer's compensation figures.
        The calculated figures serve only as hints to the loan officer.
      </li>
      <li>
        <span class="fw-bold">ECOA address:</span>
        The applicable ECOA address in the ECOA disclosure document.
      </li>
    </ul>
  </mat-card-content>
</mat-card>
