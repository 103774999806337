<mat-card *ngIf="item" class="mb-1">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-2 align-self-center">
        <img class="thumbnail-img" src="{{item.getThumbnailPath()}}" alt="Contact thumbnail">
      </div>
      <div class="col-lg-6 text-start align-self-center">
        <h5 class="mt-2 mb-1">{{ item.first }}
          <small>({{item.getRolesString()}})</small>
        </h5>
        <span>{{item.full_name}}</span>&nbsp;
        <span>({{item.email}})</span>
      </div>
      <div [hidden]="item.hidden" class="col-lg-2 align-self-center text-end">
        <a (click)="hide($event)" href="#" title="Hide contact"><i class="text-warning fas fa-eye-slash"></i><span [hidden]="isMobile" class="text-warning">&nbsp;Hide</span></a>
      </div>
      <div [hidden]="!item.hidden" class="col-lg-2 align-self-center text-end">
        <a (click)="show($event)" href="#" title="Hide contact"><i class="text-primary fas fa-eye"></i><span [hidden]="isMobile" class="text-primary">&nbsp;Show</span></a>
      </div>
      <div class="col-lg-2 align-self-center text-end">
        <a (click)="delete($event)" href="#" title="Delete contact"><i class="text-danger fas fa-trash"></i></a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
