import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Message} from '../../../models/message';
import {MessageService} from '../../../services/message.service';
import {Tran} from '../../../models/tran';
import {Contact} from '../../../models/contact';
import {User} from '../../../models/user';
import {FormUtil} from '../../form-util';
import {CommComponent} from "../comm.component";

@Component({
  selector: 'app-message',
  template: '',
})
export class MessageComponent extends CommComponent implements OnInit {
  @Input() message: Message;
  public messageService: MessageService;
  public contacts: Array<Contact>;
  public tran: Tran;
  public user: User;
  smsMessageLimit: number;
  public isContacts: boolean;

  // form and form controls
  public form: FormGroup;
  public message_text: FormControl;
  public is_sms: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.messageService = this.injector.get(MessageService);
  }

  createFormControls(): void {
    this.smsMessageLimit = 160 - ('From: ' + this.user.full_name + '\n\nDo not reply!').length;
    this.contactArray = new FormArray([]);
    this.tranContactArray = new FormArray([]);
    this.contacts.forEach(() => this.contactArray.push(new FormControl()));
    this.tranContacts.forEach(() => this.tranContactArray.push(new FormControl()));
    this.message_text = new FormControl('', AppValidators.present);
    this.is_sms = new FormControl(false);
  }

  canSMS(): boolean {
    const canSomeContactsSMS  = this.contactArray.controls.some((elt, index) => {
      if (elt.value) {
        return this.contacts[index].is_text_messaging;
      }
    });
    const canSomeTranContactsSMS  = this.tranContactArray.controls.some((elt, index) => {
      if (elt.value) {
        return this.contacts[index].is_text_messaging;
      }
    });

    return canSomeContactsSMS || canSomeTranContactsSMS;
  }

  canSend(): boolean {
    const anyContact = this.contactArray.controls.some((elt, index) => {
      return elt.value;
    });
    const anyTranContact = this.tranContactArray.controls.some((elt, index) => {
      return elt.value;
    });
    return (anyContact || anyTranContact) && this.message_text.value.length > 0;
  }

  createForm(): void {
    this.form = new FormGroup({
      message_text: this.message_text,
      contacts: this.contactArray,
      tranContacts: this.tranContactArray,
      is_sms: this.is_sms
    });
  }

  delete(event: any): void {
    event.preventDefault();
    this.messageService.deleteMessage(this.message.id)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  async sendMessage(): Promise<boolean> {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return Promise.reject(false);
    }

    const notifyContacts = [];
    this.contactArray.controls.forEach((control, index) => {
      if (control.value) {
        notifyContacts.push(this.contacts[index]);
      }
    });
    this.tranContactArray.controls.forEach((control, index) => {
      if (control.value) {
        notifyContacts.push(this.tranContacts[index]);
      }
    });

    let emails = {}
    const messages: Array<Promise<any>> = [];
    notifyContacts.forEach((contact) => {
      if (!(contact.email in emails)) {
        const payload = {
          contact_id: contact.id,
          is_sms: this.is_sms.value && contact.is_text_messaging,
          message_text: this.message_text.value,
          tran_id: this.tran ? this.tran.id : null
        };
        messages.push(this.messageService.sendMessage(payload));
        emails[contact.email] = true;
      }
    });
    try {
      await Promise.all(messages);
      FormUtil.genSuccessMessage(this.sharedService, 'Message successfully sent');
      this.eventEmitted.emit({type: 'Reload'});
      return Promise.resolve(true);
    } catch (data) {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to send message to one or more recipients', data);
      return Promise.reject(data);
    }
  }
}
