<div *ngIf="reloaded&&canDeleteTransaction">
  <ul class="nav nav-tabs" id="permission_tabs">
    <li class="nav-item">
      <a (click)="setSection($event,section.transactionPermissions)" [ngClass]="{active:currentSection===section.transactionPermissions}"
        [attr.aria-selected]="currentSection===section.transactionPermissions" class="nav-link" data-toggle="tab" id="transaction_permissions_tab">
        Loan
      </a>
    </li>

    <li class="nav-item" *ngIf="isLoanOfficerTran">
      <a (click)="setSection($event,section.loanAppPermissions)" [ngClass]="{active:currentSection===section.loanAppPermissions}"
        [attr.aria-selected]="currentSection===section.loanAppPermissions" class="nav-link" data-toggle="tab" id="loan_app_permissions_tab">
        Loan Application
      </a>
    </li>

    <li class="nav-item" *ngIf="isLoanOfficerTran">
      <a (click)="setSection($event,section.docPermissions)" [ngClass]="{active:currentSection===section.docPermissions}"
        [attr.aria-selected]="currentSection===section.docPermissions" class="nav-link" data-toggle="tab" id="doc_permissions_tab">
        Documents
      </a>
    </li>

    <li class="nav-item" *ngIf="isLoanOfficerTran">
      <a (click)="setSection($event,section.workflowPermissions)" [ngClass]="{active:currentSection===section.workflowPermissions}"
        aria-controls="workflow_permissions" [attr.aria-selected]="currentSection===section.workflowPermissions" class="nav-link" data-toggle="tab" id="workflow_permissions_tab">
        Timeline
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':currentSection===section.transactionPermissions}" aria-labelledby="transaction_permissions_tab" class="tab-pane fade" id="transaction_permissions" role="tabpanel">
      <app-view-permissions (eventEmitted)="onEvent($event)" [type]="'transaction'"></app-view-permissions>
    </div>

    <div [ngClass]="{'show active':currentSection===section.loanAppPermissions}" aria-labelledby="loan_app_permissions_tab" class="tab-pane fade" id="loan_app_permissions" role="tabpanel">
      <app-view-permissions (eventEmitted)="onEvent($event)" [type]="'loan_app'"></app-view-permissions>
    </div>

    <div [ngClass]="{'show active':currentSection===section.docPermissions}" aria-labelledby="doc_permissions_tab" class="tab-pane fade" id="doc_permissions" role="tabpanel">
      <app-view-permissions (eventEmitted)="onEvent($event)" [type]="'document'"></app-view-permissions>
    </div>

    <div [ngClass]="{'show active':currentSection===section.workflowPermissions}" aria-labelledby="workflow_permissions_tab" class="tab-pane fade" id="workflow_permissions" role="tabpanel">
      <app-view-permissions (eventEmitted)="onEvent($event)" [type]="'workflow'"></app-view-permissions>
    </div>
  </div>
</div>
