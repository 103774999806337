import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';
import {globals} from '../../../../shared/globals/globals';
import {FormUtil} from '../../../form-util';
import {DataService} from '../../../../services/data.service';

@Component({
  selector: 'app-edit-segment-account',
  templateUrl: './edit-segment-account.component.html',
})
export class EditSegmentAccountComponent extends CompBaseComponent implements OnInit {
  @Input() public item: User;
  public readonly carriers = DataService.getCarriers();

  // form and form controls
  form: FormGroup;
  prefix: FormControl;
  first: FormControl;
  middle: FormControl;
  last: FormControl;
  suffix: FormControl;
  cell_phone: FormControl;
  mobile_carrier_name: FormControl;
  user: any;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(user) {
    this.prefix = new FormControl(user.prefix);
    this.first = new FormControl(user.first, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.middle = new FormControl(user.middle);
    this.last = new FormControl(user.last, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.suffix = new FormControl(user.suffix);
    this.cell_phone = new FormControl(user.cell_phone, Validators.pattern(globals.pattern.phone));
    this.mobile_carrier_name = new FormControl(user.mobile_carrier_name);
  }

  createForm() {
    this.form = new FormGroup({
      prefix: this.prefix,
      first: this.first,
      middle: this.middle,
      last: this.last,
      suffix: this.suffix,
      cell_phone: this.cell_phone,
      mobile_carrier_name: this.mobile_carrier_name
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const updated = new User(this.form.value);
    updated.id = this.item.id;
    this.userService.updateAccount(this.form.value)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Account successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update account', data);
        this.cancel();
      });
  }
}
