import {Component, Injector, OnInit} from '@angular/core';
import {PitchService} from '../../../services/pitch.service';
import {Help} from '../../../models/help';
import {SharedService} from '../../../layouts/shared-service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtil} from "../../form-util";

const million = 1000000.0;

@Component({
  selector: 'app-view-convertible',
  templateUrl: './view-convertible.component.html',
})
export class ViewConvertibleComponent implements OnInit {
  pageTitle = 'Convertible Note Computations';
  computed: boolean;
  assumptions: Array<any> = [];
  computations: Array<any> = [];
  positions: Array<any>;
  //
  // form & controls
  //
  form: FormGroup;
  note: FormControl;
  valuation_cap: FormControl;
  valuation_event_years: FormControl;
  valuation: FormControl;
  discount: FormControl;
  interest: FormControl;
  req_capital: FormControl;

  constructor(public injector: Injector, private sharedService: SharedService, private pitchService: PitchService) {
  }

  ngOnInit() {
    this.computed = false;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountOrg
      });
    }, 0);
    this.reload();
  }

  createFormControls() {
    this.note = new FormControl(2.0, Validators.required)
    this.valuation_cap = new FormControl(12.0, Validators.required)
    this.valuation_event_years = new FormControl(1.75, Validators.required)
    this.valuation = new FormControl(24.0, Validators.required)
    this.discount = new FormControl(0.20, Validators.required)
    this.interest = new FormControl(0.08, Validators.required)
    this.req_capital = new FormControl(5.0, Validators.required)
  }

  createForm() {
    this.form = new FormGroup({
      note: this.note,
      valuation_cap: this.valuation_cap,
      valuation_event_years: this.valuation_event_years,
      valuation: this.valuation,
      discount: this.discount,
      interest: this.interest,
      req_capital: this.req_capital
    });
  }

  reload(): void {
    this.createFormControls();
    this.createForm();
  }

  createMonthArray(prefix: string, len: number): Array<string> {
    let mArr = [];
    for (let i = 0; i < len; i++) {
      mArr[i] = prefix + (i + 1);
    }
    return mArr;
  }

  onSubmit(): void {
    this.computed = false;
    const options = {
      'note': +this.note.value * million,
      'valuation_cap': +this.valuation_cap.value * million,
      'valuation_event_years': +this.valuation_event_years.value,
      'valuation': +this.valuation.value * million,
      'discount': +this.discount.value,
      'interest': +this.interest.value,
      'req_capital': +(this.req_capital.value * million)
    }

    this.pitchService.getConvertibleReport(options)
      .then((report) => {
        this.assumptions = []
        this.computations = []
        this.positions = []
        const assumptions = report['assumptions'];
        const computations = report['computations'];
        for (const key of Object.keys(assumptions)) {
          this.assumptions.push({
            key: key,
            value: assumptions[key]
          })
        }
        for (const key of Object.keys(computations)) {
          this.computations.push({
            key: key,
            value: computations[key]
          })
        }
        this.computed = true;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to compute convertible data', data);
      });
  }
}
