<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Down payment source</mat-label>
            <mat-select formControlName="funds_source" placeholder="Select down payment source" required>
              <mat-option *ngFor="let funds_source of funds_sources" [value]="funds_source.key">{{funds_source.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="funds_source.hasError('required')">
              Down payment source is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Down payment amount</mat-label>
            <input formControlName="down_payment_amount" appDecimalOnly matInput placeholder="Down payment amount" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="down_payment_amount.hasError('required')">
              Down payment amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="funds_source.value==='Other'">
        <div class="col">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Down payment explanation</mat-label>
            <input formControlName="down_payment_explanation" matInput placeholder="Explain down payment" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="down_payment_explanation.hasError('required')">
              Down payment explanation is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
