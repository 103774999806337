import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Organization} from '../models/organization';
import {OrgTran} from '../models/org-tran';
import {CacheManager} from '../models/cache-manager';

@Injectable()
export class OrganizationService {
  public defaultOrganization: Organization;

  constructor(private httpService: HttpService) {
  }

  public findOrganizationById(orgId: number): Promise<Organization> {
    if (!orgId) {
      return Promise.reject(null);
    }
    return new Promise((resolve, reject) => {
      this.httpService.get(`organizations/find_org_by_id/${orgId}`)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getUserOrgInfo(): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`organizations/get_user_org_info`)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getOrgTrans(orgId: number): Promise<Array<OrgTran>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`organizations/get_trans/${orgId}`, {name: name})
        .then((data) => {
          resolve(OrgTran.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public createOrganization(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('organizations', params)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateOrganization(orgId: number, params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`organizations/update/${orgId}`, params)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public deleteOrgLogo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('organizations/logo')
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public reassignOrgAdmin(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`organizations/set_org_admin`, params)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public setDefaultOrganization(org: Organization): void {
    this.defaultOrganization = org;
    CacheManager.storeCurrentOrgId(org.id);
  }

  public getDefaultOrganization(): Promise<Organization> {
    if (this.defaultOrganization) {
      return Promise.resolve(this.defaultOrganization);
    }
    if (CacheManager.getCurrentOrgId()) {
      return this.findOrganizationById(CacheManager.getCurrentOrgId());
    }
    return Promise.reject(null);
  }
}
