import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../../form-util';
import {CFAComponent} from '../cfa/cfa.component';

@Component({
  selector: 'app-edit-segment-cfa-entry',
  templateUrl: './edit-segment-cfa-entry.component.html',
})
export class EditSegmentCFAEntryComponent extends CFAComponent implements OnInit {
  @ViewChild('field1Elt') field1Elt: ElementRef;
  @ViewChild('field2Elt') field2Elt: ElementRef;
  // form and form controls
  public form: FormGroup;
  public field1: FormControl;
  public field2: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    // communicate to all CFA entries that the this CFA entry is now under edit
    const field = this.cfaService.getField();
    setTimeout(() => {
      switch (field) {
        case 'field1':
          this.field1Elt.nativeElement.focus();
          break;
        case 'field2':
          this.field2Elt.nativeElement.focus();
          break;
      }
    }, 0);
    this.eventService.eventEmitter.emit({type: 'editEntry', hashEntry: this.hashEntry});
  }

  createFormControls(): void {
    this.field1 = new FormControl(this.hash[this.hashEntry]['field1']);
    this.field2 = new FormControl(this.hash[this.hashEntry]['field2']);
  }

  createForm(): void {
    this.form = new FormGroup({
      field1: this.field1,
      field2: this.field2,
    });
  }

  submit(): void {
    if (this.prefix === 'y') {
      if (!this.validateYear(this.field1) || !this.validateYear(this.field2)) {
        return;
      }
      if (this.field1.value === this.field2.value && +this.field1.value > 0) {
        FormUtil.genErrorMessage(this.sharedService, 'Years must be different', null);
        this.field2.setValue(null);
        this.cancel();
        return;
      }
    }

    this.hash[this.hashEntry]['field1'] = this.field1.value;
    this.hash[this.hashEntry]['field2'] = this.field2.value;
    // Reload the parent (view-cfa-entry) so that we end up in the View mode
    this.eventEmitted.emit({type: 'Reload'});
    // Emit an event to the parent (so that it will percolate up in the CFA main component)
    this.eventEmitted.emit({type: 'Entry'});
  }

  validateYear(field: any): boolean {
    const val = field.value || '';
    if (val && val.indexOf('.') >= 0 || (+val > 0 && +val <= 2010) || +val > 2024) {
      FormUtil.genErrorMessage(this.sharedService, 'Year is invalid', null);
      field.setValue(null);
      this.cancel();
      return false;
    }
    return true;
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  getPlaceholder(): string {
    switch (this.prefix) {
      case 'y':
        return 'Year';
      default:
        return 'Amount';
    }
  }
}
