<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Creditor name</mat-label>
            <input formControlName="creditor_name" matInput placeholder="Creditor name" maxlength="{{MAX_LEN.GEN_NAME}}" appAutoFocus>
            <mat-error *ngIf="creditor_name.hasError('required')">
              Creditor name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-2 my-auto">
          <mat-label>Lien type</mat-label>
        </div>
        <div class="col-lg-4 text-end">
          <mat-radio-group aria-label="lien_label" formControlName="first_lien" required>
            <mat-radio-button [checked]="first_lien.value===true" [value]="true">First lien</mat-radio-button>
            <mat-radio-button [checked]="first_lien.value===false" [value]="false">Subordinate lien</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="submitted&&first_lien.hasError('required')">
            Lien type is required
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly payment</mat-label>
            <input appDecimalOnly formControlName="monthly_payment" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Monthly payment" required>
            <mat-error *ngIf="monthly_payment.hasError('required')">
              Monthly payment is required
            </mat-error>
            <mat-error *ngIf="monthly_payment.hasError('pattern')">
              Monthly payment is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Lien amount</mat-label>
            <input appDecimalOnly formControlName="loan_amount" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Loan amount" required>
            <mat-error *ngIf="loan_amount.hasError('required')">
              Loan amount is required
            </mat-error>
            <mat-error *ngIf="loan_amount.hasError('pattern')">
              Loan amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Credit limit</mat-label>
            <input appDecimalOnly formControlName="credit_limit" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Credit limit">
            <mat-error *ngIf="credit_limit.hasError('required')">
              Credit limit is required
            </mat-error>
            <mat-error *ngIf="credit_limit.hasError('pattern')">
              Credit limit is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
