import {Component, Injector, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {FormGroup} from '@angular/forms';
import {Tran} from '../../../../models/tran';
import {AdminTranComponent} from '../admin-tran.component';

@Component({
  selector: 'app-admin-view-tran',
  templateUrl: '../admin-view-tran.component.html',
})
export class AdminViewTranComponent extends AdminTranComponent implements OnInit {
  pageTitle = 'View Tran';
  tran: Tran;
  tranId: number;
  form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);

    this.route.paramMap.subscribe((map) => {
      this.tranId = map['params']['id'];
      this.reload();
    });
  }

  createFormControls() {
  }

  createForm() {
    this.form = new FormGroup({});
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.adminService.findTranById(this.tranId)
      .then((tran) => {
        this.tran = tran;
      }).then(() => {
      this.reloaded = true;
    });
  }

  revokeAccess(event, permission: any): void {
    event.preventDefault();
    if (confirm('Are you sure?') === false) {
      return;
    }

    this.reloaded = false;
    this.adminService.revokeLOAccess(this.tranId, permission.acl_user_id)
      .then((tran) => {
        this.tran = tran;
        this.reloaded = true;
      });
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.router.navigate(['/admin/edit-tran', this.tran.id]);
    }
  }
}
