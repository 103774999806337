import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Credit} from '../../../../models/credit';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-credit',
  template: '',
})
export class CreditComponent extends CompBaseComponent implements OnInit {
  @Input() item: Credit;
  public credit_codes = Credit.credit_codes;

  // form fields
  public form: FormGroup;
  public code_ex: FormControl;
  public amount: FormControl;
  public other_credit_explain: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'credits';
    this.section = 'lo';
  }

  createFormControls(item: Credit): void {
    this.code_ex = new FormControl(item.code_ex, AppValidators.present);
    this.amount = new FormControl(item.amount,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
    this.other_credit_explain = new FormControl(item.other_credit_explain);
  }

  createForm(): void {
    this.form = new FormGroup({
      code_ex: this.code_ex,
      amount: this.amount,
      other_credit_explain: this.other_credit_explain
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Credit(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Credit(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
