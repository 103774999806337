<div class="pr-font-large mb-5 mt-5 ms-3 me-3">
  <!--  What is PrudentCO? -->
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="row justify-content-md-center">
        <div class="col">
          <h2 class="art-blue" style="font-size: 1.7em;">What is PrudentCO?</h2>
          <p>
            PrudentCO was founded by Igal Megory-Cohen, a seasoned expert in residential mortgages, who identified the need for the home loan industry to enhance and expedite the digital mortgage process without compromising underwriting integrity and accuracy.
          </p>
          <p>
            As platform competitors pose a challenge to conventional mortgage brokers, lenders, and insurers, Igal recognized that a team of experienced innovators with deep knowledge of both mortgage and insurance can significantly contribute to their success and improve customer experience.
          </p>
          <p>
            Igal brought together a top-notch development team that shares his vision of integrating digital mortgage solutions, insurance, and real estate for the home of the future.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--  Our Team -->
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div>
        <h2 class="art-blue" style="font-size: 1.7em;">Our Team</h2>
      </div>

      <div style="height: auto;overflow: auto;">
        <h4 class="art-blue">Igal Megory-Cohen (CEO)</h4>
        <div>
          <div class="float-start m-3">
            <img src="assets/img/igal-portrait.jpg" style="height:125px">
          </div>

          <div>
            Igal obtained his Ph.D. in mathematics from the University of California, Berkeley, and has built a successful career as a software architect and technology leader. With extensive experience as a researcher and developer at IBM, as well as a software engineer for various internet and mobile companies, he dedicated himself to creating a product that meets the needs of his friends in the mortgage industry. His unwavering goal has been to simplify and enhance the home-buying experience for everyone involved. Igal is fluent in both Spanish and Hebrew and resides in Louisville, Colorado, with his wife and two children.
          </div>
        </div>
      </div>

      <div style="height: auto;overflow: auto;">
        <h4 class="art-blue">Anthony Johnson (CMO)</h4>
        <div>
          <div class="float-start m-3">
            <img src="assets/img/anthony-johnson.jpeg" style="height:125px">
          </div>
          <div>
            Anthony Johnson was the CEO and co-founder of Mortgage Education and Compliance (MEC) and is recognized for his extensive connections within the mortgage industry. He delivered NMLS-approved mortgage training and education across the country. He oversaw marketing, advertising, customer acquisition, and the sales department. Currently, Anthony is assisting PrudentCO in launching its initial market offerings.
          </div>
        </div>
      </div>

      <div style="height: auto;overflow: auto;">
        <h4 class="art-blue">Chris Burton (CTO)</h4>
        <div>
          <div class="float-start m-3">
            <img src="assets/img/chris_burton.png" style="height:125px">
          </div>
          <div>
            Chris is a seasoned startup professional with a wealth of experience across various problem areas and technologies, such as data mining and machine learning. He co-founded Termscout and was the inaugural engineering hire at several startups, including Trada, Nemerix, and ServiceMetrics. Beyond his software development skills, Chris is highly skilled in systems engineering and engineering operations, effectively managing cloud platforms like GCE and AWS, as well as overseeing CI/CD processes. Known for his collaborative nature and proactive approach, Chris is a leader who immerses himself in new domains and rapidly delivers effective solutions.
          </div>
        </div>
      </div>

      <div style="height: auto;overflow: auto;">
        <h4 class="art-blue">Jason Yaker (CPO/COO)</h4>
        <div>
          <div class="float-start m-3">
            <img src="assets/img/jason-yaker-portrait.png" style="height:125px">
          </div>
          <div>
            Jason significantly enhances PrudentCO's digital capabilities, having secured multiple #1 rankings in the Android and Apple app stores and receiving a digital Emmy nomination in 2018. He has successfully launched various companies and products for both Fortune 500 companies and startups. Beyond his responsibilities at PrudentCO, Jason serves as a mentor in the 1909 accelerator program and FAU TechRunway. A graduate of Florida Gulf Coast University, he currently resides in Boulder, Colorado.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
