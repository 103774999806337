import {ModelBase} from './model-base';

export class Details extends ModelBase {
  purchase_price: string;
  repairs: string;
  refinance: string;
  prepaid_items: string;
  closing_costs: string;
  funding_fee: string;
  discount: string;
  subordinate_financing: string;
  closing_costs_seller: string;
  funding_financed: string;
  from_borrower: boolean;
  cash_from_to_borrower: string;
  excessive_financing: string;
  non_property_debt_payoff: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'from_borrower') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = this.getNumberProp(prop, object);
      }
    }
  }

  toServerPayload(): any {
    return {
      cash_from_to_borrower: this.toServerDecimal(this.cash_from_to_borrower),
      from_borrower: ModelBase.toServerExplicitBoolean(this.from_borrower),
      purchase_price: this.toServerDecimal(this.purchase_price),
      repairs: this.toServerDecimal(this.repairs),
      refinance: this.toServerDecimal(this.refinance),
      prepaid_items: this.toServerDecimal(this.prepaid_items),
      closing_costs: this.toServerDecimal(this.closing_costs),
      funding_fee: this.toServerDecimal(this.funding_fee),
      discount: this.toServerDecimal(this.discount),
      subordinate_financing: this.toServerDecimal(this.subordinate_financing),
      closing_costs_seller: this.toServerDecimal(this.closing_costs_seller),
      funding_financed: this.toServerDecimal(this.funding_financed),
      excessive_financing: this.toServerDecimal(this.excessive_financing),
      non_property_debt_payoff: this.toServerDecimal(this.non_property_debt_payoff),
    };
  }
}
