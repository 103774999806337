import {Component, OnInit} from '@angular/core';
import {DeviceService} from '../../services/device.service';
import {Router} from '@angular/router';
import {globals} from '../../shared/globals/globals';

@Component({
  selector: 'app-horizontal-navbar-site',
  templateUrl: 'horizontal-navbar.component.html',
  styleUrls: ['horizontal-navbar.component.scss'],
})
export class HorizontalNavbarSiteComponent implements OnInit {
  isMobile: boolean;
  authServerSignInUrl: string;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.isMobile = DeviceService.isMobile;
    this.authServerSignInUrl = `${globals.getAuthServer()}/auth/signin_with_device`;
  }

  navigateTo(event, path): void {
    event.preventDefault();
    this.router.navigate([path]);
  }
}
