<mat-card *ngIf="reloaded">
  <div class="row mb-3 mt-3">
    <div class="col">
      <form [formGroup]="form" novalidate>
        <mat-radio-group (change)="onChange($event)" aria-label="bankrupt_label" formControlName="resolved">
          <mat-radio-button [checked]="resolved.value===1" [value]=1>Unresolved</mat-radio-button>
          <mat-radio-button [checked]="resolved.value===2" [value]=2>Resolved</mat-radio-button>
          <mat-radio-button [checked]="resolved.value===3" [value]=3>All</mat-radio-button>
        </mat-radio-group>
      </form>
    </div>
  </div>

  <mat-card-title *ngIf="sysAlerts.length === 0">
    No Alerts
  </mat-card-title>

  <mat-card-title *ngIf="sysAlerts.length > 0" class="text-danger">
    Current Alerts
  </mat-card-title>

  <mat-card-content *ngIf="sysAlerts.length > 0">
    <div *ngIf="sysAlerts.length > 0">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="w-15">ID</th>
            <th class="w-15">Resource</th>
            <th class="w-15">Code</th>
            <th class="w-15">Count</th>
            <th class="w-15">memo</th>
            <th class="w-15">Created at</th>
            <th class="w-10">Resolved</th>
            <th class="w-10">Op</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sysAlert of sysAlerts" class="hand-pointer">
            <td>{{sysAlert.id}}</td>
            <td>{{sysAlert.getResourceValue(sysAlert.resource)}}</td>
            <td>{{sysAlert.getCodeValue(sysAlert.code)}}</td>
            <td>{{sysAlert.count}}</td>
            <td>{{sysAlert.memo}}</td>
            <td>{{sysAlert.created_at | date: 'medium'}}</td>
            <td>{{sysAlert.resolved | booleanToString}}</td>
            <td>
              <button (click)="resolve($event,sysAlert)" *ngIf="!sysAlert.resolved" class="btn btn-sm btn-primary">Resolve</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
