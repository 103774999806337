<div *ngIf="reloaded">
  <div class="user-info">
    <div class="user-avatar">
      <img alt="User thumbnail" [src]="menuImageSrc">
      <div class="badge"></div>
    </div>
    <h5 class="user-name h6">{{menuImageName}}</h5>
  </div>

  <nav class="main-menu mb-5">
    <ul>
      <li
        *ngFor="let item of menuItems"
        [ngClass]="getMenuLiClasses(item)"
        class="menu-item"
        routerLinkActive="active"
      >
        <div *ngIf="item.groupTitle; then group else default"></div>

        <!-- Group title -->
        <ng-template #group>
          <span class="group-title">{{ item.title }}</span>
        </ng-template>
        <!-- end Group title -->

        <!-- Default item -->
        <ng-template #default>
          <div *ngIf="!item.sub; then simple else sub"></div>

          <ng-template #simple>
            <a (click)="doNothing($event)" class="item-link" href="#" routerLink="{{ item.routing }}">
              <span class="text">{{ item.title }}</span>

              <span
                class="item-badge"
                *ngIf="item.badge && item.badge.text"
                [ngStyle]="getStyles(item.badge)"
              >{{ item.badge.text }}</span>

              <span class="icon">
              <i *ngIf="item.icon && item.icon.class" [ngStyle]="getStyles(item.icon)" class="icon-wrap {{ item.icon.class }}"></i>
            </span>
            </a>
          </ng-template>

          <ng-template #sub>
            <!-- Item with sub -->
            <div href="#" class="hand-pointer item-link" (click)="groupMenuClicked($event, item)">
              <span class="text">{{ item.title }}</span>

              <span
                class="item-badge"
                *ngIf="item.badge && item.badge.text"
                [ngStyle]="getStyles(item.badge)"
              >{{ item.badge.text }}</span>

              <span *ngIf="item.sub" class="caret"><i class="fa fa-angle-right"></i></span>

              <span class="icon">
              <i *ngIf="item.icon && item.icon.class" [ngStyle]="getStyles(item.icon)" class="icon-wrap {{ item.icon.class }}"></i>
            </span>
            </div>

            <!-- Menu sub -->
            <ul class="sub" *ngIf="!item.groupTitle && item.sub">
              <li
                class="menu-item"
                *ngFor="let itemSub of item.sub"
                [hidden]="itemSub.hidden"
                [ngClass]="getSubMenuLiClasses(itemSub, rla.isActive)"
                #rla="routerLinkActive"
                routerLinkActive="active"
              >
                <a class="item-link" href="#" routerLink="{{ itemSub.routing }}" (click)="navigateTo($event,itemSub.routing)">
                  <span class="text">{{ itemSub.title }}</span>
                </a>
              </li>
            </ul>
          </ng-template>
        </ng-template>
      </li>
    </ul>
  </nav>
</div>
