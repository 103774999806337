<div class="row h-100">
  <div class="col-lg-4 mx-auto">
    <app-card [align]="'center'" [title]="'Watch POLOS Demo'" [hidden]="canVideo">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <mat-form-field class="md-icon-left w-100">
          <input #userElt formControlName="full_name" matInput placeholder="Name" maxlength="70">
          <mat-error *ngIf="full_name.hasError('required')">
            Name is required
          </mat-error>
          <mat-error *ngIf="full_name.hasError('minlength')">
            Name is too short
          </mat-error>
        </mat-form-field>

        <mat-form-field class="md-icon-left w-100">
          <input #userElt formControlName="email_address" matInput placeholder="Email address" maxlength="320">
          <mat-error *ngIf="email_address.hasError('required')">
            Email address is required
          </mat-error>
          <mat-error *ngIf="email_address.hasError('pattern')">
            Email address is invalid
          </mat-error>
        </mat-form-field>

        <button [disabled]="submitted&&!form.valid" class="btn btn-lg btn-primary" type="submit">Watch Demo</button>
      </form>
    </app-card>

    <div class="text-center mt-1" *ngIf="canVideo">
      <a class="logo hand-pointer" aria-label="Logo Image" href="/">
        <img alt="Logo" class="logo-img" [src]="logoImageSrc"
          style="height: 70px;width:auto;margin-left:auto;margin-right:auto;padding-top: 10px;padding-bottom: 10px;">
      </a>

      <div>
        <mat-card-title>A Quick Demo of POLOS</mat-card-title>
      </div>

      <div>
        <iframe width="785" height="442"
          src="https://www.youtube.com/embed/43H9R8xDO7w" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</div>
