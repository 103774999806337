import {Component, Injector, Input, OnInit} from '@angular/core';
import {Asset} from '../../../../models/asset';
import {AssetComponent} from '../asset/asset.component';

@Component({
  selector: 'app-view-segment-asset',
  templateUrl: './view-segment-asset.component.html',

})
export class ViewSegmentAssetComponent extends AssetComponent implements OnInit {
  @Input() item: Asset;
  partyIds: Array<any>;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.setPartyIds();
  }
}
