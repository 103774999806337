<mat-card *ngIf="reloaded&&isContacts">
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <p class="mb-3 mt-3">
        Select recipients:
      </p>

      <h5>All Contacts</h5>

      <div *ngFor="let contact of contacts; let i = index" formArrayName="contacts">
        <mat-checkbox [formControlName]="i">
          <strong>{{contact.full_name}}</strong>
          <span [hidden]="!contact.is_text_messaging" class="text-muted">&nbsp;(SMS OK)</span>
        </mat-checkbox>
      </div>

      <h5>Current Loan Contacts</h5>

      <div *ngFor="let contact of tranContacts; let i = index" formArrayName="tranContacts">
        <mat-checkbox [formControlName]="i">
          <strong>{{contact.full_name}}</strong>
          <span [hidden]="!contact.is_text_messaging" class="text-muted">&nbsp;(SMS OK)</span>
        </mat-checkbox>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Message text</mat-label>
            <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
              cdkTextareaAutosize
              formControlName="message_text"
              matInput
              placeholder="Message text" required>

            </textarea>
            <mat-error *ngIf="message_text.hasError('required')">
              Message text is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="!canSMS() || message_text.value.length===0" class="mb-3">
        <p>
          <strong>Text Message</strong>
          <small>&nbsp;(limited to {{smsMessageLimit}} characters)</small>
        </p>
        <p class="message-text-box">
          {{message_text.value | slice:0:smsMessageLimit}}
        </p>
      </div>

      <div [hidden]="!canSMS()" class="">
        <mat-checkbox formControlName="is_sms">&nbsp;Send a text message (SMS)?</mat-checkbox>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="sendMessage()" [disabled]="!canSend()" class="btn btn-lg btn-primary" type="submit">Send</button>
          <button (click)="reset()" class="ms-2 btn btn-lg btn-light">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded&&!isContacts">
  <mat-card-title>New Message</mat-card-title>

  <app-box *ngIf="reloaded&&!isContacts" [close]="false" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No contacts!</span>
    You can add contacts at
    <a class="pr-blue-bold" routerLink="/account/view-contacts">Account/Contacts</a>.
  </app-box>
</mat-card>
