import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {OrganizationService} from '../../../../services/organization.service';
import {OrgTran} from '../../../../models/org-tran';
import {Organization} from '../../../../models/organization';
import {User} from '../../../../models/user';
import {FormUtil} from '../../../form-util';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-org-users',
  templateUrl: './view-org-users.component.html',
})
export class ViewOrgUsersComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public orgService: OrganizationService;
  public trans: Array<OrgTran>;
  public pageTitle = 'Organization';
  public userOrg: Organization;
  public user: User;

  constructor(public injector: Injector,
              public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.orgService = this.injector.get(OrganizationService);
    this.reload(false);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(true);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.mode = 'View';
    this.reloaded = false;
    this.user = this.userService.getUserSync();
    const orgId = this.user.organization_id;
    if (!orgId) {
      this.userOrg = null;
      setTimeout(() => {
        this.reloaded = true;
      }, 0);
      return;
    }
    this.userService.getDefaultOrganization(force)
      .then((org) => {
        this.userOrg = org;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to get organization', data);
      });
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountOrg
      });
    }, 0);
  }

  editUser(event: any, user: User) {
    this.user = user;
    this.mode = 'EditUser';
  }
}
