<div *ngIf="reloaded">
  <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'Conditions'" [help]="true" [op]="'add'" [isCard]="false">
    <p>
      The purpose of Conditions is to record loan conditions that the loan officer and processor need to resolve.
      Conditions may originate from several sources including any Automatic Underwriting System (AUS), for
      example Fannie Mae's Desktop Originator (DO), or from the lender's underwriters.
    </p>
    <ul [ngClass]="{'is-mobile':isMobile}" class="help">
      <li>It is up to you to come up with the list of loan conditions.</li>
      <li>Please note that any condition that requires a document can be uploaded to
        <a class="pr-dark-blue fw-bold" routerLink="/loan/view-directory" aria-label="Account Settings">Current Loan/Repository</a>.
      </li>
      <li>You can edit or delete conditions.</li>
    </ul>
  </app-segment-header>
  <app-box [hidden]="items.length" [close]="true" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No conditions!</span>
  </app-box>

  <div *ngFor="let item of items">
    <app-view-segment-condition (eventEmitted)="onEvent($event)" [item]="item"></app-view-segment-condition>
  </div>
</div>
