<mat-card *ngIf="reloaded" class="mt-1 mb-2">
  <mat-card-title>Create a Loan Application
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Loan application name</mat-label>
            <input #nameElt formControlName="name" matInput placeholder="Loan application name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="name.hasError('required')">
              Loan application name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Loan application name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="">
        <mat-checkbox formControlName="is_default">&nbsp;Default loan application</mat-checkbox>
        <mat-hint class="pr-checkbox-hint">
          <br/>The default loan application is used for all loan computations and document generation
        </mat-hint>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="add()" class="btn btn-lg btn-primary" [disabled]="!form.valid">Create</button>
          <button (click)="reset()" class="ms-2 btn btn-lg btn-light">Reset</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
