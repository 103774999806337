<div class="row h-100 mt-5">
  <div class="col-lg-4 mx-auto">
    <mat-card class="mt-1 mb-1">
      <mat-card-title>Compute Amortization Table</mat-card-title>
      <app-box *ngIf="error" class="mt-3" [type]="'danger'" [close]="true">
        This scenario is invalid!
      </app-box>

      <mat-card-content>
        <form [formGroup]="form" novalidate>
          <div class="row">
            <div class="col">
              <mat-form-field class="md-icon-left w-100">
                <mat-label>Loan amount</mat-label>
                <input appDecimalOnly #loanAmountElt formControlName="loanAmount" matInput placeholder="Loan amount" required>
                <mat-error *ngIf="loanAmount.hasError('required')">
                  Loan amount is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field class="md-icon-left w-100">
                <mat-label>Annual interest rate (percent)</mat-label>
                <input appDecimalThreeOnly formControlName="annualInterestRate" matInput placeholder="Annual interest rate (percent)" required>
                <mat-error *ngIf="annualInterestRate.hasError('required')">
                  Annual interest rate is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field class="md-icon-left w-100">
                <mat-label>Number of months</mat-label>
                <input appNumberOnly formControlName="numberMonths" matInput placeholder="Number of months" required>
                <mat-error *ngIf="numberMonths.hasError('required')">
                  Number of months is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <button (click)="computeAmortizationTable()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Compute</button>
              <button (click)="clear()" class="ms-2 btn btn-lg btn-light">Clear</button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
