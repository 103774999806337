import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../../models/document';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from "../../../form-util";
import {SboxService} from "../../../../services/sbox.service";

@Component({
  selector: 'app-view-secured-document',
  templateUrl: './view-secured-document.component.html',
})
export class ViewSecuredDocumentComponent extends CompBaseComponent implements OnInit {
  @Input() doc: Document;
  public fullImage = false;
  public urlSafe: SafeResourceUrl;
  public downloading = false;
  public sboxService: SboxService;

  constructor(public injector: Injector,
              public sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.sboxService = this.injector.get(SboxService);
    this.mode = 'View';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('api/documents/' + this.doc.id);
  }

  toggleImage(): void {
    this.fullImage = !this.fullImage;
  }

  downloadDoc(event?:any): void {
    if (event) {
      event.preventDefault();
    }
    this.downloading = false;
    setTimeout(() => {
      this.downloading = true;
    }, 0);
  }

  deleteDoc(event: any, docId: number) {
    event.preventDefault();

    this.sboxService.deleteAnonDoc(docId)
      .then((rc) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Document deleted');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete document', null);
      });
  }
}
