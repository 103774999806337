<div *ngIf="reloaded" class="row">
  <app-box *ngIf="!currentTran" [close]="true" [type]="'warning'" class="pt-2">
    Cannot send emails because there is <b>no current loan!</b>
  </app-box>
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="pt-4">
      <li [ngClass]="{active:currentSeg===seg.newUser}">
        <span></span>
        <a (click)="setSeg($event,seg.newUser)" href="" id="new_user">Invite User</a>
      </li>

      <li *ngIf="!!currentTran && canCreateTransaction" [ngClass]="{active:currentSeg===seg.newEmail}">
        <span></span>
        <a (click)="setSeg($event,seg.newEmail)" href="" id="new_email">Orientation Email</a>
      </li>

<!--      <li *ngIf="hasEzUploadsService" [ngClass]="{active:currentSeg===seg.uploadPDF}">-->
<!--        <span></span>-->
<!--        <a (click)="setSeg($event,seg.uploadPDF)" href="" id="upload_pdf">ezUploads Request</a>-->
<!--      </li>-->
    </ul>
  </div>

  <div *ngIf="!(isMobile&&isOpen)" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-send-invite-email (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.newUser">
      </app-send-invite-email>
      <app-send-orientation-email (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.newEmail">
      </app-send-orientation-email>
      <app-send-upload-email (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.uploadPDF">
      </app-send-upload-email>
    </div>
  </div>
</div>
