<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Gift type</mat-label>
            <mat-select formControlName="gift_type" placeholder="Select gift type" required>
              <mat-option *ngFor="let gift_type of gift_types" [value]="gift_type.key">{{gift_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="gift_type.hasError('required')">
              Gift type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Gift source</mat-label>
            <mat-select formControlName="gift_source" placeholder="Select gift source" required>
              <mat-option *ngFor="let gift_source of gift_sources" [value]="gift_source.key">{{gift_source.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="gift_source.hasError('required')">
              Gift source is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="gift_source.value==='Other'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other gift description</mat-label>
            <input type="text" formControlName="other_description" matInput placeholder="Describe other gift" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="other_description.hasError('required')">
              Other gift description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Market value</mat-label>
            <input appDecimalOnly formControlName="market_value" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Market value" required>
            <mat-error *ngIf="market_value.hasError('required')">
              Market value is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pt-1 pb-1 hover-gray">
        <div class="col-lg-4 my-auto">
          <label id="deposited">
            Deposited/Not deposited
          </label>
        </div>

        <div class="col-lg-8 text-start">
          <mat-radio-group aria-label="deposited_label" formControlName="deposited">
            <mat-radio-button [checked]="deposited.value===true" [value]="true">Deposited</mat-radio-button>
            <mat-radio-button [checked]="deposited.value===false" [value]="false">Not deposited</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
