<mat-card *ngIf="reloaded">
  <mat-card-title>{{op==='Add' ? 'Add Condition' : 'Edit Condition'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput placeholder="Name" appAutoFocus required maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="description" matInput placeholder="Description" maxlength="{{MAX_LEN.L_DESC}}">
            <mat-label>Description</mat-label>
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Comments</mat-label>
            <textarea cdkAutosizeMaxRows="10" cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="comments"
              matInput
              placeholder="Comments" maxlength="{{MAX_LEN.VL_DESC}}">
            </textarea>
            <mat-error *ngIf="comments.hasError('minlength')">
              Text is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-1 hover-gray mb-1">
        <div class="col-lg-2 fw-bold my-auto">
          <label id="example-radio-group-label" style="position:relative;top:3px">Completed?</label>
        </div>
        <div class="col-lg-4 text-end">
          <mat-radio-group formControlName="done" aria-label="done">
            <mat-radio-button [checked]="done.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="done.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary">Add</button>
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
