import {Component, Injector, OnInit} from '@angular/core';
import {Income} from '../../../../models/income';
import {IncomeComponent} from '../income/income.component';

@Component({
  selector: 'app-add-income',
  templateUrl: '../income/edit-segment-income.component.html',
})
export class AddIncomeComponent extends IncomeComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Income({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
