import {ModelBase} from './model-base';

export class NewMortgage extends ModelBase {
  index: number;
  creditor_name: string;
  first_lien: boolean;
  loan_amount: string;
  monthly_payment: string;
  credit_limit: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'loan_amount' || prop === 'monthly_payment' || prop === 'credit_limit') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'first_lien') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<NewMortgage> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new NewMortgage(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      creditor_name: this.creditor_name,
      first_lien: ModelBase.toServerExplicitBoolean(this.first_lien),
      loan_amount: this.toServerDecimal(this.loan_amount),
      monthly_payment: this.toServerDecimal(this.monthly_payment),
      credit_limit: this.toServerDecimal(this.credit_limit),
    };
  }
}
