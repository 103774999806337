import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Condition} from '../models/condition';
import {CacheService} from './cache.service';

@Injectable()
export class ConditionService {

  constructor(private httpService: HttpService, private cacheService: CacheService) {
    this.cacheService.resetSeg('condition');
  }

  getTranConditions(force, tranId): Promise<Array<Condition>> {
    if (!force && this.cacheService.getSeg('condition', 'tranId') === tranId && this.getConditions()) {
      return Promise.resolve(this.getConditions());
    }

    this.cacheService.setSeg('condition', 'tranId', tranId);
    return new Promise((resolve, reject) => {
      this.httpService.get(`conditions/${tranId}`)
        .then((data) => {
          this.cacheService.setSeg('condition', 'conditions', Condition.deserializeArray(data));
          resolve(this.getConditions());
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createCondition(tranId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`conditions/${tranId}`, payload)
        .then((data) => {
          this.cacheService.resetSeg('condition');
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public getConditionById(conditionId: number): Promise<Condition> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`conditions/get_condition_by_id/${conditionId}`)
        .then((data) => {
          return resolve(new Condition(data));
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateCondition(conditionId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`conditions/${conditionId}`, payload)
        .then((data) => {
          this.cacheService.resetSeg('condition');
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public deleteCondition(conditionId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.delete(`conditions/${conditionId}`)
        .then((data) => {
          this.cacheService.resetSeg('condition');
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public getConditions(): Array<any> {
    return this.cacheService.getSeg('condition', 'conditions');
  }
}
