import {ModelBase} from './model-base';

export class Trace extends ModelBase {
  public id: number;
  public event: string;
  public event_description: string;
  public state: string;
  public data_in: string;
  public data: string;
  public data_out: string;
  public created_at: Date;
  public updated_at: Date;
  public from_state: string;
  public deleted: boolean;
  public hidden: boolean;
  public done: boolean;
  public state_display: string;
  public started_at: Date;
  public ended_at: Date;
  public comp_events: Array<number>;
  public color: string;

  constructor(object: any) {
    super();
    if (!object) {
      return;
    }
    for (const prop of Object.keys(object)) {
      if (prop === 'started_at' || prop === 'ended_at' || prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else if (prop === 'hidden') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Trace> {
    const result = [];
    for (const obj of arr) {
      const item = new Trace(obj);
      result.push(item);
    }

    return result;
  }
}
