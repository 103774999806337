<mat-card class="mt-2 mb-1" *ngIf="reloaded">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="isMobile ? 'Agreement' : 'Agreement & Signature'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <strong>Signature date:</strong> This is the date when the borrower signed the loan application.
        </li>
        <li>
          <span class="fw-bold">FYI.</span> The Loan Estimate (LE) is due within three business days of the agreement date.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <app-box [close]="true" *ngIf="signatureInFuture" [type]="'warning'" [fadeOut]="false">
      The signature date is in the future!
    </app-box>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">
        <strong>Signature date</strong>
      </div>
      <div class="col-lg-6">{{item.signature_date | date}}</div>
    </div>
  </mat-card-content>
</mat-card>
