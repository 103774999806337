<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Loan Product'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Loan Product identifies Fannie Mae loan product selected.</li>
        <li>
          <span class="fw-bold">Product description.</span>
          Description of the product which has been selected for this loan.
          Enter Standard LCOR in the Product description field to underwrite a DU Refi Plus loan casefile
          as a standard limited cash-out refinance.
        </li>
        <li>
          <span class="fw-bold">Product plan number.</span>
          An identifying number that identifies the varying loan payment/rate change characteristics of variable rate mortgages.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6 fw-bold">Product description</div>
      <div class="col-lg-6">{{item.product_description}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6 fw-bold">Product plan number</div>
      <div class="col-lg-6">{{item.getProductPlanNumber()}}</div>
    </div>
  </mat-card-content>
</mat-card>
