import {Component, Injector, Input, OnInit} from '@angular/core';
import {Declarations} from '../../../../models/declarations';
import {DeclarationExplanation} from '../../../../models/declaration-explanation';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-declarations',
  templateUrl: './view-segment-declarations.component.html',

})
export class ViewSegmentDeclarationsComponent extends CompBaseComponent implements OnInit {
  @Input() item: Declarations;
  @Input() public explanations: Array<DeclarationExplanation>;
  public declaration_text = Declarations.declaration_text;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'declaration';
    this.section = this.applicant;
  }

  getExplanation(val: string): string {
    const isExplanationRequired = !!this.item[val];
    const found = this.explanations.find((elt) => elt.declarationTypeValue === val);
    if (isExplanationRequired) {
      if (found && found.declaration_explanation && found.declaration_explanation.length) {
        return found.declaration_explanation;
      }
      return 'Declaration explanation is missing! Refer to Explanations form.';
    }
    return null;
  }

  // todo: not used
  isExplanationMissing(val: string): boolean {
    if (!this.item[val]) {
      return false;
    }
    const found = this.explanations.find((elt) => elt.declarationTypeValue === val);
    return !!found && (!found.declaration_explanation || !found.declaration_explanation.length);
  }
}
