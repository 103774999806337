<mat-card *ngIf="reloaded" class="mt-1 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'View User'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-lg-6" style="padding-right: 50px;">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">ID</div>
          <div class="col-lg-8">{{user.id}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Prefix</div>
          <div class="col-lg-8">{{user.prefix}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">First name</div>
          <div class="col-lg-8">{{user.first}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Middle name</div>
          <div class="col-lg-8">{{user.middle}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Last name</div>
          <div class="col-lg-8">{{user.last}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Suffix</div>
          <div class="col-lg-8">{{user.suffix}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Org ID</div>
          <div class="col-lg-8">{{user.organization_id}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Org Role</div>
          <div class="col-lg-8">{{user.getOrgRoleString()}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Email</div>
          <div class="col-lg-8">{{user.email}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Phone</div>
          <div class="col-lg-8">{{user.cell_phone}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Carrier</div>
          <div class="col-lg-8">{{user.mobileCarrier}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Roles</div>
          <div class="col-lg-8">{{user.getRolesString()}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Services</div>
          <div class="col-lg-8">{{user.getServicesString()}}</div>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="userOrg">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold">USER ORGANIZATION</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Name</div>
          <div class="col-lg-8">{{userOrg.name}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Description</div>
          <div class="col-lg-8">{{userOrg.description}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Company NMLS</div>
          <div class="col-lg-8">{{userOrg.nmls}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Admin name</div>
          <div class="col-lg-8">{{userOrg.admin ? userOrg.admin.full_name : ''}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Admin email</div>
          <div class="col-lg-8">{{userOrg.admin ? userOrg.admin.email : ''}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Admin phone</div>
          <div class="col-lg-8">{{userOrg.admin ? (userOrg.admin.cell_phone | phone) : ''}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">User count</div>
          <div class="col-lg-8">{{userOrg.users.length}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Created</div>
          <div class="col-lg-8">{{userOrg.created_at | date:'MM/dd/yyyy'}}</div>
        </div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Last updated</div>
          <div class="col-lg-8">{{userOrg.updated_at | date:'MM/dd/yyyy'}}</div>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="!userOrg">
        <div class="row pt-1 pb-1">
          <div class="col fw-bold">NO USER ORGANIZATION</div>
        </div>
      </div>
    </div>

    <div *ngIf="config">
      <h3>User Configuration</h3>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Address</div>
        <div class="col-lg-8">{{config.getFullAddress()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">NMLS</div>
        <div class="col-lg-8">{{config.nmls}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Company NMLS</div>
        <div class="col-lg-8">{{config.company_nmls}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Licenses</div>
        <div class="col-lg-8">{{config.getStates()}}</div>
      </div>
    </div>

    <div *ngIf="user.user_account">
      <h3>User Account</h3>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">User Account ID</div>
        <div class="col-lg-8">{{user.user_account.id}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Status</div>
        <div class="col-lg-8">{{user.user_account.getAccountStatus()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Expires on</div>
        <div class="col-lg-8">{{user.user_account.expires_on}}</div>
      </div>
    </div>

    <div *ngIf="!user.user_account">
      <h3>No User Account</h3>
    </div>

    <div *ngIf="!config">
      <h3>No User Configuration</h3>
    </div>

    <h3>Manage User</h3>

    <div>
      <h5>Organization Admin Role</h5>
      <div>
        Make this user their organization's Admin.
      </div>
      <div>
        <button (click)="setOrgAdmin()" class="btn btn-lg btn-primary" type="submit">Set Org Admin</button>
      </div>

      <h5>Remove User From Their Organization</h5>
      <div>
        Remove user from their organization.
      </div>
      <div>
        <button (click)="removeFromOrg()" class="btn btn-lg btn-primary" type="submit">Remove From Org</button>
      </div>

      <h5>Add User To Organization</h5>
      <div>
        Add user to the specified organization ID.
        <form [formGroup]="form" novalidate>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="md-icon-left w-100">
                <input formControlName="organization_id" matInput placeholder="Organization ID">
                <mat-error *ngIf="organization_id.hasError('required')">
                  Organization ID is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <button (click)="addToOrg()" class="btn btn-lg btn-primary" type="submit">Add To Org</button>
      </div>

      <h5>Hard Reset User Password</h5>
      <div>
        Hard reset user password.
      </div>
      <div>
        <button (click)="navigateToResetPw()" class="btn btn-lg btn-primary" type="submit">Navigate to Reset Password</button>
      </div>

      <h5>User Orders</h5>
      <div>
        Navigate to user orders
      </div>
      <div>
        <button (click)="navigateToUserOrders()" class="btn btn-lg btn-primary" type="submit">Navigate to User Orders</button>
      </div>

      <h5>Create Invoice</h5>
      <div>
        Create a new invoice.
      </div>
      <div>
        <button (click)="navigateToNewInvoice()" class="btn btn-lg btn-primary" type="submit">Navigate to New Invoice</button>
      </div>
    </div>

  </mat-card-content>
</mat-card>
