<mat-card *ngIf="reloaded">
  <mat-card-title>
    <div class="clearfix">
      <div class="float-left">
        My Loans
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 fw-bold border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">ID</div>
      <div class="col col-lg-1">Status</div>
      <div class="col col-lg-6">Name
        <span [hidden]="isMobile" class="text-muted">(Description)</span>
      </div>
      <div [hidden]="isMobile" class="col col-lg-2">Created</div>
      <div class="col col-lg-2">Modified</div>
    </div>

    <div (click)="navigateToTran(tran)" *ngFor="let tran of filteredTrans" class="hand-pointer row pt-1 pb-1 hover-border border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">{{tran.id}}</div>
      <div class="col col-lg-1">
        <span [ngClass]="{'badge-primary': tran.status===0,'badge-secondary': tran.status===1,'badge-warning': tran.status===2}" class="badge">
            {{tran.getStatusString()}}
        </span>
      </div>
      <div class="col col-lg-6">{{tran.name}}
        <span [hidden]="isMobile || !tran.description||tran.description.length===0" class="text-muted">({{tran.description}})</span>
      </div>
      <div [hidden]="isMobile" class="col col-lg-2">{{tran.created_at | date:'MM/dd/yyyy'}}</div>
      <div class="col col-lg-2">{{tran.updated_at | date:'MM/dd/yyyy'}}</div>
    </div>
  </mat-card-content>
</mat-card>
