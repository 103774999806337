<mat-card class="mb-1">
  <mat-card-title [ngClass]="{'text-danger': !trace.done}">
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateTransaction"
      [ngClass]="{'text-danger':trace.color==='red',
                        'text-success':trace.color==='green',
                        'text-warning':trace.color==='yellow',
                        'text-dark':trace.color==='dark'}"
      [header]="modifiedTitle" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Task</div>
      <div class="col-lg-8">{{trace.state_display}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Causing event</div>
      <div class="col-lg-8">{{trace.event_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Started at</div>
      <div class="col-lg-8">{{trace.started_at | date:'short'}}</div>
    </div>

    <div *ngIf="trace.state!=='end' && trace.state!=='start'">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Completed at</div>
        <div class="col-lg-8">{{trace.ended_at | date:'short'}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">To do</div>
        <div [innerHTML]="trace.data_in | nl2br" class="col-lg-8"></div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Status update</div>
        <div [innerHTML]="trace.data | nl2br" class="col-lg-8"></div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Summary</div>
        <div [innerHTML]="trace.data_out | nl2br" class="col-lg-8"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
