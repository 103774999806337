import {Component, Injector, OnInit} from '@angular/core';
import {Party} from '../../../../models/party';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-parties',
  templateUrl: './view-segment-parties.component.html',
})
export class ViewSegmentPartiesComponent extends CompBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.items = Party.deserializeArray(this.doc.recursive_attributes.parties);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
