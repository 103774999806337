<app-orientation-helper (orientationErrorEmitted)="onOrientationError($event)" [componentName]="'Calendar'">
</app-orientation-helper>

<div *ngIf="!orientationError">
  <div class="row  mt-2">
    <div class="col-lg-2 text-start">
      <button title="Update tasks" (click)="update()" class="btn btn-primary" type="button">Update</button>
    </div>
    <div class="col-lg-8 text-center">
      <button title="First task" (click)="firstTaskMonth()" class="ms-1 btn btn-primary">
        <i class="fas fa-angle-double-left"></i>
      </button>
      <button title="Previous month" (click)="previousMonth()" class="ms-1 btn btn-primary">
        <i class="fa fa-chevron-left"></i>
      </button>
      <h5 class="d-inline" style="position: relative;top:3px;">&nbsp;{{viewDate | date:'MMMM yyyy'}}&nbsp;</h5>
      <button title="Next month" (click)="nextMonth()" class="ms-1 btn btn-primary">
        <i class="fa fa-chevron-right"></i>
      </button>
      <button title="Last task" (click)="lastTaskMonth()" class="ms-1 btn btn-primary">
        <i class="fas fa-angle-double-right"></i>
      </button>
    </div>
    <div class="col-lg-2 text-end">
      <button title="Today" (click)="today()" class="btn btn-primary">Today</button>
    </div>
  </div>

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div *ngIf="day.events.length">
      <div *ngFor="let ev of day.events | orderBy:'title'" class="cal-cell-top" style="min-height: initial">
        <span *ngIf="day.badgeTotal > 0" [dropData]="{event:ev, draggedFrom:day}"
          [ngClass]="{'bg-dark':ev.meta.color==='dark',
              'bg-danger':ev.meta.color==='red',
              'bg-success':ev.meta.color==='green',
              'bg-warning':ev.meta.color==='yellow'}"
          class="cal-day-badge" mwlDraggable>{{ev.title}}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
    </div>
    <div *ngIf="day.events.length === 0" class="cal-cell-top">
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
  </ng-template>

  <mwl-calendar-month-view
    (eventTimesChanged)="eventTimesChanged($event)"
    [activeDayIsOpen]="false"
    [cellTemplate]="customCellTemplate"
    [events]="events"
    [refresh]="refresh"
    [viewDate]="viewDate"
  >
  </mwl-calendar-month-view>
</div>
