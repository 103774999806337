import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {AppValidators} from "../../../../../shared/validators/app-validators.validator";
import {AnalyzerService} from "../../../../../services/analyzer.service";
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-generate-urla',
  templateUrl: './generate-urla.component.html',
})
export class GenerateURLAComponent extends GenerateDocumentBaseComponent implements OnInit {
  public isDefaultLoanApp: boolean;
  public borrowerCount: number;
  public doc: Document;
  public generating: boolean;
  public applicants: Array<any>;
  public formTypeTableAll = Document.getURLAFormTypeTable();
  public formTypeTable: any[];
  @Input() folderId: string;
  // form and form controls
  public form: FormGroup;
  public who: FormControl;
  public form_type: FormControl;
  public page_number: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.applicants = this.analyzerService.getBorrowerTable(this.doc.recursive_attributes);
    this.createFormControls();
    this.createForm();
    this.reload();
  }

  createFormControls(): void {
    this.who = new FormControl(null);
    this.form_type = new FormControl(null);
    this.page_number = new FormControl(1, Validators.compose([AppValidators.present, Validators.min(1), Validators.max(10)]));
  }

  createForm(): void {
    this.form = new FormGroup({
      who: this.who,
      form_type: this.form_type,
      page_number: this.page_number,
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.doc = this.tran.getDefaultLoanApp();
    this.isDefaultLoanApp = !!this.doc;
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    if (this.borrowerCount === 1) {
      this.who.setValue('b1');
      this.setFormTypeTable('b1');
    }
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  onChangeWho(): void {
    const who = this.who.value;
    this.setFormTypeTable(who);
  }

  setFormTypeTable(who: string): void {
    const isJoint = AnalyzerService.isJointLoanApp(this.doc.recursive_attributes);
    if (who == 'b1') {
      this.formTypeTable = this.formTypeTableAll.filter((elt) => elt.key !== 'additional')
    } else {
      if (isJoint) {
        this.formTypeTable = this.formTypeTableAll.filter((elt) => elt.key !== 'borrower')
      } else {
        this.formTypeTable = this.formTypeTableAll.filter((elt) => elt.key !== 'additional')
      }
    }
  }

  genURLA(event): void {
    event.preventDefault();
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.value;
    formData.icn_id = this.folderId;
    this.generating = true;
    const payload =
      {
        'icn_id': null,
        'borrower_groups': [[this.who.value]],
        'form_types': ["URLA","ADDL","CONT","UMAR"]
      }
    this.docService.generateMultipleDocs(this.doc.id, payload)
      .then(() => {
        const message = this.folderId ? 'Document successfully generated' : 'Document successfully generated and moved to Unsorted Documents folder';
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.router.navigate(['/loan/view-directory']);
        this.generating = false;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate document', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
        this.generating = false;
      });
  }

  cancel() {
    this.eventEmitted.emit({type: 'Reload', force: false});
  }
}
