import {Component, Injector, OnInit} from '@angular/core';
import {RaceComponent} from '../race/race.component';

@Component({
  selector: 'app-edit-segment-race',
  templateUrl: '../race/edit-segment-race.component.html',
})
export class EditSegmentRaceComponent extends RaceComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
