import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import moment from 'moment';
import {Help} from '../../../models/help';
import {CompBaseComponent} from '../../comp-base.component';
import {CompEvent} from '../../../models/comp-event';
import {Document} from '../../../models/document';

@Component({
  selector: 'app-view-dashboard',
  templateUrl: './view-dashboard.component.html',
})
export class ViewDashboardComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle: string;
  public tran: Tran;
  public tranId: number;
  public txSummary: any;
  public comp_events: any;
  public levelCounts: Array<any>;
  public startDate: any;
  public creditAuthDate: any;
  public loanAppDate: any;
  public leDate: any;
  public cdDate: any;
  public lockDate: any;
  public isDefaultLoanApp: boolean;
  public isLoanApps: boolean;
  public isStarted: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageTitle = 'Dashboard';
    this.reloaded = false;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loanDashboard
      });
    }, 0);
    this.reload(false);
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.tranService.getCurrentTran(force)
      .then((tran) => {
        this.tran = tran;
        this.tranId = this.tran.id;
        this.isDefaultLoanApp = !!tran.getDefaultLoanApp();
        this.isLoanApps = !!tran.getLoanApps();
        this.txSummary = this.tranService.getTransactionSummary({
          transaction: this.tran,
          mlo: this.userService.getUserSync()
        });
        return this.loanAppService.getCurrentLoanDocument();
      })
      .then((doc: Document) => {
        this.doc = doc;
        const simulatedDate = moment().format('MM/DD/YYYY');
        const isRefinanceLoan = this.doc ? this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes) : false;
        return this.compService.getCompReport(this.tranId, 'MM/DD/YYYY', simulatedDate, isRefinanceLoan);
      })
      .then((response) => {
        let report;
        if (response && (report = response.report)) {
          const keyEvents = CompEvent.getKeyEvents(report);
          this.isStarted = keyEvents['isStarted'];
          this.levelCounts = keyEvents['levelCounts'];
          this.startDate = keyEvents['startDate'];
          this.creditAuthDate = keyEvents['creditAuthDate'];
          this.loanAppDate = keyEvents['loanAppDate'];
          this.leDate = keyEvents['leDate'];
          this.cdDate = keyEvents['cdDate'];
          this.lockDate = keyEvents['lockDate'];
          this.reloaded = true;
        }
      })
      .catch(() => {
        console.log('BAD: No current loan.');
      });
  }
}
