<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Site Map
  </mat-card-title>

  <mat-card-content>
    <p>
      The site-map display links to all facilities you can access.
    </p>
  </mat-card-content>
</mat-card>
