import {Component, Injector, OnInit} from '@angular/core';
import {CreditComponent} from '../credit/credit.component';

@Component({
  selector: 'app-edit-segment-credit',
  templateUrl: '../credit/edit-segment-credit.component.html',
})
export class EditSegmentCreditComponent extends CreditComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
