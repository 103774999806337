<mat-card *ngIf="item" class="mb-1">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-6 text-start align-self-center">
        <span>{{item.full_name}}</span>&nbsp;
        <span>({{item.email}})</span>
      </div>
      <div class="col-lg-2 align-self-center text-end">
        <a (click)="delete($event)" href="#" title="Delete customer"><i class="text-danger fas fa-trash"></i></a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
