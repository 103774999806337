import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import {TranService} from '../../../../../services/tran.service';
import {DocumentService} from '../../../../../services/document.service';
import {UserService} from '../../../../../services/user.service';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-mbfa-doc',
  templateUrl: './edit-mbfa-doc.component.html',
})
export class EditMBFADocComponent extends GenerateDocumentBaseComponent implements OnInit {
  public tranService: TranService;
  public docService: DocumentService;
  public userService: UserService;
  public txSummary: any;
  public borrowerCount: number;
  public minDate = globals.date.minTranStartDate;
  public maxDate = new Date();
  @Input() folderId: string;
  public submitted: boolean;

  // form and form controls
  public form: FormGroup;
  public mlo_name: FormControl;
  public mlo_nmls: FormControl;
  public doc_date: FormControl;
  public company_name: FormControl;
  public company_nmls: FormControl;
  public loan_amount: FormControl;
  public compensation_percent: FormControl;
  public min_compensation_amount: FormControl;
  public max_compensation_amount: FormControl;
  public broker_compensation: FormControl;
  public paid_by_lender: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.formType = 'BFA';
  }

  ngOnInit() {
    super.ngOnInit();
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.generating = false;
    this.submitted = false;
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.mlo_name = new FormControl(this.txSummary.mloFullName, AppValidators.present);
    this.mlo_nmls = new FormControl(this.txSummary.mloNMLS, AppValidators.present);
    this.company_name = new FormControl(this.txSummary.companyName, AppValidators.present);
    this.company_nmls = new FormControl(this.txSummary.companyLicenseNumber, AppValidators.present);
    this.loan_amount = new FormControl(this.txSummary.loanAmount, AppValidators.present);

    this.compensation_percent = new FormControl(this.savedDoc['compensation_percent'] || this.txSummary.compensationPercent, AppValidators.present);
    this.min_compensation_amount = new FormControl(this.savedDoc['min_compensation_amount'] || this.txSummary.brokerMinCompensation, AppValidators.present);
    this.max_compensation_amount = new FormControl(this.savedDoc['max_compensation_amount'] || this.txSummary.brokerMaxCompensation, AppValidators.present);
    this.broker_compensation = new FormControl(this.savedDoc['broker_compensation'], AppValidators.present);
    this.paid_by_lender = new FormControl(this.savedDoc['paid_by_lender'], AppValidators.present);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      mlo_name: this.mlo_name,
      mlo_nmls: this.mlo_nmls,
      company_name: this.company_name,
      company_nmls: this.company_nmls,
      loan_amount: this.loan_amount,
      compensation_percent: this.compensation_percent,
      min_compensation_amount: this.min_compensation_amount,
      max_compensation_amount: this.max_compensation_amount,
      broker_compensation: this.broker_compensation,
      paid_by_lender: this.paid_by_lender,
      doc_date: this.doc_date,
    });
  }


  getBrokerCompensation() {
    let compensation;
    if (this.loan_amount.value && this.loan_amount.value > 0) {
      compensation = this.loan_amount.value * this.compensation_percent.value / 100.00;
    }
    if (this.min_compensation_amount.value && compensation < this.min_compensation_amount.value) {
      return this.min_compensation_amount.value;
    }
    if (this.max_compensation_amount.value && compensation > this.max_compensation_amount.value) {
      return this.max_compensation_amount.value;
    }
    if (compensation) {
      return Math.round(compensation * 100) / 100;
    }
    return 'N/A';
  }

  async genMBFA(): Promise<boolean> {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.generating = true;
    const formData = this.form.value;
    formData['doc_date'] = moment(this.doc_date.value).format('MM/DD/YYYY');
    formData['broker_compensation'] = this.broker_compensation.value;
    formData['icn_id'] = this.folderId || null;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
