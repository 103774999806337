<mat-card *ngIf="reloaded" class="mt-1 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'View Tran'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{tran.name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{tran.description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Owner</div>
      <div class="col-lg-8">{{tran.owner_full_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngFor="let permission of tran.permissions">
      <div class="col-lg-2 fw-bold">User access</div>
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-3">
            <span class="fw-bold">Name:</span> {{permission.acl_full_name}}
          </div>
          <div class="col-lg-3">
            <span class="fw-bold">Username:</span> {{permission.acl_id}}
          </div>
          <div class="col-lg-3">
            <span class="fw-bold">Email:</span> {{permission.acl_email}}
          </div>
          <div class="col-lg-3">
            <a (click)="revokeAccess($event,permission)" title="Remove access" class="hand-pointer"><i class="text-danger fa fa-trash"></i></a>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
