import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class SharedService {
  private eltId = 0;
  @Output() public eventEmitted = new EventEmitter();

  // Service message commands
  emitChange(change: object) {
    this.eventEmitted.emit(change);
  }
  // generate a unique ID
  generateId(): number {
    return ++this.eltId;
  }
}
