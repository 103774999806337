import {Component, Injector, OnInit} from '@angular/core';
import {ErrorCheckComponent} from '../error-check/error-check.component';

@Component({
  selector: 'app-view-segment-error-check',
  templateUrl: './view-segment-error-check.component.html',
})
export class ViewSegmentErrorCheckComponent extends ErrorCheckComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.loan_app = this.doc.recursive_attributes;
    this.setErrorLevel();
    this.createFormControls();
    this.createForm();
  }

  runErrorCheck(event: any): void {
    event.preventDefault();
    super.errorCheck();
    this.mode = 'View';
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }
}
