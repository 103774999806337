import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import { FolderGroup} from "../../../models/folder-group";

@Component({
  selector: 'app-folder-group',
  templateUrl: './folder-group.component.html',
})
export class FolderGroupComponent extends CompBaseComponent implements OnInit {
  @Input() folderGroup: FolderGroup;
  public mode = 'View';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(loadCurrentLoanApp?: boolean) {
    super.ngOnInit(loadCurrentLoanApp);
  }
}
