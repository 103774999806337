import {Component, Injector, OnInit} from '@angular/core';
import {Alias} from '../../../../models/alias';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-aliases',
  templateUrl: './view-segment-aliases.component.html',

})
export class ViewSegmentAliasesComponent extends CompBaseComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'aliases';
    this.section = this.applicant;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Alias.deserializeArray(this.loan_app[this.applicant].aliases);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
