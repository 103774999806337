import {Component, Injector, OnInit} from '@angular/core';
import {EnvelopeHeader} from '../../../../models/envelope-header';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-envelope-header',
  templateUrl: './view-envelope-header.component.html',
})
export class ViewEnvelopeHeaderComponent extends CompBaseComponent implements OnInit {
  public item: EnvelopeHeader;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new EnvelopeHeader(this.doc.recursive_attributes['envelope_header']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
