<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Residence type</mat-label>
            <mat-select formControlName="residence_type_ex" placeholder="Select residence type" required>
              <div *ngIf="op==='Add'">
                <mat-option *ngFor="let residence_type of getRemainingResidenceTypes()" [value]="residence_type.key">{{residence_type.value}}</mat-option>
              </div>
              <div *ngIf="op==='Edit'">
                <mat-option *ngFor="let residence_type of residence_types_ex" [value]="residence_type.key">{{residence_type.value}}</mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="residence_type_ex.hasError('required')">
              Residence type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="residence_type_ex.value==='Mailing'" class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Residence term</mat-label>
            <mat-select [required]="residence_type_ex.value!=='Current'" formControlName="residence_term_ex" placeholder="Select residence term" [required]="residence_type_ex.value!=='Mailing'">
              <mat-option *ngFor="let residence_term of residence_terms_ex" [value]="residence_term.key">{{residence_term.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="residence_term_ex.hasError('required')">
              Residence term is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Address</mat-label>
            <input formControlName="address" matInput placeholder="Address" type="text" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="address.hasError('required')">
              Address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Apt/Unit/Suite</mat-label>
            <input type="text" formControlName="address2" matInput placeholder="Apt/Unit/Suite" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="address2.hasError('required')">
              Apt/Unit/Suite is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput placeholder="City" type="text" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="city.hasError('required')">
              City is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>State abbreviation</mat-label>
            <input type="text" appUpperCase appAlphaOnly formControlName="state" matInput placeholder="State abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            <mat-hint>State Abbr. E.g., WA for Washington</mat-hint>
            <mat-error *ngIf="state.hasError('required')">
              State abbreviation is required
            </mat-error>
            <mat-error *ngIf="state.hasError('pattern')">
              State is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip</mat-label>
            <input type="text" appNumberOnly formControlName="zip" matInput placeholder="Zip" maxlength="{{MAX_LEN.ZIP}}" required>
            <mat-error *ngIf="zip.hasError('required')">
              Zip is required
            </mat-error>
            <mat-error *ngIf="zip.hasError('pattern')">
              Zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip four</mat-label>
            <input type="text" appNumberOnly formControlName="zip_four" matInput placeholder="Zip four" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="zip_four.hasError('required')">
              Zip four is required
            </mat-error>
            <mat-error *ngIf="zip_four.hasError('pattern')">
              Zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Country code</mat-label>
            <input type="text" formControlName="country" matInput appAlphaOnly appUpperCase placeholder="Country code" maxlength="{{MAX_LEN.COUNTRY_CODE}}" required>
            <mat-error *ngIf="country.hasError('required')">
              Country code is required
            </mat-error>
            <mat-error *ngIf="country.hasError('pattern')">
              Country code is invalid
            </mat-error>
            <mat-hint>Country Abbreviation. Enter US for USA.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row" [hidden]="residence_type_ex.value === 'Mailing'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Number of years</mat-label>
            <input appNumberOnly formControlName="years_no" matInput placeholder="Number of years" type="text" [required]="isDurationRequired()" maxlength="2">
            <mat-error *ngIf="years_no.hasError('required')">
              Number of years is required
            </mat-error>
            <mat-error *ngIf="years_no.hasError('min')">
              Must be greater or equal to 0
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-3" [hidden]="residence_type_ex.value === 'Mailing'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Number of months</mat-label>
            <input type="text" appNumberOnly formControlName="months_no" matInput placeholder="Number of months" maxlength="2">
            <mat-error *ngIf="months_no.hasError('required')">
              Number of months is required
            </mat-error>
            <mat-error *ngIf="months_no.hasError('min') || months_no.hasError('max')">
              Number of months must be between 0 and 11
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-2" [hidden]="residence_term_ex.value !== 'Rent'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Rent</mat-label>
            <input type="text" formControlName="rent" matInput appDecimalOnly placeholder="Rent" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="rent.hasError('required')">
              Rent is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
