import {Component, Injector, OnInit} from '@angular/core';
import {DownPayment} from '../../../../models/down-payment';
import {DownPaymentComponent} from '../down-payment/down-payment.component';

@Component({
  selector: 'app-add-down-payment',
  templateUrl: '../down-payment/edit-segment-down-payment.component.html',
})
export class AddDownPaymentComponent extends DownPaymentComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new DownPayment({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
