<!-- 2021-04-22 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Tasks
  </mat-card-title>

  <mat-card-content *ngIf="isLoanProfessional">
    <p>
      <em>The best way to understand Tasks functions is to try them out.</em>
    </p>

    <h5>Current Task</h5>

    <p>
      The main purpose of the Current Task is to communicate the loan status to the loan contacts.
      The three fields (To do, Status update, and Summary) display on the workflow Timeline feature.
    </p>

    <p>
      <span class="fw-bold">To edit the Current Task data fields.</span>
    </p>

    <ul>
      <li>Click the the edit (pencil) icon.</li>
      <li>Edit the "To do", "Status update", or "Summary" text areas.</li>
      <li>Click the Update button.</li>
    </ul>

    <p>
      <span class="fw-bold">To edit Current Task timestamps.</span>
    </p>

    <ul>
      <li>Click the edit (pencil) icon.</li>
      <li>Edit the "Started at" and "Completed at" fields.</li>
      <li>Click the Update button.</li>
    </ul>

    <h5>All Tasks</h5>

    <p>
      The All Tasks tab displays the workflow's current, completed, and uncompleted tasks.
      It also lets you edit any task and mark it as "hidden."
    </p>

    <span class="fw-bold">To toggle the display of hidden tasks</span>
    <ul>
      <li>Check or uncheck the "Display hidden tasks" checkbox.</li>
    </ul>

    <span class="fw-bold">To edit a task</span>
    <ul>
      <li>Click the edit (pencil) icon next to the task.</li>
      <li>Update the task fields.</li>
      <li>Click the Update button.</li>
    </ul>

    <span class="fw-bold">To toggle any task visibility</span>
    <ul>
      <li>Click the edit (pencil) icon next to the task.</li>
      <li>Click the Hide/Show button.</li>
    </ul>

    <h5>Bulk Update</h5>

    <p>
      The Bulk Update tab displays the workflow's tasks on a calendar grid and lets you perform a bulk task update.
    </p>

    <span class="fw-bold">Legend</span>

    <ul>
      <li>
        Completed tasks are displayed in
        <span class="badge badge-pill badge-success">Green</span> badges.
      </li>
      <li>
        Uncompleted tasks are displayed in
        <span class="badge badge-pill badge-danger">Red</span> badges.
      </li>
      <li>
        Hidden tasks are displayed in
        <span class="badge badge-pill badge-warning">Yellow</span> badges.
      </li>
    </ul>

    <span class="fw-bold">Navigating the calendar</span>

    <ul>
      <li>Click the forward/backward chevron above the calendar to move to the next/previous month.</li>
      <li>Click the double chevron to move to the end/beginning of the workflow.</li>
    </ul>

    <span class="fw-bold">To bulk update task start and end dates</span>
    <ul>
      <li>Drag a task from the calendar cell to the destination cell (date).</li>
      <li>If the target cell (date) is not visible in the current calendar grid, you can accomplish the update as follows.
        <ul>
          <li>
            Drag the task to any date of the next/previous month that is visible in the current month,
          </li>
          <li>
            move to the next/previous month, and then
          </li>
          <li>
            move the task to its destination cell.
          </li>
        </ul>
      </li>
      <li>
        Repeat the above steps for all tasks that require updates.
      </li>
      <li>
        When done, click the Update button.
      </li>
    </ul>

    <h5>Reset Uncompleted Tasks</h5>

    <p>
      The need to reset uncompleted workflow tasks arises when you are revisiting a loan that has been idle for some time.
      By resetting uncompleted tasks, you are moving "uncompleted tasks" dates to the future.
    </p>

    <p>
      After you have reset uncompleted tasks, you can change the task start dates using the Uncompleted Tasks tab.
    </p>

    <span class="fw-bold">To reset the uncompleted tasks</span>

    <ul>
      <li>
        Click the Reset Uncompleted Tasks button.
      </li>
    </ul>
  </mat-card-content>
</mat-card>
