import {Component, Injector, OnInit} from '@angular/core';
import {Alias} from '../../../../models/alias';
import {AliasComponent} from '../alias/alias.component';

@Component({
  selector: 'app-add-alias',
  templateUrl: '../alias/edit-segment-alias.component.html',
})
export class AddAliasComponent extends AliasComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Alias({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
