<div [hidden]="reloaded" class="w-100" >
  <mat-spinner [diameter]="68" style="margin:0 auto;"></mat-spinner>
</div>

<mat-card *ngIf="reloaded">
  <mat-card-title>Tasks</mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-2 fw-bold border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">ID</div>
      <div class="col col-lg-4">Name</div>
      <div class="col col-lg-4">Task</div>
      <div class="col col-lg-3">Started</div>
    </div>

    <div (click)="navigateToTran($event,trace.tran_id)" *ngFor="let trace of traces"
      class="row pt-1 pb-1 hand-pointer border-bottom hover-border">
      <div [hidden]="isMobile" class="col col-lg-1">{{trace.tran_id}}</div>
      <div class="col col-lg-4">{{trace.tran_name}}</div>
      <div class="col col-lg-4">{{trace.state_display}}</div>
      <div class="col col-lg-3">
        <span *ngIf="isMobile">{{trace.started_at | date:'shortDate'}}</span>
        <span *ngIf="!isMobile">{{trace.started_at | date:'medium'}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
