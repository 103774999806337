<mat-card *ngIf="reloaded">
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row pb-3">
        <div class="col">
          <mat-radio-group formControlName="clearLiabilities" aria-label="Select an option">
            <label id="example-radio-group-label">Select the liabilities to be deleted</label><br>
            <mat-radio-button value=1>Select all liabilities with zero balance</mat-radio-button>
            <mat-radio-button value=2>Select all liabilities</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="empty()" [disabled]="!clearLiabilities.value" class="ms-2 btn btn-lg btn-warning" type="button" title="Delete all liabilities">Delete Selected Liabilities</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
