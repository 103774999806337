import {AfterViewInit, Component, ElementRef, Input, OnDestroy, SecurityContext, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PopupDialogComponent} from '../popup-dialog/popup-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-popup-link',
  templateUrl: './popup-link.component.html',
  styleUrls: ['./popup-link.component.scss']
})
export class PopupLinkComponent implements AfterViewInit, OnDestroy {
  @ViewChild('eltRef') private eltRef: ElementRef;
  @Input() dialogHeight: number;
  @Input() linkText: string;
  @Input() linkData: string;
  rect: any;
  dialogRef: any;

  constructor(public dialog: MatDialog, private dom: DomSanitizer) {
  }

  onMouseEnter(e): void {
    this.openDialog();
  }

  onMouseLeave(e): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    removeEventListener('mouseenter', this.onMouseEnter);
    removeEventListener('mouseleave', this.onMouseLeave);
  }

  ngAfterViewInit() {
    this.rect = this.eltRef.nativeElement.getBoundingClientRect();
    this.eltRef.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.eltRef.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  openDialog(): void {
    let position: any;
    if (window.innerHeight - this.rect.bottom < 200) {
      position = {left: `${this.rect.left + this.rect.width}px`, top: `${this.rect.top - this.dialogHeight}px`};
    } else {
      position = {left: `${this.rect.left + this.rect.width}px`, top: `${this.rect.bottom}px`};
    }
    this.dialogRef = this.dialog.open(PopupDialogComponent, {
      hasBackdrop: false,
      width: '350px',
      height: `${this.dialogHeight}px`,
      data: {text: this.linkData},
      panelClass: 'popupDialog',
      position: position
    });
    this.dialogRef.componentInstance.textSafe = this.dom.sanitize(SecurityContext.HTML, this.linkData);
  }
}
