<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="'Financial Report'" [help]="true">
      <span class="fw-bold">Financial Report</span>
      <p>
        The Financial Report functions compute important ratios such as debt-to-income. By default, it picks its data from
        the loan application. But you can also run what-if scenarios with your own data. Running this function does not affect the loan application.
      </p>
    </app-segment-header>
  </mat-card-title>

  <app-box *ngIf="error" [close]="true" [type]="'warning'">
    <strong>Oops!</strong>
    Some data items are missing. Please verify that the loan application contains:
    <ul class="mb-0">
      <li>
        Loan amount
      </li>
      <li>
        Property appraised or purchase value
      </li>
      <li>
        Income
      </li>
    </ul>
  </app-box>

  <mat-card-content *ngIf="!error">
    <form *ngIf="reloaded && !error" [formGroup]="form" novalidate role="form">
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly loan payment</mat-label>
            <input appDecimalOnly formControlName="monthlyLoanPayment" matInput placeholder="Monthly loan payment" appAutoFocus type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthlyLoanPayment.hasError('required')">
              Monthly loan payment is required
            </mat-error>
            <mat-hint>
              Monthly principal and interest payment
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly property taxes</mat-label>
            <input appDecimalOnly formControlName="monthlyRealEstateTaxes" matInput placeholder="Monthly property taxes" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthlyRealEstateTaxes.hasError('required')">
              Monthly property taxes is required
            </mat-error>
            <mat-hint>
              Annual tax payment divided by 12
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly homeowners insurance</mat-label>
            <input appDecimalOnly formControlName="monthlyHazardInsurance" matInput placeholder="Monthly homeowners insurance" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthlyHazardInsurance.hasError('required')">
              Monthly homeowners insurance is required
            </mat-error>
            <mat-hint>
              Annual homeowners insurance payment divided by 12
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly mortgage insurance</mat-label>
            <input appDecimalOnly formControlName="monthlyMortgageInsurance" matInput placeholder="Monthly mortgage insurance" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthlyMortgageInsurance.hasError('required')">
              Monthly mortgage insurance is required
            </mat-error>
            <mat-hint>
              If applicable
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="compute($event)" class="btn btn-lg btn-primary" type="button">Compute</button>
          <button (click)="reset($event)" class="ms-2 btn btn-lg btn-light">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
