<div [hidden]="!generating">
  <mat-spinner [diameter]="68" class="ms-5 mb-3"></mat-spinner>
</div>

<app-box *ngIf="reloaded&&!isDefaultLoanApp" [type]="'warning'" class="mt-2">
  You cannot generate the URLA document because there is
  <span class="fw-bold">no default loan application</span>. You can create a loan application and flag it as default from
  <strong>Loan Applications</strong>.
</app-box>

<div *ngIf="reloaded&&isDefaultLoanApp" [hidden]="generating">
  <form [formGroup]="form" class="mt-0">
    <div class="row">
      <div class="col-lg-6" [hidden]="borrowerCount===1">
        <mat-form-field class="md-icon-left w-100">
          <mat-select formControlName="who" placeholder="Select borrower" [required]="borrowerCount>=2" (selectionChange)="onChangeWho()">
            <mat-option *ngFor="let bor of applicants" [value]="bor.key">{{bor.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="who.hasError('required')">
            Borrower is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="md-icon-left w-100">
          <mat-select formControlName="form_type" placeholder="Select form type" required>
            <mat-option *ngFor="let formType of formTypeTable" [value]="formType.key">{{formType.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="who.hasError('required')">
            Form type is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="form_type.value==='continuation'" class="row">
      <div class="col-lg-6">
        <mat-label>Page number</mat-label>
        <mat-form-field class="md-icon-left w-100">
          <input formControlName="page_number" appNumberOnly type="text" matInput placeholder="Page Number">
          <mat-error *ngIf="page_number.hasError('min') || page_number.hasError('max')">
            Page number must be between 1 and 10
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <button (click)="genURLA($event)" [disabled]="!form.valid" class="btn btn-lg btn-primary">Generate</button>
        <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
      </div>
    </div>
  </form>
</div>
