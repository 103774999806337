import {Component, Injector, Input, OnInit} from '@angular/core';
import {Address} from '../../../../models/address';
import {AddressComponent} from '../address/address.component';

@Component({
  selector: 'app-view-segment-address',
  templateUrl: './view-segment-address.component.html',

})
export class ViewSegmentAddressComponent extends AddressComponent implements OnInit {
  @Input() item: Address;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
