import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Mcr} from '../../../../models/mcr';

@Component({
  selector: 'app-error-check',
  template: '',
})
export class OrgMcrComponent extends CompBaseComponent implements OnInit {
  public readonly quarters = Mcr.quarters;
  public readonly years = Mcr.years;
  @Input() mcr: Mcr;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  view(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }
}
