import {Component, Injector, Input, OnInit} from '@angular/core';
import {DeviceService} from '../../../services/device.service';

@Component({
  selector: 'app-site-top-banner',
  templateUrl: './site-top-banner.component.html',
})
export class SiteTopBannerComponent implements OnInit {
  public reloaded: boolean;
  public isMobile: boolean;
  @Input() public bannerTitle: string;

  constructor(public injector: Injector) {
    this.isMobile = DeviceService.isMobile;
  }

  ngOnInit() {
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
