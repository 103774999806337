<div class="legal-content pb-5" style="font-size: 16px;">
  <h3 class="section-heading">PrudentCO Terms of Use</h3>

  <p class="intro">Welcome to PrudentCO, an online loan origination service designed for mortgage loan originators (MLOs)
    and home mortgage loan borrowers. These Terms of Use are intended to explain our obligations as a service provider
    and Your obligations as a customer. Please read them carefully.
  </p>

  <p class="intro">These Terms are binding on any use of the Service and apply to You from the time that PrudentCO
    provides You with access to the Service.
  </p>

  <p class="intro">The PrudentCO Service will evolve over time based on user feedback.
    These Terms are not intended to answer every question or address every issue raised by the use of the PrudentCO Service.
    PrudentCO reserves the right to change these terms at any time, effective upon the posting of modified terms.
    PrudentCO will make every effort to communicate these changes to You via email or notification via the Website.
    It is likely the terms of use will change over time.
    It is Your obligation to ensure that You have read, understood and agree to the most recent terms available on the
    Website.
  </p>

  <p class="intro">By registering to use the Service you acknowledge that You have read and understood these Terms and
    have the authority to act on behalf of any person for whom You are using the Service.
    You are deemed to have agreed to these Terms on behalf of any entity for whom you use the Service.
  </p>

  <ol>
    <li>
      <b>Definitions</b>
      <dl>
        <dt>
          <strong>"Agreement"</strong>
        </dt>
        <dd>means these Terms of Use.</dd>

        <dt>
          <strong>"Access Fee"</strong>
        </dt>
        <dd>means the monthly fee (excluding any taxes and duties) payable by You in accordance with the fee schedule
          set out on the Website (which PrudentCO may change from time to time on notice to You).
        </dd>

        <dt>
          <strong>"Confidential Information"</strong>
        </dt>
        <dd>includes all information exchanged between the parties to this Agreement, whether in writing, electronically
          or orally, including the Service but does not include information which is, or becomes, publicly available
          other than through unauthorized disclosure by the other party.
        </dd>

        <dt>
          <strong>"Data"</strong>
        </dt>
        <dd>means any data inputted by You or with Your authority into the Website.</dd>

        <dt>
          <strong>"Intellectual Property Right"</strong>
        </dt>
        <dd>means any patent, trade mark, service mark, copyright, moral right, right in a design, know-how and any
          other intellectual or industrial property rights, anywhere in the world whether or not registered.
        </dd>

        <dt>
          <strong>"Service"</strong>
        </dt>
        <dd>means the online accounting and personal finance management services made available (as may be changed or
          updated from time to time by PrudentCO) via the Website.
        </dd>

        <dt>
          <strong>"Website"</strong>
        </dt>
        <dd>means the Internet site at the domain
          <a href="https://www.prudentco.com" class="pr-dark-blue">www.prudentco.com</a> ;or
          any other site operated by PrudentCO.
        </dd>

        <dt>
          <strong>"PrudentCO"</strong>
        </dt>
        <dd>means FrameLogic Corporation doing-business-as (DBA) PrudentCO and all current and future subsidiaries of
          PrudentCO.
        </dd>

        <dt>
          <strong>"Invited User"</strong>
        </dt>
        <dd>means any person or entity, other than the Subscriber, that uses the Service with the authorization of the
          Subscriber from time to time.
        </dd>

        <dt>
          <strong>"Subscriber"</strong>
        </dt>
        <dd>means the person who registers to use the Service, and, where the context permits, includes any entity on
          whose behalf that person registers to use the Service.
        </dd>

        <dt>
          <strong>"You"</strong>
        </dt>
        <dd>means the Subscriber, and where the context permits, an Invited User. "Your" has a corresponding meaning.
        </dd>
      </dl>
    </li>
    <li>
      <b>Use of Software</b>
      <p>PrudentCO grants You the right to access and use the Service via the Website with the particular user roles
        available to You according to Your subscription type.
        This right is exclusive, non-transferable, and limited by and subject to this Agreement.
    </li>
    <li>
      <b>Your Obligations</b>
      <ol>
        <li>
          <b>Payment obligations:</b>
          <p>
            An invoice for the Access Fee will be issued each month starting one month from the date You created Your
            PrudentCO account.
            All invoices will include the Access Fee for the preceding period one month of use. PrudentCO will
            continue invoicing You monthly until this Agreement is terminated in accordance with clause 8.
          </p>
          <p>All PrudentCO invoices will be sent to You, or to a Billing Contact whose details are provided by You, by email.
            You must pay or arrange payment of all amounts specified in any invoice by the due date for payment and are
            payable within 10 days of the invoice date.
            You are responsible for payment of all taxes and duties in addition to the Access Fee.
          </p>
        </li>
        <li>
          <b>Preferential pricing or discounts:</b>
          You may from time to time be offered preferential pricing or discounts for the Access Fees as a result of Your
          use of the Service .
          Eligibility for such preferential pricing or discounts is conditional upon Your acceptance
          of responsibility for payment of any Access Fees in relation to all of Your Transactions.
          Without prejudice to any other rights that PrudentCO may have under these Terms or at law, PrudentCO reserves the right to render
          invoices for the full (non-discounted) Access Fees due or suspend or terminate Your use of the Service in respect
          of any or all of Your Transactions in the event that any invoices for those Access Fees are not paid in full
          by the due date for payment.
        </li>
        <li>
          <b>General obligations:</b>
          You must only use the Service and Website for Your own lawful internal business purposes, in accordance with
          these Terms and any notice sent by PrudentCO or condition posted on the Website. You may use the Service and
          Website on behalf of others or in order to provide services to others but if You do so you must ensure that
          You are authorized to do so and that all persons for whom or to whom services are provided comply with and accept
          all terms of this Agreement that apply to You.
        </li>
        <li>
          <b>Consumer credit data delivered into Your PrudentCO account:</b>
          <p>
            Where available, consumer credit data are provided to You. PrudentCO reserves the right to
            pass on any charges related to the provision of consumer credit data. You have the option to decide to
            discontinue the use of this service. However, you must give PrudentCO sufficient prior notice of your intent to
            discontinue use of this service. Upon receiving such notice PrudentCO will arrange for such reports to be
            terminated.
          </p>
        </li>
        <li>
          <b>Access conditions:</b>
          <ol>
            <li>
              You must ensure that all usernames and passwords required to access the Service are kept secure and
              confidential. You must immediately notify PrudentCO of any unauthorized use of Your passwords or any other
              breach of security and PrudentCO will reset Your password and You must take all other actions that
              PrudentCO reasonably deems necessary to maintain or enhance the security of PrudentCO's computing systems and
              networks and Your access to the Services.
            </li>
            <li>
              <p>As a condition of these Terms, when accessing and using the Services, You must:</p>
              <ol>
                <li>
                  not attempt to undermine the security or integrity of PrudentCO's computing systems or networks or,
                  where the Services are hosted by a third party, that third party's computing systems and networks;
                </li>
                <li>
                  not use, or misuse, the Services in any way which may impair the functionality of the Services or
                  Website, or other systems used to deliver the Services or impair the ability of any other user to
                  use the Services or Website;
                </li>
                <li>
                  not attempt to gain unauthorized access to any materials other than those to which You have been
                  given express permission to access or to the computer system on which the Services are hosted;
                </li>
                <li>
                  not transmit, or input into the Website, any: files that may damage any other person's computing
                  devices or software, content that may be offensive, or material or Data in violation of any law (including
                  Data or other material protected by copyright or trade secrets which You do not have the right to use); and
                </li>
                <li>
                  not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer
                  programs used to deliver the Services or to operate the Website except as is strictly necessary to use
                  either of them for normal operation.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Usage Limitations:</b>
          Use of the Service may be subject to limitations, including but not limited to monthly transaction volumes.
          Any such limitations will be advised.
        </li>
        <li>
          <b>Communication Conditions:</b>
          <p>
            As a condition of these Terms, if You use any communication tools available through the Website (such as any
            forum, chat room or message center), You agree only to use such communication tools for lawful and
            legitimate purposes.
            You must not use any such communication tool for posting or disseminating any material unrelated
            to the use of the Services, including (but not limited to): offers of goods or services for sale, unsolicited
            commercial e-mail, files that may damage any other person's computing devices or software, content that may
            be offensive to any other users of the Services or the Website, or material in violation of any law
            (including material that is protected by copyright or trade secrets which You do not have the right to use).
          </p>
          <p>When You make any communication on the Website, You represent that You are permitted to make such
            communication.
            PrudentCO is under no obligation to ensure that the communications on the Website are legitimate or that
            they are related only to the use of the Services.
            As with any other web-based forum, You must exercise caution when
            using the communication tools available on the Website. However, PrudentCO does reserve the right to remove
            any communication at any time in its sole discretion.
          </p>
        </li>
        <li>
          <b>Indemnity:</b>
          You indemnify PrudentCO against: all claims, costs, damage and loss arising from Your breach of any of these
          Terms or any obligation You may have to PrudentCO, including (but not limited to) any costs relating to the
          recovery of any Access Fees that are due but have not been paid by You.
        </li>
      </ol>
    </li>
    <li>
      <b>Confidentiality and Privacy</b>
      <ol>
        <li>
          <b>Confidentiality:</b>
          <p>Unless the relevant party has the prior written consent of the other or unless required to do so by law:
          </p>
          <ol>
            <li>
              Each party will preserve the confidentiality of all Confidential Information of the other obtained in
              connection with these Terms. Neither party will, without the prior written consent of the other, disclose
              or make any Confidential Information available to any person, or use the same for its own benefit, other
              than as contemplated by these Terms.
            </li>
            <li>
              Each party's obligations under this clause will survive termination of these Terms.
            </li>
            <li>
              The provisions of clauses 4.1.1 and 4.1.2 shall not apply to any information which:
              <ol>
                <li>
                  is or becomes public knowledge other than by a breach of this clause;
                </li>
                <li>
                  is received from a third party who lawfully acquired it and who is under no obligation restricting its
                  disclosure;
                </li>
                <li>
                  is in the possession of the receiving party without restriction in relation to disclosure before the
                  date of receipt from the disclosing party; or
                </li>
                <li>
                  is independently developed without access to the Confidential Information.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Privacy:</b>
          <p>PrudentCO maintains a privacy policy that sets out the parties’ obligations in respect of personal information.
            You should read that policy at
            <a href="/site/privacy" class="pr-dark-blue">www.prudentco.com/site/privacy</a> and
            You will be taken to have accepted that policy when You accept these Terms.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <b>Intellectual Property</b>
      <ol>
        <li>
          <b>General:</b>
          <p>
            Title to, and all Intellectual Property Rights in the Services, the Website and any documentation relating
            to the Services remain the property of PrudentCO (or its licensors).
          </p>
        </li>
        <li>
          <b>Ownership of Data:</b>
          <p>
            Title to, and all Intellectual Property Rights in, the Data remain Your property. However, Your access to
            the Data is contingent on full payment of the PrudentCO Access Fee when due. You grant PrudentCO a licence
            to use, copy, transmit, store, and back-up Your information and Data for the purposes of enabling You to
            access and use the Services and for any other purpose related to provision of services to You.
          </p>
        </li>
        <li>
          <b>Backup of Data:</b>
          <p>
            You must maintain copies of all Data inputted into the Service.
            PrudentCO adheres to its best practice policies and procedures to prevent data loss, including a daily system data back-up,
            but does not make any guarantees that there will be no loss of Data.
            PrudentCO expressly excludes liability for any loss of Data no matter how caused.
          </p>
        </li>
        <li>
          <b>Third-party applications and your Data.</b>
          <p>
            If You enable third-party applications for use in conjunction with the Services, You acknowledge that
            PrudentCO may allow the providers of those third-party applications to access Your Data as required for the
            interoperation of such third-party applications with the Services. PrudentCO shall not be responsible for any disclosure,
            modification or deletion of Your Data resulting from any such access by third-party application providers.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <b>Warranties and Acknowledgements</b>
      <ol>
        <li>
          <b>Authority:</b>
          <p>
            You warrant that where You have registered to use the Service on behalf of another person, You have the
            authority to agree to these Terms on behalf of that person and agree that by registering to use the Service You bind
            the person on whose behalf You act to the performance of any and all obligations that You become subject to
            by virtue of these Terms, without limiting Your own personal obligations under these Terms.
          </p>
        </li>
        <li>
          <b>Acknowledgement:</b>
          <p>You acknowledge that:</p>
          <ol>
            <li>You are authorized to use the Services and the Website and to access the information and Data that You
              input into the Website, including any information or Data input into the Website by any person you have
              authorized to use the Service.
              You are also authorized to access the processed information and Data that is
              made available to You through Your use of the Website and the Services (whether that information and Data
              is Your own or that of anyone else).
            </li>
            <li>PrudentCO has no responsibility to any person other than You and nothing in this Agreement confers, or
              purports to confer, a benefit on any person other than You. If You use the Services or access the Website
              on behalf of or for the benefit of anyone other than yourself (whether a body corporate or otherwise) you
              agree that:
              <ol>
                <li>
                  You are responsible for ensuring that You have the right to do so;
                </li>
                <li>
                  You are responsible for authorizing any person who is given access to information or Data, and you
                  agree that PrudentCO has no obligation to provide any person access to such information or Data
                  without Your authorization and may refer any requests for information to You to address; and
                </li>
                <li>You will indemnify PrudentCO against any claims or loss relating to:
                  <ol>
                    <li>
                      PrudentCO's refusal to provide any person access to Your information or Data in accordance with
                      these Terms,
                    </li>
                    <li>
                      PrudentCO’s making available information or Data to any person with Your authorization.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The provision of, access to, and use of, the Services is on an "as is " basis and at Your own risk.
            </li>
            <li>
              PrudentCO does not warrant that the use of the Service will be uninterrupted or error free. Among other
              things, the operation and availability of the systems used for accessing the Service, including public
              telephone services, computer networks and the Internet, can be unpredictable and may from time to time
              interfere with or prevent access to the Services. PrudentCO is not in any way responsible for any such
              interference or prevention of Your access or use of the Services.
            </li>
            <li>
              PrudentCO is not Your mortgage loan officer and use of the Services does not constitute the receipt of
              mortgage loan advice. If You have any mortgage questions, please contact a mortgage loan officer (MLO).
            </li>
            <li>
              It is Your sole responsibility to determine that the Services meet Your mortgage loan origination needs and are
              suitable for the purposes for which they are used.
            </li>
            <li>
              You remain solely responsible for complying with all applicable mortgage and other laws.
              It is Your responsibility to check that storage of and access to your Data via the Software and the Website will
              comply with laws applicable to you including any laws requiring you to retain records.
            </li>
          </ol>
        </li>
        <li>
          <b>No warranties:</b>
          <p>PrudentCO gives no warranty about the Services. Without limiting the foregoing, PrudentCO does not warrant
            that the Services will meet Your requirements or that it will be suitable for any particular purpose.
            To avoid doubt, all implied conditions or warranties are excluded in so far as is permitted by law, including
            (without limitation) warranties of merchantability, fitness for purpose, title and non-infringement.
          </p>
        </li>
        <li>
          <b>Consumer guarantees:</b>
          <p>You warrant and represent that You are acquiring the right to access and use the Services for the purposes
            of obtaining or providing a mortgage loan and that, to the maximum extent permitted by law, any statutory
            consumer guarantees or legislation intended to protect non-business consumers in any jurisdiction does not
            apply to the supply of the Services, the Website or these Terms.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <b>Limitation of Liability</b>
      <ol>
        <li>To the maximum extent permitted by law, PrudentCO excludes all liability and responsibility to You (or any
          other person) in contract, tort (including negligence), or otherwise, for any loss (including loss of
          information, Data, profits and savings) or damage resulting, directly or indirectly, from any use of, or reliance on,
          the Service or Website.
        </li>
        <li>If You suffer loss or damage as a result of PrudentCO's negligence or failure to comply with these Terms,
          any claim by You against PrudentCO arising from PrudentCO's negligence or failure will be limited in respect of
          any one incident, or series of connected incidents, to the Access Fees paid by You in the previous 12 months.
        </li>
        <li>
          If You are not satisfied with the Service, Your sole and exclusive remedy is to terminate these Terms in
          accordance with Clause 8.
        </li>
      </ol>
    </li>
    <li>
      <b>Termination</b>
      <ol>
        <li>
          <b>Trial policy</b>
          <p>When You first sign up for access to the Services You can evaluate the Services under the defined trial
            usage conditions, with no obligation to continue to use the Services. If You choose to continue using the Services
            thereafter, You will be billed from the day You first added Your billing details into the Services. If You
            choose not to continue using the Services, You may delete Your organization in the 'Account' section of
            the Services.
          </p>
        </li>
        <li>
          <b>Prepaid Subscriptions</b>
          <p>
            PrudentCO will not provide any refund for any remaining prepaid period for a prepaid Access Fee subscription.
          </p>
        </li>
        <li>
          <b>No-fault termination:</b>
          <p>These Terms will continue for the period covered by the Access Fee paid or payable under clause 3.1. At the
            end of each billing period these Terms will automatically continue for another period of the same duration
            as that period, provided You continue to pay the prescribed Access Fee when due, unless either party
            terminates these Terms by giving notice to the other party at least 30 days before the end of the relevant
            payment period. If You terminate these Terms You shall be liable to pay all relevant Access Fees on a
            pro-rata basis for each day of the then current period up to and including the day of termination of these
            Terms.</p>
        </li>
        <li>
          <b>Breach:</b>
          <p>If You:</p>
          <ol>
            <li>breach any of these Terms (including, without limitation, by non-payment of any Access Fees) and do not
              remedy the breach within 14 days after receiving notice of the breach if the breach is capable of being
              remedied;
            </li>
            <li>breach any of these Terms and the breach is not capable of being remedied (which includes (without
              limitation) any breach of clause 3.4 or any payment of Access Fees that are more than 30 days overdue); or
            </li>
            <li>You or Your business become insolvent or Your business goes into liquidation or has a receiver or
              manager appointed of any of its assets or if You become insolvent, or make any arrangement with Your
              creditors, or become subject to any similar insolvency event in any jurisdiction,
            </li>
          </ol>
          <p>PrudentCO may take any or all of the following actions, at its sole discretion:</p>
          <ol start="4">
            <li>Terminate this Agreement and Your use of the Services and the Website;</li>
            <li>Suspend for any definite or indefinite period of time, Your use of the Services and the Website;</li>
            <li>Suspend or terminate access to all or any Data.</li>
            <li>Take either of the actions in sub-clauses (d), (e) and (f) of this clause 8(4) in respect of any or all
              other persons whom You have authorized to have access to Your information or Data.
            </li>
          </ol>
          <p>For the avoidance of doubt, if payment of any invoice for Access Fees due in relation to any of Your
            Billing Contacts, Billing Plans or any of Your Transactions (as defined at clause 3) is not made in full by
            the relevant due date, PrudentCO may: suspend or terminate Your use of the Service, the authority for all or
            any of Your Transactions to use the Service, or Your rights of access to all or any Data.</p>
        </li>
        <li>
          <b>Accrued Rights:</b>
          <p>Termination of these Terms is without prejudice to any rights and obligations of the parties accrued up to
            and including the date of termination. On termination of this Agreement You will:</p>
          <ol>
            <li>remain liable for any accrued charges and amounts which become due for payment before or after
              termination; and
            </li>
            <li>immediately cease to use the Services and the Website.</li>
          </ol>
        </li>
        <li>
          <b>Expiry or termination:</b>
          <p>Clauses 3.1, 3.7, 4, 5, 6, 7, 8 and 10 survive the expiry or termination of these Terms.</p>
        </li>
      </ol>
    </li>
    <li>
      <b>Help Desk</b>
      <ol>
        <li>
          <b>Technical Problems:</b>
          <p>
            in the case of technical problems You must make all reasonable efforts to investigate and diagnose
            problems before contacting PrudentCO.
            If You still need technical help, please check the support provided online by PrudentCO on the Website or failing that email us
            at
            <a href="mailto:support@prudentco.com">support&#64;prudentco.com</a>.
          </p>
        </li>
        <li>
          <b>Service availability:</b>
          <p>
            Whilst PrudentCO intends that the Services should be available 24 hours a day, seven days a week, it is
            possible that on occasions the Services or Website may be unavailable to permit maintenance or other
            development activity to take place.
          </p>
          <p>
            If for any reason PrudentCO has to interrupt the Services for longer periods than PrudentCO would normally
            expect, PrudentCO will use reasonable endeavours to publish in advance details of such activity on the
            Website.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <b>General</b>
      <ol>
        <li>
          <b>Entire agreement:</b>
          <p>
            These Terms, together with the PrudentCO Privacy Policy and the terms of any other notices or instructions
            given to You under these Terms of Use, supersede and extinguish all prior agreements, representations
            (whether oral or written), and understandings and constitute the entire agreement between You and PrudentCO
            relating to the Services and the other matters dealt with in these Terms.
          </p>
        </li>
        <li>
          <b>Waiver:</b>
          <p>
            If either party waives any breach of these Terms, this will not constitute a waiver of any other breach.
            No waiver will be effective unless made in writing.
          </p>
        </li>
        <li>
          <b>Delays:</b>
          <p>
            Neither party will be liable for any delay or failure in performance of its obligations under these Terms
            if the delay or failure is due to any cause outside its reasonable control. This clause does not apply to
            any obligation to pay money.
          </p>
        </li>
        <li>
          <b>No Assignment:</b>
          <p>
            You may not assign or transfer any rights to any other person without PrudentCO's prior written
            consent.
          </p>
        </li>
        <li>
          <b>Governing Law and Jurisdiction; Dispute Resolution Process:</b>
          <ol>
            <li>Governing Law and Jurisdiction</li>
            <p>
              If You are a resident of the United States of America or the information or Data You are accessing
              using the Services and the Website is that of a person who is a resident in the United States of
              America at the time that You accept these terms then the laws of the State of Colorado, U.S.A. govern
              this Agreement and PrudentCO and You agree that the U.S. Dispute Resolution Process described below
              applies for all disputes arising out of or in connection with this Agreement or in any way relating the
              Service –
              <b>PLEASE READ THE U.S. DISPUTE RESOLUTION PROCESS CAREFULLY AS IT REQUIRES THE USE OF ARBITRATION ON AN
                INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE
                REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
              </b>
            </p>
            <li>U.S. Dispute Resolution Process</li>
            <p>
              <b>PLEASE READ THIS CAREFULLY – IT AFFECTS YOUR RIGHTS.</b>
            </p>
            <p>
              If You are a resident in the United States of America or or the information or Data You are accessing
              using the Services and the Website is that of a person who is a resident in the United States of America
              at the time that You accept these terms, You agree with PrudentCO as follows:
            </p>
            <ol>
              <li>Summary:</li>
              <p>
                Most customer concerns can be resolved quickly and to the customer's satisfaction by emailing our
                customer experience team at support&#64;prudentco.com.
                <b>IN THE UNLIKELY EVENT THAT OUR CUSTOMER EXPERIENCE TEAM IS UNABLE TO RESOLVE A COMPLAINT YOU MAY HAVE
                  TO YOUR SATISFACTION (OR IF WE HAVE NOT BEEN ABLE TO RESOLVE A DISPUTE WE HAVE WITH YOU AFTER
                  ATTEMPTING TO DO SO INFORMALLY), WE EACH AGREE TO RESOLVE THOSE DISPUTES THROUGH BINDING ARBITRATION
                  OR SMALL CLAIMS COURT INSTEAD OF IN COURTS OF GENERAL JURISDICTION</b>. Arbitration is more informal
                than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for
                more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can
                award the same damages and relief that a court can award.
                <b>ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS; YOU AGREE WITH PrudentCO
                  THAT CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED</b>. For any non-frivolous claim that does
                not exceed $10,000, PrudentCO will pay AAA filing, administrative and arbitrator costs. Moreover, in
                arbitration You are entitled to recover attorneys' fees from PrudentCO to at least the same extent as
                You would be in court.
              </p>
              <li>Dispute Resolution Process.</li>
              <p>
                <b>ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE SERVICE OR THIS AGREEMENT WILL BE RESOLVED BY BINDING
                  ARBITRATION, RATHER THAN IN COURT</b>, except that You may assert claims in small claims court if Your
                claims qualify. The Federal Arbitration Act governs the interpretation and enforcement of this
                provision; the arbitrator shall apply Colorado law to all other matters. Notwithstanding anything to
                the contrary, any party to the arbitration may at any time seek injunctions or other forms of equitable
                relief from any court of competent jurisdiction.
                <b>WE EACH AGREE THAT ANY AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND NOT
                  AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. BY ENTERING INTO
                  THIS AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE WITH PrudentCO THAT YOU AND PrudentCO ARE EACH
                  WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE WITH
                  PrudentCO TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU
                  AGREE WITH PrudentCO THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS</b>.
              </p>
              <p>
                To begin an arbitration proceeding, send a letter requesting arbitration and describing Your claim to
                PrudentCO at PO Box 270172, Louisville, CO 80027, Attention: Legal Department.
                Arbitration will be conducted by the American Arbitration Association (AAA) before a single AAA
                arbitrator under the AAA’s rules, which are available at www.adr.org or by calling 1-800-778-7879.
                Payment of all filing, administration and arbitrator fees and costs will be governed by the AAA's rules.
                In addition, PrudentCO will reimburse those fees and costs for claims totaling less than $10,000 unless
                the arbitrator determines the claims are frivolous. Likewise, PrudentCO will not seek its attorneys’
                fees or costs in arbitration unless the arbitrator determines Your claims or defenses are frivolous. You
                agree with PrudentCO that You or PrudentCO may choose to have the arbitration conducted by telephone or
                based on written submissions. You agree with PrudentCO that in person arbitration will be conducted in
                the county in which You reside or at another mutually agreeable location. You agree with PrudentCO that
                the decision of the arbitrator shall be final and not appealable, and judgment on the arbitration award
                may be entered in any court having jurisdiction thereof. You agree with PrudentCO that the U.S. Dispute
                Resolution Process shall survive expiration, termination or rescission of this Agreement.
              </p>
            </ol>
          </ol>
        </li>
        <li>
          <b>Severability:</b>
          <p>If any part or provision of these Terms is invalid, unenforceable or in conflict with the law, that part or
            provision is replaced with a provision which, as far as possible, accomplishes the original purpose of that
            part or provision. The remainder of this Agreement will be binding on the parties.</p>
        </li>
        <li>
          <b>Notices:</b>
          <p>Any notice given under these Terms by either party to the other must be in writing by email and will be
            deemed to have been given on transmission. Notices to PrudentCO must be sent to support&#64;prudentco.com or to
            any other email address notified by email to You by PrudentCO. Notices to You will be sent to the email
            address which You provided when setting up Your access to the Service.</p>
        </li>
        <li>
          <b>Rights of Third Parties:</b>
          <p>A person who is not a party to these Terms has no right to benefit under or to enforce any term of these
            Terms.</p>
        </li>
      </ol>
    </li>
  </ol>
</div>
