import {ModelBase} from './model-base';

export class UserConfiguration extends ModelBase {
  id: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  zip_four: string;
  company_name: string;
  company_nmls: string;
  nmls: string;
  phone_number: string;
  fnma_institution_id: string;
  fnma_institution_name: string;
  compensation_percent: string;
  min_compensation_amount: string;
  max_compensation_amount: string;
  ecoa_address: string;
  company_state_license_number;
  licenses: any;

  constructor(object: any) {
    super();
    if (!object) {
      this.licenses = {};
      return this;
    }
    for (const prop of Object.keys(object)) {
      if (prop === 'phone_number') {
        this[prop] = this.getPhoneProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getCompensationPercent(): number {
    return this.compensation_percent ? +this.compensation_percent / 100 : null;
  }

  getFullAddress() {
    const addr = [this.address, this.address2].filter((elt) => elt && elt.length).join(', ');
    return super.getFullAddress(this.address, this.address2, this.city, this.state, this.zip, this.zip_four);
  }

  getStates(): string {
    if (this.licenses) {
      return Object.keys(this.licenses).map((key) => key).join(', ');
    }
    return '';
  }
}
