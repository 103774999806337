import {Component, Injector, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrgMcrComponent} from '../org-mcr/org-mcr.component';

@Component({
  selector: 'app-view-mcr-info',
  templateUrl: './view-mcr-info.component.html',
})
export class ViewMcrInfoComponent extends OrgMcrComponent implements OnInit {
  @Input() taskID: string;
  public hidden = true;

  constructor(public injector: Injector, private route: ActivatedRoute, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = true;
  }
}
