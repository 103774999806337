<app-box [close]="false" [type]="'warning'" [fadeOut]="false" [hidden]="isHidden">
  <div>
    <span class="fw-bold">Please use landscape!&nbsp;</span>
    The {{componentName || 'Component'}} will not display correctly in portrait orientation.
    Switch to landscape and then click Reset.
  </div>
  <div class="text-center pt-2">
    <button (click)="reset($event)" class="btn btn-warning">Reset</button>
  </div>
</app-box>
