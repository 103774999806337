import {Contact} from './contact';
import {ModelBase} from './model-base';
import {DataService} from '../services/data.service';
import {ProfessionalProfile} from './professional-profile';
import {UserAccount} from "./user-account";

export class User extends ModelBase {
  public static STATUS = {
    ACTIVE: 1,
    INACTIVE: 16, // same as none
    BLOCKED: 128,
    DELETED: 256
  };

  public static userRoles: Array<any> = [
    {
      key: User.ROLES.AUX,
      value: 'Unregistered Account'
    },
    {
      key: User.ROLES.BORROWER,
      value: 'Borrower'
    },
    {
      key: User.ROLES.LOAN_OFFICER,
      value: 'Loan Officer'
    },
    {
      key: User.ROLES.PROCESSOR,
      value: 'Processor'
    },
    {
      key: User.ROLES.REAL_ESTATE_AGENT,
      value: 'Real Estate Agent'
    },
    {
      key: User.ROLES.ADMIN,
      value: 'Admin'
    },
    {
      key: User.ROLES.AGENT,
      value: 'Agent'
    },
    {
      key: User.ROLES.PRESENTER,
      value: 'Presenter'
    },
  ];
  // admin use user statuses to set them in auth server
  public static userStatuses: Array<any> = [
    {
      key: User.STATUS.ACTIVE,
      value: 'Active'
    },
    {
      key: User.STATUS.INACTIVE,
      value: 'Inactive'
    },
    {
      key: User.STATUS.BLOCKED,
      value: 'Blocked'
    },
    {
      key: User.STATUS.DELETED,
      value: 'Deleted'
    },
  ];

  // admin use user statuses to set them in auth server
  public static userServices: Array<any> = [
    {
      key: User.SERVICES.NONE,
      value: 'None'
    },
    {
      key: User.SERVICES.EZMCR,
      value: 'MCR'
    },
    {
      key: User.SERVICES.EZUPLOADS,
      value: 'ezUploads'
    },
    {
      key: User.SERVICES.POLOS,
      value: 'Loans'
    },
  ];

  id: number;
  organization_id: number;
  organization_role: number;
  index: number;
  prefix: string;
  first: string;
  middle: string;
  last: string;
  suffix: string;
  email: string;
  image_path: string;
  logo_path: string;
  is_attached_image: boolean;
  thumbnail: string;
  cell_phone: string;
  mobile_carrier_name: string;
  roles: number;
  user_configuration: any;
  professional_profile: any;
  user_account: UserAccount;
  contacts: Array<Contact>;
  full_name: string; // read only
  mcr_ids: Array<number>;
  services: number;
  created_at: Date;
  updated_at: Date;
  // generated properties
  mobileCarrier: string;
  nmls: string;
  licenses: Array<any>;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'contacts') {
        this.contacts = Contact.deserializeArray(object[prop]);
      } else if (prop === 'cell_phone') {
        this[prop] = this.getPhoneProp(prop, object);
      } else if (prop === 'professional_profile' && !ModelBase.isEmpty(object[prop])) {
        this[prop] = new ProfessionalProfile(object[prop]);
      } else if (prop === 'user_account' && !ModelBase.isEmpty(object[prop])) {
        this[prop] = new UserAccount(object[prop]);
      } else if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
    if (this.mobile_carrier_name) {
      const mobileCarrierEntry = DataService.getCarriers().find(((elt) => elt.key === this.mobile_carrier_name));
      this.mobileCarrier = mobileCarrierEntry ? mobileCarrierEntry.value : null;
    } else {
      this.mobileCarrier = null;
    }
  }

  static deserializeArray(arr: Array<any>): Array<User> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new User(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  hasMCRService(): boolean {
    return ModelBase.hasMCRService(this.services);
  }

  hasNoService(): boolean {
    return ModelBase.hasNoService(this.services);
  }

  hasUploadsService(): boolean {
    return ModelBase.hasEzUploadsService(this.services);
  }

  hasAncillaryService(): boolean {
    return ModelBase.hasAncillaryService(this.services);
  }

  hasBorrowerService(): boolean {
    return ModelBase.hasBorrowerService(this.services);
  }

  hasPOLOSService(): boolean {
    return ModelBase.hasPOLOSSErvice(this.services);
  }

  isAdmin(): boolean {
    return ModelBase.isAdmin(this.roles);
  }

  isLoanOfficer(): boolean {
    return ModelBase.isLoanOfficer(this.roles);
  }

  isLoanAssistant(): boolean {
    return ModelBase.isLoanAssistant(this.roles);
  }

  isProcessor(): boolean {
    return ModelBase.isProcessor(this.roles);
  }

  isLoanProfessional(): boolean {
    return ModelBase.isLoanProfessional(this.roles);
  }

  canAccessDirectory(): boolean {
    return ModelBase.isLoanOfficer(this.roles) || ModelBase.isProcessor(this.roles);
  }

  isBorrower(): boolean {
    return ModelBase.isBorrower(this.roles);
  }

  isAuxiliary(): boolean {
    return (this.roles & User.ROLES.AUX) > 0;
  }

  isAgent(): boolean {
    return ModelBase.isAgent(this.roles);
  }

  isPresenter(): boolean {
    return ModelBase.isPresenter(this.roles);
  }

  isOther(): boolean {
    return ModelBase.isOther(this.roles);
  }

  isRealEstateAgent(): boolean {
    return ModelBase.isRealEstateAgent(this.roles);
  }

  isOrgAdmin(): boolean {
    return (this.organization_role & User.ORG_ROLES.ADMIN) > 0;
  }

  getNmls(): string {
    if (this.user_configuration) {
      return this.user_configuration.nmls;
    }
    return null;
  }

  getCompanyNmls(): string {
    if (this.user_configuration) {
      return this.user_configuration.company_nmls;
    }
    return null;
  }

  getStatesString(): string {
    if (this.user_configuration) {
      return Object.keys(this.user_configuration.licenses).join(', ');
    }
    return null;
  }

  getContactInfos(roles: number): Array<any> {
    return this.contacts.filter((elt) => (elt.roles & roles) > 0)
      .map((elt) => {
        return {
          key: elt.id,
          value: elt.toFullNameAndEmail(),
          roles: elt.roles
        };
      });
  }

  getContactById(contactId: number): Contact {
    return this.contacts.find((elt) => {
      return elt.id === contactId;
    });
  }

  getRolesString(): string {
    return ModelBase.getRolesString(this.roles);
  }

  getServicesString(): string {
    return ModelBase.getServicesString(this.services);
  }

  getServicesArray(): Array<string> {
    return ModelBase.getServicesArray(this.services);
  }

  getMissingServices(): Array<string> {
    return ModelBase.getMissingServices(this.services);
  }

  isImagePath(): boolean {
    return this.image_path && (this.image_path.length > 10);
  }

  getImagePath(): string {
    return this.getAbsolutePath(this.image_path);
  }

  isLogoPath(): boolean {
    return this.logo_path && (this.logo_path.length > 10);
  }

  getLogoPath(): string {
    return this.getAbsolutePath(this.logo_path);
  }

  getThumbnailPath(): string {
    return this.getAbsolutePath(this.thumbnail);
  }

  getOrgRoleString(): string {
    if (this.organization_role === 1) {
      return 'Admin';
    }
    return 'User';
  }

  getStates(): string {
    if (this.licenses && Array.isArray(this.licenses)) {
      return this.licenses.map((elt) => elt.state).join(', ');
    }
    return '';
  }

  hasOrg(): boolean {
    return this.organization_id && this.organization_id > 0;
  }
}
