<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Other New Mortgages'" [help]="true" [op]="'add'">
        The purpose of this segment is to provide information about
        <span class="fw-bold">other</span> new mortgage loans on the property.
        <ul>
          <li>
            An example of new mortgage is a second mortgage that the borrower takes as a lump sum or
            revolving line of home equity credit.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-new-mortgage (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [doc]="doc"></app-add-new-mortgage>

    <app-box [close]="true" [type]="'primary'" class="pt-2">
      This segment corresponds to Section 4b. of the New URLA: "Other New Mortgage Loans on the Property You are Buying or Refinancing."
    </app-box>

    <div *ngFor="let item of items">
      <app-view-new-mortgage (eventEmitted)="onEvent($event)" [doc]="doc" [item]="item"></app-view-new-mortgage>
    </div>
  </mat-card>
</div>
