import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrderComponent} from "../order/order.component";
import {OrderLineItem} from "../../../../models/order-line-item";
import moment from 'moment';

@Component({
  selector: 'app-finalize-order',
  templateUrl: './finalize-order.component.html',
})
export class FinalizeOrderComponent extends OrderComponent implements OnInit {
  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.op = 'Modify';
  }

  ngOnInit() {
    super.ngOnInit();
    const payload = {
      order_type: OrderLineItem.ORDER_TYPE.SUBSCRIPTION,
      services: this.user.services,
      starts_on: moment().format('YYYY-MM-DD'),
      subscription_term: null
    }
    this.createFormControls(payload);
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
