import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../comp-base.component';
import {DashboardService} from '../../../services/dashboard.service';
import moment from 'moment';

// Comment: ideas for colors taken from compliance
// Not applicable - 0
// Done - green (1)
// Outstanding - light yellow (2)
// Due today - yellow (3)
// Done but not compliant (too early or too late) - pink (4)
// Missed - red (5)
@Component({
  selector: 'app-messages-dashboard',
  templateUrl: './messages-dashboard.component.html',
})
export class MessagesDashboardComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public messages: Array<any>;
  public dashboardService: DashboardService;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dashboardService = this.injector.get(DashboardService);
    this.reload(false);
    this.reloadOnSync();
  }

  reload(force: boolean): void {
    this.reloaded = false;
    this.dashboardService.getMessagesDashboard(force)
      .then((data) => {
        this.messages = data;
        data.forEach((tranMessages) => this.processTranMessages(tranMessages));
        this.reloaded = true;
      });
  }

  navigateToTranId(event, tranId: number): void {
    event.preventDefault();
    this.tranService.getTran(tranId)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'dashboard'});
      })
      .then(() => {
        this.router.navigate(['/loan/view-messages']);
        return;
      });
  }

  processTranMessages(tranMessages: any): void {
    const userId = this.userService.getUserSync().id;
    const messages = tranMessages.messages;
    const countOne = messages.filter((elt) => (elt.to_user_id === userId || elt.from_user_id === userId) && moment(elt.created_at) >= moment().subtract(1, 'days')).length;
    const countSeven = messages.filter((elt) => (elt.to_user_id === userId || elt.from_user_id === userId) && moment(elt.created_at) >= moment().subtract(7, 'days')).length;
    const countAll = messages.filter((elt) => (elt.to_user_id === userId || elt.from_user_id === userId)).length;
    tranMessages['counts'] = [countOne, countSeven, countAll];
  }
}
