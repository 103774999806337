import {Component, Injector, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {Mcr} from '../../../../models/mcr';
import {OrgMcrComponent} from '../org-mcr/org-mcr.component';
import {FormUtil} from '../../../form-util';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {globals} from '../../../../shared/globals/globals';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-org-mcr-report',
  templateUrl: './org-mcr-report.component.html',
})
export class OrgMcrReportComponent extends OrgMcrComponent implements OnInit {
  @Input() mcr: Mcr;
  public pageTitle = 'Organization MCR';
  public mcrTitle: string;
  public isReport: boolean;

  public form: FormGroup;
  public state: FormControl;

  constructor(public injector: Injector,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.mcrTitle = `Review MCR for Q${this.mcr.quarter} ${this.mcr.year}`;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansList
      });
    }, 0);
    this.isReport = false;
  }

  onEvent(event: any) {
    if (event.type === 'Close') {
      this.isReport = false;
      return;
    }
    super.onEvent(event);
  }

  createFormControls(): void {
    this.state = new FormControl(this.mcr.state, Validators.pattern(globals.pattern.state));
  }

  createForm(): void {
    this.form = new FormGroup({
      state: this.state
    });
  }

  closeReport(event: any): void {
    event.preventDefault();
    this.isReport = false;
  }

  genOrgReport(): void {
    const state = this.state.value;
    this.mcrService.genOrgMcrReport(this.mcr.id, state)
      .then((mcr) => {
        this.mcr = mcr;
        this.isReport = true;
        FormUtil.genSuccessMessage(this.sharedService, 'Organization MCR report successfully generated');
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Organization MCR report generation failed', data);
      });
  }
}
