import {Component} from '@angular/core';

@Component({
  // moduleId: module.id,
  // tslint:disable-next-line
  selector: 'app-pages',
  templateUrl: 'extra.component.html',
  styleUrls: ['extra.component.scss']
})
export class ExtraLayoutComponent {
  rtl: boolean;

  constructor() {
    this.rtl = false;
  }
}
