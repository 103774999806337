import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminTranComponent} from '../admin-tran.component';
import {Help} from '../../../../models/help';
import {FormUtil} from '../../../form-util';
import {Tran} from '../../../../models/tran';

@Component({
  selector: 'app-edit-tran',
  templateUrl: '../admin-edit-tran.component.html',
})
export class AdminEditTranComponent extends AdminTranComponent implements OnInit {
  pageTitle = 'Edit Transaction';
  form: FormGroup;
  first: FormControl;
  last: FormControl;
  loanOfficer: FormControl;
  tran: Tran;
  loanOfficers: Array<any> = [];

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    this.op = 'Find';
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.route.paramMap.subscribe((map) => {
      this.tranId = map['params']['id'];
      this.reload();
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.adminService.findTranById(this.tranId)
      .then((tran) => {
        this.tran = tran;
        this.createFormControls();
        this.createForm();
        this.reloaded = true;
      });
  }

  createFormControls() {
    this.first = new FormControl('');
    this.last = new FormControl('');
    this.loanOfficer = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      first: this.first,
      last: this.last,
      loanOfficer: this.loanOfficer,
    });
  }

  cancel(): void {
    this.router.navigate(['/admin/view-tran', this.tran.id]);
  }

  findLoanOfficers(): void {
    if (!this.form.valid) {
      return;
    }
    this.adminService.findLoanOfficer(this.first.value, this.last.value)
      .then((users) => {
        this.loanOfficers = [];
        FormUtil.genSuccessMessage(this.sharedService, `${users.length} Loan Officer(s) found`);
        users.forEach((elt) => {
          this.loanOfficers.push({key: elt.id, value: `Name: ${elt.full_name} ID: ${elt.id} Email: ${elt.email}`});
        });
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to find loan officers', data);
      });
  }

  grantLOAccess(): void {
    if (!this.tran.id || !this.loanOfficer.value) {
      FormUtil.genErrorMessage(this.sharedService, 'Either transaction or loan officer is missing', null);
      return;
    }
    const payload = Object.assign({}, this.form.value);
    payload.id = this.tran.id;
    this.adminService.grantLOAccess(this.tran.id, this.loanOfficer.value)
      .then((tran) => {
        this.tran = tran;
        FormUtil.genSuccessMessage(this.sharedService, `Loan Officer access was granted successfully`);
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to grant Loan Officer access', data);
      });
  }
}
