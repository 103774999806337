import {ModelBase} from './model-base';

export class Military extends ModelBase {
  service: boolean;
  active_duty: boolean;
  veteran: boolean;
  never_activated: boolean;
  surviving: boolean;
  expiration_date: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'expiration_date') {
        this[prop] = this.getDateProp(prop, object);
      } else if (prop === 'service' || prop === 'active_duty'
        || prop === 'veteran' || prop === 'never_activated' || prop === 'surviving') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  toServerPayload(): any {
    return {
      service: ModelBase.toServerBoolean(this.service),
      active_duty: ModelBase.toServerBoolean(this.active_duty),
      veteran: ModelBase.toServerBoolean(this.veteran),
      never_activated: ModelBase.toServerBoolean(this.never_activated),
      surviving: ModelBase.toServerBoolean(this.surviving),
      expiration_date: this.toServerDate(this.expiration_date)
    };
  }
}
