import {AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-add-loan-app',
  templateUrl: './add-loan-app.component.html',
})
export class AddLoanAppComponent extends CompBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('nameElt') nameElt: ElementRef;
  form: FormGroup;
  name: FormControl;
  is_default: FormControl;
  isLoanApps: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isLoanApps = !!this.tran.getLoanApps();
    this.reset();
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  reset(): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
      this.setFocus();
    }, 0);
  }

  createFormControls() {
    this.is_default = new FormControl(!this.isLoanApps);
    this.name = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(3)]));
  }

  createForm() {
    this.form = new FormGroup({
      is_default: this.is_default,
      name: this.name,
    });
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }

    const is_default = !!this.is_default.value ? 0 : 1;
    const payload = {
      category: is_default,
      dtype: 64,
      name: this.name.value,
      tran_id: this.tran.id,
      transaction_item_id: 'LA01'
    };

    this.docService.createDocument(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application successfully created');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create loan application', data);
        this.reset();
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  setFocus() {
    setTimeout(() => {
      if (this.nameElt) {
        this.nameElt.nativeElement.focus();
      }
    }, 0);
  }
}
