import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {Mcr} from '../../../../models/mcr';

@Component({
  selector: 'app-view-lo-mcr',
  templateUrl: './view-lo-mcr.component.html',
})
export class ViewLoMcrComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public mcr: Mcr;
  public mcrId: number;
  public isError: boolean;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mcrId = this.mcrService.getCurrentLoMcrId();
    this.isError = !this.mcrId;
    this.reload(true);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(true);
      return;
    }
    super.onEvent(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    if (!this.mcrId) {
      this.isError = true;
      this.router.navigate(['/loans/lo-mcr/list']);
      return;
    }
    this.mcrService.getMcr(this.mcrId)
      .then((mcr) => {
        this.mcr = mcr;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
