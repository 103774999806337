import {Component, Injector, OnInit} from '@angular/core';
import {LoInformation} from '../../../../models/lo-information';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-lo-information',
  templateUrl: './view-lo-information.component.html',
})
export class ViewLoInformationComponent extends CompBaseComponent implements OnInit {
  public item: LoInformation;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    if (this.doc) {
      this.item = new LoInformation(this.doc.recursive_attributes['lo_information']);
      this.mode = 'View';
    }
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
