<mat-card class="mt-1 mb-1" *ngIf="reloaded&&!error">
  <mat-card-title>Authorize And Pay
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Amount</div>
      <div class="col-lg-8">{{cc.amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Credit card number</div>
      <div class="col-lg-8">{{cc.cc_number}}</div>
    </div>

    <div class="row pt-1 pb-3 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Credit card expiration month</div>
      <div class="col-lg-8">{{cc.cc_exp_month}}</div>
    </div>

    <div class="row pt-1 pb-3 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Credit card expiration year</div>
      <div class="col-lg-8">{{cc.cc_exp_year}}</div>
    </div>

    <div class="row pt-1 pb-3 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Credit card code (CVV)</div>
      <div class="col-lg-8">{{cc.cc_code}}</div>
    </div>

    <form [formGroup]="form" novalidate>
      <div class="row mt-2">
        <div class="col">
          <button (click)="authorizeAndPay()" class="btn btn-lg btn-primary">Authorize and Pay</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="mt-1 mb-1" *ngIf="reloaded&&error">
  <mat-card-title>Pay Invoice</mat-card-title>

  <mat-card-content>
    <app-box [close]="true" [hidden] [type]="'danger'" [fadeOut]="false">
      Do not refresh the browser after entering your credit card information!
      <a href="/account/invoices">Go back to Invoices</a>
    </app-box>
  </mat-card-content>
</mat-card>
