<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Services
  </mat-card-title>

  <mat-card-content>
    <p>
      This page describes your services.
    </p>
  </mat-card-content>
</mat-card>
