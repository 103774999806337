import {ModelBase} from './model-base';

// comment: product plan numbers applicable only for ARM loans
export class ProductIdentification extends ModelBase {
  public static product_plan_numbers: Array<any> = [
    {key: 'GEN06', value: 'GEN06 (6 Month)'},
    {key: 'GEN1A', value: 'GEN1A (1 Year, 1% Annual Cap)'},
    {key: 'GEN1B', value: 'GEN1B (1 Year, 2% Annual Cap)'},
    {key: 'GEN3', value: 'GEN3 (3 Year)'},
    {key: 'GEN5', value: 'GEN5 (5 Year)'},
    {key: 'GEN7', value: 'GEN7 (7 Year)'},
    {key: 'GEN10', value: 'GEN10 (10 Year)'},
    {key: '251', value: '251 (FHA 1 Year)'},
    {key: 'FHAHY', value: 'FHAHY (FHA Hybrid ARM)'},
    {key: 'VA1YR', value: 'VA1YR (VA 1 Year)'},
  ];

  product_description: string; // 30 chars
  product_plan_number: string; // 5 chars

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }

  getProductPlanNumber(): string {
    const found = ProductIdentification.product_plan_numbers.find((elt) => elt.key === this.product_plan_number);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      product_description: this.product_description,
      product_plan_number: this.product_plan_number,
    };
  }
}
