import {Component, EventEmitter, HostBinding, Injector, OnInit, Output} from '@angular/core';
import {StickyNoteComponent} from '../sticky-note/sticky-note.component';

@Component({
  selector: 'app-view-sticky-note',
  templateUrl: './view-sticky-note.component.html',
})
export class ViewStickyNoteComponent extends StickyNoteComponent implements OnInit {
  @HostBinding('class.w-100') stickyNoteClass = true;
  @Output() eventEmitted = new EventEmitter();

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
