import {Component, Injector, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {SharedService} from '../../../layouts/shared-service';

@Component({
  selector: 'app-view-org-instructions',
  templateUrl: './view-org-instructions.component.html',
})
export class ViewOrgInstructionsComponent implements OnInit {
  public pageTitle = 'MCR Instructions';

  constructor(public injector: Injector, private sharedService: SharedService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansList
      });
    }, 0);
  }
}
