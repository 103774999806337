import {Component, Injector, Input, OnInit} from '@angular/core';
import {PropertyInformation} from '../../../../models/property-information';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-property-information',
  templateUrl: './view-segment-property-information.component.html',
})
export class ViewSegmentPropertyInformationComponent extends CompBaseComponent implements OnInit {
  @Input() item: PropertyInformation;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'property_information';
    this.section = 'loan_information';
  }
}
