<mat-card class="mb-2">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="title" [help]="false" [op]="'add'">
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <app-add-permission (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'" [resource]="resource" [resource_type]="resource_type" [tran]="tran"></app-add-permission>

    <div *ngFor="let item of items">
      <app-view-permission (eventEmitted)="onEvent($event)" [item]="item" [resource]="resource" [resource_type]="resource_type" [tran]="tran"></app-view-permission>
    </div>
  </mat-card-content>
</mat-card>
