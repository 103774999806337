import {ModelBase} from './model-base';

export class Payment extends ModelBase {
  static deserializeArray(arr: Array<any>): Array<Payment> {
    const result = [];
    for (const obj of arr) {
      const item = new Payment(obj);
      result.push(item);
    }
    return result;
  }

  id: number;
  order_id: number;
  authnet_transaction_id: string;
  amount: string;
  paid_on: string;
  error_no: number;
  error_description: string;
  created_at: Date;
  updated_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else if (prop === 'paid') {
        this[prop] = super.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }
}
