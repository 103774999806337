import {Component, Injector, OnInit} from '@angular/core';
import {DeclarationExplanationComponent} from '../declaration-explanation/declaration-explanation.component';

@Component({
  selector: 'app-edit-segment-declaration-explanation',
  templateUrl: '../declaration-explanation/edit-segment-declaration-explanation.component.html',
})
export class EditSegmentDeclarationExplanationComponent extends DeclarationExplanationComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
