import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Military} from '../../../../models/military';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-military',
  templateUrl: './edit-segment-military.component.html',
})
export class EditSegmentMilitaryComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Military;

  // form and form controls
  public form: FormGroup;
  public service: FormControl;
  public active_duty: FormControl;
  public veteran: FormControl;
  public never_activated: FormControl;
  public surviving: FormControl;
  public expiration_date: FormControl;
  public minDate = new Date();

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'military';
    this.section = this.applicant;
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.service = new FormControl(item.service);
    this.active_duty = new FormControl(item.active_duty);
    this.veteran = new FormControl(item.veteran);
    this.never_activated = new FormControl(item.never_activated);
    this.surviving = new FormControl(item.surviving);
    this.expiration_date = new FormControl(item.expiration_date);
  }

  createForm(): void {
    this.form = new FormGroup({
      service: this.service,
      active_duty: this.active_duty,
      veteran: this.veteran,
      never_activated: this.never_activated,
      surviving: this.surviving,
      expiration_date: this.expiration_date,
    });
  }

  onChange(event: any, val: string): void {
    let boolValue: boolean;
    if (typeof (event.value) === 'boolean') {
      boolValue = event.value;
    } else if (typeof (event.value) === 'string') {
      if (event.value === 'true') {
        boolValue = true;
      } else if (event.value === 'false') {
        boolValue = true;
      } else {
        boolValue = null;
      }
    } else {
      boolValue = null;
    }
    this[val].setValue(boolValue);
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Military(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
