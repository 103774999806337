import {Component, Injector, Input, OnInit} from '@angular/core';
import {Condition} from '../../../models/condition';
import {ConditionComponent} from '../condition/condition.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-segment-condition',
  templateUrl: './view-segment-condition.component.html',
})
export class ViewSegmentConditionComponent extends ConditionComponent implements OnInit {
  @Input() item: Condition;
  public commentsDisplay;

  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.displayComments();
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Edit') {
      this.router.navigate(['loan/edit-condition', this.item.id]);
      return;
    }

    super.onEvent(event);
  }

  toggleComments(event: any): void {
    event.preventDefault();
    this.displayComments();
  }

  displayComments(): void {
    this.commentsDisplay = this.item.comments;
  }
}
