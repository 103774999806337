<div *ngIf="reloaded">
  <ul class="nav nav-tabs" id="dossier_tabs">
    <li class="nav-item">
      <a (click)="setSection($event,section.loanRecord)" [ngClass]="{active:currentSection===section.loanRecord}"
        [attr.aria-selected]="currentSection===section.loanRecord" class="nav-link" data-toggle="tab" id="loan_record_tab">
        Loan Record
      </a>
    </li>

    <li [hidden]="!canUpdateLoanApps" class="nav-item">
      <a (click)="setSection($event,section.loanApps)" [ngClass]="{active:currentSection===section.loanApps}"
        [attr.aria-selected]="currentSection===section.loanApps" class="nav-link" data-toggle="tab" id="loan_apps_tab">
        Loan Applications
      </a>
    </li>

    <li *ngIf="canReadDocs" class="nav-item">
      <a (click)="setSection($event,section.docs)" [ngClass]="{active:currentSection===section.docs}"
        [attr.aria-selected]="currentSection===section.docs" class="nav-link" data-toggle="tab" id="docs_tab">
        {{isMobile ? 'Docs' : 'Documents'}}
      </a>
    </li>

    <li *ngIf="canGenDocs" class="nav-item">
      <a (click)="setSection($event,section.generateDocs)" [ngClass]="{active:currentSection===section.generateDocs}"
        [attr.aria-selected]="currentSection===section.generateDocs" class="nav-link" data-toggle="tab" id="generate_docs_tab">
        {{isMobile ? 'Gen Docs' : 'Generate Documents'}}
      </a>
    </li>

    <!-- Hide Copy Documents until we are ready to support this feature-->
    <li *ngIf="false&&isDebug&&canCopyDocs" class="nav-item">
      <a (click)="setSection($event,section.copyDocs)" [ngClass]="{active:currentSection===section.copyDocs}"
        [attr.aria-selected]="currentSection===section.copyDocs" class="nav-link" data-toggle="tab" id="copy_docs_tab">
        {{isMobile ? 'Copy Docs' : 'Copy Documents'}}
      </a>
    </li>

    <li *ngIf="canReadZip" class="nav-item">
      <a (click)="setSection($event,section.util)" [ngClass]="{active:currentSection===section.util}"
        [attr.aria-selected]="currentSection===section.util" class="nav-link" data-toggle="tab" id="util_tab">
        {{isMobile ? 'Download Zip' : 'Download Zip'}}
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':currentSection===section.loanRecord}" aria-labelledby="loan_record_tab" class="tab-pane fade" id="loan_record" role="tabpanel">
      <app-view-loan (eventEmitted)="onEvent($event)" [tran]="tran"></app-view-loan>
    </div>

    <div [ngClass]="{'show active':currentSection===section.loanApps}" aria-labelledby="loan_apps_tab" class="tab-pane fade" id="loan_apps" role="tabpanel">
      <app-view-loan-apps (eventEmitted)="onEvent($event)" [loanApps]="loanApps" [tran]="tran"></app-view-loan-apps>
    </div>

    <div [ngClass]="{'show active':currentSection===section.docs}" aria-labelledby="docs_tab" class="tab-pane fade" id="docs" role="tabpanel">
      <app-view-document-system (eventEmitted)="onEvent($event)" [tran]="tran"></app-view-document-system>
    </div>

    <div [ngClass]="{'show active':currentSection===section.generateDocs}" aria-labelledby="generate_docs_tab" class="tab-pane fade" id="generate_docs" role="tabpanel">
      <app-generate-document (eventEmitted)="onEvent($event)" [tran]="tran"></app-generate-document>
    </div>

    <div [ngClass]="{'show active':currentSection===section.copyDocs}" aria-labelledby="copy_docs_tab" class="tab-pane fade" id="copy_docs" role="tabpanel">
      <app-view-sandbox-tran (eventEmitted)="onEvent($event)" [tran]="tran"></app-view-sandbox-tran>
    </div>

    <div [ngClass]="{'show active':currentSection===section.util}" aria-labelledby="util_tab" class="tab-pane fade" id="util" role="tabpanel">
      <app-view-util (eventEmitted)="onEvent($event)" [tran]="tran"></app-view-util>
    </div>
  </div>
</div>
