import {Component, Injector, OnInit} from '@angular/core';
import {HelpBaseComponent} from '../../../help-base.component';

@Component({
  selector: 'app-help-account-services',
  templateUrl: './help-account-services.component.html',
})
export class HelpAccountServicesComponent extends HelpBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
