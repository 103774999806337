<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Messages</mat-card-title>

  <mat-card-content>
    <h5>New Message</h5>

    <p>
      You can only send messages to contacts.
      If the message recipient configured a cell-phone, you can also send the message as a text-message (SMS).
    </p>

    <strong>To send a new message:</strong>

    <ul>
      <li>Click the Plus icon next to the Messages header.</li>
      <li>Select a recipient (or recipients).</li>
      <li>Compose a short message.</li>
      <li>If the recipient enabled text-messaging, a checkbox will display.</li>
      <li>Check the checkbox if you want to send the message as a text message.</li>
      <li>Click Send.</li>
    </ul>

    <h5>Received and Sent Messages</h5>

    <p>
      If a message is long, there is a "more" link which displays the entire message.
    </p>

    <strong>Delete a Message:</strong>
    <p>
      To delete a message, Click the trash icon.
    </p>
  </mat-card-content>
</mat-card>
