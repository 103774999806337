<div class="row">
  <div class="col-lg-6">
    <mat-card class="mt-1 mb-1 h-100">
      <mat-card-title>Reset Password</mat-card-title>
      <mat-card-content>
        <form *ngIf="user" [formGroup]="form" novalidate>
          <mat-form-field class="md-icon-left w-100">
            <mat-label>New password</mat-label>
            <input formControlName="password" matInput placeholder="New password" type="password" autocomplete="new-password" maxlength="{{MAX_LEN.POLOS_PW}}" appAutoFocus required>
            <mat-error *ngIf="password.hasError('required')">
              Password is required
            </mat-error>
            <mat-error *ngIf="password.hasError('minlength')">
              Password is too short
            </mat-error>
          </mat-form-field>

          <mat-form-field class="md-icon-left w-100">
            <mat-label>Password confirmation</mat-label>
            <input formControlName="password_confirmation" matInput placeholder="Password confirmation" type="password" autocomplete="new-password" maxlength="{{MAX_LEN.POLOS_PW}}" required>
            <mat-error *ngIf="password_confirmation.hasError('required')">
              Password confirmation is required
            </mat-error>
            <mat-error *ngIf="password_confirmation.hasError('minlength')">
              Password confirmation is too short
            </mat-error>
          </mat-form-field>

          <div class="row mt-2">
            <div class="col">
              <button (click)="updatePassword()" class="btn btn-lg btn-primary" [disabled]="!form.valid">Update</button>
              <button (click)="resetUpdatePassword()" class="ms-2 btn btn-lg btn-light">Reset</button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-6">
    <mat-card class="mt-1 mb-1 h-100">
      <mat-card-title>Password Rules</mat-card-title>
      <mat-card-content>
        Password must
        <ol>
          <li>be between 6 and 32 characters long,</li>
          <li>contain at least one lowercase letter,
          </li>
          <li>contain at least one uppercase letter,
          </li>
          <li>contain one numeric character (e.g., 123),
          </li>
          <li>contain one special character (e.g., !&#64;#),
          </li>
          <li>not contain any white space.</li>
        </ol>
      </mat-card-content>
    </mat-card>
  </div>
</div>
