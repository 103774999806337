import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {User} from '../../../../models/user';
import {AdminService} from '../../../../services/admin.service';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-view-user-list',
  templateUrl: './view-user-list.component.html',
})
export class ViewUserListComponent extends CompBaseComponent implements OnInit {
  public adminService: AdminService;
  public users: Array<User>;
  public pageTitle = 'User List';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.findUsers(params);
    });
  }

  findUsers(params): void {
    this.reloaded = false;
    this.adminService.findUser(params['first'], params['last'])
      .then((users) => this.users = users)
      .then(() => this.reloaded = true);
  }

  editUser(event: any, user: User): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-user', user.id]);
  }
}
