import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'yearQuarter'
})
export class YearQuarterPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    if (!value) {
      return null;
    }
    const year = Math.floor(value / 4);
    const quarter = value % 4 + 1;
    return `Q${quarter} ${year} `;
  }
}
