<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="getSectionTitle()" [help]="true" [op]="'edit'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            In Configure Fee Worksheet you can configure values which affect closing cost estimates and monthly payments.
            Values configured and saved here will appear in the subsequently generated PDF titled "Broker Fee Worksheet".
            Where possible, values you have already added to the loan application will be shown initially as suggested values,
            but you must save each form for its configured values to appear in the Broker Fee Worksheet PDF.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <mat-card-content>
      <div *ngFor="let cc of savedDoc[section]">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">{{cc['item_desc']}}</div>
          <div *ngIf="cc['type']==='string'" class="col-lg-6">{{cc['value']}}</div>
          <div *ngIf="cc['type']==='integer'" class="col-lg-6">{{cc['value']}}</div>
          <div *ngIf="cc['type']==='date'" class="col-lg-6">{{cc['value']}}</div>
          <div *ngIf="cc['type']==='boolean'" class="col-lg-6">{{cc['value']}}</div>
          <div *ngIf="cc['type']==='percent'" class="col-lg-6">{{cc['value'] ? (cc['value']/100.00 | percent:'1.3-3') : '' }}</div>
          <div *ngIf="cc['type']==='currency'" class="col-lg-6">{{cc['value'] | currency}}</div>
        </div>
      </div>
      <app-box *ngIf="!savedDoc[section] || savedDoc[section].length === 0" [close]="false" [type]="'warning'" class="pt-2">
        The
        <span class="fw-bold">{{getSectionTitle()}}</span> tab has no entries.
        Please, update this tab to provide values for its fields.
      </app-box>
    </mat-card-content>
  </mat-card>
</div>
