<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Loan Characteristics'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <span class="fw-bold">FYI.</span> Loan characteristics factors in Fannie Mae loan eligibility.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Lien type</div>
      <div class="col-lg-6">{{item.getLienTypeCode()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this a balloon loan?</div>
      <div class="col-lg-6" *ngIf="item.balloon_indicator!==null">{{item.balloon_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.balloon_indicator===null">(no response)</div>
    </div>

    <div *ngIf="item.balloon_indicator===true" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Balloon payment amount</div>
      <div class="col-lg-6">{{item.balloon_payment_amount | currency}}</div>
    </div>

    <div *ngIf="item.balloon_indicator===true">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Maturity period type</div>
        <div class="col-lg-6">{{item.getMaturityType()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Maturity period count</div>
        <div class="col-lg-6">{{item.maturity_periods_no}}</div>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is homebuyer education completed?</div>
      <div class="col-lg-6" *ngIf="item.homebuyer_education_completion_indicator!==null">{{item.homebuyer_education_completion_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.homebuyer_education_completion_indicator==null"> (no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="isFixedRateLoan">
      <div class="col-lg-6 fw-bold">Maximum life rate increase</div>
      <div class="col-lg-6">{{item.getMaximumLifetimeRateIncrease() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">MI coverage percent</div>
      <div class="col-lg-6">{{item.getMICoveragePercent() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">APR spread</div>
      <div class="col-lg-6">{{item.getAPRSpread() | percent:'1.2-2'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this loan covered by HOEPA?</div>
      <div class="col-lg-6" *ngIf="item.hoepa_indicator!==null">{{item.hoepa_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.hoepa_indicator===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this a reverse loan?</div>
      <div class="col-lg-6" *ngIf="item.is_reverse!==null">{{item.is_reverse | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.is_reverse===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Qualified Mortgage type</div>
      <div class="col-lg-6">{{item.getQMType()}}</div>
    </div>

  </mat-card-content>
</mat-card>
