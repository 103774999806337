import {Component, Injector, OnInit} from '@angular/core';
import {NoteComponent} from '../note/note.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Note} from '../../../models/note';

@Component({
  selector: 'app-edit-segment-note',
  templateUrl: '../note/edit-segment-note.component.html',
})
export class EditSegmentNoteComponent extends NoteComponent implements OnInit {
  public op = 'Edit';
  public noteId: number;
  public note: Note;

  constructor(public injector: Injector, private route: ActivatedRoute, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.route.paramMap.subscribe((map) => {
      this.noteId = map['params']['id'];
      this.noteService.getPostById(this.noteId)
        .then((note) => {
          this.note = note;
          this.createFormControls(this.note);
          this.createForm();
          this.reloaded = true;
        });
    });
  }
}
