<mat-card *ngIf="mode=='View'" class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Creditor name</div>
      <div class="col-lg-6">{{item.creditor_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.first_lien === true">
      <div class="col-lg-6 fw-bold">Lien type</div>
      <div class="col-lg-6">First lien</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.first_lien === false">
      <div class="col-lg-6 fw-bold">Lien type</div>
      <div class="col-lg-6">Subordinate lien</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Monthly payment</div>
      <div class="col-lg-6">{{item.monthly_payment | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan amount</div>
      <div class="col-lg-6">{{item.loan_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Credit limit</div>
      <div class="col-lg-6">{{item.credit_limit | currency}}</div>
    </div>

  </mat-card-content>
</mat-card>
