import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {Organization} from '../../../../models/organization';
import {AdminService} from '../../../../services/admin.service';

@Component({
  selector: 'app-view-organization',
  templateUrl: './view-organization.component.html',
})
export class ViewOrganizationComponent extends CompBaseComponent implements OnInit {
  public adminService: AdminService;
  public organization: Organization;
  public pageTitle = 'View Organization';

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe((map) => {
      const orgId = map['params']['id'];
      this.findOrganization(orgId);
    });
  }

  findOrganization(orgId: number): void {
    this.reloaded = false;
    this.adminService.findOrganizationById(orgId)
      .then((org) => this.organization = org)
      .then(() => this.reloaded = true);
  }

  viewUser(event: any, userId: Number): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-user', userId]);
  }

  editOrganization(event: any): void {
    event.preventDefault();
    this.router.navigate(['/admin/edit-organization', this.organization.id]);
  }
}
