import {Component, Injector, Input, OnInit} from '@angular/core';
import {LoanEstimateBaseComponent} from "../loan-estimate-base.component";

@Component({
  selector: 'app-view-estimate',
  templateUrl: './view-estimate.component.html',
})
export class ViewEstimateComponent extends LoanEstimateBaseComponent implements OnInit {
  @Input() section: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  edit(): void {
    if (this.canUpdateTransaction) {
      this.eventEmitted.emit({
        type: 'ChangeMode',
        mode: 'Edit'
      });
    }
  }
}
