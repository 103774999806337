<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <div>
      <mat-card-title>
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Liabilities'" [help]="true" [op]="'add_edit'">
          <ul [ngClass]="{'is-mobile':isMobile}" class="help">
            <li>
              <strong>Important:</strong> For most loan applications, the liabilities are extracted from the credit report.
              Consult with your loan officer whether you need to enter liabilities.
            </li>
            <li>
              <strong>Omit Liability from Credit Report:</strong> This should only be edited by the loan officer. When checked, the liability is either a duplicate or an error.
            </li>
            <li>Mortgages and Home Equity Lines of Credit (HELOCs) must be entered separately.</li>
            <li>A derogatory liability under collection would typically display with a liability type of "Other liability."</li>
            <li>
              If you create a liability that is tied to one of the real estate assets from the Real Estate Owned segment and then delete the real estate asset:
              <ul>
                <li>The liability will stay in place, but its corresponding Real Estate Asset ID number will be nullified.</li>
                <li>When this happens, either delete the liability entry if it does not apply or create a new entry in the Real Estate Owned section,
                  and then come back to the Liabilities section to associate it with the correct Real Estate Asset ID.
                </li>
              </ul>
            </li>
            <li>Make sure that all real estate related liabilities have the correct Real Estate Asset ID associated with them.</li>
          </ul>
        </app-segment-header>
      </mat-card-title>

      <app-add-liability (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-liability>

      <app-edit-segment-liabilities (eventEmitted)="onEvent($event)" *ngIf="mode==='Edit'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-edit-segment-liabilities>
    </div>

    <div *ngIf="mode==='View'">
      <div *ngFor="let item of items">
        <app-view-liability (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-liability>
      </div>
    </div>
  </mat-card>
</div>
