import {Component, Injector, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {Router} from '@angular/router';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-new-sandbox-loan',
  templateUrl: './new-sandbox-loan.component.html',
})
export class NewSandboxLoanComponent extends CompBaseComponent implements OnInit {
  public pageTitle: string;
  public canCreateSandboxTransaction: boolean;
  public router: Router;

  // form and form controls
  public form: FormGroup;
  public name: FormControl;
  public description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.canCreateSandboxTransaction = this.permissionService.execCan('CanCreateSandboxTransaction');
    this.pageTitle = 'New Sandbox Loan';
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansNew
      });
    }, 0);
    this.reload();
  }

  createFormControls() {
    this.name = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.description = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
    });
  }

  create(): void {
    if (!this.form.valid) {
      return;
    }

    const payload = this.form.value;
    payload['status'] = Tran.STATUS.ACTIVE;

    this.tranService.createTransaction(payload)
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        FormUtil.genSuccessMessage(this.sharedService, 'Loan successfully created');
      })
      .then(() => {
        this.router.navigate(['/loan/view-directory']);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create loan', data);
      });
  }

  reset(): void {
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.createFormControls();
      this.createForm();
      this.reloaded = true;
    }, 0);
  }
}
