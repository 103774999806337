// This component is not currently used
// Comment: we include it in the shared module to avoid inspection errors
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-terms-content',
  templateUrl: './terms-content.component.html',
})
export class TermsContentComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
