<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="mcrTitle" [help]="true">
      <p>
        The organization MCR is a tally of all the organization loan officer MCRs and is the one
        a mortgage company reports to NMLS quarterly.
      </p>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div [hidden]="isReport">
      <form [formGroup]="form" novalidate class="mt-2">
        <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>

        <!-- STATE -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>State Abbreviation</mat-label>
              <input formControlName="state" matInput placeholder="State abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
              <mat-error *ngIf="state.hasError('required')">
                State is required
              </mat-error>
              <mat-error *ngIf="state.hasError('pattern')">
                State abbreviation is invalid
              </mat-error>
              <mat-hint>State Abbr. E.g., CO for Colorado</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="isReport" class="alert show alert-dismissible" role="alert">
      <app-mcr-report [mcr]="mcr" [state]="state.value" (eventEmitted)="onEvent($event)"></app-mcr-report>
    </div>

    <div [hidden]="isReport" [formGroup]="form" class="mt-3">
      <button (click)="genOrgReport()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="button" title="Generate Report" aria-label="Generate Report">Generate Report</button>
      <button (click)="view()" class="ms-2 btn btn-lg btn-light" type="button">
        <i class="fa fa-chevron-left"></i>&nbsp;Back
      </button>
    </div>
  </mat-card-content>
</mat-card>
