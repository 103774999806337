//
// IMPORTANT: FHASecondaryResidence is not a valid property type
// it has two meanings: second home and fha secondary residence
//
import {ModelBase} from './model-base';
import {DeclarationExplanation} from './declaration-explanation';

// Order of declarations in the UI
// ------------------
// 01 - relationship
// 98 - down_payment_borrowed
// 02 - new_mortgage
// 03 - new_credit
// 05 - priority_lien
// -------------------
// 99 - endorser_loans
// 91 - judgments
// 96 - delinquent_federal_loans
// 94 - law_suits
// 06 - deed_in_lieu
// 04 - short_sale
// 93 - foreclosures
// 92 - bankrupt

export class Declarations extends ModelBase {
  public static citizen_types = [
    {key: 'USCitizen', value: 'US Citizen'},
    {key: 'PermanentResidentAlien', value: 'Permanent Resident-Alien'},
    {key: 'NonPermanentResidentAlien', value: 'Non-Permanent Resident-Alien'},
  ];
  public static title_types = [
    {key: 'Sole', value: 'Sole (Individual)'},
    {key: 'JointWithSpouse', value: 'Joint With Spouse'},
    {key: 'JointWithOtherThanSpouse', value: 'Joint With Other Than Spouse'},
  ];
  public static yes_no_responses = [
    {key: 'Yes', value: 'Yes'},
    {key: 'No', value: 'No'},
  ];
  public static property_types = [
    {key: 'FHASecondaryResidence', value: 'FHA Secondary Residence'},
    {key: 'PrimaryResidence', value: 'Primary Residence'},
    {key: 'SecondHome', value: 'Second Home'},
    {key: 'Investment', value: 'Investment Property'},
  ];

  // Child support or alimony (97) removed per mismo
  // Default loans (95) removed per mismo
  public static declaration_text = {
    '91': 'Are there any outstanding judgments against you?',
    '92': 'Have you declared bankruptcy within the past 7 years?',
    '93': 'Have you had property foreclosed upon in the last 7 years?',
    '94': 'Are you a party to a lawsuit in which you potentially have any personal financial liability?',
    '96': 'Are you currently delinquent or in default on a Federal debt?',
    '98': 'Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or ' +
      'obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?',
    '99': 'Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?',
    '01': 'If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?',
    '02': 'Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before ' +
      'closing this transaction that is not disclosed on this loan application?',
    '03': 'Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this ' +
      'loan that is not disclosed on this application?',
    '04': 'Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third ' +
      'party and the Lender agreed to accept less than the outstanding mortgage balance due?',
    '05': 'Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien ' +
      'paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?',
    '06': 'Have you conveyed title to any property in lieu of foreclosure in the past 7 years?',
  };

  judgments: boolean;
  bankrupt: boolean;
  foreclosures: boolean;
  deed_in_lieu: boolean;
  law_suits: boolean;
  delinquent_federal_loans: boolean;
  down_payment_borrowed: boolean;
  endorser_loans: boolean;
  relationship: boolean;
  new_mortgage: boolean;
  new_credit: boolean;
  short_sale: boolean;
  bankrupt_chapter_7: boolean;
  bankrupt_chapter_11: boolean;
  bankrupt_chapter_12: boolean;
  bankrupt_chapter_13: boolean;
  priority_lien: boolean;
  borrowed_amount: string;
  citizen_ex: string;
  property_type_ex: string;
  title_type_ex: string;
  primary_residence_ex: string;
  ownership_interest_ex: string;
  fha_secondary_residence: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (
        prop === 'fha_secondary_residence' ||
        prop === 'judgments' ||
        prop === 'bankrupt' ||
        prop === 'foreclosures' ||
        prop === 'deed_in_lieu' ||
        prop === 'law_suits' ||
        prop === 'delinquent_federal_loans' ||
        prop === 'down_payment_borrowed' ||
        prop === 'endorser_loans' ||
        prop === 'relationship' ||
        prop === 'new_mortgage' ||
        prop === 'new_credit' ||
        prop === 'short_sale' ||
        prop === 'priority_lien' ||
        prop === 'bankrupt_chapter_7' ||
        prop === 'bankrupt_chapter_11' ||
        prop === 'bankrupt_chapter_12' ||
        prop === 'bankrupt_chapter_13'
      ) {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'borrowed_amount') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
    if (this.property_type_ex === 'SecondHome' && this.fha_secondary_residence === true) {
      this.property_type_ex = 'FHASecondaryResidence';
    }
  }

  verifyExplanations(explanations: Array<DeclarationExplanation>): boolean {
    const givenExplanations = explanations.filter(elt => elt.declaration_explanation && elt.declaration_explanation.length);
    const countGivenExplanations = givenExplanations ? givenExplanations.length : 0;

    const attrs = [
      'judgments',
      'bankrupt',
      'foreclosures',
      'law_suits',
      'delinquent_federal_loans',
      'down_payment_borrowed',
      'endorser_loans',
      'relationship',
      'new_mortgage',
      'new_credit',
      'short_sale',
      'priority_lien',
      'deed_in_lieu',
    ];
    const yesAttrs = attrs.filter(attr => !!this[attr]);
    const yesAttrsCount = yesAttrs ? yesAttrs.length : 0;

    return countGivenExplanations === yesAttrsCount;
  }

  getResidenceStatus(): string {
    const found = Declarations.citizen_types.find((elt) => elt.key === this.citizen_ex);
    return found ? found['value'] : '';
  }

  getPropertyType(): string {
    const found = Declarations.property_types.find((elt) => elt.key === this.property_type_ex);
    return found ? found['value'] : '';
  }

  getTitleType(): string {
    const found = Declarations.title_types.find((elt) => elt.key === this.title_type_ex);
    return found ? found['value'] : '';
  }

  getBankruptcies(): string {
    const parts = [];
    if (this.bankrupt_chapter_7) {
      parts.push('Chapter 7');
    }
    if (this.bankrupt_chapter_11) {
      parts.push('Chapter 11');
    }
    if (this.bankrupt_chapter_12) {
      parts.push('Chapter 12');
    }
    if (this.bankrupt_chapter_13) {
      parts.push('Chapter 13');
    }
    if (parts.length > 0) {
      return parts.join(', ');
    }
    return null;
  }

  toServerPayload(): any {
    if (this.property_type_ex === 'FHASecondaryResidence') {
      this.property_type_ex = 'SecondHome';
      this.fha_secondary_residence = true;
    } else {
      this.fha_secondary_residence = false;
    }
    return {
      fha_secondary_residence: ModelBase.toServerExplicitBoolean(this.fha_secondary_residence),
      judgments: ModelBase.toServerExplicitBoolean(this.judgments),
      bankrupt: ModelBase.toServerExplicitBoolean(this.bankrupt),
      foreclosures: ModelBase.toServerExplicitBoolean(this.foreclosures),
      deed_in_lieu: ModelBase.toServerExplicitBoolean(this.deed_in_lieu),
      law_suits: ModelBase.toServerExplicitBoolean(this.law_suits),
      delinquent_federal_loans: ModelBase.toServerExplicitBoolean(this.delinquent_federal_loans),
      down_payment_borrowed: ModelBase.toServerExplicitBoolean(this.down_payment_borrowed),
      endorser_loans: ModelBase.toServerExplicitBoolean(this.endorser_loans),
      short_sale: ModelBase.toServerExplicitBoolean(this.short_sale),
      relationship: ModelBase.toServerExplicitBoolean(this.relationship),
      new_mortgage: ModelBase.toServerExplicitBoolean(this.new_mortgage),
      new_credit: ModelBase.toServerExplicitBoolean(this.new_credit),
      priority_lien: ModelBase.toServerExplicitBoolean(this.priority_lien),
      bankrupt_chapter_7: ModelBase.toServerExplicitBoolean(this.bankrupt_chapter_7),
      bankrupt_chapter_11: ModelBase.toServerExplicitBoolean(this.bankrupt_chapter_11),
      bankrupt_chapter_12: ModelBase.toServerExplicitBoolean(this.bankrupt_chapter_12),
      bankrupt_chapter_13: ModelBase.toServerExplicitBoolean(this.bankrupt_chapter_13),
      borrowed_amount: this.toServerDecimal(this.borrowed_amount),
      citizen_ex: this.citizen_ex,
      primary_residence_ex: this.primary_residence_ex,
      ownership_interest_ex: this.ownership_interest_ex,
      property_type_ex: this.property_type_ex,
      title_type_ex: this.title_type_ex
    };
  }
}
