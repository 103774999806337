<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Loan Purpose'" [help]="true" [op]="'edit'">
      <span class="fw-bold">Loan purpose</span>
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          The loan purpose impacts the rest of the loan application including available menus and fields.
        </li>
      </ul>

      <span class="fw-bold">Ownership type</span>
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <strong>Fee simple.</strong> Property owner owns the land and the real property attached to it. It is considered
          the highest form of ownership.
        </li>
        <li>
          <strong>Leasehold.</strong> Property owner owns the real estate that is attached to the
          land but leases the land for some period of time. Generally, the lease must be longer than the term of the loan.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row border-bottom hover-gray pb-1">
      <div class="col-lg-6">
        <strong>Is this a construction loan?</strong>
      </div>
      <div class="col-lg-6" *ngIf="item.construction_loan_indicator!==null">{{item.construction_loan_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.construction_loan_indicator===null">(no response)</div>
    </div>

    <div class="row border-bottom hover-gray pb-1">
      <div class="col-lg-6">
        <strong>Loan purpose</strong>
      </div>
      <div class="col-lg-6">{{item.getLoanPurpose()}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1" [hidden]="item.loan_purpose_code_ex !=='Other' ">
      <div class="col-lg-6">
        <strong>Loan purpose explain</strong>
      </div>
      <div class="col-lg-6">{{item.loan_purpose_explain}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1" [hidden]="true">
      <div class="col-lg-6">
        <strong>Property current usage</strong>
      </div>
      <div class="col-lg-6">{{item.getPropertyCurrentResidenceType()}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>Property usage</strong>
      </div>
      <div class="col-lg-6">{{item.getPropertyResidenceType()}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>Ownership type</strong>
      </div>
      <div class="col-lg-6">{{item.getOwnershipType()}}</div>
    </div>

    <div [hidden]="item.ownership_type_ex!=='Leasehold'" class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>Leasehold expiration date</strong>
      </div>
      <div class="col-lg-6">{{item.leasehold_expiration_date | date:'MM/dd/yyyy'}}</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>FHA: Will you occupy the property as a secondary residence?</strong>
      </div>
      <div class="col-lg-6" *ngIf="item.fha_secondary_residence!==null">{{item.fha_secondary_residence | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.fha_secondary_residence===null">(no response)</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>Mixed-use property. Will you set aside space within the property to operate your own business? </strong>
      </div>
      <div class="col-lg-6" *ngIf="item.mixed_use!==null">{{item.mixed_use | booleanToString }}</div>
      <div class="col-lg-6" *ngIf="item.mixed_use===null">(no response)</div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>Is the property subject to a clean energy lien?</strong>
      </div>
      <div class="col-lg-6" *ngIf="item.existing_energy_lien!==null">{{item.existing_energy_lien | booleanToString }}</div>
      <div class="col-lg-6" *ngIf="item.existing_energy_lien===null">(no response)</div>
    </div>

    <div *ngIf="item.loan_purpose_code_ex == 'Purchase'">
      <div class="row border-bottom hover-gray pb-1 pt-1">
        <div class="col-lg-6">
          <strong>Expected monthly rental income</strong>
        </div>
        <div class="col-lg-6">{{item.expected_rental | currency}}</div>
      </div>

      <div class="row border-bottom hover-gray pb-1 pt-1">
        <div class="col-lg-6">
          <strong>Expected net monthly rental income</strong>
        </div>
        <div class="col-lg-6">{{item.expected_net_rental | currency}}</div>
      </div>
    </div>

    <div class="row border-bottom hover-gray pb-1 pt-1">
      <div class="col-lg-6">
        <strong>The number of 1 &ndash; 4 unit properties that are financed and owned and/or obligated on by the borrower(s)</strong>
      </div>
      <div class="col-lg-6">{{item.properties_count}}</div>
    </div>

  </mat-card-content>
</mat-card>
