<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{item.name}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{item.description}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Comments</div>
      <div class="col-lg-8">{{commentsDisplay}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Completed?</div>
      <div class="col-lg-8">
        <i [hidden]="!item.done" class="far fa-check-square"></i><i [hidden]="item.done" class="far fa-minus-square"></i> {{item.done | booleanToString}}
      </div>
    </div>
  </mat-card-content>
</mat-card>
