<mat-card>
  <mat-card-title>Delete User by User ID</mat-card-title>
  <mat-card-content>
    <h5>This function works only in the non-production environments.</h5>
    <form (submit)="deleteUser()" [formGroup]="form">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="userId" appNumberOnly appAutoFocus matInput placeholder="User ID">
            <mat-error *ngIf="userId.hasError('required')">
              User ID is required
            </mat-error>
            <mat-hint>
              User ID is a number
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button class="btn btn-lg btn-primary" type="submit">Delete User</button>
          <button (click)="reset()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
        </div>
      </div>

    </form>
  </mat-card-content>
</mat-card>
