import {Component, Injector, OnInit} from '@angular/core';
import {NewMortgage} from '../../../../models/new-mortgage';
import {NewMortgageComponent} from '../new-mortgage/new-mortgage.component';

@Component({
  selector: 'app-add-new-mortgage',
  templateUrl: '../new-mortgage/edit-segment-new-mortgage.component.html',
})
export class AddNewMortgageComponent extends NewMortgageComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new NewMortgage({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
