import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Condition} from '../../../models/condition';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from '../../form-util';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {ConditionService} from '../../../services/condition.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-condition',
  templateUrl: './edit-segment-condition.component.html',
})
export class ConditionComponent extends CompBaseComponent implements OnInit {
  @Input() item: Condition;
  public conditionId: number;
  public conditionService: ConditionService;
  public tranId: number;
  public op: string;
  public form: FormGroup;
  public name: FormControl;
  public description: FormControl;
  public comments: FormControl;
  public done: FormControl;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.conditionService = this.injector.get(ConditionService);
    this.tranId = this.tranService.getCurrentTranSync().id;
  }

  createFormControls(item: Condition): void {
    this.name = new FormControl(item.name, AppValidators.present);
    this.description = new FormControl(item.description);
    this.comments = new FormControl(item.comments);
    this.done = new FormControl(item.done);
  }

  createForm(): void {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
      comments: this.comments,
      done: this.done
    });
  }

  delete(): void {
    this.conditionService.deleteCondition(this.item.id)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const condition = new Condition(this.form.value);
    this.conditionService.createCondition(this.tranId, condition.toServerPayload())
      .then(() => {
        this.router.navigate(['loan/conditions']);
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create condition', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Condition(this.form.value);
    this.conditionService.updateCondition(this.item.id, updated)
      .then(() => {
        this.router.navigate(['loan/conditions']);
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
