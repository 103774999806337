<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="list-ic vertical">
      <li [ngClass]="{active:currentSeg===seg.tab1Instructions}">
        <span></span>
        <a (click)="setSeg($event,seg.tab1Instructions)" href="" id="tab1">Instructions</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.formTop}">
        <span></span>
        <a (click)="setSeg($event,seg.formTop)" href="" id="form_top">Borrower Setup</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.loanPurpose}">
        <span></span>
        <a (click)="setSeg($event,seg.loanPurpose)" href="" id="loan_purpose">Loan Purpose</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.parties}">
        <span></span>
        <a (click)="setSeg($event,seg.parties)" href="" id="parties">Title</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.propertyInformation}">
        <span></span>
        <a (click)="setSeg($event,seg.propertyInformation)" href="" id="property_information">Property Info</a>
      </li>
      <li *ngIf="isDownPayment" [ngClass]="{active:currentSeg===seg.downPayments}">
        <span></span>
        <a (click)="setSeg($event,seg.downPayments)" href="" id="down_payments">Down Payments</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.newMortgages}">
        <span></span>
        <a (click)="setSeg($event,seg.newMortgages)" href="" id="new_mortgages">New Mortgages</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.laExpenses}">
        <span></span>
        <a (click)="setSeg($event,seg.laExpenses)" href="" id="la_expenses">Housing Expenses</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.laRealEstates}">
        <span></span>
        <a (click)="setSeg($event,seg.laRealEstates)" href="" id="la_real_estates">Real Estate</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.laAssets}">
        <span></span>
        <a (click)="setSeg($event,seg.laAssets)" href="" id="la_assets">Assets</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!isMobile || !isOpen" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-instructions  *ngSwitchCase="seg.tab1Instructions" [tab]="'tab1'">
      </app-instructions>

      <app-view-form-top (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.formTop" [doc]="doc">
      </app-view-form-top>

      <app-view-property-information (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.propertyInformation" [doc]="doc">
      </app-view-property-information>

      <app-view-loan-purpose (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.loanPurpose" [doc]="doc">
      </app-view-loan-purpose>

      <app-view-segment-down-payments (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.downPayments" [doc]="doc">
      </app-view-segment-down-payments>

      <app-view-segment-new-mortgages (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.newMortgages" [doc]="doc">
      </app-view-segment-new-mortgages>

      <app-view-segment-parties (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.parties" [doc]="doc">
      </app-view-segment-parties>

      <app-view-segment-expenses (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.laExpenses" [doc]="doc">
      </app-view-segment-expenses>

      <app-view-segment-real-estates (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.laRealEstates" [doc]="doc">
      </app-view-segment-real-estates>

      <app-view-segment-assets (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.laAssets" [doc]="doc">
      </app-view-segment-assets>
    </div>

    <app-popup-compliance-container style="width:100%;" *ngIf="false"></app-popup-compliance-container>
  </div>

  <div *ngIf="isMobile&&isOpen" class="col">
    <app-box [close]="false" [type]="'success'" class="mt-2">
      Click any menu-item to view/edit it.
    </app-box>
  </div>
</div>
