import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import $ from 'jquery';

@Component({
  selector: 'app-view-util',
  templateUrl: './view-util.component.html',
})
export class ViewUtilComponent extends CompBaseComponent implements OnInit {
  public displayDownload: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.displayDownload = false;
    if (this.tran) {
      const docs = this.tran.documents.filter((elt) => elt.dtype !== 64 && elt.dtype < 256);
      this.displayDownload = docs && (docs.length > 0);
    }
  }

  downloadZip(event) {
    event.preventDefault();
    const url = '/api/trans/download_documents_zip/' + this.tran.id;
    $('#download_zip_iframe').attr('src', url);
  }

  navigateToDocs(event: any): void {
    event.preventDefault();
    this.eventService.eventEmitter.emit({
      type: 'ChangeTab',
      tab: 'docs'
    })
  }
}
