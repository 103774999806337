<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Repository</mat-card-title>

  <mat-card-content>

    <h5>Loan Record</h5>

    <p>
      The loan record displays the loan name, description, owner, and status.
    </p>


    <h5>Loan Applications</h5>

    <p>
      From Loan Applications you create a new loan application or manage the existing ones.
    </p>

    <strong>To create a new loan application</strong>

    <ul>
      <li>
        Click the Add (plus) button next to the Loan Applications header, provide a name, and click Create.
      </li>
      <li>
        The Default Loan Application is the one you are working on.
        The main reason for having more than one loan application is for backup.
      </li>
    </ul>

    <strong>To manage a loan application</strong>

    <ul>
      <li>
        Click the Edit button of the card containing the loan application.
      </li>
      <li>
        You can now update the loan application record name.
      </li>
      <li>
        You can make a loan application the default one by checking the "Default loan application" checkbox.
      </li>
    </ul>

    <h5>Folders & Documents</h5>

    <strong>Navigating the document repository</strong>

    <p>
      Lots of functions are available from Documents and most of them are intuitive.
      As a rule, if you hover over an item (such as the document name) and the hand
      pointer becomes visible, you can then edit it by clicking it.
    </p>

    <strong>Repository Structure</strong>

    <p>
      The documents are structured as a small file system. Documents are organized into folders.
      Folders are organized into groups such as Disclosures, Assets, Liabilities.
      Unsorted Documents (not yet organized into any folder) can be found at the bottom of the Documents page.
    </p>

    <strong>Selecting folders</strong>

    <p>
      <span class="font-italic">Many stock folders are already defined for you.</span>
      To select from these folders, click the group header (for example, Disclosures) and
      check the folders that you want to add to your current loan.
    </p>

    <strong>Creating folders</strong>

    <p>
      You can also create a new folder (not listed in the stock folders)
      by clicking the Add (plus) button next to the appropriate group header.
    </p>

    <strong>To upload a PDF or image</strong>

    <p>
      Click the Upload button that appears on the same line as the folder.
    </p>

    <strong>To move a PDF or image</strong>

    <p>
      Hover over the file that you want to move.
      Click the Cut (scissors) icon.
      Hover over the folder where you want to move the file.
      Click the Paste (duplicate) icon.
    </p>

    <strong>To download a PDF or view an image</strong>

    <ul>
      <li>
        Click the PDF icon.
      </li>
      <li>
        Click the image icon to display it inline in its original size.
      </li>
    </ul>

    <strong>To change the status of a folder</strong>

    <p>
      Click the stoplight image and choose the appropriate status from the drop-down menu.
    </p>

    <h5>The unsorted folder</h5>

    The Unsorted folder contains documents that have not yet been sorted into any named folder.
    <ul [ngClass]="{'is-mobile':isMobile}" class="help">
      <li>
        Borrowers can upload documents to this folder, if they have Repository Access. Refer to
        <a class="pr-blue-bold" routerLink="/loan/view-access-control">loan/access-control/loan</a>.
      </li>
      <li>
        Documents generated by the loan officer without specifying a target folder end up in this folder as well.
      </li>
    </ul>

  </mat-card-content>
</mat-card>
