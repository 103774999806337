import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import $ from 'jquery';
import {Tran} from '../../../models/tran';
import {CompBaseComponent} from '../../comp-base.component';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-timeline',
  templateUrl: './view-timeline.component.html',
})
export class ViewTimelineComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Timeline';
  public mode: string;
  public tran: Tran;
  public isCurrentLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.timeline
      });
    }, 0);
    this.reload(false);
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.isCurrentLoan = false;
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        this.reloaded = true;
        if (tran) {
          this.tran = tran;
          this.isCurrentLoan = true;
          return;
        } else {
          this.isCurrentLoan = false;
        }
      })
      .then(() => {
        this.renderTimeline();
      });
  }

  renderTimeline() {
    if ($('#timeline-embed').length === 0) {
      return;
    }
    const slideNumber = this.tran.slide_number;
    const timeline = this.tran.timeline;
    $('#timeline-embed').unbind();
    $('#timeline-embed').html('');
    const height = '600';
    window['createStoryJS']({
      type: 'timeline',
      start_at_slide: slideNumber,
      height: height,
      width: '100%',
      source: timeline,
      embed_id: 'timeline-embed'
    });

    // remove breaks from timeline right after it renders
    let timer = setInterval(() => {
      if ($('#storyjs-timeline br').length > 0) {
        $('#storyjs-timeline br').replaceWith('&nbsp;');
        timer = null;
      }
    }, 100);
  }
}
