<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Request Documents/Images'" [help]="true">
      The recipient of your request to upload a document/image will receive an email
      with instructions to upload one or more files.
      The files will be uploaded to your account, and you will be able to download them in Documents/Images Received below.
      <ul>
        <li>
          Please provide a
          <b>meaningful description</b>
          of the file(s) and/or image(s) requested.
        </li>
        <li>
          The recipient will be able to provide information about the document/image they are uploading.
        </li>
        <li>
          The document/image description that you and the recipient provide is displayed below under Document/Images Received.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row mb-4">
      <div class="col-lg-6 text-start">
        <ul>
          <li>
            Your information will be shared with the recipient.<br/>
          </li>
          <li>
            PDF and Image files ONLY.<br/>
          </li>
          <li>
            Once uploaded, files remain available for 72 Hours.<br/>
          </li>
        </ul>
      </div>
    </div>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Recipient first name</mat-label>
            <input formControlName="firstName" matInput maxlength="{{MAX_LEN.NAME_PART}}" placeholder="Recipient first name" type="text" appAutoFocus required>
            <mat-error *ngIf="firstName.hasError('required')">
              Recipient first name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Recipient last name</mat-label>
            <input formControlName="lastName" matInput maxlength="{{MAX_LEN.NAME_PART}}" placeholder="Recipient last name" type="text" required>
            <mat-error *ngIf="lastName.hasError('required')">
              Recipient last name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Recipient email address</mat-label>
            <input type="text" appTrimOnBlur formControlName="email" matInput placeholder="Recipient email address" maxlength="{{MAX_LEN.EMAIL}}" required>
            <mat-error *ngIf="email.hasError('required')">
              Recipient email address is required
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              Recipient email address is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>File(s) description</mat-label>
            <textarea cdkAutosizeMaxRows="10"
              cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="context"
              matInput maxlength="{{MAX_LEN.VL_DESC}}"
              placeholder="File(s) description" required>
            </textarea>
            <!--            <input formControlName="context" matInput placeholder="Document description" type="text" required>-->
            <mat-error *ngIf="context.hasError('required')">
              Document description is required
            </mat-error>
            <mat-hint>Provide a meaningful description of the file(s) requested</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-6">
          <button (click)="sendUploadEmail()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="submit">Send Request</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
