<mat-card class="mt-1 mb-1">
  <mat-card-title>Account Settings
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Prefix</mat-label>
            <input formControlName="prefix" matInput appAutoFocus placeholder="Prefix" maxlength="{{MAX_LEN.NAME_PART}}">
            <mat-error *ngIf="prefix.hasError('required')">
              Prefix is required
            </mat-error>
            <mat-error *ngIf="prefix.hasError('minlength')">
              Prefix is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>First name</mat-label>
            <input formControlName="first" matInput placeholder="First name" maxlength="{{MAX_LEN.NAME_PART}}" required>
            <mat-error *ngIf="first.hasError('required')">
              First name is required
            </mat-error>
            <mat-error *ngIf="first.hasError('minlength')">
              First name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Middle name</mat-label>
            <input formControlName="middle" matInput placeholder="Middle name" maxlength="{{MAX_LEN.NAME_PART}}">
            <mat-error *ngIf="middle.hasError('required')">
              Middle name is required
            </mat-error>
            <mat-error *ngIf="middle.hasError('minlength')">
              Middle name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Last name</mat-label>
            <input formControlName="last" matInput placeholder="Last name" maxlength="{{MAX_LEN.NAME_PART}}" required>
            <mat-error *ngIf="last.hasError('required')">
              Last name is required
            </mat-error>
            <mat-error *ngIf="last.hasError('minlength')">
              Last name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Suffix</mat-label>
            <input formControlName="suffix" matInput placeholder="Suffix" maxlength="{{MAX_LEN.NAME_PART}}">
            <mat-error *ngIf="suffix.hasError('required')">
              Suffix is required
            </mat-error>
            <mat-error *ngIf="suffix.hasError('minlength')">
              Suffix is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Phone</mat-label>
            <input appPhoneOnly formControlName="cell_phone" matInput placeholder="Phone" maxlength="{{MAX_LEN.PHONE}}">
            <mat-hint>format: xxx-xxx-xxxx</mat-hint>
            <mat-error *ngIf="cell_phone.hasError('required')">
              Phone is required
            </mat-error>
            <mat-error *ngIf="cell_phone.hasError('pattern')">
              Phone is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Mobile carrier</mat-label>
            <mat-select formControlName="mobile_carrier_name" placeholder="Select carrier">
              <mat-option *ngFor="let car of carriers" [value]="car.key">{{car.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mobile_carrier_name.hasError('required')">
              Mobile carrier name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
