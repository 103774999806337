<mat-card>
  <mat-card-title>Find Transactions</mat-card-title>

  <mat-card-content>
    <form (submit)="findTrans()" [formGroup]="form">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="name" matInput placeholder="Name">
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button class="btn btn-lg btn-primary" type="submit">Find Trans</button>
      <button (click)="reset()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
    </form>
  </mat-card-content>
</mat-card>
