import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ez-upload-logo',
  templateUrl: './ez-upload-logo.component.html',
})
export class EzUploadLogoComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
