import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {OrganizationComponent} from '../organization.component';
import {Organization} from '../../../../models/organization';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
})
export class EditOrganizationComponent extends OrganizationComponent implements OnInit {
  public organization: Organization;

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe((map) => {
      this.orgId = map['params']['id'];
      this.reload();
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.adminService.findOrganizationById(this.orgId)
      .then((org) => {
        this.organization = org;
        this.createFormControls(this.organization);
        this.createForm();
        this.reloaded = true;
      });
  }

  createFormControls(org) {
    this.name = new FormControl(org.name);
    this.description = new FormControl(org.description);
    this.nmls = new FormControl(org.nmls);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description,
      nmls: this.nmls,
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    this.adminService.updateOrganization(this.organization.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization successfully updated');
        this.router.navigate(['/admin/organization-list'], {queryParams: {name: this.name.value}});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update organization', data);
      });
  }
}
