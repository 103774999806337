<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Gift type</div>
      <div class="col-lg-6">{{item.getGiftType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Gift source</div>
      <div class="col-lg-6">{{item.getGiftSource()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.gift_source==='Other'">
      <div class="col-lg-6 fw-bold">Other gift description</div>
      <div class="col-lg-6">{{item.other_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Market value</div>
      <div class="col-lg-6">{{item.market_value | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Deposited/Not deposited</div>
      <div class="col-lg-6">{{item.deposited === true ? 'Deposited' : (item.deposited === false ? 'Not deposited' : '') }}</div>
    </div>

  </mat-card-content>
</mat-card>
