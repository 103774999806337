import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {DownPayment} from '../../../../models/down-payment';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-down-payment',
  template: '',
})
export class DownPaymentComponent extends CompBaseComponent implements OnInit {
  public funds_sources = DownPayment.funds_sources;

  // form and form fields
  public form: FormGroup;
  public funds_source: FormControl;
  public down_payment_explanation: FormControl;
  public down_payment_amount: FormControl;

  // required fields
  @Input() item: DownPayment;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'down_payments';
    this.section = 'loan_information';
  }

  createFormControls(item: DownPayment): void {
    this.funds_source = new FormControl(item.funds_source, AppValidators.present);
    this.down_payment_explanation = new FormControl(item.down_payment_explanation);
    this.down_payment_amount = new FormControl(item.down_payment_amount, AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      funds_source: this.funds_source,
      down_payment_explanation: this.down_payment_explanation,
      down_payment_amount: this.down_payment_amount,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new DownPayment(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new DownPayment(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
