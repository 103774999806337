import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';
import {Mcr} from '../../../../models/mcr';
import {FormUtil} from '../../../form-util';
import {LoMcrComponent} from '../lo-mcr/lo-mcr.component';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-view-mcr-list',
  templateUrl: './view-mcr-list.component.html',
})
export class ViewMcrListComponent extends LoMcrComponent implements OnInit, OnDestroy {
  public mcrs: Array<Mcr>;
  public pageTitle = 'My MCRs';
  public user: User;
  public form: FormGroup;
  public latestQuarter: FormControl;
  public latestYear: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.loMCRList
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    this.reload(true);
    this.reloadOnSync();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createFormControls(): void {
    this.latestYear = new FormControl();
    this.latestQuarter = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      latestYear: this.latestYear,
      latestQuarter: this.latestQuarter,
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.mode = 'View';
    this.mcrService.getUserMcrs()
      .then((mcrs) => {
        this.mcrs = mcrs;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  cancel(): void {
    this.reload();
  }

  add(): void {
    const payload = {
      year: this.latestYear.value,
      quarter: this.latestQuarter.value,
      mcr_type: Mcr.TYPE.LO,
      organization_id: this.user.organization_id
    };
    this.mcrService.createMcr(payload)
      .then((mcr) => {
        this.reload(true);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create loan officer MCR', data);
        this.cancel();
      });
  }

  navigateToMcr(mcr: Mcr): void {
    this.mcrService.setCurrentLoMcrId(mcr.id);
    this.router.navigate(['/loans/lo-mcr']);
  }
}
