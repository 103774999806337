import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-credit-auth-doc',
  templateUrl: './edit-credit-auth-doc.component.html',
})
export class EditCreditAuthDocComponent extends GenerateDocumentBaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public borrowerCount: number;
  @Input() folderId: string;

  // form and form controls
  public form: FormGroup;
  public mlo_name: FormControl;
  public doc_date: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.formType = 'STEER';
  }

  ngOnInit() {
    super.ngOnInit();
    this.generating = false;
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.mlo_name = new FormControl(this.txSummary.mloFullName);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
  }

  createForm(): void  {
    this.form = new FormGroup({
      mlo_name: this.mlo_name,
      doc_date: this.doc_date,
    });
  }

  async genCreditAuth(): Promise<boolean> {
    if (!this.form.valid) {
      return;
    }
    this.generating = true;
    const formData = this.form.value;
    formData['doc_date'] = moment(this.doc_date.value).format('MM/DD/YYYY');
    formData['icn_id'] = this.folderId || null;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
