<div class="row pt-5">
  <div class="col-lg-3 mx-auto background-warning-color text-center">
    <div class="p-3">
      <h1>401</h1>
      <h2>Unauthorized!</h2>
      <p>
        Oops, you may not have access to a resource you requested or simply timed out.<br>
        Please <a class="fw-bold" href="{{signInUrl}}">sign-in</a>.
      </p>
    </div>
  </div>
</div>
