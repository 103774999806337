<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Select a Sandbox Loan'" [help]="true" [op]="''">
      The purpose of the Copy Documents facility is to empower the loan officer to copy documents, images, and loan application
      from a sandbox loan. Typically, the borrower creates a sandbox loan, fills out the loan application and uploads
      documents and images.
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          A burrower can create a sandbox loan and grant you, the loan officer, access to it.
        </li>
        <li>
          You can copy the loan documents, images, and loan application, from the sandbox loan to the current loan.
        </li>
        <li>
          Documents and images will be copied to the Unsorted Folder and loan applications to the loan application folder (Loan Applications).
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-4 fw-bold">Owner name</div>
      <div class="col-lg-4 fw-bold">Updated</div>
    </div>

    <div *ngFor="let tran of sandboxTrans">
      <div class="row hover-gray pt-2 pb-2 border-top-gray hand-pointer" (click)="onSelectedTran(tran.id)">
        <div class="col-lg-4 fw-bold">{{tran.name}}</div>
        <div class="col-lg-4">{{tran.owner_full_name }}</div>
        <div class="col-lg-4">{{tran.updated_at | date:'MM/dd/yyyy' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded&&sandboxTranId!==null" class="mt-5">
  <mat-card-title>
    Copy Documents from Sandbox Loan
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-4 fw-bold">
          Name
        </div>
        <div class="col-lg-4 fw-bold">
          Doc Type
        </div>
      </div>
      <div *ngFor="let ctl of docCheckboxArray.controls; let i = index" formArrayName="docCheckboxArray" class="row hover-gray">
        <div class="col-lg-4 my-auto">
          <mat-checkbox [formControlName]="i">{{ctl['name']}}</mat-checkbox>
        </div>
        <div class="col-lg-4 my-auto">
          {{ctl['dtype']}}
        </div>
      </div>
    </form>

    <div class="row border-bottom pt-3 pb-1">
      <div class="col">
        <button (click)="copySandboxDocs()" [disabled]="!form.valid||!isDocs()" class="btn btn-lg btn-primary">Copy Docs</button>
        <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
