import {ModelBase} from './model-base';

export class SysAlert extends ModelBase {
  public static sysResources = [
    {key: 1, value: 'PrudentCO Server'},
    {key: 2, value: 'My PrudentCO Node Server'},
  ];
  public static sysCodes = [
    {key: 0, value: 'Unknown'},
    {key: 1, value: 'Down'},
  ];
  public id: number;
  public count: number;
  public resource: number;
  public code: number;
  public memo: string;
  public resolved: boolean;
  public created_at: Date;
  public updated_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'updated_at' || prop === 'created_at') {
        this[prop] = new Date(object[prop]);
      } else if (prop === 'resolved') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'id'
        || prop === 'count'
        || prop === 'id'
        || prop === 'resource'
        || prop === 'code'
      ) {
        this[prop] = this.toNumber(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<SysAlert> {
    const result = [];
    for (const obj of arr) {
      const newSysAlert = new SysAlert(obj);
      result.push(newSysAlert);
    }

    return result;
  }

  getResourceValue(resourceKey: number): string {
    const entry = SysAlert.sysResources.find((elt) => elt.key === resourceKey);
    return entry ? entry.value : '';
  }

  getCodeValue(codeKey: number): string {
    const entry = SysAlert.sysCodes.find((elt) => elt.key === codeKey);
    return entry ? entry.value : '';
  }
}
