import {Component, Injector, OnInit} from '@angular/core';
import {ExpenseComponent} from '../expense/expense.component';

@Component({
  selector: 'app-edit-segment-expense',
  templateUrl: '../expense/edit-segment-expense.component.html',
})
export class EditSegmentExpenseComponent extends ExpenseComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
