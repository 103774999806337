<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Employer name</div>
      <div class="col-lg-6">{{item.employer_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Address</div>
      <div class="col-lg-6">{{item.getFullAddress()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">From date</div>
      <div class="col-lg-6">{{item.from_date | date}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">To date</div>
      <div class="col-lg-6">{{item.to_date | date}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Monthly income</div>
      <div class="col-lg-6">{{item.monthly_income | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Position</div>
      <div class="col-lg-6">{{item.position}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Are you the business owner or self-employed?</div>
      <div class="col-lg-6" *ngIf="item.self_employed!==null">{{item.self_employed | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.self_employed===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Business phone</div>
      <div class="col-lg-6">{{item.business_phone | phone}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Seasonal income?</div>
      <div class="col-lg-6" *ngIf="item.seasonal_income_indicator!==null">{{item.seasonal_income_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.seasonal_income_indicator===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Foreign income?</div>
      <div class="col-lg-6" *ngIf="item.foreign_income_indicator!==null">{{item.foreign_income_indicator | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.foreign_income_indicator===null">(no response)</div>
    </div>
  </mat-card-content>
  `
</mat-card>
