import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Expense} from '../../../../models/expense';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-expense',
  template: '',
})
export class ExpenseComponent extends CompBaseComponent implements OnInit {
  public expense_indicators_ex = Expense.expense_indicators_ex;
  public expense_codes_ex = Expense.expense_codes_ex;

  // form and form fields
  public form: FormGroup;
  public expense_indicator_ex: FormControl;
  public code_ex: FormControl;
  public amount: FormControl;

  // boiler plate fields
  @Input() item: Expense;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'la_expenses';
    this.section = null;
  }

  createFormControls(item: Expense): void {
    this.expense_indicator_ex = new FormControl(item.expense_indicator_ex, AppValidators.present);
    this.code_ex = new FormControl(item.code_ex, AppValidators.present);
    this.amount = new FormControl(item.amount,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
  }

  createForm(): void {
    this.form = new FormGroup({
      expense_indicator_ex: this.expense_indicator_ex,
      code_ex: this.code_ex,
      amount: this.amount
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Expense(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Expense(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

}
