import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormTop} from '../../../../models/form-top';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-form-top',
  templateUrl: './view-segment-form-top.component.html',
})
export class ViewSegmentFormTopComponent extends CompBaseComponent implements OnInit {
  @Input() item: FormTop;

  constructor(public injector: Injector) {
    super(injector);
    this.segment = 'form_top';
    this.section = 'loan_information';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
