import {ModelBase} from './model-base';

export class ArmData extends ModelBase {
  public static index_types_new: Array<any> = [
    {key: 'FHLBEleventhDistrictMonthlyCostOfFundsIndex', value: 'FHLB Eleventh District Monthly Cost of Funds Index'},
    {key: 'LIBOROneMonthWSJ25thDayOfMonth', value: 'LIBOR One Month WSJ 25th Day of Month'},
    {key: 'LIBOROneMonthWSJ25thDayOfMonthOrNextBusinessDay', value: 'LIBOR One Month WSJ 25th Day of Month Or Next Business Day'},
    {key: 'LIBOROneMonthWSJDaily', value: 'LIBOR One Month WSJ Daily'},
    {key: 'LIBOROneMonthWSJFifteenthDayOfMonth', value: 'LIBOR One Month WSJ Fifteenth Day of Month'},
    {key: 'LIBOROneMonthWSJFifteenthDayOfMonthOrNextBusinessDay', value: 'LIBOR One Month WSJ Fifteenth Day of Month Or Next Business Day'},
    {key: 'LIBOROneYearWSJDaily', value: 'LIBOR One Year WSJ Daily'},
    {key: 'LIBORSixMonthWSJ25thDayOfMonth', value: 'LIBOR Six Month WSJ 25th Day of Month'},
    {key: 'LIBORSixMonthWSJ25thDayOfMonthOrNextBusinessDay', value: 'LIBOR Six Month WSJ 25th Day of Month Or Next Business Day'},
    {key: 'LIBORSixMonthWSJFifteenthDayOfMonth', value: 'LIBOR Six Month WSJ Fifteenth Day of Month'},
    {key: 'LIBORSixMonthWSJFifteenthDayOfMonthOrNextBusinessDay', value: 'LIBOR Six Month WSJ Fifteenth Day of Month Or Next Business Day'},
    {key: 'LIBORSixMonthWSJLastBusinessDayOfMonth', value: 'LIBOR Six Month WSJ Last Business Day of Month'},
    {key: 'MonthlyFiveYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Monthly Five Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'MonthlyOneYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Monthly One Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'MonthlyThreeYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Monthly Three Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'NationalAverageContractMortgageRate', value: 'National Average Contract Mortgage Rate'},
    {key: 'NationalMonthlyMedianCostOfFundsIndexOTS', value: 'National Monthly Median Cost of Funds Index OTS'},
    {key: 'Other', value: 'Other'},
    {key: 'PrimeRateWSJEffectiveDate', value: 'Prime Rate WSJ Effective Date'},
    {key: 'PrimeRateWSJPublicationDate', value: 'Prime Rate WSJ Publication Date'},
    {key: 'SixMonthLIBOR_WSJDaily', value: 'Six Month LIBOR WSJ Daily'},
    {key: 'SixMonthLIBOR_WSJFirstBusinessDayOfTheMonth', value: 'Six Month LIBOR WSJ First Business Day of The Month'},
    {key: 'SixMonthUSTBillMonthlyAuctionDiscountRateCalculated', value: 'Six Month UST Bill Monthly Auction Discount Rate Calculated'},
    {key: 'SixMonthUSTBillMonthlyAuctionInvestmentYieldCalculated', value: 'Six Month UST Bill Monthly Auction Investment Yield Calculated'},
    {key: 'SixMonthUSTBillWeeklyAuctionDiscountRateUST', value: 'Six Month UST Bill Weekly Auction Discount Rate UST'},
    {key: 'SixMonthUSTBillWeeklyAuctionInvestmentYieldUST', value: 'Six Month UST Bill Weekly Auction Investment Yield UST'},
    {key: 'WeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Weekly Five Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'WeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Weekly One Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'WeeklySixMonthCertificateOfDepositSecondaryMarketFRBH15', value: 'Weekly Six Month Certificate of Deposit Secondary Market FRBH15'},
    {key: 'WeeklyTenYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Weekly Ten Year Treasury Securities Constant Maturity FRBH15'},
    {key: 'WeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15', value: 'Weekly Three Year Treasury Securities Constant Maturity FRBH15'},
  ];
  public static index_types_other: Array<any> = [
    {key: '11thDistrictCOF', value: '11th District COF'},
    {key: '30DayAverageSOFR', value: '30-Day Average SOFR'},
    {key: 'DailyCDRate', value: 'Daily CD Rate'},
    {key: 'FannieMae60DayRequiredNetYield', value: 'FannieMae 60-Day Required Net Yield'},
    {key: 'FannieMaeLIBOR', value: 'FannieMae LIBOR'},
    {key: 'FederalCostOfFunds', value: 'Federal Cost of Funds'},
    {key: 'FreddieMac60DayRequiredNetYield', value: 'FreddieMac 60-Day Required Net Yield'},
    {key: 'FreddieMacLIBOR', value: 'FreddieMac LIBOR'},
    {key: 'MonthlyAverageCMT', value: 'Monthly Average CMT'},
    {key: 'NationalAverageContractRate', value: 'National Average Contract Rate'},
    {key: 'NationalMonthlyMedianCostOfFunds', value: 'National Monthly Median Cost of Funds'},
    {key: 'TBillDailyValue', value: 'TBill Daily Value'},
    {key: 'WallStreetJournalLIBOR', value: 'Wall Street Journal LIBOR'},
    {key: 'WeeklyAvePrimeRate', value: 'Weekly Average Prime Rate'},
    {key: 'WeeklyAverageCDRate', value: 'Weekly Average CD Rate'},
    {key: 'WeeklyAverageCMT', value: 'Weekly Average CMT'},
    {key: 'WeeklyAverageSMTI', value: 'Weekly Average SMTI'},
    {key: 'WeeklyAverageTAABD', value: 'Weekly Average TAABD'},
    {key: 'WeeklyAverageTAAI', value: 'Weekly Average TAAI'},
  ];

  index: string;
  index_type_new: string;
  index_margin: string;
  qualifying_rate: string;
  other_description: string;
  first_adjustment_months_no: string;
  subsequent_adjustment_months_no: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'index' || prop === 'index_margin' || prop === 'qualifying_rate') {
        this[prop] = super.getNumberProp(prop, object);
      } else if (prop === 'first_adjustment_months_no' || prop === 'subsequent_adjustment_months_no') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getIndex(): number {
    return this.index ? +this.index / 100 : null;
  }

  getIndexMargin(): number {
    return this.index_margin ? +this.index_margin / 100 : null;
  }

  getQualifyingRate(): number {
    return this.qualifying_rate ? +this.qualifying_rate / 100 : null;
  }

  getIndexType(): string {
    const found = ArmData.index_types_new.find((elt) => elt.key === this.index_type_new);
    return found ? found['value'] : '';
  }

  getOtherIndexType(): string {
    const found = ArmData.index_types_other.find((elt) => elt.key === this.other_description);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toServerPercentThree(this.index),
      index_type_new: this.index_type_new,
      index_margin: this.toServerPercentThree(this.index_margin),
      qualifying_rate: this.toServerPercentThree(this.qualifying_rate),
      other_description: this.other_description,
      first_adjustment_months_no: this.toServerString(this.first_adjustment_months_no),
      subsequent_adjustment_months_no: this.toServerString(this.subsequent_adjustment_months_no)
    };
  }
}
