<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Loan Officer
  </mat-card-title>

  <mat-card-content>
    <p *ngIf="isLoanProfessional">
      The information in Account/Branding Profile is used to display the loan officer's information displayed in this
      page.
      Note that the borrower accesses this page from the same menu location as you (Home/Loan Officer).
    </p>
    <p *ngIf="!isLoanProfessional">
      This page display the loan officer's profile including contact information.
    </p>
  </mat-card-content>
</mat-card>
