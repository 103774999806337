import {Component, Injector, OnInit} from '@angular/core';
import {ConditionComponent} from '../condition/condition.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Condition} from '../../../models/condition';

@Component({
  selector: 'app-edit-segment-condition',
  templateUrl: '../condition/edit-segment-condition.component.html',
})
export class EditSegmentConditionComponent extends ConditionComponent implements OnInit {
  op = 'Edit';

  constructor(public injector: Injector, public route: ActivatedRoute, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe((map) => {
      this.conditionId = map['params']['id'];
      this.conditionService.getConditionById(this.conditionId)
        .then((condition: Condition) => {
          this.item = condition;
          this.createFormControls(this.item);
          this.createForm();
          this.reloaded = true;
        });
    });
  }

  cancel(): void {
    this.router.navigate(['loan/conditions']);
  }
}
