<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="mcrTitle" [help]="true">
      <p>
        The loans considered for your loan officer MCR report for this period are displayed below.
        Initially it is empty and therefore you need to populate it.
      </p>
      <ul>
        <li>To populate this list, click Manage MCR and then on Populate MCR.</li>
        <li>To access any loan MCR data, simply select the loan.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>
    <div class="fw-bold">Loans Associated with My MCR</div>
    <div *ngFor="let tran of mcr.trans" (click)="navigateToTranMCR(tran)" class="row pt-1 pb-1 border-bottom hover-gray hand-pointer">
      <div class="col-lg-1">
        {{tran.id}}
      </div>
      <div class="col-lg-5">
        {{tran.name}}
      </div>
      <div class="col-lg-6">
        {{tran.description}}
      </div>
    </div>

    <form [formGroup]="form" class="mt-3">
      <button (click)="populateMcr()" class="btn btn-lg btn-primary" type="button" title="Populate MCR with loan data" aria-label="Populate MCR with loan data">Populate MCR</button>
      <button (click)="manage()" class="ms-2 btn btn-lg btn-primary" type="button">Manage MCR</button>
      <button (click)="back()" class="ms-2 btn btn-lg btn-light" type="button">
        <i class="icon fa fa-chevron-left"></i>&nbsp;Back
      </button>
    </form>
  </mat-card-content>
</mat-card>

