<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'ARM Data'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>ARM segment displays only for an Adjustable Rate loan.</li>
        <li>If you don't see the ARM index in the drop-down list, select "Other" and
          then select other index type from the "Other index type" drop-down list.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Index</div>
      <div class="col-lg-6">{{item.getIndex() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Index type</div>
      <div class="col-lg-6">{{item.getIndexType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.index_type_new !== 'Other'">
      <div class="col-lg-6 fw-bold">Other index type</div>
      <div class="col-lg-6">{{item.getOtherIndexType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Index margin</div>
      <div class="col-lg-6">{{item.getIndexMargin() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Qualifying rate</div>
      <div class="col-lg-6">{{item.getQualifyingRate() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">First adjustment period count in months</div>
      <div class="col-lg-6">{{item.first_adjustment_months_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Subsequent adjustment period count in months</div>
      <div class="col-lg-6">{{item.subsequent_adjustment_months_no}}</div>
    </div>
  </mat-card-content>
</mat-card>
