import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {UserConfiguration} from '../../../../models/user-configuration';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-view-mlo-configuration',
  templateUrl: './view-mlo-configuration.component.html',
})
export class ViewMLOConfigurationComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'MLO Configuration';
  public userConfiguration: UserConfiguration;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isMobile) {
      this.pageTitle = 'MLO Config';
    }
    this.reload(false);
    this.reloadOnSync();
    if (this.isTitle) {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
        });
      }, 0);
    }
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const userSync = this.userService.getUserSync();
    this.userConfiguration = new UserConfiguration(userSync.user_configuration);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
