import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AgentService} from '../../../services/agent.service';
import {OrganizationService} from '../../../services/organization.service';
import {FormControl, FormGroup} from "@angular/forms";
import {FormUtil} from "../../form-util";

@Component({
  selector: 'app-organization',
  template: '',
})
export class AgentOrganizationComponent extends CompBaseComponent implements OnInit {
  public op: string;
  public orgId: number;
  public agentService: AgentService;
  public orgService: OrganizationService;
  public route: ActivatedRoute;
  public router: Router;
  // form and form controls
  form: FormGroup;
  name: FormControl;
  description: FormControl;
  nmls: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.agentService = this.injector.get(AgentService);
    this.orgService = this.injector.get(OrganizationService);
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    this.agentService.createOrganization(payload)
      .then((org) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization successfully created');
        this.router.navigate([`/agent/view-organization/${org.id}`]);
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create organization', data);
      });
  }
}
