<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Liability type</mat-label>
            <mat-select formControlName="liability_type_ex" placeholder="Select liability type" required>
              <mat-option *ngFor="let liability_type of liability_types" [value]="liability_type.key">{{liability_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="liability_type_ex.hasError('required')">
              Liability type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="liability_type_ex.value==='Other'">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other liability description</mat-label>
            <input formControlName="other_description" matInput placeholder="Describe other liability" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="other_description.hasError('required')">
              Describe other liability is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="liability_type_ex.value==='MortgageLoan'">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Mortgage type</mat-label>
              <mat-select formControlName="mortgage_type" placeholder="Select mortgage type" [required]="liability_type_ex.value==='MortgageLoan'||liability_type_ex.value==='HELOC'">
                <mat-option *ngFor="let mortgage_type of mortgage_types" [value]="mortgage_type.key">{{mortgage_type.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="mortgage_type.hasError('required')">
                Mortgage type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row pb-3" *ngIf="liability_type_ex.value==='MortgageLoan'">
          <div class="col-lg-6">
            Does the liability include payment for taxes and insurance?
          </div>
          <div class="col-lg-6">
            <mat-radio-group aria-label="Liability includes taxes and insurance" formControlName="liability_includes_ti">
              <mat-radio-button [checked]="liability_includes_ti.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="liability_includes_ti.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Real estate owned asset ID</mat-label>
              <mat-select [required]="liability_type_ex.value==='HELOC'||liability_type_ex.value==='MortgageLoan'" formControlName="reo_asset_id" placeholder="Select real estate owned asset ID">
                <mat-option *ngFor="let reo_asset of reo_assets" [value]="reo_asset.key">{{reo_asset.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="liability_type_ex.hasError('required')">
                Real estate owned asset ID is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Creditor name</mat-label>
            <input formControlName="creditor_name" matInput placeholder="Creditor name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="creditor_name.hasError('required')">
              Creditor name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Account number</mat-label>
            <input formControlName="account_no" matInput placeholder="Account number" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="account_no.hasError('required')">
              Account number is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Monthly payment amount</mat-label>
            <input appDecimalOnly formControlName="monthly_payment_amount" matInput placeholder="Monthly payment amount" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthly_payment_amount.hasError('required')">
              Monthly payment amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Months left</mat-label>
            <input appNumberOnly formControlName="months_left" matInput placeholder="Months left" maxlength="3">
            <mat-error *ngIf="months_left.hasError('required')">
              Months left is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Unpaid balance</mat-label>
            <input appDecimalOnly formControlName="unpaid_balance" matInput placeholder="Unpaid balance"  maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="unpaid_balance.hasError('required')">
              Unpaid balance is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit limit</mat-label>
            <input appDecimalOnly formControlName="credit_limit" matInput placeholder="Credit limit (if applicable)" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="credit_limit.hasError('required')">
              Credit limit is required
            </mat-error>
            <mat-hint>Applicable to liabilities such as HELOC or revolving credit</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Will liability be paid off prior to closing?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Liability will be paid off prior to closing" formControlName="will_be_paid">
            <mat-radio-button [checked]="will_be_paid.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="will_be_paid.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Omit liability from credit report?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Omit liability from credit report" formControlName="omit_from_credit_report">
            <mat-radio-button [checked]="omit_from_credit_report.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="omit_from_credit_report.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
