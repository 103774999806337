import {Component, Injector, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {CompBaseComponent} from '../../../../comp-base.component';

@Component({
  selector: 'app-view-segment-finalize-loan',
  templateUrl: './view-segment-finalize-loan.component.html',
})
export class ViewSegmentFinalizeLoanComponent extends CompBaseComponent implements OnInit {
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createForm();
    this.reload();
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  finalizeLoan(): void {
    this.loanAppService.finalizeLoanApp(this.doc.id)
      .then((data) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan finalized successfully');
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to finalize loan', data);
      });
  }

  reload(force?: boolean): void {
    this.loanAppService.getCurrentLoanDocument()
      .then((document: Document) => {
        this.doc = document;
      });
  }
}
