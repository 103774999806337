import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Details} from '../../../../models/details';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';

@Component({
  selector: 'app-edit-segment-details',
  templateUrl: './edit-segment-details.component.html',
})
export class EditSegmentDetailsComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Details;

  // form and form controls
  form: FormGroup;
  purchase_price: FormControl;
  repairs: FormControl;
  refinance: FormControl;
  prepaid_items: FormControl;
  closing_costs: FormControl;
  funding_fee: FormControl;
  discount: FormControl;
  subordinate_financing: FormControl;
  closing_costs_seller: FormControl;
  funding_financed: FormControl;
  cash_from_to_borrower: FormControl;
  from_borrower: FormControl;
  non_property_debt_payoff: FormControl;
  excessive_financing: FormControl;
  public isRefinanceLoan: boolean;
  public isPurchaseLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'details';
    this.section = 'lo';
    this.reload();
  }

  reload(force?: boolean): void {
    this.isPurchaseLoan = this.analyzerService.isPurchaseLoan(this.doc.recursive_attributes);
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    if (this.isPurchaseLoan) {
      this.purchase_price = new FormControl(item.purchase_price, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
    } else {
      this.purchase_price = new FormControl(item.purchase_price, Validators.pattern(globals.pattern.decimalTwo));
    }
    this.repairs = new FormControl(item.repairs, Validators.pattern(globals.pattern.decimalTwo));
    if (this.isRefinanceLoan) {
      this.refinance = new FormControl(item.refinance, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
    } else {
      this.refinance = new FormControl(item.refinance, Validators.pattern(globals.pattern.decimalTwo));
    }
    this.prepaid_items = new FormControl(item.prepaid_items, Validators.pattern(globals.pattern.decimalTwo));
    this.closing_costs = new FormControl(item.closing_costs, Validators.pattern(globals.pattern.decimalTwo));
    this.funding_fee = new FormControl(item.funding_fee, Validators.pattern(globals.pattern.decimalTwo));
    this.discount = new FormControl(item.discount, Validators.pattern(globals.pattern.decimalTwo));
    this.subordinate_financing = new FormControl(item.subordinate_financing, Validators.pattern(globals.pattern.decimalTwo));
    this.closing_costs_seller = new FormControl(item.closing_costs_seller, Validators.pattern(globals.pattern.decimalTwo));
    this.funding_financed = new FormControl(item.funding_financed, Validators.pattern(globals.pattern.decimalTwo));
    this.cash_from_to_borrower = new FormControl(item.cash_from_to_borrower, Validators.pattern(globals.pattern.decimalTwo));
    this.from_borrower = new FormControl(item.from_borrower);
    this.non_property_debt_payoff = new FormControl(item.non_property_debt_payoff);
    this.excessive_financing = new FormControl(item.excessive_financing);
  }

  createForm(): void {
    this.form = new FormGroup({
      repairs: this.repairs,
      purchase_price: this.purchase_price,
      refinance: this.refinance,
      prepaid_items: this.prepaid_items,
      closing_costs: this.closing_costs,
      funding_fee: this.funding_fee,
      discount: this.discount,
      subordinate_financing: this.subordinate_financing,
      closing_costs_seller: this.closing_costs_seller,
      funding_financed: this.funding_financed,
      cash_from_to_borrower: this.cash_from_to_borrower,
      from_borrower: this.from_borrower,
      excessive_financing: this.excessive_financing,
      non_property_debt_payoff: this.non_property_debt_payoff
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Details(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();
    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  getCashFromToBorrowerString(): string {
    switch (this.from_borrower.value) {
      case true:
        return 'Cash from borrower';
      case false:
        return 'Cash to borrower';
      case null:
        return 'Cash from/to borrower';
    }
  }
}
