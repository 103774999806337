import {Component, Injector, OnInit} from '@angular/core';
import {Expense} from '../../../../models/expense';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-expenses',
  templateUrl: './view-segment-expenses.component.html',

})
export class ViewSegmentExpensesComponent extends CompBaseComponent implements OnInit {
  public isCoBorrower: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'la_expenses';
    this.section = null;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.isCoBorrower = this.analyzerService.isCoBorrower(this.doc.recursive_attributes);
    this.loan_app = this.doc.recursive_attributes;
    this.items = Expense.deserializeArray(this.loan_app.la_expenses.coll);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
