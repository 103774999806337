import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Organization} from '../models/organization';
import {User} from '../models/user';

@Injectable()
export class AgentService {

  constructor(private httpService: HttpService) {
  }

  public createAccount(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.post('agent/create_account/uploads', payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateUser(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`agent/update_user`, payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public removeUser(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('agent/remove_user', params)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // Comment: the server limits the search only to AUX organizations
  public findOrganization(name: string, orgId: number): Promise<Array<Organization>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`agent/find_org`, {name: name, organization_id: orgId})
        .then((data) => {
          resolve(Organization.deserializeArray(data));
        })
        .catch((data) => {
          console.log('FAILED', data);
          reject(null);
        });
    });
  }

  public findOrganizationById(orgId: number): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`agent/find_org_by_id/${orgId}`)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createOrganization(params: Object): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.httpService.post('agent/create_org', params)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateOrganization(orgId: number, params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`agent/update_org/${orgId}`, params)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
