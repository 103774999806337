<mat-card class="mt-2 mb-1">
  <mat-card-title>Construction & Renovation
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" class="pb-2">
      <div class="row pt-1 pb-1">
        <div class="col fw-bold pr-font-large">
          Renovation
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-6 pb-2 pt-2 my-auto">
          Will all or part of the subject loan proceeds be used to finance the renovation of an existing property?
        </div>
        <div class="col-lg-6 my-auto text-start">
          <mat-radio-group formControlName="renovation_loan_indicator" aria-label="renovation_loan_indicator">
            <mat-radio-button [checked]="renovation_loan_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="renovation_loan_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1">
        <div class="col-lg-4">
          <mat-form-field class="w-100">
            <input appDecimalOnly formControlName="construction_improvements_cost" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Construction improvements cost" type="text">
            <mat-error *ngIf="construction_improvements_cost.hasError('pattern')">
              Construction improvements cost is invalid
            </mat-error>
            <mat-error *ngIf="construction_improvements_cost.hasError('required')">
              Construction improvements cost required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray pt-1 pb-1">
        <div class="col-lg-6 my-auto">
          Energy-related improvements?
        </div>
        <div class="col-lg-6 text-start">
          <mat-radio-group formControlName="energy_improvements_indicator" aria-label="energy_improvements_indicator">
            <mat-radio-button [checked]="energy_improvements_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="energy_improvements_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1">
        <div class="col fw-bold pr-font-large">
          Construction
        </div>
      </div>

      <div class="row pt-1 pb-1">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="construction_loan_type" placeholder="Select construction loan type">
              <mat-option *ngFor="let construction_loan_type of construction_loan_types" [value]="construction_loan_type.key">{{construction_loan_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="construction_loan_type.hasError('required')">
              Construction loan type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="construction_closing_type" placeholder="Select construction-to-permanent closing type">
              <mat-option *ngFor="let construction_closing_type of construction_closing_types" [value]="construction_closing_type.key">{{construction_closing_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="construction_loan_type.hasError('required')">
              Construction-to-permanent closing type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3 mt-1">
        <div class="col-lg-6 my-auto">
          Conversion of contract for deed?
        </div>
        <div class="col-lg-6 text-start">
          <mat-radio-group formControlName="contract_for_deed_indicator" aria-label="contract_for_deed_indicator">
            <mat-radio-button [checked]="contract_for_deed_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="contract_for_deed_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="w-100">
            <input appDecimalOnly formControlName="land_original_cost" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Land original cost amount" type="text">
            <mat-error *ngIf="land_original_cost.hasError('pattern')">
              Land original cost amount is invalid
            </mat-error>
            <mat-error *ngIf="land_original_cost.hasError('required')">
              Land original cost amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="land_appraisal_type" placeholder="Select land appraisal type">
              <mat-option *ngFor="let land_appraisal_type of land_appraisal_types" [value]="land_appraisal_type.key">{{land_appraisal_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="land_appraisal_type.hasError('required')">
              Land appraisal type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="w-100">
            <input appDecimalOnly formControlName="land_appraised_value" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Land appraised value" type="text">
            <mat-error *ngIf="land_appraised_value.hasError('pattern')">
              Land appraised value is invalid
            </mat-error>
            <mat-error *ngIf="land_appraised_value.hasError('required')">
              Land appraised value is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-6 my-auto">
          Energy efficient home?
        </div>
        <div class="col-lg-6 text-start">
          <mat-radio-group formControlName="energy_efficient_indicator" aria-label="energy_efficient_indicator">
            <mat-radio-button [checked]="energy_efficient_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="energy_efficient_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
      <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
      <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
    </form>
  </mat-card-content>
</mat-card>
