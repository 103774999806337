import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {AdminService} from '../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Tran} from '../../../models/tran';

@Component({
  selector: 'app-admin-tran',
  template: '',
})
export class AdminTranComponent extends CompBaseComponent implements OnInit {
  @Input() public tran: Tran;
  @Input() tranId: number;
  public op: string;
  public adminService: AdminService;
  public route: ActivatedRoute;
  public router: Router;

  constructor(public injector: Injector) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
