<div class="row">
  <div *ngIf="mode==='View'" class="col">
    <app-view-segment-comp-event (eventEmitted)="onEvent($event)" [item]="item" [tran]="tran"></app-view-segment-comp-event>
  </div>
</div>

<div class="row">
  <div *ngIf="mode==='Edit'" class="col">
    <app-edit-segment-comp-event (eventEmitted)="onEvent($event)" [item]="item" [tran]="tran"></app-edit-segment-comp-event>
  </div>
</div>
