import {Component, Injector, OnInit} from '@angular/core';
import {SharedService} from '../shared-service';
import {UserService} from '../../services/user.service';

@Component({
  // moduleId: module.id,
  // tslint:disable-next-line
  selector: 'site-wrapper',
  templateUrl: 'wrapper.component.html',
  providers: [SharedService]
})
export class WrapperLayoutComponent implements OnInit {
  userService: UserService;
  isAuth: boolean;

  constructor(private injector: Injector) {
    this.userService = this.injector.get(UserService);
    this.isAuth = !!this.userService.getUserSync();
  }

  ngOnInit() {
  }
}
