<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Compliance</mat-card-title>

  <mat-card-content>

    <p>
      Compliance refers to activities that must be completed in specific time frames. Our Compliance
      Engine will do the work for you so long as you supply correct dates for the compliance events.
      Pay attention to the background colors of the Compliance report.
      They are explained at the top of the report.
      The two shades of yellow refer to compliance events that must be completed either by today or tomorrow.
      You can also run what-if scenarios.
    </p>

    <span class="fw-bold">Start Date</span>
    <p>
      All compliance events are computed starting from the mandatory Start Date.
    </p>

    <span class="fw-bold">Supply Compliance Date</span>
    <ul>
      <li>
        Click anywhere on the relevant row.
      </li>
      <li>
        Supply the Occurred On date and click Update.
      </li>
    </ul>

    <span class="fw-bold">Simulate Other Dates</span>
    <p>
      You can run what-if scenarios by supplying any future date. This feature is useful if you want to get a
      response to questions like "will I be compliant tomorrow if I do nothing today?"
    </p>

    <span class="fw-bold">To Simulate a Future Date</span>
    <ul>
      <li>
        Click Simulate Another Date.
      </li>
      <li>
        Enter a future date.
      </li>
      <li>
        Click Run Simulation
      </li>
      <li>
        When done, click Exit Simulation
      </li>
    </ul>

  </mat-card-content>
</mat-card>
