import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {User} from '../models/user';
import {Organization} from '../models/organization';
import {Tran} from '../models/tran';
import {Order} from "../models/order";
import {Payment} from "../models/payment";

@Injectable()
export class AdminService {

  constructor(private httpService: HttpService) {
  }

  public findUserById(userId: number): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_user_by_id/${userId}`)
        .then((data) => {
          resolve(new User(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findUser(first: string, last: string): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      this.httpService.get('admin/find_user', {first: first, last: last})
        .then((data) => {
          resolve(User.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findOrders(user_id: number): Promise<Array<Order>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_orders/${user_id}`)
        .then((data) => {
          resolve(Order.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findOrder(order_id: number): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_order/${order_id}`)
        .then((data) => {
          resolve(new Order(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findPayments(order_id: number): Promise<Array<Payment>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_payments/${order_id}`)
        .then((data) => {
          resolve(Payment.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findTran(name: string): Promise<Array<Tran>> {
    return new Promise((resolve, reject) => {
      this.httpService.get('admin/find_tran', {name: name})
        .then((data) => {
          resolve(Tran.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findTranById(tranId: number): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_tran_by_id/${tranId}`)
        .then((data) => {
          resolve(new Tran(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findLoanOfficer(first: string, last: string): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      this.httpService.get('admin/find_loan_officer', {first: first, last: last})
        .then((data) => {
          resolve(User.deserializeArray(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public grantLOAccess(tranId: number, userId: number): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.put('admin/grant_lo_access', {tran_id: tranId, lo_id: userId})
        .then((data) => {
          resolve(new Tran(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public revokeLOAccess(tranId: number, lo_id: number): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.put('admin/revoke_lo_access', {tran_id: tranId, lo_id: lo_id})
        .then((data) => {
          resolve(new Tran(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public findOrganization(name: string, orgId: number): Promise<Array<Organization>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_org`, {name: name, organization_id: orgId})
        .then((data) => {
          resolve(Organization.deserializeArray(data));
        })
        .catch((data) => {
          console.log('FAILED', data);
          reject(null);
        });
    });
  }

  public findOrganizationById(orgId: number): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`admin/find_org_by_id/${orgId}`)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public createOrganization(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('admin/create_org', params)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateOrganization(orgId: number, params: Object): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`admin/update_org/${orgId}`, params)
        .then((data) => {
          resolve(new Organization(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createAccount(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.post('admin/create_account/uploads', payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createUid(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.post('admin/create_uid', payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateUser(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('admin/update_user', params)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public setOrgAdmin(userId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`admin/set_org_admin/${userId}`, {})
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public removeFromOrg(userId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`admin/remove_from_org/${userId}`, {})
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public addToOrg(userId: number, orgId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`admin/add_to_org/${userId}`, {organization_id: orgId})
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public hardResetPW(payload: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('admin/hard_reset_pw', payload)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public deleteUser(userId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`admin/delete_user/${userId}`)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public sendSocketMessage(toEmail: string, message: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`admin/send_socket_message`, {email: toEmail, message: message})
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
