<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Data&nbsp;
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div *ngIf="isRefinanceLoan || isConstructionLoan">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Property acquired date</mat-label>
              <input [matDatepicker]="$any(datePicker)" [min]="minDate" [max]="maxDate" formControlName="property_acquired_date" matInput placeholder="Property acquired date">
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="property_acquired_date.hasError('required')">
                Property acquired date is required
              </mat-error>
              <mat-error *ngIf="property_acquired_date.hasError('matDatepickerMin')">
                Property acquired date is unrealistic
              </mat-error>
              <mat-error *ngIf="property_acquired_date.hasError('matDatepickerMax')">
                Property acquired date is in the future
              </mat-error>
              <mat-error *ngIf="property_acquired_date.hasError('matDatepickerParse')">
                Property acquired date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="isRefinanceLoan">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Refinance purpose</mat-label>
              <mat-select formControlName="refinance_purpose_code_ex" placeholder="Select refinance purpose">
                <mat-option *ngFor="let refinance_purpose_code of refinance_purpose_codes_ex"
                  [value]="refinance_purpose_code.key">{{refinance_purpose_code.value}}
                </mat-option>
                <mat-error *ngIf="refinance_purpose_code_ex.hasError('required')">
                  Refinance purpose is required
                </mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Refinance cash-out type</mat-label>
              <mat-select formControlName="refinance_cash_out_type" placeholder="Select refinance cash-out type">
                <mat-option *ngFor="let refinance_cash_out_type of refinance_cash_out_types"
                  [value]="refinance_cash_out_type.key">{{refinance_cash_out_type.value}}
                </mat-option>
                <mat-error *ngIf="refinance_cash_out_type.hasError('required')">
                  Refinance cash-out type is required
                </mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="refinance_purpose_code_ex.value === 'HomeImprovement'">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Improvements status</mat-label>
              <mat-select [required]="improvements_made_ex.value === 'HomeImprovement'" formControlName="improvements_made_ex" placeholder="Select improvements status">
                <mat-option *ngFor="let status of improvements_statuses_ex"
                  [value]="status.key">{{status.value}}
                </mat-option>
                <mat-error *ngIf="improvements_made_ex.hasError('required')">
                  Improvement status is required
                </mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Home improvements cost</mat-label>
              <input appDecimalOnly formControlName="home_improvements_cost" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Home improvements cost" type="text">
              <mat-error *ngIf="home_improvements_cost.hasError('required')">
                Home improvements cost is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>

</mat-card>
