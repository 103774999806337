import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';
import {Organization} from '../../../../models/organization';
import {OrganizationService} from '../../../../services/organization.service';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-view-loan-mcrs-container',
  templateUrl: './view-loan-mcrs-container.component.html',
})
export class ViewLoanMcrsContainerComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Loan MCRs';
  public user: User;
  public organization: Organization;
  public orgService: OrganizationService;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    this.orgService = this.injector.get(OrganizationService);
    this.reload(true);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const service = this.user.isAgent() ? this.orgService : this.userService;
    service.getDefaultOrganization()
      .then((org) => {
        if (org) {
          this.organization = org;
          this.sharedService.eventEmitted.emit({type: 'ChangeOrg'});
          this.reloaded = true;
        }
      }).catch(() => {
      this.organization = null;
      this.reloaded = true;
    }).finally(() => {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
          help: Help.helpKeys.loansList
        });
      }, 0);

    });
  }

  cancel(): void {
    this.reload();
  }
}
