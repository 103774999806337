import {ModelBase} from './model-base';

export class Note extends ModelBase {
  public id: number;
  public user_id: number;
  public by: string;
  public title: string;
  public body: string;
  public created_at: Date;
  public updated_at: Date;

  constructor(object: any) {
    super();
    if (!object) {
      return;
    }
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Note> {
    const result = [];
    for (const obj of arr) {
      const item = new Note(obj);
      result.push(item);
    }

    return result;
  }
}
