import {ChangeDetectionStrategy, Component, Injectable, Injector, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CalendarEvent, CalendarEventTimesChangedEvent, CalendarUtils} from 'angular-calendar';
import {addWeeks, endOfMonth, startOfMonth, subWeeks} from 'date-fns';
import {GetMonthViewArgs, MonthView} from 'calendar-utils';
import {Trace} from '../../../models/trace';
import {Tran} from '../../../models/tran';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from '../../form-util';
import {DeviceService} from '../../../services/device.service';

@Injectable()
export class PrCalendarUtils extends CalendarUtils {
  getMonthView(args: GetMonthViewArgs): MonthView | any {
    args.viewStart = subWeeks(startOfMonth(args.viewDate), 1);
    args.viewEnd = addWeeks(endOfMonth(args.viewDate), 1);
    return super.getMonthView(args);
  }
}

@Component({
  selector: 'app-bulk-update-tasks',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './bulk-update-tasks.component.html',
  providers: [
    {
      provide: CalendarUtils,
      useClass: PrCalendarUtils
    }
  ]
})
export class BulkUpdateTasksComponent extends CompBaseComponent implements OnInit {
  @Input() public currentState: string;
  @Input() allTraces: Array<Trace>;
  @Input() tran: Tran;
  viewDate: Date;
  events: CalendarEvent[] = [];
  isMobile: boolean;
  firstDate: Date;
  lastDate: Date;

  refresh: Subject<any> = new Subject();

  constructor(public injector: Injector) {
    super(injector);
    const today = new Date();
    this.viewDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.isMobile = DeviceService.isMobile;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reload(null);
    this.firstTaskMonth();
  }

  reload(event: any): void {
    const allDates = this.allTraces.map((elt) => elt.started_at);
    this.firstDate = allDates.length ? allDates[0] : new Date();
    this.lastDate = allDates.length ? allDates[allDates.length - 1] : new Date();
    this.allTraces.forEach((elt) => {
      const title = this.isMobile ? this.getShortStateDisplay(elt.state_display) : elt.state_display;
      if (elt.state === this.currentState) {
        elt.color = 'dark';
      } else if (elt.hidden) {
        elt.color = 'yellow';
      } else {
        if (elt.done === true) {
          elt.color = 'green';
        } else {
          elt.color = 'red';
        }
      }
      this.events.push({title: title, start: elt.started_at, meta: {state: elt.state, color: elt.color}});
    });
  }

  getShortStateDisplay(state): string {
    switch (state) {
      case 'Credit Authorization':
        return 'CrAut';
      case 'Credit Check':
        return 'CrChk';
      case 'Loan application':
        return 'LoApp';
      case 'Loan Documentation':
        return 'LoDoc';
      case 'Loan Estimate':
        return 'LE';
      case 'Closing Disclosure':
        return 'CD';
      case 'Lender Underwriting':
        return 'Under';
      case 'Funding':
        return 'Fund';
      case 'Closing':
        return 'Close';
      case 'Appraisal':
        return 'Aprsl';
      default:
        return state.substr(0, 5);
    }
  }

  eventTimesChanged(
    {
      event,
      newStart,
      newEnd
    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next(null);
  }

  lastTaskMonth(): void {
    this.viewDate = new Date(this.lastDate.getFullYear(), this.lastDate.getMonth(), 1);
  }

  firstTaskMonth(): void {
    this.viewDate = new Date(this.firstDate.getFullYear(), this.firstDate.getMonth(), 1);
  }

  nextMonth(): void {
    this.viewDate = new Date(this.viewDate.setMonth(this.viewDate.getMonth() + 1));
  }

  previousMonth(): void {
    this.viewDate = new Date(this.viewDate.setMonth(this.viewDate.getMonth() - 1));
  }

  today(): void {
    const date = new Date();
    this.viewDate = new Date(date.getFullYear(), date.getMonth(), 1);
  }

  update(): void {
    const data = this.events.reduce((c, event) => {
      c.push({state: event.meta.state, started_at: event.start});
      return c;
    }, []);

    this.workflowService.updateBulkTraces(this.tran.id, data)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Uncompleted Tasks successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch(() => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update Uncompleted Tasks', data);
        console.log('BAD');
      });
  }
}
