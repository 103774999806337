import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {ArmData} from '../../../../models/arm-data';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-arm-data',
  templateUrl: './edit-segment-arm-data.component.html',
})
export class EditSegmentArmDataComponent extends CompBaseComponent implements OnInit {
  public index_types_new = ArmData.index_types_new;
  public index_types_other = ArmData.index_types_other;
  @Input() public item: ArmData;

  // form and form controls
  form: FormGroup;
  index: FormControl;
  index_type_new: FormControl;
  index_margin: FormControl;
  qualifying_rate: FormControl;
  other_description: FormControl;
  first_adjustment_months_no: FormControl;
  subsequent_adjustment_months_no: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'arm_data';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.index = new FormControl(item.index, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.percentThree)]));
    this.index_type_new = new FormControl(item.index_type_new);
    this.index_margin = new FormControl(item.index_margin, Validators.pattern(globals.pattern.percentThree));
    this.qualifying_rate = new FormControl(item.qualifying_rate, Validators.pattern(globals.pattern.percentThree));
    this.other_description = new FormControl(item.other_description);
    this.first_adjustment_months_no = new FormControl(item.first_adjustment_months_no);
    this.subsequent_adjustment_months_no = new FormControl(item.subsequent_adjustment_months_no);
  }

  createForm(): void {
    this.form = new FormGroup({
      index: this.index,
      index_type_new: this.index_type_new,
      index_margin: this.index_margin,
      qualifying_rate: this.qualifying_rate,
      other_description: this.other_description,
      first_adjustment_months_no: this.first_adjustment_months_no,
      subsequent_adjustment_months_no: this.subsequent_adjustment_months_no,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new ArmData(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
