<app-card [align]="'center'" [title]="'New Device Detected'">
  <span class="material-icons">devices</span>
  <p>
    A new device has been detected.
    Please check for an email from us to register your new device.
  </p>
  <div class="additional-info">
    <a (click)="navigateToSign($event)" href="/pages/sign-in">Return to Sign in</a>
  </div>
</app-card>
