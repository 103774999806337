import {Component, Injector, Input, OnInit} from '@angular/core';
import {Borrower} from '../../../../models/borrower';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-borrower',
  templateUrl: './view-segment-borrower.component.html',

})
export class ViewSegmentBorrowerComponent extends CompBaseComponent implements OnInit {
  @Input() item: Borrower;
  public title: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = this.applicant;
    this.section = this.applicant;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.title = this.getFormTitle();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  getBorrowerTitle(borrowerAbbr: string): string {
    const marriedToTitle = this.analyzerService.getBorrowerTitle(borrowerAbbr);
    if (marriedToTitle.toLocaleLowerCase().startsWith('unknown')) {
      return 'Not Married to Any Borrower'
    }
    return marriedToTitle;
  }

  getFormTitle(): string {
    switch (this.applicant) {
      case 'borrower_1':
        return 'Borrower';
      case 'borrower_2':
        return 'Co-Borrower';
      case 'borrower_3':
        return  'Borrower 3';
      case 'borrower_4':
        return 'Borrower 4';
    }
  }
}
