<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Previous Employments'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            Additional employment data is required if applicant employed in current position for less than two years.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-sec-employment (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-sec-employment>

    <div *ngFor="let item of items">
      <app-view-sec-employment (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-sec-employment>
    </div>
  </mat-card>
</div>
