import {Component, Injector, OnInit} from '@angular/core';
import {NewMortgage} from '../../../../models/new-mortgage';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-new-mortgages',
  templateUrl: './view-segment-new-mortgages.component.html',

})
export class ViewSegmentNewMortgagesComponent extends CompBaseComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'new_mortgages';
    this.section = 'loan_information';
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.items = NewMortgage.deserializeArray(this.doc.recursive_attributes.new_mortgages);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
