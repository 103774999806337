import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {UserService} from '../../services/user.service';
import {PermissionService} from '../../services/permission.service';
import {DeviceService} from '../../services/device.service';

@Component({
  selector: 'app-help-base',
  template: ''
})
export class HelpBaseComponent implements OnInit {
  @Output() public eventEmitted = new EventEmitter();
  public sharedService: SharedService;
  public userService: UserService;
  public permissionService: PermissionService;
  public isLoanOfficer: boolean;
  public isLoanAssistant: boolean;
  public isProcessor: boolean;
  public isLoanProfessional: boolean;
  public isBorrower: boolean;
  public isMobile: boolean;

  constructor(public injector: Injector) {
    this.sharedService = this.injector.get(SharedService);
    this.userService = this.injector.get(UserService);
    this.permissionService = this.injector.get(PermissionService);
  }

  toggleHelp(): void {
    this.sharedService.eventEmitted.emit({type: 'ToggleHelp'});
  }

  ngOnInit() {
    this.isProcessor = this.userService.getUserSync().isProcessor();
    this.isLoanOfficer = this.userService.getUserSync().isLoanOfficer();
    this.isLoanAssistant = this.userService.getUserSync().isLoanAssistant();
    this.isLoanProfessional = this.userService.getUserSync().isLoanProfessional();
    this.isBorrower = this.userService.getUserSync().isBorrower();
    this.isMobile = DeviceService.isMobile;
  }
}
