import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
})
export class DocumentComponent extends CompBaseComponent implements OnInit {
  @Input() document: Document;
  @Input() folderId: string;
  public mode: string;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  // comment: do not percolate change mode
  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }

    super.onEvent(event);
  }
}
