<!-- Unsubscibed tested on dev Sep 23, 2021 -->
<app-card *ngIf="!verified" [align]="'center'" [title]="'Unsubscribe Email'" [outline]="true" class="mt-5">
  <app-box *ngIf="error" [close]="true" [type]="'danger'" [fadeOut]="true">
    {{error}}
  </app-box>

  <form [formGroup]="form" novalidate>
    <div class="row mb-4">
      <div class="col text-start">
        <b>Email:</b>&nbsp;{{email.value}}
      </div>
    </div>

    <div class="row mt-2">
      <div class="col text-start">
        <button (click)="unsubscribe($event)" class="btn btn-lg btn-primary">Unsubscribe</button>
        <button (click)="cancel($event)" class="ms-2 btn btn-lg btn-light">Cancel</button>
      </div>
    </div>
  </form>

  <div class="additional-info">
    &nbsp;
  </div>
</app-card>

<app-card *ngIf="verified" [align]="'center'" [title]="'Email Unsubscribed'" [outline]="true" class="mt-5">
  <span class="material-icons">check_circle</span>

  <p>
    <strong>Email was unsubscribed!</strong>
  </p>

  <div class="additional-info">
    You may close this window.
  </div>
</app-card>
