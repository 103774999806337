import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {CalcComponent} from '../calc/calc.component';
import {LoanCalc} from '../../../models/loan-calc';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-edit-segment-calc-monthly',
  templateUrl: '../calc/edit-segment-calc.component.html',
})
export class EditSegmentCalcMonthlyComponent extends CalcComponent implements OnInit {
  @ViewChild('loanAmountElt') loanAmountElt: ElementRef;
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: 'Calculator',
        help: Help.helpKeys.accountMLO
      });
    }, 0);
    this.createFormControls();
    this.createForm();
    this.reload();
    this.setFocus();
  }

  clear() {
    this.loan = new LoanCalc({});
    this.reload();
    this.setFocus();
  }

  setFocus() {
    setTimeout(() => {
      if (this.loanAmountElt) {
        this.loanAmountElt.nativeElement.focus();
      }
    }, 0);
  }
}
