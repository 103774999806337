import {Component, Injector, Input, OnInit} from '@angular/core';
import {ArmData} from '../../../../models/arm-data';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-arm-data',
  templateUrl: './view-segment-arm-data.component.html',

})
export class ViewSegmentArmDataComponent extends CompBaseComponent implements OnInit {
  @Input() item: ArmData;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
