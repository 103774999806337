import {Component, Injector, OnInit} from '@angular/core';
import {Concession} from '../../../../models/concession';
import {ConcessionComponent} from '../concession/concession.component';

@Component({
  selector: 'app-add-concession',
  templateUrl: '../concession/edit-segment-concession.component.html',
})
export class AddConcessionComponent extends ConcessionComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Concession({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
