<mat-card class="mb-2">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-2 hand-pointer" (click)="tran.isLoanOfficerTran()?edit():null">
        <img [src]="item.thumbnail" style="max-width: 40px;" alt="Contact Photo">
      </div>
      <div class="col-lg-8 hand-pointer my-auto" (click)="tran.isLoanOfficerTran()?edit():null">
        <div>
          <span class="fw-bold">{{item.acl_full_name}}</span><span [hidden]="isMobile">&nbsp;({{item.acl_email}})</span>
        </div>
        <div class="row" *ngIf="!isMobile">
          <div class="col-lg-4">
            <span class="fw-bold">Role:</span> {{item.roleDescription}}
          </div>
          <div *ngIf="tran.isLoanOfficerTran()" class="col-lg-4">
            <span class="fw-bold">Access:</span> {{item.toPermissionString()}}
          </div>
          <div *ngIf="tran.isLoanOfficerTran()&&resource_type==='transaction'" class="col-lg-4">
            <span class="fw-bold">Repo/Docs access:</span> {{item.dirAccess | booleanToString}}
          </div>
        </div>
        <div *ngIf="isMobile">
          <span>{{item.roleDescription}}:&nbsp;</span>
          <span>{{item.toPermissionString()}}</span>
        </div>
      </div>
      <div class="col-lg-2 text-end hand-pointer my-auto" title="Delete user access">
        <a (click)="delete($event)"><i class="text-danger fas fa-trash"></i></a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
