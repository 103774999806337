<app-simple-navbar></app-simple-navbar>

<div class="row upload spinner mt-4 w-100">
  <div class="col-lg-3 text-center mx-auto pr-border">
    <p class="pr-font-large p-3">
      <span class="fw-bold">Signing out of POLOS</span> <br>
      See you next time!
    </p>
  </div>
</div>
