import {Component, Injector, OnInit} from '@angular/core';
import {EmailComponent} from '../email/email.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-send-invite-email',
  templateUrl: './edit-invite-email.component.html',
})
export class SendInviteEmailComponent extends EmailComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.reload();
  }

  createFormControls(): void {
    this.firstName = new FormControl(null, AppValidators.present);
    this.lastName = new FormControl(null, AppValidators.present);
    this.email = new FormControl(null, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
  }

  createForm(): void {
    this.form = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.getContacts(force)
      .then(() => {
        this.createFormControls();
        this.createForm();
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
