import {Component, Injector, OnInit} from '@angular/core';
import {HelpBaseComponent} from '../../../help-base.component';

@Component({
  selector: 'app-help-message',
  templateUrl: './help-message.component.html',

})
export class HelpMessageComponent extends HelpBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
