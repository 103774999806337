import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProductCharacteristics} from '../../../../models/product-characteristics';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-product-characteristics',
  templateUrl: './edit-segment-product-characteristics.component.html',
})
export class EditSegmentProductCharacteristicsComponent extends CompBaseComponent implements OnInit {
  public readonly negative_amortization_types = ProductCharacteristics.negative_amortization_types;
  @Input() public item: ProductCharacteristics;
  public submitted: boolean;

  // form and form controls
  form: FormGroup;
  interest_only: FormControl;
  prepayment_penalty_indicator: FormControl;
  negative_amortization_indicator: FormControl;
  interest_only_months_no: FormControl;
  negative_amortization_type: FormControl;
  prepayment_months_no: FormControl;
  fixed_period_months_no: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'product_characteristics';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.interest_only = new FormControl(item.interest_only);
    this.prepayment_penalty_indicator = new FormControl(item.prepayment_penalty_indicator);
    this.negative_amortization_indicator = new FormControl(item.negative_amortization_indicator);
    this.interest_only_months_no = new FormControl(item.interest_only_months_no);
    this.negative_amortization_type = new FormControl(item.negative_amortization_type);
    this.prepayment_months_no = new FormControl(item.prepayment_months_no);
    this.fixed_period_months_no = new FormControl(item.fixed_period_months_no);
  }

  createForm(): void {
    this.form = new FormGroup({
      interest_only: this.interest_only,
      prepayment_penalty_indicator: this.prepayment_penalty_indicator,
      negative_amortization_indicator: this.negative_amortization_indicator,
      negative_amortization_type: this.negative_amortization_type,
      interest_only_months_no: this.interest_only_months_no,
      prepayment_months_no: this.prepayment_months_no,
      fixed_period_months_no: this.fixed_period_months_no
    });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new ProductCharacteristics(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
