<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Other expense type</div>
      <div class="col-lg-6">{{item.getSupportType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.support_type_ex!== 'Other'">
      <div class="col-lg-6 fw-bold">Support description</div>
      <div class="col-lg-6">{{item.support_type_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Monthly payment amount</div>
      <div class="col-lg-6">{{item.monthly_payment_amount | currency}}&nbsp;</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Months left</div>
      <div class="col-lg-6">{{item.months_left}}&nbsp;</div>
    </div>
  </mat-card-content>
</mat-card>
