import {Component, Injector, Input, OnInit} from '@angular/core';
import {SecEmployment} from '../../../../models/sec-employment';
import {SecEmploymentComponent} from '../sec-employment/sec-employment.component';

@Component({
  selector: 'app-view-segment-sec-employment',
  templateUrl: './view-segment-sec-employment.component.html',

})
export class ViewSegmentSecEmploymentComponent extends SecEmploymentComponent implements OnInit {
  @Input() item: SecEmployment;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
