import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FormUtil} from "../../form-util";

@Component({
  selector: 'app-view-loan-apps',
  templateUrl: './view-loan-apps.component.html',
})
export class ViewLoanAppsComponent extends CompBaseComponent implements OnInit {
  @Input() public loanApps: Array<Document>;
  public actionUrl: string;
  public isDefaultLoanApp: boolean;
  public isLoanApp: boolean;
  public urlSafe: SafeResourceUrl;
  public downloading: boolean;
  private url: string;
  public loanAppDocId: number;

  constructor(public injector: Injector, public sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View'
    this.actionUrl = `trans/mismo_3_4_upload?transaction_item_id=LA01&tran_id=${this.tran.id}`;
    this.isLoanApp = this.loanApps && (this.loanApps.length > 0);
    this.isDefaultLoanApp = !!this.tran.getDefaultLoanApp();
    this.loanAppService.getCurrentLoanDocument()
      .then((loanAppDoc: Document) => {
        if (loanAppDoc) {
          this.loanAppDocId = loanAppDoc.id;
          this.url = `api/documents/export_mismo/${loanAppDoc.id}`;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        }
      });
  }

  upload(): void {
    this.mode = 'Upload';
  }

  export(): void {
    this.downloading = false;
    this.downloading = true;
    setTimeout(() => {
      this.onUploadDownloadComplete();
    }, 1000);

  }

  duplicate(): void {
    this.docService.copyDocument(this.loanAppDocId)
      .then((doc) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application successfully duplicated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to duplicate loan application', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
      });
  }


  cancel(): void {
    this.mode = 'View';
  }

  onUploadDownloadComplete(): void {
    this.tranService.getCurrentTran(true)
      .then(() => {
        this.mode = 'View';
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      });
  }
}
