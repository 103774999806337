import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';
import {Customer} from "../../../../models/customer";

@Component({
  selector: 'app-customer',
  template: '',
})
export class CustomerComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Customer;
  public form: FormGroup;
  public email: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createFormControls(): void {
    this.email = new FormControl('', Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
  }

  createForm(): void {
    this.form = new FormGroup({email: this.email});
  }

  delete(event: any) {
    event.preventDefault();
    //todo:
    this.userService.deleteContact(this.item.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Customer successfully deleted');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete customer', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
      });
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    this.addCustomer(this.email.value);
  }

  addCustomer(email: string): void {
    const payload = {
      name: email
    };

    //todo:
    this.userService.addContact(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Customer successfully added');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to add customer', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
      });
  }
}
