<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on New Loan</mat-card-title>
  <mat-card-content>
    <ul>
      <li>
        Your new loan's name must be unique.
      </li>
    </ul>
  </mat-card-content>
</mat-card>
