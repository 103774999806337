import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from '../../form-util';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Router} from '@angular/router';
import {ConfigItem} from "../../../models/config-item";
import {ConfigurationService} from "../../../services/configuration.service";

@Component({
  selector: 'app-config',
  templateUrl: './edit-segment-config.component.html',
})
export class ConfigComponent extends CompBaseComponent implements OnInit {
  @Input() item: ConfigItem;
  public configService: ConfigurationService;
  public op: string;
  public form: FormGroup;
  public config_key: FormControl;
  public config_value: FormControl;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.configService = this.injector.get(ConfigurationService);
  }

  createFormControls(item: ConfigItem): void {
    this.config_key = new FormControl(item.config_key, AppValidators.present);
    this.config_value = new FormControl(item.config_value, AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      config_key: this.config_key,
      config_value: this.config_value,
    });
  }

  delete(): void {
    this.configService.deleteConfigItem(this.item.config_key)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    this.configService.createConfigItem(this.config_key.value, this.config_value.value)
      .then(() => {
        this.router.navigate(['admin/configs']);
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create config item', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    this.configService.updateConfigItem(this.config_key.value, this.config_value.value)
      .then(() => {
        this.router.navigate(['admin/configs']);
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update config item', errorData);
      });
  }
}
