<mat-card class="mt-2 mb-1">
  <mat-card-title>Property Information&nbsp;
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Address</mat-label>
            <input type="text" formControlName="property_address" matInput placeholder="Address" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="property_address.hasError('required')">
              Address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Apt/Unit/Suite</mat-label>
            <input type="text" formControlName="property_address2" matInput placeholder="Apt/Unit/Suite" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="property_address2.hasError('required')">
              Apt/Unit/Suite is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>City</mat-label>
            <input type="text" formControlName="property_city" matInput placeholder="City" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="property_city.hasError('required')">
              City is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>State abbreviation</mat-label>
            <input type="text" formControlName="property_state" appAlphaOnly matInput appUpperCase placeholder="State abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            <mat-error *ngIf="property_state.hasError('required')">
              State is required
            </mat-error>
            <mat-error *ngIf="property_state.hasError('pattern')">
              State name is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip</mat-label>
            <input type="text" appNumberOnly formControlName="property_zip" matInput placeholder="Zip" required maxlength="{{MAX_LEN.ZIP}}">
            <mat-error *ngIf="property_zip.hasError('required')">
              Zip is required
            </mat-error>
            <mat-error *ngIf="property_zip.hasError('pattern')">
              Zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip four</mat-label>
            <input type="text" appNumberOnly formControlName="property_zip_four" matInput placeholder="Zip four" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="property_zip_four.hasError('required')">
              Zip four is required
            </mat-error>
            <mat-error *ngIf="property_zip_four.hasError('pattern')">
              Zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>County</mat-label>
            <input formControlName="property_county" matInput placeholder="County" type="text" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="property_county.hasError('required')">
              County is required
            </mat-error>
            <mat-error *ngIf="property_county.hasError('pattern')">
              County is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>MSA identifier</mat-label>
            <input type="text" formControlName="msa_id" appNumberOnly matInput placeholder="MSA identifier" maxlength="5">
            <mat-error *ngIf="msa_id.hasError('required')">
              MSA identifier is required
            </mat-error>
            <mat-error *ngIf="msa_id.hasError('pattern')">
              MSA identifier is invalid
            </mat-error>
            <mat-hint>Use FIPS code (5 digits)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Census Tract Identifier</mat-label>
            <input type="text" formControlName="census_id" matInput placeholder="Census Tract Identifier" maxlength="7">
            <mat-error *ngIf="census_id.hasError('required')">
              Census Tract Identifier is required
            </mat-error>
            <mat-error *ngIf="census_id.hasError('pattern')">
              Census Tract Identifier is invalid
            </mat-error>
            <mat-hint>Format: nnnn.nn or nnnnnn</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100 mb-5">
            <mat-label>Number of units financed</mat-label>
            <input type="text" appNumberOnly formControlName="units_no" matInput placeholder="Number of units financed"  maxlength="3" required>
            <mat-error *ngIf="units_no.hasError('required')">
              Number of units financed is required
            </mat-error>
            <mat-error *ngIf="units_no.hasError('min') || units_no.hasError('max')">
              Number of units financed is invalid
            </mat-error>
            <mat-hint>For conventional loans, number of units financed must be between 1 and 4</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Year built</mat-label>
            <input type="text" appNumberOnly formControlName="year_built" matInput placeholder="Year built" maxlength="4" required>
            <mat-error *ngIf="year_built.hasError('required')">
              Year built is required
            </mat-error>
            <mat-error *ngIf="year_built.hasError('min') || year_built.hasError('max')">
              Year built is unrealistic
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Property type</mat-label>
            <mat-select formControlName="property_type" placeholder="Select property type" required>
              <mat-option *ngFor="let property_type of property_types"
                [value]="property_type.key">{{property_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="property_type.hasError('required')">
              Property type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="property_type.value==='Other'">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>Other property type explanation</mat-label>
            <input type="text" formControlName="property_type_other" matInput placeholder="Explain other property type" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="property_type_other.hasError('required')">
              Other property type is required
            </mat-error>
            <mat-error *ngIf="property_type_other.hasError('pattern')">
              Other property type is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Attachment type</mat-label>
            <mat-select formControlName="attachment_type" placeholder="Select attachment type" required>
              <mat-option *ngFor="let attachment_type of attachment_types"
                [value]="attachment_type.key">{{attachment_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="attachment_type.hasError('required')">
              Attachment type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray" *ngIf="property_type.value==='Other'">
        <div class="col-lg-6 my-auto">
          Is this property a multi-family dwelling (5 or more units)?
        </div>
        <div class="col-lg-6">
          <mat-radio-group aria-label="Multi-family dwelling" formControlName="multi_family">
            <mat-radio-button [checked]="multi_family.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="multi_family.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row hover-gray">
        <div class="col-lg-6 my-auto">
          Is the property located in a project per trading partner guidelines?
        </div>
        <div class="col-lg-6">
          <mat-radio-group aria-label="property in project" formControlName="in_project">
            <mat-radio-button [checked]="in_project.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="in_project.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mb-3 hover-gray">
        <div class="col-lg-6 my-auto">
          Is the property located in a PUD?
        </div>
        <div class="col-lg-6">
          <mat-radio-group aria-label="property in PUD?" formControlName="in_pud">
            <mat-radio-button [checked]="in_pud.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="in_pud.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col fw-bold pr-font-large">
          Property in Project
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Project design type</mat-label>
            <mat-select formControlName="design_type" placeholder="Select project design type">
              <mat-option *ngFor="let design_type of design_types"
                [value]="design_type.key">{{design_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="land_type.hasError('required')">
              Project design type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Project legal structure</mat-label>
            <mat-select formControlName="legal_structure" placeholder="Select project legal structure">
              <mat-option *ngFor="let legal_structure of legal_structures"
                [value]="legal_structure.key">{{legal_structure.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="legal_structure.hasError('required')">
              Project legal structure is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col fw-bold pr-font-large">
          Native American or Hawaiian Land Types
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Native American/Hawaiian land type</mat-label>
            <mat-select formControlName="land_type" placeholder="Select Native American/Hawaiian land type">
              <mat-option *ngFor="let land_type of land_types"
                [value]="land_type.key">{{land_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="land_type.hasError('required')">
              Land type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="land_type.value==='Other'">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>Other land type description</mat-label>
            <input type="text" formControlName="land_type_other_description" matInput placeholder="Describe other land type" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="land_type_other_description.hasError('required')">
              Land type other description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
