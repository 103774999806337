<mat-card *ngIf="reloaded" class="mt-1">
  <mat-card-title>Hard Reset User Password</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="user_id" matInput placeholder="User ID" required>
            <mat-error *ngIf="user_id.hasError('required')">
              User ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="pw" matInput placeholder="Password" required>
            <mat-error *ngIf="pw.hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Reset Form</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
