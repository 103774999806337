import {Component, Injector, Input, OnInit} from '@angular/core';
import {Details} from '../../../../models/details';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-details',
  templateUrl: './view-segment-details.component.html',

})
export class ViewSegmentDetailsComponent extends CompBaseComponent implements OnInit {
  @Input() item: Details;

  public isRefinanceLoan: boolean;
  public isPurchaseLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isPurchaseLoan = this.analyzerService.isPurchaseLoan(this.doc.recursive_attributes);
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
  }
}
