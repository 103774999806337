import {Component, Injector, OnInit} from '@angular/core';
import {LoanCharacteristics} from '../../../../models/loan-characteristics';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-loan-characteristics',
  templateUrl: './view-loan-characteristics.component.html',
})
export class ViewLoanCharacteristicsComponent extends CompBaseComponent implements OnInit {
  public item: LoanCharacteristics;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new LoanCharacteristics(this.doc.recursive_attributes['loan_characteristics']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
