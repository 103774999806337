import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {Organization} from '../../../../models/organization';
import {AgentService} from '../../../../services/agent.service';
import {AgentOrganizationComponent} from '../organization.component';
import {FormUtil} from '../../../form-util';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-view-agent-organization',
  templateUrl: './view-agent-organization.component.html',
})
export class ViewAgentOrganizationComponent extends AgentOrganizationComponent implements OnInit {
  public user: User;
  public orgId: number;
  public organization: Organization;
  public pageTitle = 'View Organization';
  public mode = 'View';

  constructor(public injector: Injector,
              public router: Router,
              public route: ActivatedRoute) {
    super(injector);
    this.agentService = this.injector.get(AgentService);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.route.paramMap.subscribe((map) => {
      this.orgId = map['params']['id'];
      this.reload();
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    this.agentService.findOrganizationById(this.orgId)
      .then((org) => {
        this.organization = org;
        this.orgService.setDefaultOrganization(org);
        this.sharedService.eventEmitted.emit({type: 'ChangeOrg'});
        this.reloaded = true;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Organization not found', data);
      });
  }

  editOrganization(event: any): void {
    event.preventDefault();
    this.router.navigate(['/agent/edit-organization', this.organization.id]);
  }

  editUser(event: any, user: User) {
    this.user = user;
    this.mode = 'EditUser';
  }
}
