<div *ngIf="reloaded">
  <mat-card class="mt-1 mb-3 w-50">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [header]="'User Account Status'" [help]="false"></app-segment-header>
    </mat-card-title>

    <mat-card-content>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-3 fw-bold">User ID</div>
        <div class="col-lg-9">{{userAccount.user_id}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-3 fw-bold">Status</div>
        <div class="col-lg-9">{{userAccount.getAccountStatus()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-3 fw-bold">Subscription term</div>
        <div class="col-lg-9">{{userAccount.getSubscriptionTerm()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-3 fw-bold">Expires on</div>
        <div class="col-lg-9">{{userAccount.expires_on}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-3 fw-bold">Created at</div>
        <div class="col-lg-9">{{userAccount.created_at | date: 'medium'}}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <div *ngIf="userAccount.status == uaStatuses.NONE || userAccount.status == uaStatuses.ERROR">
    <app-box [type]="'warning'" class="text-white" [close]="false">
      <strong>There isn't any service subscription for your account. Please select service subscription at
        <a class="pr-blue-bold" routerLink="/account/subscriptions">Account/Subscriptions</a>.</strong>
    </app-box>
  </div>

  <div *ngIf="userAccount.status == uaStatuses.PAST_DUE">
    <app-box [type]="'danger'" class="text-white" [close]="true">
      <strong>The account is past due!</strong>
    </app-box>
  </div>

  <div *ngIf="userAccount.status == uaStatuses.OK || userAccount.status == uaStatuses.OUTSTANDING || userAccount.status == uaStatuses.PAST_DUE">
    <div *ngFor="let invoice of invoices">
      <app-view-invoice (eventEmitted)="onEvent($event)" [item]="invoice">
      </app-view-invoice>
    </div>

    <app-box [type]="'primary'" [close]="true" *ngIf="invoices.length===0" [close]="true" [fadeOut]="true">
      No outstanding invoices!
    </app-box>
  </div>
</div>
