<mat-card class="mb-2">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-2 ">
        <img alt="Thumbnail" src="{{thumbnailPath}}" style="max-width: 30px;">
      </div>

      <div class="col-lg-6">
        <span *ngIf="!isMobile" class="fw-bold">{{type==='sent' ? 'To: ' : 'From: '}}</span>
        <span [hidden]="type==='sent'">{{message.caption || 'System'}}</span>&nbsp;
        <span [hidden]="type==='received'">{{message.caption || 'System'}}</span>&nbsp;
        <span [hidden]="!message.is_sms" class="badge badge-info">SMS</span>
      </div>

      <div *ngIf="!isMobile" class="col-lg-3">
        <span>{{message.created_at | date:'MM/dd/yyyy'}}</span>
      </div>
      <div *ngIf="isMobile" class="col-lg-3">
        <span>{{message.created_at | date:'MM/dd'}}</span>
      </div>
      <div class="col-lg-1 text-end">
        <a (click)="delete($event)" class=""><i class="text-danger fa fa-trash"></i></a>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-10 d-flex">
        <div class="fw-bold">Message:</div>&nbsp;
        <app-read-more [textLength]=125 [text]="message.message_text">
        </app-read-more>
      </div>
    </div>
  </mat-card-content>
</mat-card>
