import {Component, Injector, Input, OnInit} from '@angular/core';
import {Income} from '../../../../models/income';
import {IncomeComponent} from '../income/income.component';

@Component({
  selector: 'app-view-segment-income',
  templateUrl: './view-segment-income.component.html',

})
export class ViewSegmentIncomeComponent extends IncomeComponent implements OnInit {
  @Input() item: Income;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
