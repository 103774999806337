<form [formGroup]="form" class="pb-2">
  <mat-form-field class="md-icon-left w-100">
    <mat-label>Document name</mat-label>
    <input formControlName="name" matInput placeholder="Document name" maxlength="{{MAX_LEN.GEN_NAME}}">
    <mat-error *ngIf="name.hasError('required')">
      Name is required
    </mat-error>
    <mat-error *ngIf="name.hasError('minlength')">
      Name is too short
    </mat-error>
  </mat-form-field>

  <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
  <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
  <button (click)="delete()" class="ms-2 btn btn-lg btn-danger">Delete</button>
</form>
