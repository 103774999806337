<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Fannie Mae Institution ID'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          <span class="fw-bold">Fannie Mae Institution ID.</span>
          This field is required to resubmit loans to the appropriate institution.
          If not provided, Fannie Mae default institution for the user will be used.
          If this institution differs from the original submission, an exception will occur.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Fannie Mae institution ID</div>
      <div class="col-lg-6">{{item.institution_id}}</div>
    </div>
  </mat-card-content>
</mat-card>
