import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
})
export class EditDocumentComponent extends CompBaseComponent implements OnInit {
  @Input() document: Document;
  public form: FormGroup;
  public name: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.name = new FormControl(this.document.name, Validators.compose([AppValidators.present, Validators.minLength(2)]));
  }

  createForm(): void {
    this.form = new FormGroup({name: this.name});
  }

  delete(): void {
    this.docService.deleteDocument(this.document.id)
      .then((data) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Document successfully deleted');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete document', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
        return;
      });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }

    this.docService.updateDocument(this.document.id, this.form.value)
      .then((data) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Document successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update document', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }
}
