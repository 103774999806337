<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Subscriptions
  </mat-card-title>

  <mat-card-content>
    <div>
      The subscriptions page display your current services, subscription, and the charges.
    </div>
  </mat-card-content>
</mat-card>
