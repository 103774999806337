<mat-card *ngIf="reloaded">
  <mat-card-content>
    <div>
      <app-box *ngIf="!isDefaultLoanApp&&isCurrentTran" [close]="false" [type]="'warning'" class="pt-2">
        The
        <span class="fw-bold">loan application</span> link doesn't display because there is
        <span class="fw-bold">no default loan application</span>.
      </app-box>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h5 class="mb-1">Home</h5>
        <ul>
          <li>
            <a class="pr-blue-bold" routerLink="/home/services">Your Services</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/home/highlights">Account Highlights</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/home/lo-info">Loan Owner's Info</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/home/site-layout">Site Map</a> (this page)
          </li>
        </ul>

        <h5 class="mb-1">Account</h5>

        <ul>
          <li>
            <a class="pr-blue-bold" routerLink="/account/settings">Account Settings</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/account/services">Your Services</a>
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/account/mlo-configuration">MLO Configuration</a>
          </li>
          <li [hidden]="!isLoanOfficer">
            <a class="pr-blue-bold" routerLink="/account/user-organization">Organization</a>
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/account/branding">Branding</a>
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/account/invite">Invite Users</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/account/view-contacts">Contacts</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/account/reset-password">Reset Password</a>
          </li>
        </ul>

        <h5 class="mb-1">ezUploads</h5>
        <ul>
          <li>
            <a class="pr-blue-bold" routerLink="/ez-uploads/ez-uploads-instructions">ezUploads Instructions</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/ez-uploads/secured-uploads">ezUploads</a>
          </li>
        </ul>

        <h5 class="mb-1">Communication</h5>
        <ul>
          <li>
            <a class="pr-blue-bold" routerLink="/loan/view-messages">Messages</a> (send system and/or text messages)
          </li>
          <li [hidden]="!hasPOLOSService">
            <a class="pr-blue-bold" routerLink="/loan/view-emails">Emails</a>
          </li>
        </ul>
      </div>

      <div class="col-lg-6">
        <h5 class="mb-1">MCR</h5>
        <app-box *ngIf="!hasMCRService" [close]="false" [type]="'warning'" class="pt-2">
          No MCR Wizard access.
        </app-box>

        <ul [hidden]="!hasMCRService">
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/mcr/view-wizard">MCR Wizard</a>
          </li>
        </ul>

        <h5 class="mb-1">Loans</h5>

        <ul>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loans/loan-officer-dashboard">My Dashboard</a>
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/loans/list">My Loans</a>
          </li>
          <li [hidden]="!hasMCRService">
            <a class="pr-blue-bold" routerLink="/loans/lo-mcr/list">My MCRs</a>
          </li>
          <li [hidden]="!isLoanOfficer">
            <a class="pr-blue-bold" routerLink="/loans/new-loan">New Loan</a>
          </li>
        </ul>

        <h5 class="mb-1">Current Loan</h5>
        <app-box *ngIf="!isCurrentTran" [close]="false" [type]="'warning'" class="pt-2">
          The
          <b>Current Loan's</b> links are not displayed because no loan was selected from <a class="pr-blue-bold" routerLink="/loans/list">My Loans</a>.
        </app-box>

        <ul [hidden]="!isCurrentTran">
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/view-dashboard">Loan Dashboard</a>
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/conditions">Conditions</a> (manage loan conditions)
          </li>
          <li [hidden]="(!isLoanProfessional&&!isBorrower)">
            <a class="pr-blue-bold" routerLink="/loan/view-directory">Repository</a> (manage loan records)
          </li>
          <li [hidden]="(!isLoanProfessional&&!isBorrower)||!isDefaultLoanApp">
            <a class="pr-blue-bold" routerLink="/loan/view-loan-app">Loan Application</a> (manage loan application)
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/view-workflow">Workflow</a> (manage workflow)
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/view-tasks">Tasks</a> (manage workflow tasks)
          </li>
          <li>
            <a class="pr-blue-bold" routerLink="/loan/view-timeline">Timeline</a>
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/view-comp-events">Compliance</a> (manage compliance)
          </li>
          <li [hidden]="!isLoanProfessional">
            <a class="pr-blue-bold" routerLink="/loan/view-notes">Notes</a> (record loan notes)
          </li>
          <li [hidden]="!isLoanOfficer">
            <a class="pr-blue-bold" routerLink="/loan/view-access-control">Access Control</a> (manage user access)
          </li>
        </ul>
      </div>
    </div>

  </mat-card-content>
</mat-card>
