import {ModelBase} from './model-base';

export class TranMcr extends ModelBase {
  public static loan_disposition_codes = [
    {key: 'AC080', value: 'Application in process'},
    {key: 'AC070', value: 'Application closed and funded'},
    {key: 'AC030', value: 'Application approved but not accepted'},
    {key: 'AC040', value: 'Application denied'},
    {key: 'AC050', value: 'Application withdrawn'},
    {key: 'AC060', value: 'File closed for incompleteness'},
    {key: 'ACMOV', value: 'Loan moved to another state'},
  ];
  public static loan_purpose_codes = [
    {key: 'AC300', value: 'Home purchase'},
    {key: 'AC310', value: 'Home improvement'},
    {key: 'AC320', value: 'Refinancing'},
  ];
  public static reverse_purpose_codes = [
    {key: 'AC800', value: 'Home purchase'},
    {key: 'AC810', value: 'Other'},
  ];
  public static qm_codes = [
    {key: 'AC920', value: 'Qualified Mortgage (QM)'},
    {key: 'AC930', value: 'Non-Qualified Mortgage (Non-QM)'},
    {key: 'AC940', value: 'Not subject to QM'},
  ];
  public static loan_type_codes = [
    {key: 'AC100', value: 'Conventional'},
    {key: 'AC110', value: 'FHA-insured'},
    {key: 'AC120', value: 'VA-guaranteed'},
    {key: 'AC130', value: 'FSA/RHS-guaranteed'},
    {key: 'PR099', value: 'Not Conventional'},
  ];
  public static property_type_codes = [
    {key: 'AC200', value: 'One to four family dwelling'},
    {key: 'AC210', value: 'Manufactured housing'},
    {key: 'AC220', value: 'Multifamily dwelling'},
  ];
  public static hecm_type_codes = [
    {key: 'AC700', value: 'HECM-Standard'},
    {key: 'AC710', value: 'HECM-Saver'},
    {key: 'AC720', value: 'Proprietary/Other'},
  ];
  public static lien_status_codes = [
    {key: 'AC500', value: 'First lien'},
    {key: 'AC510', value: 'Subordinate lien'},
    {key: 'AC520', value: 'Not secured by a lien'},
  ];

  public tran_id: number;
  public is_hecm: boolean;
  public is_hoepa: boolean;
  public is_brokered: boolean;
  public state_changed: string;
  public is_repurchased: boolean;
  public loan_amount: number;
  public prev_loan_amount: number;
  public from_borrower: boolean;
  public loan_disposition_code: string;
  public loan_purpose_code: string;
  public property_type_code: string;
  public qm_code: string;
  public loan_type_code: string;
  public hecm_type_code: string;
  public lien_status_code: string;
  public reverse_purpose_code: string;
  public start_date: string;
  public end_date: string;
  public broker_fee: Number;
  public lender_fee: Number;

  constructor(object: any) {
    super();
    this.tran_id = null;
    this.loan_amount = null;
    this.prev_loan_amount = null;
    this.from_borrower = true;
    this.is_hecm = null;
    this.is_hoepa = null;
    this.is_brokered = true;
    this.state_changed = 'N';
    this.is_repurchased = false; // default is_repurchased to false
    this.start_date = null;
    this.end_date = null;
    this.broker_fee = null;
    this.lender_fee = null;
    this.loan_disposition_code = null;
    this.property_type_code = null;
    this.qm_code = null;
    this.loan_purpose_code = null;
    this.loan_type_code = null;
    this.hecm_type_code = null;
    this.lien_status_code = null;
    this.reverse_purpose_code = null;
    if (object) {
      for (const prop of Object.keys(object)) {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<TranMcr> {
    const result = [];
    for (const obj of arr) {
      const item = TranMcr.deserialize(obj);
      result.push(item);
    }

    return result;
  }

  static deserialize(obj): TranMcr {
    return new TranMcr(obj);
  }

  getLoanDisposition(): string {
    const found = TranMcr.loan_disposition_codes.find((elt) => elt.key === this.loan_disposition_code);
    return found ? found['value'] : '';
  }

  getPropertyType(): string {
    const found = TranMcr.property_type_codes.find((elt) => elt.key === this.property_type_code);
    return found ? found['value'] : '';
  }

  getQMClassification(): string {
    const found = TranMcr.qm_codes.find((elt) => elt.key === this.qm_code);
    return found ? found['value'] : '';
  }

  getHecmType(): string {
    const found = TranMcr.hecm_type_codes.find((elt) => elt.key === this.hecm_type_code);
    return found ? found['value'] : '';
  }

  getReversePurpose(): string {
    const found = TranMcr.reverse_purpose_codes.find((elt) => elt.key === this.reverse_purpose_code);
    return found ? found['value'] : '';
  }

  getLoanPurpose(): string {
    const found = TranMcr.loan_purpose_codes.find((elt) => elt.key === this.loan_purpose_code);
    return found ? found['value'] : '';
  }

  getLoanType(): string {
    const found = TranMcr.loan_type_codes.find((elt) => elt.key === this.loan_type_code);
    return found ? found['value'] : '';
  }

  getLienStatus(): string {
    const found = TranMcr.lien_status_codes.find((elt) => elt.key === this.lien_status_code);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      tran_id: this.tran_id,
      is_hecm: this.is_hecm,
      is_hoepa: this.is_hoepa,
      is_brokered: this.is_brokered,
      state_changed: this.state_changed,
      is_repurchased: this.is_repurchased,
      loan_amount: this.loan_amount,
      prev_loan_amount: this.prev_loan_amount,
      from_borrower: this.from_borrower,
      loan_disposition_code: this.loan_disposition_code,
      loan_purpose_code: this.loan_purpose_code,
      property_type_code: this.property_type_code,
      qm_code: this.qm_code,
      loan_type_code: this.loan_type_code,
      hecm_type_code: this.hecm_type_code,
      lien_status_code: this.lien_status_code,
      reverse_purpose_code: this.reverse_purpose_code,
      start_date: this.start_date,
      end_date: this.end_date,
      broker_fee: this.broker_fee,
      lender_fee: this.lender_fee,
    };
  }
}
