<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Real Estate Owned'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>Create a new entry for each real estate you own.</li>
          <li>For a refinance loan, the
            <span class="fw-bold">FIRST</span> entry should be the real estate property you are refinancing.
          </li>
          <li>The system generates a number called a Real Estate Asset ID for each entry you create.
            This ID is used in the Liabilities section to associate one or more liabilities with this real estate.
          </li>
          <li>
            <span class="fw-bold">Expenses:</span> Include maintenance and association fees, if applicable.
            Include taxes and homeowners insurance, if they are not part of your liabilities (i.e., paid by your lender).
          </li>
          <li>You can edit or delete entries.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-real-estate (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-real-estate>

    <div *ngFor="let item of items">
      <app-view-real-estate (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-real-estate>
    </div>
  </mat-card>
</div>
