<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Invoices
  </mat-card-title>

  <mat-card-content>
    <div>
      The subscriptions page display your current and previous invoices. This is also where you pay your invoices
    </div>
  </mat-card-content>
</mat-card>
