import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {globals} from '../../shared/globals/globals';

@Component({
  selector: 'app-login-from-polos',
  templateUrl: './login-from-auth.component.html',
  styleUrls: ['./login-from-auth.component.scss']
})
export class LoginFromAuthComponent implements OnInit {
  public pageTitle: string;
  public verified: boolean;
  public processed: boolean;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService) {
  }

  ngOnInit() {
    this.verified = false;
    this.processed = false;
    window.localStorage.clear();
    this.pageTitle = 'Sign in';
    this.route.queryParams.subscribe((params) => {
      const accessToken = params['access_token'];
      this.userService.loginFromAuth(accessToken)
        .then(() => {
          this.verified = true;
          this.processed = false;
          this.router.navigate(['/']);
        })
        .catch((data) => {
          this.router.navigate(['/pages/sign-in-failed']);
          console.log('login failed', data);
        });
    });
  }

  navigateToSignIn(event: any) {
    event.preventDefault();
    window.location.href = `${globals.getAuthServer()}/auth/signin_with_device`;
  }
}
