import {Component, Injector, OnInit} from '@angular/core';
import {Expense} from '../../../../models/expense';
import {ExpenseComponent} from '../expense/expense.component';

@Component({
  selector: 'app-add-expense',
  templateUrl: '../expense/edit-segment-expense.component.html',
})
export class AddExpenseComponent extends ExpenseComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Expense({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
