import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-sticky-note',
  template: '',

})
export class StickyNoteComponent extends CompBaseComponent implements OnInit {
  public comment: string;
  public comments: any;
  @Input() section = '';
  @Input() segment = '';

  // form and form controls
  public form: FormGroup;
  public body: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createFormControls() {
    this.body = new FormControl(this.comment);
  }

  createForm() {
    this.form = new FormGroup({
      body: this.body,
    });
  }

  // comment: do not percolate change mode
  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }

    super.onEvent(event);
  }

  getComment(): string {
    const comments = this.getComments();
    if (comments && comments[this.section] && comments[this.section][this.segment]) {
      let result = comments[this.section][this.segment];
      result = result.trim();
      return result.length ? result : null;
    }
    return null;
  }

  getComments(): any {
    if (!this.doc['comments']) {
      return {};
    }
    return JSON.parse(this.doc['comments']);
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }

    const comments = Object.assign(this.comments, {});
    if (!comments[this.section]) {
      comments[this.section] = {};
    }
    comments[this.section][this.segment] = this.body.value;

    this.docService.updateDocumentComments(this.doc.id, comments)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Instructions successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update instructions', data);
        this.cancel();
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  editNote(event: any): void {
    if (event) {
      event.preventDefault();
    }
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
  }
}
