import moment from 'moment';

export class CacheManager {
  static dict = {
    currentOrgId: 'currentOrgId',
    currentTranId: 'currentTranId',
    currentUserId: 'currentUserId',
    currentInvoiceId: 'currentInvoiceId',
    currentPopup: 'currentPopup'
  };

  constructor() {
  }

  static storeCurrentOrgId(orgId: number): void {
    if (typeof orgId === 'number') {
      window.localStorage.setItem(CacheManager.dict.currentOrgId, orgId.toString());
    }
  }

  static getCurrentOrgId(): number {
    const currentOrgId = window.localStorage.getItem(CacheManager.dict.currentOrgId);
    return currentOrgId ? +currentOrgId : null;
  }

  static storeCurrentTranId(tranId: number): void {
    if (typeof tranId === 'number') {
      window.localStorage.setItem(CacheManager.dict.currentTranId, tranId.toString());
    }
  }

  static getCurrentTranId(): number {
    const currentTranId = window.localStorage.getItem(CacheManager.dict.currentTranId);
    return currentTranId ? +currentTranId : null;
  }

  static getCurrentUserId(): number {
    const currentUserId = window.localStorage.getItem(CacheManager.dict.currentUserId);
    return currentUserId ? +currentUserId : null;
  }

  static resetCurrentInvoiceId(): void {
    window.localStorage.setItem(CacheManager.dict.currentInvoiceId, null);
  }

  static storeCurrentUserId(userId: number): void {
    if (+this.getCurrentUserId() !== userId) {
      window.localStorage.clear();
    }
    if (typeof userId === 'number') {
      window.localStorage.setItem(CacheManager.dict.currentUserId, userId.toString());
    }
  }

  static storeCurrentInvoiceId(invoiceId: number): void {
    if (typeof invoiceId === 'number') {
      window.localStorage.setItem(CacheManager.dict.currentInvoiceId, invoiceId.toString());
    }
  }

  static getCurrentInvoiceId(): number {
    const currentInvoiceId = window.localStorage.getItem(CacheManager.dict.currentInvoiceId);
    return currentInvoiceId ? +currentInvoiceId : null;
  }

  static getCurrentDate(): string {
    return moment().format('YYYY-MM-DD');
  }

  static getCurrentPopupDate(): any {
    const retrievedObject = localStorage.getItem(CacheManager.dict.currentPopup);
    // comment: there is weird behavior whereby the retrieved object is the string 'null'
    return typeof (retrievedObject) === 'string' && retrievedObject !== 'null' ? JSON.parse(retrievedObject)['date'] : null;
  }

  static storeCurrentPopupDate(): void {
    const dtString = moment().format('YYYY-MM-DD');
    const popupObject = {'date': dtString};
    localStorage.setItem(CacheManager.dict.currentPopup, JSON.stringify(popupObject));
  }

  static resetCache(): void {
    window.localStorage.clear()
  }
}
