<mat-card class="mt-2 mb-1">
  <mat-card-title>HMDA</mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="update()" [formGroup]="form" novalidate>

      <div class="row pb-2 pt-2 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          Ethnicity collected on the basis of visual observation or surname?
        </div>
        <div class="col text-end">
          <mat-radio-group formControlName="ethnicity_visual" aria-label="ethnicity_visual">
            <mat-radio-button [checked]="ethnicity_visual.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="ethnicity_visual.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pb-2 pt-2 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          Sex collected on the basis of visual observation or surname?
        </div>
        <div class="col text-end">
          <mat-radio-group formControlName="gender_visual" aria-label="gender_visual">
            <mat-radio-button [checked]="gender_visual.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="gender_visual.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pb-2 pt-2 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          Race collected on the basis of visual observation or surname?
        </div>
        <div class="col text-end">
          <mat-radio-group formControlName="race_visual" aria-label="race_visual">
            <mat-radio-button [checked]="race_visual.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="race_visual.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>HMDA collection method</mat-label>
            <mat-select formControlName="taken_by" placeholder="Select HMDA collection method">
              <mat-option *ngFor="let taken_by of taken_bys" [value]="taken_by.key">{{taken_by.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="taken_by.hasError('required')">
              Method is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>
