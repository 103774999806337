import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appPhoneOnly]'
})
export class PhoneOnlyDirective {
  // Allow  numerals, ".", "-", blank, and parenthesis
  // comment: xxx-xxx-xxxx or xxx.xxx.xxxx or (xxx) xxx-xxxx
  private regex: RegExp = new RegExp(/[ \(\)0-9\-\.]/);
  private specialKeys: Array<string> = ['Enter', 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // allow also all special, meta, and controle keys
    if (this.specialKeys.indexOf(event.key) >= 0 || event.metaKey || event.ctrlKey) {
      return;
    }
    if (!String(event.key).match(this.regex)) {
      event.preventDefault();
    }
  }
}
