import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Invoice} from '../../../../models/invoice';
import {Router} from '@angular/router';
import {InvoiceService} from '../../../../services/invoice.service';
import {FormControl, FormGroup} from "@angular/forms";
import {AppValidators} from "../../../../shared/validators/app-validators.validator";
import {FormUtil} from "../../../form-util";

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
})
export class ViewInvoiceComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Invoice;
  @Output() eventEmitted = new EventEmitter();
  private invoiceService: InvoiceService;
  public form: FormGroup;
  public coupon: FormControl;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.invoiceService = this.injector.get(InvoiceService);
    this.createFormControls();
    this.createForm();
  }

  navToPay(): void {
    // if (this.item.balance <= 0.01) {
    if (this.item.balance <= 10000.00) {
      this.invoiceService
        .markPaid(this.item.id)
        .then(() => {
          FormUtil.genSuccessMessage(this.sharedService, 'Invoice marked paid');
        })
        .then(() => {
          return this.userService.refreshUser()
        })
        .then(() => {
          this.eventService.eventEmitter.emit({
            type: 'Reload',
            component: 'account'
          });
        })
        .catch((data) => {
          FormUtil.genErrorMessage(this.sharedService, 'Failed to navigate to pay or mark invoice as paid', data);
        })
      return;
    }
    this.invoiceService.setCurrentInvoice(this.item);
    this.router.navigate(['/account/invoice/pay']);
  }

  applyCoupon(): void {
    this.invoiceService
      .applyCoupon(this.item.id, this.coupon.value)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Coupon applied successfully to invoice');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to apply coupon', data);
      })
  }

  createFormControls() {
    this.coupon = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      coupon: this.coupon,
    });
  }
}
