import {Component, Injector, OnInit} from '@angular/core';
import {CFAComponent} from '../cfa/cfa.component';

@Component({
  selector: 'app-view-cfa-entry',
  templateUrl: './view-cfa-entry.component.html'
})
export class ViewCFAEntryComponent extends CFAComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    // if another CFA entry is now under edit, then reload (close) this CFA entry
    // without saving field values
    this.eventService.eventEmitter.subscribe((event) => {
      if (event.type === 'editEntry') {
        if (event.hashEntry !== this.hashEntry) {
          this.reload();
        }
      }
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    setTimeout(() => {
      this.mode = 'View';
    }, 0);
  }
}
