<mat-card class="mt-1 mb-3">
  <mat-card-title>Edit Instructions</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Instructions</mat-label>
            <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
              cdkTextareaAutosize
              formControlName="body"
              matInput appAutoFocus
              placeholder="Instructions">
            </textarea>
            <mat-error *ngIf="body.hasError('required')">
              Body is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
