import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable()
export class HomeService {

  constructor(private httpService: HttpService
  ) {
  }

  // unsubscribe. Payload: email of the unsubscribe email
  public unsubscribe(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('home/unsubscribe', payload)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          console.log('FAILED: home.service', data);
          reject(data.error);
        });
    });
  }
}
