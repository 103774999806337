<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Key</div>
      <div class="col-lg-8">{{item.config_key}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 fw-bold">Value</div>
      <div class="col-lg-8">{{item.config_value}}</div>
    </div>
  </mat-card-content>
</mat-card>
