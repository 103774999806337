import {Component, Injector, OnInit} from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {DeviceService} from '../../services/device.service';
import {EmailService} from '../../services/email.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-site',
  template: '',
})
export class SiteComponent implements OnInit {
  public pageTitle = '';
  public reloaded: boolean;
  public sharedService: SharedService;
  public userService: UserService;
  public emailService: EmailService;
  public isMobile: boolean;
  public isAuth: boolean;

  constructor(public injector: Injector) {
    this.userService = this.injector.get(UserService);
    this.isMobile = DeviceService.isMobile;
    this.isAuth = !!this.userService.getUserSync();
  }

  ngOnInit() {
    this.sharedService = this.injector.get(SharedService);
    this.emailService = this.injector.get(EmailService);
  }
}
