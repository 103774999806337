<mat-card *ngIf="reloaded">
  <mat-card-title>{{op === 'Add' ? 'Add Config Item' : 'Edit Config Item'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Key</mat-label>
            <input formControlName="config_key" matInput placeholder="Key" appAutoFocus required>
            <mat-error *ngIf="config_key.hasError('required')">
              Key is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="config_value" matInput placeholder="Value">
            <mat-label>Value</mat-label>
            <mat-error *ngIf="config_value.hasError('required')">
              Value is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary">Add</button>
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
