import {Component, Injector, OnInit} from '@angular/core';
import {FinancialRatioComponent} from '../financial-ratio/financial-ratio.component';

@Component({
  selector: 'app-view-financial-ratio',
  templateUrl: './view-financial-ratio.component.html',
})
export class ViewFinancialRatioComponent extends FinancialRatioComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'Edit';
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      const mode = event['mode'];
      if (mode === 'Edit' && event.payload) {
        this.ql = event.payload;
      }
      this.mode = mode;
      return;
    }

    if (event.type === 'Compute') {
      this.ql = event.payload;
      this.mode = 'View';
      return;
    }

    this.eventEmitted.emit(event);
  }
}
