import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute} from '@angular/router';
import {Message} from "../../../models/message";
import {MessageComponent} from "../message/message.component";

@Component({
  selector: 'app-view-messages',
  templateUrl: './view-messages.component.html',
})
export class ViewMessagesComponent extends MessageComponent implements OnInit, OnDestroy {
  public messageService: MessageService;
  public messagesSent: Array<Message>;
  public messagesReceived: Array<Message>;

  constructor(public injector: Injector, private route: ActivatedRoute) {
    super(injector);
    this.messageService = this.injector.get(MessageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setSeg(null, this.seg.received);
    this.sharedService.eventEmitted.subscribe((event) => {
      if (event.type === 'ToggleNestedMenu') {
        this.setMenuOpen(!this.isOpen);
      }
    });
    this.reload(false);
    this.reloadOnSync();
  }

  onEvent(event: any) {
    if (event.type === 'ChangeMode' && event.mode === 'Add') {
      this.setSeg(null, this.seg.newMessage);
      return;
    }

    if (event.type === 'Reload') {
      this.reload(false);
      this.setSeg(null, this.seg.received);
      return;
    }
  }

  reload(force?: boolean): void {
    if (typeof force === 'undefined') {
      force = true;
    }
    this.reloaded = false;
    this.messagesReceived = [];
    this.messagesSent = [];
    const user = this.userService.getUserSync();
    this.messageService.getMessages(force)
      .then((messages) => {
        messages.forEach((m) => {
          if (+m.from_user_id === +user.id) {
            this.messagesSent.push(m);
          } else if (m.to_user_id === user.id) {
            this.messagesReceived.push(m);
          }
        })
        this.setSeg(null, this.seg.received);
        setTimeout(() => {
          this.reloaded = true;
        });
      });
  }
}
