<div class="pr-font-16" style="border-top:2px solid grey; display: flex;flex-direction: row;flex-wrap: wrap;width: 100%;padding-top: 25px; padding-bottom: 25px;">
  <div *ngIf="!isMobile" style="flex: 33.3%">
    <div class="ms-3 text-start">
      &copy; Copyright 2024 PrudentCO
    </div>
  </div>
  <div style="flex: 33.3%">
    <div class="mx-auto" style="display: flex;align-items: center;justify-content: center;">
      <div class="flex: 33.3%">
        <a class="hand-pointer" href="{{get_page_url('terms')}}">Terms of Use</a>&nbsp;|&nbsp;
      </div>
      <div class="flex: 33.3%">
        <a class="hand-pointer" href="{{get_page_url('privacy')}}">Privacy Policy</a>&nbsp;|&nbsp;
      </div>
      <div class="flex: 33.3%">
        <a class="hand-pointer" href="{{get_page_url('contact')}}">Contact Us</a>
      </div>
    </div>
  </div>
  <div *ngIf="!isMobile" style="flex: 33.3%">
    <div class="text-end me-3">
      Version 3.4.10
    </div>
  </div>
</div>
