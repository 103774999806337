import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {EnvelopeHeader} from '../../../../models/envelope-header';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-envelope-header',
  templateUrl: './edit-segment-envelope-header.component.html',
})
export class EditSegmentEnvelopeHeaderComponent extends CompBaseComponent implements OnInit {
  @Input() public item: EnvelopeHeader;

  // form and form controls
  form: FormGroup;
  institution_id: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'envelope_header';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
    this.reload();
  }

  createFormControls(item): void {
    this.institution_id = new FormControl(item.institution_id);
  }

  createForm(): void {
    this.form = new FormGroup({
      institution_id: this.institution_id,
    });
  }

  populate(): void {
    const config = this.userService.getUserSync().user_configuration;
    this.institution_id.setValue(config.fnma_institution_id);
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new EnvelopeHeader(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
  }
}
