<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [help]="false" [op]="'add'"></app-segment-header>
    </mat-card-title>

    <ul class="pt-4">
      <li [ngClass]="{active:currentSeg===seg.received}">
        <span></span>
        <a (click)="setSeg($event,seg.received)" href="" id="received">Received</a>
      </li>

      <li [ngClass]="{active:currentSeg===seg.sent}">
        <span></span>
        <a (click)="setSeg($event,seg.sent)" href="" id="sent">Sent</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!(isMobile&&isOpen)" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-view-segment-messages (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.received" [messages]="messagesReceived" [type]="seg.received">
      </app-view-segment-messages>
      <app-view-segment-messages (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.sent" [messages]="messagesSent" [type]="seg.sent">
      </app-view-segment-messages>
      <app-send-message (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.newMessage">
      </app-send-message>
    </div>
  </div>
</div>
