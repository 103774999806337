<app-box *ngIf="reloaded&&!workflow.started" class="mt-3" [type]="'warning'">
  The
  <span class="fw-bold">workflow has not yet started!</span>
  You can start the loan workflow from
  <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
</app-box>

<div *ngIf="reloaded&&workflow.started">
  <mat-card>
    <mat-card-title>Reset Uncompleted Tasks</mat-card-title>

    <mat-card-content>
      <div class="row mb-3">
        <div class="col-lg-6">
          <blockquote class="blockquote">
            <p class="mb-0">
              The need to reset uncompleted workflow tasks typically arises when you are revisiting a loan that has been idle for some time.
              By resetting uncompleted tasks, you are moving "uncompleted tasks" task dates to the future.
            </p>
          </blockquote>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="resetFutureTasks()" class="btn btn-lg btn-primary">Reset Uncompleted Tasks</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
