import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Address} from '../../../../models/address';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-address',
  template: '',
})
export class AddressComponent extends CompBaseComponent implements OnInit {
  public residence_types_ex = Address.residence_types_ex;
  public residence_terms_ex = Address.residence_terms_ex;

  // form and form fields
  public form: FormGroup;
  residence_type_ex: FormControl;
  residence_term_ex: FormControl;
  address: FormControl;
  address2: FormControl;
  city: FormControl;
  state: FormControl;
  zip: FormControl;
  zip_four: FormControl;
  country: FormControl;
  years_no: FormControl;
  months_no: FormControl;
  rent: FormControl;
  @Input() items: Array<Address>;

  // required fields
  @Input() item: Address;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'addresses';
    this.section = this.applicant;
  }

  getRemainingResidenceTypes(): Array<any> {
    const all = this.residence_types_ex.map((elt) => elt.key);
    const used = this.items.map((elt) => elt.residence_type_ex);
    const formerAddressIndex = used.indexOf('Prior');
    if (formerAddressIndex >= 0) {
      used.splice(formerAddressIndex, 1);
    }
    const remaining = all.filter(code => used.indexOf(code) < 0);
    return this.residence_types_ex.filter((elt) => remaining.indexOf(elt.key) >= 0);
  }

  createFormControls(item: Address): void {
    this.residence_type_ex = new FormControl(item.residence_type_ex, AppValidators.present);
    this.residence_term_ex = new FormControl(item.residence_term_ex);
    this.address = new FormControl(item.address, AppValidators.present);
    this.address2 = new FormControl(item.address2);
    this.city = new FormControl(item.city, AppValidators.present);
    this.state = new FormControl(item.state, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.state)]));
    this.zip = new FormControl(item.zip, Validators.pattern(globals.pattern.zip));
    this.zip_four = new FormControl(item.zip_four, Validators.pattern(globals.pattern.zip_four));
    this.country = new FormControl(item.country, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.country)]));
    this.years_no = new FormControl(item.years_no,
      Validators.compose([Validators.min(0)]));
    this.months_no = new FormControl(item.months_no,
      Validators.compose([Validators.min(0), Validators.max(11)]));
    this.rent = new FormControl(item.rent);
  }

  createForm(): void {
    this.form = new FormGroup({
      residence_term_ex: this.residence_term_ex,
      residence_type_ex: this.residence_type_ex,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      zip_four: this.zip_four,
      country: this.country,
      years_no: this.years_no,
      months_no: this.months_no,
      rent: this.rent,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Address(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Address(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  isDurationRequired() {
    return (this.residence_type_ex.value === 'Current' || this.residence_type_ex.value === 'Prior');
  }
}
