import {Component, Injector, Input, OnInit} from '@angular/core';
import {EnvelopeHeader} from '../../../../models/envelope-header';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-envelope-header',
  templateUrl: './view-segment-envelope-header.component.html',

})
export class ViewSegmentEnvelopeHeaderComponent extends CompBaseComponent implements OnInit {
  @Input() item: EnvelopeHeader;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
