<style>
    .border-right-light {
        border-right: 1px solid #adb5bd;
    }

    .border-left-light {
        border-left: 1px solid #adb5bd;
    }

    .border-top-light {
        border-top: 1px solid #adb5bd;
    }

    .border-bot-light {
        border-bottom: 1px solid #adb5bd;
    }
</style>

<div>
  <div class="row">
    <div class="col my-auto">
      <h4>MCR Report for {{state}}</h4>
    </div>
    <div class="col text-end my-auto">
      <button class="btn btn-lg btn-primary" (click)="closeReport($event)" aria-label="close">
        <i class="fa fa-window-close pr-font-large"></i>
        <span class="fw-bold">
        &nbsp;Close
        </span>
      </button>
    </div>
  </div>

  <div *ngFor="let key of reportKeys" class="hand-pointer">
    <div *ngIf="displayH1(key)" class="row fw-bold mt-3">
      <div *ngFor="let mlo of report[key]" [ngClass]="mlo.css">
        {{mlo.text}}
      </div>
    </div>
    <div *ngIf="displayH2(key)" class="row fw-bold">
      <div *ngFor="let mlo of report[key]" [ngClass]="mlo.css">
        {{mlo.text}}
      </div>
    </div>
    <div *ngIf="displayACAndMLO(key)" class="row">
      <div class="col-lg-1 border-right-light border-top-light border-left-light" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{key}}</div>
      <div class="col-lg-3 border-right-light border-top-light" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['text']}}</div>
      <div class="border-right-light border-top-light text-center" [ngClass]="{'border-bot-light': report[key]['last']===true,'col-lg-2 ':key==='ACMLO','col-lg-1': key!=='ACMLO'}">{{report[key]['1']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [hidden]="report[key]['count']<2" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['2']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [hidden]="report[key]['count']<3" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['3']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [hidden]="report[key]['count']<4" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['4']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [hidden]="report[key]['count']<5" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['5']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [hidden]="report[key]['count']<6" [ngClass]="{'border-bot-light': report[key]['last']===true}">{{report[key]['6']}}</div>
    </div>
  </div>

  <div *ngIf="mcr.isOrgMcr()">
    <div class="row fw-bold mt-3">
      <div class="col-lg-5">
        MLO DATA
      </div>
    </div>
    <div class="row fw-bold">
      <div class="col-lg-4 border-right-light">
      </div>
      <div class="col-lg-2 border-top-light border-right-light text-center fw-bold" [ngClass]="{'border-bot-light': report['ACMLOS'].length===0}">
        NMLS ID
      </div>
      <div class="col-lg-1 border-top-light border-right-light text-center fw-bold" [ngClass]="{'border-bot-light': report['ACMLOS'].length===0}">
        Amt ($)
      </div>
      <div class="col-lg-1 border-top-light border-right-light text-center fw-bold" [ngClass]="{'border-bot-light': report['ACMLOS'].length===0}">
        Cnt (#)
      </div>
    </div>

    <div *ngFor="let mlo of report['ACMLOS']" class="row ">
      <div class="col-lg-1 border-left-light  border-right-light border-top-light border-left-light" [ngClass]="{'border-bot-light': mlo['last']===true}">ACMLO</div>
      <div class="col-lg-3 border-right-light border-top-light" [ngClass]="{'border-bot-light': mlo['last']===true}">{{mlo['text']}}</div>
      <div class="col-lg-2 border-right-light border-top-light text-center" [ngClass]="{'border-bot-light': mlo['last']===true}">{{mlo['1']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [ngClass]="{'border-bot-light': mlo['last']===true}">{{mlo['2']}}</div>
      <div class="col-lg-1 border-right-light border-top-light text-center" [ngClass]="{'border-bot-light': mlo['last']===true}">{{mlo['3']}}</div>
    </div>
  </div>
</div>
