import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {Trace} from '../../../models/trace';
import {Workflow} from '../../../models/workflow';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-view-segment-tasks',
  templateUrl: './view-segment-tasks.component.html',
})
export class ViewSegmentTasksComponent extends CompBaseComponent implements OnInit {
  static displayHidden = false;
  static displayUnvisited = false;
  public workflow: Workflow;
  public traces: Array<Trace>;
  public filteredTraces: Array<Trace>;
  public allTraces: Array<Trace>;
  public currentState: string;

  // form and form controls
  public form: FormGroup;
  public displayHidden: FormControl;
  public displayUnvisited: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.reload();
  }

  createFormControls(): void {
    this.displayHidden = new FormControl(ViewSegmentTasksComponent.displayHidden);
    this.displayUnvisited = new FormControl(ViewSegmentTasksComponent.displayUnvisited);
  }

  createForm(): void {
    this.form = new FormGroup({
      displayHidden: this.displayHidden,
      displayUnvisited: this.displayUnvisited
    });
  }

  onFilterChange(): void {
    ViewSegmentTasksComponent.displayHidden = this.displayHidden.value;
    ViewSegmentTasksComponent.displayUnvisited = this.displayUnvisited.value;
    this.loadTraces();
  }

  loadTraces(): void {
    this.reloaded = false;
    this.filteredTraces = this.allTraces.filter((elt) => {
      const cond1 = ViewSegmentTasksComponent.displayHidden ? true : !elt.hidden;
      const cond2 = ViewSegmentTasksComponent.displayUnvisited ? true : (elt.state === this.currentState || elt.done === true);
      return cond1 && cond2;
    });
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  reload(force?: boolean): void {
    this.tranService.getCurrentTran()
      .then((tran: Tran) => {
        this.tran = tran;
        this.workflow = tran.workflow;
        this.currentState = this.workflow.currentTrace ? this.workflow.currentTrace.state : null;
        this.traces = this.workflow.Traces;
        this.allTraces = this.workflow.allTraces;
        this.loadTraces();
      })
      .then(() => {
        this.loadTraces();
      });
  }
}
