import {ModelBase} from './model-base';

export class Liability extends ModelBase {
  public static liability_types = [
    {key: 'BorrowerEstimatedTotalMonthlyLiabilityPayment', value: 'Borrower Estimated Total Monthly Liability Payment'},
    {key: 'CollectionsJudgmentsAndLiens', value: 'Collections Judgments and Liens'},
    {key: 'DeferredStudentLoan', value: 'Deferred Student Loan'},
    {key: 'DelinquentTaxes', value: 'Delinquent Taxes'},
    {key: 'FirstPositionMortgageLien', value: 'First Position Mortgage Lien'},
    {key: 'Garnishments', value: 'Garnishments'},
    {key: 'HELOC', value: 'HELOC'},
    {key: 'HomeownersAssociationLien', value: 'Homeowners Association Lien'},
    {key: 'Installment', value: 'Installment'},
    {key: 'LeasePayment', value: 'LeasePayment'},
    {key: 'MonetaryJudgment', value: 'Monetary Judgment'},
    {key: 'MortgageLoan', value: 'Mortgage Loan'},
    {key: 'Open30DayChargeAccount', value: 'Open 30-Day Charge Account'},
    {key: 'Other', value: 'Other '},
    {key: 'PersonalLoan', value: 'Personal Loan'},
    {key: 'Revolving', value: 'Revolving'},
    {key: 'SecondPositionMortgageLien', value: 'Second Position Mortgage Lien'},
    {key: 'Taxes', value: 'Taxes'},
    {key: 'TaxLien', value: 'TaxLien'},
    {key: 'ThirdPositionMortgageLien', value: 'Third Position Mortgage Lien'},
    {key: 'UnsecuredHomeImprovementLoanInstallment', value: 'Unsecured Home Improvement Loan Installment'},
    {key: 'UnsecuredHomeImprovementLoanRevolving', value: 'Unsecured Home Improvement Loan Revolving'},
  ];

  public static mortgage_types = [
    {key: 'Conventional', value: 'Conventional'},
    {key: 'FHA', value: 'FHA'},
    {key: 'LocalAgency', value: 'Local Agency'},
    {key: 'Other', value: 'Other'},
    {key: 'PublicAndIndianHousing', value: 'Public and Indian Housing'},
    {key: 'StateAgency', value: 'State Agency'},
    {key: 'USDARuralDevelopment', value: 'USDA Rural Development'},
    {key: 'VA', value: 'VA'},
  ];

  index: number;
  creditor_name: string;
  account_no: string;
  monthly_payment_amount: string;
  months_left: string;
  unpaid_balance: string;
  will_be_paid: boolean;
  reo_asset_id: string;
  omit_from_credit_report: boolean;
  mortgage_type: string;
  credit_limit: string;
  liability_type_ex: string;
  other_description: string;
  liability_includes_ti: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'monthly_payment_amount'
        || prop === 'months_left'
        || prop === 'unpaid_balance'
        || prop === 'credit_limit') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'will_be_paid' || prop === 'omit_from_credit_report' || prop === 'liability_includes_ti') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Liability> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Liability(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  static getLiabilityType(liability_type_ex): string {
    const found = Liability.liability_types.find((elt) => elt.key === liability_type_ex);
    return found ? found.value : null;
  }

  getLiabilityType(): string {
    return Liability.getLiabilityType(this.liability_type_ex);
  }

  getMortgageType(): string {
    const found = Liability.mortgage_types.find((elt) => elt.key === this.mortgage_type);
    return found ? found.value : null;
  }

  isMortgageTypeLiability(): boolean {
    return this.liability_type_ex === 'MortgageLoan' || this.liability_type_ex === 'HELOC';
  }

  isMortgageLoan(): boolean {
    return this.liability_type_ex === 'MortgageLoan';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      liability_type_ex: this.liability_type_ex,
      creditor_name: this.creditor_name,
      account_no: this.account_no,
      monthly_payment_amount: this.toServerDecimal(this.monthly_payment_amount),
      months_left: this.toNumber(this.months_left),
      unpaid_balance: this.toServerDecimal(this.unpaid_balance),
      will_be_paid: ModelBase.toServerExplicitBoolean(this.will_be_paid),
      reo_asset_id: this.reo_asset_id,
      omit_from_credit_report: ModelBase.toServerExplicitBoolean(this.omit_from_credit_report),
      liability_includes_ti: ModelBase.toServerExplicitBoolean(this.liability_includes_ti),
      mortgage_type: this.mortgage_type,
      credit_limit: this.toServerDecimal(this.credit_limit),
      other_description: this.other_description
    };
  }
}
