import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {CacheService} from './cache.service';

@Injectable()
export class CfaService {

  constructor(private httpService: HttpService, private cacheService: CacheService) {
    this.cacheService.resetSeg('cfa', 'field');
  }

  setField(val): void {
    this.cacheService.setSeg('cfa', 'field', val);
  }

  getField(): any {
    return this.cacheService.getSeg('cfa', 'field');
  }
}
