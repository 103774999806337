import {Component, Injector, OnInit} from '@angular/core';
import {Race} from '../../../../models/race';
import {RaceComponent} from '../race/race.component';

@Component({
  selector: 'app-add-race',
  templateUrl: '../race/edit-segment-race.component.html',
})
export class AddRaceComponent extends RaceComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Race({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
