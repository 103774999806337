<mat-card *ngIf="reloaded">
  <mat-card-title>View Organization</mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{organization.name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Type</div>
      <div class="col-lg-8">{{organization.getOrgType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{organization.description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">NMLS</div>
      <div class="col-lg-8">{{organization.nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Admin</div>
      <div class="col-lg-8" *ngIf="organization.admin">Name: {{organization.admin.full_name}} - Email: {{organization.admin.email}} - Phone: {{organization.admin.cell_phone || 'N/A'}}</div>
      <div class="col-lg-8" *ngIf="!organization.admin">
        <small>(Admin not assigned)</small>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button (click)="editOrganization($event)" class="btn btn-lg btn-primary">Edit</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded">
  <mat-card-title>Organization Users</mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Sys Role</th>
          <th>Org Role</th>
          <th>Name</th>
          <th>Email</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="viewUser($event,user.id)" *ngFor="let user of organization.users" class="hand-pointer">
          <td>{{user.id}}</td>
          <td>{{user.getRolesString()}}</td>
          <td>{{user.getOrgRoleString()}}</td>
          <td>{{user.full_name}}</td>
          <td>{{user.isLoanOfficer() ? user.email : 'N/A'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>

