<div>
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating">

  <div class="row">
    <div class="col-lg-8">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Company address</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="company_address" matInput placeholder="Company address" maxlength="{{MAX_LEN.FULL_ADDR}}">
        <mat-error *ngIf="company_address.hasError('required')">
          Company address is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>MLO NMLS ID</mat-label>
        <input [readonly]="true" class="text-primary" appNumberOnly formControlName="mlo_nmls" matInput placeholder="MLO NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
        <mat-error *ngIf="mlo_nmls.hasError('required')">
          MLO NMLS ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Company name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="company_name" matInput placeholder="Company name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="company_name.hasError('required')">
          Company name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Company NMLS ID</mat-label>
        <input [readonly]="true" class="text-primary" appNumberOnly formControlName="company_nmls" matInput placeholder="Company NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
        <mat-error *ngIf="company_nmls.hasError('required')">
          Company NMLS ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date">
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Date is unrealistic
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Date format is invalid
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8">
      <div class="ms-3 me-3">
        Must choose fixed or adjustable rate
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-lg-4">
      <mat-radio-group aria-label="fixed_rate_label" formControlName="fixed_rate">
        <mat-radio-button [checked]="fixed_rate.value===true" [value]="true">Fixed rate</mat-radio-button>
        <mat-radio-button [checked]="fixed_rate.value===false" [value]="false">Adjustable rate</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="submitted && fixed_rate.hasError('required')" class="mb-1">
        Must choose fixed or adjustable rate
      </mat-error>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8">
      <div class="m-3">
        <span class="fw-bold">Option 1.</span> Lowest interest rate
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Rate as percent</mat-label>
        <input appDecimalThreeOnly formControlName="option_1_percent" matInput placeholder="Rate as percent">
        <mat-error *ngIf="option_1_percent.hasError('required')">
          Rate as percent is required
        </mat-error>
        <mat-error *ngIf="option_1_percent.hasError('max')||option_1_percent.hasError('min')">
          Rate is invalid
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Cost amount</mat-label>
        <input appDecimalOnly formControlName="option_1_cost" matInput placeholder="Cost amount">
        <mat-error *ngIf="option_1_cost.hasError('required')">
          Cost amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8">
      <div class="m-3">
        <span class="fw-bold">Option 2.</span>
        Lowest interest rate without negative amortization, a
        prepayment penalty, interest-only payments, a balloon payment in the first 7
        years of the life of the loan, a demand feature, shared equity, or shared
        appreciation
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Rate as percent</mat-label>
        <input appDecimalThreeOnly formControlName="option_2_percent" matInput placeholder="Rate as percent">
        <mat-error *ngIf="option_2_percent.hasError('max')||option_2_percent.hasError('min')">
          Rate is invalid
        </mat-error>
        <mat-error *ngIf="option_2_percent.hasError('required')">
          Rate as percent is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Cost amount</mat-label>
        <input appDecimalOnly formControlName="option_2_cost" matInput placeholder="Cost amount">
        <mat-error *ngIf="option_2_cost.hasError('required')">
          Cost amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="m-3">
        <span class="fw-bold">Option 3.</span>
        Lowest total dollar amount for origination points or fees and
        discount points
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Rate as percent</mat-label>
        <input appDecimalThreeOnly formControlName="option_3_percent" matInput placeholder="Rate as percent">
        <mat-error *ngIf="option_3_percent.hasError('max')||option_3_percent.hasError('min')">
          Rate is invalid
        </mat-error>
        <mat-error *ngIf="option_3_percent.hasError('required')">
          Rate as percent is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Cost amount</mat-label>
        <input appDecimalOnly formControlName="option_3_cost" matInput placeholder="Cost amount">
        <mat-error *ngIf="option_3_cost.hasError('required')">
          Cost amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="m-3">
        <span class="fw-bold">Effective Rate and Cost.</span>
        Effective rate and origination cost of this loan
      </div>
    </div>
  </div>


  <div class="row mb-3">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Rate</mat-label>
        <input appDecimalThreeOnly formControlName="rate_percent" matInput placeholder="Rate as percent" required>
        <mat-error *ngIf="rate_percent.hasError('max')||rate_percent.hasError('min')">
          Rate is invalid
        </mat-error>
        <mat-error *ngIf="rate_percent.hasError('required')">
          Rate as percent is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Cost amount</mat-label>
        <input appDecimalOnly formControlName="cost" matInput placeholder="Cost amount">
        <mat-error *ngIf="cost.hasError('required')">
          Cost amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <button (click)="genAntiSteering()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Save Data</button>
  <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
</form>
