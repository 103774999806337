<mat-card *ngIf="reloaded && isOrg">
  <mat-card-title>
    <div class="clearfix">
      <div class="float-left">
        Organization Loans
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 fw-bold border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">ID</div>
      <div [hidden]="isMobile" class="col col-lg-3">Your Access</div>
      <div class="col-lg-3">Name</div>
      <div class="col-lg-3">Owner</div>
      <div class="col-lg-2">Created</div>
    </div>

    <app-box *ngIf="filteredTrans.length===0" [close]="true" [type]="'warning'" class="pt-2">
      <strong>No organization loans!</strong>
    </app-box>

    <div (click)="navigateToTran(tran)" *ngFor="let tran of filteredTrans" class="hand-pointer row pt-1 pb-1 hover-border border-bottom">
      <div [hidden]="isMobile" class="col-lg-1">{{tran.id}}</div>
      <div [hidden]="isMobile" class="col-lg-3">
      <span style="width: 50px;">
        {{tran.getUserAccess()}}
      </span>
      </div>
      <div class="col-lg-3">{{tran.name}}</div>
      <div class="col-lg-3">{{tran.owner_full_name}}</div>
      <div class="col-lg-2">{{tran.created_at | date:'MM/dd/yyyy'}}</div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded && !isOrg">
  <mat-card-title>
    <div class="clearfix">
      <div class="float-left">
        No User Organization
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <app-box [close]="true" [type]="'danger'" [fadeOut]="false">
      You are not part of any organization. You may be able to create an organization from
      <a routerLink="/account/user-organization" aria-label="Account Organization" class="fw-bold text-danger">Account/Organization</a>.
    </app-box>

  </mat-card-content>
</mat-card>
