import {inject, Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {User} from '../models/user';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {ConfigurationService} from './configuration.service';
import {TranService} from './tran.service';
import {SharedService} from '../layouts/shared-service';
import {CacheManager} from '../models/cache-manager';
import {ComplianceService} from './compliance.service';
import {globals} from '../shared/globals/globals';
import {Organization} from '../models/organization';
import {OrganizationService} from './organization.service';
import {CacheService} from './cache.service';

@Injectable()
export class UserService {
  private static rootUnAuth = '/site/index';
  // public static isBorrower: boolean;
  public static licensedToBorrower: boolean;
  public static licensedToUploads: boolean;
  public static licensedToAncillary: boolean;
  public static licensedToMCR: boolean;
  public static licensedToLoans: boolean;
  public static licensedToTimeline: boolean;

  constructor(private complianceService: ComplianceService,
              private httpService: HttpService,
              private cacheService: CacheService,
              private configurationService: ConfigurationService,
              private tranService: TranService,
              private orgService: OrganizationService,
              private sharedService: SharedService,
              private router: Router
  ) {
  }

  public getRootURL(): string {
    return '/home/highlights';
  }

  // Comment: for whatever reason the canActivate guard runs BEFORE app component initializes.
  // For that reason we must init http service and refresh user from server
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.configurationService.loadApp();
    if (globals.maintenance === true) {
      this.router.navigate(['/pages/maintenance']);
      return Promise.resolve(false);
    }
    if (state.url.startsWith('/site')) {
      return Promise.resolve(true);
    }
    if (this.getUserSync()) {
      if (state.url === '/') {
        this.router.navigate([this.getRootURL()])
          .then(() => {
            return Promise.resolve(true);
          });
      }
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      this.getUserFromSession(true)
        .then(() => {
          if (state.url === '/') {
            this.router.navigate([this.getRootURL()]);
          }
          resolve(true);
        })
        .catch(() => {
          this.router.navigate([UserService.rootUnAuth])
            .then(() => {
              resolve(false);
            })
        });
    });
  }

  public setServices(user: User): void {
    UserService.licensedToLoans = user.hasPOLOSService();
    UserService.licensedToUploads = user.hasUploadsService();
    UserService.licensedToAncillary = user.hasAncillaryService();
    UserService.licensedToMCR = user.hasMCRService();
    UserService.licensedToBorrower = user.hasBorrowerService();
  }

  // todo: need to remove createAccount. Create account only from auth server
  public createAccount(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.post('home/create_account/uploads', payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateAccount(payload: any): Promise<boolean> | any {
    return new Promise((resolve, reject) => {
      this.httpService.put('account', payload)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateUserConfiguration(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('account/user_configurations/' + payload.id, payload)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateUserProfile(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('account/professional_profiles/' + payload.id, payload)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updatePassword(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('account/update_password', payload)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public addContact(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('account/contacts', payload)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public deleteContact(contactId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`account/contacts/${contactId}`)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public hideContact(contactId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`account/contacts/hide/${contactId}`, null)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public showContact(contactId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`account/contacts/show/${contactId}`, null)
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public deleteAccountPhoto(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('account/photo')
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          return this.tranService.getCurrentTran(true);
        })
        .then(() => resolve(true))
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public deleteAccountLogo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('account/logo')
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public loginFromAuth(access_token: string): Promise<boolean> {
    const payload = {access_token: access_token};
    return new Promise((resolve, reject) => {
      this.httpService.post('home/login_from_auth', payload)
        .then((data) => {
          return this.loginUserToApp(data);
        })
        .then(() => {
          this.cacheService.resetSeg('user', 'accountOverview');
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // Get user from session, and from server if necessary.
  public getUserFromSession(force: boolean = false): Promise<boolean> {
    if (!force && this.getUserSync()) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      this.httpService.get('home/get_session')
        .then((data) => {
          return this.loginUserToApp(data);
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public refreshUser(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.getUserFromSession(true);
      this.getAccountOverview(true);
      resolve(true);
    });
  }

  public getAccountOverview(force?: boolean): any {
    const accountOverview = this.cacheService.getSeg('user', 'accountOverview');
    if (!force && accountOverview) {
      return Promise.resolve(accountOverview);
    }
    return new Promise((resolve, reject) => {
      this.httpService.get('account/overview')
        .then((data) => {
          this.cacheService.setSeg('user', 'accountOverview', data);
          resolve(data);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  // Comment: we need to populate current tran and the user's loan apps so that
  // the menu permissions will be updated correctly:
  // 1. the loan list need to be activated if the user is a loan officer or a borrower with access to any loan
  // 2. we need to know whether there is a current loan
  public loginUserToApp(data: any): Promise<boolean> {
    const user = new User(data);
    this.setUser(user);
    this.httpService.setAuthorization(user);
    this.setServices(user);
    CacheManager.storeCurrentUserId(user.id);
    return new Promise((resolve, reject) => {
      this.tranService.getTrans(false)
        .then(() => {
          return this.tranService.getCurrentTran(false);
        })
        .then(() => {
          this.sharedService.emitChange({type: 'Login'});
          return resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  // Request to reset password
  public resetPassword(payload) {
    return new Promise((resolve, reject) => {
      this.httpService.post('home/account_password_reset_json', payload)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public verifyAccount(payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('home/account_verification_json', payload)
        .then((data) => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  public verifyUserToken(payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('home/user_token_verification_json', payload)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public addServices(services: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('orders/add_services', {services: services})
        .then((data) => {
          const user = new User(data);
          this.setUser(user);
          resolve(true);
        })
        .catch((data) => {
          reject(data.error);
        });
    });
  }

  public logoutSession(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('home/logout_session')
        .then(() => {
          this.cacheService.voidCache();
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public isContacts(): boolean {
    const user = this.getUserSync();
    return user && user.contacts && user.contacts.length > 0;
  }

  public isProcessor(): boolean {
    const user = this.getUserSync();
    return user && user.isProcessor();
  }

  public isBorrower(): boolean {
    const user = this.getUserSync();
    return user && user.isBorrower();
  }

  public isLoanOfficer(): boolean {
    const user = this.getUserSync();
    return user && user.isLoanOfficer();
  }

  public isRealEstateAgent(): boolean {
    const user = this.getUserSync();
    return user && user.isRealEstateAgent();
  }

  public isAdmin(): boolean {
    const user = this.getUserSync();
    return user && user.isAdmin();
  }

  public isAgent(): boolean {
    const user = this.getUserSync();
    return user && user.isAgent();
  }

  public isPresenter(): boolean {
    const user = this.getUserSync();
    return user && user.isPresenter();
  }

  public isLoanProfessional(): boolean {
    const user = this.getUserSync();
    return user && user.isLoanProfessional();
  }

  public isOrgAdmin(): boolean {
    const user = this.getUserSync();
    return user && user.isOrgAdmin();
  }

  public getDefaultOrganization(force?: boolean): Promise<Organization> {
    const userOrganization = this.cacheService.getSeg('user', 'userOrganization');
    if (!force && userOrganization) {
      return Promise.resolve(userOrganization);
    }
    const orgId = this.getUserSync().organization_id;
    if (!orgId) {
      return Promise.resolve(null);
    }
    return new Promise(async (resolve) => {
      try {
        const org = await this.orgService.findOrganizationById(orgId);
        this.cacheService.setSeg('user', 'userOrganization', org);
        resolve(org);
      } catch (data) {
        resolve(null);
      }
    });
  }

  public getUserSync(): User {
    return this.cacheService.getSeg('user', 'user');
  }

  public setUser(user: User): void {
    return this.cacheService.setSeg('user', 'user', user);
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(UserService).canActivate(next, state);
}
