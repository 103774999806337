<app-orientation-helper (orientationErrorEmitted)="onOrientationError($event)" [componentName]="'Workflow'"></app-orientation-helper>

<app-box *ngIf="!workflow.started&&!orientationError" [close]="false" [type]="'warning'" class="mt-3">
  The
  <span class="fw-bold">workflow has not yet started!</span>
  To start it, click the
  <strong>edit</strong> (pencil) button and follow the provided instructions.
</app-box>

<mat-card *ngIf="reloaded&&!orientationError">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateTransaction" [header]="header" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content *ngIf="workflow.started">
    <app-box *ngIf="!isCompDone" [type]="'warning'" class="mt-3">
      {{compMessage}}
    </app-box>

    <app-box *ngIf="requiredComps.length>0 || optComps.length>0" [close]="true" [type]="'primary'">
      <div class="row">
        <div class="col-lg-6">
          <h5>Key Dates</h5>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              Start date
            </div>
            <div class="col-lg-6">
              {{startDate || 'N/A'}}
            </div>
          </div>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              {{isMobile ? 'Cr Auth date' : 'Credit authorization date'}}
            </div>
            <div class="col-lg-6">
              {{creditAuthDate || 'N/A'}}
            </div>
          </div>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              {{isMobile ? 'Loan application date' : 'Loan application received date'}}
            </div>
            <div class="col-lg-6">
              {{loanAppDate || 'N/A'}}
            </div>
          </div>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              {{isMobile ? 'LE date' : 'Loan Estimate (LE)'}}
            </div>
            <div class="col-lg-6">
              {{leDate || 'N/A'}}
            </div>
          </div>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              {{isMobile ? 'CD date' : 'Closing Disclosure (CD) date'}}
            </div>
            <div class="col-lg-6">
              {{cdDate || 'N/A'}}
            </div>
          </div>
          <div class="row mb-1 me-0 align-items-end">
            <div class="col-lg-6">
              {{isMobile ? 'Lock Exp date' : 'Lock expiration date'}}
            </div>
            <div class="col-lg-6">
              {{lockDate || 'N/A'}}
            </div>
          </div>
        </div>
        <div class="col-lg-6 pb-2">
          <div class="row">
            <div class="col-lg-9">
              <h5>Loan Events Legend</h5>
            </div>
            <div class="col-lg-3 text-center">
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Completed on time
            </div>
            <div class="col-lg-3 pr-success fw-bold text-center">
              {{levelCounts[1]}}
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Outstanding
            </div>
            <div class="col-lg-3 pr-light fw-bold text-center">
              {{levelCounts[2]}}
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Due today
            </div>
            <div class="col-lg-3 pr-warning fw-bold text-center">
              {{levelCounts[3]}}
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Completed but not compliant
              <small [hidden]="isMobile">(too early or too late)</small>
            </div>
            <div class="col-lg-3 pr-danger fw-bold text-center">
              {{levelCounts[4]}}
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Missed
            </div>
            <div class="col-lg-3 pr-fatal fw-bold text-center">
              {{levelCounts[5]}}
            </div>
          </div>
          <div class="row mb-1 me-0">
            <div class="col-lg-9 border-bottom hover-gray">
              Compliance not applicable
            </div>
            <div class="col-lg-3 pr-light-gray fw-bold text-center">
              {{levelCounts[0]}}
            </div>
          </div>
        </div>
      </div>
    </app-box>


    <div *ngIf="requiredComps.length > 0" class="mt-3">
      <mat-card-title>Required Compliance Events</mat-card-title>

      <div class="row comp-border-bottom border-top-gray">
        <div class="col-lg-6 comp-cell comp-border-left">
          <span class="fw-bold">Description</span>&nbsp;(code)
        </div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due On or After</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">{{isMobile ? 'Occur On' : 'Occurred On'}}</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due By</div>
      </div>

      <div *ngFor="let compEvent of requiredComps">
        <app-view-comp-event (eventEmitted)="onEvent($event)" [item]="compEvent" [tran]="tran"></app-view-comp-event>
      </div>
    </div>

    <div *ngIf="optComps.length > 0" class="mt-3">
      <mat-card-title>Non-Compliance Events</mat-card-title>

      <div class="row comp-border-bottom border-top-gray">
        <div class="col-lg-6 comp-cell comp-border-left">
          <span class="fw-bold">Description</span>&nbsp;(code)
        </div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due On or After</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">{{isMobile ? 'Occur On' : 'Occurred On'}}</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due By</div>
      </div>

      <div *ngFor="let compEvent of optComps">
        <app-view-comp-event (eventEmitted)="onEvent($event)" [item]="compEvent" [tran]="tran"></app-view-comp-event>
      </div>
    </div>

    <div *ngIf="optComps.length == 0 && requiredComps.length == 0">
      <mat-card-title>No Compliance Events</mat-card-title>
    </div>

    <mat-card-title class="mt-3">Current Task</mat-card-title>

    <div class="row pb-1 border-bottom hover-gray border-top-gray">
      <div class="col-lg-6 fw-bold">Task</div>
      <div class="col-lg-6">{{currentTrace.state_display}}</div>
    </div>

    <div class="row pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Causing event</div>
      <div class="col-lg-6">{{currentTrace.event_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Started at</div>
      <div class="col-lg-6">{{currentTrace.started_at | date:'medium'}}</div>
    </div>

    <div *ngIf="currentTrace.ended_at" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">
        <strong>Completed at</strong>
      </div>
      <div class="col-lg-6">{{currentTrace.ended_at | date:'medium'}}</div>
    </div>

    <div *ngIf="currentTrace.ended_at" class="row mt-3">
      <div class="col">
        <p>
          You can clear the "Completed at" timestamp by clicking the button below.
        </p>
      </div>

      <div class="col mt-3">
        <button (click)="clearTerminatedAtTask()" class="btn btn-lg btn-warning" title="Clear current task's terminated at field">Clear Completed at</button>
      </div>
    </div>

    <div *ngIf="!currentTrace.ended_at" class="row mt-3">
      <div class="col">
        You can set the "Completed at" timestamp to now by clicking the button below.
      </div>

      <div class="col mt-3">
        <button (click)="terminateTask()" class="btn btn-lg btn-primary" title="Terminate task">Mark Current Task Completed</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
