import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Contact} from '../../../../models/contact';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-contact',
  template: '',
})
export class ContactComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Contact;
  public form: FormGroup;
  public email: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createFormControls(): void {
    this.email = new FormControl('', Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
  }

  createForm(): void {
    this.form = new FormGroup({email: this.email});
  }

  delete(event: any) {
    event.preventDefault();
    this.userService.deleteContact(this.item.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Contact successfully deleted');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete contact', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  hide(event: any) {
    event.preventDefault();
    this.userService.hideContact(this.item.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Contact successfully hidden');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to hide contact', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  show(event: any) {
    event.preventDefault();
    this.userService.showContact(this.item.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Contact successfully shown');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to show contact', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    this.addContact(this.email.value);
  }

  addContact(email: string): void {
    const payload = {
      name: email
    };

    this.userService.addContact(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Contact successfully added/shown');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to add contact', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }
}
