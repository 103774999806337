<mat-card class="mt-1 mb-1">
  <mat-card-title>{{folderGroup.name}}&nbsp;
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div *ngFor="let item of items; let i = index" formArrayName="items">
        <mat-checkbox [formControlName]="i">{{item['display'] || item['name']}}</mat-checkbox>
      </div>

      <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
      <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
    </form>
  </mat-card-content>
</mat-card>
