<div *ngIf="generating" >
  <mat-spinner [diameter]="68" class="ms-5 mb-3"></mat-spinner>
</div>

<form *ngIf="reloaded" [formGroup]="form" [hidden]="generating">
  <div class="row" *ngIf="isCoBorrower&&!who.value">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-select (selectionChange)="onSelectionChange()" formControlName="who" placeholder="Select borrower" [required]="isCoBorrower">
          <mat-option *ngFor="let bor of applicants" [value]="bor.key">{{bor.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="who.hasError('required')">
          Borrower is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div [hidden]="isCoBorrower&&!who.value">
    <div class="row">
      <div class="col">
        <h5>{{getWhoString()}}</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <mat-form-field class="w-100">
          <mat-label>{{getWhoString()}} name</mat-label>
          <input formControlName="name" matInput placeholder="Borrower/Co-Borrower name" maxlength="{{MAX_LEN.GEN_NAME}}" required>
          <mat-error *ngIf="name.hasError('required')">
            Borrower/Co-Borrower name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <mat-form-field class="w-100">
          <mat-label>Address</mat-label>
          <input formControlName="address" matInput placeholder="Address" maxlength="{{MAX_LEN.FULL_ADDR}}" required>
          <mat-error *ngIf="address.hasError('required')">
            Address is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <mat-form-field class="w-100">
          <mat-label>DOB</mat-label>
          <input appDateOnly [matDatepicker]="$any(datePicker0)" [max]="maxPersonDOB" [min]="minPersonDOB" formControlName="dob" matInput placeholder="DOB" required>
          <mat-datepicker-toggle [for]="datePicker0" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #datePicker0></mat-datepicker>
          <mat-error *ngIf=" dob.hasError('matDatepickerMax')">
            DOB is invalid (too recent or in the future)
          </mat-error>
          <mat-error *ngIf="dob.hasError('matDatepickerMin')">
            DOB date is unrealistic
          </mat-error>
          <mat-error *ngIf="dob.hasError('matDatepickerParse')">
            DOB format is invalid
          </mat-error>
          <mat-error *ngIf="!dob.hasError('matDatepickerParse')&&dob.hasError('required')">
            DOB is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <h5>Primary Identification</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Document type</mat-label>
              <input formControlName="primary_document_type" matInput placeholder="Document type" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="primary_document_type.hasError('required')">
                Document type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Document number</mat-label>
              <input formControlName="primary_document_number" matInput placeholder="Document number" maxlength="{{MAX_LEN.GEN_ID}}">
              <mat-error *ngIf="primary_document_number.hasError('required')">
                Document number is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Issued date</mat-label>
              <input [matDatepicker]="$any(datePicker1)" [max]="maxIssueDate" [min]="minIssueDate" appDateOnly formControlName="primary_issue_date" matInput placeholder="Issued date">
              <mat-datepicker-toggle [for]="datePicker1" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker1></mat-datepicker>
              <mat-error *ngIf="primary_issue_date.hasError('matDatepickerMin')">
                Issued date is unrealistic
              </mat-error>
              <mat-error *ngIf="primary_issue_date.hasError('matDatepickerMax')">
                Issued date is in the future
              </mat-error>
              <mat-error *ngIf="primary_issue_date.hasError('matDatepickerParse')">
                Date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Expiration date</mat-label>
              <input [matDatepicker]="$any(datePicker2)" [min]="minExpDate" [max]="maxExpDate" appDateOnly formControlName="primary_expiration_date" matInput placeholder="Expiration date">
              <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker2></mat-datepicker>
              <mat-error *ngIf="primary_expiration_date.hasError('matDatepickerMin')">
                Expiration date must be in the future
              </mat-error>
              <mat-error *ngIf="primary_expiration_date.hasError('matDatepickerMax')">
                Expiration date is unrealistic
              </mat-error>
              <mat-error *ngIf="primary_expiration_date.hasError('matDatepickerParse')">
                Date format is invalid
              </mat-error>
              <mat-error *ngIf="primary_expiration_date.hasError('minmax')">
                Expiration date must be after issue date
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Issued by</mat-label>
              <input formControlName="primary_issued_by" matInput placeholder="Issued by" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="primary_issued_by.hasError('required')">
                Issued by is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="row">
          <div class="col">
            <h5> Secondary Identification</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Document type</mat-label>
              <input formControlName="secondary_document_type" matInput placeholder="Document type" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="secondary_document_type.hasError('required')">
                Document type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Document number</mat-label>
              <input formControlName="secondary_document_number" matInput placeholder="Document number" maxlength="{{MAX_LEN.GEN_ID}}">
              <mat-error *ngIf="secondary_document_number.hasError('required')">
                Document number is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Issued date</mat-label>
              <input [matDatepicker]="$any(datePicker3)" [max]="maxIssueDate" [min]="minIssueDate" appDateOnly formControlName="secondary_issue_date" matInput placeholder="Issued date">
              <mat-datepicker-toggle [for]="datePicker3" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker3></mat-datepicker>
              <mat-error *ngIf="secondary_issue_date.hasError('matDatepickerMin')">
                Issued date is unrealistic
              </mat-error>
              <mat-error *ngIf="secondary_issue_date.hasError('matDatepickerMax')">
                Issued date is in the future
              </mat-error>
              <mat-error *ngIf="secondary_issue_date.hasError('matDatepickerParse')">
                Issued date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Expiration date</mat-label>
              <input [matDatepicker]="$any(datePicker4)" [min]="minExpDate" [max]="maxExpDate" appDateOnly formControlName="secondary_expiration_date" matInput placeholder="Expiration date">
              <mat-datepicker-toggle [for]="datePicker4" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker4></mat-datepicker>
              <mat-error *ngIf="secondary_expiration_date.hasError('matDatepickerMin')">
                Expiration date must be in the future
              </mat-error>
              <mat-error *ngIf="secondary_expiration_date.hasError('matDatepickerMax')">
                Expiration date is unrealistic
              </mat-error>
              <mat-error *ngIf="secondary_expiration_date.hasError('matDatepickerParse')">
                Date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field class="w-100">
              <mat-label>Issued by</mat-label>
              <input formControlName="secondary_issued_by" matInput placeholder="Issued by" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="secondary_issued_by.hasError('required')">
                Issued by is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5>Loan Officer</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <mat-form-field class="w-100">
          <mat-label>MLO name</mat-label>
          <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
          <mat-error *ngIf="mlo_name.hasError('required')">
            MLO name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <mat-form-field class="w-100">
          <mat-label>Document date</mat-label>
          <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" appDateOnly formControlName="doc_date" matInput placeholder="Document date">
          <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
            Document date is unrealistic
          </mat-error>
          <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
            Document date format is invalid
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genPATRIOT()" class="btn btn-lg btn-primary" type="submit">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary" type="button">Cancel</button>
    </div>
  </div>
</form>
