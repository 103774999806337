import {Component, Injector, Input, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {OrgUserComponent} from '../org-user.component';
import {User} from '../../../../models/user';

@Component({
  selector: 'app-add-segment-org-user',
  templateUrl: '../edit-segment-org-user/edit-segment-org-user.component.html',
})
export class AddSegmentOrgUserComponent extends OrgUserComponent implements OnInit {
  pageTitle = 'Add Loan Officer';

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountOrg
      });
    }, 0);
  }

  reload(force?: boolean): void {
    this.createFormControls(new User({organization_role: User.ORG_ROLES.NONE}));
    this.createForm();
    this.reloaded = true;
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
