<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Expense indicator</mat-label>
            <mat-select formControlName="expense_indicator_ex" placeholder="Select expense indicator" required>
              <mat-option *ngFor="let expense_indicator of expense_indicators_ex"
                [value]="expense_indicator.key">{{expense_indicator.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="expense_indicator_ex.hasError('required')">
              Expense indicator is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Expense type</mat-label>
            <mat-select formControlName="code_ex" placeholder="Select expense type" required>
              <mat-option *ngFor="let code of expense_codes_ex"
                [value]="code.key">{{code.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="code_ex.hasError('required')">
              Expense type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Amount</mat-label>
            <input appDecimalOnly formControlName="amount" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Amount" required>
            <mat-error *ngIf="amount.hasError('required')">
              Amount is required
            </mat-error>
            <mat-error *ngIf="amount.hasError('pattern')">
              Amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
