import {Component, HostBinding, Injector, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
})
export class ReadMoreComponent implements OnInit {
  @HostBinding('style.width') readMoreWidth = '100%';
  @HostBinding('style.display') readMoreDisplay = 'inline-block';
  @Input() public text: string;
  @Input() public textLength = 128;
  public collapsedText: string;
  public isCollapsed = true;
  public isCollapsible = false;

  constructor(private injector: Injector) {
  }

  ngOnInit() {
    const firstLineLength = this.text.indexOf('\n');
    if (firstLineLength >= 0) {
      this.collapsedText = this.text.substr(0, firstLineLength);
      this.isCollapsible = true;
      return;
    }
    if (this.text.length > this.textLength) {
      this.collapsedText = this.text.substr(0, this.textLength);
      this.isCollapsible = true;
      this.isCollapsed = true;
      return;
    }
    this.isCollapsible = false;
    this.isCollapsed = false;
  }

  toggle(event: any): void {
    event.preventDefault();
    this.isCollapsed = !this.isCollapsed;
  }
}
