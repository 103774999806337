import {Component, Injector, Input, OnInit} from '@angular/core';
import {RealEstate} from '../../../../models/real-estate';
import {RealEstateComponent} from '../real-estate/real-estate.component';

@Component({
  selector: 'app-view-segment-real-estate',
  templateUrl: './view-segment-real-estate.component.html',

})
export class ViewSegmentRealEstateComponent extends RealEstateComponent implements OnInit {
  @Input() item: RealEstate;
  public partyIds: Array<any>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setPartyIds();
    this.setPartyIds();
  }
}
