import {Directive, HostListener} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';

@Directive({
  selector: '[appBackButton]'
})
export class BackButtonDirective {
  constructor(private navigation: NavigationService) {
  }

  // constructor() {}

  @HostListener('click')
  onClick(): void {
    this.navigation.back();
  }
}
