import {ModelBase} from './model-base';

export class Race extends ModelBase {
  public static race_codes = [
    {key: 'AmericanIndianOrAlaskaNative', value: 'American Indian or Alaska Native'},
    {key: 'Asian', value: 'Asian'},
    {key: 'BlackOrAfricanAmerican', value: 'Black or African American'},
    {key: 'NativeHawaiianOrOtherPacificIslander', value: 'Native Hawaiian or Other Pacific Islander'},
    {key: 'White', value: 'White'},
  ];
  index: number;
  race: string;
  indian_description: string;
  asian_description: string;
  islander_description: string;
  hawaii: boolean;
  guam: boolean;
  samoa: boolean;
  india: boolean;
  china: boolean;
  philippines: boolean;
  japan: boolean;
  korea: boolean;
  vietnam: boolean;
  race_ex: string;
  other_asian: boolean;
  other_islander: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'hawaii' || prop === 'guam' || prop === 'samoa') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'india' || prop === 'china' || prop === 'philippines' || prop === 'japan' || prop === 'korea' || prop === 'vietnam') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'other_asian' || prop === 'other_islander') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Race> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Race(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getRaceShort(): string {
    const found = Race.race_codes.find((elt) => elt.key === this.race_ex);
    return found ? found['value'] : null;
  }

  getRace(): string {
    const found = Race.race_codes.find((elt) => elt.key === this.race_ex);
    if (!found) {
      return null;
    }

    let race = found['value'];
    const subs = [];
    if (this.indian_description) {
      subs.push(this.indian_description);
    }
    if (this.guam) {
      subs.push('Guam');
    }
    if (this.hawaii) {
      subs.push('Hawaii');
    }
    if (this.samoa) {
      subs.push('Samoa');
    }
    if (this.other_islander) {
      if (this.islander_description && this.islander_description.length > 0) {
        subs.push(this.islander_description);
      } else {
        subs.push('Other');
      }
    }
    if (this.china) {
      subs.push('China');
    }
    if (this.india) {
      subs.push('India');
    }
    if (this.japan) {
      subs.push('Japan');
    }
    if (this.korea) {
      subs.push('Korea');
    }
    if (this.philippines) {
      subs.push('Philippines');
    }
    if (this.vietnam) {
      subs.push('Vietnam');
    }
    if (this.other_asian) {
      if (this.asian_description && this.asian_description.length > 0) {
        subs.push(this.asian_description);
      } else {
        subs.push('Other');
      }
    }
    if (subs.length > 0) {
      race += (' (' + subs.join(', ') + ')');
    }
    return race;
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      race_ex: this.race_ex,
      indian_description: this.indian_description,
      asian_description: this.asian_description,
      islander_description: this.islander_description,
      hawaii: ModelBase.toServerExplicitBoolean(this.hawaii),
      guam: ModelBase.toServerExplicitBoolean(this.guam),
      samoa: ModelBase.toServerExplicitBoolean(this.samoa),
      india: ModelBase.toServerExplicitBoolean(this.india),
      china: ModelBase.toServerExplicitBoolean(this.china),
      philippines: ModelBase.toServerExplicitBoolean(this.philippines),
      japan: ModelBase.toServerExplicitBoolean(this.japan),
      korea: ModelBase.toServerExplicitBoolean(this.korea),
      vietnam: ModelBase.toServerExplicitBoolean(this.vietnam),
      other_asian: ModelBase.toServerExplicitBoolean(this.other_asian),
      other_islander: ModelBase.toServerExplicitBoolean(this.other_islander),
    };
  }
}
