import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {Mcr} from '../../../../models/mcr';

export interface DialogData {
  quarter: number;
  year: number;
}

@Component({
  selector: 'app-year-quarter-dialog',
  templateUrl: './year-quarter-dialog.component.html',
  styleUrls: ['./year-quarter-dialog.component.scss']
})
export class YearQuarterDialogComponent implements OnInit {
  public readonly quarters = Mcr.quarters;
  public readonly years = Mcr.years;
  public form: FormGroup;
  public quarter: FormControl;
  public year: FormControl;

  constructor(public dialogRef: MatDialogRef<YearQuarterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.year = new FormControl(this.data.year);
    this.quarter = new FormControl(this.data.quarter);
  }

  createForm(): void {
    this.form = new FormGroup({
      year: this.year,
      quarter: this.quarter,
    });
  }

  onDone(): void {
    this.data.quarter = this.quarter.value;
    this.data.year = this.year.value;
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
