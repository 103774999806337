import {Component, Injector, OnInit} from '@angular/core';
import {FinancialRatioComponent} from '../financial-ratio/financial-ratio.component';

@Component({
  selector: 'app-view-segment-financial-ratio',
  templateUrl: './view-segment-financial-ratio.component.html',
})
export class ViewSegmentFinancialRatioComponent extends FinancialRatioComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.qualifyLoan = Object.assign({}, this.ql);
    this.createFormControls();
    this.createForm();
  }

  close(event: any): void {
    if (event) {
      event.preventDefault();
    }
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit', payload: this.qualifyLoan});
  }
}
