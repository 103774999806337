import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../../../layouts/shared-service';
import {TranService} from '../../../services/tran.service';
import {CompEvent} from '../../../models/comp-event';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../comp-base.component';
import moment from 'moment';
import {Help} from '../../../models/help';
import {globals} from '../../../shared/globals/globals';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-view-comp-events',
  templateUrl: './view-comp-events.component.html',
})
export class ViewCompEventsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Compliance';
  public sharedService: SharedService;
  public tranService: TranService;
  public compEvents: Array<CompEvent>;
  public tranId: number;
  public simulateDateEnabled: boolean;
  public readonly dateFormat = 'MM/DD/YYYY';
  public levelCounts: Array<any>;

  // key compliance dates
  public startDate: string;
  public creditAuthDate: string;
  public loanAppDate: string;
  public leDate: string;
  public cdDate: string;
  public lockDate: string;

  // indicator whether compliance started
  public isStarted: boolean;

  // instance variable for form
  public simulated_date: Date;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;

  // form and form groups
  public form: FormGroup;
  public simulatedDate: FormControl;
  public form_start: FormGroup;
  public wfStartDate: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.simulateDateEnabled = false;
    this.reload();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.compliance
      });
    }, 0);
    this.reloadOnSync();
  }

  enterSimulation(): void {
    this.simulateDateEnabled = true;
  }

  exitSimulation(): void {
    this.simulateDateEnabled = false;
    this.reload();
  }

  getSimulatedCompEvents(): void {
    this.reloaded = false;
    if (!this.form.valid) {
      return;
    }

    if (!moment(this.simulatedDate.value, this.dateFormat).isValid()) {
      return;
    }

    const simulationDate = moment(this.simulatedDate.value, this.dateFormat).format(this.dateFormat);
    this.tranService.getCurrentTranId()
      .then((tranId: number) => {
        this.tranId = tranId;
        return this.loanAppService.getCurrentLoanDocument();
      })
      .then((doc) => {
        const isRefinanceLoan = doc ? this.analyzerService.isRefinanceLoan(doc.recursive_attributes) : false;
        return this.compService.getCompReport(this.tranId, this.dateFormat, simulationDate, isRefinanceLoan, true);
      })
      .then((response) => {
          let report;
          if (response && (report = response.report)) {
            this.compEvents = CompEvent.deserializeArray(Object.values(report));
            const keyEvents = CompEvent.getKeyEvents(report);
            this.isStarted = keyEvents['isStarted'];
            this.levelCounts = keyEvents['levelCounts'];
            this.startDate = keyEvents['startDate'];
            this.creditAuthDate = keyEvents['creditAuthDate'];
            this.loanAppDate = keyEvents['loanAppDate'];
            this.leDate = keyEvents['leDate'];
            this.cdDate = keyEvents['cdDate'];
            this.lockDate = keyEvents['lockDate'];
          }
          this.reloaded = true;
        }
      ).catch((errorData) => {
      console.log('BAD', errorData);
    });
  }

  createFormControls(): void {
    this.simulatedDate = new FormControl(this.simulated_date);
    this.wfStartDate = new FormControl(new Date());
  }

  createForm(): void {
    this.form = new FormGroup({
      simulatedDate: this.simulatedDate
    });
    this.form_start = new FormGroup({
      wfStartDate: this.wfStartDate
    });
  }

  reload(force?: boolean): void {
    this.simulated_date = new Date();
    this.createFormControls();
    this.createForm();
    this.getSimulatedCompEvents();
  }

  startWorkflow(): void {

    // const simulationDate = moment(this.simulatedDate.value, this.dateFormat).format(this.dateFormat);
    const payload = {
      new_event: 'start',
      notify_contacts: [],
      force: false,
      started_at: this.wfStartDate.value
    };

    this.workflowService.updateWorkflow(this.tranId, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Workflow successfully started');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to start workflow', data);
        this.cancel();
      });
  }
}
