import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {OrgMcrComponent} from '../org-mcr/org-mcr.component';
import {FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {Mcr} from '../../../../models/mcr';

@Component({
  selector: 'app-view-segment-org-mcr',
  templateUrl: './view-segment-org-mcr.component.html',
})
export class ViewSegmentOrgMcrComponent extends OrgMcrComponent implements OnInit {
  public pageTitle = 'Organization MCR';
  public mcrTitle: string;
  public form: FormGroup;

  constructor(public injector: Injector, private route: ActivatedRoute, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createForm();
    this.mcrTitle = `Organization MCR for Q${this.mcr.quarter} ${this.mcr.year}`;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansList
      });
    }, 0);
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mcrService.getMcr(this.mcr.id)
      .then((mcr: Mcr) => {
        this.mcr = mcr;
        this.reloaded = true;
      });
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  goToReport(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Report'});
  }

  goToXml(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'Xml'});
  }

  deleteOrgMcr(event: any): void {
    event.preventDefault();
    this.mcrService.deleteMcr(this.mcr.id)
      .then((mcr) => {
        FormUtil.genSuccessMessage(this.sharedService, 'MCR report successfully deleted');
        this.router.navigate(['/mcr/view-wizard']);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete MCR report', data);
      });
  }

  back(): void {
    this.router.navigate(['/mcr/view-wizard']);
  }
}
