import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Concession} from '../../../../models/concession';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-concession',
  template: '',
})
export class ConcessionComponent extends CompBaseComponent implements OnInit {
  @Input() item: Concession;
  public concession_types = Concession.concession_types;

  public form: FormGroup;
  public concession_type: FormControl;
  public concession_amount: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'concessions';
    this.section = 'lo';
  }

  createFormControls(item: Concession): void {
    this.concession_amount = new FormControl(item.concession_amount, AppValidators.present);
    this.concession_type = new FormControl(item.concession_type, AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      concession_type: this.concession_type,
      concession_amount: this.concession_amount,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Concession(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Concession(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
