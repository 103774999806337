import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {globals} from '../shared/globals/globals';
import {ConfigItem} from "../models/config-item";

@Injectable()
export class ConfigurationService {

  constructor(private httpService: HttpService) {
  }

  loadApp(): void {
    this.httpService.setUrlBase(globals.getUrlBase());
  }

  public getConfigItem(configKey: string): Promise<ConfigItem> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`config`, {config_key: configKey}, false)
        .then((data) => {
          const item = new ConfigItem(data);
          resolve(item);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public createConfigItem(configKey: string, configValue: string): Promise<ConfigItem> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`config`, {
        config_key: configKey,
        config_value: configValue
      }, false)
        .then((data) => {
          const item = new ConfigItem(data);
          resolve(item);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public deleteConfigItem(configKey: string): Promise<ConfigItem> {
    return new Promise((resolve, reject) => {
      return this.httpService.delete(`config`, {config_key: configKey})
        .then((data) => {
          const item = new ConfigItem(data);
          resolve(item);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateConfigItem(configKey: string, configValue: string): Promise<ConfigItem> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`config`, {
        config_key: configKey,
        config_value: configValue
      }, false)
        .then((data) => {
          const item = new ConfigItem(data);
          resolve(item);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public getConfigItems(): Promise<Array<ConfigItem>> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('config/index', {}, false)
        .then((data) => {
          const item = ConfigItem.deserializeArray(data);
          resolve(item);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }
}
