import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ContactComponent} from '../contact/contact.component';

@Component({
  selector: 'app-add-contact',
  templateUrl: '../contact/edit-segment-contact.component.html',
})
export class AddContactComponent extends ContactComponent implements OnInit {
  @ViewChild('nameElt') nameElt;
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
