import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-add-state-license',
  templateUrl: './add-state-license.component.html',
})
export class AddStateLicenseComponent extends CompBaseComponent implements OnInit {
  public form: FormGroup;
  public state: FormControl;
  public license: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  add(): void {
    this.eventEmitted.emit({type: 'NewLicense', state: this.state.value.toUpperCase(), license: this.license.value});
  }

  createFormControls(): void {
    this.state = new FormControl(null, Validators.compose([Validators.required, Validators.pattern(globals.pattern.state)]));
    this.license = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      state: this.state,
      license: this.license
    });
  }
}
