<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="isMobile ? 'Send Invite' : 'Send an Invitation to Join PrudentCO'" [help]="false"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>First name</mat-label>
            <input formControlName="firstName" matInput maxlength="{{MAX_LEN.NAME_PART}}" placeholder="First name" type="text" appAutoFocus required>
            <mat-error *ngIf="firstName.hasError('required')">
              First name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Last name</mat-label>
            <input formControlName="lastName" matInput maxlength="{{MAX_LEN.NAME_PART}}" placeholder="Last name" type="text" required>
            <mat-error *ngIf="lastName.hasError('required')">
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Email address</mat-label>
            <input formControlName="email" matInput maxlength="{{MAX_LEN.EMAIL}}" placeholder="Email address" type="text">
            <mat-error *ngIf="email.hasError('required')">
              Email address is required
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              Email address is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="sendUserRegistrationEmail()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="submit">Send Invite</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
