import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appAlphaNumericOnly]'
})
export class AlphaNumericOnlyDirective {
  // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp(/^-?\d*$/g);
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9_]*$/g);
  // Allow key codes for special events: Backspace, tab, end, home
  private specialKeys: Array<string> = ['Enter', 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // allow also all meta keys (they are browser dependent)
    if (this.specialKeys.indexOf(event.key) >= 0 || event.metaKey) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
