<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row mb-2">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Residence type</div>
      <div class="col-lg-6">{{item.getResidenceType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.residence_type_ex === 'Mailing'">
      <div class="col-lg-6 fw-bold">Residence term</div>
      <div class="col-lg-6">{{item.getResidenceTerm()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Address</div>
      <div class="col-lg-6">{{item.address}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Apt/Unit/Suite</div>
      <div class="col-lg-6">{{item.address2}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">City</div>
      <div class="col-lg-6">{{item.city}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">State</div>
      <div class="col-lg-6">{{item.state}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Zip</div>
      <div class="col-lg-6">{{item.zip}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Zip four</div>
      <div class="col-lg-6">{{item.zip_four}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Country code</div>
      <div class="col-lg-6">{{item.country}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.residence_type_ex === 'Mailing'">
      <div class="col-lg-6 fw-bold">Number of years</div>
      <div class="col-lg-6">{{item.years_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.residence_type_ex === 'Mailing'">
      <div class="col-lg-6 fw-bold">Number of months</div>
      <div class="col-lg-6">{{item.months_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="item.residence_term_ex !== 'Rent'">
      <div class="col-lg-6 fw-bold">Rent</div>
      <div class="col-lg-6">{{item.rent | currency}}</div>
    </div>
  </mat-card-content>
</mat-card>
