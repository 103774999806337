<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Race'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            You are not required to submit information about your race identity.
          </li>
          <li>
            However, if you do not provide this information, the lender is required under federal regulations to make
            a best guess based on surname and visual observation if you are making this application in person.
          </li>
          <li>
            You may select more than one race.
          </li>
          <li>
            For some races (for example, Asian) you can also check your nationality. If your nationality is not
            on the list, you can write in your nationality (for example, Thai).
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-race (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc" [items]="items"></app-add-race>

    <div *ngFor="let item of items">
      <app-view-race (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item" [items]="items"></app-view-race>
    </div>

    <form *ngIf="canUpdateDocument" [formGroup]="form" novalidate>
      <div class="row">
        <div class="col">
          <mat-checkbox (change)="onNoInformationChange()" formControlName="no_information_race">I do not wish to provide this information</mat-checkbox>
        </div>
      </div>
    </form>

    <div class="row mt-4" *ngIf="!canUpdateDocument&&(no_information_race.value==='Y' || no_information_race.value===true)">
      <div class="col">
        &check; I do not wish to provide this information.
      </div>
    </div>
  </mat-card>
</div>
