import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {Order} from '../../../../models/order';
import {AdminService} from '../../../../services/admin.service';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-view-order-list',
  templateUrl: './view-order-list.component.html',
})
export class ViewOrderListComponent extends CompBaseComponent implements OnInit {
  public adminService: AdminService;
  public orders: Array<Order>;
  public pageTitle = 'Order List';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.findOrders(params);
    });
  }

  findOrders(params: any): void {
    this.reloaded = false;
    this.adminService.findOrders(+params['user_id'])
      .then((orders) => this.orders = orders)
      .then(() => this.reloaded = true);
  }

  viewOrder(event: any, order: Order): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-order', order.id]);
  }
}
