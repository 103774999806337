<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="list-ic vertical">
      <li [ngClass]="{active:currentSeg===seg.tab2Instructions}">
        <span></span>
        <a (click)="setSeg($event,seg.tab2Instructions)" href="" id="tab2">Instructions</a>
      </li>
      <li [ngClass]="{active:currentSeg===applicant}">
        <span></span>
        <a (click)="setSeg($event,applicant)" [ngClass]="{active:currentSeg===applicant}" href="" id="{{applicant}}_{{applicant}}">{{applicantTitle}}</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.dependents}">
        <span></span>
        <a (click)="setSeg($event,seg.dependents)" [ngClass]="{active:currentSeg===seg.dependents}" href="" id="{{applicant}}_dependents">Dependents</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.addresses}">
        <span></span>
        <a (click)="setSeg($event,seg.addresses)" [ngClass]="{active:currentSeg===seg.addresses}" href="" id="{{applicant}}_addresses">Addresses</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.employment}">
        <span></span>
        <a (click)="setSeg($event,seg.employment)" [ngClass]="{active:currentSeg===seg.employment}" href="" id="{{applicant}}_employment">Prim Employment</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.employment2}">
        <span></span>
        <a (click)="setSeg($event,seg.employment2)" [ngClass]="{active:currentSeg===seg.employment2}" href="" id="{{applicant}}_employment2">Sec Employment</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.secEmployments}">
        <span></span>
        <a (click)="setSeg($event,seg.secEmployments)" href="" id="{{applicant}}_secondary_employments">Prev Employments</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.incomes}">
        <span></span>
        <a (click)="setSeg($event,seg.incomes)" href="" id="{{applicant}}_incomes">Income</a>
      </li>
      <li *ngIf="isPurchaseLoan" [ngClass]="{active:currentSeg===seg.gifts}">
        <span></span>
        <a (click)="setSeg($event,seg.gifts)" href="" id="{{applicant}}_gifts">Gifts</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.liabilities}">
        <span></span>
        <a (click)="setSeg($event,seg.liabilities)" href="" id="{{applicant}}_liabilities">Liabilities</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.supports}">
        <span></span>
        <a (click)="setSeg($event,seg.supports)" href="" id="{{applicant}}_supports">Expenses</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.aliases}">
        <span></span>
        <a (click)="setSeg($event,seg.aliases)" href="" id="{{applicant}}_aliases">Aliases</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.declarations}">
        <span></span>
        <a (click)="setSeg($event,seg.declarations)" href="" id="{{applicant}}_declaration">Declarations</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.declarationExplanations}">
        <span></span>
        <a (click)="setSeg($event,seg.declarationExplanations)" href="" id="{{applicant}}_declaration_explanations">Explanations</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.military}">
        <span></span>
        <a (click)="setSeg($event,seg.military)" [ngClass]="{active:currentSeg===seg.military}" href="" id="{{applicant}}_military">Military</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.information}">
        <span></span>
        <a (click)="setSeg($event,seg.information)" [ngClass]="{active:currentSeg===seg.information}" href="" id="{{applicant}}_information">Ethnicity & Sex</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.races}">
        <span></span>
        <a (click)="setSeg($event,seg.races)" href="" id="{{applicant}}_race_informations">Race</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.hmda}" *ngIf="canUpdateTransaction">
        <span></span>
        <a (click)="setSeg($event,seg.hmda)" href="" id="{{applicant}}_hmda">HMDA</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.agreement}">
        <span></span>
        <a (click)="setSeg($event,seg.agreement)" href="" id="{{applicant}}_agreement">Agreement</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!(isMobile&&isOpen)" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-instructions  *ngSwitchCase="seg.tab2Instructions" [tab]="'tab2'">
      </app-instructions>
      <app-view-declarations (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.declarations" [applicant]="applicant" [doc]="doc">
      </app-view-declarations>
      <app-view-segment-declaration-explanations (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.declarationExplanations" [applicant]="applicant" [doc]="doc">
      </app-view-segment-declaration-explanations>
      <app-view-agreement (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.agreement" [applicant]="applicant" [doc]="doc">
      </app-view-agreement>
      <app-view-hmda (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.hmda" [applicant]="applicant" [doc]="doc">
      </app-view-hmda>
      <app-view-employment (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.employment" [employmentType]="'primary'" [applicant]="applicant" [doc]="doc">
      </app-view-employment>
      <app-view-employment (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.employment2" [employmentType]="'secondary'" [applicant]="applicant" [doc]="doc">
      </app-view-employment>
      <app-view-information (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.information" [applicant]="applicant" [doc]="doc">
      </app-view-information>
      <app-view-borrower (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.borrower_1" [applicant]="applicant" [doc]="doc">
      </app-view-borrower>
      <app-view-borrower (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.borrower_2" [applicant]="applicant" [doc]="doc">
      </app-view-borrower>
      <app-view-borrower (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.borrower_3" [applicant]="applicant" [doc]="doc">
      </app-view-borrower>
      <app-view-borrower (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.borrower_4" [applicant]="applicant" [doc]="doc">
      </app-view-borrower>
      <app-view-segment-addresses (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.addresses" [applicant]="applicant" [doc]="doc">
      </app-view-segment-addresses>
      <app-view-segment-gifts (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.gifts" [applicant]="applicant" [doc]="doc">
      </app-view-segment-gifts>
      <app-view-segment-aliases (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.aliases" [applicant]="applicant" [doc]="doc">
      </app-view-segment-aliases>
      <app-view-segment-dependents (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.dependents" [applicant]="applicant" [doc]="doc">
      </app-view-segment-dependents>
      <app-view-segment-liabilities (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.liabilities" [applicant]="applicant" [doc]="doc">
      </app-view-segment-liabilities>
      <app-view-segment-incomes (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.incomes" [applicant]="applicant" [doc]="doc">
      </app-view-segment-incomes>
      <app-view-segment-down-payments (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.downPayments" [applicant]="applicant" [doc]="doc">
      </app-view-segment-down-payments>
      <app-view-segment-sec-employments (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.secEmployments" [applicant]="applicant" [doc]="doc">
      </app-view-segment-sec-employments>
      <app-view-military (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.military" [applicant]="applicant" [doc]="doc">
      </app-view-military>
      <app-view-segment-races (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.races" [applicant]="applicant" [doc]="doc">
      </app-view-segment-races>
      <app-view-segment-supports (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.supports" [applicant]="applicant" [doc]="doc">
      </app-view-segment-supports>
      <app-view-segment-credits (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.credits" [applicant]="applicant" [doc]="doc">
      </app-view-segment-credits>
    </div>

    <app-popup-compliance-container style="width:100%;" *ngIf="false"></app-popup-compliance-container>
  </div>

  <div *ngIf="isMobile&&isOpen" class="col">
    <app-box [close]="false" [type]="'success'" class="mt-2">
      Click any menu-item to view/edit it.
    </app-box>
  </div>
</div>
