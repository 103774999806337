import {Component, Injector, OnInit} from '@angular/core';
import {EmailComponent} from '../email/email.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {globals} from '../../../shared/globals/globals';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-send-upload-email',
  templateUrl: './edit-upload-email.component.html',
})
export class SendUploadEmailComponent extends EmailComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  createFormControls(): void {
    this.firstName = new FormControl(null, AppValidators.present);
    this.lastName = new FormControl(null, AppValidators.present);
    this.email = new FormControl(null, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
    this.context = new FormControl(null);
  }

  createForm(): void {
    this.form = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      context: this.context
    });
  }

  cancel(): void {
    this.reload();
  }

  sendUploadEmail(): Promise<void> {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const payload = {
      first: this.firstName.value,
      last: this.lastName.value,
      email: this.email.value,
      context: this.context.value,
      email_code: 'upload_request'
    };
    this.emailService.sendEmail(payload)
      .then(() => {
        const message = `The ezUploads Request Email was successfully queued for sending.`;
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.reload(false);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to send ezUploads Request Email', data);
      });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
