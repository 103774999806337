import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HomeComponent} from '../home.component';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
})
export class HighlightsComponent extends HomeComponent implements OnInit, OnDestroy {

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = 'Account Highlights';
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.reloadHome()
      .then(() => {
        setTimeout(() => {
          this.sharedService.emitChange({
            type: 'ChangePage',
            title: this.pageTitle,
            help: Help.helpKeys.homeHighlights
          });
          this.reloaded = true;
        }, 0);
      });
  }
}
