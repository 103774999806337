import {Component, Injector, Input, OnInit} from '@angular/core';
import {Invoice} from '../../../../models/invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {AdminInvoiceComponent} from '../invoice/admin-invoice.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-admin-edit-invoice',
  templateUrl: '../invoice/admin-edit-invoice.component.html',
})
export class AdminEditInvoiceComponent extends AdminInvoiceComponent implements OnInit {
  @Input() public item: Invoice;
  public invoiceId: number;
  public pageTitle = 'Edit Invoice';

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.route.paramMap.subscribe((map) => {
      this.invoiceId = map['params']['id'];
      this.reload();
    });

  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.invoiceService.getInvoice(this.invoiceId)
      .then((invoice: Invoice) => {
        this.item = invoice;
        this.createFormControls(this.item);
        this.createForm();
        this.reloaded = true;
      });
  }

  cancel(): void {
    this.router.navigate(['/admin/view-invoice', this.item.id]);
  }

  update(): void {
    this.invoiceService.updateInvoice(this.item.id, this.form.value)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Invoice successfully updated');
        this.router.navigate(['/admin/view-invoice', this.item.id]);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update invoice', data);
      });
  }
}
