<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Real estate asset ID</div>
      <div class="col-lg-6">{{item.reo_asset_id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Address</div>
      <div class="col-lg-6">{{item.getFullAddress()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Estimated market value</div>
      <div class="col-lg-6">{{item.estimated_market_value | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Market value</div>
      <div class="col-lg-6">{{item.market_value | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Property disposition</div>
      <div class="col-lg-6">{{item.getPropertyDisposition()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Current occupancy</div>
      <div class="col-lg-6">{{item.getCurrentOccupancy()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Intended occupancy</div>
      <div class="col-lg-6">{{item.getOccupancy()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.occupancy==='Other'">
      <div class="col-lg-6 fw-bold">Other occupancy description</div>
      <div class="col-lg-6">{{item.occupancy_other_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Liens amount</div>
      <div class="col-lg-6">{{item.liens_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Gross rental income</div>
      <div class="col-lg-6">{{item.gross_rental_income | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Monthly expenses</div>
      <div class="col-lg-6">{{item.monthly_expenses | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Net rental income</div>
      <div class="col-lg-6">{{item.net_rental_income | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Does the property secure the loan?</div>
      <div class="col-lg-6" *ngIf="item.property_secure_loan!==null">{{item.property_secure_loan | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.property_secure_loan===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Owners</div>
      <div class="col-lg-6">
        {{getPartyIds()}}
      </div>
    </div>
  </mat-card-content>
</mat-card>
