import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Construction} from '../../../../models/construction';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-construction',
  templateUrl: './edit-segment-construction.component.html',
})
export class EditSegmentConstructionComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Construction;
  public readonly land_appraisal_types = Construction.land_appraisal_types;
  public readonly construction_loan_types = Construction.construction_loan_types;
  public readonly construction_closing_types = Construction.construction_closing_types;

  // form and form controls
  form: FormGroup;
  construction_loan_type: FormControl;
  construction_closing_type: FormControl;
  land_original_cost: FormControl;
  construction_improvements_cost: FormControl;
  energy_efficient_indicator: FormControl;
  contract_for_deed_indicator: FormControl;
  renovation_loan_indicator: FormControl;
  energy_improvements_indicator: FormControl;
  land_appraised_value: FormControl;
  land_appraisal_type: FormControl;


  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'construction';
    this.section = 'lo';
    this.reload();
  }

  reload(force?: boolean): void {
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.construction_loan_type = new FormControl(item.construction_loan_type);
    this.construction_closing_type = new FormControl(item.construction_closing_type);
    this.land_original_cost = new FormControl(item.land_original_cost, Validators.pattern(globals.pattern.decimalTwo));
    this.construction_improvements_cost = new FormControl(item.construction_improvements_cost, Validators.pattern(globals.pattern.decimalTwo));
    this.energy_efficient_indicator = new FormControl(item.energy_efficient_indicator);
    this.contract_for_deed_indicator = new FormControl(item.contract_for_deed_indicator);
    this.renovation_loan_indicator = new FormControl(item.renovation_loan_indicator);
    this.energy_improvements_indicator = new FormControl(item.energy_improvements_indicator);
    this.land_appraised_value = new FormControl(item.land_appraised_value);
    this.land_appraisal_type = new FormControl(item.land_appraisal_type);
  }

  createForm(): void {
    this.form = new FormGroup({
      construction_loan_type: this.construction_loan_type,
      construction_closing_type: this.construction_closing_type,
      land_original_cost: this.land_original_cost,
      construction_improvements_cost: this.construction_improvements_cost,
      energy_efficient_indicator: this.energy_efficient_indicator,
      contract_for_deed_indicator: this.contract_for_deed_indicator,
      renovation_loan_indicator: this.renovation_loan_indicator,
      energy_improvements_indicator: this.energy_improvements_indicator,
      land_appraised_value: this.land_appraised_value,
      land_appraisal_type: this.land_appraisal_type,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Construction(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();
    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
