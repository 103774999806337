import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-page-verify-device',
  templateUrl: './verify-device.component.html',
  styleUrls: ['./verify-device.component.scss']
})
export class PageVerifyDeviceComponent implements OnInit {
  public reloaded: boolean;
  public verified: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService) {
  }

  ngOnInit() {
    this.reloaded = false;
    this.route.queryParams.subscribe((params) => {

      this.userService.verifyUserToken(params)
        .then(() => {
          this.reloaded = true;
          this.verified = true;
        })
        .catch(() => {
          this.verified = false;
          this.reloaded = true;
        });
    });
  }

  navigateToSign(event: any): void {
    event.preventDefault();
    this.router.navigate(['/pages/sign-in']);
  }
}
