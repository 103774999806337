<mat-card *ngIf="reloaded" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="mcrTitle" [help]="true">
      <p>
        The list below is of your organization's loan officers whose MCR data is used to compile the organization MCR report.
      </p>
      <ul>
        <li>To generate your organization MCR, click Manage MCR, and then on Generate Report</li>
        <li>To delete the organization MCR, click Manage MCR. The Delete function is then available.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <app-box [close]="true" [type]="'primary'" class="pt-2">
    <ul>
      <li>
        To review your state MCR online, click Review State Report button.
      </li>
      <li>
        To create your organization MCR as an XML file, click Create XML button.
      </li>
    </ul>

    <div>
      Downloaded XML files should be in your downloaded folder.
    </div>
  </app-box>

  <mat-card-content>
    <form [formGroup]="form" class="mt-3">
      <div class="row">
        <div class="col-lg-10">
          <button (click)="goToReport()" class="btn btn-lg btn-primary" title="Create MCR report" type="button">Review State Report</button>
          <button (click)="goToXml()" class="ms-2 btn btn-lg btn-primary" type="button" title="Create MCR in XML format">Create XML</button>
          <button (click)="back()" class="ms-2 btn btn-lg btn-light" type="button" title="Go back">
            <i class="fa fa-chevron-left"></i>&nbsp;Back
          </button>
        </div>
        <div class="col-lg-2 text-end">
          <a (click)="deleteOrgMcr($event)" class="ms-2 hand-pointer" title="Delete MCR"><i class="text-danger fa fa-trash"></i></a>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

