import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {globals} from '../../../../shared/globals/globals';
import $ from 'jquery';

@Component({
  selector: 'app-view-invite',
  templateUrl: './view-invite.component.html',
})
export class ViewInviteComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Invite Users';
  public inviteUserLink: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountInvite
      });
    }, 0);
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }

    super.onEvent(event);
  }

  copyLinkToClipboard(): void {
    const copyToClipboard = function () {
      const elt = window.document.getElementById('invite_user_link');
      const $temp = $('<input>');
      $('body').append($temp);
      $temp.val($(elt).text()).select();
      document.execCommand('copy');
      $temp.remove();
    };

    copyToClipboard();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const userId = this.userService.getUserSync().id;
    this.inviteUserLink = globals.getInviteUserLink(userId);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
