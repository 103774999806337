import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './layouts/default/default.component';
import {ExtraLayoutComponent} from './layouts/extra/extra.component';

import {UIModule} from './components/ui.module';
import {SharedModule} from './shared/shared.module';
import {ContentModule} from './content/content.module';
import {PagesModule} from './pages/pages.module';
// The next import includes the core NgIdleModule but includes keepalive providers for easy wireup
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {HttpService} from './http/http.service';
import {ConfigurationService} from './services/configuration.service';
import {PingService} from './services/ping.service';
import {MenuService} from './services/menu.service';
import {HomeService} from './services/home.service';
import {WrapperLayoutComponent} from './layouts/wrapper/wrapper.component';
import {SiteLayoutComponent} from './layouts/site/site.component';
import {SiteComponent} from './content/site/site.component';
import {ContactUsComponent} from './content/site/contact/contact-us.component';
import {OurStoryComponent} from './content/site/about/our-story/our-story.component';
import {POLOSFeaturesComponent} from './content/site/about/polos-features/polos-features.component';
import {POLOSVideoComponent} from './content/site/about/polos-video/polos-video.component';
import {SiteFootBannerComponent} from './content/site/site-foot-banner/site-foot-banner.component';
import {TermsComponent} from './content/site/about/terms/terms.component';
import {PrivacyComponent} from './content/site/about/privacy/privacy.component';
import {CalculatorService} from './services/calculator.service';
import {AgentService} from './services/agent.service';
import {InvoiceService} from './services/invoice.service';
import {NavigationService} from './services/navigation.service';
import {McrServiceComponent} from './content/site/mcr/mcr-service.component';
import {ProcessService} from "./services/processr.service";
import {UploadFileService} from "./services/upload-file.service";
import {LandingComponent} from "./content/site/landing/landing.component";
import {ShopComponent} from "./content/site/shop/shop.component";

@NgModule({
  declarations: [
    AppComponent,
    WrapperLayoutComponent,
    DefaultLayoutComponent,
    SiteLayoutComponent,
    ExtraLayoutComponent,
    SiteComponent,
    LandingComponent,
    ShopComponent,
    ContactUsComponent,
    OurStoryComponent,
    McrServiceComponent,
    SiteFootBannerComponent,
    TermsComponent,
    PrivacyComponent,
    POLOSFeaturesComponent,
    POLOSVideoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UIModule,
    ContentModule,
    SharedModule,
    PagesModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    HttpService,
    ConfigurationService,
    PingService,
    MenuService,
    InvoiceService,
    ProcessService,
    HomeService,
    CalculatorService,
    AgentService,
    NavigationService,
    UploadFileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
