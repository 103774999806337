<app-simple-navbar></app-simple-navbar>

<div *ngIf="!reloaded" class="row upload spinner mt-4">
  <div class="col text-center">
    <img src='/api/images/spinner_white.gif'>
  </div>
</div>

<div *ngIf="reloaded">
  <!-- link failed verification -->
  <div *ngIf="!verified">
    <app-ez-upload-logo></app-ez-upload-logo>
    <app-card [align]="'center'" class="mt-5" [title]="'Upload Verification'" [outline]="true" [alignTitle]="'center'">
      <span class="material-icons text-danger">highlight_off</span>
      <p class="text-danger">
        <strong>Upload Verification Failed!</strong>
      </p>
      <p>
        Ask your service provider to re-send you a new upload link.
      </p>
    </app-card>
  </div>

  <!-- link verified -->
  <div *ngIf="verified">
    <!-- did not check warnings -->
    <div *ngIf="!warned">
      <div class="row mt-5 justify-content-center">
        <div class="col-lg-5">
          <mat-card class="mt-1 mb-2">
            <app-ez-upload-logo></app-ez-upload-logo>
            <mat-card-title class="text-center mt-5">Important Instructions</mat-card-title>
            <mat-card-content>
              <div class="mb-2">
                <span class="fw-bold">Do not refresh or reload this page!</span>
                Each upload link can only be accessed once.
              </div>
              <div>
                Please confirm:
              </div>
              <form [formGroup]="form" novalidate>
                <div class="row">
                  <div class="col">
                    <mat-checkbox formControlName="warn1">{{user_full_name}} has sent this request and will receive the files you upload.</mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-checkbox formControlName="warn2">You are aware of the reason for their request.</mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-checkbox formControlName="warn3">You authorize PrudentCO to release these documents to {{user_full_name}}.</mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-center mt-3">
                    <button (click)="onDoneWarn()" [disabled]="!(warn1.value&&warn2.value&&warn3.value)" class="btn btn-lg btn-primary" type="button">Begin Uploads</button>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <!-- checked warnings -->
    <div *ngIf="warned" class="mt-4">

      <!-- uploading files -->
      <div *ngIf="!done" class="row pt-1 justify-content-center">
        <div class="col-lg-6">
          <mat-card class="mt-1 mb-2">
            <mat-card-content>
              <div class="row">
                <div class="col-lg-6 mx-auto">
                  <app-ez-upload-logo></app-ez-upload-logo>
                </div>
              </div>

              <div class="alert alert-danger mt-3" role="alert">
                <div class="text-danger">
                  You are authorizing the uploaded files to be shared with:
                </div>
                <div class="fw-bold text-danger">
                  <div>Full name: {{user_full_name}}</div>
                  <div>Email: {{user_email}}</div>
                  <div>Phone: {{user_phone | phone}}</div>
                </div>
                <div class="text-danger">
                  If this is not correct, please do not proceed.
                </div>
              </div>

              Steps to upload documents:
              <ul>
                <li>
                  Choose a PDF or image to upload.
                </li>
                <li>
                  Provide a short file description for the recipient.
                </li>
                <li>
                  Click Upload.
                </li>
                <li>
                  You can repeat the above steps to upload multiple files.
                </li>
                <li>
                  When done, click Finished or close this window.
                </li>
              </ul>

              <app-box *ngIf="error" [close]="true" [type]="'danger'" [fadeOut]="true">
                <span [innerHTML]="error"></span>
              </app-box>

              <app-upload-file *ngIf="appUploadFile" [isDescription]="true" class="col-lg-6" (error)="onError($event)" (uploadSubmit)="onUploadSubmit($event)" (uploadComplete)="onUploadComplete($event)" [disableCancel]="true" [uploadType]="'pdf-image'" [multipleFiles]="true" [actionUrl]=actionUrl [title]="'Upload Files'">
              </app-upload-file>

              <div class="mb-2">
                <span *ngIf="filenames.length>0">The following files have already been uploaded:</span>
                <span *ngIf="filenames.length==0">The uploaded files will be listed below.</span>
                <ol>
                  <li *ngFor="let filename of filenames; let indexOfFilename=index" class="hover-gray pr-font-large fw-bold">{{filename}}</li>
                  <!--              <div class="col-lg-6 text-end">-->
                  <!--                <a (click)="deleteFileName(indexOfFilename)" class="me-5" title="Remove document"><i class="text-danger fas fa-trash"></i></a>-->
                  <!--              </div>-->
                </ol>
              </div>

              <div class="row">
                <div class="col">
                  <button (click)="onDone()" [disabled]="filenames.length==0" class="btn btn-lg btn-primary" type="button">Finished</button>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <!-- done uploading files -->
      <div *ngIf="done&&filenames.length>0" class="mt-5">
        <app-ez-upload-logo/>
        <mat-card class="mt-3 w-50" style="margin: auto">
          <mat-card-title>Thank You!</mat-card-title>
          <mat-card-content>
            <p>
              Thank you for keeping your documents secure. The following documents were uploaded:
            </p>
            <ul class="fw-bold">
              <li *ngFor="let filename of filenames">{{filename}}</li>
            </ul>
            <p>
              Please, close this window.
            </p>
            <p>
              <b>PrudentCO Team</b>
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
