import {Component, OnInit} from '@angular/core';
import {globals} from "../globals/globals";

@Component({
  selector: 'app-service-content',
  templateUrl: './service-content.component.html',
})
export class ServiceContentComponent implements OnInit {
  public contactEmail: string;
  constructor() {
  }

  ngOnInit() {
    this.contactEmail = globals.contactEmailForTerms;
  }
}
