<div class="row">
  <div class="col-lg-9">
    <mat-card *ngIf="reloaded" class="mt-1 mb-1 h-100">
      <mat-card-title>Invite Users to Join PrudentCO</mat-card-title>
      <mat-card-content>
        There are two ways you can invite new users to join PrudentCO and
        <span class="fw-bold">be notified</span> when they register.

        <h5>Invite User from a Loan</h5>

        You can invite your borrower to join PrudentCO from
        <span class="fw-bold">Current Loan/Messages/Invite User</span>.
        When you follow this route:

        <ul>
          <li>
            You should receive a confirmation message upon sending the invite.
          </li>
          <li>
            When the user creates a PrudentCO account, you receive a notification.
          </li>
        </ul>

        <h5>Invite Users Through an External Service</h5>

        You can also invite a user using an external service such as your CRM or mail service.
        To proceed in this way, copy the following link to your external service.
        <div class="segment-header-box mt-2">
          <div class="row">
            <div class="col-lg-9 my-auto" id="invite_user_link">{{inviteUserLink}}</div>
            <div class="col-lg-3 my-auto">
              <button class="btn btn-lg btn-primary" (click)="copyLinkToClipboard()">Copy Link to Clipboard</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
