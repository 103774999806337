<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Originator Information</mat-card-title>

  <mat-card-content>
    <div class="row pb-3">
      <div class="col">
        <button (click)="populate()" class="btn btn-lg btn-warning" type="submit">Populate from Account</button>
      </div>
    </div>

    <form (ngSubmit)="update()" [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Loan originator name</mat-label>
            <input type="text" formControlName="lo_name" matInput placeholder="Loan originator name" appAutoFocus maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="lo_name.hasError('required')">
              Loan originator name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Phone number</mat-label>
            <input type="text" appPhoneOnly formControlName="lo_phone_number" matInput placeholder="Phone number" maxlength="{{MAX_LEN.PHONE}}">
            <mat-error *ngIf="lo_phone_number.hasError('required')">
              Phone number is required
            </mat-error>
            <mat-error *ngIf="lo_phone_number.hasError('pattern')">
              Phone number is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Loan application received date</mat-label>
            <input [matDatepicker]="$any(datePicker2)" [min]="minDate" [max]="maxDate" formControlName="lo_received_date" matInput placeholder="Loan application received date">
            <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker2></mat-datepicker>
            <mat-error *ngIf="lo_received_date.hasError('matDatepickerMin') || lo_orig_date.hasError('matDatepickerMax')">
              Loan application received date is unrealistic
            </mat-error>
            <mat-error *ngIf="lo_received_date.hasError('required')">
              Application received is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Origination date</mat-label>
            <input [matDatepicker]="$any(datePicker)" [min]="minDate" [max]="maxDate" formControlName="lo_orig_date" matInput placeholder="Origination date">
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="lo_orig_date.hasError('matDatepickerMin') || lo_orig_date.hasError('matDatepickerMax')">
              Origination date is unrealistic
            </mat-error>
            <mat-error *ngIf="lo_orig_date.hasError('required')">
              Origination date is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company name</mat-label>
            <input type="text" formControlName="lo_company_name" matInput placeholder="Company name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="lo_company_name.hasError('required')">
              Company name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company address</mat-label>
            <input type="text" formControlName="lo_company_address" matInput placeholder="Company address" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="lo_company_address.hasError('required')">
              Company address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company Apt/Unit/Suite</mat-label>
            <input type="text" formControlName="lo_company_address2" matInput placeholder="Company Apt/Unit/Suite" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="lo_company_address2.hasError('required')">
              Company Apt/Unit/Suite is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company city</mat-label>
            <input type="text" formControlName="lo_company_city" matInput placeholder="Company city" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="lo_company_city.hasError('required')">
              Company city is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company state</mat-label>
            <input type="text" formControlName="lo_company_state" matInput appUpperCase appAlphaOnly placeholder="Company state" maxlength="{{MAX_LEN.STATE_ABBR}}">
            <mat-error *ngIf="lo_company_state.hasError('required')">
              Company state is required
            </mat-error>
            <mat-error *ngIf="lo_company_state.hasError('pattern')">
              Company state is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company zip</mat-label>
            <input type="text" appNumberOnly formControlName="lo_company_zip" matInput placeholder="Company zip" maxlength="{{MAX_LEN.ZIP}}">
            <mat-error *ngIf="lo_company_zip.hasError('required')">
              Company zip is required
            </mat-error>
            <mat-error *ngIf="lo_company_zip.hasError('pattern')">
              Company zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company zip four</mat-label>
            <input type="text" appNumberOnly formControlName="lo_company_zip_four" matInput placeholder="Company zip four" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="lo_company_zip_four.hasError('required')">
              Company zip four is required
            </mat-error>
            <mat-error *ngIf="lo_company_zip_four.hasError('pattern')">
              Company zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Loan originator NMLS ID</mat-label>
            <input type="text" formControlName="lo_nmls" matInput placeholder="Loan originator NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
            <mat-error *ngIf="lo_nmls.hasError('required')">
              Loan originator NMLS ID is required
            </mat-error>
            <mat-error *ngIf="lo_nmls.hasError('pattern')">
              Loan originator NMLS is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Loan originator state license</mat-label>
            <input type="text" formControlName="lo_state_license" matInput placeholder="Loan originator state license" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="lo_state_license.hasError('required')">
              Loan originator state license is required
            </mat-error>
            <mat-error *ngIf="lo_state_license.hasError('pattern')">
              Loan originator state license is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company NMLS ID</mat-label>
            <input type="text" formControlName="lo_company_nmls" matInput placeholder="Company NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
            <mat-error *ngIf="lo_company_nmls.hasError('required')">
              Company NMLS ID is required
            </mat-error>
            <mat-error *ngIf="lo_company_nmls.hasError('pattern')">
              Company NMLS ID is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Company state license</mat-label>
            <input type="text" formControlName="lo_company_state_license_number" matInput placeholder="Company state license" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="lo_company_state_license_number.hasError('required')">
              Company state license is required
            </mat-error>
            <mat-error *ngIf="lo_company_state_license_number.hasError('pattern')">
              Company state license is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Email</mat-label>
            <input type="text" formControlName="lo_email" matInput placeholder="Email" maxlength="{{MAX_LEN.EMAIL}}">
            <mat-error *ngIf="lo_email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="lo_email.hasError('pattern')">
              Email is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>
