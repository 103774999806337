<app-card *ngIf="!verified&&processed" [align]="'center'" [title]="'Sign in Verification'" [alignTitle]="'center'" [logo]="true">
  <span class="material-icons text-danger">highlight_off</span>

  <p class="text-danger">
    <strong>Sign in  verification failed!</strong>
  </p>

  <p>
    Oops! Something unexpected happened.
  </p>

  <div class="additional-info">
    <a (click)="navigateToSignIn($event)" href="/pages/sign-in">Sign in again</a>
  </div>
</app-card>

<div [hidden]="!processed" class="w-100" >
  <mat-spinner [diameter]="68" style="margin:0 auto;top:250px;"></mat-spinner>
</div>
