<div style="position:relative;min-height: 100%;">
  <main>
    <app-horizontal-navbar-site></app-horizontal-navbar-site>
    <div class="">
      <app-messages></app-messages>
      <app-help></app-help>
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<app-footer style="position:relative;height: 70px;width:100%"></app-footer>

