import {Component, Injector, OnInit} from '@angular/core';
import {Dependent} from '../../../../models/dependent';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-dependents',
  templateUrl: './view-segment-dependents.component.html',
})
export class ViewSegmentDependentsComponent extends CompBaseComponent implements OnInit {
  public items: Array<Dependent>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'dependents';
    this.section = this.applicant;
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Dependent.deserializeArray(this.loan_app[this.applicant].dependents);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
