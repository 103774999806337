<mat-card class="mb-2">
  <mat-card-content>
    <!-- edit: show the grantee's info -->
    <div *ngIf="op==='Edit'" class="row">
      <div class="col-lg-2">
        <div>
          <img [src]="item.thumbnail" style="max-width: 40px;" alt="Contact Photo">
        </div>
      </div>

      <div class="col-lg-10">
        <span class="fw-bold">{{item.acl_full_name}}</span><span [hidden]="isMobile">&nbsp;({{item.acl_email}})</span>
        &nbsp;&nbsp;<span [hidden]="isMobile" class="fw-bold">Role:</span>&nbsp;
        <span [hidden]="isMobile">{{item.roleDescription}}</span>
      </div>
    </div>

    <form [formGroup]="form" novalidate>
      <!-- add: select contact -->
      <div *ngIf="op==='Add'" class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Contact</mat-label>
            <mat-select [required]="op==='Add'" formControlName="contact" (selectionChange)="onSelectChange($event)" placeholder="Select contact" required>
              <mat-option *ngFor="let contact of contacts" [value]="contact.key">{{contact.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="contact.hasError('required')">
              Contact is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="tran.isLoanOfficerTran()">
        <!-- access: always present -->
        <div class="row hover-gray">
          <div class="col-lg-2 fw-bold my-auto">
            <h5>Access</h5>
          </div>
          <div class="col-lg-4 my-auto">
            <mat-radio-group aria-label="permissions_label" formControlName="acl_permission" required>
              <mat-radio-button [checked]="checkReadNoWrite()" [value]="4">Read</mat-radio-button>
              <mat-radio-button [checked]="checkReadAndWrite()" [value]="6">Read/Update</mat-radio-button>
              <mat-error *ngIf="submitted && acl_permission.hasError('required')">
                Access is required
              </mat-error>
            </mat-radio-group>
          </div>
        </div>

        <!-- tran only -->
        <div *ngIf="resource_type==='transaction'&&acl_permission.value&&+acl_permission.value>0">
          <!-- if editing a permission -->
          <div *ngIf="op==='Edit'">
            <div *ngIf="item.isBorrower() || item.isLoanProfessional()" class="row mt-1">
              <div class="col-lg-6">
                <mat-checkbox formControlName="dir_permission">
                  Access to the Repository/Docs is permitted.
                  <span *ngIf="item.isBorrower()">
                  Borrowers are restricted to the "Unsorted Documents" folder for uploading files.
                  If a file is moved to another folder or access is revoked, borrowers will no longer be able to access it.
                  </span>
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="item.isLoanProfessional()" class="row mt-1">
              <div class="col-lg-6">
                <mat-checkbox formControlName="recursive">
                  Apply the selected access to the loan application (Read or Read/Update) and access all documents and images.
                  This choice is applicable only to resources in existence at assignment time.
                </mat-checkbox>
              </div>
            </div>
          </div>

          <!-- if adding a permission -->
          <div *ngIf="op==='Add'">
            <div *ngIf="isContact('borrower') || isContact('loan_professional')" class="row mt-1">
              <div class="col-lg-6">
                <mat-checkbox formControlName="dir_permission">
                  Access to the Repository/Docs is permitted.
                  <span *ngIf="isContact('borrower')">
                  Borrowers are restricted to the "Unsorted documents" folder for uploading files.
                  If a file is moved to another folder or access is revoked, borrowers will no longer be able to access it.
                  </span>
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="isContact('loan_professional')" class="row mt-1">
              <div class="col-lg-6">
                <mat-checkbox formControlName="recursive">
                  Apply the selected access to the loan application (Read or Read/Update) and access all documents and images.
                  This choice is applicable only to resources in existence at assignment time.
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Grant</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
