import {Component, Injector, OnInit} from '@angular/core';
import {Liability} from '../../../../models/liability';
import {LiabilityComponent} from '../liability/liability.component';

@Component({
  selector: 'app-add-liability',
  templateUrl: '../liability/edit-segment-liability.component.html',
})
export class AddLiabilityComponent extends LiabilityComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Liability({}));
    this.createForm();
    this.reload();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
