// todo: needs work
import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Invoice} from '../models/invoice';
import {CacheManager} from '../models/cache-manager';
import {CC} from '../models/cc';
import {CacheService} from './cache.service';

@Injectable()
export class InvoiceService {
  constructor(private httpService: HttpService,
              private cacheService: CacheService) {
  }

  public setCC(cc: CC): void {
    this.cacheService.setSeg('invoice', 'cc', cc);
  }

  public getCC(): CC {
    return this.cacheService.getSeg('invoice', 'cc');
  }

  public setCurrentInvoice(invoice): void {
    this.cacheService.setSeg('invoice', 'currentInvoice', invoice);
    this.cacheService.setSeg('invoice', 'invoiceId', invoice.id);
  }

  public getCurrentInvoice(userId): Promise<Invoice> {
    const currentInvoice = this.cacheService.getSeg('invoice', 'currentInvoice');
    if (currentInvoice) {
      return Promise.resolve(currentInvoice);
    }
    const invoiceId = CacheManager.getCurrentInvoiceId();
    return new Promise((resolve, reject) => {
      this.getUserInvoices(userId)
        .then((invoices) => {
          const found = invoices.find((elt) => elt.id === invoiceId);
          found ? resolve(found) : reject(null);
        });
    });
  }

  public resetCurrentInvoice(): void {
    this.cacheService.resetSeg('invoice', 'invoiceId');
  }

  public getUserInvoices(userId: number): Promise<Array<Invoice>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`invoices/${userId}/invoices`)
        .then((data) => {
          resolve(Invoice.deserializeArray(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getInvoices(userId: number): Promise<Array<Invoice>> {
    return new Promise((resolve, reject) => {
      const params = userId ? {user_id: userId} : {};
      this.httpService.get(`invoices/index`, params, false)
        .then((data) => {
          resolve(Invoice.deserializeArray(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public deleteInvoice(invoiceId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`invoices/${invoiceId}`, false)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  public getInvoice(invoiceId: number): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`invoices/${invoiceId}`, [], false)
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateInvoice(invoiceId: number, payload): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`invoices/${invoiceId}`, payload, false)
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createInvoice(payload): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`invoices`, payload, false)
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public payInvoice(invoiceId: number, payload: any): Promise<Array<Invoice>> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`invoices/pay/${invoiceId}`, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public markPaid(invoiceId: number): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`invoices/mark_paid/${invoiceId}`, {})
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public applyCoupon(invoiceId: number, coupon: string): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`invoices/apply_coupon/${invoiceId}`, {coupon: coupon})
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
