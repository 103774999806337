import {ModelBase} from './model-base';

export class Construction extends ModelBase {
  public static construction_loan_types = [
    {key: 'ConstructionOnly', value: 'Construction Only'},
    {key: 'ConstructionToPermanent', value: 'Construction-To-Permanent'},
  ];
  public static construction_closing_types = [
    {key: 'OneClosing', value: 'One Closing'},
    {key: 'Other', value: 'Other'},
    {key: 'TwoClosing', value: 'Two Closing'},
  ];
  public static land_appraisal_types = [
    {key: 'Appraised', value: 'Appraised'},
    {key: 'Original', value: 'Original'},
    {key: 'NotApplicable', value: 'Not Applicable'},
  ];

  construction_loan_type: string;
  construction_closing_type: string;
  land_original_cost: string;
  construction_improvements_cost: string;
  energy_efficient_indicator: boolean;
  contract_for_deed_indicator: boolean;
  renovation_loan_indicator: boolean;
  energy_improvements_indicator: boolean;
  land_appraised_value: string;
  land_appraisal_type: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'land_original_cost'
        || prop === 'construction_improvements_cost'
        || prop === 'land_appraised_value') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'energy_efficient_indicator'
        || prop === 'contract_for_deed_indicator'
        || prop === 'renovation_loan_indicator'
        || prop === 'energy_improvements_indicator') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getLandAppraisalType(): string {
    const found = Construction.land_appraisal_types.find((elt) => elt.key === this.land_appraisal_type);
    return found ? found['value'] : '';
  }

  getConstructionLoanType(): string {
    const found = Construction.construction_loan_types.find((elt) => elt.key === this.construction_loan_type);
    return found ? found['value'] : '';
  }

  getConstructionClosingType(): string {
    const found = Construction.construction_closing_types.find((elt) => elt.key === this.construction_closing_type);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      construction_loan_type: this.construction_loan_type,
      construction_closing_type: this.construction_closing_type,
      land_original_cost: this.toServerDecimal(this.land_original_cost),
      construction_improvements_cost: this.toServerDecimal(this.construction_improvements_cost),
      land_appraised_value: this.toServerDecimal(this.land_appraised_value),
      land_appraisal_type: this.land_appraisal_type,
      energy_efficient_indicator: ModelBase.toServerExplicitBoolean(this.energy_efficient_indicator),
      contract_for_deed_indicator: ModelBase.toServerExplicitBoolean(this.contract_for_deed_indicator),
      renovation_loan_indicator: ModelBase.toServerExplicitBoolean(this.renovation_loan_indicator),
      energy_improvements_indicator: ModelBase.toServerExplicitBoolean(this.energy_improvements_indicator),
    };
  }
}
