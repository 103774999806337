<div [hidden]="reloaded" class="w-100" >
  <mat-spinner [diameter]="68" style="margin:0 auto;"></mat-spinner>
</div>

<mat-card *ngIf="reloaded">
  <mat-card-title>Received Messages</mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom fw-bold">
      <div class="col">Loan
        <span class="text-muted"></span>
      </div>
      <div class="col">Today</div>
      <div class="col">Last 7 Days</div>
      <div class="col">Ever</div>
    </div>

    <div *ngFor="let message of messages" (click)="navigateToTranId($event,message.tran_id)"
      class="row hand-pointer pt-1 pb-1 hover-border border-bottom">
      <div class="col text-truncate">{{message.tran.name}}<span [hidden]="isMobile">&nbsp;({{message.tran_id}})</span>
      </div>
      <div class="col">{{message.counts[0]}}</div>
      <div class="col">{{message.counts[1]}}</div>
      <div class="col">{{message.counts[2]}}</div>
    </div>
  </mat-card-content>
</mat-card>
