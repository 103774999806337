<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Branding Profile'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Company name</div>
      <div class="col-lg-8">{{item.company}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Office name</div>
      <div class="col-lg-8">{{item.office}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Company URL</div>
      <div class="col-lg-8">{{item.company_url}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">About my company</div>
      <div [innerHTML]="item.about_my_company | nl2br" class="col-lg-8"></div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Phone number</div>
      <div class="col-lg-8">{{item.phone | phone}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Personal URL</div>
      <div class="col-lg-8">{{item.personal_url}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">About me</div>
      <div [innerHTML]="item.about_me | nl2br" class="col-lg-8"></div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">My hobbies</div>
      <div class="col-lg-8">{{item.hobbies}}</div>
    </div>

<!--    <div *ngIf="mode==='View'" class="border-bottom">-->
<!--      <div class="row pt-1 pb-1 hover-gray">-->
<!--        <div class="col-lg-4">-->
<!--          <span class="fw-bold">Logo</span><br>-->
<!--          The logo image height is set to 70 pixels.-->
<!--          Any width overflow is hidden.-->
<!--        </div>-->
<!--        <div class="col-lg-8 d-flex">-->
<!--          <img [src]="item.logo_url" style="border: 5px solid #555;">-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="row pt-1 pb-1">-->
<!--        <div class="col text-start my-auto">-->
<!--          <button (click)="uploadLogo($event)" class="btn btn-lg btn-primary" type="button" title="Upload logo">Upload</button>-->
<!--        </div>-->
<!--        <div class="col text-end my-auto">-->
<!--          <button [hidden]="!canDeleteLogo" (click)="deleteLogo()" class="ms-2 btn btn-lg btn-danger" title="Delete logo" type="button">Delete</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div *ngIf="mode==='Upload'" class="row mt-3">-->
<!--      <app-upload-file class="col" (cancel)="onCancel()" (uploadComplete)="onUploadComplete()" [uploadType]="'image'" [multipleFiles]="false" actionUrl="account/logo_upload" title="Upload Logo">-->
<!--      </app-upload-file>-->
<!--    </div>-->

  </mat-card-content>
</mat-card>
