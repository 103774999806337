import {Component, Injector, Input, OnInit} from '@angular/core';
import {TransmittalData} from '../../../../models/transmittal-data';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-buydown',
  templateUrl: './view-segment-buydown.component.html',
})
export class ViewSegmentBuydownComponent extends CompBaseComponent implements OnInit {
  @Input() item: TransmittalData;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.item = new TransmittalData(this.doc.recursive_attributes['transmittal_data']);
    this.mode = 'View';
  }
}
