import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {User} from '../../../models/user';
import {DataService} from '../../../services/data.service';
import {AdminService} from '../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrgAdminService} from '../../../services/org-admin.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {globals} from '../../../shared/globals/globals';
import {FormUtil} from '../../form-util';

@Component({
  selector: 'app-admin-user',
  template: '',
})
export class AdminUserComponent extends CompBaseComponent implements OnInit {
  @Input() public user: User;
  public op: string;
  @Input() userId: number;
  public adminService: AdminService;
  public orgAdminService: OrgAdminService;
  public route: ActivatedRoute;
  public router: Router;
  public readonly carriers = DataService.getCarriers();
  public readonly userRoles = User.userRoles;

  // form and form controls
  form: FormGroup;
  prefix: FormControl;
  first: FormControl;
  middle: FormControl;
  last: FormControl;
  suffix: FormControl;
  email: FormControl;
  cell_phone: FormControl;
  mobile_carrier_name: FormControl;
  // roles
  adminRole: FormControl;
  borrowerRole: FormControl;
  loanOfficerRole: FormControl;
  agentRole: FormControl;
  presenterRole: FormControl;
  auxRole: FormControl;
  processorRole: FormControl;
  reAgentRole: FormControl;
  otherRole: FormControl;
  // services
  borrowerService: FormControl;
  sauService: FormControl;
  loanService: FormControl;
  mcrUserService: FormControl;
  // Uid parameters
  status: FormControl;
  password: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.orgAdminService = this.injector.get(OrgAdminService);
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createFormControls(user) {
    this.prefix = new FormControl(user.prefix);
    this.first = new FormControl(user.first, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.middle = new FormControl(user.middle);
    this.last = new FormControl(user.last, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.suffix = new FormControl(user.suffix);
    this.cell_phone = new FormControl(user.cell_phone, Validators.pattern(globals.pattern.phone));
    this.email = new FormControl(user.email, Validators.compose([AppValidators.present, Validators.email]));
    this.adminRole = new FormControl(user.isAdmin());
    this.borrowerRole = new FormControl(user.isBorrower());
    this.loanOfficerRole = new FormControl(user.isLoanOfficer());
    this.agentRole = new FormControl(user.isAgent());
    this.presenterRole = new FormControl(user.isPresenter());
    this.auxRole = new FormControl(user.isAuxiliary());
    this.processorRole = new FormControl(user.isProcessor());
    this.reAgentRole = new FormControl(user.isRealEstateAgent());
    this.otherRole = new FormControl(user.isOther());
    this.sauService = new FormControl(user.hasUploadsService());
    this.borrowerService = new FormControl(user.hasBorrowerService());
    this.loanService = new FormControl(user.hasPOLOSService());
    this.mcrUserService = new FormControl(user.hasMCRService());
    this.mobile_carrier_name = new FormControl(user.mobile_carrier_name);
    this.status = new FormControl();
    this.password = new FormControl();
  }

  createForm() {
    this.form = new FormGroup({
      prefix: this.prefix,
      first: this.first,
      middle: this.middle,
      last: this.last,
      suffix: this.suffix,
      cell_phone: this.cell_phone,
      email: this.email,
      adminRole: this.adminRole,
      borrowerRole: this.borrowerRole,
      loanOfficerRole: this.loanOfficerRole,
      agentRole: this.agentRole,
      presenterRole: this.presenterRole,
      auxRole: this.auxRole,
      sauService: this.sauService,
      borrowerService: this.borrowerService,
      loanService: this.loanService,
      mcrUserService: this.mcrUserService,
      mobile_carrier_name: this.mobile_carrier_name,
      status: this.status,
      password: this.password,
      processorRole: this.processorRole,
      reAgentRole: this.reAgentRole,
      otherRole: this.otherRole,
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    payload['roles'] = this.getRoles();
    payload['services'] = this.getServices();
    payload.id = this.user.id;
    this.adminService.updateUser(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'User successfully updated');
        this.router.navigate(['/admin/view-user', this.user.id], {
          queryParams: {
            first: this.first.value,
            last: this.last.value
          }
        });
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update user', data);
      });
  }

  add(): void {
    const payload = Object.assign({}, this.form.value);
    payload['roles'] = this.getRoles();
    payload['services'] = this.getServices();
    payload['status'] = this.getStatus();
    console.log('Add()', globals.generateFormErrors(this.form));
    if (!this.form.valid) {
      return;
    }

    this.adminService.createAccount(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Step 1: User successfully created');
        return this.adminService.createUid(payload);
      }).then(() => {
      FormUtil.genSuccessMessage(this.sharedService, 'Step 2: Uid successfully created');
      return this.router.navigate(['/admin/user-list'], {
        queryParams: {
          first: this.first.value,
          last: this.last.value
        }
      })
    })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create user', data);
      });
  }

  getRoles(): number {
    let roles = 0;
    if (this.auxRole.value) {
      roles |= User.ROLES.AUX;
    }
    if (this.adminRole.value) {
      roles |= User.ROLES.ADMIN;
    }
    if (this.agentRole.value) {
      roles |= User.ROLES.AGENT;
    }
    if (this.loanOfficerRole.value) {
      roles |= User.ROLES.LOAN_OFFICER;
    }
    if (this.reAgentRole.value) {
      roles |= User.ROLES.REAL_ESTATE_AGENT;
    }
    if (this.otherRole.value) {
      roles |= User.ROLES.OTHER;
    }
    if (this.processorRole.value) {
      roles |= User.ROLES.PROCESSOR;
    }
    if (this.presenterRole.value) {
      roles |= User.ROLES.PRESENTER;
    }
    if (this.borrowerRole.value) {
      roles |= User.ROLES.BORROWER;
    }
    return roles;
  }

  getServices(): number {
    let services = 0;
    if (this.borrowerService.value) {
      services |= User.SERVICES.BORROWER;
    }
    if (this.mcrUserService.value) {
      services |= User.SERVICES.EZMCR;
    }
    if (this.sauService.value) {
      services |= User.SERVICES.EZUPLOADS;
    }
    if (this.loanService.value) {
      services |= User.SERVICES.POLOS;
    }
    return services;
  }

  getStatus(): number {
    return this.status.value ? User.UID_STATUS.ACTIVE : User.UID_STATUS.INACTIVE;
  }
}
