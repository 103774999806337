<mat-card class="mt-1 mb-2">
  <mat-card-content *ngIf="taskID==='0'">
    <p>
      We have broken down the organization's MCR report production into seven easy steps.
    </p>

    <b>Designate Your Organization Admin</b>
    <p>
      Assign one person from your organization to be the
      <b>Organization Admin</b>.
      The Admin performs the next steps.
    </p>

    <b>Step 1: MLO Configuration</b>
    <p>
      The purpose of this task is to ensure that your MLO Configuration includes your company's NMLS ID.
    </p>

    <b>Step 2: Create Your Organization and Users</b>
    <p>
      In this step, you create your organization (give it a name), and add loan officers to it.
    </p>

    <b>Step 3: Main Processing: Loan MCRs</b>
    <p>
      For each loan that was either in-process, closed, completed, or terminated (for whatever reason), you
      provide MCR loan data. This will make sense when you start adding these records.
    </p>

    <b>Step 4: Create the Organization MCR</b>
    <p>
      Create a new MCR record for the year and quarter you are reporting.
    </p>

    <b>Step 5: Review State MCR</b>
    <p>
      The purpose of this task is to visually review each state MCR for errors and omissions.
      The state report is displayed similarly to the way it is laid out in NMLS
    </p>

    <b>Step 6: Generate the Organization's MCR XML</b>
    <p>
      Generate a single XML for all states you need to report and download it.
    </p>

    <b>Step 7: Upload the MCR XML file to NMLS</b>
    <p>
      We provide basic instructions for uploading the MCR XML file to NMLS
    </p>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='1'">
    <p>
      The MLO configuration settings are used as default values for generating loan documents.
      For the purpose of the Mortgage Call Report, the company NMLS ID and state licenses,
      where you are licensed to originate, are required. To enter data for other loan officers,
      move on to the next task.
    </p>

    <ul>
      <li>
        <span class="fw-bold">Personal NMLS.</span> The loan officer's NMLS ID.
      </li>
      <li>
        <span class="fw-bold">State licenses.</span>
        Add entries for each state where you (admin/user) are licensed to originate.
      </li>
      <li>
        <span class="fw-bold">Company NMLS.</span> The loan officer's company's NMLS ID
        <span class="fw-bold">(required to produce MCR)</span>.
      </li>
      <li>
        <span class="fw-bold">FNMA Institution fields.</span>
        These fields are applicable to a loan officer who submits directly to Fannie Mae AUS.
        Ignore these fields, if you submit to AUS through your lender.
      </li>
      <li>
        <span class="fw-bold">Compensation fields.</span>
        These fields are used to calculate the loan officer's compensation figures.
        The calculated figures serve only as hints to the loan officer.
      </li>
      <li>
        <span class="fw-bold">ECOA address.</span>
        The applicable ECOA address in the ECOA disclosure.
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='2'">
    <div>
      Your organization, identified by your company's NMLS ID, is a container for all loan officers in your organization.
    </div>
    <h5>Types of Loan Officers</h5>
    <div>
      There are two types of loan officers.
    </div>
    <ul [ngClass]="{'is-mobile':isMobile}" class="help">
      <li>
        The first is an existing loan officer with a POLOS account.
        <b>An existing loan officer can log in to POLOS.</b>
      </li>
      <li>
        The other type is an auxiliary loan officer that you create only for the purpose of generating the Mortgage Call Report (MCR).
        <b>An auxiliary loan officer cannot log in to POLOS.</b>
      </li>
    </ul>
    <h5>Managing Existing Loan Officers</h5>
    <div>
      You can add loan officers to your organization if
    </div>
    <ul [ngClass]="{'is-mobile':isMobile}" class="help">
      <li>
        They have the same company NMLS ID as your organization's, and
      </li>
      <li>
        They are not a member of another organization (this is a rare situation).
      </li>
    </ul>
    <h5>Managing Unregistered Loan Officers</h5>
    <ul [ngClass]="{'is-mobile':isMobile}" class="help">
      <li>
        You can add auxiliary loan officers to your organization for the
        <b>sole purpose of generating your organization MCR</b>.
      </li>
      <li>
        When you add/edit an auxiliary loan officer account, add/edit the state(s) where they
        and your company are licensed to operate.
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='3'">
    <p>
      Your organization's loan MCRs are listed below.
      Each loan MCR represents a single input to the organization MCR.
    </p>
    <ul>
      <li>
        <b>To select a different period, click the header (Loan MCRs for...).</b>
      </li>
      <li>Each loan that was either started, processed, or closed in this quarter should be accounted for in the list below.</li>
      <li>The loan MCRs are sorted by the Loan Officer and Loan MCR names.</li>
      <li>To
        <b>add</b> a new loan MCR click the Add (plus) button.
      </li>
      <li>To
        <b>edit</b> any existing Loan MCR, select it, and then click the Edit MCR button.
      </li>
      <li>You can
        <b>delete</b> any loan MCR from the Edit page.
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='4'">
    <p>
      Your organization MCRs are listed below.
    </p>
    <ul>
      <li>To add a new organization MCR, click the Add (plus) button.</li>
      <li>To manage an MCR report, select it from the list.</li>
      <li>To delete an MCR, select it and then click the trash icon.</li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='5'">
    <div>
      It is a good idea to review each of your state MCRs for errors or omissions.
      To this end, we display the state MCR report similar to the way it is laid out on the NMLS website.
      Steps to review the state MCR.
    </div>
    <ul>
      <li>
        Select your MCR from the list.
      </li>
      <li>
        Click Report.
      </li>
      <li>
        Enter the state abbreviation.
      </li>
      <li>
        Click Generate Report
      </li>
      <li>
        Review the report.
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='6'">
    <div>
      Generate a single MCR XML file for all states the company is required to report.
    </div>
    <ul>
      <li>
        Select your MCR from the list.
      </li>
      <li>
        Click XML.
      </li>
      <li>
        Provide all state abbreviations.
        For instance, for Colorado and New York, enter "CO NY".
      </li>
      <li>
        Click Generate XML.
      </li>
      <li>
        When the MCR XML file is ready, a "Download XML" button will display.
      </li>
      <li>
        Click "Download XML".
      </li>
      <li>
        Save the file.
      </li>
      <li>
        WARNING: Do not edit this file.
      </li>
    </ul>
  </mat-card-content>

  <mat-card-content *ngIf="taskID==='7'">
    <h4>Upload to NMLS</h4>
    There are two ways to upload your MCR XML file, depending on whether you have already created an MCR record or not. In either case, the result is the same.
    <h5>No MCR Record</h5>
    This is the case when you have not created an MCR record for the reporting quarter:
    <ul>
      <li>
        Log in to <a href="https://mortgage.nationwidelicensingsystem.org/Pages/default.aspx" target="_blank">NMLS</a>.
      </li>
      <li>
        Click &ldquo;FILING&rdquo; and then &ldquo;Mortgage Call Reports.&rdquo; The URL is
        <a href="https://statemortgageregistry.com/FormFiling/MCR/MCRFilings.aspx" target="_blank">Mortgage Call Reports.</a>
      </li>
      <li>
        Click the &ldquo;Upload New MCR&rdquo; button and then upload your MCR XML file.
      </li>
      <li>
        Note that right after uploading the MCR XML file, NMLS is processing it.
        For this reason, it might take a few minutes until NMLS is done processing (refer to
        Troubleshooting).
      </li>
      <li>
        From the MCR Filings page, select MCR for the reporting period in edit mode.
      </li>
      <li>
        Review the MCR. Amend the &ldquo;ACNOTE&rdquo; segment, if necessary.
      </li>
      <li>
        Finally, submit the report.
      </li>
    </ul>

    <h5>MCR Record Exists</h5>
    If you have previously created an MCR record:
    <ul>
      <li>
        Log in to <a href="https://mortgage.nationwidelicensingsystem.org/Pages/default.aspx" target="_blank">NMLS</a>.
      </li>
      <li>
        Navigate to the MCR Filings page. Click &ldquo;FILING&rdquo; and then &ldquo;Mortgage Call Reports.&rdquo; The URL is
        <a href="https://statemortgageregistry.com/FormFiling/MCR/MCRFilings.aspx" target="_blank">Mortgage Call Reports.</a>
      </li>
      <li>
        Click the &ldquo;Upload RMLA&rdquo; button.
      </li>
      <li>
        Upload your MCR XML file.
      </li>
      <li>
        Note that right after uploading the MCR XML file, NMLS is processing the file.
        It might take a few minutes until NMLS is done processing (refer to
        Troubleshooting).
      </li>
      <li>
        Select the MCR record for the reporting period in edit mode.
      </li>
      <li>
        Review the MCR. Amend the &ldquo;ACNOTE&rdquo; segment, if necessary.
      </li>
      <li>
        Finally, submit the report.
      </li>
    </ul>

    <h5>Troubleshooting</h5>
    <p>
      The user experience on the NMLS website could be challenging.
      For instance, after you have uploaded the MCR XML file, nothing happens, whereas NMLS is, in fact, processing the file.
      Be patient, and try refreshing the page every few minutes.
      If you are having issues with NMLS, feel free to contact us. We will be glad to help.
    </p>
  </mat-card-content>
</mat-card>
