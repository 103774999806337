import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../comp-base.component';
import {User} from '../../models/user';

@Component({
  selector: 'app-home',
  template: '',
})
export class HomeComponent extends CompBaseComponent implements OnInit {
  public pageTitle = '';
  public accountOverview: any;
  public activeTransactionsCount: number;
  public isLoanProfessional: boolean;
  public isLoanOfficer: boolean;
  public isBorrower: boolean;
  public isREAgent: boolean;
  public isAdmin: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    // the use of isLoanOfficer is only for access control and branding
    this.isLoanOfficer = this.userService.getUserSync().isLoanOfficer();
    this.isLoanProfessional = this.userService.getUserSync().isLoanProfessional();
    this.isBorrower = this.userService.getUserSync().isBorrower();
    this.isREAgent = this.userService.getUserSync().isRealEstateAgent();
    this.isAdmin = this.userService.getUserSync().isAdmin();
  }

  reloadHome(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.tranService.getTrans()
        .then((trans) => {
          if (trans) {
            this.activeTransactionsCount = trans.reduce((mem, tran) => {
              if (tran.status === 0) {
                mem++;
              }
              return mem;
            }, 0);
          } else {
            this.activeTransactionsCount = 0;
          }
          return this.userService.getAccountOverview();
        })
        .then((data) => {
          const user: User = this.userService.getUserSync();
          data.roleDescription = user.getRolesString();
          data.servicesDescription = user.getServicesString();
          this.accountOverview = data;
          resolve(true);
        })
        .catch((data) => {
          console.log('FAILED: home component reload');
          reject(data);
        });
    });
  }
}
