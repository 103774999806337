import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HomeService} from '../../../services/home.service';
import {globals} from '../../../shared/globals/globals';
import {AppValidators} from '../../../shared/validators/app-validators.validator';

@Component({
  selector: 'app-page-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class PageUnsubscribeComponent implements OnInit {
  public queryParams: any;
  public unsubscribeEmail: string;
  public verified = false;
  public error: string;
  form: FormGroup;
  email: FormControl;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private homeService: HomeService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = Object.assign({}, params);
      if (params && params['email']) {
        this.unsubscribeEmail = params['email'];
      }
      this.createFormControls();
      this.createForm();
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsTouched();
      });
    });
  }

  createFormControls() {
    this.email = new FormControl(this.unsubscribeEmail, Validators.compose([Validators.pattern(globals.pattern.email), AppValidators.present]));
  }

  createForm() {
    this.form = new FormGroup({
      email: this.email
    });
  }

  unsubscribe(event: any): void {
    this.verified = false;
    this.error = null;
    event.preventDefault();
    if (!this.form.valid) {
      return;
    }

    const payload = {
      email: this.email.value,
      message: 'N/A'
    };
    this.homeService.unsubscribe(payload)
      .then(() => {
        this.verified = true;
      })
      .catch((data) => {
        this.error = data.errors.join('. ') + '!';
        this.verified = false;
      });
  }

  cancel(event: any): void {
    this.navigateToWWW(event);
  }

  navigateToWWW(event: any): void {
    event.preventDefault();
    window.location.href = 'https://www.prudentco.com';
  }
}
