import {ModelBase} from './model-base';
import {Folder} from './folder';

export class FolderGroup extends ModelBase {
  description: string;
  display: string;
  id: string;
  name: string;
  selected: boolean;
  stock: boolean;
  items: Array<Folder>;
  // generated properties
  isLoanApp: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'items') {
        this.items = object['items'].map((item) => new Folder(item));
      } else {
        this[prop] = object[prop];
      }
    }
    this.selected = this.items.some((elt) => elt.selected);
    this.display = this.name;
    this.isLoanApp = this.id === 'LA';
  }

  static deserializeArray(arr: Array<any>): Array<FolderGroup> {
    const result = [];
    for (const obj of arr) {
      result.push(new FolderGroup(obj));
    }

    return result;
  }
}
