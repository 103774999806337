import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fnmaBooleanToString'
})
export class FnmaBooleanToStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value && value === 'Y' ? 'Yes' : 'No';
  }
}
