import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-find-organizations',
  templateUrl: './find-organizations.component.html',
})
export class FindOrganizationsComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Find Organizations';

  // form and form controls
  public form: FormGroup;
  public name: FormControl;
  public organization_id: FormControl;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl('');
    this.organization_id = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      organization_id: this.organization_id
    });
  }

  findOrganizations(): void {
    this.router.navigate(['/admin/organization-list'], {queryParams: this.form.value});
  }

  reset(): void {
    this.form.reset();
  }
}
