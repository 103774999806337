<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="header" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Current employment information is of utmost importance to the loan application. Fill it out accurately and completely.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="item.na" class="row">
      <div class="col">
        <span class="fw-bold"><i class="fa fa-check"></i>&nbsp;Does not apply</span>
      </div>
    </div>

    <div *ngIf="!item.na">
      <div *ngIf="!item.na">
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Employment status</div>
          <div class="col-lg-6">{{item.employment_status}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Employer name</div>
          <div class="col-lg-6">{{item.employer_name}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Address</div>
          <div class="col-lg-6">{{item.getFullAddress()}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Number of years in line of work</div>
          <div class="col-lg-6">{{item.profession_years_no}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Number of months</div>
          <div class="col-lg-6">{{item.profession_months_no}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Position</div>
          <div class="col-lg-6">{{item.position}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Start date</div>
          <div class="col-lg-6">{{item.start_date | date: 'MM/dd/yyyy'}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Are you the business owner or self-employed?</div>
          <div class="col-lg-6" *ngIf="item.self_employed!==null">{{item.self_employed | booleanToString}}</div>
          <div class="col-lg-6" *ngIf="item.self_employed===null">(no response)</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.self_employed===true">
          <div class="col-lg-6 fw-bold" *ngIf="item.ownership===true">I have an ownership share of 25% or more</div>
          <div class="col-lg-6 fw-bold" *ngIf="item.ownership===false">I have an ownership share of less than 25%</div>
          <div class="col-lg-6 fw-bold" *ngIf="item.ownership===null">I have an ownership share</div>
          <div class="col-lg-6" *ngIf="item.ownership===null">(no response)</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Are you employed by a family member, property seller, real estate agent, or other party to the transaction?</div>
          <div class="col-lg-6" *ngIf="item.employed!==null">{{item.employed | booleanToString}}</div>
          <div class="col-lg-6" *ngIf="item.employed===null">(no response)</div>
        </div>

        <div class="row pt-1 pb-3 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Monthly income (or loss)</div>
          <div class="col-lg-6">{{item.income_or_loss | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Business phone</div>
          <div class="col-lg-6">{{item.business_phone | phone}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Seasonal income?</div>
          <div class="col-lg-6" *ngIf="item.seasonal_income_indicator!==null">{{item.seasonal_income_indicator | booleanToString}}</div>
          <div class="col-lg-6" *ngIf="item.seasonal_income_indicator===null">(no response)</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Foreign income?</div>
          <div class="col-lg-6" *ngIf="item.foreign_income_indicator!==null">{{item.foreign_income_indicator | booleanToString}}</div>
          <div class="col-lg-6" *ngIf="item.foreign_income_indicator===null">(no response)</div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
