import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Trace} from '../../../models/trace';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
})
export class ViewTaskComponent extends CompBaseComponent implements OnInit {
  @Input() public currentState: string;
  @Input() public trace: Trace;
  @Input() public title: string;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }
}
