<div *ngIf="canUpdateTransaction" class="row border-bottom">
  <div class="col mb-2">
    <button (click)="edit()" class="btn btn-secondary"><i class="fa fa-edit"></i>&nbsp;Edit Loan Application Properties</button>
  </div>
</div>

<div class="row border-bottom">
  <div class="col-lg-4 pt-1 pb-1 fw-bold">
    Name
  </div>

  <div class="col-lg-8">
    <div [ngClass]="{'pr-blue-bold': loanApp.isDefault()}" class="d-inline mt-0 mb-0">{{loanApp.name}}</div>
    <div [hidden]="!loanApp.isDefault()" class="d-inline text-muted">&nbsp;(Default)</div>
  </div>
</div>

<div class="row border-bottom pt-1 pb-1">
  <div class="col-lg-4 fw-bold">
    Modified
  </div>
  <div class="col-lg-8">
    <span>{{loanApp.updated_at | date:'medium'}}</span>
  </div>
</div>

<div class="row pt-3 pb-1">
  <div class="col-lg-4 hand-pointer " *ngIf="loanApp.isDefault()">
    <button (click)="navigateToLoanApp()" class="btn btn-lg btn-primary"><i class="fa fa-edit"></i>&nbsp;Edit Loan Application</button>
  </div>
</div>

<iframe *ngIf="downloading" [src]="urlSafe" class="d-none"></iframe>
