<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row pb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Dependent age</mat-label>
            <input appNumberOnly formControlName="age" matInput placeholder="Dependent age" maxlength="3" appAutoFocus required>
            <mat-error *ngIf="age.hasError('required')">
              Age is required
            </mat-error>
            <mat-error *ngIf="age.hasError('min')">
              Age must be a positive integer
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
