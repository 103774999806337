import {Component, Injector, OnInit} from '@angular/core';
import {SharedService} from '../shared-service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line
  selector: 'site-layout',
  templateUrl: './site.component.html',
  providers: [SharedService]
})
export class SiteLayoutComponent implements OnInit {
  messages = [];

  constructor(private injector: Injector, public dialog: MatDialog) {
  }

  ngOnInit() {
  }
}
