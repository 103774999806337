import {Component, Injector, OnInit} from '@angular/core';
import {Permission} from '../../../models/permission';
import {PermissionComponent} from '../permission/permission.component';
import {ModelBase} from '../../../models/model-base';

@Component({
  selector: 'app-add-permission',
  templateUrl: '../permission/edit-segment-permission.component.html',
})
export class AddPermissionComponent extends PermissionComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    // setting the default permission to the minimum Read.
    this.createFormControls(new Permission({acl_permission: ModelBase.PERMISSION.R}));
    this.createForm();
  }
}
