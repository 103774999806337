<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Invoice'" [help]="false"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="op==='Add'" class="row pt-1 pb-3 hover-gray">
      <div class="col-lg-6 fw-bold">{{fullName}}</div>
    </div>

    <div *ngIf="op==='Edit'">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Invoice ID</div>
        <div class="col-lg-4">{{item.id}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">User</div>
        <div class="col-lg-4">{{item.full_name}} ({{item.user_id}})</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Created at</div>
        <div class="col-lg-4">{{item.created_at | date:'MM/dd/yyyy'}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Updated at</div>
        <div class="col-lg-4">{{item.updated_at | date:'MM/dd/yyyy'}}</div>
      </div>
    </div>

    <form [formGroup]="form" novalidate class="mt-3">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="user_id" matInput placeholder="User ID" required>
            <mat-error *ngIf="user_id.hasError('required')">
              User ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="amount" matInput placeholder="Amount" required>
            <mat-error *ngIf="amount.hasError('required')">
              Amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="status" placeholder="Invoice status">
              <mat-option *ngFor="let st of invoice_statuses" [value]="st.key">{{st.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button *ngIf="op==='Add'" (click)="create()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Create</button>
          <button *ngIf="op==='Edit'" (click)="update()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

