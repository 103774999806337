<mat-card [hidden]="!displayFolder" class="mb-2" (mouseenter)="onEnter($event)" (mouseleave)="onLeave($event)">
  <mat-card-title class="doc-folder">
    <div (click)="edit()" [ngClass]="{'hand-pointer':canUpdateTransaction}" title="Click to edit" class="pr-font-large">
      {{folder.display | slice:0:maxDispChars}}&nbsp;<span [hidden]="!folder.description.length" style="font-size: 14px;">({{folder.description | slice:0:maxDescChars}})</span>
    </div>
  </mat-card-title>

  <mat-card-content>
    <!-- folder status -->
    <div class="row hover-gray pb-1 pt-2" style="border-bottom: 1px dotted black;">
      <div class="col-lg-4 d-flex my-auto" *ngIf="folder.id!=='US'">
        <div class="me-2">
          <span class="fw-bold">Status</span>
        </div>
        <div class="dropdown">
          <div class="status-icon" data-toggle="dropdown">
            <img *ngIf="folder.status===1" alt="Status: Outstanding" class="hand-pointer" src='assets/img/circle-red.png' title="Status: Outstanding">
            <img *ngIf="folder.status===3" alt="Status: OK" class="hand-pointer img-fluid" src='assets/img/circle-green.png' title="Status: OK">
            <img *ngIf="folder.status===4" alt="Status: Cancelled" class="hand-pointer img-fluid" src='assets/img/circle-cancel.png' title="Status: Cancelled">
            <span class="text-muted" *ngIf="folder.status===1">&nbsp;(Outstanding)</span>
            <span class="text-muted" *ngIf="folder.status===3">&nbsp;(OK)</span>
            <span class="text-muted" *ngIf="folder.status===4">&nbsp;(Cancelled)</span>
          </div>
          <ul *ngIf="canUpdateTransaction" class="dropdown-menu" role="menu">
            <li class="dropdown-header" role="presentation">Status&nbsp;<i class="fa fa-caret-down"></i>
            </li>
            <li class="hand-pointer" role="presentation">
              <a (click)="updateStatus($event,1)"><i><img alt="Status: Outstanding" src='assets/img/circle-red.png' title="Status: Outstanding"></i>&nbsp;Outstanding</a>
            </li>
            <li class="hand-pointer" role="presentation">
              <a (click)="updateStatus($event,3)"><i><img alt="Status: OK" src='assets/img/circle-green.png' title="Status: OK"></i>&nbsp;OK</a>
            </li>
            <li class="hand-pointer" role="presentation">
              <a (click)="updateStatus($event,4)"><i><img alt="Status: Cancelled" src='assets/img/circle-cancel.png' title="Status: Cancelled"></i>&nbsp;Cancelled</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 my-auto" *ngIf="folder.id==='US'">
        You can upload documents to this folder.
      </div>

      <div class="col-lg-6 text-end my-auto">
        <!-- Paste icon -->
        <div *ngIf="(folder.status === 1)&&canUpload">
          <div [ngStyle]="{'visibility':isCutAndPaste ? 'visible' : 'hidden'}" class="cut-and-paste hand-pointer">
            <i (click)="paste($event)" class="fa fa-paste" title="Paste"></i>
          </div>
        </div>
      </div>

      <div class="col-lg-2 my-auto text-end">
        <div *ngIf="(folder.status === 1)&&canUpload" class="text-end my-auto ms-5">
          <button (click)="upload()" class="btn btn-outline-primary btn-sm pr-button-border">
            <i class="fa fa-upload"></i>Upload
          </button>
        </div>
      </div>
    </div>

    <!-- file upload -->
    <div *ngIf="mode==='Upload'" class="mt-3">
      <app-upload-file (cancel)="cancel()" (uploadComplete)="onUploadComplete()" [actionUrl]="actionUrl" [title]="'Upload a PDF or Image to Folder'" [uploadType]="'all'" [multipleFiles]="true"></app-upload-file>
    </div>

    <!-- folder documents -->
    <div *ngFor="let doc of docs">
      <app-document (eventEmitted)="onEvent($event)" *ngIf="doc.canReadDocument && doc.isFolderDoc()" [document]="doc" [folderId]="folder.id"></app-document>
    </div>
  </mat-card-content>
</mat-card>
