<div *ngIf="reloaded">
  <div *ngIf="mode==='Add'">
    <app-box [close]="true" [type]="'danger'" [fadeOut]="false">
      <b>Your account has no service subscription.</b>
      You must add a service subscription to continue.
    </app-box>

    <app-add-order (eventEmitted)="onEvent($event)"></app-add-order>
  </div>

  <div *ngIf="mode==='Past-Due'">
    <app-box [close]="true" [type]="'danger'" [fadeOut]="false">
      <b>The account is past due.</b>
      You need to pay your outstanding
      <a class="pr-blue-bold" routerLink="/account/invoices">account/Invoices</a>.
    </app-box>
  </div>

  <app-finalize-order *ngIf="mode==='Modify'" (eventEmitted)="onEvent($event)"></app-finalize-order>

  <table *ngIf="mode==='View'" class="table mb-0 table-hover">
    <thead>
    <tr>
      <th>ID</th>
      <th>Amount</th>
      <th>Status</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let order of orders">
      <td>{{order.id}}</td>
      <td>{{order.amount | currency}}</td>
      <td>{{order.getStatus()}}</td>
      <td *ngIf="order.order_line_items.length === 0">
        Error: No line items for this order
      </td>
      <td *ngIf="order.order_line_items.length > 0">
        <div *ngFor="let oli of order.order_line_items">
          <table class="table table-hover" style="border-bottom: black 1px solid">
            <tr>
              <td style="border: none; padding: 0 10px;">Order type:</td>
              <td style="border: none; padding: 0 10px;">{{oli.getType()}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Status:</td>
              <td style="border: none; padding: 0 10px;">{{oli.getStatus()}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Subscription term:</td>
              <td style="border: none; padding: 0 10px;">{{oli.getSubscriptionTerm()}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Services:</td>
              <td style="border: none; padding: 0 10px;">{{oli.getServices()}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Amount:</td>
              <td style="border: none; padding: 0 10px;">{{oli.amount | currency}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Starts on:</td>
              <td style="border: none; padding: 0 10px;">{{oli.starts_on}}</td>
            </tr>
            <tr>
              <td style="border: none; padding: 0 10px;">Expires on:</td>
              <td style="border: none; padding: 0 10px;">{{oli.expires_on}}</td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
