import {Component, Injector, OnInit} from '@angular/core';
import {SegmentWorkflowComponent} from '../segment-workflow/segment-workflow.component';
import {ModelBase} from '../../../models/model-base';

@Component({
  selector: 'app-edit-segment-workflow',
  templateUrl: './edit-segment-workflow.component.html',
})
export class EditSegmentWorkflowComponent extends SegmentWorkflowComponent implements OnInit {
  public timeFrames = ModelBase.timeFrames;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return super.ngOnInit()
        .then(() => {
          this.createFormControls(this.tran);
          this.createForm();
          this.reloaded = true;
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
