import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormUtil} from '../../../form-util';
import {Help} from '../../../../models/help';
import {globals} from '../../../../shared/globals/globals';
import {OrgUserComponent} from '../org-user.component';
import {Organization} from '../../../../models/organization';

@Component({
  selector: 'app-edit-segment-org-user',
  templateUrl: './edit-segment-org-user.component.html',
})
export class EditSegmentOrgUserComponent extends OrgUserComponent implements OnInit {
  pageTitle = 'Edit Loan Officer';

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'EditUser';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountOrg
      });
    }, 0);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls(this.user);
    this.createForm();
    this.reloaded = true;
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    this.states.setValue(this.states.value.toUpperCase());
    const statesCured = this.states.value.toUpperCase().replace(/,/g, ' ').trim().split(/\s+/);
    const errorMessages = [];
    statesCured.forEach((state) => {
      if (!globals.pattern.state.test(state)) {
        const errorMessage = `State ${state} is invalid`;
        errorMessages.push(errorMessage);
      }
    });
    if (errorMessages.length > 0) {
      FormUtil.genErrorMessage(this.sharedService, errorMessages.join('. '), null);
      return;
    }

    const payload = Object.assign({}, this.form.value);
    payload.id = this.user.id;
    payload.organization_id = this.item.id;
    payload.organization_role = this.orgRole.value;
    this.getService().updateUser(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization User successfully updated');
        return this.userService.getDefaultOrganization(true);
      })
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update user', data);
      });
  }

  remove(): void {
    const payload = {
      nmls: this.user.nmls,
      organization_id: this.item.id
    };
    this.getService().removeUser(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Organization User successfully removed');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to remove user', data);
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
