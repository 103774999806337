<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Organization Loans
  </mat-card-title>
  <mat-card-content>
    <h5>Organization Loans</h5>

    <p>
      All users with the same company NMLS ID can be added to the same organization.
      The organization loans table displays all active loans of all organization users.
    </p>
  </mat-card-content>
</mat-card>
