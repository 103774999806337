import {Component, Injector, OnInit} from '@angular/core';
import {AssetComponent} from '../asset/asset.component';

@Component({
  selector: 'app-edit-segment-asset',
  templateUrl: '../asset/edit-segment-asset.component.html',
})
export class EditSegmentAssetComponent extends AssetComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
    this.setPartyIds();
    this.setParties();
  }
}
