import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Party} from '../../../../models/party';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-party',
  template: '',
})
export class PartyComponent extends CompBaseComponent implements OnInit {
  @Input() item: Party;
  public vesting_types = Party.vesting_types;
  public trust_types = Party.trust_types;
  public roles = Party.roles;

  // form fields
  public form: FormGroup;
  public role: FormControl;
  public full_name: FormControl;
  public vesting_type: FormControl;
  public other_description: FormControl;
  public trust_type: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'parties';
    this.section = 'lo';
  }

  createFormControls(item: Party): void {
    this.role = new FormControl(item.role, AppValidators.present);
    this.vesting_type = new FormControl(item.vesting_type, AppValidators.present);
    this.full_name = new FormControl(item.full_name, AppValidators.present);
    this.other_description = new FormControl(item.other_description);
    this.trust_type = new FormControl(item.trust_type);
  }

  createForm(): void {
    this.form = new FormGroup({
      role: this.role,
      vesting_type: this.vesting_type,
      full_name: this.full_name,
      other_description: this.other_description,
      trust_type: this.trust_type,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (this.role.value === 'OTHER') {
      this.vesting_type.setValue('Other');
    }
    if (!this.form.valid) {
      return;
    }

    const updated = new Party(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Party(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: null};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
