<mat-card class="mt-2 mb-1">
  <mat-card-title>Appraisal</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div *ngIf="isPurchaseLoan" class="row hover-gray mb-2">
        <div class="col-lg-4 my-auto">
          Seller offered below market financing?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Seller below market financing" formControlName="seller_below_market_financing">
            <mat-radio-button [checked]="seller_below_market_financing.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="seller_below_market_financing.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="isRefinanceLoan" class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Existing mortgage owner</mat-label>
            <mat-select [required]="isRefinanceLoan" formControlName="existing_mortgage_owner_code_ex" placeholder="Select existing mortgage owner">
              <mat-option *ngFor="let existing_mortgage_owner_code of existing_mortgage_owner_codes_ex"
                [value]="existing_mortgage_owner_code.key">{{existing_mortgage_owner_code.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="existing_mortgage_owner_code_ex.hasError('required')">
              Existing mortgage owner is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Appraisal type</mat-label>
            <mat-select formControlName="appraisal_type_ex" placeholder="Select appraisal type" required>
              <mat-option *ngFor="let appraisal_type of appraisal_types_ex"
                [value]="appraisal_type.key">{{appraisal_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appraisal_type_ex.hasError('required')">
              Appraisal type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property appraised value</mat-label>
            <input appDecimalOnly formControlName="property_appraised_value" matInput placeholder="Property appraised value" type="text" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="property_appraised_value.hasError('required')">
              Property appraised value is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Appraisal ID</mat-label>
            <input type="text" formControlName="appraisal_identifier" matInput placeholder="Appraisal ID" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="appraisal_identifier.hasError('required')">
              Appraisal ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
