<!-- 2019-09-05 last reviewed this help -->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Workflow</mat-card-title>
  <mat-card-content>

    <h5>Workflow Overview</h5>

    <p>
      The requirements for our workflow features came from loan officers through many iterations with the company's
      engineering team.
      The goals were to 1) aid the loan officer comply with federal and state rules (so-called "compliance"),
      2) communicate the current loan status to loan contacts, and
      3) notify the loan contacts of "to do" items.
      <em>The Timeline feature is a visual representation of the workflow.</em>
    </p>

    <p>
      The task completion date is updated automatically when the loan officer moves the workflow to the next task.
      The loan officer should always ensure that the workflow current task reflects what they are presently working on.
      When the current task has completed, the loan officer should move the workflow to the next task.
      The workflow starts when the loan officer starts working on the loan and is completed when the loan transaction
      ends.
    </p>

    <h5>Compliance Events Integrated with Workflow</h5>

    <p>
      Our integration of compliance events with workflow is a breakthrough in loan origination.
      The Update Workflow function displays compliance (required by law) and optional events that are due by the end of
      the current task.
      Since some or all of the optional events are commonly done by loan officers, we include them with the compliance
      events for the benefit
      of the loan officer.
      The idea of the integrated workflow would become crystal clear when you go through the motions.
    </p>

    <p>
      The workflow cannot be moved to the next task unless the loan officer updates the "Occurred on" field of the
      required compliance events.
      If for whatever reason, the loan officer wishes to force workflow update (although the compliance events were not
      performed),
      they can accomplish it by checking the "Force update" checkbox.
      We do not recommend forcing workflow updates although you may do so at your own discretion.
    </p>

    <p>
      The time frames of compliance events are dynamically computed with each update to any compliance event.
      The loan purpose may also affect the funding projected time frame by adding three rescission days to the closing
      disclosure date.
    </p>

    <h5>Starting the Workflow</h5>

    <p>
      The loan workflow is created with the loan but is not automatically started! The reason is simple.
      Initially, your borrower is only a lead. Maybe they will become a customer, maybe not. When you are ready to work
      on
      the loan in earnest, you should start the workflow.
    </p>

    <strong>To start the workflow:</strong>

    <ul>
      <li>Navigate to Update Workflow tab under Workflow.</li>
      <li>Click the edit (pencil) icon next to "WORKFLOW &#64; New".</li>
      <li>Click the "Start workflow" radio button.</li>
      <li>Click the Update button.</li>
    </ul>

    <h5>Updating the Workflow</h5>

    <p>
      You should update the workflow, by moving it to the next task, at the termination of the current task.
    </p>
    <ul>
      <li>Click the edit (pencil) icon next to the caption "WORKFLOW &#64; [task name]".</li>
      <li>Select the next task from the radio button list.</li>
      <li>Select the contacts you wish to be notified of the change (optional).</li>
      <li>Click Update.</li>
    </ul>

    <p>
      Although the procedure for updating workflow is straightforward, there are gotchas you must be aware of if you are
      new to the workflow concept.
    </p>

    <ul>
      <li>Not all task transitions are possible.</li>
    </ul>

  </mat-card-content>
</mat-card>
