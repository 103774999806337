import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-select-document',
  templateUrl: './select-document.component.html',
})
export class SelectDocumentComponent extends CompBaseComponent implements OnInit {
  @Input() formType: Array<string>;
  @Input() formTitle: string;
  @Input() formID: number;
  @Output() formSelectionChange = new EventEmitter();

  // form and form controls
  public form: FormGroup;
  public formSelected: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  createFormControls(): void {
    this.formSelected = new FormControl(false);
  }

  createForm(): void {
    this.form = new FormGroup({
      formSelected: this.formSelected,
    });
  }

  onSelectionChange(): void {
    this.formSelectionChange.emit({formType: this.formType, formSelected: !!this.formSelected.value, formID: this.formID})
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    this.onSelectionChange();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
