import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {SiteComponent} from '../../site.component';

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
})
export class OurStoryComponent extends SiteComponent implements OnInit, OnDestroy {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = 'Our Story';
    super.ngOnInit();
    this.reload();
  }

  ngOnDestroy(): void {
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.homeHighlights
      });
      this.reloaded = true;
    }, 0);
  }
}
