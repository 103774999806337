import {Component, Injector, OnInit} from '@angular/core';
import {ProductCharacteristics} from '../../../../models/product-characteristics';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-product-characteristics',
  templateUrl: './view-product-characteristics.component.html',
})
export class ViewProductCharacteristicsComponent extends CompBaseComponent implements OnInit {
  public item: ProductCharacteristics;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new ProductCharacteristics(this.doc.recursive_attributes['product_characteristics']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
