import {ModelBase} from './model-base';

export class Borrower extends ModelBase {
  public static martial_statuses_ex: Array<any> = [
    {key: 'Married', value: 'Married'},
    {key: 'Separated', value: 'Separated'},
    {key: 'Unmarried', value: 'Unmarried'},
  ];

  public static domestic_relationships: Array<any> = [
    {key: 'CivilUnion', value: 'Civil Union'},
    {key: 'DomesticPartnership', value: 'Domestic Partnership'},
    {key: 'RegisteredReciprocalBeneficiaryRelationship', value: 'Registered Reciprocal Beneficiary Relationship'},
    {key: 'Other', value: 'Other'},
  ];

  public static language_codes = [
    {key: 'ASL', value: 'ASL'},
    {key: 'ARA', value: 'Arabic'},
    {key: 'CAM', value: 'Cambodian'},
    {key: 'CAN', value: 'Cantonese'},
    {key: 'CHI', value: 'Chinese Mandarin'},
    {key: 'CRE', value: 'Creole'},
    {key: 'CZE', value: 'Czech'},
    {key: 'ENG', value: 'English'},
    {key: 'FAR', value: 'Farsi'},
    {key: 'FRE', value: 'French'},
    {key: 'GER', value: 'German'},
    {key: 'HIN', value: 'Hindi'},
    {key: 'HMO', value: 'Hmong'},
    {key: 'IND', value: 'Indonesian'},
    {key: 'ITA', value: 'Italian'},
    {key: 'KOR', value: 'Korean'},
    {key: 'OTH', value: 'Other'},
    {key: 'POL', value: 'Polish'},
    {key: 'POR', value: 'Portuguese'},
    {key: 'RUS', value: 'Russian'},
    {key: 'SPA', value: 'Spanish'},
    {key: 'SWA', value: 'Swahili'},
    {key: 'TUR', value: 'Turkish'},
    {key: 'UKR', value: 'Ukrainian'},
    {key: 'VIE', value: 'Vietnamese'}
  ];

  id: number;
  role: string; // borrower_1 or borrower_2
  email: string;
  first: string;
  middle: string;
  last: string;
  suffix: string;
  home_phone: string;
  cell_phone: string;
  fullName: string;
  dependents_no: string; // read-only property
  dob: Date;
  ssn: string;
  years_school: string;
  work_phone: string;
  work_phone_extension: string;
  community_state: boolean;
  marital_status_ex: string;
  domestic_relationship_indicator: boolean;
  domestic_relationship_type: string;
  domestic_relationship_other_description: string;
  domestic_relationship_state_code: string;
  caivrs_identifier: string;
  language_code: string;
  other_language: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'dob') {
        this[prop] = this.getDateProp(prop, object);
      } else if (prop === 'community_state') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'domestic_relationship_indicator') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'years_school') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'dependents_no') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'ssn') {
        this[prop] = this.getSSNProp(prop, object);
      } else if (prop === 'home_phone') {
        this[prop] = this.getPhoneProp(prop, object);
      } else if (prop === 'cell_phone') {
        this[prop] = this.getPhoneProp(prop, object);
      } else if (prop === 'work_phone') {
        this[prop] = this.getPhoneProp(prop, object);
      } else if (prop === 'work_phone_extension') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Borrower> {
    const result = [];
    for (const obj of arr) {
      result.push(new Borrower(obj));
    }

    return result;
  }

  getLanguage(): string {
    const found = Borrower.language_codes.find((elt) => elt.key === this.language_code);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      role: this.role,
      email: this.email,
      first: this.first,
      middle: this.middle,
      last: this.last,
      suffix: this.suffix,
      home_phone: this.toServerPhone(this.home_phone),
      cell_phone: this.toServerPhone(this.cell_phone),
      fullName: this.fullName,
      dob: this.toServerDate(this.dob),
      ssn: this.toServerSSN(this.ssn),
      marital_status_ex: this.marital_status_ex,
      domestic_relationship_indicator: ModelBase.toServerExplicitBoolean(this.domestic_relationship_indicator),
      domestic_relationship_type: this.domestic_relationship_type,
      domestic_relationship_other_description: this.domestic_relationship_other_description,
      domestic_relationship_state_code: this.domestic_relationship_state_code,
      years_school: this.toNumber(this.years_school),
      work_phone: this.toServerPhone(this.work_phone),
      work_phone_extension: this.toServerPhone(this.work_phone_extension),
      community_state: ModelBase.toServerExplicitBoolean(this.community_state),
      caivrs_identifier: this.caivrs_identifier,
      language_code: this.language_code,
      other_language: this.other_language,
    };
  }
}
