<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>Populate Liabilities from Credit Report</mat-card-title>

  <mat-card-content>
    <div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Prepared for</div>
        <div class="col-lg-6">{{preparedFor.value}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Credit report name</div>
        <div class="col-lg-6">{{doc.name}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Description</div>
        <div class="col-lg-6">{{doc.description}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Created at</div>
        <div class="col-lg-6">{{doc.created_at | date: 'medium'}}</div>
      </div>
    </div>

    <form [formGroup]="form" novalidate>
      <div class="row mt-3">
        <div class="col">
          <button (click)="generate($event)" [disabled]="!form.valid" class="btn btn-lg btn-primary">Generate Credit Report (PDF)</button>
          <button (click)="populate($event)" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Populate Loan Application Liabilities</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div >
  <mat-spinner [diameter]="68" [hidden]="reloaded" class="ms-5 mb-3"></mat-spinner>
</div>
