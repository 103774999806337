<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Emails</mat-card-title>

  <mat-card-content>
    <div>
      <h5>Invite User</h5>

      <p>
        Invite User sends an invitation email to an unregistered user with instructions how to create a new account at PrudentCO.
      </p>

      <strong>To send an invite user email:</strong>

      <ul>
        <li>Provide first and last names and email address of the new user.</li>
        <li>Click Send.</li>
      </ul>

      <h5>Orientation Email</h5>

      <p>
        Orientation Email sends an email to a new borrower with instructions how to get started with POLOS.
      </p>

      <strong>To send a new email:</strong>

      <ul>
        <li>Select recipients.</li>
        <li>Click Send.</li>
      </ul>
    </div>
  </mat-card-content>
</mat-card>
