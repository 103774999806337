import {Component, Injector, OnInit} from '@angular/core';
import {Support} from '../../../../models/support';
import {SupportComponent} from '../support/support.component';

@Component({
  selector: 'app-add-support',
  templateUrl: '../support/edit-segment-support.component.html',
})
export class AddSupportComponent extends SupportComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Support({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
