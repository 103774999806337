import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {FormUtil} from "../../form-util";
import {Router} from "@angular/router";

@Component({
  selector: 'app-generate-document-base',
  template: '',
})
export class GenerateDocumentBaseComponent extends CompBaseComponent implements OnInit {
  @Input() formType: string;
  @Input() folderId: string;
  public txSummary: any;
  public generating: boolean;
  public savedDoc: any;
  public router: Router;

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();

    this.tran = this.tranService.getCurrentTranSync();
    this.txSummary = this.tranService.getTransactionSummary({
      transaction: this.tran,
      mlo: this.userService.getUserSync()
    });
  }

  onEvent(event: any): void {
    // do hard reload only if necessary
    if (event.type === 'Reload') {
      const force = typeof (event.force) === 'undefined' ? false : event.force;
      if (force) {
        this.eventEmitted.emit(event);
      } else {
        this.reload(false);
      }
      return;
    }
    super.onEvent(event);

    // todo: need to check whether it is okay to remove next line
    // this.eventEmitted.emit(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  async saveForm(formData: any): Promise<boolean> {
    formData['state'] = this.txSummary['propertyState'];
    const payload = FormUtil.getFormData(this.tran.id, this.formType, formData);
    await this.docService.saveForm(payload)
      .then(() => {
        let message: string;
        message = 'Form successfully saved';
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.generating = false;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to generate document', data);
        this.eventEmitted.emit({
          type: 'ChangeMode',
          mode: 'View'
        });
        this.generating = false;
        return Promise.resolve(false)
      });
    return Promise.resolve(true)
  }

  cancelConfigDoc(): void {
    this.router.navigate(['/loan/view-directory'])
  }
}
