import {ModelBase} from './model-base';

export class LoanInformation extends ModelBase {
  public static mortgage_types_ex: Array<any> = [
    {key: 'Conventional', value: 'Conventional'},
    {key: 'FHA', value: 'FHA'},
    {key: 'LocalAgency', value: 'Local Agency'},
    {key: 'Other', value: 'Other'},
    {key: 'PublicAndIndianHousing', value: 'Public and Indian Housing'},
    {key: 'StateAgency', value: 'State Agency'},
    {key: 'USDARuralDevelopment', value: 'USDA Rural Development'},
    {key: 'VA', value: 'VA'},
  ];

  public static amortization_types_ex: Array<any> = [
    {key: 'AdjustableRate', value: 'Adjustable Rate'},
    {key: 'Fixed', value: 'Fixed'},
    {key: 'GEM', value: 'GEM'},
    {key: 'GPM', value: 'GPM'},
    {key: 'GraduatedPaymentARM', value: 'Graduated Payment ARM'},
    {key: 'Other', value: 'Other'},
    {key: 'RateImprovementMortgage', value: 'Rate Improvement Mortgage'},
    {key: 'Step', value: 'Step'},
  ];

  public static refinance_types: Array<any> = [
    {key: 'CashOutVA', value: 'Cash-Out VA'},
    {key: 'FullDocumentation', value: 'Full Documentation'},
    {key: 'InterestRateReductionRefinanceLoan', value: 'Interest Rate Reduction Refinance Loan'},
    {key: 'Other', value: 'Other'},
    {key: 'StreamlineWithAppraisal', value: 'Streamline With Appraisal'},
    {key: 'StreamlineWithoutAppraisal', value: 'Streamline Without Appraisal'}
  ];

  public static amortization_period_types: Array<any> = [
    {key: 'Biweekly', value: 'Biweekly'},
    {key: 'Day', value: 'Day'},
    {key: 'Month', value: 'Month'},
    {key: 'Quarter', value: 'Quarter'},
    {key: 'Semimonthly', value: 'Semimonthly'},
    {key: 'Week', value: 'Week'},
    {key: 'Year', value: 'Year'},
  ];

  agency_case_no: string;
  lender_case_no: string;
  loan_amount: string;
  interest_rate: string;
  periods_no: string;
  amortization_type_explain: string;
  mortgage_type_ex: string;
  amortization_type_ex: string;
  amortization_period_type: string;
  other_description: string;
  gov_refinance_type: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'loan_amount' || prop === 'interest_rate' || prop === 'periods_no') {
        this[prop] = super.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getInterestRate(): number {
    return this.interest_rate ? +this.interest_rate / 100 : null;
  }

  getMortgageType(): string {
    const found = LoanInformation.mortgage_types_ex.find((elt) => elt.key === this.mortgage_type_ex);
    return found ? found['value'] : '';
  }

  getAmortizationType(): string {
    const found = LoanInformation.amortization_types_ex.find((elt) => elt.key === this.amortization_type_ex);
    return found ? found['value'] : '';
  }

  getGovRefinanceType(): string {
    const found = LoanInformation.refinance_types.find((elt) => elt.key === this.gov_refinance_type);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      mortgage_type_ex: this.mortgage_type_ex,
      agency_case_no: this.agency_case_no,
      lender_case_no: this.lender_case_no,
      loan_amount: this.toServerDecimal(this.loan_amount),
      interest_rate: this.toServerPercentThree(this.interest_rate),
      periods_no: this.toNumber(this.periods_no),
      amortization_type_ex: this.amortization_type_ex,
      amortization_type_explain: this.amortization_type_explain,
      amortization_period_type: this.amortization_period_type,
      other_description: this.other_description,
      gov_refinance_type: this.gov_refinance_type,
    };
  }
}
