import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {NewMortgage} from '../../../../models/new-mortgage';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-new-mortgage',
  template: '',
})
export class NewMortgageComponent extends CompBaseComponent implements OnInit {
  @Input() item: NewMortgage;
  public submitted: boolean;

  // form and form fields
  public form: FormGroup;
  public creditor_name: FormControl;
  public first_lien: FormControl;
  public loan_amount: FormControl;
  public monthly_payment: FormControl;
  public credit_limit: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.submitted = false;
    this.segment = 'new_mortgages';
    this.section = null;
  }

  createFormControls(item: NewMortgage): void {
    this.creditor_name = new FormControl(item.creditor_name, AppValidators.present);
    this.first_lien = new FormControl(item.first_lien);
    this.loan_amount = new FormControl(item.loan_amount, AppValidators.present);
    this.monthly_payment = new FormControl(item.monthly_payment, AppValidators.present);
    this.credit_limit = new FormControl(item.credit_limit);
  }

  createForm(): void {
    this.form = new FormGroup({
      creditor_name: this.creditor_name,
      first_lien: this.first_lien,
      loan_amount: this.loan_amount,
      monthly_payment: this.monthly_payment,
      credit_limit: this.credit_limit,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new NewMortgage(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new NewMortgage(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
