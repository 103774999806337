import {ModelBase} from './model-base';

export class Hmda extends ModelBase {
  public static taken_bys = [
    {key: 'Email', value: 'Email'},
    {key: 'FaceToFace', value: 'Face-to-Face'},
    {key: 'Fax', value: 'Fax'},
    {key: 'Internet', value: 'Internet'},
    {key: 'Mail', value: 'Mail'},
    {key: 'Telephone', value: 'Telephone'},
  ];
  race_visual: boolean;
  ethnicity_visual: boolean;
  gender_visual: boolean;
  taken_by: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'race_visual' || prop === 'ethnicity_visual' || prop === 'gender_visual') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getTakenBy(): string {
    const found = Hmda.taken_bys.find((elt) => elt.key === this.taken_by);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      race_visual: ModelBase.toServerExplicitBoolean(this.race_visual),
      ethnicity_visual: ModelBase.toServerExplicitBoolean(this.ethnicity_visual),
      gender_visual: ModelBase.toServerExplicitBoolean(this.gender_visual),
      taken_by: this.taken_by
    };
  }
}
