import {Component, Injector, Input, OnInit} from '@angular/core';
import {Military} from '../../../../models/military';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-military',
  templateUrl: './view-segment-military.component.html',

})
export class ViewSegmentMilitaryComponent extends CompBaseComponent implements OnInit {
  @Input() item: Military;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'military';
    this.section = this.applicant;
  }
}
