<div *ngIf="!isCollapsible" [innerHTML]="text | nl2br">
</div>

<div *ngIf="isCollapsible" class="hand-pointer">
  <div (click)="toggle($event)" *ngIf="isCollapsed">
    <span [innerHTML]="collapsedText| slice:0:textLength | nl2br"></span><a (click)="toggle($event)" href="">&nbsp;More ...</a>
  </div>

  <div (click)="toggle($event)" *ngIf="!isCollapsed">
    <span [innerHTML]="text | nl2br"></span><a (click)="toggle($event)" href="">&nbsp;Less ...</a>
  </div>
</div>
