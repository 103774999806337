import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Help} from '../../../../models/help';
import {AppValidators} from "../../../../shared/validators/app-validators.validator";
import {CompBaseComponent} from "../../../comp-base.component";
import {AdminService} from "../../../../services/admin.service";

@Component({
  selector: 'app-send-socket-message',
  templateUrl: './send-socket-message.component.html',
})
export class SendSocketMessageComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Send Socket Message to User';
  public adminService: AdminService;

  // form and form controls
  public form: FormGroup;
  public email: FormControl;
  public message: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new FormControl('', Validators.compose([AppValidators.present, Validators.email]));
    this.message = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      email: this.email,
      message: this.message
    });
  }

  sendMessage(): void {
    console.log('sending message');
    this.adminService.sendSocketMessage(this.email.value, this.message.value);
  }

  reset(): void {
    this.form.reset();
  }
}
