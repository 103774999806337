import {Component, Injector, Input, OnInit} from '@angular/core';
import {LoanPurpose} from '../../../../models/loan-purpose';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-loan-purpose',
  templateUrl: './view-segment-loan-purpose.component.html',

})
export class ViewSegmentLoanPurposeComponent extends CompBaseComponent implements OnInit {
  @Input() item: LoanPurpose;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'loan_purpose';
    this.section = 'loan_information';
  }
}
