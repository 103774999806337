import {ModelBase} from './model-base';

export class Gift extends ModelBase {
  public static gift_types = [
    {key: 'GiftOfCash', value: 'Gift of Cash'},
    {key: 'GiftOfPropertyEquity', value: 'Gift of Property Equity'},
    {key: 'Grant', value: 'Grant'},
  ];

  public static gift_sources = [
    {key: 'CommunityNonProfit', value: 'Community Non-Profit'},
    {key: 'Employer', value: 'Employer'},
    {key: 'FederalAgency', value: 'Federal Agency'},
    {key: 'Lender', value: 'Lender'},
    {key: 'LocalAgency', value: 'Local Agency'},
    {key: 'NonParentRelative', value: 'Non-Parent Relative'},
    {key: 'Other', value: 'Other'},
    {key: 'Parent', value: 'Parent'},
    {key: 'Relative', value: 'Relative'},
    {key: 'ReligiousNonProfit', value: 'Religious Non-Profit'},
    {key: 'StateAgency', value: 'State Agency'},
    {key: 'UnmarriedPartner', value: 'Unmarried Partner'},
    {key: 'Other', value: 'Other'},
  ];

  index: number;
  market_value: string;
  gift_source: string;
  gift_type: string;
  deposited: boolean;
  other_description: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'market_value') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'deposited') {
        this[prop] = this.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Gift> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Gift(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getGiftType(): string {
    const found = Gift.gift_types.find((elt) => elt.key === this.gift_type);
    return found ? found['value'] : '';
  }

  getGiftSource(): string {
    const found = Gift.gift_sources.find((elt) => elt.key === this.gift_source);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      gift_type: this.gift_type,
      gift_source: this.gift_source,
      market_value: this.toServerDecimal(this.market_value),
      deposited: ModelBase.toServerExplicitBoolean(this.deposited),
      other_description: this.other_description
    };
  }
}
