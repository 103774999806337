import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {User} from '../../../models/user';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends CompBaseComponent implements OnInit {
  pageTitle = 'Reset Password';
  user: User;
  form: FormGroup;
  password: FormControl;
  password_confirmation: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.accountPassword
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    this.createFormControls();
    this.createForm();
  }

  updatePassword() {
    if (!this.form.valid) {
      return;
    }

    if (this.password.value !== this.password_confirmation.value) {
      FormUtil.genErrorMessage(this.sharedService, 'Password does not match password confirmation', null);
      return;
    }

    const payload = {
      password: this.password.value,
      password_confirmation: this.password_confirmation.value,
    };

    this.userService.updatePassword(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Password successfully updated');
        this.resetForm();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update password', data);
      });
  }

  resetUpdatePassword(): void {
    this.resetForm();
  }

  resetForm(): void {
    this.form.reset();
    Object.keys(this.form.controls).forEach((name) => {
      this.form.controls[name].setErrors(null);
    });
  }

  createFormControls() {
    this.password = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(6)]));
    this.password_confirmation = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(6)]));
  }

  createForm() {
    const arr = ['password', 'password_confirmation'];
    const controls = {};
    for (const field of arr) {
      controls[field] = this[field];
    }
    this.form = new FormGroup(controls);
  }
}
