import {Component, Injector, OnInit} from '@angular/core';
import {Race} from '../../../../models/race';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {ModelBase} from '../../../../models/model-base';

@Component({
  selector: 'app-view-segment-races',
  templateUrl: './view-segment-races.component.html',
})
export class ViewSegmentRacesComponent extends CompBaseComponent implements OnInit {
  public form: FormGroup;
  public no_information_race: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'race_informations';
    this.section = this.applicant;
    this.reload();
  }

  createFormControls(): void {
    const no_info = ModelBase.getBooleanProp(this.loan_app[this.applicant].race_informations.no_information_race);
    this.no_information_race = new FormControl(no_info);
  }

  createForm(): void {
    this.form = new FormGroup({
      no_information_race: this.no_information_race
    });
  }

  onNoInformationChange(): void {
    this.update();
  }

  update(): void {
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = {'no_information_race': ModelBase.toServerBoolean(this.no_information_race.value)};

    this.loanAppService.updateLoanAppObj(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Race.deserializeArray(this.loan_app[this.applicant].race_informations.coll);
    setTimeout(() => {
      this.reloaded = true;
      this.createFormControls();
      this.createForm();
    }, 0);
  }
}
