<mat-card *ngIf="reloaded" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'No Loan MCR'" [help]="true">
      <p>
        This list below consists of quarters against which you can generate the loan officer MCR report for this loan.
        A list with multiple entries makes sense only when a loan spans more than one quarter.
        Otherwise, the list should contain only the single quarter when the loan application was submitted and closed.
      </p>
      <p>
        When the quarter list contains more than one entry, then you can run the loan officer MCR report
        for each quarter of the list.
      </p>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>
      <div class="row mt-2">
        <div class="col-lg-6">
          <button (click)="create()" class="btn btn-lg btn-primary" aria-label="Create Loan MCR">Create Loan MCR</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
