import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fnmaMaritalStatusToString'
})
export class FnmaMaritalStatusToStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    switch (value) {
      case 'M':
        return 'Married';
      case 'U':
        return 'Unmarried';
      case 'S':
        return 'Separated';
    }
  }
}
