// declare const AXIS_TYPES: {
//   readonly value: 1;
//   readonly category: 1;
//   readonly time: 1;
//   readonly log: 1;
// };

import {Component, Injector, OnInit} from '@angular/core';
import {PitchService} from '../../../services/pitch.service';
import {EChartsOption} from 'echarts';
import {Help} from '../../../models/help';
import {SharedService} from '../../../layouts/shared-service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-view-pitch',
  templateUrl: './view-pitch.component.html',
  styleUrls: ['view-pitch.component.scss'],
})
export class ViewPitchComponent implements OnInit {
  pageTitle = 'View Financials';
  Math = Math; // local access to JS Math
  computed: boolean;

  // charts
  chartRevenueLOS: EChartsOption;
  chartRevenueLoanMarketplace: EChartsOption;
  chartRevenue: EChartsOption;
  chartExpenseDevLOS: EChartsOption;
  chartExpenseDevLoanMarketplace: EChartsOption;
  chartExpenseMarketing: EChartsOption;
  chartExpenseSales: EChartsOption;
  chartExpenseGNA: EChartsOption;
  chartExpense: EChartsOption;
  chartIncome: EChartsOption;
  chartCumulative: EChartsOption;
  chartTax: EChartsOption;
  chartNetIncome: EChartsOption;
  chartMulti: EChartsOption;

  //
  // form & controls
  //
  form: FormGroup;
  dispRevenueLOS: FormControl;
  dispRevenueLoanMarketplace: FormControl;
  dispRevenue: FormControl;
  dispIncome: FormControl;
  dispCumulative: FormControl;
  dispExpenseDevLOS: FormControl;
  dispExpenseDevLoanMarketplace: FormControl;
  dispExpenseMarketing: FormControl;
  dispExpenseSales: FormControl;
  dispExpenseGNA: FormControl;
  dispExpense: FormControl;
  dispAssumptions: FormControl;
  dispTax: FormControl;
  dispNetIncome: FormControl;
  dispFinancials: FormControl;
  dispSelectAll: FormControl;
  dispMulti: FormControl;
  // LOS revenue
  maxLOs: FormControl;
  chargeLO: FormControl;
  losRevenueShift: FormControl;
  losRevenueMonths: FormControl;
  // LOS expense
  losExpenseShift: FormControl;
  losExpenseMonths: FormControl;
  losExpenseCounts: FormControl;
  losCostPerMonth: FormControl;
  // marketplace revenue
  maxTxsPercent: FormControl;
  chargeTx: FormControl;
  marketplaceRevenueShift: FormControl;
  marketplaceRevenueMonths: FormControl;
  // marketplace expenses
  marketplaceExpenseShift: FormControl;
  marketplaceExpenseMonths: FormControl;
  marketplaceExpenseCounts: FormControl;
  marketplaceCostPerMonth: FormControl;
  //sales expense
  salesPercentage: FormControl;
  //marketing expense
  marketingPercentage: FormControl;
  // G&A expense
  minGNA: FormControl;
  GNAPercentage: FormControl;
  targetInflectionRevenueYear: FormControl;

  report: any;
  arr = Array.from(new Array(20).keys());
  arr1 = Array.from(new Array(20).keys()).slice(0, 10);
  arr2 = Array.from(new Array(20).keys()).slice(10, 20);
  options: any;
  minCashFlow: number;

  // pitch constants
  TOTAL_MONTHS = 60;

  constructor(public injector: Injector, private sharedService: SharedService, private pitchService: PitchService) {
  }

  ngOnInit() {
    this.computed = false;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountOrg
      });
    }, 0);
    console.log(this.arr1);
    console.log(this.arr1.length);

    this.reload();
  }

  createFormControls() {
    this.dispRevenueLOS = new FormControl(false);
    this.dispRevenueLoanMarketplace = new FormControl(false);
    this.dispRevenue = new FormControl(false);
    this.dispIncome = new FormControl(false);
    this.dispCumulative = new FormControl(false);
    this.dispExpenseDevLOS = new FormControl(false);
    this.dispExpenseDevLoanMarketplace = new FormControl(false);
    this.dispExpenseMarketing = new FormControl(false);
    this.dispExpenseSales = new FormControl(false);
    this.dispExpenseGNA = new FormControl(false);
    this.dispExpense = new FormControl(false);
    this.dispAssumptions = new FormControl(true);
    this.dispTax = new FormControl(false);
    this.dispNetIncome = new FormControl(false);
    this.dispFinancials = new FormControl(false);
    this.maxLOs = new FormControl(7500, Validators.required);
    this.maxTxsPercent = new FormControl(3.25, Validators.required);
    this.chargeTx = new FormControl(1500, Validators.required);
    this.chargeLO = new FormControl(65.00, Validators.required);
    this.losCostPerMonth = new FormControl(15000, Validators.required);
    this.marketplaceCostPerMonth = new FormControl(15000, Validators.required);
    this.marketingPercentage = new FormControl(12.5, Validators.required);
    this.salesPercentage = new FormControl(10.0, Validators.required);
    this.minGNA = new FormControl(30000.0, Validators.required);
    this.targetInflectionRevenueYear = new FormControl(10000000.0, Validators.required);
    this.GNAPercentage = new FormControl(12.5, Validators.required);
    this.dispSelectAll = new FormControl(false, Validators.required);
    this.dispMulti = new FormControl(false, Validators.required);

    //revenues
    this.losRevenueShift = new FormControl(3, Validators.required);
    this.losRevenueMonths = new FormControl(36, Validators.required);
    this.marketplaceRevenueShift = new FormControl(18, Validators.required);
    this.marketplaceRevenueMonths = new FormControl(24, Validators.required);

    // expenses
    this.losExpenseShift = new FormControl(0, Validators.required);
    this.losExpenseMonths = new FormControl(18, Validators.required);
    this.losExpenseCounts = new FormControl('2,3,4', Validators.required);
    this.marketplaceExpenseShift = new FormControl(18, Validators.required);
    this.marketplaceExpenseMonths = new FormControl(36, Validators.required);
    this.marketplaceExpenseCounts = new FormControl('2,4,8', Validators.required);
  }

  createForm() {
    this.form = new FormGroup({
      dispRevenueLOS: this.dispRevenueLOS,
      dispRevenueLoanMarketplace: this.dispRevenueLoanMarketplace,
      dispRevenue: this.dispRevenue,
      dispIncome: this.dispIncome,
      dispCumulative: this.dispCumulative,
      dispExpenseDevLOS: this.dispExpenseDevLOS,
      dispExpenseDevLoanMarketplace: this.dispExpenseDevLoanMarketplace,
      dispExpenseMarketing: this.dispExpenseMarketing,
      dispExpenseSales: this.dispExpenseSales,
      dispExpenseGNA: this.dispExpenseGNA,
      dispExpense: this.dispExpense,
      dispAssumptions: this.dispAssumptions,
      dispTax: this.dispTax,
      dispNetIncome: this.dispNetIncome,
      dispFinancials: this.dispFinancials,
      dispSelectAll: this.dispSelectAll,
      dispMulti: this.dispMulti,

      losRevenueShift: this.losRevenueShift,
      losRevenueMonths: this.losRevenueMonths,
      marketplaceRevenueShift: this.marketplaceRevenueShift,
      marketplaceRevenueMonths: this.marketplaceRevenueMonths,

      losExpenseShift: this.losExpenseShift,
      losExpenseMonths: this.losExpenseMonths,
      losExpenseCounts: this.losExpenseCounts,

      marketplaceExpenseShift: this.marketplaceExpenseShift,
      marketplaceExpenseMonths: this.marketplaceExpenseMonths,
      marketplaceExpenseCounts: this.marketplaceExpenseCounts,


      maxTxsPercent: this.maxTxsPercent,
      chargeTx: this.chargeTx,
      maxLOs: this.maxLOs,
      chargeLO: this.chargeLO,
      losCostPerMonth: this.losCostPerMonth,
      marketplaceCostPerMonth: this.marketplaceCostPerMonth,
      marketingPercentage: this.marketingPercentage,
      salesPercentage: this.salesPercentage,
      minGNA: this.minGNA,
      GNAPercentage: this.GNAPercentage,
      targetInflectionRevenueYear: this.targetInflectionRevenueYear,
    });
  }

  reload(): void {
    this.createFormControls();
    this.createForm();
  }

  onChangeDispAll(): void {
    this.dispRevenueLOS.setValue(this.dispSelectAll.value);
    this.dispRevenueLoanMarketplace.setValue(this.dispSelectAll.value);
    this.dispRevenue.setValue(this.dispSelectAll.value);
    this.dispIncome.setValue(this.dispSelectAll.value);
    this.dispCumulative.setValue(this.dispSelectAll.value);
    this.dispExpenseDevLOS.setValue(this.dispSelectAll.value);
    this.dispExpenseDevLoanMarketplace.setValue(this.dispSelectAll.value);
    this.dispExpenseMarketing.setValue(this.dispSelectAll.value);
    this.dispExpenseSales.setValue(this.dispSelectAll.value);
    this.dispExpenseGNA.setValue(this.dispSelectAll.value);
    this.dispExpense.setValue(this.dispSelectAll.value);
    this.dispAssumptions.setValue(this.dispSelectAll.value);
    this.dispTax.setValue(this.dispSelectAll.value);
    this.dispNetIncome.setValue(this.dispSelectAll.value);
    this.dispFinancials.setValue(this.dispSelectAll.value);
    this.dispMulti.setValue(this.dispSelectAll.value);
  }

  createChart(title, data, color): any {
    return {
      xAxis: {
        type: 'category',
        data: this.createMonthArray('M', this.TOTAL_MONTHS),
      },
      yAxis: {
        type: 'value',
      },
      series: [{
        name: title,
        data: data,
        type: 'line', // 'line' 'bar',
        lineStyle: {color: color},
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.seriesName;
          }
        }
      },],
    };
  }


  createMultiChart(data): any {
    return {
      xAxis: {
        type: 'category',
        data: this.createMonthArray('M', 36),
      },
      legend: {
        data: ['Revenue', 'Expense', 'Income', 'Tax']
      },
      yAxis: {
        type: 'value',
      },
      series: [{
        name: 'Revenue',
        data: data['revenue'].slice(0, 36),
        type: 'line', // 'line' 'bar',
        lineStyle: {color: 'blue'},
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.seriesName;
          }
        }
      }, {
        name: 'Expense',
        data: data['expense'].slice(0, 36),
        smooth: true,
        type: 'line', // 'line' 'bar',
        lineStyle: {color: 'red'},
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.seriesName;
          }
        }
      }, {
        name: 'Income',
        data: data['income'].slice(0, 36),
        type: 'line', // 'line' 'bar',
        lineStyle: {color: 'green'},
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.seriesName;
          }
        }
      }, {
        name: 'Tax',
        data: data['tax'].slice(0, 36),
        type: 'line', // 'line' 'bar',
        lineStyle: {color: '#ee82ee'},
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.seriesName;
          }
        }
      },
      ],
    };
  }

  createMonthArray(prefix: string, len: number): Array<string> {
    let mArr = [];
    for (let i = 0; i < len; i++) {
      mArr[i] = prefix + (i + 1);
    }
    return mArr;
  }

  onSubmit(): void {
    this.computed = false;
    const countsLOS = this.losExpenseCounts.value.split(',').map((x) => +x);
    const countsLoanMarketplace = this.marketplaceExpenseCounts.value.split(',').map((x) => +x);
    this.options = {
      revenue_los: {
        shiftMonths: Math.floor(+this.losRevenueShift.value),
        monthsCount: Math.floor(+this.losRevenueMonths.value),
        maxLOs: Math.floor(+this.maxLOs.value),
        chargeLO: +this.chargeLO.value,
        totalMonths: this.TOTAL_MONTHS,
      },
      revenue_loan_marketplace: {
        shiftMonths: Math.floor(+this.marketplaceRevenueShift.value),
        monthsCount: Math.floor(+this.marketplaceRevenueMonths.value),
        maxTxs: Math.floor((7500000 * this.maxTxsPercent.value / 100.00) / 12),
        chargeTx: +this.chargeTx.value,
        totalMonths: this.TOTAL_MONTHS,
      },
      expense_dev_los: {
        count1: countsLOS[0],
        count2: countsLOS[1],
        count3: countsLOS[2],
        costPerMonth: +this.losCostPerMonth.value,
        shiftMonths: Math.floor(+this.losExpenseShift.value),
        monthsCount: Math.floor(+this.losExpenseMonths.value),
        totalMonths: this.TOTAL_MONTHS,
      },
      expense_dev_loan_marketplace: {
        count1: countsLoanMarketplace[0],
        count2: countsLoanMarketplace[1],
        count3: countsLoanMarketplace[2],
        costPerMonth: +this.marketplaceCostPerMonth.value,
        shiftMonths: Math.floor(+this.marketplaceExpenseShift.value),
        monthsCount: Math.floor(+this.marketplaceExpenseMonths.value),
        totalMonths: this.TOTAL_MONTHS,
      },
      expense_marketing: {
        percentage: +this.marketingPercentage.value,
      },
      expense_sales: {
        percentage: +this.salesPercentage.value,
      },
      expense_gna: {
        totalMonths: this.TOTAL_MONTHS,
        targetInflectionRevenueYear: +this.targetInflectionRevenueYear.value,
        minGNA: +this.minGNA.value,
        GNAPercentage: +this.GNAPercentage.value
      },
    };

    this.pitchService.getPitchReport(this.options)
      .then((data) => {
        this.chartRevenueLOS = this.createChart('Revenue LOS', data['revenue_los'], '#00ffff');
        this.chartRevenueLoanMarketplace = this.createChart('Revenue Marketplace', data['revenue_loan_marketplace'], '#0000ff');
        this.chartRevenue = this.createChart('Total Revenue', data['revenue'], '#0000ff');
        this.chartExpenseDevLOS = this.createChart('Expense Dev LOS',  data['expense_dev_los'], '#0000ff');
        this.chartExpenseDevLoanMarketplace = this.createChart('Expense Dev Marketplace', data['expense_dev_loan_marketplace'], '#0000ff');
        this.chartExpenseMarketing = this.createChart('Expense Marketing', data['expense_marketing'], '#0000ff');
        this.chartExpenseSales = this.createChart('Expense Sales', data['expense_sales'], '#0000ff');
        this.chartExpenseGNA = this.createChart('Expense G&A', data['expense_gna'], '#0000ff');
        this.chartExpense = this.createChart('Total Expense', data['expense'], '#0000ff');
        this.chartIncome = this.createChart('Income', data['income'], '#0000ff');
        this.chartCumulative = this.createChart('Cumulative Cash Flow', data['cumulative'], '#0000ff');
        this.minCashFlow = data['min_cash_flow'];
        this.chartTax = this.createChart('Tax', data['tax'], '#0000ff');
        this.chartNetIncome = this.createChart('Net Income', data['net_income'], '#0000ff');
        this.chartMulti = this.createMultiChart(data);
        this.report = data['report'];
        this.computed = true;
      }).then(() => {
    });
  }
}
