import {Component, Injector, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {SharedService} from '../../../layouts/shared-service';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-page-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class PageSignOutComponent implements OnInit {
  public sharedService: SharedService;
  private userService: UserService;

  constructor(public injector: Injector) {
    this.userService = this.injector.get(UserService);
    this.sharedService = this.injector.get(SharedService);
  }

  ngOnInit() {
    this.userService.logoutSession()
      .then(() => {
        this.sharedService.emitChange({type: 'Logout'});
        window.location.href = globals.getWWWServer();
      });
  }
}
