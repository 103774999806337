import {Component, Injector, OnInit} from '@angular/core';
import {CFAComponent} from '../cfa/cfa.component';

@Component({
  selector: 'app-view-segment-cfa-entry',
  templateUrl: './view-segment-cfa-entry.component.html',
})
export class ViewSegmentCFAEntryComponent extends CFAComponent implements OnInit {

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  editField(field: string): void {
    this.cfaService.setField(field);
    super.edit();
  }
}
