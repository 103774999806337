<div *ngIf="reloaded&&user">
  <div class="row mt-2">
    <div class="col-lg-4">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Account Photo</mat-card-title>
        </mat-card-header>
        <img [src]="user.getImagePath()" mat-card-image>
        <mat-card-actions *ngIf="user.is_attached_image">
          <button (click)="deleteImage()" class="btn btn-lg btn-danger">Delete Photo</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <app-upload-file *ngIf="!user.is_attached_image" (cancel)="onCancel()" (uploadComplete)="onUploadComplete()" [uploadType]="'image'" [multipleFiles]="false" actionUrl="account/photo_upload" title="Upload Photo">
  </app-upload-file>
</div>
