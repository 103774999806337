import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Folder} from '../../../models/folder';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-edit-folder',
  templateUrl: './edit-folder.component.html',
})
export class EditFolderComponent extends CompBaseComponent implements OnInit {
  @Input() folder: Folder;
  public form: FormGroup;
  public display: FormControl;
  public description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.display = new FormControl(this.folder.display, Validators.compose([AppValidators.present, Validators.minLength(2)]));
    this.description = new FormControl(this.folder.description);
  }

  createForm(): void {
    this.form = new FormGroup({display: this.display, description: this.description});
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    this.tranService.updateTransactionItem(this.tran.id, this.folder.id, this.form.value)
      .then((data) => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }
}
