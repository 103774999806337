const today = new Date();

export const globals: any = {
  errorLevel: {
    info:0,
    success:1,
    warning:2,
    error:3,
  },
  contactEmailForTerms: 'support@prudentco.com',
  maintenance: false,
  date: {
    minYearBuilt: 1700,
    maxYearBuilt: today.getFullYear(),
    minAutoYear: 1900,
    maxAutoYear: today.getFullYear(),
    minTranStartDate: new Date(1970, 0, 1),
    maxTranEndDate: new Date(2030, 0, 1),
    maxDocExpDate: new Date(2050, 0, 1),
    minDocIssueDate: new Date(1900, 0, 1),
    minPersonDOB: new Date(1900, 0, 1),
    maxPersonDOB: new Date(today.getFullYear() - 16, today.getMonth(), today.getDate()),
    minEmpStartDate: new Date(1900, 0, 1),
    maxEmpEndDate: today
  },
  pattern: {
    phone: /(^[0-9]{3}[\-.]?[0-9]{3}[\-.]?[0-9]{4}$)|(^\([0-9]{3}\)\s?[0-9]{3}[\-.]?[0-9]{4}$)/,
    state: /^(AA|AE|AP|AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)$/,
    country: /^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|AN|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SK|SI|SB|SO|ZA|GS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)$/i,
    zip: /^[0-9]{5}$/,
    zip_four: /^[0-9]{4}$/,
    censusTract: /^[0-9]+[.]?[0-9]+$/,
    percent: /^([0-9]{1,2})?(\.[0-9]+)?$/,
    percentThree: /^[0-9]{1,3}(\.[0-9]{1,3})?$/,
    percentTwo: /^[0-9]{1,2}(\.[0-9]{1,2})?$/,
    url: /^https?:\/\/([-a-zA-Z0-9_]+\.)+[a-z]{2,}(\/.*)?$/,
    email: /^[-a-zA-Z0-9_+.]+@([-a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/,
    decimalTwo: /^\d{0,12}(\.\d{1,2})?$/,
    propertiesCount: /^(1|2|3|4)$/,
    decimalThree: /^\d{1,3}(\.\d{1,3})?$/,
    ssn: /^(\d{9})|(\d{3}-\d{2}-\d{4})$/,
    numberOnly: /^[\d]+$/,
    time: /^((0?[1-9]|1[0-2]):[0-5][0-9]\s?(am|pm))|((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])$/i,
  },
  segment: {
    laAssets: 'la_assets',
    laRealEstates: 'la_real_estates',
    laExpenses: 'la_expenses',
    declarations: 'declarations',
    declarationExplanations: 'declaration_explanations',
    details: 'details',
    envelopeHeader: 'envelope_header',
    loanCharacteristics: 'loan_characteristics',
    productCharacteristics: 'product_characteristics',
    productIdentification: 'product_identification',
    agreement: 'agreement',
    addenda: 'addenda',
    hmda: 'hmda',
    adsCollection: 'ads_collection',
    borrower_1: 'borrower_1',
    borrower_2: 'borrower_2',
    borrower_3: 'borrower_3',
    borrower_4: 'borrower_4',
    lifeInsurance: 'life_insurance',
    cashDeposit: 'cash_deposit',
    addresses: 'addresses',
    assets: 'assets',
    gifts: 'gifts',
    aliases: 'aliases',
    dependents: 'dependents',
    liabilities: 'liabilities',
    incomes: 'incomes',
    autos: 'autos',
    downPayments: 'down_payments',
    newMortgages: 'new_mortgages',
    undrawns: 'undrawns',
    secEmployments: 'sec_employments',
    expenses: 'expenses',
    races: 'races',
    supports: 'supports',
    credits: 'credits',
    titleHolderName: 'title_holder_name',
    parties: 'parties',
    loanPurpose: 'loan_purpose',
    propertyInformation: 'property_information',
    transmittalData: 'transmittal_data',
    loanData: 'loan_data',
    loanInformation: 'loan_information',
    buydown: 'buydown',
    construction: 'construction',
    armData: 'arm_data',
    formTop: 'form_top',
    tab1Instructions: 'tab1_instructions',
    tab2Instructions: 'tab2_instructions',
    tab3Instructions: 'tab3_instructions',
    tab4Instructions: 'tab4_instructions',
    information: 'information',
    loInformation: 'lo_information',
    employment: 'employment',
    employment2: 'employment2',
    military: 'military',
    errorCheck: 'error_check',
    finalizeLoan: 'finalize_loan',
    financialRatios: 'financial_ratios',
    creditReports: 'credit_reports',
    getCreditReport: 'get_credit_report',
    mcr: 'mcr',
    cfa: 'cfa',
    concessions: 'concessions'
  },
  section: {
    terms: 'terms',
    borrower_1: 'borrower_1',
    borrower_2: 'borrower_2',
    borrower_3: 'borrower_3',
    borrower_4: 'borrower_4',
    lo: 'lo',
    tools: 'tools',
    currentTask: 'current_task',
    tasks: 'tasks',
    bulkUpdateTasks: 'bulkUpdateTasks',
    resetFutureTasks: 'resetFutureTasks',
    updateWorkflow: 'update_workflow',
    resetTasks: 'reset_tasks',
    loanRecord: 'loan_record',
    loanApps: 'loan_apps',
    docs: 'docs',
    copyDocs: 'copy_docs',
    generateDocs: 'generate_docs',
    util: 'util',
    transactionPermissions: 'transactionPermissions',
    workflowPermissions: 'workflowPermissions',
    docPermissions: 'docPermissions',
    loanAppPermissions: 'loanAppPermissions',
    complianceDash: 'complianceDash',
    securedUploads: 'securedUploads',
    sendSecuredUploadEmail: 'sendSecuredUploadEmail',
    workflowDash: 'workflowDash',
    test: 'test',
    general: 'general',
    transaction_details: 'transaction_details',
    loan_terms: 'loan_terms',
    lender: 'lender',
    origination: 'origination',
    other_payments: 'other_payments',
    insurance: 'insurance',
    title: 'title',
    taxes_and_fees: 'taxes_and_fees',
    other: 'other',
    escrow: 'escrow',
  },
  getServicesTitle: (): string => {
    return 'ezUploads'
  },
  getEnv: (): string => {
    const host = window.location.host;
    switch (host) {
      case 'dev.prudentco.com':
      case 'my.dev.prudentco.com':
        return 'development';
      case 'qa.prudentco.com':
      case 'myqa.prudentco.com':
        return 'qa';
      case 'prudentco.biz':
      case 'www.prudentco.biz':
      case 'www.prudentco.com':
      case 'prudentco.com':
      case 'my.prudentco.com':
        return 'production';
      default:
        return 'default environment';
    }
  },
  isDebug: (): boolean => {
    return globals.getEnv() === 'qa' || globals.getEnv() === 'development' || globals.getEnv() === 'test';
  },
  getMyServer: (): string => {
    const env = globals.getEnv();
    switch (env) {
      case 'production':
        return 'https://my.prudentco.com';
      case 'qa':
        return 'https://myqa.prudentco.com';
      case 'development':
        return 'http://my.dev.prudentco.com';
    }
  },
  getAuthServer: (): string => {
    const env = globals.getEnv();
    switch (env) {
      case 'production':
        return 'https://auth.prudentco.com';
      case 'qa':
        return 'https://authqa.prudentco.com';
      case 'development':
        return 'http://auth.dev.prudentco.com';
    }
  },
  getWWWServer: (): string => {
    const env = globals.getEnv();
    switch (env) {
      case 'production':
        return 'https://www.prudentco.com';
      case 'qa':
        return 'https://qa.prudentco.com';
      case 'development':
        return 'http://dev.prudentco.com';
    }
  },
  getInviteUserLink: (notifyUserId: number): string => {
    return `${globals.getMyServer()}`;
  },
  getUrlBase: (): string => {
    const host = window.location.host;
    const protocol = window.location.protocol;
    return protocol + '//' + host + '/api';
  },
  getMissingThumbnailUrl: (): string => {
    return globals.getUrlBase() + '/images/thumb/missing.png';
  },
  formatPhone: (val: string): string => {
    if (!val || val.length !== 10) {
      return val;
    }
    return `${val.substr(0, 3)}-${val.substr(3, 3)}-${val.substr(6, 4)}`;
  },
  generateFormErrors(form): Array<any> {
    const errors = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: any = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            'control ': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  },
  scrollTop: (): void => {
    const top = document.getElementById('navbar-top');
    if (top) {
      top.scrollIntoView();
    }
  }
};
