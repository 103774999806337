import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Information} from '../../../../models/information';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-information',
  templateUrl: './edit-segment-information.component.html',
})
export class EditSegmentInformationComponent extends CompBaseComponent implements OnInit {
  public ethnicities_ex = Information.ethnicities_ex;
  public genders = Information.genders;
  @Input() public item: Information;

  // form and form controls
  form: FormGroup;
  no_information_sex: FormControl;
  no_information_ethnicity: FormControl;
  ethnicity_ex: FormControl;
  gender: FormControl;
  mexico: FormControl;
  puerto_rico: FormControl;
  cuba: FormControl;
  other: FormControl;
  other_description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'information';
    this.section = this.applicant;
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.no_information_sex = new FormControl(item.no_information_sex);
    this.no_information_ethnicity = new FormControl(item.no_information_ethnicity);
    this.ethnicity_ex = new FormControl(item.ethnicity_ex);
    this.gender = new FormControl(item.gender);
    this.mexico = new FormControl(item.mexico);
    this.puerto_rico = new FormControl(item.puerto_rico);
    this.cuba = new FormControl(item.cuba);
    this.other = new FormControl(item.other);
    this.other_description = new FormControl(item.other_description);
  }

  createForm(): void {
    this.form = new FormGroup({
      ethnicity_ex: this.ethnicity_ex,
      no_information_ethnicity: this.no_information_ethnicity,
      gender: this.gender,
      no_information_sex: this.no_information_sex,
      mexico: this.mexico,
      puerto_rico: this.puerto_rico,
      cuba: this.cuba,
      other: this.other,
      other_description: this.other_description,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    this.setOthers();
    if (!this.form.valid) {
      return;
    }

    const updated = new Information(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  setOthers(): void {
    this.other_description.setErrors(null);
    if (this.other.value == true && !this.other_description.value) {
      this.other_description.setErrors({'required': true});
    }
    if (this.other.value != true) {
      this.other_description.setValue(null);
    }
  }
}
