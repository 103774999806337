import {ModelBase} from './model-base';
import {OrderLineItem} from "./order-line-item";

export class Order extends ModelBase {
  static deserializeArray(arr: Array<any>): Array<Order> {
    const result = [];
    for (const obj of arr) {
      const item = new Order(obj);
      result.push(item);
    }
    return result;
  }

  id: number;
  user_account_id: number;
  amount: number;
  status: number;
  entered_by_user_id: number;
  entered_by_name: string;
  order_line_items: Array<any>
  created_at: Date;
  updated_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else if (prop === 'order_line_items') {
        this.order_line_items = OrderLineItem.deserializeArray(object[prop])
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getStatus(): string {
    return ModelBase.getStatus(this.status);
  }
}
