import {Component, Injector, OnInit} from '@angular/core';
import {EmailComponent} from '../email/email.component';

@Component({
  selector: 'app-send-orientation-email',
  templateUrl: './edit-email.component.html',
})
export class SendEmailComponent extends EmailComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.getContacts(force)
      .then(() => {
        this.createFormControls();
        this.createForm();
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      })
  }
}
