<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header class="d-inline" (eventEmitted)="onEvent($event)" [header]="getHeader()" [clickable]="true" [help]="true" [op]="'add'">
      <p>
        Your organization loan MCRs are listed below.
        Each loan MCR represents a single input to the organization MCR.
      </p>
      <ul>
        <li><b>To select a different period, click the header (Loan MCRs for...).</b></li>
        <li>Each loan that was either started, processed, or closed in this quarter should be accounted for in the list below.</li>
        <li>The loan MCRs are sorted by the Loan Officer and MCR names.</li>
        <li>To
          <b>add</b> a new loan MCR click the Add (plus) button.
        </li>
        <li>To
          <b>edit</b> any existing loan MCR, select it, and then click the Edit MCR button.
        </li>
        <li>You can
          <b>delete</b> any loan MCR from the Edit page.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>
    <app-box *ngIf="mcrs.length===0" [close]="true" [type]="'warning'" class="pt-2">
      <strong>No Loan MCRs!</strong>
    </app-box>

    <form [hidden]="mode!=='Add'" [formGroup]="form" novalidate>
      <mat-card-title>Add Loan MCR</mat-card-title>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Organization user</mat-label>
            <mat-select formControlName="orgUser" placeholder="Select user" required>
              <mat-option *ngFor="let orgUser of orgUsers" [value]="orgUser.key">{{orgUser.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="orgUser.hasError('required')">
              User is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Loan MCR name</mat-label>
            <input formControlName="name" matInput placeholder="Loan MCR name" type="text" maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="name.hasError('required')">
              Loan MCR Name is required
            </mat-error>
            <mat-hint>
              Give the Loan MCR a meaningful name
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-6">
          <button (click)="add()" [disabled]="!form.valid" class="btn btn-lg btn-primary" aria-label="Add">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </form>

    <div *ngIf="mcrs.length> 0" class="row pt-4 pb-1 fw-bold border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">ID</div>
      <div [hidden]="isMobile" class="col col-lg-2">Loan Officer</div>
      <div class="col col-lg-2">Name</div>
      <div class="col col-lg-2">Period</div>
      <div [hidden]="isMobile" class="col col-lg-2">Created</div>
      <div class="col col-lg-2">Modified</div>
    </div>

    <div (click)="navigateToMcr(mcr)" *ngFor="let mcr of mcrs" class="hand-pointer row pt-1 pb-1 hover-border border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">{{mcr.id}}</div>
      <div [hidden]="isMobile" class="col col-lg-2">{{mcr.owner_full_name}}</div>
      <div class="col col-lg-2">{{mcr.name}}</div>
      <div class="col col-lg-2">
        Q{{mcr.quarter}} {{mcr.year}}
      </div>
      <div [hidden]="isMobile" class="col col-lg-2">{{mcr.created_at | date:'MM/dd/yyyy'}}</div>
      <div class="col col-lg-2">{{mcr.updated_at | date:'MM/dd/yyyy'}}</div>
    </div>
  </mat-card-content>
</mat-card>
