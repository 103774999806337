import {AfterViewInit, Component, ElementRef, HostBinding, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Router} from '@angular/router';
import {globals} from '../../../../shared/globals/globals';
import {FormUtil} from '../../../form-util';
import {SiteComponent} from '../../site.component';
import {Help} from '../../../../models/help';
import {Notification} from '../../../../models/notification';

@Component({
  selector: 'app-polos-video',
  templateUrl: './polos-video.component.html',
})
export class POLOSVideoComponent extends SiteComponent implements OnInit, AfterViewInit {
  @ViewChild('userElt', {static: true}) userElt: ElementRef;
  @HostBinding('class') class = 'page';
  public form: FormGroup;
  public email_address: FormControl;
  public full_name: FormControl;
  public error: string;
  public submitted: boolean;
  public canVideo: boolean;
  public logoImageSrc: string;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageTitle = 'POLOS Demo';
    this.error = null;
    this.submitted = false;
    this.canVideo = false;
    this.logoImageSrc = 'assets/img/prudentco-logo-small.png';
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.homeHighlights
      });
      this.reloaded = true;
    }, 0);
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  createFormControls() {
    this.email_address = new FormControl('', Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.email)]));
    this.full_name = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(4)]));
  }

  createForm() {
    this.form = new FormGroup({
      email_address: this.email_address,
      full_name: this.full_name,
    });
  }

  setFocus() {
    setTimeout(() => {
      this.userElt.nativeElement.focus();
    }, 0);
  }

  navigateToSign(event: any): void {
    event.preventDefault();
    this.router.navigate(['/pages/sign-in']);

  }

  navigateToCreate(event: any): void {
    event.preventDefault();
    this.router.navigate(['/pages/sign-up']);

  }

  onSubmit() {
    this.submitted = true;
    this.error = null;

    if (this.form.invalid) {
      return;
    }

    const payload = {notification_type: Notification.NOTIFICATION_TYPE.VIDEO_WATCH, name: this.full_name.value, email: this.email_address.value};
    this.emailService.createNotification(payload)
      .then(() => {
        this.canVideo = true;
      })
      .catch((data) => {
        this.error = FormUtil.genErrorMessageOnly('System Error: Cannot create a video request', data);
      });
  }
}
