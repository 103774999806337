import {ModelBase} from './model-base';

export class Agreement extends ModelBase {
  signature_date: Date; // 1 char Y/N

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'signature_date') {
        this[prop] = super.getDateProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  toServerPayload(): any {
    return {
      signature_date: this.toServerDate(this.signature_date)
    };
  }
}
