import {ModelBase} from './model-base';

// todo: only the credits shown on the form are displayed in credits
export class Credit extends ModelBase {
  public static credit_codes = [
    {key: 'EarnestMoney', value: 'Earnest Money'},
    {key: 'EmployerAssistedHousing', value: 'Employer Assisted Housing'},
    {key: 'LotEquity', value: 'Lot Equity'},
    {key: 'RelocationFunds', value: 'Relocation Funds'},
    {key: 'RentFromSubjectProperty', value: 'Rent Credit'},
    {key: 'SweatEquity', value: 'Sweat Equity'},
    {key: 'TradeEquity', value: 'Trade Equity'},
    // end of what appears on the NURLA form
    {key: 'SellerCredit', value: 'Seller Credit'},
    {key: 'LenderCredit', value: 'Lender Credit'},
    // todo: do we need next line?
    {key: 'LeasePurchaseFund', value: 'Lease Purchase Fund'},
    {key: 'Other', value: 'Other'},
  ];
  index: number;
  amount: string;
  other_credit_explain: string;
  code_ex: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'amount') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Credit> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Credit(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getCreditType(): string {
    const found = Credit.credit_codes.find((elt) => elt.key === this.code_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      code_ex: this.code_ex,
      amount: this.toServerDecimal(this.amount),
      other_credit_explain: this.other_credit_explain
    };
  }
}
