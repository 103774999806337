<div *ngIf="!name" class="row hover-gray" (click)="editField('field1')" [ngClass]="{'fw-bold':prefix==='y'}" title="Click to edit">
  <div class="col-lg-6 pt-1 pb-1 hand-pointer" [ngClass]="{'fw-bold':bold}">
    {{hash[hashEntry]['caption']}}
  </div>

  <div (click)="editField('field1')" class="col-lg-2 pt-1 pb-1 border-bottom">
    {{prefixString}} &nbsp;
    <div *ngIf="currency" style="float: right">
      {{hash[hashEntry]['field1'] | currency}}
    </div>
    <span *ngIf="!currency" style="float: right">
      {{hash[hashEntry]['field1']}}
    </span>
  </div>

  <div class="col-lg-2 pt-1 pb-1">
  </div>

  <div (click)="editField('field2')" class="col-lg-2 pt-1 pb-1 border-bottom">
    {{prefixString}} &nbsp;
    <div *ngIf="currency" style="float: right">
      {{hash[hashEntry]['field2'] | currency}}
    </div>
    <span *ngIf="!currency" style="float: right">
      {{hash[hashEntry]['field2']}}
    </span>
  </div>
</div>

<div (click)="editField('field1')" *ngIf="name" class="row hover-gray" [ngClass]="{'fw-bold':prefix==='y'}" title="Click to edit">
  <div class="col-lg-6 pt-1 pb-1 hand-pointer" [ngClass]="{'fw-bold':bold}">
    {{hash[hashEntry]['caption']}}
  </div>

  <div class="col-lg-6 pt-1 pb-1 border-bottom">
    {{hash[hashEntry]['field1']}}
  </div>
</div>
