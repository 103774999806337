import {Component, Injector, OnInit} from '@angular/core';
import {Condition} from '../../../models/condition';
import {ConditionComponent} from '../condition/condition.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-condition',
  templateUrl: '../condition/edit-segment-condition.component.html',
})
export class AddConditionComponent extends ConditionComponent implements OnInit {
  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Condition({done: false}));
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    });
  }

  cancel(): void {
    this.router.navigate(['loan/conditions']);
  }
}
