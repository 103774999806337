import {Component, Injector, Input, OnInit} from '@angular/core';
import {Expense} from '../../../../models/expense';
import {ExpenseComponent} from '../expense/expense.component';

@Component({
  selector: 'app-view-segment-expense',
  templateUrl: './view-segment-expense.component.html',

})
export class ViewSegmentExpenseComponent extends ExpenseComponent implements OnInit {
  @Input() item: Expense;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
