<mat-card class="mt-2 mb-1">
  <mat-card-title>Real Estate</mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="update()" [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property address</mat-label>
            <input formControlName="property_address" matInput placeholder="Property address" type="text" appAutoFocus maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="property_address.hasError('required')">
              Property address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property Apt/Unit/Suite</mat-label>
            <input formControlName="property_address2" matInput placeholder="Property Apt/Unit/Suite" type="text" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="property_address2.hasError('required')">
              Apt/Unit/Suite is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property city</mat-label>
            <input formControlName="property_city" matInput placeholder="Property city" type="text" maxlength="{{MAX_LEN.ADDR_PART}}" required>
            <mat-error *ngIf="property_city.hasError('required')">
              Property city is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property state abbreviation</mat-label>
            <input formControlName="property_state" appUpperCase matInput appAlphaOnly placeholder="Property state abbreviation" type="text" maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            <mat-hint>State Abbr. E.g., CO for Colorado</mat-hint>
            <mat-error *ngIf="property_state.hasError('required')">
              Property state is required
            </mat-error>
            <mat-error *ngIf="property_state.hasError('pattern')">
              Property state is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property zip</mat-label>
            <input appNumberOnly formControlName="property_zip" matInput placeholder="Property zip" type="text" maxlength="{{MAX_LEN.ZIP}}" required>
            <mat-error *ngIf="property_zip.hasError('required')">
              Property zip is required
            </mat-error>
            <mat-error *ngIf="property_zip.hasError('pattern')">
              Property zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property zip four</mat-label>
            <input appNumberOnly formControlName="property_zip_four" matInput placeholder="Property zip four" type="text" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="property_zip_four.hasError('required')">
              Property zip four is required
            </mat-error>
            <mat-error *ngIf="property_zip_four.hasError('pattern')">
              Property zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Property country</mat-label>
            <input formControlName="property_country" appAlphaOnly matInput appUpperCase placeholder="Property country code" type="text" maxlength="{{MAX_LEN.COUNTRY_CODE}}">
            <mat-error *ngIf="property_country.hasError('required')">
              Property country code is required
            </mat-error>
            <mat-error *ngIf="property_country.hasError('pattern')">
              Property country code is invalid
            </mat-error>
            <mat-hint>Country Abbreviation. Enter US for USA.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!--estimated market value-->
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Estimated market value</mat-label>
            <input appDecimalOnly formControlName="estimated_market_value" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Estimated market value" type="text">
            <mat-error *ngIf="estimated_market_value.hasError('required')">
              Estimated market value is required
            </mat-error>
            <mat-error *ngIf="estimated_market_value.hasError('pattern')">
              Estimated market value is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--market value-->
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Market value</mat-label>
            <input appDecimalOnly formControlName="market_value" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Market value" type="text">
            <mat-error *ngIf="market_value.hasError('required')">
              Market value is required
            </mat-error>
            <mat-error *ngIf="market_value.hasError('pattern')">
              Market value is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Property disposition</mat-label>
            <mat-select formControlName="property_disposition_ex" placeholder="Select property disposition" required>
              <mat-option *ngFor="let property_disposition of property_dispositions_ex" [value]="property_disposition.key">{{property_disposition.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="property_disposition_ex.hasError('required')">
              Property disposition is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Current occupancy</mat-label>
            <mat-select formControlName="current_occupancy" placeholder="Select current occupancy" required>
              <mat-option *ngFor="let current_occupancy of occupancy_types" [value]="current_occupancy.key">{{current_occupancy.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="current_occupancy.hasError('required')">
              Current occupancy is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Intended occupancy</mat-label>
            <mat-select formControlName="occupancy" placeholder="Select intended occupancy" required>
              <mat-option *ngFor="let occupancy of occupancy_types" [value]="occupancy.key">{{occupancy.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="occupancy.hasError('required')">
              Intended occupancy is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="occupancy.value=='Other'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Other occupancy description</mat-label>
            <input formControlName="occupancy_other_description" matInput placeholder="Describe other occupancy" type="text" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="occupancy_other_description.hasError('required')">
              Occupancy other description is required
            </mat-error>
            <mat-error *ngIf="occupancy_other_description.hasError('pattern')">
              Occupancy other description is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Liens amount</mat-label>
            <input appDecimalOnly formControlName="liens_amount" matInput placeholder="Liens amount" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="liens_amount.hasError('required')">
              Liens amount is required
            </mat-error>
            <mat-error *ngIf="liens_amount.hasError('pattern')">
              Liens amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Gross rental income</mat-label>
            <input appDecimalOnly formControlName="gross_rental_income" matInput placeholder="Gross rental income" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="gross_rental_income.hasError('required')">
              Gross rental income is required
            </mat-error>
            <mat-error *ngIf="gross_rental_income.hasError('pattern')">
              Gross rental income is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly expenses</mat-label>
            <input appDecimalOnly formControlName="monthly_expenses" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Monthly expenses" type="text">
            <mat-error *ngIf="monthly_expenses.hasError('required')">
              Monthly expenses is required
            </mat-error>
            <mat-error *ngIf="monthly_expenses.hasError('pattern')">
              Monthly expenses is invalid
            </mat-error>
            <mat-hint>
              (see Help for details)
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Net rental income</mat-label>
            <input appDecimalOnly formControlName="net_rental_income" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Net rental income" type="text">
            <mat-error *ngIf="net_rental_income.hasError('required')">
              Net rental income is required
            </mat-error>
            <mat-error *ngIf="net_rental_income.hasError('pattern')">
              Net rental income is invalid
            </mat-error>
            <mat-hint>(For lender to calculate)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray">
        <div class="col-lg-4 my-auto">
          Does the property secure the loan?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Property secure loan" formControlName="property_secure_loan">
            <mat-radio-button [checked]="property_secure_loan.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="property_secure_loan.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mb-1 mt-3">
        <div class="col fw-bold">Real estate owners</div>
      </div>

      <div class="row hover-gray">
        <div class="col pt-2">
          <div *ngIf="partyIds.length===0" class="text-danger">
            Owner(s) is required!
          </div>
          <div *ngIf="partyIds.length>0">
            <div *ngFor="let partyId of partyIds" class="mb-2">
              <span class="badge bg-success" style="font-size:1.1rem;">
              {{partyId.value}}&nbsp;<i (click)="deletePartyId(partyId.key)" class="far fa-window-close hand-pointer"></i>
              </span>&nbsp;
            </div>
          </div>
          <div *ngIf="newPartyIds.length>0">
            <div *ngFor="let partyId of newPartyIds" class="mb-2">
              <span class="badge bg-secondary" style="font-size:1.1rem;">
                {{partyId.value}}&nbsp;<i (click)="addPartyId(partyId.key)" class="fas fa-plus-square hand-pointer"></i>
              </span>&nbsp;
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
