import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../../comp-base.component';
import {LoanAppStructureService} from '../../../../../services/loan-app-structure.service';
import {Document} from '../../../../../models/document';
import {AnalyzerService} from '../../../../../services/analyzer.service';

@Component({
  selector: 'app-error-check',
  template: '',
})
export class ErrorCheckComponent extends CompBaseComponent implements OnInit {
  static errorCheckObject: any;
  static errorLevel = {
    info: true,
    warning: true,
    danger: true
  };
  @Input() doc: Document;
  public loanAppStructure: LoanAppStructureService;
  public loan_app: any;
  public errorMessages = [];
  public isRunning: boolean;
  public columns = {
    id: {reverse: undefined},
    errorLevel: {reverse: undefined},
    sectionName: {reverse: undefined},
    segmentName: {reverse: undefined},
    text: {reverse: undefined}
  };
  public orderBy = 'id';
  public errorLevel = {
    info: true,
    warning: true,
    danger: true
  };

  // form with one single button
  public form: FormGroup;
  public errorLevelInfo: FormControl;
  public errorLevelWarning: FormControl;
  public errorLevelDanger: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.loanAppStructure = this.injector.get(LoanAppStructureService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setErrorLevel(): void {
    this.errorLevel = ErrorCheckComponent.errorLevel;
  }

  createFormControls(): void {
    this.errorLevelInfo = new FormControl(this.errorLevel.info);
    this.errorLevelWarning = new FormControl(this.errorLevel.warning);
    this.errorLevelDanger = new FormControl(this.errorLevel.danger);
  }

  createForm(): void {
    this.form = new FormGroup({
      errorLevelInfo: this.errorLevelInfo,
      errorLevelWarning: this.errorLevelWarning,
      errorLevelDanger: this.errorLevelDanger
    });
  }

  errorCheck() {
    let currentSection = null;
    let currentSegment = null;
    let sectionIndex;
    let segmentIndex;
    const isLoanProfessional = this.userService.isLoanProfessional();

    // traverse loan structure: by section (key) and segment (element in the array)
    const getNextSectionSegment = () => {
      if (currentSection == null) {
        sectionIndex = 0;
        segmentIndex = 0;
      } else {
        if (loanStructure[currentSection].length > segmentIndex + 1) {
          segmentIndex++;
        } else if (Object.keys(loanStructure).length > sectionIndex + 1) {
          sectionIndex++;
          segmentIndex = 0;
        } else {
          return [null, null];
        }
      }
      currentSection = Object.keys(loanStructure)[sectionIndex];
      currentSegment = loanStructure[currentSection][segmentIndex];

      return [currentSection, currentSegment];
    };

    const loanStructure = {};
    loanStructure['loan_information'] = this.loanAppStructure.getSegmentsArray('loan_information', this.loan_app, isLoanProfessional);
    const count = AnalyzerService.borrowerCount(this.loan_app);
    ErrorCheckComponent.getIntArray(1, 4).forEach((n) => {
      if (count >= n) {
        const bor = `borrower_${n}`;
        loanStructure[bor] = this.loanAppStructure.getSegmentsArray(bor, this.loan_app, isLoanProfessional);
      }
    });

    if (isLoanProfessional) {
      loanStructure['lo'] = this.loanAppStructure.getSegmentsArray('lo', this.loan_app, isLoanProfessional);
    }

    loanStructure['general'] = this.loanAppStructure.getSegmentsArray('general', this.loan_app, isLoanProfessional);

    this.isRunning = true;
    this.saveErrorCheckObject();
    this.errorMessages = [];
    let [section, segment] = getNextSectionSegment();
    while (section) {
      const messages = this.analyzerService.errorCheck(this.loan_app, section, segment);
      this.errorMessages = this.errorMessages.concat(messages);
      [section, segment] = getNextSectionSegment();
    }
    this.isRunning = false;
    this.setErrorMessages();
    this.saveErrorCheckObject();
  }

  saveErrorCheckObject() {
    ErrorCheckComponent.errorCheckObject = {
      errorMessages: this.errorMessages,
      isRunning: this.isRunning,
      columns: this.columns,
      orderBy: this.orderBy,
      errorLevel: this.errorLevel
    };
  }

  restoreErrorCheckObject() {
    const errorCheckObject = {};
    Object.assign(errorCheckObject, ErrorCheckComponent.errorCheckObject);
    if (typeof (errorCheckObject) === 'undefined') {
      return undefined;
    }

    Object.keys(errorCheckObject).forEach((key) => this[key] = errorCheckObject[key]);
    return true;
  }

  setErrorMessages() {
    this.errorMessages.forEach((message, index) => {
      message['id'] = index + 1;
      message['sectionName'] = this.loanAppStructure.getSectionName(message.section);
      message['segmentName'] = this.loanAppStructure.getSegmentName(message.segment);
    });
  }

  setOrderBy(event, column) {
    event.preventDefault();
    const reverse = this.columns[column]['reverse'];
    this.columns[column]['reverse'] = typeof (reverse) === 'undefined' ? false : !reverse;
    this.orderBy = column;
    this.saveErrorCheckObject();
  }

  getOrderBy() {
    return this.orderBy;
  }

  isReverse() {
    return this.columns[this.getOrderBy()]['reverse'];
  }

  getTableRowClass(message) {
    return {
      'text-white bg-info': message.errorLevel === 0,
      'text-white bg-warning': message.errorLevel === 1,
      'text-white bg-danger': message.errorLevel === 2
    };
  }

  getSortIconClass(column): string {
    if (this.getOrderBy() !== column) {
      return '';
    }
    const reverse = this.columns[column]['reverse'];
    const up = typeof (reverse) === 'undefined' ? reverse : !reverse;
    return up ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
  }

  onFilterChange(): void {
    ErrorCheckComponent.errorLevel.info = this.errorLevelInfo.value;
    ErrorCheckComponent.errorLevel.warning = this.errorLevelWarning.value;
    ErrorCheckComponent.errorLevel.danger = this.errorLevelDanger.value;
    this.setErrorLevel();
    this.getFilteredErrors();
  }

  getFilteredErrors() {
    const levels = [];
    if (this.errorLevel.info) {
      levels.push(0);
    }
    if (this.errorLevel.warning) {
      levels.push(1);
    }

    if (this.errorLevel.danger) {
      levels.push(2);
    }

    const errorMessages = this.errorMessages.filter((message) => levels.indexOf(message.errorLevel) >= 0);
    if (this.orderBy === 'id' || this.orderBy === 'errorLevel') {
      errorMessages.sort((x, y) => x[this.orderBy] - y[this.orderBy]);
    } else {
      errorMessages.sort((x, y) => x[this.orderBy] === y[this.orderBy] ? 0 : (x[this.orderBy] < y[this.orderBy] ? -1 : 1));
    }

    if (this.isReverse()) {
      errorMessages.reverse();
    }
    return errorMessages;
  }

  getLevelByCode(code) {
    switch (code) {
      case 0:
        return 'Info';
      case 1:
        return 'Warning';
      case 2:
        return 'Error';
    }
  }

  getSectionName(code) {
    return this.loanAppStructure.getSectionName(code);
  }

  getSegmentName(code) {
    return this.loanAppStructure.getSegmentName(code);
  }
}
