<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'Invoices'" [help]="true" [op]="'add'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>TBD</li>
      </ul>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>User</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let inv of invoices" class="hand-pointer">
          <td (click)="navigateToInvoice($event,inv)">{{inv.id}}</td>
          <td (click)="navigateToInvoice($event,inv)">{{inv.full_name}} ({{inv.user_id}})</td>
          <td (click)="navigateToInvoice($event,inv)">{{inv.start_date | date: 'MM/dd/yyyy' }}</td>
          <td (click)="navigateToInvoice($event,inv)">{{inv.amount | currency}}</td>
          <td (click)="navigateToInvoice($event,inv)">{{inv.getStatus()}}</td>
          <td>
            <a (click)="delete($event,inv)" title="Delete invoice" class="ms-2"><i class="text-danger fa fa-trash"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
</mat-card>
