import {Component, Injector, OnInit} from '@angular/core';
import {CompEventComponent} from '../comp-event/comp-event.component';

@Component({
  selector: 'app-edit-segment-comp-event',
  templateUrl: '../comp-event/edit-segment-comp-event.component.html',
})
export class EditSegmentCompEventComponent extends CompEventComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }
}
