import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {CompBaseComponent} from '../../../comp-base.component';
import {globals} from "../../../../shared/globals/globals";
import {Document} from "../../../../models/document";
import {Router} from "@angular/router";
import {FormUtil} from "../../../form-util";

@Component({
  selector: 'app-generate-document',
  templateUrl: './generate-document.component.html',
})
export class GenerateDocumentComponent extends CompBaseComponent implements OnInit {
  public readonly formTypes = Document.getAllFormTypes();
  public doc_codes: Array<any>;
  public formType: string;
  public isDefaultLoanApp: boolean;
  public folders: Array<any>;
  public txSummary: any;
  public generating: boolean;
  public statesArr: Array<string>;

  // form and form controls
  public form: FormGroup;
  public state: FormControl;
  public docCode: FormControl;
  public folder_id: FormControl;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.txSummary = this.tranService.getTransactionSummary({
      transaction: this.tran,
      mlo: this.userService.getUserSync()
    });
    this.reload();
  }

  createFormControls(): void {
    this.state = new FormControl(this.txSummary.propertyState);
    this.docCode = new FormControl('', AppValidators.present);
    this.folder_id = new FormControl(null);
  }

  createForm(): void {
    this.form = new FormGroup({
      state: this.state,
      docCode: this.docCode,
      folder_id: this.folder_id
    });
  }

  getDocTitle(formType: string): string {
    const found = this.doc_codes.find((elt) => elt.key === formType);
    if (!found) {
      return null;
    }
    if (found.configurable) {
      return 'Configure ' + found.value;
    }
    return 'Generate ' + found.value;
  }

  stateChanged(): void {
    if (this.state && this.state.value && this.state.value.trim()) {
      this.doc_codes = this.formTypes['US'].filter((formType: {
        key: string;
      }) => formType.key != 'URLA');
      const statesArr = this.state.value.trim().replace(/\s{2,}/g, ' ').replace(',', ' ').split(' ');
      // Ensure that repeat states do not cause adding extra entries
      let states: Set<string> = new Set();
      statesArr.forEach((elt: string) => states.add(elt));
      this.statesArr = Array.from(states);
      let errorMessages = [];
      states.forEach((elt: string) => {
        if (!globals.pattern.state.test(elt)) {
          errorMessages.push(`State ${elt} is invalid`);
        }
      })
      if (errorMessages.length > 0) {
        FormUtil.genErrorMessage(this.sharedService, errorMessages.join('. '), null);
        return;
      }
      states.forEach((stateAbbr: string) => {
        if (this.formTypes[stateAbbr]) {
          this.doc_codes = [...this.doc_codes, ...this.formTypes[stateAbbr]];
        }
      })
    }
  }

  onEvent(event: any): void {
    // do hard reload only if necessary
    if (event.type === 'Reload') {
      const force = typeof (event.force) === 'undefined' ? false : event.force;
      if (force) {
        this.eventEmitted.emit(event);
      } else {
        this.reload(false);
      }
      return;
    }

    this.eventEmitted.emit(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.folders = this.tran.getSelectedFolders().reduce((mem, folder) => {
      const object = {
        key: folder.id,
        value: `${folder.groupName}: ${folder.name}`
      };
      mem.push(object);
      return mem;
    }, []);
    this.isDefaultLoanApp = !!this.tran.getDefaultLoanApp();
    this.createFormControls();
    this.createForm();
    this.stateChanged();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  updateParams(formType: string): void {
    this.router.navigate(['/loan/config-doc'], {
      state: {
        configInput: {
          folderId: this.folder_id.value,
          formType: this.docCode.value,
          stateArr: this.statesArr,
          title: this.getDocTitle(formType)
        },
      }
    });
  }

  cancelUpdate(event: any) {
    event.preventDefault();
    this.reload();
  }
}
