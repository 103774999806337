import moment from 'moment';
import {ModelBase} from './model-base';

export class LoanPurpose extends ModelBase {
  public static ownership_types_ex: Array<any> = [
    {key: 'FeeSimple', value: 'Fee Simple'},
    {key: 'Leasehold', value: 'Leasehold'},
  ];
  public static property_residence_types_ex: Array<any> = [
    {key: 'Investment', value: 'Investment'},
    {key: 'Other', value: 'Other'},
    {key: 'PrimaryResidence', value: 'Primary Residence'},
    {key: 'SecondHome', value: 'Second Home'}
  ];
  public static loan_purpose_codes_ex: Array<any> = [
    {key: 'MortgageModification', value: 'Mortgage Modification'},
    {key: 'Other', value: 'Other'},
    {key: 'Purchase', value: 'Purchase'},
    {key: 'Refinance', value: 'Refinance'},
    {key: 'Unknown', value: 'Unknown'}
  ];

  loan_purpose_explain: string;
  leasehold_expiration_date: Date;
  fha_secondary_residence: boolean;
  existing_energy_lien: boolean;
  mixed_use: boolean;
  expected_rental: string;
  loan_purpose_code_ex: string;
  property_residence_type_ex: string;
  property_current_residence_type_ex: string;
  ownership_type_ex: string;
  expected_net_rental: string;
  properties_count: string;
  construction_loan_indicator: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'leasehold_expiration_date' && object['leasehold_expiration_date']) {
        const date = moment(object[prop], 'YYYYMMDD');
        this[prop] = date.toDate();
      } else if (prop === 'fha_secondary_residence'
        || prop === 'existing_energy_lien'
        || prop === 'construction_loan_indicator'
        || prop === 'mixed_use') {
        this[prop] = super.getBooleanProp(prop, object);
      } else if (prop === 'expected_rental' || prop === 'expected_net_rental' || prop === 'properties_count') {
        this[prop] = super.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getLoanPurpose(): string {
    const found = LoanPurpose.loan_purpose_codes_ex.find((elt) => elt.key === this.loan_purpose_code_ex);
    return found ? found['value'] : '';
  }

  getOwnershipType(): string {
    const found = LoanPurpose.ownership_types_ex.find((elt) => elt.key === this.ownership_type_ex);
    return found ? found['value'] : '';
  }

  getPropertyResidenceType(): string {
    const found = LoanPurpose.property_residence_types_ex.find((elt) => elt.key === this.property_residence_type_ex);
    return found ? found['value'] : '';
  }

  getPropertyCurrentResidenceType(): string {
    const found = LoanPurpose.property_residence_types_ex.find((elt) => elt.key === this.property_current_residence_type_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      loan_purpose_code_ex: this.loan_purpose_code_ex,
      loan_purpose_explain: this.loan_purpose_explain,
      property_residence_type_ex: this.property_residence_type_ex,
      property_current_residence_type_ex: this.property_current_residence_type_ex,
      ownership_type_ex: this.ownership_type_ex,
      leasehold_expiration_date: this.toServerDate(this.leasehold_expiration_date),
      fha_secondary_residence: ModelBase.toServerExplicitBoolean(this.fha_secondary_residence),
      existing_energy_lien: ModelBase.toServerExplicitBoolean(this.existing_energy_lien),
      mixed_use: ModelBase.toServerExplicitBoolean(this.mixed_use),
      expected_rental: this.toServerDecimal(this.expected_rental),
      expected_net_rental: this.toServerDecimal(this.expected_net_rental),
      properties_count: this.toServerString(this.properties_count),
      construction_loan_indicator: ModelBase.toServerExplicitBoolean(this.construction_loan_indicator),
    };
  }
}
