import {Component, Injector, Input, OnInit} from '@angular/core';
import {Construction} from '../../../../models/construction';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-construction',
  templateUrl: './view-segment-construction.component.html',

})
export class ViewSegmentConstructionComponent extends CompBaseComponent implements OnInit {
  @Input() item: Construction;

  public isConstructionLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.isConstructionLoan = this.analyzerService.isConstructionLoan(this.doc.recursive_attributes);
  }
}
