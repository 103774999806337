import {Component, Injector, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {Router} from '@angular/router';
import {InvoiceService} from '../../../../services/invoice.service';
import {CC} from '../../../../models/cc';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-auth-payment',
  templateUrl: './auth-payment.component.html',
})
export class AuthPaymentComponent extends CompBaseComponent implements OnInit {
  private invoiceService: InvoiceService;
  public error: boolean;
  public reloaded: boolean;
  public cc: CC;

  // form and form controls
  form: FormGroup;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.invoiceService = this.injector.get(InvoiceService);
    const cc = this.invoiceService.getCC();
    this.cc = this.invoiceService.getCC();
    this.error = !this.cc;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  createFormControls() {
  }

  createForm() {
    this.form = new FormGroup({});
  }

  authorizeAndPay(): void {
    if (!this.form.valid) {
      return;
    }
    this.invoiceService.payInvoice(this.cc.invoice_id, this.cc)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Invoice successfully paid');
        this.invoiceService.resetCurrentInvoice();
        return this.userService.refreshUser();
      })
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
        this.router.navigate(['/account/invoices']);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to pay invoice', data);
      });
  }

  cancel(): void {
    this.invoiceService.resetCurrentInvoice();
    this.router.navigate(['/account/invoices']);
  }
}
