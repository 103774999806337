import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Agreement} from '../../../../models/agreement';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';

@Component({
  selector: 'app-edit-segment-agreement',
  templateUrl: './edit-segment-agreement.component.html',
})
export class EditSegmentAgreementComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Agreement;

  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;

  // form and form controls
  form: FormGroup;
  signature_date: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'agreement';
    this.section = this.applicant;
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.signature_date = new FormControl(item.signature_date, AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      signature_date: this.signature_date,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Agreement(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();
    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
