import {Component, Injector, OnInit} from '@angular/core';
import {GiftComponent} from '../gift/gift.component';
import {Gift} from '../../../../models/gift';

@Component({
  selector: 'app-add-gift',
  templateUrl: '../gift/edit-segment-gift.component.html',
})
export class AddGiftComponent extends GiftComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Gift({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
