import {Component, Injector, OnInit} from '@angular/core';
import {McrComponent} from '../mcr/mcr.component';
import {FormUtil} from '../../../../form-util';
import {Router} from "@angular/router";

@Component({
  selector: 'app-edit-segment-mcr',
  templateUrl: './edit-segment-mcr.component.html',
})
export class EditSegmentMcrComponent extends McrComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  close(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Reload'});
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    this.setRequiredParams();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  onParamChange(event: any): void {
    this.setRequiredParams();
  }

  setRequiredParams(): void {
    this.isParamRequired = this.isRepurchased.value === false;
    this.isEndDateRequired = this.isParamRequired && this.loanDispositionCode.value == 'AC070';
    this.isHECMRequired = this.isParamRequired && this.isHECM.value === true;
  }

  delete(): void {
    this.mcrService.deleteMcr(this.mcr.id).then(() => {
      FormUtil.genSuccessMessage(this.sharedService, 'MCR successfully deleted');
    }).then(() => {
      return this.tranService.getCurrentTran(true);
    }).then(() => {
      // update may be called from loan/tools or from mcr. The backlink is used to go back to the MCR Wizard
      if (this.backLink && (this.backLink.length > 0)) {
        this.injector.get(Router).navigate([this.backLink]);
      } else {
        this.eventEmitted.emit({type: 'Reload'});
      }
    }).catch((data) => {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to delete MCR', data);
    });
  }
}
