import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Permission} from '../../../models/permission';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {FormUtil} from '../../form-util';
import {PermissionService} from '../../../services/permission.service';
import {User} from '../../../models/user';
import {ModelBase} from '../../../models/model-base';
import {OrganizationService} from '../../../services/organization.service';

@Component({
  selector: 'app-permission',
  template: '',
})
export class PermissionComponent extends CompBaseComponent implements OnInit {
  @Input() item: Permission;
  @Input() resource_type: string;
  @Input() public resource: any;
  @Input() public tran: Tran;
  public orgService: OrganizationService;
  public resource_id: number;
  public user: User;
  public contacts: Array<any>;
  public submitted: boolean;
  public currentContact: any = null;

  public form: FormGroup;
  public acl_permission: FormControl;
  public dir_permission: FormControl;
  public contact: FormControl;
  public recursive: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.orgService = this.injector.get(OrganizationService);
    this.user = this.userService.getUserSync();
    this.contacts = this.user.getContactInfos(this.getRoles(this.resource_type));
    switch (this.resource_type) {
      case 'transaction':
      case 'workflow':
        this.resource_id = this.tran.id;
        break;
      case 'document':
      case 'mcr':
        this.resource_id = this.resource.id;
        break;
      default:
        this.resource_id = null;
    }
    this.submitted = false;
  }

  createFormControls(item: Permission): void {
    const dirPermission: boolean = (item.acl_permission & ModelBase.PERMISSION.DIR) > 0;
    const aclPermission = item.acl_permission & ~ModelBase.PERMISSION.DIR;
    this.acl_permission = new FormControl(aclPermission, AppValidators.present);
    this.dir_permission = new FormControl(dirPermission);
    this.contact = new FormControl('');
    this.recursive = new FormControl(false);
  }

  createForm(): void {
    this.form = new FormGroup({
      acl_permission: this.acl_permission,
      contact: this.contact,
      recursive: this.recursive,
      dir_permission: this.dir_permission
    });
  }

  delete(event: any): void {
    event.preventDefault();
    const payload = {
      resource_type: this.resource_type,
      resource_id: this.resource_id,
      access: 0,
      recursive: true
    };

    this.permissionService.updatePermission(this.item.acl_user_id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Successfully deleted user access');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to deleted user access', data);
        this.cancel();
      });
  }

  add(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const payload = {
      resource_type: this.resource_type,
      resource_id: this.resource_id,
      access: this.computeAccess(),
      recursive: this.recursive.value
    };

    this.permissionService.updatePermission(+this.contact.value, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Successfully added user access');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to add user access', data);
        this.cancel();
      });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const payload = {
      resource_type: this.resource_type,
      resource_id: this.resource_id,
      access: this.computeAccess(),
      recursive: this.recursive.value
    };

    this.permissionService.updatePermission(this.item.acl_user_id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Successfully updated user access');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update user access', data);
        this.cancel();
      });
  }

  computeAccess(): number {
    let access = +this.acl_permission.value;
    if (this.resource_type === 'transaction') {
      if (this.dir_permission.value === true) {
        access |= ModelBase.PERMISSION.DIR;
      } else {
        access &= ~ModelBase.PERMISSION.DIR;
      }
    }
    return access;
  }

  checkReadNoWrite(): boolean {
    return PermissionService.checkReadNoWrite(+this.acl_permission.value);
  }

  checkReadAndWrite(): boolean {
    return PermissionService.checkReadAndWrite(+this.acl_permission.value);
  }

  onSelectChange($event: any): void {
    this.currentContact = this.contacts.find((elt) => elt.key === this.contact.value);
  }

  isContact(what: string): boolean {
    switch (what) {
      case 'borrower':
        return this.currentContact && ((this.currentContact.roles & User.ROLES.BORROWER) > 0);
      case 'loan_professional':
        return this.currentContact && (
          ((this.currentContact.roles & User.ROLES.LOAN_OFFICER) > 0) ||
          ((this.currentContact.roles & User.ROLES.PROCESSOR) > 0) ||
          ((this.currentContact.roles & User.ROLES.LOAN_ASSISTANT) > 0)
        );
      default:
        return false;
    }
  }

  public getRoles(resource_type: string): number {
    switch (resource_type) {
      case 'document':
      case 'loan':
        return ModelBase.ROLES.ADMIN + ModelBase.ROLES.LOAN_OFFICER + ModelBase.ROLES.PROCESSOR + ModelBase.ROLES.BORROWER + ModelBase.ROLES.LOAN_ASSISTANT;
      case 'transaction':
        return ModelBase.ROLES.ADMIN + ModelBase.ROLES.LOAN_OFFICER + ModelBase.ROLES.PROCESSOR + ModelBase.ROLES.BORROWER + ModelBase.ROLES.REAL_ESTATE_AGENT + ModelBase.ROLES.LOAN_ASSISTANT;
      case 'workflow':
        return ModelBase.ALL_ROLES;
      case 'mcr':
        return ModelBase.ALL_ROLES;
      default:
        return ModelBase.ROLES.NONE;
    }
  }
}
