import {Component, OnInit} from '@angular/core';
import {io} from 'socket.io-client';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-socket-messenger',
  templateUrl: 'socket-messenger.component.html',
})
export class SocketMessengerComponent implements OnInit {
  public socketMessages = []
  public reloaded: boolean;
  public socketConnected: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    const socket = io();
    const email = this.userService.getUserSync().email;
    socket.on('connect', () => {
      this.socketConnected = true
      console.log('===> client connected');
      socket.emit('info', {email: email})
    });
    socket.on('message', (messageObj) => {
      this.reloaded = false
      this.socketMessages.push(messageObj['message']);
      setTimeout(() => {
        this.reloaded = true;
      }, 0)
    });
    setTimeout(() => {
      if (!this.socketConnected) {
        console.log('===> client failed to connect to server socket');
        socket.disconnect();
      }
    }, 3000)
  }

  onClose(): void {
    this.reloaded = false;
    // delete all messages on message window's close event
    this.socketMessages.splice(0, this.socketMessages.length);
    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }
}
