<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating" class="w-100">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date" required>
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Document date is unrealistic
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Document date format is invalid
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('required')">
          Document date is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genCreditAuth()" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</form>
