import {Component, Injector, OnInit} from '@angular/core';
import {LoanData} from '../../../../models/loan-data';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-loan-data',
  templateUrl: './view-loan-data.component.html',
})
export class ViewLoanDataComponent extends CompBaseComponent implements OnInit {
  public item: LoanData;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new LoanData(this.doc.recursive_attributes['loan_data']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
