import {Component, Injector, OnInit} from '@angular/core';
import {CustomerComponent} from "../customer/customer.component";

@Component({
  selector: 'app-view-segment-customer',
  templateUrl: './view-segment-customer.component.html',
})
export class ViewSegmentCustomerComponent extends CustomerComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }
}
