<mat-card *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'My MCRs'" [help]="true" [op]="'add'">
      <p>
        The MCR list below includes all quarters against which you can run an MCR report.
      </p>
      <ul>
        <li>To start working on a specific MCR, select it from the list.</li>
        <li>To add a new quarter click the Add (plus) button and supply quarter and year.</li>
        <li>To delete an MCR, select it and then click Manage MCR. The Delete function is then available.</li>
      </ul>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>
    <app-box *ngIf="mcrs.length===0" [close]="true" [type]="'warning'" class="pt-2">
      <strong>No MCRs!</strong>
    </app-box>

    <div *ngIf="user.hasOrg()">
      <form [hidden]="mode!=='Add'" [formGroup]="form" novalidate>
        <div class="row mt-3">
          <!-- quarter -->
          <div class="col-lg-4">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>MCR Quarter</mat-label>
              <mat-select formControlName="latestQuarter" placeholder="Select MCR quarter" required>
                <mat-option *ngFor="let qr of quarters" [value]="qr.key">{{qr.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="latestQuarter.hasError('required')">
                MCR quarter is required
              </mat-error>
            </mat-form-field>
          </div>

          <!-- year -->
          <div class="col-lg-4">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>MCR Year</mat-label>
              <mat-select formControlName="latestYear" placeholder="Select MCR year" required>
                <mat-option *ngFor="let yr of years" [value]="yr.key">{{yr.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="latestYear.hasError('required')">
                MCR year is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-lg-6">
            <button (click)="add()" [disabled]="!form.valid" class="btn btn-lg btn-primary" aria-label="Add">Add</button>
            <button (click)="cancel()" class="ms-2 btn btn-lg btn-light" aria-label="Cancel">Cancel</button>
          </div>
        </div>
      </form>

      <div *ngIf="mcrs.length> 0" class="row pt-1 pb-1 fw-bold border-bottom">
        <div [hidden]="isMobile" class="col col-lg-1">ID</div>
        <div class="col col-lg-2">Period</div>
        <div [hidden]="isMobile" class="col col-lg-2">Created</div>
        <div class="col col-lg-2">Modified</div>
      </div>

      <div (click)="navigateToMcr(mcr)" *ngFor="let mcr of mcrs" class="hand-pointer row pt-1 pb-1 hover-border border-bottom">
        <div [hidden]="isMobile" class="col col-lg-1">{{mcr.id}}</div>
        <div class="col col-lg-2">
          Q{{mcr.quarter}} {{mcr.year}}
        </div>
        <div [hidden]="isMobile" class="col col-lg-2">{{mcr.created_at | date:'MM/dd/yyyy'}}</div>
        <div class="col col-lg-2">{{mcr.updated_at | date:'MM/dd/yyyy'}}</div>
      </div>
    </div>
    <div *ngIf="!user.hasOrg()">
      <app-box [close]="true" [type]="'warning'" class="pt-2">
        Your organization was not created. You can create an organization at
        <a class="pr-blue-bold" routerLink="/account/user-organization">Account/Organization</a>.
      </app-box>
    </div>
  </mat-card-content>
</mat-card>
