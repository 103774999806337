import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {AdminService} from '../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormUtil} from "../../form-util";
import {FormControl,FormGroup} from "@angular/forms";

@Component({
  selector: 'app-admin-organization',
  template: '',
})
export class OrganizationComponent extends CompBaseComponent implements OnInit {
  public op: string;
  public orgId: number;
  public adminService: AdminService;
  public route: ActivatedRoute;
  public router: Router;
  // form and form controls
  form: FormGroup;
  name: FormControl;
  description: FormControl;
  nmls: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    this.adminService.createOrganization(payload)
        .then(() => {
          FormUtil.genSuccessMessage(this.sharedService, 'Organization successfully created');
          this.router.navigate(['/admin/organization-list'], {queryParams: {name: this.name.value}});
          return;
        })
        .catch((data) => {
          FormUtil.genErrorMessage(this.sharedService, 'Failed to create organization', data);
        });
  }
}
