<form [formGroup]="form" novalidate>
  <div>
    <mat-checkbox formControlName="dispAssumptions">Assumptions</mat-checkbox>
    <mat-checkbox formControlName="dispFinancials">Financial Report</mat-checkbox>
    <mat-checkbox formControlName="dispMulti">Multi Charts</mat-checkbox>
    <mat-checkbox formControlName="dispSelectAll" (change)="onChangeDispAll()">Select All</mat-checkbox>
  </div>
  <div>
    <mat-checkbox formControlName="dispRevenueLOS">LOS Revenue</mat-checkbox>
    <mat-checkbox formControlName="dispRevenueLoanMarketplace">Marketplace Revenue</mat-checkbox>
    <mat-checkbox formControlName="dispRevenue">Total Revenue</mat-checkbox>
  </div>
  <div>
    <mat-checkbox formControlName="dispExpenseDevLOS">Development: LOS</mat-checkbox>
    <mat-checkbox formControlName="dispExpenseDevLoanMarketplace">Development: Marketplace</mat-checkbox>
    <mat-checkbox formControlName="dispExpenseMarketing">Marketing Expense</mat-checkbox>
    <mat-checkbox formControlName="dispExpenseSales">Sales Expense</mat-checkbox>
    <mat-checkbox formControlName="dispExpenseGNA">G&A Expense</mat-checkbox>
    <mat-checkbox formControlName="dispExpense">Total Expense</mat-checkbox>
  </div>
  <div>
    <mat-checkbox formControlName="dispIncome">Income</mat-checkbox>
    <mat-checkbox formControlName="dispTax">Tax</mat-checkbox>
    <mat-checkbox formControlName="dispNetIncome">Net Income</mat-checkbox>
    <mat-checkbox formControlName="dispCumulative">Cumulative Cash Flow</mat-checkbox>
  </div>

  <div *ngIf="dispAssumptions.value">
    <div class="row">
      <!--    LOS Revenue   -->
      <div class="col-4">
        <h5>LOS Revenue</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Max LOs</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="maxLOs" matInput placeholder="Max LOs">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Charge per LO/month</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="chargeLO" matInput placeholder="Charge per LO/month">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Shift in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="losRevenueShift" matInput placeholder="Shift in months">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Length in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="losRevenueMonths" matInput placeholder="Length in months">
          </div>
        </div>
      </div>

      <!--    Development: LOS   -->
      <div class="col-4">
        <h5>Development: LOS</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Dev counts</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="losExpenseCounts" matInput placeholder="Counts">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Cost dev/month</mat-label>
          </div>
          <div class="col-6">
            <input formControlName="losCostPerMonth" matInput placeholder="Cost per month">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Shift in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="losExpenseShift" matInput placeholder="Shift in months">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Length in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="losExpenseMonths" matInput placeholder="Length in months">
          </div>
        </div>
      </div>

      <!--    G & A   -->
      <div class="col-4">
        <h5>G&A</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Min G&A ($)</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="minGNA" matInput placeholder="Min G&A">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>G&A Percentage</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="GNAPercentage" matInput placeholder="G&A percentage">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Target inflection revenue per year</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="targetInflectionRevenueYear" matInput placeholder="Inflection point revenue">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--    Marketplace Revenue   -->
      <div class="col-4">
        <h5>Marketplace Revenue</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Max Txs percentage</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="maxTxsPercent" matInput placeholder="Max Txs (%)">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Charge per Tx</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="chargeTx" matInput placeholder="Charge per Tx">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Shift in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceRevenueShift" matInput placeholder="Shift in months">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Length in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceRevenueMonths" matInput placeholder="Length in months">
          </div>
        </div>
      </div>

      <!--    Development: Marketplace   -->
      <div class="col-4">
        <h5>Development: Marketplace</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Dev counts</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceExpenseCounts" matInput placeholder="Counts">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Cost dev/month</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceCostPerMonth" matInput placeholder="Cost per month">
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Shift in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceExpenseShift" matInput placeholder="Shift in months">
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Length in months</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketplaceExpenseMonths" matInput placeholder="Length in months">
          </div>
        </div>
      </div>

      <!--    Marketing Expense   -->
      <div class="col-4">
        <h5>Marketing</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Marketing Expense (percent)</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="marketingPercentage" matInput placeholder="Marketing percentage">
          </div>
        </div>

        <h5>Sales</h5>
        <div class="row mb-2">
          <div class="col-6 my-auto">
            <mat-label>Sales Expense (percent)</mat-label>
            <br>
          </div>
          <div class="col-6">
            <input formControlName="salesPercentage" matInput placeholder="Marketing percentage">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button (click)="onSubmit()" class="btn btn-sm btn-primary" [disabled]="form.invalid">Submit</button>
      </div>
    </div>
  </div>
</form>

<div *ngIf="computed" style="width: 1690px;">
  <div [hidden]="!dispExpenseDevLOS.value">
    <h3 class="fin-header">Development: LOS</h3>
    <div echarts [options]="chartExpenseDevLOS" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispExpenseDevLoanMarketplace.value">
    <h3 class="fin-header">Development: Marketplace</h3>
    <div echarts [options]="chartExpenseDevLoanMarketplace" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispExpenseMarketing.value">
    <h3 class="fin-header">Marketing Expense</h3>
    <div echarts [options]="chartExpenseMarketing" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispExpenseSales.value">
    <h3 class="fin-header">Sales Expense</h3>
    <div echarts [options]="chartExpenseSales" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispExpenseGNA.value">
    <h3 class="fin-header">G&A Expense</h3>
    <div echarts [options]="chartExpenseGNA" class="prudent-chart"></div>
  </div>

  <div *ngIf="dispExpense.value">
    <h3 class="fin-header">Total Expenses</h3>
    <div>
      <div echarts [options]="chartExpense" class="prudent-chart"></div>
    </div>
  </div>

  <div [hidden]="!dispRevenueLOS.value">
    <h3 class="fin-header">LOS Revenue</h3>
    <div echarts [options]="chartRevenueLOS" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispRevenueLoanMarketplace.value">
    <h3 class="fin-header">Marketplace Revenue</h3>
    <div echarts [options]="chartRevenueLoanMarketplace" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispRevenue.value">
    <h3 class="fin-header">Total Revenues</h3>
    <div echarts [options]="chartRevenue" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispIncome.value">
    <h3 class="fin-header">Income</h3>
    <div echarts [options]="chartIncome" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispTax.value">
    <h3 class="fin-header">Tax</h3>
    <div echarts [options]="chartTax" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispNetIncome.value">
    <h3 class="fin-header">Net Income</h3>
    <div echarts [options]="chartNetIncome" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispMulti.value">
    <h3 class="fin-header">Revenues, Expenses, Income, Tax for the First Three years</h3>
    <div echarts [options]="chartMulti" class="prudent-chart"></div>
  </div>

  <div [hidden]="!dispCumulative.value">
    <h3 class="fin-header">Cumulative Cash Flow</h3>
    <div echarts [options]="chartCumulative" class="prudent-chart"></div>
    <h3>Min Cumulative Cash Flow Projection</h3>
    <div>
      {{minCashFlow | currency}}
    </div>
  </div>

  <div [hidden]="!dispFinancials.value">
    <h3>Financial Report for Five Years</h3>
    <table class="table">
      <thead class="thead-light">
      <tr>
        <th scope="col">&nbsp;</th>
        <th scope="col" *ngFor="let i of arr" class="text-end">Q{{i}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">Revenue</th>
        <td *ngFor="let val of report['revenue']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Expense</th>
        <td *ngFor="let val of report['expense']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Income</th>
        <td *ngFor="let val of report['income']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Tax</th>
        <td *ngFor="let val of report['tax']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Net Income</th>
        <td *ngFor="let val of report['net_income']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Cumulative</th>
        <td *ngFor="let val of report['cumulative']" class="text-end">{{Math.round(val).toLocaleString()}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div [hidden]="!dispFinancials.value" class="w-50">
    <h3>Financial Report for Quarters 1 - 10</h3>
    <table class="table">
      <thead class="thead-light">
      <tr>
        <th scope="col">&nbsp;</th>
        <th scope="col" *ngFor="let i of arr1" class="text-end">Q{{i + 1}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">Revenue</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['revenue'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Expense</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['expense'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Income</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['income'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Tax</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['tax'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Net Income</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['net_income'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Cumulative</th>
        <td *ngFor="let i of arr1" class="text-end">{{Math.round(report['cumulative'][i]).toLocaleString()}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div [hidden]="!dispFinancials.value" class="w-50">
    <h3>Financial Report for Quarters 11 - 20</h3>
    <table class="table">
      <thead class="thead-light">
      <tr>
        <th scope="col">&nbsp;</th>
        <th scope="col" *ngFor="let i of arr2" class="text-end">Q{{i + 1}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">Revenue</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['revenue'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Expense</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['expense'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Income</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['income'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Tax</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['tax'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Net Income</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['net_income'][i]).toLocaleString()}}</td>
      </tr>
      <tr>
        <th scope="row">Cumulative</th>
        <td *ngFor="let i of arr2" class="text-end">{{Math.round(report['cumulative'][i]).toLocaleString()}}</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
