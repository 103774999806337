export const MENU_BILLING: any = [
  {
    title: 'Home',
    icon: {
      class: 'fa fa-home'
    },
    sub: [
      {
        title: 'Your Services',
        routing: '/home/services',
      },
      {
        title: 'Acct Highlights',
        routing: '/home/highlights',
      },
      {
        title: 'Branding',
        routing: '/home/lo-info',
        permission: 'CanCreateTransaction',
      },
    ]
  },
  {
    title: 'Account',
    icon: {
      class: 'fa fa-cog'
    },
    sub: [
      {
        title: 'Account Settings',
        routing: '/account/settings'
      },
      {
        title: 'Your Services',
        routing: '/account/services',
        permission: 'CanBeLoanProfessional'
      },
      {
        title: 'Status/Invoices',
        routing: '/account/invoices',
      },
      {
        title: 'Subscriptions',
        routing: '/account/subscriptions',
      },
      {
        title: 'Reset Password',
        routing: '/account/reset-password'
      },
    ]
  },
  // admin only functions
  {
    title: 'Admin',
    permission: 'CanCreateUser', // functions tha can only be accessed by admin
    hidden: true,
    icon: {
      class: 'fa fa-user'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'Config',
        routing: '/admin/configs',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Trans',
        routing: '/admin/find-trans',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Orders',
        routing: '/admin/find-orders',
        permission: 'CanCreateUser'
      },
      {
        title: 'New User',
        routing: '/admin/new-user',
        permission: 'CanCreateUser'
      },
      {
        title: 'Delete User',
        routing: '/admin/delete-user',
        permission: 'CanCreateUser'
      },
      {
        title: 'Reset Password',
        routing: '/admin/reset-pw',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Users',
        routing: '/admin/find-users',
        permission: 'CanCreateUser'
      },
      {
        title: 'Alerts',
        routing: '/admin/alerts',
        permission: 'CanCreateUser'
      },
      {
        title: 'New Organization',
        routing: '/admin/new-organization',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Organizations',
        routing: '/admin/find-organizations',
        permission: 'CanCreateUser'
      },
      {
        title: 'Invoices',
        routing: '/admin/invoices',
        permission: 'CanCreateUser'
      },
    ]
  },
];
