import {Component, Injector, OnInit} from '@angular/core';
import {McrComponent} from '../mcr/mcr.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-segment-mcr',
  templateUrl: './view-segment-mcr.component.html',
})
export class ViewSegmentMcrComponent extends McrComponent implements OnInit {
  constructor(public injector: Injector, private router: Router) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  back(): void {
    this.router.navigate(['/mcr/view-wizard']);
  }
}
