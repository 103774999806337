import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {FolderGroup} from '../../../models/folder-group';
import {Folder} from '../../../models/folder';

@Component({
  selector: 'app-view-document-system',
  templateUrl: './view-document-system.component.html',
})
export class ViewDocumentSystemComponent extends CompBaseComponent implements OnInit {
  public unsortedFolder: Folder;
  public folderGroups: Array<FolderGroup>;
  public canAccessDirectory: boolean;

  constructor(public injector: Injector) {
    super(injector);
    const unsortedFolderObj = {
      display: '',
      description: '',
      name: 'Unsorted Documents',
      id: 'US',
      selected: true,
      stock: true,
      status: 1
    };
    this.unsortedFolder = new Folder(unsortedFolderObj);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.canAccessDirectory = this.userService.getUserSync().canAccessDirectory();
    this.tranService.getCurrentTran(false)
      .then((tran) => {
        // folder group names not including 'US' (the unsorted folder)
        this.folderGroups = FolderGroup.deserializeArray(tran.gcns);
        this.tran = tran;
        this.reloaded = true;
      });
  }
}
