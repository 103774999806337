<mat-card>
  <mat-card-title>Find Orders</mat-card-title>
  <mat-card-content>
    <form (submit)="findOrders()" [formGroup]="form">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="user_id" matInput placeholder="User ID" appAutoFocus>
            <mat-error *ngIf="userId.hasError('required')">
              User ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button class="btn btn-lg btn-primary" type="submit">Find Orders</button>
      <button (click)="reset()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
    </form>
  </mat-card-content>
</mat-card>
