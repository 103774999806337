import {ModelBase} from './model-base';

export class SecEmployment extends ModelBase {
  index: number;
  employer_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  zip_four: string;
  country: string;
  self_employed: boolean;
  from_date: Date;
  to_date: Date;
  position: string;
  monthly_income: string;
  business_phone: string;
  seasonal_income_indicator: boolean;
  foreign_income_indicator: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'business_phone') {
        this[prop] = this.getPhoneProp(prop, object);
      } else if (prop === 'from_date' || prop === 'to_date') {
        this[prop] = this.getDateProp(prop, object);
      } else if (prop === 'self_employed' || prop === 'seasonal_income_indicator' || prop === 'foreign_income_indicator') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'monthly_income') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<SecEmployment> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new SecEmployment(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getFullAddress(): string {
    return super.getFullAddress(this.address, this.address2, this.city, this.state, this.zip, this.zip_four, this.country);
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      employer_name: this.employer_name,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      zip_four: this.zip_four,
      country: this.country,
      self_employed: ModelBase.toServerExplicitBoolean(this.self_employed),
      position: this.position,
      business_phone: this.business_phone,
      from_date: this.toServerDate(this.from_date),
      to_date: this.toServerDate(this.to_date),
      monthly_income: this.toServerDecimal(this.monthly_income),
      seasonal_income_indicator: ModelBase.toServerExplicitBoolean(this.seasonal_income_indicator),
      foreign_income_indicator: ModelBase.toServerExplicitBoolean(this.foreign_income_indicator),
    };
  }
}
