<mat-card class="mt-1 mb-2">
  <mat-card-title>
    Instructions for Creating Your Organization's Mortgage Call Report (MCR)
  </mat-card-title>
  <mat-card-content>
    <p>
      The following instructions walk you through the process of creating your organization's Mortgage Call Report (MCR) by the Organization Admin.
      To avoid going back and forth, perform the following steps in the order they are presented.
      Steps 1&ndash;5 are performed once per organization.
    </p>

    <b>Step 1: Designate Your Organization Admin</b>
    <p>
      Designate one person from your organization as your
      <b>Organization Admin</b>.
      The Organization Admin performs the next steps.
    </p>

    <b>Step 2: Verify Account Settings</b>
    <p>
      In
      <a class="pr-dark-blue fw-bold" routerLink="/account/settings" aria-label="Account Settings">Account Settings</a>,
      verify that your role is "loan officer."
      If not, contact PrudentCO.
    </p>

    <b>Step 3: Edit Your MLO Configuration</b>
    <p>
      Navigate to
      <a class="pr-dark-blue fw-bold" routerLink="/account/mlo-configuration" aria-label="Account Settings">Account/MLO Configuration</a>
      and fill out your and your
      <b>company's NMLS IDs</b>.
      The company's NMLS ID is key to creating your organization.
    </p>

    <b>Step 4: Create Your Organization</b>
    <p>
      Navigate to
      <a class="pr-dark-blue fw-bold" routerLink="/account/user-organization" aria-label="Account Settings">Account/Organization</a>
      and create your organization. Verify that the company's NMLS ID matches your company's NMLS ID (from the previous step).
    </p>

    <b>Step 5: Create Loan Officers</b>
    <p>
      Create your organization's loan officers at
      <a class="pr-dark-blue fw-bold" routerLink="/account/user-organization" aria-label="Account Settings">Account/Organization</a>.
      For each loan officer, provide their first and last names, email, NMLS ID, and the states where they and the company are licensed to operate.
      For the purpose of MCR generation, there is no need to provide state license numbers.
    </p>

    <b>Step 6: Main Processing - Create Loan MCRs</b>
    <p>
      This is the main step in creating your organization's MCR.
      Navigate to
      <a class="pr-dark-blue fw-bold" routerLink="/org/loan-mcrs" aria-label="Account Settings">Organization/Loan MCRs</a>.
      For each loan that was either started, in processing, closed or terminated during the reporting quarter, create a Loan MCR.
    </p>
    <ul>
      <li>
        Associate the Loan MCR with a loan officer.
      </li>
      <li>
        Give the Loan MCR a meaningful name.
      </li>
      <li>
        Click the Loan MCR record and provide the Mortgage Call Report (MCR) data for it.
      </li>
      <li>
        The "Previous loan amount" field only applies to a loan whose loan amount has changed since the previous quarter.
      </li>
      <li>
        The "Broker fees" field applies to a loan that was closed and funded during the reporting quarter.
      </li>
    </ul>

    <b>Step 7: Generate the Organization's MCR</b>
    <ul>
      <li>
        Navigate to
        <a class="pr-dark-blue fw-bold" routerLink="/org/mcr/list" aria-label="Account Settings">Organization/Org MCRs</a>
        and create an MCR for the reporting quarter.
      </li>
      <li>
        Click the MCR record to manage it.
      </li>
      <li>
        We suggest that you generate a report for each state where your organization operates.
        Click Report and enter the state abbreviation for the report.
      </li>
      <li>
        Browse the state report for any missing or extraneous information or something that looks out of the ordinary.
      </li>
      <li>
        If all state reports look okay, you can now safely generate the XML report and download it.
        Click the XML button. List all the state abbreviations where your company is licensed to operate.
      </li>
      <li>
        Click Generate XML.
      </li>
      <li>
        Click Download XML.
      </li>
      <li>
        Your XML file is now ready to upload to NMLS.
      </li>
    </ul>

    <b>Step 8: Upload the XML file to NMLS</b>
    <p>
      This is the final step.
      Log in to NMLS and create a new MCR record for the same reporting quarter as in the previous steps.
      Upload the XML file to the NMLS.
      If everything has gone smoothly so far, you should see your organization's MCR report laid out similarly to the state report you generated in Step 7.
      Amend comments as necessary, and finally submit the report.
    </p>
    <p>
      The user experience in NMLS could be challenging.
      For instance, after you have uploaded the MCR XML file, nothing happens, whereas, in fact, NMLS is processing the file.
      Be patient and try refreshing the page every few minutes.
      If that does not help, feel free to contact us. We will be glad to help.
    </p>
  </mat-card-content>
</mat-card>
