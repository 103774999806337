<div>
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<form [formGroup]="form" [hidden]="generating">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>MLO name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_name" matInput placeholder="MLO name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="mlo_name.hasError('required')">
          MLO name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>MLO NMLS ID</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="mlo_nmls" matInput placeholder="MLO NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
        <mat-error *ngIf="mlo_nmls.hasError('required')">
          MLO NMLS ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Company name</mat-label>
        <input [readonly]="true" class="text-primary" formControlName="company_name" matInput placeholder="Company name" maxlength="{{MAX_LEN.GEN_NAME}}">
        <mat-error *ngIf="company_name.hasError('required')">
          Company name is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Company NMLS ID</mat-label>
        <input [readonly]="true" class="text-primary" appNumberOnly formControlName="company_nmls" matInput placeholder="Company NMLS ID" maxlength="{{MAX_LEN.NMLS_ID}}">
        <mat-error *ngIf="company_nmls.hasError('required')">
          Company NMLS ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Loan amount</mat-label>
        <input [readonly]="true" class="text-primary" appDecimalOnly formControlName="loan_amount" matInput placeholder="Loan amount">
        <mat-error *ngIf="loan_amount.hasError('required')">
          Loan amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Broker compensation rate</mat-label>
        <input appDecimalThreeOnly formControlName="compensation_percent" matInput placeholder="Broker compensation rate" required>
        <mat-error *ngIf="compensation_percent.hasError('required')">
          Broker compensation rate is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Broker min. compensation amount</mat-label>
        <input appDecimalOnly formControlName="min_compensation_amount" matInput placeholder="Broker min compensation amount" required>
        <mat-error *ngIf="min_compensation_amount.hasError('required')">
          Broker min compensation amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Broker max. compensation amount</mat-label>
        <input appDecimalOnly formControlName="max_compensation_amount" matInput placeholder="Broker max compensation amount" required>
        <mat-error *ngIf="max_compensation_amount.hasError('required')">
          Broker max compensation amount is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="md-icon-left w-100">
        <mat-label>Broker compensation amount</mat-label>
        <input appDecimalOnly formControlName="broker_compensation" matInput placeholder="Broker compensation amount" required>
        <mat-error *ngIf="broker_compensation.hasError('required')">
          Broker compensation amount is required
        </mat-error>
        <mat-hint>
          computed compensation - {{getBrokerCompensation()}}
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 mb-3">
      <mat-radio-group aria-label="paid_by_lender_label" class="mt-2" formControlName="paid_by_lender" required>
        <mat-radio-button [checked]="paid_by_lender.value===true" [value]="true">Paid by lender</mat-radio-button>
        <mat-radio-button [checked]="paid_by_lender.value===false" [value]="false">Paid by borrower</mat-radio-button>
        <br/>
        <mat-hint>Paid by lender or borrower is required</mat-hint>
        <mat-error *ngIf="submitted&&paid_by_lender.hasError('required')">
          Paid by lender/broker is required
        </mat-error>
      </mat-radio-group>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="w-100">
        <mat-label>Document date</mat-label>
        <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="doc_date" matInput placeholder="Document date">
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="doc_date.hasError('matDatepickerMin') || doc_date.hasError('matDatepickerMax')">
          Document date is either too early or after today
        </mat-error>
        <mat-error *ngIf="doc_date.hasError('matDatepickerParse')">
          Date format is invalid
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button (click)="genMBFA()" [disabled]="!form.valid" class="btn btn-lg btn-primary">Save Data</button>
      <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
    </div>
  </div>
</form>
