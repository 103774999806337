<mat-card *ngIf="reloaded" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="isMobile ? 'Loan Originator Info' : 'Loan Originator Information'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Default values for this segment are taken from the loan officer's configuration (refer to MLO Configuration under Account).</li>
        <li>Verify and update fields as necessary.</li>
      </ul>

    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <app-box *ngIf="true" [close]="true" [type]="'primary'">
      <strong>State Licenses.</strong>
      Verify that the state licenses of the MLO and their mortgage company are of the state
      where the property is located:
      <span class="fw-bold">{{propertyState}}</span>.
    </app-box>

    <app-box *ngIf="receivedInFuture" [close]="true" [type]="'warning'">
      Received date is in the future.
    </app-box>

    <app-box *ngIf="originationInFuture" [close]="true" [type]="'warning'">
      Origination date is in the future.
    </app-box>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan originator name</div>
      <div class="col-lg-6">{{item.lo_name}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Phone number</div>
      <div class="col-lg-6">{{item.lo_phone_number | phone}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan application received date</div>
      <div class="col-lg-6">{{item.lo_received_date | date}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Origination date</div>
      <div class="col-lg-6">{{item.lo_orig_date | date}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company name</div>
      <div class="col-lg-6">{{item.lo_company_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company address</div>
      <div class="col-lg-6">{{item.lo_company_address}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company Apt/Unit/Suite</div>
      <div class="col-lg-6">{{item.lo_company_address2}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company city</div>
      <div class="col-lg-6">{{item.lo_company_city}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company state</div>
      <div class="col-lg-6">{{item.lo_company_state}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company zip</div>
      <div class="col-lg-6">{{item.lo_company_zip}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company zip four</div>
      <div class="col-lg-6">{{item.lo_company_zip_four}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan originator NMLS</div>
      <div class="col-lg-6">{{item.lo_nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan originator state license</div>
      <div class="col-lg-6">{{item.lo_state_license}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company NMLS</div>
      <div class="col-lg-6">{{item.lo_company_nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Company state license</div>
      <div class="col-lg-6">{{item.lo_company_state_license_number}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan originator email</div>
      <div class="col-lg-6">{{item.lo_email}}</div>
    </div>
  </mat-card-content>
</mat-card>
