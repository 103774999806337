import {ModelBase} from './model-base';

export class OrderLineItem extends ModelBase {
  static deserializeArray(arr: Array<any>): Array<OrderLineItem> {
    const result = [];
    for (const obj of arr) {
      const item = new OrderLineItem(obj);
      result.push(item);
    }
    return result;
  }

  id: number;
  order_type: number;
  status: number;
  subscription_term: number;
  services: number;
  amount: number;
  starts_on: string;
  expires_on: string;
  updated_at: Date;
  created_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getType(): string {
    switch(this.order_type) {
      case ModelBase.ORDER_TYPE.SUBSCRIPTION:
        return 'Subscription';
      case ModelBase.ORDER_TYPE.SERVICE:
        return 'Service';
    }
  }

  getStatus(): string {
    return ModelBase.getStatus(this.status);
  }

  getSubscriptionTerm(): string {
    switch(this.subscription_term) {
      case 1:
        return 'Monthly';
      case 3:
        return 'Quarterly';
      case 6:
        return 'Half-Yearly';
      case 12:
        return 'Yearly';
      default:
        return 'None';
    }
  }

  getServices(): string {
    return ModelBase.getServices(this.services);
  }
}
