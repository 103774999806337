import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../models/help';
import {FormUtil} from "../../form-util";
import {CacheManager} from "../../../models/cache-manager";

@Component({
  selector: 'app-view-loan-list',
  templateUrl: './view-loan-list.component.html',
})
export class ViewLoanListComponent extends CompBaseComponent implements OnInit, OnDestroy {
  static checkbox = {
    activeTrans: true,
    inactiveTrans: false,
    terminatedTrans: false,
    sandboxTrans: false
  };
  public trans: Array<Tran>;
  public filteredTrans: Array<Tran>;
  public pageTitle = 'My Loans';
  public form: FormGroup;
  public activeTrans: FormControl;
  public inactiveTrans: FormControl;
  public terminatedTrans: FormControl;
  public sandboxTrans: FormControl;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.loansList
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload(false);
    this.reloadOnSync();
  }

  createFormControls() {
    this.activeTrans = new FormControl(ViewLoanListComponent.checkbox.activeTrans);
    this.inactiveTrans = new FormControl(ViewLoanListComponent.checkbox.inactiveTrans);
    this.terminatedTrans = new FormControl(ViewLoanListComponent.checkbox.terminatedTrans);
    this.sandboxTrans = new FormControl(ViewLoanListComponent.checkbox.sandboxTrans);
  }

  createForm() {
    this.form = new FormGroup({
      activeTrans: this.activeTrans,
      inactiveTrans: this.inactiveTrans,
      terminatedTrans: this.terminatedTrans,
      sandboxTrans: this.sandboxTrans
    });
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.tranService.getTrans(force)
      .then((trans) => {
        this.trans = trans;
        this.filteredTrans = this.trans.filter((elt) => {
          return (this.isDebug ? true : elt.isLoanOfficerTran()) && (
            (ViewLoanListComponent.checkbox.activeTrans && elt.status === Tran.STATUS.ACTIVE) ||
            (ViewLoanListComponent.checkbox.inactiveTrans && elt.status === Tran.STATUS.INACTIVE) ||
            (ViewLoanListComponent.checkbox.terminatedTrans && elt.status === Tran.STATUS.COMPLETED) ||
            (ViewLoanListComponent.checkbox.sandboxTrans && elt.ttype === Tran.TTYPE.BORROWER)
          );
        });
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  onFilterChange(event: any): void {
    ViewLoanListComponent.checkbox.activeTrans = this.activeTrans.value;
    ViewLoanListComponent.checkbox.inactiveTrans = this.inactiveTrans.value;
    ViewLoanListComponent.checkbox.terminatedTrans = this.terminatedTrans.value;
    ViewLoanListComponent.checkbox.sandboxTrans = this.sandboxTrans.value;
    this.reload();
  }

  navigateToTran(tran: Tran): void {
    const user = this.userService.getUserSync();
    this.tranService.getTran(tran.id)
      .then(() => {
        this.eventService.eventEmitter.emit({
          type: 'Reload',
          component: 'trans'
        });
      })
      .then(() => {
        if (user.isLoanProfessional()) {
          this.router.navigate(['/loan/view-directory']);
          return;
        }
        if (user.isBorrower()) {
          this.router.navigate(['/loan/view-timeline']);
          return;
        }
        if (user.isRealEstateAgent()) {
          this.router.navigate(['/loan/view-timeline']);
          return;
        }
      });
  }

  delete(event: any, tran: Tran): void {
    event.preventDefault();
    if (confirm(`Are you sure you want to delete ${tran.name}?`) === false) {
      return;
    }

    this.tranService.deleteTran(tran.id)
      .then(() => {
        CacheManager.resetCache();
        FormUtil.genSuccessMessage(this.sharedService, 'Loan deleted');
        window.location.href = '/loans/list'
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete loan', data);
      });
  }
}
