<mat-card *ngIf="reloaded" class="mt-1 mb-1">
  <mat-card-title>Update Workflow</mat-card-title>

  <mat-card-content>
    <app-box *ngIf="!isCompDone" class="mt-3" [type]="'warning'">
      {{compMessage}}
    </app-box>

    <div *ngIf="workflow.started">
      <div class="row hover-gray pt-1 pb-1 border-bottom">
        <div class="col-lg-4 fw-bold">Current task</div>
        <div class="col-lg-8">{{currentTrace.state_display}}</div>
      </div>

      <div class="row hover-gray pt-1 pb-1 border-bottom">
        <div class="col-lg-4 fw-bold">Started at</div>
        <div class="col-lg-8">{{currentTrace.started_at | date:'medium'}}</div>
      </div>
    </div>

    <form [formGroup]="form" novalidate>
      <div class="row mt-3" [hidden]="workflow.ended">
        <div class="col">
          <mat-radio-group aria-labelby="event-label" class="radio-group" formControlName="event" required>
            <mat-label>Next task</mat-label>
            <mat-radio-button class="hover-gray" *ngFor="let event of permissibleEvents" [value]="event.key">{{event.value}}
            </mat-radio-button>
            <mat-error *ngIf="submitted && event.hasError('required')">
              Next task is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>

      <div [hidden]="workflow.ended">
        <p class="mb-3 mt-3">
          <span class="fw-bold">Set the started at date and time of the next task:</span><br/>
          <span>The default started at date and time below are derived from the previous tasks and compliance events performed.
          </span>
        </p>

        <div class="row mt-3">
          <div class="col-lg-4">
            <mat-form-field class="w-100">
              <mat-label>Task start date</mat-label>
              <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="started_at" matInput
                placeholder="Next task started at date" required>
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="started_at.hasError('required')">
                Started at date is required
              </mat-error>
              <mat-error *ngIf="started_at.hasError('matDatepickerMin') || started_at.hasError('matDatepickerMax')">
                Stated at date is unrealistic
              </mat-error>
              <mat-error *ngIf="started_at.hasError('matDatepickerParse')">
                Started at date format is invalid
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Task start time</mat-label>
              <mat-select formControlName="started_at_time" placeholder="Select time" required>
                <mat-option *ngFor="let time of timeFrames" [value]="time.key" class="dd-narrow">{{time.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="started_at_time.hasError('required')">
                Time is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <p *ngIf="permissions.length" class="mb-1 mt-1">
        <strong>Select who should be notified of the workflow update.</strong> (optional)
      </p>

      <div *ngFor="let permission of permissions; let i = index" formArrayName="permissions">
        <mat-checkbox class="hover-gray" [formControlName]="i">{{permission.acl_full_name}}</mat-checkbox>
      </div>

      <div *ngIf="!isCompDone">
        <p class="mb-1 mt-1 text-danger fw-bold">
          There are uncompleted compliance events:
        </p>

        <div class="text-danger">
          <mat-checkbox formControlName="forceWorkflow">
            <strong>Force update?</strong>
          </mat-checkbox>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button [hidden]="workflow.ended" (click)="update()" [disabled]="!isCompDone&&!forceWorkflow.value" class="btn btn-lg btn-primary">
            Update
          </button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>

      <div class="row mt-3" *ngIf="workflow.started">
        <div class="col-lg-12 fw-bold">
          You can undo this task by clicking the Undo Task button below.
        </div>
        <div class="col-lg-12">
          This will cause the current task to rewind to the previous task.
        </div>
      </div>

      <div class="row mt-2" *ngIf="workflow.started">
        <div class="col">
          <button (click)="undo()" class="btn btn-lg btn-warning">Undo Task</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
