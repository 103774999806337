import {Component, OnInit} from '@angular/core';
import {SharedService} from "../../layouts/shared-service";
import {globals} from "../globals/globals";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {
  public reloaded = false;
  public messages = [];

  constructor(private sharedService: SharedService) {
  }

  ngOnInit() {
    this.sharedService.eventEmitted.subscribe(
      event => {
        if (event.type === 'Message') {
          this.reloaded = false;
          if (event.level === globals.errorLevel.error || event.level === globals.errorLevel.success) {
            while(this.messages.length > 0) {
              this.messages.pop();
            }
          }
          this.messages.push(event);
          setTimeout(() => {
            this.reloaded = true;
          }, 0);
        }
      }
    );
  }

  onClose(messageId: any) {
    this.reloaded = false;
    this.messages.forEach((message, index) => {
      if (message.messageId == messageId) {
        this.messages.splice(index, 1);
      }
      setTimeout(() => {
        this.reloaded = true;
      }, 0);
    })
  }
}
