<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <div>
      Financial Report
      <div class="float-right">
        <a (click)="close($event)" *ngIf="isMobile" title="Close"><i class="text-dark fas fa-times-circle pr-font-large"></i></a>
        <button (click)="close($event)" *ngIf="!isMobile" class="btn btn-lg btn-primary">
          <i class="fas fa-times-circle"></i>&nbsp;Close
        </button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="fw-bold col" style="font-size: 16px">Principal, Interest, Taxes, Insurance<span *ngIf="!isMobile"> (PITI)</span>
          </div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Loan payment
            <span *ngIf="!isMobile">(principal and interest)</span>
          </div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyLoanPayment | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Real estate taxes</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyRealEstateTaxes | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Homeowners insurance</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyHazardInsurance | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Mortgage insurance</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyMortgageInsurance | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total PITI</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.piti | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Expenses and Payments</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Child support, alimony, job-related expenses</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlySupportExpenses | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Other monthly expenses</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyOtherExpenses | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Liability payments</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyLiabilityExpenses | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total expenses and payments</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.totalMonthlyExpensesAndPayments | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total monthly expenses</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.totalMonthlyExpenses | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Income</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Income (excluding rental income)</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyIncome | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Net real estate rental income</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.monthlyNetRealEstateIncome | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Expected net real estate income
            <span *ngIf="!isMobile">(new purchase only)</span>
          </div>
          <div class="col-lg-6 text-end">{{qualifyLoan.expectedMonthlyNetRentalIncome | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total monthly income</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.totalMonthlyIncome | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Assets</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Liquid assets</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.assets | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Real estate assets</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.realEstateAssets | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">Gifts</div>
          <div class="col-lg-6 text-end">{{qualifyLoan.gifts | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total assets</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.totalAssets | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Liabilities</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Total liabilities</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.liabilities | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Net Worth</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Net worth</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.netWorth | currency | minusSignToParens}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold" style="font-size: 16px;">Ratios</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Loan to Value (LTV)
            <span *ngIf="!isMobile" class="fw-normal">(loan amount / min of appraisal and purchase price)</span>
          </div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.loanToValue | percent:'1.2-2'}}</div>
        </div>

        <div *ngIf="!qualifyLoan.error" class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">Front Ratio</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.frontRatio | percent:'1.2-2'}}</div>
        </div>

        <div *ngIf="!qualifyLoan.error" class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6 fw-bold">DTI</div>
          <div class="col-lg-6 text-end fw-bold">{{qualifyLoan.backRatio | percent:'1.2-2'}}</div>
        </div>

        <div *ngIf="qualifyLoan.error" class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold">
            <span class="danger">Front Ratio Error (no income)</span>
          </div>
        </div>

        <div *ngIf="qualifyLoan.error" class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col fw-bold">
            <span class="danger">DTI Error (no income)</span>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
