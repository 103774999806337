import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Help} from '../../../../models/help';
import {Organization} from '../../../../models/organization';
import {User} from '../../../../models/user';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-agent-permissions',
  templateUrl: './view-agent-permissions.component.html',
})
export class ViewAgentPermissionsComponent extends CompBaseComponent implements OnInit {
  public user: User;
  public orgId: number;
  public organization: Organization;
  public pageTitle = 'Organization Permissions';
  public mode = 'View';

  constructor(public injector: Injector,
              public router: Router,
              public route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.route.paramMap.subscribe((map) => {
      this.orgId = map['params']['id'];
      this.reload();
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
