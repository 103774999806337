import {Pipe, PipeTransform} from '@angular/core';

// return the last 4 digits preceded by numbers masked by x's
@Pipe({
  name: 'ssn'
})
export class SsnPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }

    let number3: string;
    switch (value.length) {
      case 9:
        number3 = value.slice(5, 9); // 123456789
        break;
      case 11:
        number3 = value.slice(7, 11); // 123-45-6789
        break;
      default:
        return value;
    }

    return `xxx-xx-${number3}`;
  }
}
