import {Component, Injector, Input, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-view-segment-permissions',
  templateUrl: './view-segment-permissions.component.html',
})
export class ViewSegmentPermissionsComponent extends CompBaseComponent implements OnInit {
  // type is: transaction, workflow, document, loanApp
  @Input() public type: string;
  @Input() public resource_type: string;
  @Input() public resource: any;
  @Input() public tran: Tran;

  public title: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.resource) {
      console.log('BAD: no resource passed-in');
      return;
    }
    this.items = this.resource.permissions;
    switch (this.type) {
      case 'mcr':
        this.title = `MCR: ${this.resource.year} Q${this.resource.quarter}`;
        break;
      case 'transaction':
        this.title = `Loan: ${this.tran.name}`;
        break;
      case 'loan_app':
        this.title = this.resource.category === 0 ? `Loan application: <span class="pr-blue">${this.resource.name}</span> <small class="text-muted">(default)</small></span>` : `Loan App: ${this.resource.name}`;
        break;
      case 'document':
        if (this.resource.isImage()) {
          this.title = `Image: ${this.resource.name}`;
          break;
        }
        this.title = `Document: ${this.resource.name}`;
        break;
      case 'workflow':
        this.title = `Timeline: ${this.tran.name}`;
        break;
    }
  }
}
