import {Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild} from '@angular/core';
import {StickyNoteComponent} from '../sticky-note/sticky-note.component';

@Component({
  selector: 'app-view-sticky-note-segment',
  templateUrl: './view-sticky-note-segment.component.html',
})
export class ViewStickyNoteSegmentComponent extends StickyNoteComponent implements OnInit {
  @Output() eventEmitted = new EventEmitter();
  @ViewChild('stickyNoteElt', {static: true}) private segmentHeaderElement: ElementRef;
  public canUpdateComment = false;
  public alertType = 'warning';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.canUpdateComment = this.permissionService.execCan('CanUpdateComment', this.doc);
    this.comment = this.getComment();
  }
}
