import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {CacheService} from './cache.service';

@Injectable()
export class EmailService {

  constructor(private httpService: HttpService, private cacheService: CacheService) {
  }

  sendEmail(payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('emails', payload)
        .then((data) => {
          this.cacheService.resetSeg('message');
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createNotification(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post('emails/create_notification', payload)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateNotification(notification_id: number, description: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`emails/update_notification/${notification_id}`, {description: description})
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
