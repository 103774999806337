<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Branding Profile
  </mat-card-title>

  <mat-card-content>
    <p>
      The loan officer's branding data is used to display the loan officer profile.
      Refer to Home/Loan Officer to view the branding data in action.
      The loan officer's company logo and the loan officer portrait display at the top of the vertical menu.
    </p>

    <p>
      <span class="fw-bold">Logo.</span>
      The default logo is PrudentCO's.
      The loan officer can upload their company logo to replace the default logo.
      The uploaded logo should be of the landscape variety similar to PrudentCO's logo.
      It displays at the top of the vertical menu with height set to 70 pixels (70px).
    </p>

    <p>
      <span class="fw-bold">The default logo.</span>
      If you delete the current logo, the displayed logo will be PrudentCO's.
    </p>
  </mat-card-content>
</mat-card>
