<div class="pr-font-large mt-3">
  NOTICE: THIS IS A CONTRACT BINDING YOU TO THE TERMS CONTAINED HEREIN. BY MANIFESTING YOUR ASSENT TO THIS CONTRACT (WHETHER BY ESTABLISHING AN ACCOUNT, OR BY OTHERWISE ACCESSING OR UTILIZING THE PRUDENTCO SERVICES OFFERED BY PRUDENTCO THROUGH THE PRUDENTCO.COM WEBSITE) YOU ACCEPT AND AGREE TO THE OBLIGATIONS CREATED BY THIS CONTRACTUAL AGREEMENT IN THEIR ENTIRETY. YOU FURTHER ACKNOWLEDGE AND RECOGNIZE THAT THIS AGREEMENT IS BASED UPON GOOD AND VALUABLE CONSIDERATION AND YOU HEREBY ACKNOWLEDGE THE RECEIPT AND SUFFICIENCY OF THAT CONSIDERATION. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT UTILIZE THESE SERVICES.
</div>

<div class="legal-content pr-font-large mb-3">
  <h3 class="section-heading">I. Parties and Agreement:</h3>
  <p>
    This Contract is entered into between FrameLogic Corporation DBA PrudentCO ("PrudentCO," "us," "we," "our" or "ours"), a Colorado company, which has its principal place of business at 1942 Broadway Suite 314, Boulder, Colorado 80302, and you ("Customer," "you," "your," or "yours"),
    as the recipient or user of services rendered through
    our web-based <span>loan origination platform</span><span>application</span>
    available at my.prudentco.com (collectively, the "Parties"). If you purport to be acting on behalf of any other person, company, or entity, you hereby warrant and affirm that you have the actual authority to so act for that person, company, or entity, and agree to assume all responsibilities created by this Agreement if the person, company, or entity you purport to represent disclaims your representation or is found to have not acted through your actions. Capitalized terms used in this Agreement have the meaning provided in the Definitions section of this Agreement.
  </p>
  <p>
    By using this Service, accessing the PrudentCO website's Subscriber Areas, or utilizing the Services or Software made available through the PrudentCO website, you manifest your agreement to the terms contained in this Agreement and the Terms of Use and Privacy Policy made available on the PrudentCO website, as updated periodically. To the extent any conflict or difference exists between this Agreement and any Terms of Use or Privacy Policy contained on the PrudentCO website, this document shall control. PrudentCO reserves the right to update and change this Agreement from time to time without notice. Any new features implemented shall become subject to this Agreement. Continuing to use the PrudentCO services after any such changes or feature implementations shall constitute consent to such changes and acceptance of any new, changed, or modified terms or provisions.
  </p>
  <p>
    You may not alter, supplement, or amend this Agreement unless we agree in a written agreement signed by both you and a duly authorized representative of PrudentCO. Any attempt by you to alter, supplement, or amend this Agreement other than through a signed, written agreement (as described above) is invalid. If you do not agree to the terms of this Agreement, you may not utilize our services. Therefore, as set forth in this Agreement, the Parties agree as follows:
  </p>

  <h3 class="section-heading">II. Services Offered:</h3>
  <ol type="A">
    <li>
      <u>Loan Origination Services:</u> PrudentCO offers a complete suite of loan origination services through its flagship product known as POLOS (PrudentCO Online Loan Origination Software).
      All services are offered through a "Software-as-a-Service" model.
      Customer must agree to the applicable fee schedules (refer to Addendum A) and pricing options, and render payment before access will be granted.
    </li>
    <li>
      <u>ezUploads:</u>
      PrudentCO offers service providers a secure service for collecting customer.
      The service is offered through a "Software-as-a-Service" model.
    </li>
    <li>
      <u>Payment of Applicable Fees:</u> Upon payment of the access fee(s) applicable to the package(s) selected by Customer, which may include additional costs associated with (1) specific Customer needs, (2) Statements of Work, and (3) any late fees as set forth below, and upon creation of Access Credentials, Customer will be granted access to the PrudentCO services applicable to the package(s) selected by Customer and will be able to establish User accounts for the number of Users authorized by the specific package purchased by Customer. Payment must be received and verified before access will be granted. Unless otherwise agreed to by PrudentCO, all fees are due in advance and are payable in U.S. dollars. Customer is responsible for any taxes, assessments, or governmental fees associated with the subscription to and use of the PrudentCO services.
    </li>
    <li>
      <u>Complimentary Accounts:</u> Notwithstanding anything to the contrary herein, access to certain services is without charge if the Customer is a member in good standing of a participating trade association and has received a complimentary account for such services from a PrudentCO representative. Customer's access to the services will terminate immediately upon the earlier of (i) the termination of the PrudentCO's agreement with the applicable trade association, (ii) PrudentCO's termination of the complimentary program at PrudentCO's sole discretion, or (iii) the breach by Customer of any material provisions of this Terms of Service.
    </li>
    <li>
      <u>Service Levels and System Uptime:</u> PrudentCO will take commercially reasonable efforts to maintain system uptime and accessibility. Except as otherwise provided in the provisions of the specific package Specifications selected
      <span>and purchased</span>
      by Customer, however, PrudentCO makes no guarantee that there will be no breaks in service or that services will always be available. Except as provided in the provisions of the specific package selected
      <span>and purchased</span>
      by Customer, PrudentCO expressly disclaims any guarantee of or liability for any system failures, system downtime, system instability, or other accessibility problems and you agree to hold PrudentCO harmless for any such incidents.
    </li>
    <li>
      <u>Technical Support:</u> In order to address any technical issues, Customer agrees to follow the procedures set forth from time to time on the company website.
    </li>
  </ol>
  <h3 class="section-heading">III. Accounts, Duration, and Access to Services:</h3>
  <ol type="A">
    <li>
      <u>Access to Services:</u> Upon creation of Access Credentials for the applicable User account(s),
      PrudentCO grants to Customer, subject to all the terms and conditions of (1) this Agreement and (2) the specific package to which Customer subscribes,
      a non-exclusive, non-transferable, non-sublicensable license to access and use the PrudentCO Services via the Internet for the duration of the Term.
      Under no circumstances are any Customers or Users authorized to resell or divulge for profit or remuneration, or otherwise disclose regardless of profit or remuneration, to any third party any information obtained through the PrudentCO services.
    </li>
    <li>
      <u>Access to Services:</u> Upon payment of the fee(s) applicable to the package(s) selected by Customer, in the case of a paying Customer, and upon creation of Access Credentials for the applicable User account(s), PrudentCO grants to Customer, subject to all the terms and conditions of (1) this Agreement and (2) the specific package to which Customer subscribes, a non-exclusive, non-transferable, non-sublicensable license to access and use the PrudentCO Services via the Internet for the duration of the Term.
      Borrowing Customers ("Borrowers") may use the Service solely for personal or individual use.
      Under no circumstances are any Customers or Users authorized to resell or divulge for profit or remuneration, or otherwise disclose regardless of profit or remuneration, to any third party any information obtained through the PrudentCO services.
    </li>
    <li>
      <u>Duration and Late Fees:</u> The duration of this Agreement begins upon the granting of a license to a Customer to access the PrudentCO Services, as described in Section III, A (above), and shall continue for the minimum contract duration specified with regard to the applicable service package (the "Initial Term"). Upon expiration of the Initial Term, this Agreement shall renew automatically for an additional period of equal duration under the terms of the service package(s) previously chosen by Customer (each such renewal period shall be referred to as a "Renewal Term", and together with the Initial Term, the "Term") unless altered or cancelled by either party before the date of such automatic renewal. Payment for the Renewal Term will become due upon renewal. If Customer has previously provided a payment mechanism to satisfy applicable access fees or other fees or costs, Customer hereby authorizes PrudentCO to bill Customer through the previously provided payment mechanism unless Customer provides alternate payment mechanism prior to the beginning of the Renewal Term. If the previously-provided payment mechanism fails, or if Customer's account otherwise goes into arrears, Customer agrees that PrudentCO may assess late fees of 1.5% of the outstanding balance per month on any and all past-due amount(s). If payment is not rendered when due, PrudentCO is under no obligation to continue rendering services and may suspend, disable, or terminate the past-due account at its sole discretion and without prior notice to Customer.
    </li>
    <li>
      <u>Access Credentials:</u> Access credentials are the sole responsibility of the Customer. Customers and Users are encouraged to use long and complex passwords consisting of upper-case, lower-case, numeric, and special characters, that have not been previously used in any way elsewhere, to prevent unauthorized access in the event of a "brute force," "credential stuffing," or other forms of cyber attack. Customer assumes all risk and responsibility for creating passwords of sufficient length and complexity. Each User must have his or her own Access Credentials. The number of Users to whom access is granted (i.e., the number of Access Credentials authorized) is controlled entirely by the specific access package selected
      <span>and purchased</span>
      by the Customer. Access Credentials may not be disclosed to, shared with, or distributed to others, and you agree to take full responsibility for any activities or actions occurring under your account. If your credentials are lost, stolen, or otherwise compromised, you must immediately notify us either by email to
      {{contactEmail}}.
    </li>
    <li>
      <u>Transferability:</u> User accounts are not transferable and you may not share your log-on credentials with others or allow others to use your account other than as provided for the specific account options associated with the specific PrudentCO package
      <span>purchased</span>
      <span>selected</span>
      by Customer.
      You, alone, are responsible for keeping your log-on credentials private and confidential and hereby assume any and all risks associated with a violation of this provision. IN THE EVENT THAT YOUR CREDENTIALS ARE USED TO ACCESS OUR SERVICES IN VIOLATION OF THIS PROVISION, EITHER WITH YOUR KNOWLEDGE OR THROUGH YOUR NEGLIGENCE, YOU HEREBY ACCEPT ANY ASSOCIATED LIABILITY AND AGREE TO INDEMNIFY PRUDENTCO FOR ANY AND ALL COSTS, EXPENDITURES, LIABILITIES, FINES, JUDGMENTS, PENALTIES, REIMBURSEMENTS, OR ASSESSMENTS ASSOCIATED WITH THE IMPROPER USE OF YOUR CREDENTIALS.
    </li>
    <li>
      <u>Capacity:</u> PrudentCO may be utilized only by persons at least 18 years of age. This site and the PrudentCO services are not intended for children or to be used by anyone under the age of 18. Persons who have been deemed incompetent by a court of law, or who otherwise lack contractual capacity, may not utilize this website or the PrudentCO services.
    </li>
  </ol>
  <h3 class="section-heading">IV. Intellectual Property Rights:</h3>
  <ol type="A">
    <li>
      <u>Ownership of Products and Software:</u> All materials provided by PrudentCO to Customer with respect to the PrudentCO products or services, including but not limited to the Software, Confidential Information, proprietary data, proprietary documentation associated with the PrudentCO products or services, or other proprietary information developed or provided by PrudentCO, PrudentCO, or any subsidiaries, affiliates, or agents thereof (including the underlying computer code of our product and/or its web-based application, or any hosted portion thereof; graphics; logos; icons; images; and any non-public methodologies, equipment, processes, or procedures used by PrudentCO to deliver the PrudentCO products and/or services, including any copyrights, trademarks, patents, trade secrets, and any other intellectual property rights inherent in and appurtenant to the PrudentCO products and/or services), shall remain the sole and exclusive property of PrudentCO. Except for any preexisting Customer Intellectual Property Rights, Customer Data, and Confidential Information provided by Customer under this Agreement, and subject to any third-party rights or restrictions, PrudentCO will own all Intellectual Property Rights in or related to all deliverables that are developed or delivered by us hereunder or pursuant to any subsequent Statement of Work.
    </li>
    <li>
      <u>Customer Data:</u>
      <ol type="i">
        <li>
          <u>Title and Ownership of Customer Data:</u> Customer shall retain title to and ownership of Customer Data but grants PrudentCO (and its service providers and affiliates, to the extent those affiliates are working for PrudentCO) a limited, irrevocable, non-exclusive, royalty-free, transferable, global license to access, store, process, and use Customer Data solely for the purposes of providing services and/or complying with the obligations of PrudentCO under this Agreement or as otherwise authorized by our Privacy Policy, which is incorporated into this Agreement by reference. PrudentCO will not disclose, sell, assign, lease, or otherwise dispose of or commercially exploit Customer Data in any manner other than as set forth herein, including pursuant to our Privacy Policy, without the prior written consent of the Customer, except to the extent such disclosure or use is required by law, rule, regulation, or government or court order. The foregoing restrictions on Customer Data shall not apply to De-Identified data, which may be used by PrudentCO and its affiliates for business purposes. PrudentCO will establish and maintain commercially reasonable safeguards against the unintended destruction, loss, or alteration of Customer Data in our possession.
        </li>
        <li>
          <u>Retention of Customer Data:</u> Upon termination of this Agreement for any reason, PrudentCO may retain Customer Data for a reasonable, limited period of time to facilitate business or operational needs, to assist with future Customer return to the PrudentCO services, or for other legitimate purposes. PrudentCO, however, is under no obligation to retain Customer Data and may irrevocably delete or otherwise dispose of Customer Data.
        </li>
      </ol>
    </li>
    <li>
      <u>Information Privacy and Data Security:</u> Although data security risks cannot be completely eliminated, PrudentCO will take commercially reasonable precautions to protect data and information provided to us or stored with us in connection with your use of the PrudentCO Services. Your use of our Services and the accompanying decision to provide information to us, however, is completely voluntary. Accordingly, you agree to hold PrudentCO and its Representatives harmless for any improper or unlawful acquisition of your information by any third party. You hereby agree to protect your computer system, network, or applications (the Customer Systems) from risks that could lead to data security incidents. In the event that a data security incident can be traced to a security deficiency or failure attributable to you or your computer system(s), YOU AGREE TO HOLD PRUDENTCO HARMLESS FOR ANY SUCH DATA SECURITY INCIDENT AND TO INDEMNIFY PRUDENTCO FOR ANY COSTS, FINES, FEES, ASSESSMENTS, OR OTHER EXPENDITURES INCURRED TO INVESTIGATE, REMEDIATE, OR OTHERWISE ADDRESS THE DATA SECURITY INCIDENT.
    </li>
    <li>
      <u>Affirmative Restrictions:</u> You hereby agree that you will not, directly or indirectly, do or allow to be done through you any of the following:
      <ol type="i">
        <li>
          Alter or modify any part of the PrudentCO Products, Software, or Services;
        </li>
        <li>
          Copy or duplicate, or permit a third party to copy or duplicate, any aspect of the PrudentCO Products, Software, or Services;
        </li>
        <li>
          Modify, translate, or create derivative works of the PrudentCO Products, Software, or Services;
        </li>
        <li>
          Decompile, decipher, reverse engineer, disassemble, or otherwise reduce or attempt to derive or discover the source code, algorithms, specifications, architecture, or other elements of the PrudentCO Products, Software, or Services;
        </li>
        <li>
          Create any link to the PrudentCO Services or frame or mirror the content contained thereon, or accessible from, the PrudentCO platform;
        </li>
        <li>
          Create, control, or operate any service, company, entity, or operation competing directly with PrudentCO's Services.
        </li>
        <li>
          Sublicense, transfer, sell, resell, lease, assign, give, distribute or disclose the PrudentCO Products, Software, or Services, or any license to access the PrudentCO Products, Software, or Services, to any third party.
        </li>
      </ol>
    </li>
    <li>
      The provisions of this section shall survive the expiration or termination of this Agreement.
    </li>
  </ol>
  <h3 class="section-heading">V. Acceptable Use Policy:</h3>
  <ol type="A">
    <li>
      <u>Unacceptable Use:</u> PrudentCO requires that all Customers and other Users of the PrudentCO Services conduct themselves with respect toward others. PrudentCO hereby implements and enforces the following Unacceptable Use Policy, to which you agree by accepting the terms of this Agreement. Pursuant to that policy, the following rules apply to your use of the Service:
      <ol type="i">
        <li>
          <u>Abusive Behavior:</u> Do not harass, threaten, or defame any person or entity. Do not contact any person who has requested no further contact. Do not use ethnic or religious slurs against any person or group.
        </li>
        <li>
          <u>Privacy:</u> Do not violate the privacy rights of any person. Do not collect or disclose any personal address, social security number, or other personally identifiable information without the person's affirmative consent and permission. Do not cooperate in or facilitate identity theft or fraud.
        </li>
        <li>
          <u>Intellectual Property:</u> Do not infringe upon the copyrights, trademarks, trade secrets, or other intellectual property rights of any person or entity. Do not reproduce, publish, or disseminate software, audio recordings, video recordings, photographs, articles, or other works of authorship without the written permission of the copyright holder.
        </li>
        <li>
          <u>Hacking, Viruses, and Network Attacks:</u> Do not access any computer, computer network, or communications system without authorization, including the computers used to provide the PrudentCO Service. Do not attempt to penetrate or disable any security system. Do not intentionally distribute a computer virus, launch a denial of service attack, or in any other way attempt to interfere with the functioning of any computer, communications system, or website. Do not attempt to access or otherwise interfere with the accounts of other users of the Service.
        </li>
        <li>
          <u>Spam:</u> Do not send bulk, unsolicited emails ("Spam") or sell or market any product or service advertised by or connected with Spam. Do not facilitate or cooperate in the dissemination of Spam in any way. Do not violate the CAN-SPAM Act of 2003 or any other similar laws or laws designed to prevent or control unsolicited emails.
        </li>
        <li>
          <u>Fraud:</u> Do not issue fraudulent offers to sell or buy products, services, or investments. Do not mislead anyone about the details or nature of a commercial transaction. Do not commit fraud in any other way.
        </li>
        <li>
          <u>Violations of Law:</u> Do not violate any Law.
        </li>
      </ol>
    </li>
    <li>
      <u>Consequences of Violation:</u> Violation of this Acceptable Use Policy, or the Agreement at large, may lead to suspension or termination of your account(s), notification of illegal or improper activities to regulatory and/or governmental bodies, or legal action against you. In addition, you may be required to pay for the costs of investigation and remedial action related to violations of this Policy or this Agreement. To the extent your conduct constitutes a violation or suspected violation of any law, you agree and consent that PrudentCO may provide any and all relevant information to law enforcement personnel for purposes of investigating a violation or alleged violation of law. PrudentCO reserves the right to take any other remedial action it sees fit.
    </li>
    <li>
      <u>Reporting Unacceptable Use:</u> PrudentCO requests that anyone with information about a violation of this Acceptable Use Policy report it via email to
      {{contactEmail}}.
      Please provide the date and time (with time zone) of the violation and any identifying information regarding the violator, including the email or internet protocol (IP) address, along with any other available information that can identify the violator, as well as details of the violation.
    </li>
    <li>
      <u>Revision of Acceptable Use Policy:</u> PrudentCO may, from time to time, update this Acceptable Use Policy by posting a new version on its website and sending the Customer or User-written notification (which may be in a digital or electronic format) of the change. The new version will become effective on the date of such notification.
    </li>
  </ol>
  <h3 class="section-heading">VI. Limitations, Disclaimers, and Wavier of Warranties:</h3>
  <ol type="A">
    <li>
      WE ARE NOT YOUR AGENT. WE ARE A TECHNOLOGY COMPANY OFFERING THE USE OF OUR SOFTWARE AND CLOUD-BASED PLATFORM FOR OTHERS TO CONDUCT BUSINESS OR FIND OTHERS OFFERING OR SOLICITING LOANS OR OTHER FINANCIAL OFFERINGS. PARTIES UTILIZING OUR SERVICES TO INTERACT OR ENGAGE IN BUSINESS WITH OTHER PARTIES DO SO AT THEIR OWN RISK AND OF THEIR OWN ACCORD, AND ARE SOLELY RESPONSIBLE FOR RESEARCHING, VALIDATING, AND UNDERSTANDING THOSE OTHER PARTIES, THE SPECIFIC FINANCIAL POSTURES OF THOSE PARTIES, AND ANY CONTRACTUAL DUTIES OR OBLIGATIONS CONTEMPLATED, SOLICITED, OFFERED, ACCEPTED, OR OTHERWISE UNDERTAKEN WITH THOSE PERSONS OR ENTITIES. WE DO NOT ENDORSE, RECOMMEND, OR MAKE OTHER REPRESENTATIONS REGARDING THE USERS OF OUR SERVICES TO OFFER OR SOLICIT LOANS OR EXTENSIONS OF CREDIT. BORROWERS AND LENDERS ARE SOLELY RESPONSIBLE FOR RELATIONSHIPS, LOANS, OFFERINGS, REPRESENTATIONS, AND/OR CONTRACTUAL OBLIGATIONS OFFERED OR SOLICITED THROUGH OUR SERVICE. BY USING OUR SERVICE, YOU AGREE THAT NEITHER WE NOR OUR REPRESENTATIVES ARE LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY DAMAGES, COSTS, FEES, FINES, ASSESSMENTS, OR OTHER HARMS RELATED TO OR ARISING FROM YOUR RELATIONSHIP WITH US OR YOUR RELATIONSHIP WITH ANY LENDER OR BORROWER UNDERTAKEN THROUGH OR IN CONNECTION WITH OUR SERVICES, AND YOU AGREE TO THE INDEMNIFICATION PROVISIONS PROVIDED ELSEWHERE IN THIS AGREEMENT.
    </li>
    <li>
      BY USING OUR SERVICE, YOU AGREE THAT NEITHER WE NOR OUR REPRESENTATIVES ARE LIABLE OR RESPONSIBLE
      IN ANY WAY FOR ANY DAMAGES, COSTS, FEES, FINES, ASSESSMENTS, OR OTHER HARMS RELATED TO OR ARISING FROM
      YOUR RELATIONSHIP WITH US OR YOUR RELATIONSHIP WITH YOUR CUSTOMER UNDERTAKEN THROUGH OR
      IN CONNECTION WITH OUR SERVICES, AND YOU AGREE TO THE INDEMNIFICATION PROVISIONS PROVIDED ELSEWHERE IN THIS AGREEMENT.
    </li>
    <li>
      Customer hereby warrants and avers (1) that it is compliant with all applicable Laws controlling any lending or financial offerings or operations it is offering or promoting; (2) that Customer has obtained any and all necessary licenses or registrations, and has satisfied all other regulatory or other obligations related to its offered lending or financial operations or services; and/or (3) that it has or will independently research any person or entity with whom it is connecting through the PrudentCO services to verify and satisfy itself that the other person or entity is fully licensed, registered, or otherwise compliant with any and all applicable rules, laws, or obligations. CUSTOMER UNDERSTANDS AND AGREES THAT PRUDENTCO, WHETHER VIA THE PRUDENTCO SERVICES OR OTHERWISE, IS NOT RESPONSIBLE FOR VERIFYING THIS INFORMATION. CUSTOMER FULLY ASSUMES ALL RESPONSIBILITY FOR, OR LIABILITY ARISING FROM, ANY LICENSING, REGISTRATION, OR OTHER REGULATORY OBLIGATIONS AND AGREES TO INDEMNIFY AND HOLD HARMLESS PRUDENTCO FOR ANY CLAIMS ARISING FROM, OR RELATED TO, ANY SUCH ISSUES.
    </li>
    <li>
      PrudentCO warrants that the PrudentCO services will perform substantially and materially in accordance with the functions described in the user documentation under normal use and circumstances by Authorized Users of the Services, in accordance with this documentation and the specific provisions of the applicable package selected
      <span>and purchased</span>
      by Customer. UNDER NO CIRCUMSTANCES, HOWEVER, IS PRUDENTCO LIABLE FOR ANY DEFECTS OR ERRORS IN THE PRUDENTCO SERVICES OR THE PROGRAMMING ON WHICH IT IS BASED. IF THERE IS A MATERIAL BREACH OF THE ABOVE WARRANTY, PRUDENTCO'S ENTIRE LIABILITY AND CUSTOMER'S ENTIRE REMEDY SHALL BE, AT PRUDENTCO'S OPTION, TO (I) MODIFY THE SERVICES TO CONFORM TO THE DOCUMENTATION; (II) PROVIDE A REASONABLE WORKAROUND SOLUTION WHICH WILL REASONABLY MEET CUSTOMER'S REQUIREMENTS; OR (III) IF NEITHER OF THE FOREGOING IS COMMERCIALLY REASONABLE, TERMINATE THIS AGREEMENT WITH NO FURTHER LIABILITY TO PRUDENTCO. THESE REMEDIES ARE CUSTOMER'S SOLE AND EXCLUSIVE REMEDIES FOR A CLAIM OF BREACH OF WARRANTY UNDER THIS AGREEMENT. EXCEPT FOR INDEMNIFICATION OBLIGATIONS THAT MAY ARISE HEREUNDER, CUSTOMER EXPRESSLY AGREES THAT THE USE OF THE PRUDENTCO SERVICES IS A T THE CUSTOMER'S SOLE RISK.
    </li>
    <li>
      Customer is solely responsible for any interactions with advertisers, vendors, third-party service providers, and other users (collectively, "Third Parties"). We are not responsible for the activities, omissions, or other conduct, whether online or offline, of any Third Party. We are not responsible for any incorrect, inaccurate, or unlawful content posted through the Service by Third Parties. Under no circumstances shall we be responsible for any loss or damage resulting from the use of PrudentCO by Third Parties or from any content posted on PrudentCO or transmitted to users by Third Parties, or any interactions with Third Parties, whether online or offline.
    </li>
    <li>
      Customer is solely responsible FOR ITS OWN COMPLIANCE with applicable legal requirements.
      <span>PrudentCO Print Forms are provided as a resource only.</span>
      PrudentCO DOES NOT GUARANTEE COMPLIANCE with any laws, ordinances, or regulations.
      <span>
      Please consult your compliance officer or attorney for instructions on how to comply with the law.
      PrudentCO will not be held responsible for any issues you may experience using the forms.
      </span>
    </li>
  </ol>
  <h3 class="section-heading">VII. Choice of Law and Dispute Resolution:</h3>
  <ol type="A">
    <li>
      <u>Choice of Law:</u> This Agreement is governed by the laws of Colorado without regard to any conflict of law rules.
    </li>
    <li>
      <u>Venue:</u> Subject to the Arbitration provisions set forth below, the parties hereby agree that any action or claim brought in connection with, or based in any way upon, this Agreement, or any action or claim brought by Customer against PrudentCO, regardless of whether that claim sounds in contract, tort, or otherwise, including any decision to enforce an arbitration award, will be brought only in the State of Colorado, County of Boulder, and that any attempt for such a claim to be brought in another location shall be deemed null and void. The Parties hereby manifest their intent to submit to the jurisdiction of Colorado for all claims falling within the scope of this provision.
    </li>
    <li>
      <u>Arbitration:</u> The parties agree that any controversy, claim, or action arising out of or relating to this Agreement, or the breach thereof, shall be settled by final and binding arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules (including the Optional Rules for Emergency Measures of Protection). The arbitration shall take place in Boulder County, Colorado before a single arbitrator. Judgment on any award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
    </li>
  </ol>
  <h3 class="section-heading">VIII. Limitation on Time to File Claims:</h3>
  <p>
    YOU AGREE THAT ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE PRUDENTCO SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS HEREBY AGREED TO BE WAIVED AND PERMANENTLY BARRED.
  </p>
  <h3 class="section-heading">IX. General Indemnification:</h3>
  <ol type="A">
    <li>
      <u>Direct Indemnification:</u> You agree to defend and indemnify PrudentCO and its Representatives, officers, owners, directors, shareholders, parents, subsidiaries, agents, affiliates, successors, and assigns for any Losses, claims, expenses, or costs of any kind, including reasonable attorney fees, arising from (1) your use of the PrudentCO Services, (2) your failure to comply with the PrudentCO Terms of Service, (3) negligence, actions, or omissions, (4) violation or alleged violation of any law, or (5) violation or alleged violation of the rights of any third-party.
    </li>
    <li>
      <u>Third-Party Claim Indemnification:</u> You agree to defend and indemnify PrudentCO and its Representatives, officers, owners, directors, shareholders, parents, subsidiaries, agents, affiliates, successors and assigns against any third-party claims, suit, or proceeding, or for any Losses, expenses, or costs of any kind, including reasonable attorney fees, arising out of or related to Customer's alleged or actual use of, misuse of, or failure to use the PrudentCO Services, including without limitation (1) claims by your customers, users, subscribers, and/or employees, and (2) claims related to unauthorized disclosure or exposure of personally identifiable information or other private information.
    </li>
    <li>
      <u>Indemnified Claims:</u> Indemnified claims include, without limitation, claims arising out of or related to PrudentCO's alleged negligence. PrudentCO will control the defense of any indemnified claim, including appeals, negotiations, and any settlement or compromise thereof, provided that Customer will have the right, not to be exercised unreasonably, to reject any settlement or compromise that requires that it admit wrongdoing or liability or subjects it to any ongoing affirmative obligations. Customer's obligations set forth above include retention and payment of attorneys and payment of court costs, as well as settlement at Customer's expense and payment of judgments.
    </li>
  </ol>
  <h3 class="section-heading">X. Force Majeure:</h3>
  <ol type="A">
    <li>
      In no event will PrudentCO or its Representatives be liable or responsible, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any payment obligation) when and to the extent such failure or delay is caused by any circumstances beyond PrudentCO's reasonable control (a "Force Majeure Event"), including acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, passage of Law or any action taken by a governmental or public authority, including imposing an embargo, export or import restriction, quota or other restriction or prohibition or any complete or partial government shutdown, or other national or regional shortage of adequate power or telecommunications or transportation. Either party may terminate this Agreement if a Force Majeure Event continues substantially uninterrupted for a period of 30 days or more.
    </li>
    <li>
      In the event of any failure or delay caused by a Force Majeure Event, PrudentCO shall give prompt written notice to Customer stating the period of time the occurrence is expected to continue and use commercially reasonable efforts to end the failure or delay and minimize the effects of such Force Majeure Event.
    </li>
  </ol>
  <h3 class="section-heading">XI. Cookie Policy:</h3>
  <p>
    PrudentCO uses cookies, small files stored on your computer, to provide services and track user activities on its website and within the PrudentCO product. Cookies may help improve the user experience, for example, by allowing users to maintain a persistent log-on and to remember users when they return to the Service. If you do not wish for Cookies to be used, you should clear the cookies from your computer and configure the Customer Systems to prevent cookies in the future.
  </p>
  <h3 class="section-heading">XII. Entire Agreement:</h3>
  <p>
    This document, which may include the Privacy Policy and Terms of Use posted and maintained on the prudentco.com website as updated from time to time (incorporated herein by reference), and representations made regarding the specific package selected
    <span>and purchased</span>
    by Customer, is the entire agreement between PrudentCO and Customer. No other agreements, statements, representations, assertions, claims or promises made prior to, or contemporaneous with, this Agreement that alter, contradict, or otherwise purport to influence this Agreement have any effect and are expressly excluded from the Agreement between PrudentCO and Customer.
  </p>
  <h3 class="section-heading">XIII. Severability:</h3>
  <p>
    If any portion, provision, or term of this Agreement is determined to be unenforceable, illegal, or invalid, that portion, provision, or term will be excluded from this Agreement to the extent it is unenforceable, illegal, or invalid. The remainder of this Agreement shall continue and remain in effect provided that the exclusion of any term does not materially change or frustrate (1) the intent of the Parties or (2) the economic substance of this Agreement between the Parties.
  </p>
  <h3 class="section-heading">XIV. Definitions:</h3>
  <p>
    <b>"Access Credentials"</b> means any username, identification number, password, license or security key, security token, PIN or other security code, method, technology, or device used, alone or in combination, to verify an individual's identity and authorization to access and use the PrudentCO Services.
  </p>
  <p>
    <b>"Agreement"</b> means this Terms of Service contract between the Parties.
  </p>
  <p>
    <b>"Authorized User"</b> means an individual who has agreed to this Agreement and who is authorized by PrudentCO to utilize its PrudentCO services
    <span>pursuant to a purchase from a Customer</span>.
  </p>
  <p>
    <b>"Borrowers"</b> means Customers or Users of the PrudentCO service who wish to obtain from Lenders information regarding loan options or packages, or other services, being offered by Lenders.
  </p>
  <p>
    <b>"Contract"</b> has the same meaning as "Agreement."
  </p>
  <p>
    <b>"Confidential Information"</b> means any and all trade secrets, business and financial information, and other proprietary information or data disclosed between PrudentCO and Customer, or incorporated in materials or products provided by or to Customer through the PrudentCO services.
  </p>
  <p>
    <b>"Customer"</b> means either (1) an individual who has agreed to this Agreement and is utilizing PrudentCO's services for individual purposes and not for business purposes, or (2) a Person who has agreed to this Agreement and is utilizing PrudentCO's loan origination services for business purposes, for purposes relating to generating profit, or for any purpose other than non-business personal use.
  </p>
  <p>
    <b>"Customer"</b> means an individual who has agreed to this Agreement and is utilizing PrudentCO's services
    for business purposes.
  </p>
  <p>
    <b>"Customer Data"</b> means, other than Resultant Data, information, data, and other content, in any form or medium, including Customer-provided Confidential Information, that is collected, downloaded or otherwise received, directly or indirectly from Customer or an Authorized User by or through the Services or that incorporates or is derived from the Processing of such information, data or content by or through the Services.
  </p>
  <p>
    <b>"Customer Systems"</b> means the Customer's information technology infrastructure, including computers, software, hardware, databases, electronic systems (including database management systems) and networks, whether operated directly by Customer or through the use of third-party services.
  </p>
  <p>
    <b>"De-Identified"</b> means to be processed or rendered in such a manner as to remove personal identifiable information such that the information cannot be used to identify an individual.
  </p>
  <p>
    <b>"Documentation"</b> means any manuals, instructions, or other documents or materials that PrudentCO provides or makes available to Customer in any form or medium and which describe the functionality, components, features, or requirements of the PrudentCO Services or Provider Materials, including any aspect of the installation, configuration, integration, operation, use, support or maintenance thereof.
  </p>
  <p>
    <b>"Intellectual Property Rights"</b> means any and all registered and unregistered rights granted, applied for or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.
  </p>
  <p>
    <b>"Law"</b> means any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree or other requirements of any federal, state, local or foreign government or political subdivision thereof, or any arbitrator, court or tribunal of competent jurisdiction.
  </p>
  <p>
    <b>"Lenders"</b> means Customers or Users of the PrudentCO service who wish to offer to Borrowers information regarding loan options or packages, or other services, being offered by Lenders.
  </p>
  <p>
    <b>"Losses"</b> means any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.
  </p>
  <p>
    <b>"Parties"</b> means the parties to this Agreement.
  </p>
  <p>
    <b>"Person"</b> means an individual, corporation, partnership, joint venture, limited liability entity, governmental authority, unincorporated organization, trust, association or other entity.
  </p>
  <p>
    <b>"Personal Information"</b> means any information that does or can identify a specific individual or by/from which a specific individual may be identified, contacted, or located. Personal Information includes all "nonpublic personal information" as defined under the Gramm-Leach-Bliley Act, "protected health information" as defined under the Health and Insurance Portability and Accountability Act of 1996, "Personal Data" as defined in the EU Data Protection Directive (Directive 95/46/EEC), "Personal Information" as defined under the Children's Online Privacy Protection Act of 1998, and all rules and regulations issued under any of the foregoing.
  </p>
  <p>
    <b>"Privacy Policy"</b> means the most recent version of the privacy policy posted on the prudentco.com website as updated from time to time.
  </p>
  <p>
    <b>"Provider Materials"</b> means the Service Software, Specifications, Documentation and Provider Systems and any and all other information, data, documents, materials, works, and other content, devices, methods, processes, hardware, software, and other technologies and inventions, including any deliverables, technical or functional descriptions, requirements, plans or reports, that are provided or used by PrudentCO or any Subcontractor in connection with the Services or otherwise comprise or relate to the Services or Provider Systems. For the avoidance of doubt, Provider Materials include Resultant Data and any information, data, or other content derived from PrudentCO's monitoring of Customer's access to or use of the Services, but do not include Customer Data.
  </p>
  <p>
    <b>"Provider Systems"</b> means the information technology infrastructure used by or on behalf of PrudentCO in performing the Services, including all computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by PrudentCO or through the use of third-party services.
  </p>
  <p>
    <b>"Representatives"</b> means, with respect to a party, that party's, and its affiliates, employees, officers, directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors, and legal advisors.
  </p>
  <p>
    <b>"Resultant Data"</b> means information, data, and other content that is derived by or through the Services from Processing Customer Data and is sufficiently different from such Customer Data that such Customer Data cannot be reverse-engineered or otherwise identified from the inspection, analysis, or further Processing of such information, data or content.
  </p>
  <p>
    <b>"Service Software"</b> means PrudentCO's software application or applications and any third-party or other software, and all new versions, updates, revisions, improvements, and modifications of the foregoing, that PrudentCO provides remote access to and use of as part of the Services.
  </p>
  <p>
    <b>"Services"</b> means the PrudentCO software, program, website, application(s), functionality, or operations available through the PrudentCO website or any other remotely accessible application or process.
  </p>
  <p>
    <b>"Software"</b> means PrudentCO's proprietary codebase underlying its PrudentCO Services.
  </p>
  <p>
    <b>"Subscriber Areas"</b> means the PrudentCO Services and/or those portions of the PrudentCO website that only Authorized Users may utilize and which require the use of Access Credentials to access.
  </p>
  <p>
    <b>"Specifications"</b> means the specifications for the Services set forth in the account types provided for each on the PrudentCO website and, to the extent consistent with and not limiting of the foregoing, any other Statements of Work or documentation associated therewith.
  </p>
  <p>
    <b>"Terms of Service"</b> means this Terms of Service contract, as updated from time to time and posted on the PrudentCO website.
  </p>
  <p>
    <b>"Term"</b> means the Initial Term and any Renewal Term.
  </p>
  <p>
    <b>"User"</b> means an Authorized User of the PrudentCO Services.
  </p>

  <p>Last updated: August 2023</p>
</div>
