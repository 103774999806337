import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-simple-navbar',
  templateUrl: './simple-navbar.component.html',
})
export class SimpleNavbarComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
