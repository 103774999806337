import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {TransmittalData} from '../../../../models/transmittal-data';
import {globals} from '../../../../shared/globals/globals';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-transmittal-data',
  templateUrl: './edit-segment-transmittal-data.component.html',
})
export class EditSegmentTransmittalDataComponent extends CompBaseComponent implements OnInit {
  @Input() public item: TransmittalData;
  public readonly appraisal_types_ex = TransmittalData.appraisal_types_ex;
  public readonly existing_mortgage_owner_codes_ex = TransmittalData.existing_mortgage_owner_codes_ex;
  public isRefinanceLoan: boolean;
  public isPurchaseLoan: boolean;

  // form and form controls
  form: FormGroup;
  existing_mortgage_owner_code_ex: FormControl;
  property_appraised_value: FormControl;
  appraisal_type_ex: FormControl;
  seller_below_market_financing: FormControl;
  appraisal_identifier: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'transmittal_data';
    this.section = 'lo';
    this.reload();
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.existing_mortgage_owner_code_ex = new FormControl(item.existing_mortgage_owner_code_ex);
    this.appraisal_type_ex = new FormControl(item.appraisal_type_ex, AppValidators.present);
    this.property_appraised_value = new FormControl(item.property_appraised_value, AppValidators.present);
    this.seller_below_market_financing = new FormControl(item.seller_below_market_financing);
    this.appraisal_identifier = new FormControl(item.appraisal_identifier);
  }

  createForm(): void {
    this.form = new FormGroup({
      seller_below_market_financing: this.seller_below_market_financing,
      existing_mortgage_owner_code_ex: this.existing_mortgage_owner_code_ex,
      property_appraised_value: this.property_appraised_value,
      appraisal_type_ex: this.appraisal_type_ex,
      appraisal_identifier: this.appraisal_identifier,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    let updated = new TransmittalData(this.form.value);
    updated['buydown_rate'] = this.item.buydown_rate;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
    this.isPurchaseLoan = this.analyzerService.isPurchaseLoan(this.doc.recursive_attributes);
  }
}
