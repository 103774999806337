import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-loan-app-container',
  templateUrl: './view-loan-app-container.component.html',
})
export class ViewLoanAppContainerComponent extends CompBaseComponent implements OnInit {
  @Input() loanApp: Document;
  public mode: string;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }
}
