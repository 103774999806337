import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {SecEmployment} from '../../../../models/sec-employment';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-sec-employment',
  template: '',
})
export class SecEmploymentComponent extends CompBaseComponent implements OnInit {
  @Input() item: SecEmployment;
  public minDate = globals.date.minEmpStartDate;
  public maxDate = globals.date.maxEmpEndDate;

  // form and form controls
  public form: FormGroup;
  public employer_name: FormControl;
  public address: FormControl;
  public address2: FormControl;
  public city: FormControl;
  public state: FormControl;
  public zip: FormControl;
  public zip_four: FormControl;
  public country: FormControl;
  public self_employed: FormControl;
  public from_date: FormControl;
  public to_date: FormControl;
  public position: FormControl;
  public business_phone: FormControl;
  public monthly_income: FormControl;
  public seasonal_income_indicator: FormControl;
  public foreign_income_indicator: FormControl;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'secondary_employments';
    this.section = this.applicant;
  }

  createFormControls(item: SecEmployment): void {
    this.employer_name = new FormControl(item.employer_name);
    this.address = new FormControl(item.address);
    this.address2 = new FormControl(item.address2);
    this.city = new FormControl(item.city);
    this.state = new FormControl(item.state, Validators.pattern(globals.pattern.state));
    this.zip = new FormControl(item.zip, Validators.pattern(globals.pattern.zip));
    this.zip_four = new FormControl(item.zip_four, Validators.pattern(globals.pattern.zip_four));
    this.country = new FormControl(item.country, Validators.pattern(globals.pattern.country));
    this.self_employed = new FormControl(item.self_employed);
    this.from_date = new FormControl(item.from_date, AppValidators.present);
    this.to_date = new FormControl(item.to_date, AppValidators.present);
    this.position = new FormControl(item.position);
    this.business_phone = new FormControl(item.business_phone, Validators.pattern(globals.pattern.phone));
    this.monthly_income = new FormControl(item.monthly_income);
    this.seasonal_income_indicator = new FormControl(item.seasonal_income_indicator);
    this.foreign_income_indicator = new FormControl(item.foreign_income_indicator);
  }

  createForm(): void {
    this.form = new FormGroup({
      employer_name: this.employer_name,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      zip_four: this.zip_four,
      country: this.country,
      self_employed: this.self_employed,
      from_date: this.from_date,
      to_date: this.to_date,
      position: this.position,
      business_phone: this.business_phone,
      monthly_income: this.monthly_income,
      seasonal_income_indicator: this.seasonal_income_indicator,
      foreign_income_indicator: this.foreign_income_indicator,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    if (this.from_date.value > this.to_date.value) {
      this.displayDateError();
      return;
    }

    const updated = new SecEmployment(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    if (this.from_date.value > this.to_date.value) {
      this.displayDateError();
      return;
    }

    const updated = new SecEmployment(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  displayDateError(): void {
    FormUtil.genErrorMessage(this.sharedService, 'From date must be before To date', null);
  }
}
