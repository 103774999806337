import {ModelBase} from './model-base';

export class Alias extends ModelBase {
  index: number;
  alternate_first: string;
  alternate_middle: string;
  alternate_last: string;
  alternate_suffix: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }

  static deserializeArray(arr: Array<any>): Array<Alias> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Alias(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      alternate_first: this.alternate_first,
      alternate_middle: this.alternate_middle,
      alternate_last: this.alternate_last,
      alternate_suffix: this.alternate_suffix
    };
  }
}
