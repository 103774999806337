<mat-card *ngIf="reloaded" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="'Edit Loan Level MCR'" [help]="true">
      <div [innerHtml]="helpMcr"></div>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row mb-3" *ngIf="!!tran">
      <div class="col">
        <button (click)="populate()" class="btn btn-lg btn-warning" aria-label="Populate" title="Populate from Loan Data" type="submit">Populate from Loan Data</button>
      </div>
    </div>

    <form [formGroup]="form" novalidate>
      <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>

      <!-- NAME -->
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Loan MCR name</mat-label>
            <input formControlName="name" matInput placeholder="Loan MCR name" appAutoFocus maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="name.hasError('required')">
              Loan MCR name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- STATE -->
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>State</mat-label>
            <input formControlName="state" matInput placeholder="State"maxlength="{{MAX_LEN.STATE_ABBR}}" required>
            <mat-error *ngIf="state.hasError('required')">
              State is required
            </mat-error>
            <mat-error *ngIf="state.hasError('pattern')">
              State is invalid
            </mat-error>
            <mat-hint>State Abbr.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- MCR period: quarter and year -->
      <div class="row mt-3">
        <!-- latest MCR quarter -->
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>MCR quarter</mat-label>
            <mat-select formControlName="latestQuarter" placeholder="Select MCR quarter" [required]="isParamRequired">
              <mat-option *ngFor="let qr of quarters" [value]="qr.key">{{qr.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="latestQuarter.hasError('required')">
              MCR quarter is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <!-- latest MCR year -->
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>MCR year</mat-label>
            <mat-select formControlName="latestYear" placeholder="Select MCR year" [required]="isParamRequired">
              <mat-option *ngFor="let yr of years" [value]="yr.key">{{yr.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="latestYear.hasError('required')">
              MCR year is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>


      <!-- is repurchased loan -->
      <div class="row mt-2 hover-gray">
        <div class="col-lg-4 my-auto">
          <label id="is-repurchased-label">Is this a repurchased loan?</label>
        </div>
        <div class="col-lg-8">
          <mat-radio-group (change)="onParamChange($event)" aria-label="isRepurchased" formControlName="isRepurchased" required>
            <mat-radio-button [checked]="isRepurchased.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="isRepurchased.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- LOAN AMOUNT -->
      <div class="row mt-2 mb-5">
        <div class="col-lg-6">
          <div *ngIf="isHECMRequired">
            Enter the full exposure amount the institution may have on the loan.
          </div>
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Current loan amount</mat-label>
            <input formControlName="loanAmount" appNumberOnly matInput placeholder="Current loan amount" maxlength="12" required>
            <mat-error *ngIf="loanAmount.hasError('required')">
              Current loan amount is required
            </mat-error>
            <mat-hint>Loan amount rounded to the nearest dollar. No comma or $ sign.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="isRepurchased.value===true">

        <!-- start date -->
        <div class="row mt-2">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Loan application received date</mat-label>
              <input appDateOnly [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="startDate" matInput placeholder="Loan application received date" [required]="isParamRequired">
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="startDate.hasError('matDatepickerMin') || startDate.hasError('matDatepickerMax')">
                Loan application received date is unrealistic
              </mat-error>
              <mat-error *ngIf="startDate.hasError('matDatepickerParse')">
                Loan application received date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- loan disposition -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Loan disposition</mat-label>
              <mat-select (selectionChange)="onParamChange($event)" formControlName="loanDispositionCode" placeholder="Select loan disposition" [required]="isParamRequired">
                <mat-option *ngFor="let ldc of loan_disposition_codes" [value]="ldc.key">{{ldc.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="loanDispositionCode.hasError('required')">
                Loan disposition is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- end date -->
        <div [hidden]="!isEndDateRequired" class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Loan closed date</mat-label>
              <input appDateOnly [matDatepicker]="$any(datePicker2)" [max]="maxDate" [min]="minDate" formControlName="endDate" matInput placeholder="Loan closed date" [required]="isEndDateRequired">
              <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker2></mat-datepicker>
              <mat-error *ngIf="endDate.hasError('matDatepickerMin') || endDate.hasError('matDatepickerMax')">
                Loan closed date is unrealistic
              </mat-error>
              <mat-error *ngIf="endDate.hasError('matDatepickerParse')">
                End date format is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Loan application received from_borrower or third party -->
        <div class="row hover-gray">
          <div class="col-lg-4 my-auto">
            <label id="from-borrower-label">Who was the loan application received from?</label>
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="fromBorrower" formControlName="fromBorrower" [required]="isParamRequired">
              <mat-radio-button [checked]="fromBorrower.value===true" [value]="true">From borrower</mat-radio-button>
              <mat-radio-button [checked]="fromBorrower.value===false" [value]="false">From third party</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

        <!-- HECM: is hecm -->
        <div class="row hover-gray">
          <div class="col-lg-4 my-auto">
            <label id="hecm-label">Is this a reverse mortgage (HECM) loan?</label>
          </div>
          <div class="col-lg-8">
            <mat-radio-group (change)="onParamChange($event)" class="d-inline-block" aria-label="isHECM" formControlName="isHECM" [required]="isParamRequired">
              <mat-radio-button [checked]="isHECM.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="isHECM.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- HECM: HECM type -->
        <div [hidden]="!isHECMRequired" class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Mortgage type</mat-label>
              <mat-select formControlName="hecmTypeCode" class="d-inline-block" placeholder="Select reverse mortgage type" [required]="isHECMRequired">
                <mat-option *ngFor="let hecm of hecm_type_codes" [value]="hecm.key">{{hecm.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hecmTypeCode.hasError('required')">
                HECM type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- HECM: HECM purpose -->
        <div [hidden]="!isHECMRequired" class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Reverse loan purpose</mat-label>
              <mat-select formControlName="reversePurposeCode" placeholder="Select reverse loan purpose" [required]="isHECMRequired">
                <mat-option *ngFor="let rp of reverse_purpose_codes" [value]="rp.key">{{rp.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="reversePurposeCode.hasError('required')">
                Reverse purpose is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- is brokered or funded -->
        <div class="row hover-gray">
          <div class="col-lg-4 my-auto">
            <label [hidden]="!isMobile" id="is-brokered-label-mobile">Is this loan brokered or funded?</label>
            <label [hidden]="isMobile" id="is-brokered-label">Is this loan brokered or funded by your company?</label>
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="isBrokered" formControlName="isBrokered" [required]="isParamRequired">
              <mat-radio-button [checked]="isBrokered.value===true" [value]="true">Brokered</mat-radio-button>
              <mat-radio-button [checked]="isBrokered.value===false" [value]="false">Funded</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- HOEPA: is hoepa -->
        <div class="row hover-gray">
          <div class="col-lg-4 my-auto">
            <label id="hoepa-label">Is this loan subject to HOEPA?</label>
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="isHoepa" formControlName="isHOEPA" [required]="isParamRequired">
              <mat-radio-button [checked]="isHOEPA.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="isHOEPA.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- state changed -->
        <div class="row hover-gray">
          <div class="col-lg-4 my-auto">
            <label [hidden]="!isMobile" id="is-state-changed-label-mobile">Loan's state changed?</label>
            <label [hidden]="isMobile" id="is-state-changed-label">Was this loan's state changed?</label>
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="stateChanged" formControlName="stateChanged" [required]="isParamRequired">
              <mat-radio-button [checked]="stateChanged.value==='A'" [value]="'A'">Loan added to state</mat-radio-button>
              <mat-radio-button [checked]="stateChanged.value==='R'" [value]="'R'">Loan removed from state</mat-radio-button>
              <mat-radio-button [checked]="stateChanged.value==='N'" [value]="'N'">Not Applicable</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- QM: qm status -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>QM status</mat-label>
              <mat-select formControlName="qmCode" placeholder="Select QM status" [required]="isParamRequired">
                <mat-option *ngFor="let qm of qm_codes" [value]="qm.key">{{qm.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="qmCode.hasError('required')">
                QM status is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- LIEN: lien status -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Lien status</mat-label>
              <mat-select formControlName="lienStatusCode" placeholder="Select lien status" [required]="isParamRequired">
                <mat-option *ngFor="let ls of lien_status_codes" [value]="ls.key">{{ls.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="lienStatusCode.hasError('required')">
                Lien status is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- LOAN PURPOSE: loan purpose -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Loan purpose</mat-label>
              <mat-select formControlName="loanPurposeCode" placeholder="Select loan purpose" [required]="isParamRequired">
                <mat-option *ngFor="let lp of loan_purpose_codes" [value]="lp.key">{{lp.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="loanPurposeCode.hasError('required')">
                Loan purpose is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- LOAN TYPE: (e.g., conventional) -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Loan type</mat-label>
              <mat-select formControlName="loanTypeCode" placeholder="Select loan type" [required]="isParamRequired">
                <mat-option *ngFor="let lt of loan_type_codes" [value]="lt.key">{{lt.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="loanTypeCode.hasError('required')">
                Loan type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- PROPERTY: property type -->
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Property type</mat-label>
              <mat-select formControlName="propertyTypeCode" placeholder="Select property type" [required]="isParamRequired">
                <mat-option *ngFor="let pt of property_type_codes" [value]="pt.key">{{pt.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="propertyTypeCode.hasError('required')">
                Property type is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- BROKER FEE -->
        <div class="row" *ngIf="isBrokered.value===true">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Broker fees collected</mat-label>
              <input formControlName="brokerFee" appNumberOnly matInput placeholder="Broker fees collected" maxlength="12">
              <mat-error *ngIf="brokerFee.hasError('required')">
                Broker fees collected is required
              </mat-error>
              <mat-hint>Broker fees collected rounded to the nearest dollar. No comma or $ sign.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <!-- LENDER FEE -->
        <div class="row" *ngIf="isBrokered.value===false">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Lender fees (if your company funded the loan)</mat-label>
              <input formControlName="lenderFee" appNumberOnly matInput placeholder="Lender fees if your company funded the loan" maxlength="12">
              <mat-error *ngIf="lenderFee.hasError('required')">
                Lender fees
              </mat-error>
              <mat-hint>Lender fees rounded to the nearest dollar. No comma or $ sign.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <!-- original loan amount -->
        <div class="row mt-2">
          <div class="col-lg-6">
            Enter "Previous loan amount"
            <span class="fw-bold">only if "Current loan amount" is different</span> from the NMLS-reported amount in the previous quarter.
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Previous loan amount (if applicable)</mat-label>
              <input formControlName="prevLoanAmount" appNumberOnly matInput placeholder="Previous loan amount (if applicable)" maxlength="12">
              <mat-error *ngIf="prevLoanAmount.hasError('required')">
                Previous loan amount is required
              </mat-error>
              <mat-hint>Previous loan amount rounded to the nearest dollar. No comma or $ sign.</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button (click)="update()" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="delete()" class="ms-2 btn btn-lg btn-danger">Delete<i class="text-danger fas fa-trash"></i>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

