import {Component, Injector, OnInit} from '@angular/core';
import {LiabilityComponent} from '../liability/liability.component';

@Component({
  selector: 'app-view-liability',
  templateUrl: './view-liability.component.html',
})
export class ViewLiabilityComponent extends LiabilityComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // we override onEvent so that the event will not propagate to parent for ChangeMode
  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }

    this.eventEmitted.emit(event);
  }
}
