import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-popup-compliance',
  templateUrl: './popup-compliance.component.html',
  styleUrls: ['./popup-compliance.component.scss']
})
export class PopupComplianceComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
