import {Component, HostBinding, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {SiteComponent} from '../site.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {Notification} from '../../../models/notification';

@Component({
  selector: 'app-mcr-service',
  templateUrl: './mcr-service.component.html',
})
export class McrServiceComponent extends SiteComponent implements OnInit, OnDestroy {
  public email: FormControl;
  public name: FormControl;
  public phone_number: FormControl;
  public message: FormControl;
  public form: FormGroup;
  public sent: boolean;
  public submitted: boolean;
  public error: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = 'MCR';
    super.ngOnInit();
    this.reload();
  }

  ngOnDestroy(): void {
  }

  createFormControls() {
    this.name = new FormControl(null, AppValidators.present);
    this.email = new FormControl(null, Validators.compose([AppValidators.present, Validators.email]));
    this.phone_number = new FormControl(null);
    this.message = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      email: this.email,
      message: this.message,
      phone_number: this.phone_number,
    });
  }

  submit(event: any): void {
    event.preventDefault();
    this.error = null;
    this.sent = false;

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const payload = this.form.value;
    payload['notification_type'] = Notification.NOTIFICATION_TYPE.MCR_SERVICE;
    this.emailService.createNotification(payload)
      .then(() => {
        this.sent = true;
        // FormUtil.genSuccessMessage(this.sharedService, 'Note successfully created');
      })
      .catch((data) => {
        this.error = data.errors.join(' ') + '!';
      });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.error = null;
    this.sent = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.homeHighlights
      });
      this.reloaded = true;
    }, 0);
  }
}
