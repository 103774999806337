import {Component, Injector, OnInit} from '@angular/core';
import {AdminUserComponent} from '../admin-user.component';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-edit-user',
  templateUrl: '../admin-edit-user.component.html',
})
export class AdminEditUserComponent extends AdminUserComponent implements OnInit {
  pageTitle = 'Edit User';

  constructor(public injector: Injector) {
    super(injector);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    })
    this.route.paramMap.subscribe((map) => {
      this.userId = map['params']['id'];
      this.reload();
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.adminService.findUserById(this.userId)
      .then((user) => {
        this.user = user;
        this.createFormControls(this.user);
        this.createForm();
        this.reloaded = true;
      });
  }

  cancel(): void {
    this.router.navigate(['/admin/view-user', this.user.id]);
  }
}
