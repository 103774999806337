import {Component, Injector, OnInit} from '@angular/core';
import {FeeWorksheetBaseComponent} from "../fee-worksheet-base.component";

@Component({
  selector: 'app-view-segment-worksheet',
  templateUrl: './view-segment-worksheet.component.html',
})
export class ViewSegmentWorksheetComponent extends FeeWorksheetBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }

  edit(): void {
    if (this.canUpdateTransaction) {
      this.eventEmitted.emit({
        type: 'ChangeMode',
        mode: 'Edit'
      });
    }
  }
}
