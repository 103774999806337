import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';

@Component({
  selector: 'app-get-credit-report',
  templateUrl: './get-credit-report.component.html',
})
export class GetCreditReportComponent extends CompBaseComponent implements OnInit {
  public actionUrl: string;
  public mode: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reset()
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const tran = this.tranService.getCurrentTranSync();
    this.actionUrl = `credit_reports/credit_file_upload?tran_id=${tran.id}`;
  }

  UpdateMethod(mode: string): void {
    this.mode = mode;
  }

  reset(): void {
    this.mode = null;
  }
}
