import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {TranService} from './tran.service';

@Injectable()
export class WorkflowService {

  constructor(private httpService: HttpService,
              private tranService: TranService
  ) {
  }

  public updateWorkflow(transactionId: number, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`workflow/update_workflow_state/${transactionId}`, payload)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public resetFutureTraces(transactionId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`workflow/reset_future_traces/${transactionId}`, null)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateBulkTraces(transactionId: number, traces: Array<any>): Promise<boolean> {
    const payload = {traces: traces};
    return new Promise((resolve, reject) => {
      return this.httpService.put(`workflow/update_bulk_traces/${transactionId}`, payload)
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public UndoLastTrace(transactionId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`workflow/undo_last_trace/${transactionId}`, {})
        .then((data) => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }
}
