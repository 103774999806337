import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Declarations} from '../../../../models/declarations';
import {DeclarationExplanation} from '../../../../models/declaration-explanation';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-declarations',
  templateUrl: './view-declarations.component.html',
})
export class ViewDeclarationsComponent extends CompBaseComponent implements OnDestroy, OnInit {

  public item: Declarations;
  public explanations: Array<DeclarationExplanation>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.item = new Declarations(this.doc.recursive_attributes[this.applicant]['declaration']);
    const declarations = this.doc.recursive_attributes[this.applicant]['declaration_explanations'];
    const codes = declarations.map(elt => elt.declaration_type_code);
    const allCodes = DeclarationExplanation.declaration_codes.map(elt => elt.key);
    const missingCodes = allCodes.filter(elt => codes.indexOf(elt) < 0);
    const explanations = DeclarationExplanation.deserializeArray(declarations);
    missingCodes.forEach(code => {
      const de = DeclarationExplanation.createDeclarationExplanationFromCode(code);
      explanations.push(de);
    });
    this.explanations = explanations;
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  ngOnDestroy(): void {
  }
}
