import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-ecoa-doc',
  templateUrl: './edit-ecoa-doc.component.html',
})
export class EditECOADocComponent extends GenerateDocumentBaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public borrowerCount: number;
  @Input() folderId: string;

  // form and form controls
  public form: FormGroup;
  public ecoa_address: FormControl;
  public borrower_name: FormControl;
  public co_borrower_name: FormControl;
  public borrower3_name: FormControl;
  public borrower4_name: FormControl;
  public mlo_name: FormControl;
  public doc_date: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.generating = false;
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.ecoa_address = new FormControl(this.savedDoc.ecoa_address, AppValidators.present);
    this.borrower_name = new FormControl(this.txSummary.borrowerFullName);
    this.co_borrower_name = new FormControl(this.txSummary.coBorrowerFullName);
    this.borrower3_name = new FormControl(this.txSummary.borrower3FullName);
    this.borrower4_name = new FormControl(this.txSummary.borrower4FullName);
    this.mlo_name = new FormControl(this.txSummary.mloFullName);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      ecoa_address: this.ecoa_address,
      borrower_name: this.borrower_name,
      borrower3_name: this.borrower3_name,
      borrower4_name: this.borrower4_name,
      co_borrower_name: this.co_borrower_name,
      mlo_name: this.mlo_name,
      doc_date: this.doc_date,
    });
  }

  async genECOA(): Promise<boolean> {
    if (!this.form.valid) {
      return;
    }
    this.generating = true;
    const formData = this.form.value;
    formData['doc_date'] = moment(this.doc_date.value).format('MM/DD/YYYY');
    formData['borrowerCount'] = this.borrowerCount;
    formData['icn_id'] = this.folderId || null;
    formData['lender_case_no'] = this.txSummary.lenderCaseNo;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
