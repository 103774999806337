<h2 mat-dialog-title>Select Year & Quarter</h2>
<mat-dialog-content>
  <form [formGroup]="form" novalidate>
    <div class="row mt-3">
      <!-- year -->
      <div class="col-lg-6">
        <mat-form-field class="md-icon-left w-100">
          <mat-label>MCR year</mat-label>
          <mat-select appAutoFocus formControlName="year" placeholder="Select MCR year" required>
            <mat-option *ngFor="let yr of years" [value]="yr.key">{{yr.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="year.hasError('required')">
            MCR year is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- quarter -->
      <div class="col-lg-6">
        <mat-form-field class="md-icon-left w-100">
          <mat-label>MCR quarter</mat-label>
          <mat-select formControlName="quarter" placeholder="Select MCR quarter" required>
            <mat-option *ngFor="let qr of quarters" [value]="qr.key">{{qr.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="quarter.hasError('required')">
            MCR quarter is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>

  <button class="btn btn-lg btn-primary" [disabled]="!form.valid" [mat-dialog-close]="[year.value,quarter.value]">Update</button>
  <button class="ms-2 btn btn-lg btn-light" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
