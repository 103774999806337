<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on ezUploads Instructions
  </mat-card-title>

  <mat-card-content>
    <p>
      If you are new to PrudentCO, please get yourself familiarized with ezUploads.
      It will make you productive quickly.
    </p>
  </mat-card-content>
</mat-card>
