import {Component, Injector, OnInit} from '@angular/core';
import {Liability} from '../../../../models/liability';
import {CompBaseComponent} from '../../../comp-base.component';
import {Document} from '../../../../models/document';
import {AnalyzerService} from '../../../../services/analyzer.service';

@Component({
  selector: 'app-view-segment-liabilities',
  templateUrl: './view-segment-liabilities.component.html',

})
export class ViewSegmentLiabilitiesComponent extends CompBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
    this.segment = 'liabilities';
    this.section = this.applicant;
    this.sharedService.eventEmitted.subscribe((event) => {
      if (event.type === 'ReloadLoanApp') {
        this.loanAppService.getCurrentLoanDocument()
          .then((doc: Document) => {
            if (doc) {
              this.doc = doc;
            }
            this.reload();
          });
      }
    });
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = Liability.deserializeArray(this.loan_app[this.applicant].liabilities);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
