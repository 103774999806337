import {ModelBase} from './model-base';

export class Support extends ModelBase {
  public static support_types_ex = [
    {key: 'Alimony', value: 'Alimony'},
    {key: 'ChildSupport', value: 'Child Support'},
    {key: 'SeparateMaintenanceExpense', value: 'Separate Maintenance Payment'},
    {key: 'JobRelatedExpenses', value: 'Job Related Expense'},
    {key: 'Other', value: 'Other'}
  ];
  index: number;
  monthly_payment_amount: string;
  months_left: string;
  support_type_ex: string;
  support_type_description: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'monthly_payment_amount' || prop === 'months_left') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Support> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Support(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getSupportType(): string {
    const found = Support.support_types_ex.find((elt) => elt.key === this.support_type_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      support_type_ex: this.support_type_ex,
      support_type_description: this.support_type_description,
      monthly_payment_amount: this.toServerDecimal(this.monthly_payment_amount),
      months_left: this.toNumber(this.months_left),
    };
  }
}
