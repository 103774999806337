import {AfterViewInit, Component, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {AppValidators} from '../../../shared/validators/app-validators.validator';

@Component({
  selector: 'app-add-folder',
  templateUrl: './add-folder.component.html',
})
export class AddFolderComponent extends CompBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('nameElt') nameElt: ElementRef;
  @Input() group_id: string;
  form: FormGroup;
  name: FormControl;
  description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  ngAfterViewInit(): void {
    if (this.nameElt) {
      setTimeout(() => this.nameElt.nativeElement.focus(), 0);
    }
  }

  reset(): void {
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  add(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({group_id: this.group_id}, this.form.value);
    this.tranService.createTransactionItem(this.tran.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Folder successfully added');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to add folder', data);
        this.reload();
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  createFormControls() {
    this.name = new FormControl('', Validators.compose([AppValidators.present, Validators.minLength(3)]));
    this.description = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
      description: this.description
    });
  }
}
