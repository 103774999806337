<mat-card *ngIf="reloaded" class="mt-1">
  <mat-card-title>Grant Loan Officer Access to Transaction</mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{tran.name}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{tran.description}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Owner name</div>
      <div class="col-lg-8">{{tran.owner_full_name}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngFor="let permission of tran.permissions">
      <div class="col-lg-4 fw-bold">User access</div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-lg-4">
            <span class="fw-bold">Name:</span> {{permission.acl_full_name}}
          </div>
          <div class="col-lg-4">
            <span class="fw-bold">Username:</span> {{permission.acl_id}}
          </div>
          <div class="col-lg-4">
            <span class="fw-bold">Email:</span> {{permission.acl_email}}
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="form" novalidate class="mt-2">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="first" matInput placeholder="Loan Officer first name">
            <mat-error *ngIf="first.hasError('required')">
              Loan Officer first name is required
            </mat-error>
            <mat-error *ngIf="first.hasError('minlength')">
              Loan Officer first name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="last" matInput placeholder="Loan Officer last name">
            <mat-error *ngIf="last.hasError('required')">
              Loan Officer last name is required
            </mat-error>
            <mat-error *ngIf="last.hasError('minlength')">
              Loan Officer last name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="loanOfficers.length > 0">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-select formControlName="loanOfficer" placeholder="Select loan officer">
              <mat-option *ngFor="let lo of loanOfficers" [value]="lo.key">{{lo.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="loanOfficer.hasError('required')">
              Loan officer is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="findLoanOfficers()" *ngIf="op==='Find'" class="btn btn-lg btn-primary">Find LOs</button>
          <button (click)="grantLOAccess()" *ngIf="loanOfficers.length > 0" class="ms-2 btn btn-lg btn-primary">Grant Access</button>
          <button (click)="reload()" class="ms-2 btn btn-lg btn-light">Reset</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
