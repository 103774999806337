import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';
import {CompBaseComponent} from '../../../comp-base.component';
import {AdminService} from '../../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-pw',
  templateUrl: './admin-reset-pw.component.html',
})
export class AdminResetPwComponent extends CompBaseComponent implements OnInit {
  adminService: AdminService;
  userId: number = null;
  userName = '';
  form: FormGroup;
  user_id: FormControl;
  pw: FormControl;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.adminService = this.injector.get(AdminService);
    this.route.queryParams.subscribe((params) => {
      if (params && params['user_id']) {
        this.userId = +params['user_id'];
      }
      this.reload();
    });

    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  cancel(): void {
    this.reload();
  }

  createFormControls() {
    this.user_id = new FormControl(this.userId);
    this.pw = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      user_id: this.user_id,
      pw: this.pw,
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const payload = Object.assign({}, this.form.value);
    this.adminService.hardResetPW(payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Password successfully hard reset');
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to hard reset password', data);
      });
  }
}
