import {Component, Injector, Input, OnInit} from '@angular/core';
import {Document} from '../../../models/document';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../form-util';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CompBaseComponent} from '../../comp-base.component';

@Component({
  selector: 'app-edit-loan-app-record',
  templateUrl: './edit-loan-app-record.component.html',
})
export class EditLoanAppRecordComponent extends CompBaseComponent implements OnInit {
  @Input() loanApp: Document;

  // form and form controls
  public form: FormGroup;
  public name: FormControl;
  public is_default: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.name = new FormControl(this.loanApp.name, AppValidators.present);
    this.is_default = new FormControl(this.loanApp.isDefault());
  }

  createForm(): void {
    this.form = new FormGroup({
      name: this.name,
      is_default: this.is_default
    });
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }

    const category = !!this.is_default.value ? 0 : 1;
    const payload = {
      name: this.name.value,
      category: category
    };
    this.docService.updateDocument(this.loanApp.id, payload)
      .then((doc) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', data);
        this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      });
  }

  delete(): void {
    if (confirm('Are you sure?') === false) {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
      return;
    }

    this.docService.deleteDocument(this.loanApp.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Loan application deleted');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete loan application', data);
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }
}
