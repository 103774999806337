<mat-card *ngIf="reloaded">
  <mat-card-title>
    <div class="clearfix">
      <div class="float-left">
        My Loans
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>

    <form [formGroup]="form" novalidate>
      <div class="d-flex">
        <div>
          <mat-checkbox (change)="onFilterChange($event)" formControlName="activeTrans">Active</mat-checkbox>
        </div>
        <div>
          <mat-checkbox (change)="onFilterChange($event)" class="ms-2" formControlName="inactiveTrans">Inactive</mat-checkbox>
        </div>
        <div>
          <mat-checkbox (change)="onFilterChange($event)" class="ms-2" formControlName="terminatedTrans">Completed</mat-checkbox>
        </div>
        <div *ngIf="isDebug">
          <mat-checkbox (change)="onFilterChange($event)" class="ms-2" formControlName="sandboxTrans">Sandbox</mat-checkbox>
        </div>
      </div>
    </form>

    <div class="row pt-1 pb-1 fw-bold border-bottom">
      <div [hidden]="isMobile" class="col col-lg-1">ID</div>
      <div class="col col-lg-1">Status</div>
      <div class="col col-lg-5">Name
        <span [hidden]="isMobile" class="text-muted">(Description)</span>
      </div>
      <div [hidden]="isMobile" class="col col-lg-2">Created</div>
      <div class="col col-lg-2">Modified</div>
      <div class="col col-lg-1">
      </div>
    </div>

    <div *ngFor="let tran of filteredTrans" [ngClass]="{'pr-light-gray':tran.isSandboxTran()}" class="hand-pointer row pt-1 pb-1 hover-border border-bottom pr-light-gray">
      <div  (click)="navigateToTran(tran)" [hidden]="isMobile" class="col col-lg-1">{{tran.id}}</div>
      <div  (click)="navigateToTran(tran)" class="col col-lg-1">
        <span [ngClass]="{'badge-primary': tran.status===0,'badge-secondary': tran.status===1,'badge-warning': tran.status===2}" class="badge">
            {{tran.getStatusString()}}
        </span>
      </div>
      <div  (click)="navigateToTran(tran)" class="col col-lg-5">{{tran.name}}
        <span [hidden]="isMobile || !tran.description||tran.description.length===0" class="text-muted">({{tran.description}})</span>
      </div>
      <div  (click)="navigateToTran(tran)" [hidden]="isMobile" class="col col-lg-2">{{tran.created_at | date:'MM/dd/yyyy'}}</div>
      <div  (click)="navigateToTran(tran)" class="col col-lg-2">{{tran.updated_at | date:'MM/dd/yyyy'}}</div>
      <div class="col col-lg-1 text-end">
        <a (click)="delete($event, tran)" title="Delete Loan" class="ms-2 pr-font-large"><i class="text-danger fa fa-trash"></i></a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
