import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {TranService} from '../../../../../services/tran.service';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-patriot-doc',
  templateUrl: './edit-patriot-doc.component.html',
})
export class EditPatriotDocComponent extends GenerateDocumentBaseComponent implements OnInit {
  public txSummary: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public minPersonDOB = globals.date.minPersonDOB;
  public maxPersonDOB = globals.date.maxPersonDOB;
  public maxExpDate = globals.date.maxDocExpDate;
  public minExpDate = new Date();
  public minIssueDate = globals.date.minDocIssueDate;
  public maxIssueDate = new Date();
  public isCoBorrower: boolean;
  public applicants: Array<any> = [];
  @Input() folderId: string;

  // form and form controls
  public form: FormGroup;
  public name: FormControl;
  public dob: FormControl;
  public address: FormControl;
  public primary_document_type: FormControl;
  public primary_document_number: FormControl;
  public primary_issue_date: FormControl;
  public primary_expiration_date: FormControl;
  public primary_issued_by: FormControl;
  public secondary_document_type: FormControl;
  public secondary_document_number: FormControl;
  public secondary_issue_date: FormControl;
  public secondary_expiration_date: FormControl;
  public secondary_issued_by: FormControl;
  public doc_date: FormControl;
  public mlo_name: FormControl;
  public who: FormControl;
  public flatten: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.formType = 'PATRIOT';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.applicants = this.analyzerService.getBorrowerTable(this.doc.recursive_attributes);
    this.generating = false;
    this.createFormControls();
    this.createForm();
    this.onSelectionChange();
    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }

  onSelectionChange(): void {
    this.reloaded = false;
    const who = this.who.value;
    const form_who = this.savedDoc[who] || {};
    const attrs = ['name', 'address', 'primary_document_type', 'primary_document_number',
      'primary_issued_by', 'secondary_document_type', 'secondary_document_number', 'secondary_issued_by'];
    attrs.forEach((attr) => {
      this[attr].setValue(form_who[attr])
    });
    this.mlo_name.setValue(this.txSummary['mloFullName'])
    const dateAttrs = ['doc_date', 'dob', 'primary_issue_date', 'primary_expiration_date', 'secondary_issue_date', 'secondary_expiration_date'];
    dateAttrs.forEach((attr) => {
      this[attr].setValue(form_who[attr] ? new Date(form_who[attr]) : null);
    });

    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }

  getName(): string {
    const borrower = TranService.getBorrower(this.tran, +this.who.value);
    return borrower['full_name'] || null;
  }

  getDOB(): string {
    const bor = TranService.getBorrower(this.tran, +this.who.value);
    const dt = moment(bor['dob'], 'YYYYMMDD');
    return dt.isValid() ? moment(dt).format('YYYY-MM-DD') : null;
  }

  getWhoString(): string {
    if (!this.who.value || this.who.value == 'b1') {
      return 'Borrower';
    }
    if (this.who.value == 'b2') {
      return 'Co-Borrower';
    }
  }

  createFormControls(): void {
    this.isCoBorrower = this.txSummary.isCoBorrower;
    this.who = new FormControl(null);
    this.dob = new FormControl(null, AppValidators.present);
    this.name = new FormControl(null, AppValidators.present);
    this.address = new FormControl(null, AppValidators.present);
    this.primary_document_type = new FormControl(null);
    this.primary_document_number = new FormControl(null);
    this.primary_issue_date = new FormControl(null);
    this.primary_expiration_date = new FormControl(null);
    this.primary_issued_by = new FormControl(null);
    this.secondary_document_type = new FormControl(null);
    this.secondary_document_number = new FormControl(null);
    this.secondary_issue_date = new FormControl(null);
    this.secondary_expiration_date = new FormControl(null);
    this.secondary_issued_by = new FormControl(null);
    console.log('txSummary', this.txSummary);
    this.mlo_name = new FormControl(this.txSummary.mloFullName);
    this.flatten = new FormControl(true);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      who: this.who,
      name: this.name,
      dob: this.dob,
      address: this.address,
      doc_date: this.doc_date,
      mlo_name: this.mlo_name,
      primary_document_type: this.primary_document_type,
      primary_document_number: this.primary_document_number,
      primary_issue_date: this.primary_issue_date,
      primary_expiration_date: this.primary_expiration_date,
      primary_issued_by: this.primary_issued_by,
      secondary_document_type: this.secondary_document_type,
      secondary_document_number: this.secondary_document_number,
      secondary_issue_date: this.secondary_issue_date,
      secondary_expiration_date: this.secondary_expiration_date,
      secondary_issued_by: this.secondary_issued_by,
      flatten: this.flatten,
    });
  }

  async genPATRIOT(): Promise<boolean> {
    if (!this.form.valid) {
      return;
    }
    const docDate = moment(this.doc_date.value, 'MM/DD/YYYY').isValid() ? moment(this.doc_date.value).format('MM/DD/YYYY') : null;
    const dobDate = moment(this.dob.value).isValid() ? moment(this.dob.value).format('MM/DD/YYYY') : null;
    const primaryIssueDate = moment(this.primary_issue_date.value).isValid() ? moment(this.primary_issue_date.value).format('MM/DD/YYYY') : null;
    const primaryExpirationDate = moment(this.primary_expiration_date.value).isValid() ? moment(this.primary_expiration_date.value).format('MM/DD/YYYY') : null;
    const secondaryIssueDate = moment(this.secondary_issue_date.value).isValid() ? moment(this.secondary_issue_date.value).format('MM/DD/YYYY') : null;
    const secondaryExpirationDate = moment(this.secondary_expiration_date.value).isValid() ? moment(this.secondary_expiration_date.value).format('MM/DD/YYYY') : null;
    const formData = {
      form_type: 'PATRIOT',
      who: this.who.value,
      icn_id: (this.folderId || null),
      name: this.name.value,
      dob: dobDate,
      address: this.address.value,
      doc_date: docDate,
      mlo_name: this.mlo_name.value,
      primary_document_type: this.primary_document_type.value,
      primary_document_number: this.primary_document_number.value,
      primary_issue_date: primaryIssueDate,
      primary_expiration_date: primaryExpirationDate,
      primary_issued_by: this.primary_issued_by.value,
      secondary_document_type: this.secondary_document_type.value,
      secondary_document_number: this.secondary_document_number.value,
      secondary_issue_date: secondaryIssueDate,
      secondary_expiration_date: secondaryExpirationDate,
      secondary_issued_by: this.secondary_issued_by.value
    };
    this.generating = true;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
