<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>Credit Report
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Credit report name</mat-label>
            <input formControlName="name" matInput placeholder="Credit report name" appAutoFocus  maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="name.hasError('required')">
              Credit report name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput placeholder="Description" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update($event)" class="btn btn-lg btn-primary" [disabled]="!form.valid">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div >
  <mat-spinner [diameter]="68" [hidden]="reloaded" class="ms-5 mb-3"></mat-spinner>
</div>
