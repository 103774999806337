import {Component, Injector, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {globals} from "../../../../shared/globals/globals";
import {GenerateDocumentBaseComponent} from "../generate-document-base.component";

@Component({
  selector: 'app-fee-worksheet-base',
  template: '',
})
export class FeeWorksheetBaseComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() section: string;
  public router: Router;
  public readonly tabKeys = globals.section;
  // comments:
  // Hazard insurance appears under "Hazard"
  // MI appears under "MI"
  // Balloon payment appears "Balloon Payment"
  // mi_premium_months removed because this is a computed item
  // property_tax appears under "Property Tax"
  public readonly feeWorksheetStock = {
    // OK
    "general": [
      // OK
      {
        "type_ex": "General",
        "key": "date_issued",
        "note": "",
        "item_desc": "Date Issued",
        "value": "",
        "type": "date",
        "stock": "true"
      },
      // OK
      {
        "type_ex": "General",
        "key": "prepaid_days",
        "note": "",
        "item_desc": "Number of Prepaid Days",
        "value": "15",
        "type": "integer",
        "stock": "true"
      }
    ],
    // OK
    "transaction_details": [
      // TBD: init from loan app data
      {
        "type_ex": "Details",
        "key": "purchase_price",
        "note": "",
        "item_desc": "Property Sale Price",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK? - This item is applicable to refinance for refinance for home improvement OR construction loan
      {
        "type_ex": "Details",
        "key": "construction_improvements_cost",
        "note": "",
        "item_desc": "Renovation Cost",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // TBD: init from loan app data
      {
        "type_ex": "Details",
        "key": "land_original_cost",
        "note": "",
        "item_desc": "Cost of Land Acquired Separately",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK? - this can be calculated from liabilities. Now, there is no such feature. We can leave it as is for the time being.
      {
        "type_ex": "Details",
        "key": "mortgage_loan_payoff_amount",
        "note": "",
        "item_desc": "Mortgage Loan Payoff Amount",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK? - same as in the above
      {
        "type_ex": "Details",
        "key": "other_debt_payoff_amount",
        "note": "",
        "item_desc": "Other Debt Payoff Amount",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK? - Don't find such entry in MISMO or the loan application
      // Need to further research this one, but it is part of the standard fee worksheet
      {
        "type_ex": "Details",
        "key": "outside_cc_amount",
        "note": "",
        "item_desc": "Outside Closing Cost Amount",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    // OK
    "other_payments" : [
      // OK. We can Deduce subordinate loan payment from liabilities. Not available now.
      {
        "type_ex": "Other Payments",
        "key": "subordinate_lien_payment_per_month",
        "note": "",
        "item_desc": "Subordinate lien Payment per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // we can figure this amount from HomeownersAssociationDuesAndCondominiumFees in la_expense <===
      {
        "type_ex": "Other Payments",
        "key": "hoa_condominium_dues_per_month",
        "note": "",
        "item_desc": "HOA and Condominium Payment per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // we can figure this amount from la_expense under "Other" <===
      {
        "type_ex": "Other Payments",
        "key": "other_payments_per_month",
        "note": "",
        "item_desc": "Other Payments per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    // OK
    "loan_terms": [
      // OK - we can figure out from loan application
      {
        "type_ex": "Loan Terms",
        "key": "loan_identifier",
        "note": "",
        "item_desc": "Loan Identifier",
        "value": "",
        "type": "string",
        "stock": "true"
      },
      // OK - we can figure out from loan application
      {
        "type_ex": "Loan Terms",
        "key": "loan_duration_months",
        "note": "",
        "item_desc": "Loan Duration in Months",
        "value": "",
        "type": "integer",
        "stock": "true"
      },
      // OK - we can figure out from loan application
      {
        "type_ex": "Loan Terms",
        "key": "loan_amount",
        "note": "",
        "item_desc": "Loan Amount",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - we can figure out from loan application
      {
        "type_ex": "Loan Terms",
        "key": "loan_interest_rate",
        "note": "",
        "item_desc": "Interest Rate",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      // OK - we can figure out from loan application
      {
        "type_ex": "Loan Terms",
        "key": "loan_product",
        "note": "",
        "item_desc": "Loan Product",
        "value": "",
        "type": "string",
        "stock": "true"
      },
    ],
    // OK
    "title":[
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Shop",
        "key": "title_lender_policy_fee",
        "note": "",
        "item_desc": "Title - Lender's Title Policy Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Shop",
        "key": "title_settlement_fee",
        "note": "",
        "item_desc": "Title - Settlement Agent Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Shop",
        "key": "title_search_fee",
        "note": "",
        "item_desc": "Title - Title Search Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Shop",
        "key": "title_courier_fee",
        "note": "",
        "item_desc": "Title - Courier Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    // Input for insurance items is not in the LE
    "insurance":[
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Hazard",
        "key": "hazard_insurance_per_year",
        "note": "",
        "item_desc": "Hazard Insurance per Year",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Insurance",
        "key": "supplemental_insurance_per_year",
        "note": "",
        "item_desc": "Supplemental Insurance Payment per Month",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK
      {
        "type_ex": "MI",
        "key": "mi_insurance_rate",
        "note": "",
        "item_desc": "MI Premium Rate Percentage per Year",
        "value": "",
        "type": "percent",
        "stock": "true"
      }
    ],
    // OK
    "taxes_and_fees": [
      // OK? - this is a new type_ex
      {
        "type_ex": "Property Tax",
        "key": "property_tax_per_year",
        "note": "",
        "item_desc": "Property Tax per Year",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Taxes and Other Government Fees",
        "key": "recording_fee",
        "note": "",
        "item_desc": "Recording Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - this is not in the FNMA gem
      {
        "type_ex": "Taxes and Other Government Fees",
        "key": "transfer_fee",
        "note": "",
        "item_desc": "Transfer Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    // OK
    "origination": [
      // OK - not in FNMA gem. Comment: need to somehow include the origination percent in here
      {
        "type_ex": "Origination",
        "key": "points",
        "note": "",
        "item_desc": "Origination Fee (Points)",
        "value": "",
        "type": "percent",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "Origination",
        "key": "loan_application_fee",
        "note": "",
        "item_desc": "Application Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "Origination",
        "key": "processing_fee",
        "note": "",
        "item_desc": "Processing Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "Origination",
        "key": "lender_underwriting",
        "note": "",
        "item_desc": "Lender Underwriting Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "No Shop",
        "key": "appraisal_fee",
        "note": "",
        "item_desc": "Appraisal Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "No Shop",
        "key": "credit_report_fee",
        "note": "",
        "item_desc": "Credit Report Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "No Shop",
        "key": "flood_fee",
        "note": "",
        "item_desc": "Flood Determination Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "No Shop",
        "key": "lender_tax_research_fee",
        "note": "",
        "item_desc": "Tax Status Research Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "Shop",
        "key": "inspection_fee",
        "note": "",
        "item_desc": "Inspection Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
      // OK - not in FNMA gem
      {
        "type_ex": "Shop",
        "key": "survey_fee",
        "note": "",
        "item_desc": "Survey Fee",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    // OK
    "other": [
      // OK - not in FNMA gem and I don't think we ever need to add it to the gem
      {
        "type_ex": "Other",
        "key": "homeowner_title_policy",
        "note": "",
        "item_desc": "Title -- Owner Title Policy (optional)",
        "value": "",
        "type": "currency",
        "stock": "true"
      },
    ],
    "escrow": [
      {
        "type_ex": "Escrow",
        "key": "escrow_hazard_insurance",
        "note": "",
        "item_desc": "Escrow - Homeowner Insurance",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
      {
        "type_ex": "Escrow",
        "key": "escrow_property_taxes",
        "note": "",
        "item_desc": "Escrow - Property Taxes",
        "value": "",
        "type": "boolean",
        "stock": "true"
      },
    ],
    "prepaids": [ ]
  }
  public sections = [];

  constructor(public injector: Injector) {
    super(injector);
    this.router = this.injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.formType = 'FEE_WORKSHEET';
    this.sections = Object.keys(this.feeWorksheetStock);
    this.savedDoc = this.doc.content3[this.formType] || {};
  }

  getSectionTitle(): string {
    switch (this.section) {
      case globals.section.general:
        return 'General';
      case globals.section.transaction_details:
        return 'Transaction Details'
      case globals.section.loan_terms:
        return 'Loan Details'
      case globals.section.lender:
        return 'Lender'
      case globals.section.other_payments:
        return 'Other Payments'
      case globals.section.origination:
        return 'Origination'
      case globals.section.other:
        return 'Other Fees'
      case globals.section.title:
        return 'Title'
      case globals.section.insurance:
        return 'Insurance'
      case globals.section.taxes_and_fees:
        return 'Taxes & Fees'
      case globals.section.escrow:
        return 'Escrow'
    }
  }
}
