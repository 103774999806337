export const MENU: any = [
  {
    title: 'Home',
    icon: {
      class: 'fa fa-home'
    },
    sub: [
      {
        title: 'Your Services',
        routing: '/home/services',
      },
      {
        title: 'Acct. Highlights',
        routing: '/home/highlights',
      },
      {
        title: "Loan Owner's Info",
        routing: '/home/lo-info',
      },
      {
        title: 'Site Map',
        routing: '/home/site-layout',
      },
    ]
  },
  {
    title: 'Account',
    icon: {
      class: 'fa fa-cog'
    },
    sub: [
      {
        title: 'Account Settings',
        routing: '/account/settings'
      },
      {
        title: 'Your Services',
        routing: '/account/services',
      },
      {
        title: 'MLO Configuration',
        routing: '/account/mlo-configuration',
        permission: 'CanBeLoanProfessional'
      },
      {
        title: 'Organization',
        routing: '/account/user-organization',
        permission: 'CanReadOrg'
      },
      {
        title: 'Branding',
        routing: '/account/branding',
        permission: 'CanBeLoanProfessional'
      },
      {
        title: 'Invite Users',
        routing: '/account/invite',
      },
      {
        title: 'Contacts',
        routing: '/account/view-contacts',
        permission: 'CanReadContacts'
      },
      {
        title: 'Reset Password',
        routing: '/account/reset-password'
      },
    ]
  },
  {
    title: 'ezUploads',
    permission: 'CanCreateEzUploads',
    icon: {
      class: 'fa fa-cloud-upload-alt'
    },
    sub: [
      {
        title: 'ezUploads Inst.',
        permission: 'CanCreateEzUploads',
        routing: '/ez-uploads/ez-uploads-instructions'
      },
      {
        title: 'Secured Uploads',
        permission: 'CanCreateEzUploads',
        routing: '/ez-uploads/secured-uploads',
      },
    ]
  },
  {
    title: 'Communication',
    permission: 'CanCommunicate',
    icon: {
      class: 'fa fa-envelope'
    },
    sub: [
      {
        title: 'Messages',
        routing: '/loan/view-messages',
      },
      {
        title: 'Emails',
        routing: '/loan/view-emails',
        permission: 'CanCommunicate',
      },
    ]
  },
  {
    title: 'MCR',
    permission: 'CanCreateMCRs',
    icon: {
      class: 'fa fa-id-card'
    },
    sub: [
      {
        title: 'Wizard',
        routing: '/mcr/view-wizard',
      },
    ]
  },
  {
    title: 'My Loans',
    permission: 'CanReadTransactions',
    icon: {
      class: 'fa fa-folder-plus'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'My Dashboard',
        routing: '/loans/loan-officer-dashboard',
        permission: 'CanBeLoanProfessional'
      },
      {
        title: 'My Loans',
        routing: '/loans/list',
        permission: 'CanReadTransactions'
      },
      {
        title: 'My MCRs',
        routing: '/loans/lo-mcr/list',
        permission: 'CanCreateMCRs'  //restricted to loan officer with canLoans
      },
      {
        title: 'New Loan',
        routing: '/loans/new-loan',
        permission: 'CanCreateTransaction'  //restricted to loan officer with canLoans
      },
    ]
  },
  // Disabled for now. And working only in debug mode for borrower
  {
    title: 'My Sandbox',
    permission: 'CanCreateSandboxTransaction',
    icon: {
      class: 'fa fa-folder-plus'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'Sandbox Loans',
        routing: '/sandbox/list',
        permission: 'CanCreateSandboxTransaction'
      },
      {
        title: 'New Sandbox Loan',
        routing: '/sandbox/new-loan',
        permission: 'CanCreateSandboxTransaction'
      },
    ]
  },
  {
    title: 'Current Loan',
    permission: 'CanReadTimeline',
    icon: {
      class: 'fa fa-folder-open'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'Dashboard',
        routing: '/loan/view-dashboard',
        permission: 'CanReadDashboard'
      },
      {
        title: 'Conditions',
        routing: '/loan/conditions',
        permission: 'CanUpdateTransaction'
      },
      {
        title: 'Repository',
        routing: '/loan/view-directory',
        permission: 'CanReadRepo'
      },
      {
        title: 'Loan Application',
        routing: '/loan/view-loan-app',
        permission: 'CanReadLoanApp' // check specific permission to the default loan application
      },
      {
        title: 'Workflow',
        routing: '/loan/view-workflow',
        permission: 'CanUpdateTransaction'  //restricted to loan pro
      },
      {
        title: 'Tasks',
        routing: '/loan/view-tasks',
        permission: 'CanUpdateTransaction'  //restricted to loan pro
      },
      {
        title: 'Timeline',
        routing: '/loan/view-timeline',
        permission: 'CanReadTimeline'
      },
      {
        title: 'Compliance',
        routing: '/loan/view-comp-events',
        permission: 'CanUpdateTransaction' //restricted to loan pro
      },
      {
        title: 'Notes',
        routing: '/loan/view-notes',
        permission: 'CanUpdateTransaction'  //restricted to loan pro
      },
      {
        title: 'Access Control',
        routing: '/loan/view-access-control',
        permission: 'CanDeleteTransaction'
      },
    ]
  },
  {
    title: 'Business',
    permission: 'CanPresent',
    hidden: true,
    icon: {
      class: 'fa fa-user'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'Financials',
        routing: '/admin/view-pitch',
        permission: 'CanPresent'
      },
      {
        title: 'Convertible Loan',
        routing: '/admin/view-convertible',
        permission: 'CanPresent'
      },
    ]
  },

  // admin only functions
  {
    title: 'Admin',
    permission: 'CanCreateUser', // functions tha can only be accessed by admin
    hidden: true,
    icon: {
      class: 'fa fa-user'
    },
    badge: {
      color: '#fff',
      bg: '#FF8426'
    },
    sub: [
      {
        title: 'Config',
        routing: '/admin/configs',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Trans',
        routing: '/admin/find-trans',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Orders',
        routing: '/admin/find-orders',
        permission: 'CanCreateUser'
      },
      {
        title: 'New User',
        routing: '/admin/new-user',
        permission: 'CanCreateUser'
      },
      {
        title: 'Delete User',
        routing: '/admin/delete-user',
        permission: 'CanCreateUser'
      },
      {
        title: 'Reset Password',
        routing: '/admin/reset-pw',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Users',
        routing: '/admin/find-users',
        permission: 'CanCreateUser'
      },
      {
        title: 'Send Soc Message',
        routing: '/admin/send-socket-message',
        permission: 'CanCreateUser'
      },
      {
        title: 'Alerts',
        routing: '/admin/alerts',
        permission: 'CanCreateUser'
      },
      {
        title: 'New Organization',
        routing: '/admin/new-organization',
        permission: 'CanCreateUser'
      },
      {
        title: 'Find Organizations',
        routing: '/admin/find-organizations',
        permission: 'CanCreateUser'
      },
    ]
  },
];
