<mat-card *ngIf="tran&&canUpdateLoanRecord">
  <mat-card-title>Loan Record</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Name</mat-label>
            <input appAutoFocus formControlName="name" matInput placeholder="Name" maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput placeholder="Description"  maxlength="{{MAX_LEN.L_DESC}}">
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
              Description is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Loan status</mat-label>
            <mat-select formControlName="status" placeholder="Select" required>
              <mat-option *ngFor="let status of statuses" [value]="status.key">{{status.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="status.hasError('required')">
              Status is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
