import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';
import {FormUtil} from '../../../../form-util';

@Component({
  selector: 'app-view-cfa',
  templateUrl: './view-cfa.component.html',
})
export class ViewCFAComponent extends CompBaseComponent implements OnInit {
  public tranId: number;
  public worksheet_hash: any;
  public hash: any;
  public generating: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  // updates total and other summations
  onEvent(event: any): void {
    if (event.type === 'Entry') {
      this.computeTotal('field1');
      this.computeTotal('field2');
      return;
    }
    super.onEvent(event);
  }

  computeTotal(field: string): void {
    let total = null;
    Object.keys(this.hash).forEach((key) => {
      const val = this.hash[key][field];
      if (val && /^\d/.test(key)) {
        if (total === null) {
          total = 0.0;
        }
        if (this.hash[key]['prefix'] === 'm') {
          total -= +val;
        } else {
          total += +val;
        }
      }
    });
    if (total !== null) {
      this.hash['total'][field] = total.toString();
    } else {
      this.hash['total'][field] = null;
    }
  }

  save(): void {
    this.tranService.updateTranWS(this.tranId, 'CFA', {payload: this.worksheet_hash})
      .then((data) => {
        this.reload();
      });
  }

  generateCFADoc(): void {
    this.generating = true;
    this.docService.generateCFADoc(this.doc.id)
      .then((data) => {
        const message = `The CFA document successfully generated and placed in Current Loan/Repository/Documents. Please refer to this section's Help`;
        FormUtil.genSuccessMessage(this.sharedService, message, false);
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'System Error. Failed to generate CFA document', data);
      });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.generating = false;
    this.tranService.getCurrentTranId()
      .then((tranId) => {
        this.tranId = tranId;
        return this.tranService.getTranWS(tranId, 'CFA');
      }).then((doc) => {
      this.doc = doc;
      this.worksheet_hash = doc.content2;
      this.hash = this.worksheet_hash['CFA'];
      this.reloaded = true;
    });
  }
}
