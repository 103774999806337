<mat-card *ngIf="reloaded">
  <mat-card-title>
    ezUploads Instructions
  </mat-card-title>

  <mat-card-content *ngIf="hasEzUploadsService">
    <h5>
      To send an upload file request:
    </h5>

    <ul>
      <li>Navigate to <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.</li>
      <li>Select the ezUploads Request tab.</li>
      <li>Provide the contact information of your customer and the files you are requesting.</li>
      <li>Click Send Request.</li>
    </ul>

    <h5>
      To receive uploaded files:
    </h5>

    <ul>
      <li>Navigate to <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads/Secured Uploads</a>.</li>
      <li>For each uploaded file, verify that it is indeed what you asked for.
        The <b>"Requester"</b> and <b>"Uploader"</b> texts are the descriptions that you and your customer supplied, respectively.
      </li>
      <li>If the file is a PDF, click the PDF icon to download it.
        If the file is an image, click the image icon and then save it. Use the browser's "save image" function (Save Image As if you use Chrome) to save the image.
      </li>
      <li>When you're done downloading the file, delete it.</li>
    </ul>
  </mat-card-content>
</mat-card>
