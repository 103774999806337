<form [formGroup]="form" novalidate>
  <div *ngIf="!name" class="row">
    <div class="col-lg-6 hand-pointer" (click)="cancel()">
      {{hash[hashEntry]['caption']}}
    </div>

    <div class="col-lg-2">
      <mat-form-field class="w-100">
        <mat-label *ngIf="currency">Amount</mat-label>
        <mat-label *ngIf="!currency">Year</mat-label>
        <input *ngIf="currency" #field1Elt appDecimalOnly formControlName="field1" matInput placeholder="Amount" maxlength="{{MAX_LEN.CURR}}">
        <input *ngIf="!currency" #field1Elt appNumberOnly formControlName="field1" matInput placeholder="Year" maxlength="4">
        <mat-error *ngIf="field1.hasError('pattern')">
          Entry is invalid
        </mat-error>
        <mat-error *ngIf="field1.hasError('required')">
          Entry is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-2">
    </div>

    <div class="col-lg-2">
      <mat-form-field class="w-100">
        <mat-label *ngIf="currency">Amount</mat-label>
        <mat-label *ngIf="!currency">Year</mat-label>
        <input *ngIf="currency" #field2Elt appDecimalOnly formControlName="field2" matInput placeholder="Amount" maxlength="{{MAX_LEN.CURR}}">
        <input *ngIf="!currency" #field2Elt appNumberOnly formControlName="field2" matInput placeholder="Year" maxlength="4">
        <mat-error *ngIf="field2.hasError('pattern')">
          Entry is invalid
        </mat-error>
        <mat-error *ngIf="field2.hasError('required')">
          Entry is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-2">
      <button class="btn btn-xs btn-primary" (click)="submit()" style="display:none" type="submit"></button>
    </div>
  </div>

  <div *ngIf="name" class="row">
    <div class="col-lg-6 hand-pointer" (click)="cancel()">
      {{hash[hashEntry]['caption']}}
    </div>

    <div class="col-lg-6">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input #field1Elt formControlName="field1" matInput placeholder="Name" maxlength="50">
        <mat-error *ngIf="field1.hasError('pattern')">
          Entry is invalid
        </mat-error>
        <mat-error *ngIf="field1.hasError('required')">
          Entry is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-2">
      <button class="btn btn-xs btn-primary" (click)="submit()" style="display:none" type="submit"></button>
    </div>
  </div>
</form>
