<form [formGroup]="form" class="mt-3">
  <mat-form-field class="w-100 mb-2">
    <mat-label>Declaration explanation</mat-label>
    <textarea cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="3"
      cdkTextareaAutosize
      formControlName="declaration_explanation"
      matInput maxlength="{{MAX_LEN.L_DESC}}"
      placeholder="Please explain declaration">
   </textarea>
    <mat-error *ngIf="declaration_explanation.hasError('required')">
      Declaration explanation is required
    </mat-error>
  </mat-form-field>

  <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
  <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
</form>
