import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Declarations} from '../../../../models/declarations';
import {DeclarationExplanation} from '../../../../models/declaration-explanation';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-declarations',
  templateUrl: './edit-segment-declarations.component.html',
})
export class EditSegmentDeclarationsComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Declarations;
  @Input() public explanations: Array<DeclarationExplanation>;

  // key-value choices from model used in declarations
  public citizen_types = Declarations.citizen_types;
  public property_types = Declarations.property_types;
  public title_types = Declarations.title_types;
  public declaration_text = Declarations.declaration_text;

  // form and form controls
  form: FormGroup;
  judgments: FormControl;
  bankrupt: FormControl;
  foreclosures: FormControl;
  deed_in_lieu: FormControl;
  law_suits: FormControl;
  default_loans: FormControl;
  delinquent_federal_loans: FormControl;
  child_support: FormControl;
  down_payment_borrowed: FormControl;
  endorser_loans: FormControl;
  citizen_ex: FormControl;
  primary_residence_ex: FormControl;
  ownership_interest_ex: FormControl;
  property_type_ex: FormControl;
  title_type_ex: FormControl;
  relationship: FormControl;
  new_mortgage: FormControl;
  new_credit: FormControl;
  short_sale: FormControl;
  bankrupt_chapter_7: FormControl;
  bankrupt_chapter_11: FormControl;
  bankrupt_chapter_12: FormControl;
  bankrupt_chapter_13: FormControl;
  priority_lien: FormControl;
  borrowed_amount: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'declaration';
    this.section = this.applicant;
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.judgments = new FormControl(item.judgments);
    this.bankrupt = new FormControl(item.bankrupt);
    this.foreclosures = new FormControl(item.foreclosures);
    this.deed_in_lieu = new FormControl(item.deed_in_lieu);
    this.law_suits = new FormControl(item.law_suits);
    this.default_loans = new FormControl(item.default_loans);
    this.delinquent_federal_loans = new FormControl(item.delinquent_federal_loans);
    this.child_support = new FormControl(item.child_support);
    this.down_payment_borrowed = new FormControl(item.down_payment_borrowed);
    this.endorser_loans = new FormControl(item.endorser_loans);
    this.citizen_ex = new FormControl(item.citizen_ex);
    this.primary_residence_ex = new FormControl(item.primary_residence_ex);
    this.ownership_interest_ex = new FormControl(item.ownership_interest_ex);
    this.property_type_ex = new FormControl(item.property_type_ex);
    this.title_type_ex = new FormControl(item.title_type_ex);
    this.relationship = new FormControl(item.relationship);
    this.new_mortgage = new FormControl(item.new_mortgage);
    this.new_credit = new FormControl(item.new_credit);
    this.short_sale = new FormControl(item.short_sale);
    this.bankrupt_chapter_7 = new FormControl(item.bankrupt_chapter_7);
    this.bankrupt_chapter_11 = new FormControl(item.bankrupt_chapter_11);
    this.bankrupt_chapter_12 = new FormControl(item.bankrupt_chapter_12);
    this.bankrupt_chapter_13 = new FormControl(item.bankrupt_chapter_13);
    this.priority_lien = new FormControl(item.priority_lien);
    this.borrowed_amount = new FormControl(item.borrowed_amount);
  }

  createForm(): void {
    this.form = new FormGroup({
      judgments: this.judgments,
      bankrupt: this.bankrupt,
      foreclosures: this.foreclosures,
      deed_in_lieu: this.deed_in_lieu,
      law_suits: this.law_suits,
      default_loans: this.default_loans,
      delinquent_federal_loans: this.delinquent_federal_loans,
      child_support: this.child_support,
      down_payment_borrowed: this.down_payment_borrowed,
      endorser_loans: this.endorser_loans,
      citizen_ex: this.citizen_ex,
      primary_residence_ex: this.primary_residence_ex,
      ownership_interest_ex: this.ownership_interest_ex,
      property_type_ex: this.property_type_ex,
      title_type_ex: this.title_type_ex,
      relationship: this.relationship,
      new_mortgage: this.new_mortgage,
      new_credit: this.new_credit,
      short_sale: this.short_sale,
      bankrupt_chapter_7: this.bankrupt_chapter_7,
      bankrupt_chapter_11: this.bankrupt_chapter_11,
      bankrupt_chapter_12: this.bankrupt_chapter_12,
      bankrupt_chapter_13: this.bankrupt_chapter_13,
      priority_lien: this.priority_lien,
      borrowed_amount: this.borrowed_amount,
    });
  }

  isBorrowedAmountRequired(): boolean {
    return this.down_payment_borrowed.value === true;
  }

  // val is the declaration name
  onChange(event: any, val: string): void {
    let boolValue: boolean;
    if (typeof (event.value) === 'boolean') {
      boolValue = event.value;
    } else if (typeof (event.value) === 'string') {
      if (event.value === 'true') {
        boolValue = true;
      } else if (event.value === 'false') {
        boolValue = true;
      } else {
        boolValue = null;
      }
    } else {
      boolValue = null;
    }
    this[val].setValue(boolValue);
  }

  getExplanation(val: string): DeclarationExplanation {
    return this.explanations.find((elt) => elt.declarationTypeValue === val);
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });

    if (!this.form.valid) {
      return;
    }

    const updated = new Declarations(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
