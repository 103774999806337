import {Injectable} from '@angular/core';
import {User} from "../models/user";
import {Organization} from "../models/organization";
import {UserService} from "./user.service";

@Injectable()
export class ProcessService {
  public static task = '0';
  public static maxTask = '0';
  public static nodesCount = 8;

  constructor(private userService: UserService) {
  }

  public updateProcess(user: User, organization: Organization): boolean {
    try {
      if (!this.isCompanyNmls(user)) {
        ProcessService.maxTask = '1';
      } else if (!organization) {
        ProcessService.maxTask = '2';
      } else {
        ProcessService.maxTask = '99';
      }
      return true;
    } catch {
      return false;
    }
  }

  public progressTask(): string {
    let nextNodeSymbol: number = (+ProcessService.task + 1) % ProcessService.nodesCount;
    if (['1', '2'].includes(ProcessService.maxTask) && (nextNodeSymbol > +ProcessService.maxTask)) {
      nextNodeSymbol = +ProcessService.maxTask;
    }
    ProcessService.task = nextNodeSymbol.toString();
    return ProcessService.task;
  }

  public regressTask(): string {
    console.log('task', ProcessService.task);
    if (ProcessService.task === '0') {
      return ProcessService.task;
    }
    const nextNodeSymbol: number = (+ProcessService.task - 1) % ProcessService.nodesCount;
    ProcessService.task = nextNodeSymbol.toString();
    return ProcessService.task;
  }

  // Helper Functions
  public isCompanyNmls(user: User): boolean {
    const companyNmls: string = user.getCompanyNmls();
    return companyNmls && (companyNmls.length > 0);
  }
}
