import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UIModule} from '../components/ui.module';
import {UserService} from '../services/user.service';
import {SharedModule} from '../shared/shared.module';
import {Page500Component} from './extra-pages/page-500/page-500.component';
import {Page401Component} from './extra-pages/page-401/page-401.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';

import {Page404Component} from './extra-pages/page-404/page-404.component';
import {PageSignOutComponent} from './extra-pages/sign-out/sign-out.component';
import {PageDeviceComponent} from './extra-pages/device/device.component';
import {PageVerifyDeviceComponent} from './extra-pages/verify-device/verify-device.component';
import {PageUploadPdfComponent} from './extra-pages/upload-pdf/upload-pdf.component';
import {PageUnsubscribeComponent} from './extra-pages/unsubscribe/unsubscribe.component';

@NgModule({
  imports: [
    UIModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSliderModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatInputModule,
    MatGridListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
  ],
  declarations: [
    Page404Component,
    PageSignOutComponent,
    PageDeviceComponent,
    PageVerifyDeviceComponent,
    PageUploadPdfComponent,
    Page500Component,
    Page401Component,
    PageUnsubscribeComponent,
  ],
  exports: [
    Page404Component,
    PageSignOutComponent,
  ],
  providers: [
    UserService
  ],
})
export class PagesModule {
}
