import {Component, Injector, OnInit} from '@angular/core';
import {PermissionComponent} from '../permission/permission.component';

@Component({
  selector: 'app-edit-segment-permission',
  templateUrl: '../permission/edit-segment-permission.component.html',
})
export class EditSegmentPermissionComponent extends PermissionComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
