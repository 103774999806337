<mat-card *ngIf="reloaded">
  <mat-card-content>
    <div *ngIf="hasNoService">
      <h4>No Services</h4>
      <p>
        Your account has no services. If you believe this is an error, please
        <a href="" class="pr-rla" routerLink="/home/contact">Contact Us</a> .
      </p>
    </div>

    <div *ngIf="hasBorrowerService">
      <h4>Borrower Service</h4>
      <p>
        PrudentCO's Online Loan Origination System (POLOS) allows you to efficiently view/complete a loan application and keep track of the loan progress.
        Key features include:
      </p>
      <ul>
        <li>
          <a href="" routerLink="/account/view-contacts">Account/Contacts:</a> View your contacts.
        </li>
        <li>
          <a href="" routerLink="/loan/view-messages">Communication/Messages: </a> Send and receive messages.
        </li>
        <li>
          <a href="" routerLink="/loans/list">My Loans:</a> Access and view your loan(s) information.
        </li>
      </ul>
    </div>

    <div *ngIf="hasAncillaryService">
      <h4>Ancillary Service</h4>
      <p>
        PrudentCO's Online Loan Origination System (POLOS) allows you to keep track of the loan progress and communicate with the loan stakeholders.
        Key features include:
      </p>
      <ul>
        <li>
          <a href="" routerLink="/account/view-contacts">Account/Contacts:</a> View your contacts.
        </li>
        <li>
          <a href="" routerLink="/loan/view-messages">Communication/Messages: </a> Send and receive messages.
        </li>
        <li>
          <a href="" routerLink="/loans/list">My Loans:</a> Access and view your loan(s) information.
        </li>
      </ul>
    </div>

    <div *ngIf="hasEzUploadsService">
      <h4>ezUploads</h4>
      <p>
        ezUploads enables you to easily and securely collect documents from anybody.
        Quick links:
      </p>
      <ul>
        <li>
          <a href="" routerLink="/ez-uploads/secured-uploads">ezUploads</a>.
        </li>
        <li>
          <a href="" routerLink="/ez-uploads/ez-uploads-instructions">ezUploads Instructions</a> for sending upload requests and receiving uploaded files.
        </li>
      </ul>
    </div>

    <div *ngIf="hasMCRService">
      <h4>MCR Wizard</h4>
      <div>
        Our MCR Wizard
      </div>
      <p>
      <ul>
        <li>
          Simplifies the generation of the NMLS Mortgage Call Report (MCR),
        </li>
        <li>
          Creates an accurate report, and
        </li>
        <li>
          Generates an XML file (a type of text file), ready for uploading to NMLS.
        </li>
      </ul>
    </div>

    <div *ngIf="hasPOLOSService">
      <h4>POLOS</h4>

      <p>
        POLOS is PrudentCO Online Loan Origination System:
      </p>

      <div class="fw-bold">Creating Loans</div>

      <p>
        You can create a new loan if your role is "Loan Officer."
      </p>

      <div class="fw-bold">The Loan Application</div>

      <p>
        POLOS has a complete support for Fannie Mae's Residential Loan Application.
        POLOS provides you with a powerful error checking tool that can detect errors and omissions.
        You can also produce key ratios and statistics that lenders use to qualify loans and run "what if" scenarios.
      </p>

      <div class="fw-bold">The Repository</div>

      <p>
        The Repository facility lets you manage the loan record, create the loan application, define the loan repository's structure,
        and generate and upload documents. If you give your borrower a directory access, they can directly upload documents to
        the unsorted folder. Note: Borrowers can only access the files they upload.
      </p>

      <div class="fw-bold">Timeline</div>

      <p>
        You start the loan workflow and update it when loan milestones are reached.
        The loan workflow is displayed on an intuitive timeline with text and instructions for borrowers.
      </p>

      <div class="fw-bold">Messages and Emails</div>

      <p>
        Every user in POLOS can send system messages to their contacts.
        You can send emails to your contacts when loan milestones are reached.
      </p>

      <div class="fw-bold">Sending and Receiving Text Messages</div>

      <p>
        You can send text messages to any of your contacts if their
        <a class="pr-blue-bold" routerLink="/account/settings">Account Settings</a> includes a
        phone number and carrier information.
      </p>
    </div>
  </mat-card-content>
</mat-card>
