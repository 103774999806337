<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Reset Password
  </mat-card-title>

  <mat-card-content>
    <p>
      We are enforcing strong password rules to protect your data. To reset your password, enter a new password and
      confirm
      your choice by re-entering it.
    </p>
  </mat-card-content>
</mat-card>
