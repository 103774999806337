import {ModelBase} from './model-base';

export class Notification extends ModelBase {
  public static NOTIFICATION_TYPE = {
    REQUEST: 1,
    SIGN_UP: 2,
    VIDEO_WATCH: 4,
    UNSUBSCRIBE: 8,
    CONTACT_US: 16,
    VISITED_PAGE: 32,
    MCR_SERVICE: 64
  };
}
