<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Credit type</mat-label>
            <mat-select formControlName="code_ex" placeholder="Select credit type" required>
              <mat-option *ngFor="let code of credit_codes" [value]="code.key">{{code.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="code_ex.hasError('required')">
              Credit type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="code_ex.value==='Other'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Other credit explanation</mat-label>
            <input formControlName="other_credit_explain" matInput placeholder="Explain other credit" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="other_credit_explain.hasError('required')">
              Explain other credit is required
            </mat-error>
            <mat-error *ngIf="other_credit_explain.hasError('pattern')">
              Explain other credit is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Amount</mat-label>
            <input appDecimalOnly formControlName="amount" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Amount" required>
            <mat-error *ngIf="amount.hasError('required')">
              Amount is required
            </mat-error>
            <mat-error *ngIf="amount.hasError('pattern')">
              Amount is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
