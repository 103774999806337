<div *ngIf="reloaded">
  <div class="row">
    <div class="col-lg-6">
      <mat-card class="mt-1 mb-1">
        <mat-card-title>
          <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Your Services'" [help]="false"></app-segment-header>
        </mat-card-title>

        <mat-card-content>
          <div>
            The services you have subscribed to:
          </div>
          <ul>
            <li *ngFor="let currentService of userServices">
              {{currentService}}
            </li>
          </ul>
          <!-- REMOVE the ability to add new services -->
          <div *ngIf="false && user.isLoanProfessional() && missingServices.length">
            <div>
              You can add the following service(s) to your account:
            </div>
            <form [formGroup]="form" novalidate>
              <div *ngFor="let elt of missingServices" class="row">
                <div *ngIf="elt.service === services.EZUPLOADS">
                  <mat-checkbox formControlName="sauSvc">ezUploads</mat-checkbox>
                </div>
                <div *ngIf="elt.service === services.EZMCR">
                  <mat-checkbox formControlName="mcrSvc">MCR Wizard</mat-checkbox>
                </div>
                <div *ngIf="elt.service === services.POLOS">
                  <mat-checkbox formControlName="loansSvc">POLOS</mat-checkbox>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button (click)="addServices()" [disabled]="!(sauSvc.value||mcrSvc.value||loansSvc.value)" class="btn btn-lg btn-primary">Add Services</button>
                </div>
              </div>
            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
