<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Help
  </mat-card-title>
  <mat-card-content>
    <p>
      You can get contextual help by clicking the Help icon (as you have just done).
      Clicking again on the Help icon or on the Help area toggles the visibility of Help section. Try it.
    </p>
  </mat-card-content>
</mat-card>
