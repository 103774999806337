import {Component, Injector, Input, OnInit} from '@angular/core';
import {Tran} from "../../../models/tran";
import {CommComponent} from "../comm.component";

@Component({
  selector: 'app-view-segment-messages',
  templateUrl: './view-segment-messages.component.html',
})
export class ViewSegmentMessagesComponent extends CommComponent implements OnInit {
  @Input() messages: Array<any>;
  @Input() type: string;
  groupedMessages = {};
  keys = [];
  trans: Array<Tran>;
  public title: string;
  public isMessages: boolean;
  public currentTranId: number;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.trans = this.tranService.getTransSync();
    this.groupedMessages = this.messages.reduce(function (memo, item) {
      if (!(item.tran_id in memo)) {
        memo [item.tran_id] = []
      }
      memo[item.tran_id].push(item);
      return memo;
    }, {});
    this.keys = Object.keys(this.groupedMessages);

    super.ngOnInit();
    switch (this.type) {
      case 'sent':
        this.title = 'Sent Messages';
        break;
      case 'received':
        this.title = 'Received Messages';
        break;
    }
    this.isMessages = this.messages && this.messages.length > 0;
    const currentTran = this.tranService.getCurrentTranSync();

    this.currentTranId = currentTran ? this.tranService.getCurrentTranSync().id : null;
    this.reloaded = true;
  }

  getTranTitle(tran_id: number): string {
    const tran = this.trans.find((elt) => {
      return +elt.id === +tran_id;
    })

    return tran ? tran.name : 'N/A';
  }
}
