import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Mcr} from '../../../models/mcr';
import {CompBaseComponent} from '../../../content/comp-base.component';

@Component({
  selector: 'app-mcr-report',
  templateUrl: './mcr-report.component.html',
})
export class McrReportComponent extends CompBaseComponent implements OnInit {
  @Input() mcr: Mcr;
  @Input() state: string;
  public report: any;
  public reportKeys: Array<string>;
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.report = this.mcr.content['mcr'];
    // remove the auxiliary 'ACMOV' whose job was to assist in the generation of AC063
    this.reportKeys = Object.keys(this.report).filter((k) => k != 'ACMOV');
  }

  closeReport(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Close'});
  }

  displayACAndMLO(key: string): boolean {
    if (this.mcr.mcr_type === 1 || this.mcr.mcr_type === 4) {
      return key.startsWith('AC') && !key.startsWith('ACMLOS');
    }
    return key.startsWith('AC') && !key.startsWith('ACMLO');
  }

  displayH1(key: string): boolean {
    if (this.mcr.isLoMcr() || this.mcr.isTranMcr()) {
      return key.startsWith('H1');
    }
    // ignore any H1 for Org MLO
    return key.startsWith('H1') && !key.startsWith('H1MLO');
  }

  displayH2(key: string): boolean {
    if (this.mcr.isLoMcr() || this.mcr.isTranMcr()) {
      return key.startsWith('H2');
    }
    // ignore any H2 for Org MLO
    return key.startsWith('H2') && !key.startsWith('H2MLO');
  }
}
