import {ModelBase} from './model-base';

export class Asset extends ModelBase {
  public static asset_types = [
    {key: 'Annuity', value: 'Annuity'},
    {key: 'Automobile', value: 'Automobile'},
    {key: 'Boat', value: 'Boat'},
    {key: 'Bond', value: 'Bond'},
    {key: 'BorrowerEstimatedTotalAssets', value: 'Borrower Estimated Total Assets'},
    {key: 'BorrowerPrimaryHome', value: 'Borrower Primary Home'},
    {key: 'BridgeLoanNotDeposited', value: 'Bridge Loan Not Deposited'},
    {key: 'CashOnHand', value: 'Cash On Hand'},
    {key: 'CertificateOfDepositTimeDeposit', value: 'Certificate of Deposit Time Deposit'},
    {key: 'CheckingAccount', value: 'Checking Account'},
    {key: 'EarnestMoneyCashDepositTowardPurchase', value: 'Earnest Money Cash Deposit Toward Purchase'},
    {key: 'EmployerAssistance', value: 'Employer Assistance'},
    {key: 'IndividualDevelopmentAccount', value: 'Individual Development Account'},
    {key: 'LifeInsurance', value: 'Life Insurance'},
    {key: 'MoneyMarketFund', value: 'Money Market Fund'},
    {key: 'MutualFund', value: 'Mutual Fund'},
    {key: 'NetWorthOfBusinessOwned', value: 'Net Worth of Business Owned'},
    {key: 'Other', value: 'Other'},
    {key: 'PendingNetSaleProceedsFromRealEstateAssets', value: 'Pending Net Sale Proceeds from Real Estate Assets'},
    {key: 'ProceedsFromSaleOfNonRealEstateAsset', value: 'Proceeds From Sale of Non-Real Estate Asset'},
    {key: 'ProceedsFromSecuredLoan', value: 'Proceeds from Secured Loan'},
    {key: 'ProceedsFromUnsecuredLoan', value: 'Proceeds from Unsecured Loan'},
    {key: 'RecreationalVehicle', value: 'Recreational Vehicle'},
    {key: 'RelocationMoney', value: 'Relocation Money'},
    {key: 'RetirementFund', value: 'Retirement Fund'},
    {key: 'SaleOtherAssets', value: 'Sale Other Assets'},
    {key: 'SavingsAccount', value: 'Savings Account'},
    {key: 'SavingsBond', value: 'Savings Bond'},
    {key: 'SeverancePackage', value: 'Severance Package'},
    {key: 'Stock', value: 'Stock'},
    {key: 'StockOptions', value: 'Stock Options'},
    {key: 'TrustAccount', value: 'Trust Account'},
  ];
  index: number;
  institution_name: string; // 35 chars
  institution_account_no: string;  // 30 chars
  institution_market_value: string;  // 15 chars
  institution_asset_description: string;  // 80 chars
  asset_type_ex: string;
  party_ids: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'institution_market_value') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Asset> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Asset(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getAssetType(): string {
    const found = Asset.asset_types.find((elt) => elt.key === this.asset_type_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      asset_type_ex: this.asset_type_ex,
      institution_name: this.institution_name,
      institution_account_no: this.institution_account_no,
      institution_market_value: this.toServerDecimal(this.institution_market_value),
      institution_asset_description: this.institution_asset_description,
      party_ids: this.party_ids
    };
  }
}
