<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Monthly Income'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>Primary employment income refers to income source(s) from your primary job.</li>
          <li>Secondary employment income refers to income source(s) from your secondary job (if you have one).</li>
          <li>Other income refers to income from non-employment sources.</li>
          <li>You can edit or delete income sources.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-income (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-income>

    <mat-card-title class="mb-3 mt-3" *ngIf="priItems.length>0">Primary Employment Income
      <span style="font-size: 1rem">(income from your primary job)</span>
    </mat-card-title>

    <div *ngFor="let item of priItems">
      <app-view-income (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-income>
    </div>

    <mat-card-title class="mb-3 mt-3" *ngIf="secItems.length>0">Secondary Employment Income
      <span style="font-size: 1rem">(income from your secondary job, if any)</span>
    </mat-card-title>

    <div *ngFor="let item of secItems">
      <app-view-income (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-income>
    </div>

    <mat-card-title class="mb-3 mt-3" *ngIf="otherItems.length>0">Other Income
      <span style="font-size: 1rem">(non-employment related income)</span>
    </mat-card-title>

    <div *ngFor="let item of otherItems">
      <app-view-income (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-income>
    </div>
  </mat-card>
</div>
