<div *ngIf="!reloaded" class="row upload spinner mt-4 mb-4">
  <div class="col text-center">
    <img src='/api/images/spinner_white.gif'>
  </div>
</div>

<app-card *ngIf="reloaded&&!verified" [align]="'center'" [title]="'Device Verification'">
  <span class="material-icons text-danger">highlight_off</span>

  <p class="text-danger">
    <strong>Device verification failed!</strong>
  </p>

  <div class="additional-info">
    <a (click)="navigateToSign($event)" href="/pages/sign-in">Go to Sign in</a>
  </div>
</app-card>

<app-card *ngIf="reloaded&&verified" [align]="'center'" [title]="'Account Verification'">
  <span class="material-icons">check_circle</span>

  <p>
    <strong>Device verification complete!</strong>
  </p>

  <p>
    Please sign-in.
  </p>


  <div class="additional-info">
    <a (click)="navigateToSign($event)" href="/pages/sign-in">Sign in</a>
  </div>
</app-card>
