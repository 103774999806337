import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {LoanCalc} from '../../../models/loan-calc';
import {CompBaseComponent} from '../../comp-base.component';
import {CalculatorService} from '../../../services/calculator.service';

@Component({
  selector: 'app-calc',
  template: '',
})
export class CalcComponent extends CompBaseComponent implements OnInit {
  @Input() public loan: LoanCalc;
  @Input() public amorTable: any;
  public calcService: CalculatorService;
  public error: boolean; // not used yet
  public percent5Pattern = /^([\d]{1,2}(\.[\d]{0,2})?)?[%]?[%]?$/;

  // form and form controls
  public numberMonths: FormControl;
  public annualInterestRate: FormControl;
  public loanAmount: FormControl;
  public offsetMonth: FormControl;
  public monthlyPayment: FormControl;
  public financeCharge: FormControl;
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  // comment: do not call super (because it is for authenticated users)
  ngOnInit() {
    this.calcService = this.injector.get(CalculatorService);
  }

  createFormControls() {
    this.numberMonths = new FormControl(this.loan.numberMonths);
    this.annualInterestRate = new FormControl(this.loan.annualInterestRate);
    this.loanAmount = new FormControl(this.loan.loanAmount);
    this.offsetMonth = new FormControl(0);
    this.monthlyPayment = new FormControl(this.loan.monthlyPayment);
    this.financeCharge = new FormControl(this.loan.financeCharge);
  }

  createForm() {
    this.form = new FormGroup({
      numberMonths: this.numberMonths,
      annualInterestRate: this.annualInterestRate,
      loanAmount: this.loanAmount,
      offsetMonth: this.offsetMonth,
      monthlyPayment: this.monthlyPayment,
      financeCharge: this.financeCharge
    });
  }

  reload() {
    this.error = false;
    this.createFormControls();
    this.createForm();
  }

  computeMonthlyPayment() {
    this.error = false;
    const loanOut = new LoanCalc({});
    const annualInterestRate = parseFloat(this.annualInterestRate.value || 0.0);
    let loanAmount = this.loanAmount.value;
    loanAmount = parseFloat(loanAmount || 0.0);
    const numberMonths = parseInt(this.numberMonths.value, 10);
    const offsetMonth = parseInt(this.offsetMonth.value, 10);
    const monthlyPayment = CalculatorService.computeMonthlyPayment(loanAmount, offsetMonth, annualInterestRate, numberMonths, 0.001);
    loanOut.annualInterestRate = annualInterestRate;
    loanOut.loanAmount = loanAmount;
    loanOut.numberMonths = numberMonths;
    loanOut.offsetMonth = offsetMonth;
    loanOut.monthlyPayment = monthlyPayment;
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View', loan: loanOut});
  }

  computeAnnualInterestRate() {
    this.error = false;
    const loanOut = new LoanCalc({});
    let loanAmount = this.loanAmount.value;
    loanAmount = parseFloat(loanAmount || 0.0);
    let monthlyPayment = this.monthlyPayment.value;
    monthlyPayment = parseFloat(monthlyPayment || 0.0);
    const numberMonths = parseInt(this.numberMonths.value || 360, 10);
    const offsetMonth = parseInt(this.offsetMonth.value || 0, 10);
    loanOut.loanAmount = loanAmount;
    loanOut.numberMonths = numberMonths;
    loanOut.offsetMonth = offsetMonth;
    loanOut.monthlyPayment = monthlyPayment;

    if (loanAmount <= 0.1 || monthlyPayment <= 0.1 || monthlyPayment * (numberMonths - offsetMonth) < (loanAmount - 1)) {
      this.error = true;
      return;
    }
    loanOut.annualInterestRate = CalculatorService.computeFixedRate(offsetMonth, loanAmount, numberMonths, monthlyPayment, 0.001);
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View', loan: loanOut});
  }

  computeAmortizationTable() {
    this.error = false;

    const annualInterestRate = parseFloat(this.annualInterestRate.value || 0.0);
    let loanAmount = this.loanAmount.value;
    loanAmount = parseFloat(loanAmount || 0.0);
    const numberMonths = parseInt(this.numberMonths.value || 360, 10);
    const offsetMonth = parseInt(this.offsetMonth.value || 0, 10);

    if (loanAmount <= 0.1 || annualInterestRate >= 99.9 || annualInterestRate <= 0 || offsetMonth >= numberMonths) {
      this.error = true;
      return;
    }

    const loanOut = new LoanCalc({});
    loanOut.annualInterestRate = annualInterestRate;
    loanOut.loanAmount = loanAmount;
    loanOut.numberMonths = numberMonths;
    loanOut.offsetMonth = offsetMonth;
    const amorTable = CalculatorService.computeFixedAmortizationTable(offsetMonth, loanAmount, annualInterestRate, numberMonths);
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View', amor: amorTable, loan: loanOut});
  }

  computeAPR() {
    this.error = false;
    const annualInterestRate = parseFloat(this.annualInterestRate.value || 0.0);
    const loanAmount = this.loanAmount.value;
    const financeCharge = parseFloat(this.financeCharge.value || 0.0);
    const numberMonths = parseInt(this.numberMonths.value || 360, 10);
    const offsetMonth = parseInt(this.offsetMonth.value || 0, 10);

    if (loanAmount <= 0.1 || annualInterestRate >= 99.9 || annualInterestRate <= 0 || offsetMonth >= numberMonths) {
      this.error = true;
      return;
    }
    const loanOut = new LoanCalc({});
    loanOut.annualInterestRate = annualInterestRate;
    loanOut.loanAmount = loanAmount;
    loanOut.financeCharge = financeCharge;
    loanOut.numberMonths = numberMonths;
    loanOut.offsetMonth = offsetMonth;

    if (financeCharge <= 0.1) {
      loanOut.apr = annualInterestRate;
    } else {
      loanOut.apr = CalculatorService.computeFixedRateAPR(loanAmount, offsetMonth, financeCharge, annualInterestRate, numberMonths);
    }
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View', loan: loanOut});
  }
}
