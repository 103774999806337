import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Workflow} from '../../../models/workflow';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-workflow-tabs',
  templateUrl: './view-workflow-tabs.component.html',
})
export class ViewWorkflowTabsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Workflow';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.workflow
      });
    }, 0);
    this.reload();
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.tranService.getCurrentTran(force)
      .then((tran) => {
        this.tran = tran;
        return this.loanAppService.getCurrentLoanDocument();
      })
      .then((doc) => {
        this.doc = doc;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
