<mat-card>
  <mat-card-title>{{title}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row hover-gray pt-1 pb-1">
        <div class="col-lg-2 fw-bold border-bottom">
          Task
        </div>
        <div class="col-lg-4 border-bottom text-end hover-gray">{{trace.state_display}}</div>
      </div>

      <div class="row hover-gray pt-1 pb-1">
        <div class="col-lg-2 border-bottom fw-bold">
          Updated at
        </div>
        <div class="col-lg-4 border-bottom text-end">{{trace.updated_at | date:'medium'}}</div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-4">
          <mat-form-field class="w-100">
            <mat-label>Started at date</mat-label>
            <input [matDatepicker]="$any(datePicker)" [min]="minDate" [max]="maxDate" formControlName="started_at"
              matInput placeholder="Started at date" required>
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="started_at.hasError('matDatepickerMin') || started_at.hasError('matDatepickerMax')">
              Started at date is unrealistic
            </mat-error>
            <mat-error *ngIf="started_at.hasError('matDatepickerParse')">
              Started at date format is invalid
            </mat-error>
            <mat-error *ngIf="started_at.hasError('required')">
              Started at date is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Started at time</mat-label>
            <mat-select formControlName="started_at_time" placeholder="Select time">
              <mat-option *ngFor="let time of timeFrames" [value]="time.key" class="dd-narrow">{{time.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="started_at_time.hasError('required')">
              Time is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="trace.state!=='start'&& trace.state!=='end'">
        <div class="row mt-3">
          <div class="col-lg-4">
            <mat-form-field class="w-100">
              <mat-label>Completed at date</mat-label>
              <input [matDatepicker]="$any(datePicker2)" [min]="minDate" [max]="maxDate" formControlName="ended_at"
                matInput placeholder="Completed at date">
              <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker2></mat-datepicker>
              <mat-error *ngIf="ended_at.hasError('required')">
                Completed at date is required
              </mat-error>
              <mat-error *ngIf="ended_at.hasError('matDatepickerMin') || ended_at.hasError('matDatepickerMax')">
                Completed at date is unrealistic
              </mat-error>
              <mat-error *ngIf="ended_at.hasError('matDatepickerParse')">
                Completed at date format is invalid
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4" [hidden]="!ended_at.value">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Completed at time</mat-label>
              <mat-select formControlName="ended_at_time" placeholder="Select time" [required]="!!ended_at.value">
                <mat-option *ngFor="let time of timeFrames" [value]="time.key" class="dd-narrow">{{time.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="ended_at_time.hasError('required')">
                Time is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>To do</mat-label>
              <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
                cdkTextareaAutosize
                formControlName="data_in"
                matInput
                placeholder="To do" maxlength="{{MAX_LEN.VL_DESC}}">
            </textarea>
              <mat-error *ngIf="data_in.hasError('required')">
                To do is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Status update</mat-label>
              <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
                cdkTextareaAutosize
                formControlName="data"
                matInput
                placeholder="Status update" maxlength="{{MAX_LEN.VL_DESC}}">
            </textarea>
              <mat-error *ngIf="data.hasError('required')">
                Status update is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Summary</mat-label>
              <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
                cdkTextareaAutosize
                formControlName="data_out"
                matInput
                placeholder="Summary" maxlength="{{MAX_LEN.VL_DESC}}">
            </textarea>
              <mat-error *ngIf="data_out.hasError('required')">
                Summary is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button [hidden]="trace.hidden" (click)="hide($event)" class="ms-2 btn btn-lg btn-primary">Hide</button>
          <button [hidden]="!trace.hidden" (click)="show($event)" class="ms-2 btn btn-lg btn-primary">Show</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
