<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Invoice'" [help]="false"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice ID</div>
      <div class="col-lg-8">{{item.id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice amount</div>
      <div class="col-lg-8">{{item.amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Status</div>
      <div class="col-lg-8">{{item.getStatus()}}</div>
    </div>

    <div class="row mt-3" [hidden]="item.isPaid()">
      <div class="col">
        <button (click)="navToPay()" class="btn btn-lg btn-primary">Pay Invoice</button>
        <button (click)="navToEdit()" class="ms-2 btn btn-lg btn-primary">Edit</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

