import {Component, Injector, OnInit} from '@angular/core';
import {AdminUserComponent} from '../admin-user.component';
import {User} from '../../../../models/user';
import {UserConfiguration} from '../../../../models/user-configuration';
import {Help} from '../../../../models/help';
import {FormUtil} from '../../../form-util';
import {FormControl, FormGroup} from '@angular/forms';
import {Organization} from '../../../../models/organization';
import {OrganizationService} from '../../../../services/organization.service';

@Component({
  selector: 'app-admin-view-user',
  templateUrl: '../admin-view-user.component.html',
})
export class AdminViewUserComponent extends AdminUserComponent implements OnInit {
  pageTitle = 'View User';
  user: User;
  userOrg: Organization;
  orgService: OrganizationService;
  config: UserConfiguration;
  form: FormGroup;
  organization_id: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.orgService = this.injector.get(OrganizationService);
    this.op = 'Edit';
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);

    this.route.paramMap.subscribe((map) => {
      this.userId = map['params']['id'];
      this.reload();
    });
  }

  createFormControls() {
    this.organization_id = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      organization_id: this.organization_id,
    });
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.userOrg = null;
    this.adminService.findUserById(this.userId)
      .then((user) => {
        this.user = user;
        return this.config = user.user_configuration ? new UserConfiguration(user.user_configuration) : null;
      }).then(() => {
      if (this.user.organization_id) {
        return this.adminService.findOrganizationById(this.user.organization_id);
      }
      return null;
    }).then((org: Organization) => {
      if (org) {
        this.userOrg = org;
      }
      this.reloaded = true;
    });
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.router.navigate(['/admin/edit-user', this.user.id]);
    }
  }

  setOrgAdmin(): void {
    this.adminService.setOrgAdmin(this.user.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'User organization role successfully updated');
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to set the user organization role', data);
      });
  }

  removeFromOrg(): void {
    this.adminService.removeFromOrg(this.user.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'User successfully removed from organization');
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to remove user from organization', data);
      });
  }

  addToOrg(): void {
    if (!this.organization_id.value) {
      FormUtil.genErrorMessage(this.sharedService, 'Organization ID is required', null);
      return;
    }

    this.adminService.addToOrg(this.user.id, this.organization_id.value)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'User organization role successfully updated');
        this.reload();
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to set the user organization role', data);
      });
  }

  navigateToResetPw(): void {
    this.router.navigate(['/admin/reset-pw'], {queryParams: {user_id: this.user.id}});
  }

  navigateToUserOrders(): void {
    this.router.navigate(['/admin/order-list'], {queryParams: {user_id: this.user.id}});
  }

  navigateToNewInvoice(): void {
    this.router.navigate(['/admin/new-invoice'], {queryParams: {user_id: this.user.id, full_name: this.user.full_name}});
  }
}
