import {Component, Injector, Input, OnInit} from '@angular/core';
import {DownPayment} from '../../../../models/down-payment';
import {DownPaymentComponent} from '../down-payment/down-payment.component';

@Component({
  selector: 'app-view-segment-down-payment',
  templateUrl: './view-segment-down-payment.component.html',

})
export class ViewSegmentDownPaymentComponent extends DownPaymentComponent implements OnInit {
  @Input() item: DownPayment;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
