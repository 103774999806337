<app-orientation-helper (orientationErrorEmitted)="onOrientationError($event)" [componentName]="'Compliance Report'">
</app-orientation-helper>

<div *ngIf="reloaded&&canUpdateTransaction&&!orientationError">
  <div *ngIf="!isStarted">
    <app-box [type]="'warning'">
      The compliance events cannot be displayed because the
      <span class="fw-bold">workflow has not yet started!</span>
      You can start the loan workflow from
      <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
    </app-box>

    <form [formGroup]="form_start" class="w-100">
      <div class="row w-100">
        <div class="col-lg-2 my-auto">
          <button (click)="startWorkflow()" class="btn btn-lg btn-primary" type="button">Start Workflow</button>
        </div>
        <div class="col-lg-4 my-auto">
          <mat-form-field class="w-100">
            <mat-label>Workflow start date</mat-label>
            <input [matDatepicker]="$any(datePicker2)" [max]="maxDate" [min]="minDate" formControlName="wfStartDate" matInput placeholder="Workflow start date" required>
            <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker2></mat-datepicker>
            <mat-error *ngIf="wfStartDate.hasError('matDatepickerMin') || wfStartDate.hasError('matDatepickerMax')">
              Workflow start date is unrealistic
            </mat-error>
            <mat-error *ngIf="wfStartDate.hasError('matDatepickerParse')">
              Workflow start date is invalid
            </mat-error>
            <mat-error *ngIf="wfStartDate.hasError('required')">
              Workflow start date is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <app-box *ngIf="isStarted" [type]="'primary'" [close]="true">
    <div class="row">
      <div class="col-lg-6">
        <h5>Key Dates</h5>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Start date:
          </div>
          <div class="col-lg-6">
            <strong>{{startDate || 'N/A'}}</strong>
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Credit authorization date:
          </div>
          <div class="col-lg-6">
            <strong>{{creditAuthDate || 'N/A'}}</strong>
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Loan application date:
          </div>
          <div class="col-lg-6">
            <strong>{{loanAppDate || 'N/A'}}</strong>
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Loan Estimate (LE) date:
          </div>
          <div class="col-lg-6">
            <strong>{{leDate || 'N/A'}}</strong>
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Closing Disclosure (CD) date:
          </div>
          <div class="col-lg-6">
            <strong>{{cdDate || 'N/A'}}</strong>
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-6">
            Lock expiration date:
          </div>
          <div class="col-lg-6">
            <strong>{{lockDate || 'N/A'}}</strong>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pb-2">
        <div class="row">
          <div class="col-lg-9">
            <h5>Loan Events Legend</h5>
          </div>
          <div class="col-lg-3"></div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Completed on time
          </div>
          <div class="col-lg-3 pr-success fw-bold text-center">
            {{levelCounts[1]}}
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Outstanding
          </div>
          <div class="col-lg-3 pr-light fw-bold text-center">
            {{levelCounts[2]}}
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Due today
          </div>
          <div class="col-lg-3 pr-warning fw-bold text-center">
            {{levelCounts[3]}}
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Completed but not compliant
            <small>(too early or too late)</small>
          </div>
          <div class="col-lg-3 pr-danger fw-bold text-center">
            {{levelCounts[4]}}
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Missed
          </div>
          <div class="col-lg-3 pr-fatal fw-bold text-center">
            {{levelCounts[5]}}
          </div>
        </div>
        <div class="row mb-1 me-0">
          <div class="col-lg-9">
            Compliance not applicable
          </div>
          <div class="col-lg-3 pr-light-gray fw-bold text-center">
            {{levelCounts[0]}}
          </div>
        </div>
      </div>
    </div>
  </app-box>

  <mat-card *ngIf="isStarted" class="mb-2 w-100">
    <mat-card-title>Compliance and Optional Events</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" class="w-100">
        <div [hidden]="simulateDateEnabled" class="mb-3">
          <label><strong>Today's date:</strong></label>&nbsp;{{simulatedDate.value | date:'MM/dd/yyyy'}}&nbsp;&nbsp;&nbsp;
          <button (click)="enterSimulation()" class="btn btn-lg btn-primary" type="button">Simulate Another Date</button>
        </div>

        <div class="row w-100" [hidden]="!simulateDateEnabled">
          <div class="col-lg-2" style="position:relative;top: 10px;">
            <mat-form-field class="w-100">
              <mat-label>Simulated date</mat-label>
              <input [matDatepicker]="$any(datePicker)" [max]="maxDate" [min]="minDate" formControlName="simulatedDate" matInput placeholder="Simulated date" required>
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="simulatedDate.hasError('matDatepickerMin') || simulatedDate.hasError('matDatepickerMax')">
                Simulated date is unrealistic
              </mat-error>
              <mat-error *ngIf="simulatedDate.hasError('matDatepickerParse')">
                Simulated date is invalid
              </mat-error>
              <mat-error *ngIf="simulatedDate.hasError('required')">
                Simulated date is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4 my-auto">
            <button (click)="getSimulatedCompEvents()" class="btn btn-lg btn-primary" type="button">Run Simulation</button>
            <button (click)="exitSimulation()" class="btn btn-lg btn-light ms-1" type="button">Exit Simulation</button>
          </div>
        </div>
      </form>

      <div class="pb-1 pt-1">
        Required events display in
        <strong>bold</strong>.
      </div>

      <div class="row comp-border-bottom">
        <div class="col-lg-6 comp-cell comp-border-left">
          <span class="fw-bold">Description</span>&nbsp;(code)
        </div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due On or After</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">{{isMobile ? 'Occur On' : 'Occurred On'}}</div>
        <div class="col-lg-2 comp-cell comp-border-left fw-bold">Due By</div>
      </div>
      <div *ngFor="let compEvent of compEvents">
        <app-view-comp-event (eventEmitted)="onEvent($event)" [item]="compEvent" [tran]="tran"></app-view-comp-event>
      </div>
    </mat-card-content>
  </mat-card>
</div>
