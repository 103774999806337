import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @HostBinding('class.ni-card') niCardClass = true;
  @Input() title = '';
  @Input() bgColor = '';
  @Input() customBgColor = '';
  @Input() color = '';
  @Input() customColor = '';
  @Input() bgImage = '';
  @Input() outline = false;
  @Input() indents: any = '';
  @Input() align = 'left';
  @Input() alignTitle = 'left';
  @Input() logo = false;

  constructor() {
  }

  ngOnInit() {
  }
}
