<mat-card *ngIf="reloaded">
  <mat-card-title>{{pageTitle}}</mat-card-title>

  <mat-card-content>
    <div *ngIf="hasPOLOSService">
      <div class="fw-bold">Loans</div>

      <div *ngIf="activeTransactionsCount===0">
        <div *ngIf="!isLoanProfessional">
          <strong>Error Checking.</strong> Please contact your loan officer!
        </div>
        <div *ngIf="isLoanOfficer">
          You do not have access to any loan.
          You can create a new loan from
          <a class="pr-blue-bold" routerLink="/loans/new-loan">My Loans/New Loan</a>.
        </div>
      </div>

      <div *ngIf="activeTransactionsCount >= 1">
        <ul>
          <li>
            You have access to the
            <a class="pr-blue-bold" routerLink="/loan/view-timeline">current loan</a>.
          </li>
        </ul>
      </div>

      <div *ngIf="activeTransactionsCount > 1">
        <ul>
          <li>
            You have access to
            <a class="pr-blue-bold" routerLink="/loans/list">{{activeTransactionsCount}} {{activeTransactionsCount == 1 ? 'active loan' : 'active loans' }}</a>.
          </li>
        </ul>
      </div>

      <div class="fw-bold mt-3">Contacts</div>

      <div *ngIf="accountOverview.contact_count===0">
        <div *ngIf="isLoanProfessional">
          You do not have any contacts!
          Add new contacts from Account/Contacts.
        </div>

        <div *ngIf="!isLoanProfessional">
          <strong>You are not yet a contact of your loan officer.</strong> Please contact your loan officer!
        </div>
      </div>

      <div *ngIf="accountOverview.contact_count > 0">
        <ul>
          <li>You have
            <a class="pr-blue-bold" routerLink="/account/view-contacts">{{accountOverview.contact_count}} {{accountOverview.contact_count == 1 ? 'contact' : 'contacts' }}</a>.
          </li>
        </ul>
      </div>

      <div class="fw-bold mt-3">Messages</div>

      <ul *ngIf="false">
        <li>
          <a class="pr-blue-bold" routerLink="/loan/view-comm">Sent</a> {{accountOverview.messages_sent_count}} {{accountOverview.messages_sent_count == 1 ? 'message' : 'messages'}}.
        </li>
        <li>
          <a class="pr-blue-bold" routerLink="/loan/view-comm">Received</a> {{accountOverview.messages_received_count}} {{accountOverview.messages_received_count == 1 ? 'message' : 'messages'}}.
        </li>
      </ul>

      <ul *ngIf="true">
        <li>Sent {{accountOverview.messages_sent_count}} messages.</li>
        <li>Received {{accountOverview.messages_received_count}} {{accountOverview.messages_received_count == 1 ? 'message' : 'messages'}}.</li>
      </ul>
    </div>

    <ul>
      <li>Your role:
        <strong>{{accountOverview.roleDescription}}</strong>
      </li>
      <li>Your services:
        <strong>{{accountOverview.servicesDescription}}</strong>
      </li>
    </ul>

  </mat-card-content>
</mat-card>
