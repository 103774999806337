import {Component, Injector, OnInit} from '@angular/core';
import {DependentComponent} from '../dependent/dependent.component';

@Component({
  selector: 'app-view-dependent',
  templateUrl: './view-dependent.component.html',
})
export class ViewDependentComponent extends DependentComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
