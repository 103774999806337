import {Component, Injector, OnInit} from '@angular/core';
import {DeclarationExplanation} from '../../../../models/declaration-explanation';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-declaration-explanations',
  templateUrl: './view-segment-declaration-explanations.component.html',
})
export class ViewSegmentDeclarationExplanationsComponent extends CompBaseComponent implements OnInit {
  public items: Array<DeclarationExplanation>;
  public explanationsRequired: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    this.items = DeclarationExplanation.deserializeArray(this.loan_app[this.applicant].declaration_explanations);
    setTimeout(() => {
      this.explanationsRequired = this.items && this.items.length > 0;
      this.segment = 'declaration_explanations';
      this.section = this.applicant;
      this.reloaded = true;
    }, 0);
  }
}
