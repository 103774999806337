<div *ngIf="reloaded && mcr">
  <app-view-segment-mcr (eventEmitted)="onEvent($event)" *ngIf="mode==='View'" [mcr]="mcr">
  </app-view-segment-mcr>

  <app-edit-segment-mcr (eventEmitted)="onEvent($event)" *ngIf="mode==='Edit'" [mcr]="mcr" [backLink]="'/mcr/view-wizard'">
  </app-edit-segment-mcr>
</div>

<div *ngIf="reloaded && !mcr">
  <app-add-tran-mcr (eventEmitted)="onEvent($event)" [mcr]="mcr">
  </app-add-tran-mcr>
</div>
