<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Contacts
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="isLoanProfessional">
      <p>
        You
        <strong>must</strong> add a contact to your account before you can communicate with them or give them access
        to
        your loan. To add a contact:
      </p>
      <ul>
        <li>Click the plus icon next to the Contacts header.</li>
        <li>Enter the contact's email.</li>
        <li>Click Add.</li>
      </ul>
    </div>
    <div *ngIf="isBorrower">
      <p>
        Your loan officer should display in your contact list. If your contact list is empty or does not include your
        loan officer,
        please notify your loan officer and give them either your account name or email address.
      </p>
    </div>
    <h5>Filtering Contacts</h5>
    <p>
      You can filter out contacts in this view by hiding them.
      In case you have access to multiple loans, you can also filter out all contacts except the ones who can access the current loan.
      You can also undo any hidden contact by "showing" them.
    </p>
    <ul>
      <li>To hide a contact, simply click the Hide icon (<i class="fas fa-eye-slash"></i>).
      </li>
      <li>To restrict the view to the current loan, check the corresponding checkbox.</li>
      <li>To display a hidden contact: check the "Display hidden contacts" checkbox
        and then click the Show icon (<i class="fas fa-eye"></i>) of the hidden contact.
      </li>
    </ul>

  </mat-card-content>
</mat-card>
