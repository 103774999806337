<app-orientation-helper (orientationErrorEmitted)="onOrientationError($event)" [componentName]="'Compliance Dashboard'">
</app-orientation-helper>

<div *ngIf="!orientationError">
  <div [hidden]="reloaded" class="w-100" >
    <mat-spinner [diameter]="68" style="margin:0 auto;"></mat-spinner>
  </div>

  <mat-card *ngIf="reloaded">
    <mat-card-title>Compliance</mat-card-title>
    <mat-card-content>
      <div class="table-responsive">
        <!-- todo: (css chore) modify table to bootstrap rows and cols -->
        <table class="table" style="table-layout: fixed">
          <thead>
          <tr>
            <th [hidden]="isMobile" class="border-bottom-none">ID</th>
            <th class="border-bottom-none">Name</th>
            <th class="text-center w-10 border-bottom-none">{{isMobile? 'N/A' : 'Not Applicable'}}</th>
            <th class="text-center w-10 border-bottom-none">{{isMobile? 'Comp' : 'Compliant'}}</th>
            <th class="text-center w-10 border-bottom-none" style="word-wrap: break-word">{{isMobile? 'Not Done' : 'Outstanding'}}</th>
            <th class="text-center w-10 border-bottom-none">{{isMobile? 'Today' : 'Due Today'}}</th>
            <th class="text-center w-10 border-bottom-none">{{isMobile? 'Not Comp' : 'Not Compliant'}}</th>
            <th class="text-center w-10 border-bottom-none">{{isMobile? 'Miss' : 'Missed'}}</th>
            <th [hidden]="isMobile" class="text-center border-bottom-none ">{{isMobile? 'Modified' : 'Modified'}}</th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="navigateToTran(tran)" *ngFor="let tran of trans" class="hand-pointer hover-border">
            <td [hidden]="isMobile">{{tran.id}}</td>
            <td>{{tran.name}}
              <span [hidden]="!tran.description||tran.description.length===0" class="text-muted">({{tran.description}})</span>
            </td>
            <th class="pr-light-gray text-center">{{tran.count_comp[0]}}</th>
            <th class="pr-success text-center">{{tran.count_comp[1]}}</th>
            <th class="pr-light text-center">{{tran.count_comp[2]}}</th>
            <th class="pr-warning text-center">{{tran.count_comp[3]}}</th>
            <th class="pr-danger text-center">{{tran.count_comp[4]}}</th>
            <th class="pr-fatal text-center">{{tran.count_comp[5]}}</th>
            <td [hidden]="isMobile" class="text-center">{{tran.updated_at | date: 'shortDate'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
