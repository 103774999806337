import {Component, Injector, Input, OnInit} from '@angular/core';
import {MessageComponent} from "../message/message.component";

@Component({
  selector: 'app-view-segment-message',
  templateUrl: './view-segment-message.component.html',
})
export class ViewSegmentMessageComponent extends MessageComponent implements OnInit {
  @Input() type: string;
  public thumbnailPath: string;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    if (this.type === 'received') {
      this.thumbnailPath = this.message.getFromThumbnailPath();
    }
    if (this.type === 'sent') {
      this.thumbnailPath = this.message.getToThumbnailPath();
    }
  }
}
