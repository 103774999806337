import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {User} from '../models/user';

@Injectable()
export class OrgAdminService {

  constructor(private httpService: HttpService) {
  }

  // used only by org admin
  public createAccount(payload: any): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.post('org_admin/create_account', payload)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // used only by org admin
  public addUser(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('org_admin/add_org_user', payload)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateUser(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('org_admin/update_user', params)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public removeUser(params: Object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put('org_admin/remove_user', params)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
