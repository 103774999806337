import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceService} from '../../services/device.service';

@Component({
  selector: 'app-orientation-helper',
  templateUrl: './orientation-helper.component.html',
})
export class OrientationHelperComponent implements OnInit {
  @Output() public orientationErrorEmitted = new EventEmitter();
  @Input() public componentName: string;
  public isMobile: boolean;
  public isHidden: boolean;
  public orientationError: boolean;

  constructor() {
    this.isMobile = DeviceService.isMobile;
  }

  reset(event: any): void {
    this.checkForErrors();
  }

  ngOnInit() {
    this.checkForErrors();
  }

  checkForErrors(): void {
    this.orientationError = (window.innerHeight > window.innerWidth);
    this.isHidden = !this.orientationError;
    this.orientationErrorEmitted.emit({type: 'OrientationError', error: this.orientationError});
  }
}
