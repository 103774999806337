import {Component, Injector, OnInit} from '@angular/core';
import {HelpBaseComponent} from '../../help-base.component';

@Component({
  selector: 'app-help-any',
  templateUrl: './help-any.component.html',
})
export class HelpAnyComponent extends HelpBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
