<div *ngIf="reloaded&&(canDeleteTransaction)">
  <div *ngIf="isContacts">
    <div *ngIf="type==='transaction'">
      <mat-card-title class="mt-2">
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Loan Access'" [help]="true">
          <div>
            Loan Access displays the users who can access the loan. What a user can view or do depends on their role.
          </div>

          <ul>
            <li>
              <span class="fw-bold">Processor.</span> They can do almost anything you can do, if you give them Read/Update access to the loan.
            </li>
            <li>
              <span class="fw-bold">Borrower.</span> They can view and upload documents to Repository/Documents, if you give them Repository Access.
              Otherwise, they are restricted to Messages and Timeline.
            </li>
            <li>
              <span class="fw-bold">Real Estate Agent.</span> They are always restricted to Messages and Timeline. You grant a Real Estate Agent access through
              the Timeline tab.
            </li>
          </ul>

          <div>
            <span class="fw-bold">Operations</span>
          </div>
          <ul>
            <li>
              If you delete the contact's Loan Access, they will not be able to access the loan or any of its resources.
            </li>

            <li>
              On the other extreme, if you grant a processor or loan officer access to All Resources, they
              will have access to all loan resources that existed when the permission was granted.
            </li>

            <li>
              Use the other tabs to grant access to a specific resource such as the loan application, document, image,
              or Timeline.
            </li>

            <li>
              You cannot give any borrower Read/Update to the loan but only to specific resources such as the loan application.
            </li>

          </ul>
        </app-segment-header>
      </mat-card-title>

      <app-view-segment-permissions (eventEmitted)="onEvent($event)" [resource]="tran" [resource_type]="'transaction'" [tran]="tran" [type]="type">
      </app-view-segment-permissions>
    </div>

    <div *ngIf="type==='loan_app'">
      <mat-card-title class="mt-2">
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Loan Application Access'" [help]="true">
          <p>
            In most cases, only the loan owner and borrowers should have access to the Loan Application.
            You must grant borrowers Read/Update access to the Loan Application when they work on the Loan Application.
            When they are done you should reduce their access to Read.
          </p>
          <ul>
            <li>
              Please note that only one Loan Application can be flagged as the Default Loan Application.
              In most cases, there is no need for more than one Loan Application.
            </li>

            <li>
              Real estate agent cannot access the Loan Application.
            </li>

            <li>
              You can grant other loan officers Read/Update access to the loan application.
            </li>
          </ul>
        </app-segment-header>
      </mat-card-title>

      <app-view-segment-permissions (eventEmitted)="onEvent($event)" *ngFor="let doc of laDocs" [resource]="doc" [resource_type]="'document'" [tran]="tran" [type]="type">
      </app-view-segment-permissions>

      <app-box *ngIf="!laDocs || laDocs.length === 0" class="mt-3" [type]="'warning'">
        <span class="fw-bold">No loan application!</span>
      </app-box>
    </div>

    <div *ngIf="type==='document'">
      <mat-card-title class="mt-2">
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Document & Image Access'" [help]="true">
          <p>
            When a user uploads a document to the loan, they are automatically granted Read/Update to the document.
            But thereafter, you can reduce their access to Read or completely remove their access.
          </p>
          <ul>
            <li>
              You can give contacts access to all loan resources through the Loan tab.
            </li>

            <li>
              The Read/Update access enables the contact to change the name and description of the document or image.
            </li>

          </ul>
        </app-segment-header>
      </mat-card-title>

      <app-view-segment-permissions (eventEmitted)="onEvent($event)" *ngFor="let doc of otherDocs" [resource]="doc" [resource_type]="'document'" [tran]="tran" [type]="type">
      </app-view-segment-permissions>

      <app-box *ngIf="!otherDocs || otherDocs.length === 0" class="mt-3" [type]="'warning'">
        No documents!
      </app-box>
    </div>

    <div *ngIf="type==='mcr'">
      <mat-card-title class="mt-2">
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'MCR Access'" [help]="true">
          <p>
          </p>
        </app-segment-header>
      </mat-card-title>

      <app-view-segment-permissions (eventEmitted)="onEvent($event)" *ngFor="let mcr of mcrs" [resource]="mcr" [resource_type]="'mcr'" [type]="type">
      </app-view-segment-permissions>

      <app-box *ngIf="mcrs.length===0" class="mt-3" [type]="'warning'">
        No MCRs!
      </app-box>
    </div>

    <div *ngIf="type==='workflow'">
      <mat-card-title class="mt-2">
        <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Timeline Access'" [help]="true">
          <p>
            Use Timeline tab to grant access only to Timeline.
            The main purpose of Timeline tab is to grant real estate agents limited access to the loan.
          </p>
          <ul>
            <li>
              Contacts with access to Timeline are listed under the Loan tab.
            </li>

            <li>
              If you remove a contact access to Timeline, they will lose access to the loan.
              Timeline is the minimal access to the loan.
            </li>

            <li>
              Please note that any user with access the loan can also access Messages.
            </li>
          </ul>
        </app-segment-header>
      </mat-card-title>

      <app-view-segment-permissions (eventEmitted)="onEvent($event)" [resource]="workflow" [resource_type]="'workflow'" [tran]="tran" [type]="type">
      </app-view-segment-permissions>
    </div>
  </div>

  <app-box *ngIf="!isContacts" [close]="false" [type]="'warning'" class="pt-2">
    <strong>No contacts!</strong>
    You can add contacts from <a class="pr-blue-bold" routerLink="/account/view-contacts">Account/Contacts</a>.
  </app-box>
</div>
