<div class="container mt-3 mb-3">
    <div class="col-md-10 mx-auto">
      <h2>Privacy Policy</h2>

      <div class="row justify-content-md-center">

      <div class="legal-content pr-font-large">
        <p>
          At PrudentCO, we take privacy very seriously. We’ve updated our privacy policy (Policy) to ensure
          that we communicate to You, in the clearest way possible, how we treat personal information. We encourage You to carefully read
          this Policy. It will help You make informed decisions about sharing Your personal information with us.
        </p>
        <p>
          The defined terms in this Policy have the same meaning as in our
          <a href="/site/terms">Terms of Use</a>, which You should read together with this Policy. By accessing
          our Website and using our Service, You consent to the terms of this Policy and agree to be bound by it and our Terms of Use.
        </p>
        <h4>PrudentCO collects Your personal information</h4>
        <p>
          PrudentCO is a provider of delightful online mortgage software that’s simple, smart, and secure and gives mortgage
          loan originators (MLOs) and borrowers real-time visibility of a mortgage loan transaction.
        </p>
        <p>
          PrudentCO provides service providers, such as loan officers, a secure service
          to collect documents and images from their customers.
        </p>
        <p>
          The Service involves the storage of Data about you and your customers. That Data in most cases include personal information.
          “Personal information” is information about an identifiable individual, and may include information such as the
          individual’s name, social security number (SSN), date of birth, email address, telephone number, bank account details,
          taxation details, and accounting and financial information.
        </p>
        <p>PrudentCO may collect personal information directly from You when You:</p>
        <ul>
          <li>register to use the Service,</li>
          <li>use the Service,</li>
          <li>contact the PrudentCO support team, and</li>
          <li>visit our Website.</li>
        </ul>
        <p>
          You can always choose not to provide Your personal information to PrudentCO, but it may mean that we are unable to
          provide You with the Service.
        </p>
        <h4>PrudentCO may receive personal information from You about others</h4>
        <p>
          Through Your use of the Service, PrudentCO may also collect information from You about someone else. If You provide
          PrudentCO with personal information about someone else, You must ensure that You are authorized to disclose that
          information to PrudentCO and that, without PrudentCO taking any further steps required by applicable data protection
          or privacy laws, PrudentCO may collect, use and disclose such information for the purposes described in this Policy.
        </p>
        <p>
          This means that You must take reasonable steps to ensure the individual concerned is aware of and/or consents to the
          various matters detailed in this Policy, including the fact that their personal information is being collected, the
          purposes for which that information is being collected, the intended recipients of that information, the individual's
          right to obtain access to that information, PrudentCO’s identity, and how to contact PrudentCO.
        </p>
        <p>
          Where requested to do so by PrudentCO, You must also assist PrudentCO with any requests by the individual to access
          or update the personal information You have collected from them and entered into the Service.
        </p>
        <h4>PrudentCO collects, holds, and uses Your personal information for limited purposes</h4>
        <p>PrudentCO collects Your personal information so that we can provide You with the Service and any related services
          You may request. In doing so, PrudentCO may use the personal information we have collected from You for purposes
          related to the Services including to:
        </p>
        <ul>
          <li>verify Your identity,</li>
          <li>administer the Service,</li>
          <li>notify You of new or changed services offered in relation to the Service,</li>
          <li>carry out marketing or training relating to the Service,</li>
          <li>assist with the resolution of technical support issues or other issues relating to the Service,</li>
          <li>comply with laws and regulations in applicable jurisdictions, and</li>
          <li>communicate with You.</li>
        </ul>
        <p>By using the Service, You consent to Your personal information being collected, held and used in this way and for any other use You authorize.
          PrudentCO will only use Your personal information for the purposes described in this Policy or with Your express permission.
        </p>
        <p>It is Your responsibility to keep Your password to the Service safe.
          You should notify us as soon as possible if You become aware of any misuse of Your password, and immediately
          change your password within the Service or via the
          <a href="https://my.prudentco.com/pages/forgot-password">“Forgot Password”</a> process.
        </p>
        <h4>PrudentCO can aggregate Your non-personally identifiable data</h4>
        <p>By using the Service, You agree that PrudentCO can access, aggregate and use non-personally identifiable data PrudentCO has collected from You.
          This data will in no way identify You or any other individual.
        </p>
        <p>PrudentCO may use this aggregated non-personally identifiable data to:</p>
        <ul>
          <li>assist us to better understand how our customers are using the Service,</li>
          <li>provide our customers with further information regarding the uses and benefits of the Service,</li>
          <li>enhance user productivity, including by creating useful business insights from that aggregated data
            and allowing You to benchmark Your business’ performance against that aggregated data, and
          </li>
          <li>otherwise to improve the Service.</li>
        </ul>
        <h4>PrudentCO holds your personal information on servers located in the United States of America (the U.S.)</h4>
        <p>
          We use top tier, third party data hosting providers (Digital Ocean) to host our Services on servers located in the U.S.
        </p>
        <p>
          By entering personal information into the Services, you consent to that personal information being hosted on servers
          located in the U.S. While your personal information will be stored on servers located in the U.S., it will remain
          within PrudentCO’s effective control at all times. Each data hosting provider’s role is limited to providing a hosting
          and storage service to PrudentCO, and we’ve taken steps to ensure that our data hosting providers do not have access to,
          and use the necessary level of protection for, Your personal information. They do not control, and are not permitted to
          access or use your personal information, except for the limited purpose of storing the information.
        </p>
        <h4>PrudentCO takes steps to protect your personal information</h4>
        <p>PrudentCO is committed to protecting the security of Your personal information and we take all reasonable precautions
          to protect it from unauthorized access, modification or disclosure. Your personal information is stored on secure
          servers that have SSL Certificates.
        </p>
        <p>
          However, the Internet is not in itself a secure environment and we cannot give an absolute assurance that Your
          information will be secure at all times. Transmission of personal information over the Internet is at Your own risk
          and You should only enter, or instruct the entering of, personal information to the Service within a secure environment.
        </p>
        <p>
          We will advise You at the first reasonable opportunity upon discovering or being advised of a security breach where
          Your personal information is lost, stolen, accessed, used, disclosed, copied, modified, or disposed of by any
          unauthorized persons or in any unauthorized manner.
        </p>
        <h4>PrudentCO only discloses Your Personal Information in limited circumstances</h4>
        <!--<p>PrudentCO will only disclose the personal information You have provided to us to entities outside the PrudentCO group of companies if it is necessary and appropriate to facilitate the purpose for which Your personal information was collected pursuant to this Policy, including the provision of the Service.</p>-->
        <p>
          PrudentCO will disclose Your personal information to a third party unless You have provided Your
          express consent. However, You should be aware that PrudentCO may be required to disclose Your personal information
          without Your consent in order to comply with any court orders, subpoenas, or other legal process or investigation,
          if such disclosure is required by law. Where possible and appropriate, we will notify You if we are required by law
          to disclose Your personal information.
        </p>
        <h4>PrudentCO does not store Your credit card details</h4>
        <p>If You choose to pay for the Service by credit card, Your credit card details are not stored by the Service and
          cannot be accessed by PrudentCO staff. Your credit card details are encrypted and securely stored by
          Authorize.Net to enable PrudentCO to automatically bill your credit card on a recurring basis. You should review
          <a href="http://www.authorize.net/company/privacy" target="_blank">Authorize.Net's Privacy Policy</a>
          to ensure you are happy with it.
        </p>
        <h4>You may request access to Your personal information</h4>
        <p>
          It is Your responsibility to ensure that the personal information You provide to us is accurate, complete and up-to-date.
          You may request access to the information we hold about You, or request that we update or correct any personal information
          we hold about You, by setting out Your request in writing and sending it to us at
          <a href="mailto:support@prudentco.com">support&#64;prudentco.com</a>.
        </p>
        <p>
          PrudentCO will process Your request as soon as reasonably practicable, provided we are not otherwise prevented from
          doing so on legal grounds. If we are unable to meet Your request, we will let you know why. For example, it may be
          necessary for us to deny Your request if it would have an unreasonable impact on the privacy or affairs of other
          individuals, or if it is not reasonable and practicable for us to process Your request in the manner You have requested.
          In some circumstances, it may be necessary for us to seek to arrange access to Your personal information through a
          mutually agreed intermediary.
        </p>
        <p>
          We’ll only keep Your personal information for as long as we require it for the purposes of providing You with the
          Service. However, we may also be required to keep some of Your personal information for specified periods of time,
          for example under certain laws relating to corporations, money laundering, and financial reporting legislation.
        </p>
        <h4>PrudentCO uses cookies</h4>
        <p>
          In providing the Service, PrudentCO utilizes "cookies". A cookie is a small text file that is stored on Your computer.
          A cookie does not identify You personally or contain any other information about You but
          it does identify Your computer.
        </p>
        <p>
          We and some of our affiliates and third-party service providers may use a combination of “persistent cookies” (cookies
          that remain on Your hard drive for an extended period of time) and “session ID cookies” (cookies that expire when You
          close Your browser) on the Website to, for example, track overall site usage, and track and report on Your use and
          interaction with ad impressions and ad services.
        </p>
        <p>
          You can set your browser to notify You when You receive a cookie so that You will have an opportunity to either accept
          or reject it in each instance. However, You should note that refusing cookies may have a negative impact on the functionality
          and usability of the Website.
        </p>
        <p>We do not respond to or honour “Do Not Track” requests at this time.</p>
        <h4>You can opt-out of any email communications</h4>
        <p>
          PrudentCO sends billing information, product information, Service updates and Service notifications to You via email.
          Our emails will contain clear and obvious instructions describing how You can choose to be removed from any mailing
          list not essential to the Service. PrudentCO will remove You at Your request.
        </p>
        <h4>You are responsible for transfer of Your data to third-party applications</h4>
        <p>
          The Service may allow You, the Subscriber, or another Invited User within the relevant subscription to the Service
          to transfer Data, including Your personal information, electronically to and from third-party applications. PrudentCO
          has no control over, and takes no responsibility for, the privacy practices or content of these applications. You are
          responsible for checking the privacy policy of any such applications so that You can be informed of how they will
          handle personal information.
        </p>
        <h4>PrudentCO has a privacy complaints process</h4>
        <p>
          If You wish to complain about how we have handled Your personal information, please provide us with
          full details of Your complaint and any supporting documentation:
        </p>
        <ul>
          <li>by e-mail at
            <a href="mailto:support@prudentco.com">support&#64;prudentco.com</a>, or
          </li>
          <li>by letter to The Privacy Officer, PrudentCO Corporation, PO Box 270172, Louisville, CO 80027.</li>
        </ul>
        <p>Our Privacy Officer will endeavour to:</p>
        <ul>
          <li>
            provide an initial response to Your query or complaint within 10 business days, and
          </li>
          <li>
            investigate and attempt to resolve Your query or complaint within 30 business days or such longer period as is
            necessary and notified to you by our Privacy Officer.
          </li>
        </ul>
        <h4>This policy may be updated from time to time</h4>
        <p>PrudentCO reserves the right to change this Policy at any time, and any amended Policy is effective upon posting to
          this Website. PrudentCO will make every effort to communicate any significant changes to You via email or notification
          via the Service. Your continued use of the Service will be deemed acceptance of any amended Policy.
        </p>
        <p>Last updated: August 2023</p>
      </div>
    </div>
  </div>
</div>
