<mat-card class="mt-2 mb-1" *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="'Loan Level MCR'" [help]="true">
      <div [innerHtml]="helpMcr"></div>
    </app-segment-header>
  </mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan MCR name</div>
      <div class="col-lg-6">{{mcr.name}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">State</div>
      <div class="col-lg-6">{{mcr.state}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6">
        <span class="fw-bold">MCR period</span> (latest)
      </div>
      <div class="col-lg-6">Q{{mcr.quarter}} {{mcr.year}}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Is this a repurchased loan?</div>
      <div class="col-lg-6">{{tranMcr.is_repurchased === true ? 'Yes' : (tranMcr.is_repurchased === false ? 'No' : '') }}</div>
    </div>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Loan amount</div>
      <div class="col-lg-6">{{tranMcr.loan_amount | currency}}</div>
    </div>
    <div [hidden]="isRepurchased.value===true">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan application received date</div>
        <div class="col-lg-6">{{tranMcr.start_date | date: 'shortDate'}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan disposition</div>
        <div class="col-lg-6">{{tranMcr.getLoanDisposition()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan closed date</div>
        <div class="col-lg-6">{{tranMcr.end_date | date: 'shortDate'}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">From who was the loan application received?</div>
        <div class="col-lg-6">{{tranMcr.from_borrower === true ? 'From borrower' : (tranMcr.from_borrower === false ? 'From third party' : '') }}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Is this a reverse loan (HECM)?</div>
        <div class="col-lg-6">{{tranMcr.is_hecm === true ? 'Yes' : (tranMcr.is_hecm === false ? 'No' : '') }}</div>
      </div>
      <div [hidden]="isHECM.value === false" class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Reverse loan type</div>
        <div class="col-lg-6">{{tranMcr.getHecmType()}}</div>
      </div>
      <div [hidden]="isHECM.value === false" class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Reverse loan purpose</div>
        <div class="col-lg-6">{{tranMcr.getReversePurpose()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Is this loan brokered or funded by your company?</div>
        <div class="col-lg-6">{{tranMcr.is_brokered === true ? 'Brokered' : (tranMcr.is_brokered === false ? 'Funded' : '') }}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Is loan subject to HOEPA?</div>
        <div class="col-lg-6">{{tranMcr.is_hoepa === true ? 'Yes' : (tranMcr.is_hoepa === false ? 'No' : '') }}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Was this loan's state changed?</div>
        <div class="col-lg-6">{{tranMcr.state_changed === 'A' ? 'Loan added to state': (tranMcr.state_changed === 'R' ? 'Loan removed from state' : 'Not Applicable')}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan QM classification</div>
        <div class="col-lg-6">{{tranMcr.getQMClassification()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Lien status</div>
        <div class="col-lg-6">{{tranMcr.getLienStatus()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan purpose</div>
        <div class="col-lg-6">{{tranMcr.getLoanPurpose()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Loan type</div>
        <div class="col-lg-6">{{tranMcr.getLoanType()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Property type</div>
        <div class="col-lg-6">{{tranMcr.getPropertyType()}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="tranMcr.is_brokered === true">
        <div class="col-lg-6 fw-bold">Broker fees</div>
        <div class="col-lg-6">{{tranMcr.broker_fee | currency}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="tranMcr.is_brokered === false">
        <div class="col-lg-6">
          <span class="fw-bold">Lender fees</span>
        </div>
        <div class="col-lg-6">{{tranMcr.lender_fee | currency}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6">
          <span class="fw-bold">Previous loan amount</span> (if applicable)
        </div>
        <div class="col-lg-6">{{tranMcr.prev_loan_amount | currency}}</div>
      </div>
    </div>
    <form [formGroup]="form" class="mt-3">
      <button (click)="edit()" class="btn btn-lg btn-primary" type="button">Edit MCR</button>
      <button (click)="back()" *ngIf="!doc" class="ms-2 btn btn-lg btn-light" type="button">
        <i class="fa fa-chevron-left"></i>&nbsp;Back
      </button>
    </form>
  </mat-card-content>
</mat-card>
