import {Component, Injector, Input, OnInit} from '@angular/core';
import {LoanInformation} from '../../../../models/loan-information';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-loan-information',
  templateUrl: './view-segment-loan-information.component.html',

})
export class ViewSegmentLoanInformationComponent extends CompBaseComponent implements OnInit {
  @Input() item: LoanInformation;
  public isRefinanceLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isRefinanceLoan = this.analyzerService.isRefinanceLoan(this.doc.recursive_attributes);
  }
}
