import {Component, Injector, OnInit} from '@angular/core';
import {DependentComponent} from '../dependent/dependent.component';

@Component({
  selector: 'app-edit-segment-dependent',
  templateUrl: '../dependent/edit-segment-dependent.component.html',
})
export class EditSegmentDependentComponent extends DependentComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
