import {Component, Injector, OnInit} from '@angular/core';
import {Note} from '../../../models/note';
import {NoteComponent} from '../note/note.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-add-note',
  templateUrl: '../note/edit-segment-note.component.html',
})
export class AddNoteComponent extends NoteComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector, public route: ActivatedRoute, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe((map) => {
      this.tranId = map['params']['id'];
      this.createFormControls(new Note({}));
      this.createForm();
      setTimeout(() => {
        this.reloaded = true;
      }, 0);
    });
  }
}
