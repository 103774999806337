<div class="row pt-5">
  <div class="col-lg-3 mx-auto background-warning-color text-center">
    <div class="p-3">
      <h1>404</h1>
      <h2>Page not found!</h2>
      <p>Sorry, but the page you were trying to view does not exist!</p>
      <p>Navigate back or close this window.</p>
    </div>
  </div>
</div>
