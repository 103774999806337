import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {AdminService} from '../../../../services/admin.service';
import {Help} from '../../../../models/help';
import {Tran} from '../../../../models/tran';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-view-tran-list',
  templateUrl: './view-tran-list.component.html',
})
export class ViewTranListComponent extends CompBaseComponent implements OnInit {
  public adminService: AdminService;
  public trans: Array<Tran>;
  public pageTitle = 'Transaction List';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.adminService = this.injector.get(AdminService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.findTrans(params);
    });
  }

  findTrans(params): void {
    this.reloaded = false;
    this.adminService.findTran(params['name'])
      .then((trans) => this.trans = trans)
      .then(() => this.reloaded = true)
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to Find Trans', data);
      });
  }

  editTran(event: any, tran: Tran): void {
    event.preventDefault();
    this.router.navigate(['/admin/view-tran', tran.id]);
  }
}
