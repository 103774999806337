<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Loan Application</mat-card-title>

  <mat-card-content>
    <p>
      The Loan Application is organized into tabs and each tab is organized as a horizontal menu.
      Each loan application form corresponds to a specific Fannie Mae data segment.
      The loan officer can add comments ("sticky notes") to each data segment,
      to help the borrower with data entry.
    </p>

    <p>
      POLOS hides fields and data segments that are irrelevant to the Loan Application.
      For this feature to be most useful,
      fill out the Loan Application moving from
      <strong>the left to the right tab</strong>,
      and traverse each tab from
      <strong>top to bottom</strong>.
    </p>

    <p>
      In most cases, the borrower can complete their part of the Loan Application in about 15&ndash;20 minutes.
      There are parts of the Loan Application that are only accessible to the loan officer.
    </p>

    <h5>Contextual Help</h5>

    <p>
      Each data segment comes with specific help available by clicking the help (question mark) icon next to the header.
      Clicking anywhere on the help window or on the help icon toggles the Help display.
    </p>
  </mat-card-content>
</mat-card>
