import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Tran} from '../models/tran';
import {Document} from '../models/document';
import {TranService} from './tran.service';

@Injectable()
export class LoanAppService {

  constructor(private tranService: TranService,
              private httpService: HttpService
  ) {
  }

  public getCurrentLoanDocument(force?: boolean): Promise<Document> {
    return this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        if (!tran) {
          return Promise.resolve(null);
        }
        const found = tran['documents'].find((elt) => {
          return elt.dtype === 64 && elt.category === 0;
        });
        const doc = found ? found : null;
        return Promise.resolve(doc);
      });
  }

  public updateLoanApp(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`loan_apps/update_loan_application/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateLoanAppObj(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`loan_apps/update_loan_app_obj/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public addLoanAppItem(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`loan_apps/add_loan_app_item/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updateLoanAppItem(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`loan_apps/update_loan_app_item/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public emptyLoanAppObj(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`loan_apps/empty_loan_application_obj/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public deleteItem(docId: number, payload): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`loan_apps/delete_loan_application_obj/${docId}`, payload)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public finalizeLoanApp(documentId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`loan_apps/finalize_loan_application/${documentId}`, null)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then((data) => {
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  updateApplicantLiabilities(docId: number, payload: any): Promise<boolean> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.put('loan_apps/update_applicant_liabilities/' + docId, payload)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
