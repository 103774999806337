<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on My Dashboard
  </mat-card-title>
  <mat-card-content>
    <h5>My Dashboard</h5>

    <p>
      My Dashboard displays compliance, workflow, and messages highlights for all the active loans.
      Clicking any table row navigates to the corresponding loan function.
    </p>

    <h5>Compliance Dashboard</h5>

    <p>
      Compliance Dashboard displays compliance highlights for all active loans.
      The colors follow the same convention as for loan compliance.
    </p>
    `
    <div class="row mb-1">
      <div class="col-lg-6">
        Compliance not applicable
      </div>
      <div class="col-lg-6 pr-light-gray fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Completed on time
      </div>
      <div class="col-lg-6 pr-success fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Outstanding
      </div>
      <div class="col-lg-6 pr-light fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Due today
      </div>
      <div class="col-lg-6 pr-warning fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Completed but not compliant
        <small [hidden]="isMobile">(too early or too late)</small>
      </div>
      <div class="col-lg-6 pr-danger fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Missed
      </div>
      <div class="col-lg-6 pr-fatal fw-bold">
        &nbsp;
      </div>
    </div>

    <h5>Workflow Dashboard</h5>

    <p>
      Workflow Dashboard displays the current task of each active loan.
    </p>

    <h5>Messages Dashboard</h5>

    <p>
      Messages Dashboard displays counts of received messages in the last 24 hours, 7, days, and anytime for each of the
      active loans.
      The most recent non-zero count is colored according to the following legend.
    </p>

    <div class="row mb-1">
      <div class="col-lg-6">
        Received in the last 24 hours
      </div>
      <div class="col-lg-6 pr-warning fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Received in the last seven days
      </div>
      <div class="col-lg-6 pr-light fw-bold">
        &nbsp;
      </div>
    </div>

    <div class="row mb-1">
      <div class="col-lg-6">
        Received anytime
      </div>
      <div class="col-lg-6 pr-light-gray fw-bold">
        &nbsp;
      </div>
    </div>
  </mat-card-content>
</mat-card>
