<mat-card class="mt-1 mb-1">
  <mat-card-title>Branding Profile</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company name</mat-label>
            <input formControlName="company" matInput appAutoFocus placeholder="Company name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="company.hasError('minlength')">
              Company name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Office name</mat-label>
            <input formControlName="office" matInput placeholder="Office name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="office.hasError('minlength')">
              Office name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Company URL</mat-label>
            <input formControlName="company_url" matInput placeholder="Company URL" maxlength="{{MAX_LEN.URL}}">
            <mat-hint>URL must start with http:// or https://</mat-hint>
            <mat-error *ngIf="company_url.hasError('pattern')">
              URL is invalid. URL must start with "http://" or "https://".
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>About my company</mat-label>
            <textarea cdkAutosizeMaxRows="10" cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="about_my_company"
              matInput
              placeholder="About my company">
            </textarea>
            <mat-error *ngIf="about_my_company.hasError('minlength')">
              Text is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Phone number</mat-label>
            <input appPhoneOnly formControlName="phone" matInput placeholder="Phone number" maxlength="{{MAX_LEN.PHONE}}">
            <mat-hint>format: xxx-xxx-xxxx</mat-hint>
            <mat-error *ngIf="phone.hasError('pattern')">
              Phone number is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-3 mt-2">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Personal URL</mat-label>
            <input formControlName="personal_url" matInput placeholder="Personal URL" maxlength="{{MAX_LEN.URL}}">
            <mat-hint>URL must start with http:// or https://</mat-hint>
            <mat-error *ngIf="personal_url.hasError('pattern')">
              URL is invalid. URL must start with "http://" or "https://".
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>About me</mat-label>
            <textarea cdkAutosizeMaxRows="10" cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="about_me"
              matInput
              placeholder="About me">
            </textarea>
            <mat-error *ngIf="about_me.hasError('minlength')">
              Text is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>My hobbies</mat-label>
            <textarea cdkAutosizeMaxRows="3" cdkAutosizeMinRows="1"
              cdkTextareaAutosize
              formControlName="hobbies"
              matInput
              placeholder="My hobbies">
            </textarea>
            <mat-error *ngIf="hobbies.hasError('minlength')">
              Text is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
