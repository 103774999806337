import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RealEstate} from '../../../../models/real-estate';
import {globals} from '../../../../shared/globals/globals';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {ModelBase} from '../../../../models/model-base';

@Component({
  selector: 'app-real-estate',
  template: '',
})
export class RealEstateComponent extends CompBaseComponent implements OnInit {
  @Input() item: RealEstate;
  public property_dispositions_ex = RealEstate.property_dispositions_ex;
  public occupancy_types = RealEstate.occupancy_types;
  public partyIds: Array<any>;
  public newPartyIds = [];

  // form and form controls
  public form: FormGroup;
  public property_address: FormControl;
  public property_address2: FormControl;
  public property_city: FormControl;
  public property_state: FormControl;
  public property_country: FormControl;
  public property_zip: FormControl;
  public property_zip_four: FormControl;
  public property_disposition_ex: FormControl;
  public estimated_market_value: FormControl;
  public market_value: FormControl;
  public liens_amount: FormControl;
  public monthly_expenses: FormControl;
  public gross_rental_income: FormControl;
  public net_rental_income: FormControl;
  public property_secure_loan: FormControl;
  public current_occupancy: FormControl;
  public occupancy: FormControl;
  public occupancy_other_description: FormControl;
  public party_ids: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'la_real_estates';
    this.section = null;
    this.partyIds = [];
    this.newPartyIds = [];
  }

  setPartyIds(): void {
    this.item.party_ids = this.item.party_ids || '';
    this.partyIds = this.item.party_ids.split(',').map((elt) => {
      return {key: elt, value: ModelBase.partyTable[elt]};
    });
  }

  getPartyIds(): string {
    return this.partyIds.map(elt => elt.value).join(', ');
  }

  setParties(): void {
    this.partyIds.sort((x, y) => x.key.localeCompare(y.key));
    const count = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    for (let i = 1; i <= 4; i++) {
      if (i <= count) {
        if (!this.partyIds.map(elt => elt.key).includes(`b${i}`)) {
          this.newPartyIds.push({key: `b${i}`, value: ModelBase.partyTable[`b${i}`]});
        }
      }
    }
    this.newPartyIds.sort((x, y) => x.key.localeCompare(y.key));
  }

  deletePartyId(partyId): void {
    const index = this.partyIds.findIndex((elt) => elt.key === partyId);
    this.partyIds.splice(index, 1);
    this.newPartyIds = [];
    this.setParties();
  }

  addPartyId(partyId): void {
    const index = this.newPartyIds.findIndex((elt) => elt.key === partyId);
    this.partyIds.push({key: partyId, value: ModelBase.partyTable[partyId]});
    this.newPartyIds = [];
    this.setParties();
  }

  createFormControls(item: RealEstate): void {
    this.property_address = new FormControl(item.property_address, AppValidators.present);
    this.property_address2 = new FormControl(item.property_address2);
    this.property_city = new FormControl(item.property_city, AppValidators.present);
    this.property_state = new FormControl(item.property_state, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.state)]));
    this.property_country = new FormControl(item.property_country, Validators.pattern(globals.pattern.country));
    this.property_zip = new FormControl(item.property_zip, Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.zip)]));
    this.property_zip_four = new FormControl(item.property_zip_four, Validators.pattern(globals.pattern.zip_four));
    this.property_disposition_ex = new FormControl(item.property_disposition_ex, AppValidators.present);
    this.estimated_market_value = new FormControl(item.estimated_market_value, Validators.pattern(globals.pattern.decimalTwo));
    this.market_value = new FormControl(item.market_value, Validators.compose([Validators.pattern(globals.pattern.decimalTwo)]));
    this.liens_amount = new FormControl(item.liens_amount, Validators.pattern(globals.pattern.decimalTwo));
    this.monthly_expenses = new FormControl(item.monthly_expenses, Validators.pattern(globals.pattern.decimalTwo));
    this.gross_rental_income = new FormControl(item.gross_rental_income, Validators.pattern(globals.pattern.decimalTwo));
    this.net_rental_income = new FormControl(item.net_rental_income, Validators.pattern(globals.pattern.decimalTwo));
    this.property_secure_loan = new FormControl(item.property_secure_loan);
    this.current_occupancy = new FormControl(item.current_occupancy);
    this.occupancy = new FormControl(item.occupancy);
    this.occupancy_other_description = new FormControl(item.occupancy_other_description);
    this.party_ids = new FormControl(item.party_ids);
  }

  createForm(): void {
    this.form = new FormGroup({
      property_address: this.property_address,
      property_address2: this.property_address2,
      property_city: this.property_city,
      property_state: this.property_state,
      property_zip: this.property_zip,
      property_zip_four: this.property_zip_four,
      property_country: this.property_country,
      property_disposition_ex: this.property_disposition_ex,
      estimated_market_value: this.estimated_market_value,
      market_value: this.market_value,
      liens_amount: this.liens_amount,
      gross_rental_income: this.gross_rental_income,
      monthly_expenses: this.monthly_expenses,
      net_rental_income: this.net_rental_income,
      property_secure_loan: this.property_secure_loan,
      current_occupancy: this.current_occupancy,
      occupancy: this.occupancy,
      occupancy_other_description: this.occupancy_other_description,
      party_ids: this.party_ids
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  // todo: need to check and set party_ids
  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (this.partyIds.length === 0 || !this.form.valid) {
      return;
    }
    this.party_ids.setValue(this.partyIds.map((elt) => elt.key).join(','));
    const updated = new RealEstate(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (this.partyIds.length === 0 || !this.form.valid) {
      return;
    }
    this.party_ids.setValue(this.partyIds.map((elt) => elt.key).join(','));
    const updated = new RealEstate(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
