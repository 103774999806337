import {Component, Injector, OnInit} from '@angular/core';
import {RealEstateComponent} from '../real-estate/real-estate.component';

@Component({
  selector: 'app-edit-segment-real-estate',
  templateUrl: '../real-estate/edit-segment-real-estate.component.html',
})
export class EditSegmentRealEstateComponent extends RealEstateComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
    this.setPartyIds();
    this.setParties();
  }
}
