<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="true" [header]="'POLOS Online Config'" [help]="true" [op]="'add'">
        <p>
          Configure POLOS's configuration items.
        </p>
      </app-segment-header>
    </mat-card-title>

    <div *ngFor="let item of items">
      <app-view-segment-config (eventEmitted)="onEvent($event)" [item]="item"></app-view-segment-config>
    </div>
  </mat-card>
</div>
