import {Component, Injector, OnInit} from '@angular/core';
import {ConfigComponent} from '.././config/config.component';
import {Router} from '@angular/router';
import {ConfigItem} from "../../../models/config-item";

@Component({
  selector: 'app-add-config',
  templateUrl: '../config/edit-segment-config.component.html',
})
export class AddConfigComponent extends ConfigComponent implements OnInit {
  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new ConfigItem({}));
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    });
  }

  cancel(): void {
    this.router.navigate(['admin/configs']);
  }
}
