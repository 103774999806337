import {Component, Injector, OnInit} from '@angular/core';
import {GiftComponent} from '../gift/gift.component';

@Component({
  selector: 'app-edit-segment-gift',
  templateUrl: '../gift/edit-segment-gift.component.html',
})
export class EditSegmentGiftComponent extends GiftComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
