import {Component, Injector, OnInit} from '@angular/core';
import {HelpBaseComponent} from '../../../help-base.component';

@Component({
  selector: 'app-help-loans-list',
  templateUrl: './help-loans-list.component.html',

})
export class HelpLoansListComponent extends HelpBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
