import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {TranService} from './tran.service';

@Injectable()
export class UploadFileService {

  constructor(private httpService: HttpService, private tranService: TranService) {
  }

  upload(file: File, actionURL: string): Promise<boolean> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.httpService.post(actionURL, formData, true)
        .then(() => {
          resolve(true)
        }).catch((data) => {
        reject(data);
      });
    });
  }
}
