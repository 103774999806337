import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Note} from '../models/note';
import {CacheService} from './cache.service';

@Injectable()
export class NoteService {

  constructor(private httpService: HttpService, private cacheService: CacheService) {
    this.cacheService.resetSeg('post');
  }

  public getPosts(force: boolean, transactionId: number): Promise<Array<Note>> {
    const posts = this.cacheService.getSeg('post', 'posts');
    if (!force && posts) {
      return Promise.resolve(posts);
    }
    return new Promise((resolve, reject) => {
      return this.httpService.get(`notes?tran_id=${transactionId}`)
        .then((data) => {
          const posts = Note.deserializeArray(data);
          this.cacheService.setSeg('post', 'posts', posts);
          resolve(posts);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public getPostById(noteId): Promise<Note> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`notes/${noteId}`)
        .then((data) => {
          resolve(new Note(data));
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public createPost(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`notes`, payload)
        .then((data) => {
          this.cacheService.resetSeg('post');
          return resolve(true);
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public updatePost(postId: number, payload: any): Promise<Note> {
    return new Promise((resolve, reject) => {
      return this.httpService.put(`notes/${postId}`, payload)
        .then((data) => {
          this.cacheService.resetSeg('post');
          return resolve(new Note(data));
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }

  public deletePost(transactionId: number, postId: number): Promise<Note> {
    return new Promise((resolve, reject) => {
      return this.httpService.delete(`notes/${postId}`)
        .then((data) => {
          this.cacheService.resetSeg('post');
          return resolve(new Note(data));
        })
        .catch((data) => {
          return reject(data);
        });
    });
  }
}
