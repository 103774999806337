import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrderComponent} from "../order/order.component";
import {Help} from "../../../../models/help";

@Component({
  selector: 'app-add-order',
  templateUrl: '../order/edit-segment-order.component.html',
})
export class AddOrderComponent extends OrderComponent implements OnInit {
  pageTitle = 'Add Services'
  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls({services: this.user.services});
    this.createForm();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountSettings
      });
    }, 0);

    setTimeout(() => {
      this.reloaded = true;
    });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
