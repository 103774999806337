<div *ngIf="reloaded&&canUpdateTransaction">
  <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Notes'" [help]="false" [op]="'add'" [isCard]="false"></app-segment-header>

  <div *ngFor="let note of notes">
    <app-view-segment-note (eventEmitted)="onEvent($event)" [note]="note" [tran]="tran"></app-view-segment-note>
  </div>

  <app-box *ngIf="!notes||(notes.length===0)" class="mt-3" [type]="'warning'">
    <strong>No notes!</strong>
  </app-box>
</div>
