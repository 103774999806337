import {Component, Injector, OnInit} from '@angular/core';
import {CompEventComponent} from '../comp-event/comp-event.component';

@Component({
  selector: 'app-view-segment-comp-event',
  templateUrl: './view-segment-comp-event.component.html',
})
export class ViewSegmentCompEventComponent extends CompEventComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  editCompEvent(): void {
    if (this.item.code !== 'lock') {
      this.eventEmitted.emit({type: 'ChangeMode', mode: 'Edit'});
    }
  }
}
