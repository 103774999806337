import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {ProfessionalProfile} from '../../../../models/professional-profile';

@Component({
  selector: 'app-view-segment-profile',
  templateUrl: './view-segment-profile.component.html',
})
export class ViewSegmentProfileComponent extends CompBaseComponent implements OnInit {
  @Input() item: ProfessionalProfile;
  canDeleteLogo: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  onCancel() {
    this.mode = 'View';
  }

  uploadLogo(event: any): void {
    event.preventDefault();
    this.mode = 'Upload';
  }

  deleteLogo(): void {
    this.userService.deleteAccountLogo()
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Logo successfully deleted');
        return this.tranService.getCurrentTran();
      })
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete logo', data);
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  onUploadComplete(): void {
    this.tranService.getCurrentTran(true)
      .then(() => this.userService.refreshUser())
      .then(() => this.tranService.getCurrentTran())
      .then(() => {
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  reload(force?: boolean): void {
    this.mode = 'View';
    this.reloaded = false;
    if (force) {
      this.userService.refreshUser()
        .then(() => {
          this.canDeleteLogo = this.item.logo_url.indexOf('prudentco') < 0;
          this.reloaded = true;
        })
        .catch(() => {
        });
      return;
    }
    this.canDeleteLogo = this.item.logo_url.indexOf('prudentco') < 0;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
