<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Assets'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            All asset types, except for gifts and real estate, should be entered using this form.
          </li>
          <li>
            For each asset, enter the asset's owner or owners.
            The owner(s) can be the borrower, co-borrower, an entity (non-person) or a combination thereof.
          </li>
          <li>You can edit or delete assets.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-asset (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc"></app-add-asset>

    <div *ngFor="let item of items">
      <app-view-asset (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-asset>
    </div>
  </mat-card>
</div>
