import {Component, Injector, OnInit} from '@angular/core';
import {ContactComponent} from '../contact/contact.component';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.component.html',
})
export class ViewContactComponent extends ContactComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }
}
