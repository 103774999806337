import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Alias} from '../../../../models/alias';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';

@Component({
  selector: 'app-alias',
  templateUrl: './edit-segment-alias.component.html',
})
export class AliasComponent extends CompBaseComponent implements OnInit {
  @Input() item: Alias;
  public op: string;
  public form: FormGroup;
  public alternate_first: FormControl;
  public alternate_middle: FormControl;
  public alternate_last: FormControl;
  public alternate_suffix: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'aliases';
    this.section = this.applicant;
  }

  createFormControls(item: Alias): void {
    this.alternate_first = new FormControl(item.alternate_first, AppValidators.present);
    this.alternate_middle = new FormControl(item.alternate_middle);
    this.alternate_last = new FormControl(item.alternate_last, AppValidators.present);
    this.alternate_suffix = new FormControl(item.alternate_suffix);
  }

  createForm(): void {
    this.form = new FormGroup({
      alternate_first: this.alternate_first,
      alternate_middle: this.alternate_middle,
      alternate_last: this.alternate_last,
      alternate_suffix: this.alternate_suffix,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Alias(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data['aliases'] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Alias(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section, index: this.item.index};
    data['aliases'] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

}
