<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="mcrTitle" [help]="true">
      <p>
        The organization MCR is the tally of all the organization loan officer MCRs and is the one
        a mortgage company reports to NMLS quarterly.
      </p>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate class="mt-2">
      <app-box [hidden]="true" [type]="'warning'" class="mt-2"></app-box>

      <!-- STATE -->
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>State abbreviations</mat-label>
            <input formControlName="states" [readonly]="!!xmlMcrValid" matInput placeholder="Enter state abbreviations" appUpperCase maxlength="{{MAX_LEN.STATE_ABBRS}}" required>
            <mat-error *ngIf="states.hasError('required')">
              State(s) is required
            </mat-error>
            <mat-error *ngIf="states.hasError('pattern')">
              State(s) is invalid
            </mat-error>
            <mat-hint>Enter one or more state abbreviations: e.g., "CA, CO"</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!-- Include Serviced -->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <mat-checkbox formControlName="include_serviced" class="mb-0">Include 0's for "Loans Serviced Nationwide Totals" Section</mat-checkbox>-->
<!--          <div>-->
<!--            <mat-hint style="font-size: smaller">-->
<!--              Leave this checkbox checked unless your organization services loans.-->
<!--            </mat-hint>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </form>

    <iframe *ngIf="downloading" [src]="urlSafe" class="d-none">
    </iframe>

    <div [hidden]="false" [formGroup]="form" class="mt-3">
      <button (click)="checkOrgXmlMcr()" [disabled]="!form.valid ||states.hasError('required') || states.hasError('pattern')" [hidden]="xmlMcrValid" class="btn btn-lg btn-primary" type="button" title="Generate XML MCR" aria-label="Generate XML">Generate XML</button>
      <button (click)="genOrgXmlMcr()" [hidden]="!xmlMcrValid" class="ms-2 btn btn-lg btn-primary" type="button" title="Generate XML MCR" aria-label="Download XML">Download XML</button>
      <button (click)="view()" class="ms-2 btn btn-lg btn-light" type="button">
        <i class="fa fa-chevron-left"></i>&nbsp;Back
      </button>
    </div>
  </mat-card-content>
</mat-card>
