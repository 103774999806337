import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class PageDeviceComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  navigateToSign(event: any): void {
    event.preventDefault();
    this.router.navigate(['/pages/sign-in']);
  }
}
