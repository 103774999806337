<div *ngIf="reloaded">
  <mat-card *ngIf="!explanationsRequired" class="mt-2 mb-1">
    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Declaration Explanations'" [help]="false">
      </app-segment-header>
    </mat-card-title>

    <app-box [type]="'primary'" [close]="true" class="mt-2">
      No declaration explanations are required.
    </app-box>
  </mat-card>

  <mat-card *ngIf="explanationsRequired" class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="'Declaration Explanations'" [help]="true">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>
            Please provide information for each declaration listed below to explain and support your position.
          </li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <div *ngFor="let item of items">
      <app-view-declaration-explanation (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item"></app-view-declaration-explanation>
    </div>
  </mat-card>
</div>
