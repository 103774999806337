<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Asset type</mat-label>
            <mat-select formControlName="asset_type_ex" placeholder="Select asset type" required>
              <mat-option *ngFor="let asset_type of asset_types" [value]="asset_type.key">{{asset_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="asset_type_ex.hasError('required')">
              Asset type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="asset_type_ex.value!=='Other'" class="row">
        <div class="col-lg-12">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other asset description</mat-label>
            <input [required]="asset_type_ex.value==='Other'" formControlName="institution_asset_description"  placeholder="Describe other asset" matInput maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="institution_asset_description.hasError('required')">
              Describe other asset is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Asset holder name</mat-label>
            <input formControlName="institution_name" matInput placeholder="Asset holder name" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="institution_name.hasError('required')">
              Asset holder name is required
            </mat-error>
            <mat-hint>(applicable only for assets held by another entity such as bank)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Account number</mat-label>
            <input formControlName="institution_account_no" matInput placeholder="Account number" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="institution_account_no.hasError('required')">
              Account number is required
            </mat-error>
            <mat-hint>(applicable only for assets held by another entity such as bank)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Market value</mat-label>
            <input appDecimalOnly formControlName="institution_market_value" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Market value" required>
            <mat-error *ngIf="institution_market_value.hasError('required')">
              Market value is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pt-1 pb-2">
        <div class="col fw-bold">Asset owners</div>
      </div>

      <div class="row hover-gray">
        <div class="col pt-2">
          <div *ngIf="partyIds.length===0&&submitted===true" class="text-danger">
            Owner(s) is required!
          </div>
          <div *ngIf="partyIds.length>0">
            <div *ngFor="let partyId of partyIds" class="mb-2">
              <span class="badge bg-success" style="font-size:1.1rem;">
                {{partyId.value}}&nbsp;<i (click)="deletePartyId(partyId.key)" class="far fa-window-close hand-pointer"></i>
              </span>&nbsp;
            </div>
          </div>

          <div *ngIf="newPartyIds.length>0">
            <div *ngFor="let partyId of newPartyIds" class="mb-2">
            <span class="badge bg-secondary" style="font-size:1.1rem;">
              {{partyId.value}}&nbsp;<i (click)="addPartyId(partyId.key)" class="fas fa-plus-square hand-pointer"></i>
            </span>&nbsp;
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
