import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {UserService} from './user.service';
import {TranService} from './tran.service';
import {ModelBase} from '../models/model-base';
import {Tran} from "../models/tran";
import {Document} from "../models/document";

@Injectable()
export class PermissionService {
  constructor(private httpService: HttpService,
              private userService: UserService,
              private tranService: TranService
  ) {
  }

  public static checkReadNoWrite(val: number): boolean {
    const valRWOnly = val & (~ModelBase.PERMISSION.DIR) & (~ModelBase.PERMISSION.X);
    return valRWOnly === ModelBase.PERMISSION.R;
  }

  public static checkReadAndWrite(val: number): boolean {
    const valRWOnly = val & (~ModelBase.PERMISSION.DIR) & (~ModelBase.PERMISSION.X);
    return valRWOnly === ModelBase.PERMISSION.RW;
  }

  canRead(permission: number): boolean {
    return (permission & ModelBase.PERMISSION.R) > 0;
  }

  canReadDocs(permission: number): boolean {
    return ((permission & ModelBase.PERMISSION.X) > 0) || ((permission & ModelBase.PERMISSION.DIR) > 0);
  }

  canUpdate(permission: number): boolean {
    return (permission & ModelBase.PERMISSION.W) > 0;
  }

  canDelete(permission: number): boolean {
    return (permission & ModelBase.PERMISSION.X) > 0;
  }

  isOwner(permission: number): boolean {
    return (permission & ModelBase.PERMISSION.X) > 0;
  }

  isLoanActive(): boolean {
    const tran = this.tranService.getCurrentTranSync();
    return tran ? tran.isActive() : false;
  }

  // Comment: this is the high-level access to getting resource permission
  execCan(canDoWhat: string, resource?: any): boolean {
    const licensedToUploads: boolean = UserService.licensedToUploads;
    const licensedToMCR: boolean = UserService.licensedToMCR;
    const licensedToLoans: boolean = UserService.licensedToLoans;
    const licensedToBorrower: boolean = UserService.licensedToBorrower;
    const licensedToAncillary: boolean = UserService.licensedToAncillary;
    const canAccessLoans: boolean = UserService.licensedToBorrower || UserService.licensedToLoans || licensedToAncillary;

    switch (canDoWhat) {
      // ACCOUNT
      case 'CanReadContacts':
        return true;
      case 'CanCreateContact':
        return licensedToLoans || licensedToMCR
      case 'CanBeLoanProfessional':
        return licensedToLoans;
      case 'CanAccessMCR':
        return licensedToMCR;
      case 'CanBeBorrower':
        return licensedToBorrower;

      // COMMUNICATION
      case 'CanCommunicate':
        return true;
      case 'CanEmail':
        return licensedToLoans;

      // ezUploads
      case 'CanCreateEzUploads':
        return licensedToUploads;

      // MCR
      case 'CanCreateMCRs':
        return licensedToMCR;

      // Organization
      case 'CanReadOrg': // can access Account/Organization
        return licensedToLoans && this.userService.isLoanOfficer();

      // ADMIN & PRESENTER
      case 'CanPresent':
        return this.userService.isPresenter();
      case 'CanCreateUser':
        return this.userService.isAdmin();

      // LOAN
      case 'CanUpload':
      case 'CanReadRepo':
        return this.can('Read', 'repo');
      case 'CanCreateTransaction':
        return licensedToLoans && this.userService.isLoanOfficer();
      case 'CanUpdateTransaction':
      case 'CanUpdateLoanApps':
        return this.isLoanActive() && licensedToLoans && this.can('Update', 'transaction');
      case 'CanDeleteTransaction':
        return licensedToLoans && this.can('Delete', 'transaction');
      case 'CanUpdateLoanRecord':
        return licensedToLoans && this.can('Update', 'loanRecord');
      // "Document" refers to the loan application
      case 'CanUpdateDocument':
        return this.isLoanActive() && canAccessLoans && this.can('Update', 'document', resource);
      case 'CanUpdateComment':
        return licensedToLoans && this.can('Update', 'comment', resource);
      case 'CanReadTransactions':
        return canAccessLoans;
      case 'CanReadDocs':
        return this.can('Read', 'dir');
      case 'CanCopyDocs':
      case 'CanGenDocs':
        return this.tranService.isLoanOfficerTran() && licensedToLoans && this.can('Update', 'dir');
      case 'CanReadDashboard':
        return licensedToLoans && this.can('Read', 'transaction');
      case 'CanReadTimeline':
        return this.tranService.isLoanOfficerTran() && this.can('Read', 'timeline');
      case 'CanReadZip':
        return licensedToLoans && this.can('Read', 'dir');
      case 'CanReadLoanApp':
        return this.can('Read', 'loanApp');
      case 'CanReadLoanSeg':
        return licensedToLoans && this.can('Read', 'loanSeg');
      // No sandbox support
      case 'CanCreateSandboxTransaction':
        return false;

      default:
        return false;
    }
  }

  can(op: string, resourceName: string, resource?: any): boolean {
    const operation: string = op.charAt(0).toUpperCase() + op.substring(1);
    switch (resourceName) {
      case 'loanRecord':
        if (operation === 'Update') {
          return this.canUpdate(this.getUserPermission('loanRecord'));
        }
        break;

      case 'transaction':
        switch (operation) {
          case 'Delete':
            return this.canDelete(this.getUserPermission('transaction'));
          case 'Read':
            return this.canRead(this.getUserPermission('transaction'));
          case 'Update':
            return this.canUpdate(this.getUserPermission('transaction'));
        }
        break;

      case 'timeline':
        switch (operation) {
          case 'Read':
            return this.canRead(this.getUserPermission('transaction'));
        }
        break;

      case 'docs':
        switch (operation) {
          case 'Copy':
          case 'Gen':
            return this.can('Update', 'transaction');
        }
        break;

      case 'dir':
        switch (operation) {
          case 'Read':
            return this.canReadDocs(this.getUserPermission('transaction'));
          case 'Update':
            return this.canUpdate(this.getUserPermission('transaction'));
        }
        break;

      case 'repo':
        switch (operation) {
          case 'Read':
            return this.can('Read', 'transaction');
        }
        break;

      case 'zip':
        if (operation === 'Read') {
          return this.can('Update', 'transaction');
        }
        break;

      case 'comment':
        if (operation === 'Update') {
          return this.can('Update', 'document', resource);
        }
        break;

      case 'document':
        if (operation === 'Update') {
          if (resource) {
            return this.canUpdate(this.getUserPermission('document', resource.user_permission));
          }
        }
        break;

      case 'loanApp':
        if (operation === 'Read') {
          const tran: Tran = this.tranService.getCurrentTranSync();
          const doc: Document = tran ? tran.getDefaultLoanApp() : null;
          if (doc) {
            return this.canRead(doc.user_permission);
          }
        }
        break;

      case 'loanSeg':
        if (operation === 'Read') {
          const tran: Tran = this.tranService.getCurrentTranSync();
          const doc: Document = tran ? tran.getDefaultLoanApp() : null;
          if (doc) {
            return this.canRead(doc.user_permission);
          }
        }
        break;
    }
    return false;
  }

  // Purpose: limit user access to inactive only to loan-professionals
  getUserPermission(resource: string, inPermission?: number) {
    const licensedToLoans = UserService.licensedToLoans
    const permission: number = this.getUserPermissionRaw(resource, inPermission);
    if (licensedToLoans) {
      return permission;
    }
    const tran: Tran = this.tranService.getCurrentTranSync();
    return (tran && tran.isActive()) ? permission : permission & ModelBase.PERMISSION.R;
  }

  getUserPermissionRaw(resource: string, inPermission?: number) {
    if (!resource) {
      return ModelBase.PERMISSION.NONE;
    }
    switch (resource) {
      case 'document':
        return inPermission;
      case 'loanRecord':
      case 'transaction':
        const tran: Tran = this.tranService.getCurrentTranSync();
        return tran ? tran['user_permission'] : ModelBase.PERMISSION.NONE;
      default:
        return ModelBase.PERMISSION.NONE;
    }
  }

  public updatePermission(userId: number, payload: any): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpService.put(`permissions/${userId}`, payload);
        await this.tranService.getCurrentTran(true);
        resolve(true);
      } catch (data) {
        reject(data);
      }
    });
  }
}
