import {Injectable} from '@angular/core';
import {IMenuItem} from '../components/menu/menu-item';
import {MENU} from '../../settings/menu';
import {MENU_BILLING} from '../../settings/menu_billing';
import {UserService} from "./user.service";
import {ModelBase} from "../models/model-base";

@Injectable()
export class MenuService {
  constructor(private userService: UserService,
  ) {
  }

  getMenuItems(): IMenuItem[] {
    const user = this.userService.getUserSync();
    if (!user.user_account.status || [ModelBase.UAB_STATUS.ERROR, ModelBase.UAB_STATUS.NONE, ModelBase.UAB_STATUS.PAST_DUE].includes(user.user_account.status)) {
      return MENU_BILLING;
    }
    return MENU
  }
}
