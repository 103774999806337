import {ModelBase} from './model-base';

export class LoanCalc extends ModelBase {
  public numberMonths: number;
  public annualInterestRate: number;
  public loanAmount: number;
  public offsetMonth: number;
  public monthlyPayment: number;
  public financeCharge: number;
  public apr: number;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }
}
