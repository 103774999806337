<mat-card class="mt-2 mb-1">
  <mat-card-title>Ethnicity & Sex&nbsp;
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" class="pb-2">
      <div [hidden]="no_information_ethnicity.value===true" class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Ethnicity</mat-label>
            <mat-select formControlName="ethnicity_ex" placeholder="Select ethnicity">
              <mat-option *ngFor="let ethnicity of ethnicities_ex" [value]="ethnicity.key">{{ethnicity.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="ethnicity_ex.hasError('required')">
              Ethnicity is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="no_information_ethnicity.value===true || ethnicity_ex.value === 'NotHispanicOrLatino'">
        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="mexico">Mexican</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="puerto_rico">Puerto Rican</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="cuba">Cuba</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-checkbox formControlName="other">Other</mat-checkbox>
          </div>
        </div>

        <div class="row" [hidden]="other.value !== true">
          <div class="col-lg-6">
            <mat-form-field class="md-icon-left w-100">
              <mat-label>Other ethnicity description</mat-label>
              <input formControlName="other_description" matInput placeholder="Describe other ethnicity" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="other_description.hasError('required')">
                Other ethnicity description is required when ethnicity is "Other"
              </mat-error>
              <mat-error *ngIf="other_description.hasError('pattern')">
                Other ethnicity description is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="no_information_ethnicity">I do not wish to provide this information</mat-checkbox>
        </div>
      </div>

      <h4>Sex</h4>

      <div [hidden]="no_information_sex.value===true" class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Sex</mat-label>
            <mat-select formControlName="gender" placeholder="Select sex">
              <mat-option *ngFor="let gender of genders"
                [value]="gender.key">{{gender.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="gender.hasError('required')">
              Sex is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="no_information_sex">I do not wish to provide this information</mat-checkbox>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
