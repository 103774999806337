import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Mcr} from '../models/mcr';
import {CacheService} from './cache.service';

@Injectable()
export class McrService {

  constructor(private httpService: HttpService,
              private cacheService: CacheService) {
  }

  setCurrentLoMcrId(mcrId: number): void {
    this.cacheService.setSeg('mcr', 'currentLoMcrId', mcrId);
  }

  getCurrentLoMcrId(): number {
    return this.cacheService.getSeg('mcr', 'currentLoMcrId');
  }

  setCurrentOrgMcrId(mcrId: number): void {
    return this.cacheService.setSeg('mcr', 'currentOrgMcrId', mcrId);
  }

  getCurrentOrgMcrId(): number {
    return this.cacheService.getSeg('mcr', 'currentOrgMcrId');
  }

  setCurrentTranMcrId(mcrId: number): void {
    this.cacheService.setSeg('mcr', 'currentTranMcrId', mcrId);
  }

  getCurrentTranMcrId(): number {
    return this.cacheService.getSeg('mcr', 'currentTranMcrId');
  }

  getUserMcrs(): Promise<Array<Mcr>> {
    return new Promise((resolve, reject) => {
      this.httpService.get('mcrs/user_mcrs')
        .then((data) => {
          const mcrs = this.sortMcrsReverse(Mcr.deserializeArray(data));
          resolve(mcrs.filter((elt) => {
            return elt.mcr_type === Mcr.TYPE.LO;
          }));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  getOrgMcrs(org_id: number): Promise<Array<Mcr>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`mcrs/org_mcrs/${org_id}`)
        .then((data) => {
          const mcrs = this.sortMcrsReverse(Mcr.deserializeArray(data));
          resolve(mcrs);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  getTranMcrs(orgId: number, year: number, quarter: number): Promise<Array<Mcr>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`mcrs/tran_mcrs/${orgId}`, {year: year, quarter: quarter})
        .then((data) => {
          const mcrs = this.sortMcrsReverse(Mcr.deserializeArray(data));
          resolve(mcrs);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  getMcr(mcrId: number): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.get('mcrs/' + mcrId)
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  getTranMcr(tranId: number): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.get('mcrs/tran_mcr/' + tranId)
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  createMcr(payload): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.post('mcrs', payload, false)
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  updateMcr(mcrId, payload): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`mcrs/${mcrId}`, payload, false)
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  populateMcr(mcrId: number): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`mcrs/${mcrId}/populate`, {})
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  genLOMcrReport(mcrId: number, state: string): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`mcrs/${mcrId}/gen_lo_mcr_report`, {state: state})
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  genOrgMcrReport(mcrId: number, state: string): Promise<Mcr> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`mcrs/${mcrId}/gen_org_mcr_report`, {state: state})
        .then((data) => {
          resolve(new Mcr(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  checkGenOrgXmlMcr(mcrId: number, states: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`mcrs/${mcrId}/gen_org_xml_mcr`, {test: 1, states: states, include_serviced: true})
        .then((obj) => {
          resolve(obj);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  deleteMcr(mcrId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('mcrs/' + mcrId)
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // helper functions
  public sortMcrsReverse(mcrs): Array<Mcr> {
    if (mcrs.length === 0) {
      return mcrs;
    }

    mcrs.sort((x, y) => {
      // compare years
      if (x.year < y.year) {
        return 1;
      }
      if (y.year < x.year) {
        return -1;
      }
      if (x.quarter < y.quarter) {
        return 1;
      }
      if (y.quarter < x.quarter) {
        return -1;
      }
      return 0;
    });
    return mcrs;
  }
}
