import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';
import {Document} from "../../../../../models/document";

@Component({
  selector: 'app-view-credit-report',
  templateUrl: './view-credit-report.component.html',
})
export class ViewCreditReportComponent extends CompBaseComponent implements OnInit {
  @Input() loanAppDoc: Document;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }

    if (event.type === 'Compute') {
      this.mode = 'View';
      return;
    }

    this.eventEmitted.emit(event);
  }
}
