import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Asset} from '../../../../models/asset';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {ModelBase} from '../../../../models/model-base';

@Component({
  selector: 'app-asset',
  template: '',
})
export class AssetComponent extends CompBaseComponent implements OnInit {
  public asset_types = Asset.asset_types;
  public partyIds: Array<any>;
  public newPartyIds: Array<any>;
  public submitted = false;

  // form fields
  public form: FormGroup;
  asset_type_ex: FormControl;
  institution_name: FormControl;
  institution_account_no: FormControl;
  institution_market_value: FormControl;
  institution_asset_description: FormControl;
  party_ids: FormControl;

  // required fields
  @Input() item: Asset;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'la_assets';
    this.section = null;
    this.partyIds = [];
    this.newPartyIds = [];
  }

  setPartyIds(): void {
    this.item.party_ids = this.item.party_ids || '';
    this.partyIds = this.item.party_ids.split(',').map((elt) => {
      return {key: elt, value: ModelBase.partyTable[elt]};
    });
  }

  getPartyIds(): string {
    return this.partyIds.map(elt => elt.value).join(', ');
  }

  setParties(): void {
    this.partyIds.sort((x, y) => x.key.localeCompare(y.key));
    const count = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    for (let i = 1; i <= 4; i++) {
      if (i <= count) {
        if (!this.partyIds.map(elt => elt.key).includes(`b${i}`)) {
          this.newPartyIds.push({key: `b${i}`, value: ModelBase.partyTable[`b${i}`]});
        }
      }
    }
    this.newPartyIds.sort((x, y) => x.key.localeCompare(y.key));
  }

  deletePartyId(partyId): void {
    const index = this.partyIds.findIndex((elt) => elt.key === partyId);
    this.partyIds.splice(index, 1);
    this.newPartyIds = [];
    this.setParties();
  }

  addPartyId(partyId): void {
    const index = this.newPartyIds.findIndex((elt) => elt.key === partyId);
    this.partyIds.push({key: partyId, value: ModelBase.partyTable[partyId]});
    this.newPartyIds = [];
    this.setParties();
  }

  createFormControls(item: Asset): void {
    this.asset_type_ex = new FormControl(item.asset_type_ex, AppValidators.present);
    this.institution_name = new FormControl(item.institution_name);
    this.institution_account_no = new FormControl(item.institution_account_no);
    this.institution_market_value = new FormControl(item.institution_market_value, AppValidators.present);
    this.institution_asset_description = new FormControl(item.institution_asset_description);
    this.party_ids = new FormControl(item.party_ids);
  }

  createForm(): void {
    this.form = new FormGroup({
      asset_type_ex: this.asset_type_ex,
      institution_name: this.institution_name,
      institution_account_no: this.institution_account_no,
      institution_market_value: this.institution_market_value,
      institution_asset_description: this.institution_asset_description,
      party_ids: this.party_ids
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (this.partyIds.length === 0 || !this.form.valid) {
      return;
    }
    this.party_ids.setValue(this.partyIds.map((elt) => elt.key).join(','));

    const updated = new Asset(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    this.submitted = true;
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (this.partyIds.length === 0 || !this.form.valid) {
      return;
    }
    this.party_ids.setValue(this.partyIds.map((elt) => elt.key).join(','));

    const updated = new Asset(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
