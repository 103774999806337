import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {Trace} from '../../../models/trace';

@Component({
  selector: 'app-view-segment-task',
  templateUrl: './view-segment-task.component.html',
})
export class ViewSegmentTaskComponent extends CompBaseComponent implements OnInit {
  @Input() public tran: Tran;
  @Input() public trace: Trace;
  @Input() public title: string;
  @Input() public currentState: string;
  public canUpdateTransaction: boolean;
  public modifiedTitle: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.trace.state === this.currentState) {
      if (this.trace.state === 'end') {
        this.trace.color = 'green';
      } else {
        this.trace.color = 'dark';
      }
    } else if (this.trace.hidden) {
      this.trace.color = 'yellow';
    } else {
      if (this.trace.done === true) {
        this.trace.color = 'green';
      } else {
        this.trace.color = 'red';
      }
    }

    this.modifiedTitle = this.trace.hidden ? this.title + ' <i class="text-warning fas fa-eye-slash"></i>' : this.title;
  }
}
