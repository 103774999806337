<mat-card class="mt-2 mb-1" *ngIf="reloaded">
  <mat-card-title>Borrower Setup</mat-card-title>

  <div *ngIf="+borrower_count.value > 1 && !isGrouping()" class="alert alert-warning alert-dismissible fade show" role="alert">
    <strong>Borrower Grouping missing.</strong>
    Before proceeding with configuring Borrower Grouping, ensure that you save the number of borrowers even if their names are missing.
    For each borrower, fill in the borrower information (Borrower/Co-Borrower tab) and then return to finish configuring Borrower Grouping.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>

  <mat-card-content>
    <form [formGroup]="form" class="pb-2">
      <div class="row">
        <div class="col">
          <mat-radio-group aria-label="borrower_count" formControlName="borrower_count">
            <mat-radio-button [checked]="borrower_count.value==='1'" [value]="'1'">I am applying for an individual credit</mat-radio-button>
            <br>
            <mat-radio-button [checked]="borrower_count.value==='2'" [value]="'2'">I am applying for a joint credit (two borrowers)</mat-radio-button>
            <br>
            <mat-radio-button [checked]="borrower_count.value==='3'" [value]="'3'">I am applying for a three borrowers credit</mat-radio-button>
            <br>
            <mat-radio-button [checked]="borrower_count.value==='4'" [value]="'4'">I am applying for a four borrowers credit</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="(+borrower_count.value > 1) && isGrouping()">
        <app-segment-header (eventEmitted)="onEvent($event)" class="mt-2" [isCard]="false" [enable]="canUpdateDocument" [header]="'Borrower Grouping'" [help]="true" [op]="''">
          Borrower Setup determines:
          <ul [ngClass]="{'is-mobile': isMobile}" class="help">
            <li>The number of borrowers</li>
            <li>The default URLA form(s) used for creating PDFs</li>
          </ul>
          <h5>Grouping</h5>
          When there are two or more borrowers, the borrower grouping determines the pair of individuals who share assets and liabilities.
          The first borrower in each group receives the complete URLA form, which includes assets and liabilities information for both borrowers.
          The other borrower receives the URLA Additional form.<br/>
          <span class="fw-bold">How to group:</span> Drag-and-Drop borrowers from the borrower list into the designated groups.
          <h5>Generating Documents</h5>
          When you create documents (Repository/Generate Documents), the default borrower grouping will be displayed.
          However, you will have the option to override this default grouping.
        </app-segment-header>

        <app-dnd [borrowerObjTable]="borrowerObjTable" [borrowerGroupsIn]="item.borrower_groups" (borrowerGroupsOut)="onBorrowerGroups($event)"></app-dnd>
      </div>

      <div class="row mt-5">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
