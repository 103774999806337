import {Component, Injector, Input, OnInit} from '@angular/core';
import {Agreement} from '../../../../models/agreement';
import {CompBaseComponent} from '../../../comp-base.component';
import moment from 'moment';

@Component({
  selector: 'app-view-segment-agreement',
  templateUrl: './view-segment-agreement.component.html',

})
export class ViewSegmentAgreementComponent extends CompBaseComponent implements OnInit {
  @Input() item: Agreement;
  public signatureInFuture: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.segment = 'agreement';
    this.section = this.applicant;
    this.signatureInFuture = moment(this.item.signature_date).isAfter(moment());
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
