import {Component, Injector, OnInit} from '@angular/core';
import {ViewLoanAppTabComponent} from '../view-loan-app-tab.component';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-view-applicant',
  templateUrl: './view-applicant.component.html',
})
export class ViewApplicantComponent extends ViewLoanAppTabComponent implements OnInit {
  public static currentBorrowerSeg = globals.segment.tab2Instructions;
  public static currentCoBorrowerSeg = globals.segment.tab2Instructions;
  public static currentBorrower3Seg = globals.segment.tab2Instructions;
  public static currentBorrower4Seg = globals.segment.tab2Instructions;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloadTab();
  }

  reloadSegment(): void {
    if (this.applicant === 'borrower_1') {
      this.currentSeg = ViewApplicantComponent.currentBorrowerSeg;
    } else if (this.applicant === 'borrower_2') {
      this.currentSeg = ViewApplicantComponent.currentCoBorrowerSeg;
    } else if (this.applicant === 'borrower_3') {
      this.currentSeg = ViewApplicantComponent.currentBorrower3Seg;
    } else if (this.applicant === 'borrower_4') {
      this.currentSeg = ViewApplicantComponent.currentBorrower4Seg;
    }
  }

  setSeg(event: Event, segment: string): void {
    event.preventDefault();
    if (this.applicant === 'borrower_1') {
      this.currentSeg = ViewApplicantComponent.currentBorrowerSeg = segment;
    } else if (this.applicant === 'borrower_2') {
      this.currentSeg = ViewApplicantComponent.currentCoBorrowerSeg = segment;
    } else if (this.applicant === 'borrower_3') {
      this.currentSeg = ViewApplicantComponent.currentBorrower3Seg = segment;
    } else if (this.applicant === 'borrower_4') {
      this.currentSeg = ViewApplicantComponent.currentBorrower4Seg = segment;
    }
    this.setMenuOpen(false);
    setTimeout(() => {
      globals.scrollTop();
    }, 0);
  }
}
