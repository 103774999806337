<style>
    mat-icon {
        font-size: 75px;
        height: 75px;
        width: 75px;
    }
</style>

<div class="row mt-5 pr-font-large" *ngIf="!sent">
  <div class="col-lg-2"></div>
  <div class="col-lg-4">
    <div class="pr-font-larger text-center mb-2 art-blue" style="font-weight: bold">
      Contact Us
    </div>
    <mat-card [title]="'Contact Us'">
      <mat-card-content>
        <app-box *ngIf="error" [close]="true" [type]="'danger'">
          {{error}}
        </app-box>

        <form (ngSubmit)="submit($event)" [formGroup]="form">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput placeholder="Name *" maxlength="70" appAutoFocus required>
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Name is too short
            </mat-error>
          </mat-form-field>

          <mat-form-field class="md-icon-left w-100">
            <mat-label>Email address</mat-label>
            <input formControlName="email" matInput placeholder="Email address *" maxlength="320" required>
            <mat-error *ngIf="email.hasError('required')">
              Email address is required
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              Email address is invalid
            </mat-error>
          </mat-form-field>

          <mat-form-field class="md-icon-left w-100">
            <mat-label>Phone number</mat-label>
            <input formControlName="phone_number" matInput placeholder="Phone number" maxlength="14">
            <mat-error *ngIf="phone_number.hasError('required')">
              Phone number is required
            </mat-error>
            <mat-error *ngIf="phone_number.hasError('pattern')">
              Phone number is invalid
            </mat-error>
          </mat-form-field>

          <mat-form-field class="md-icon-left w-100">
            <mat-label>Message</mat-label>
            <textarea
              cdkAutosizeMaxRows="10"
              cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="message"
              maxlength="{{MAX_LEN.CONTACT_MESSAGE}}"
              matInput
              placeholder="Message *" required>
            </textarea>
            <mat-error *ngIf="message.hasError('required')">
              Message is required
            </mat-error>
            <mat-error *ngIf="message.hasError('pattern')">
              Message is invalid
            </mat-error>
          </mat-form-field>
          <button (click)="submit($event)" [disabled]="submitted&&!form.valid" class="btn btn-lg art-bg-blue text-white fw-bold" type="submit">Submit</button>
        </form>

      </mat-card-content>
    </mat-card>

  </div>
  <div class="col-lg-4">
    <div class="row h-100">
      <div class="col my-auto">
        <div class="row m-2">
          <div class="col-3 my-auto">
            <mat-icon class="pr-dark-blue">mail_outlined</mat-icon>
          </div>
          <div class="col-9 my-auto">
            Please provide your contact information and the problem you are trying to solve.
          </div>
        </div>
        <div class="row m-2">
          <div class="col-3 my-auto">
            <mat-icon class="pr-dark-blue">smartphone_outlined</mat-icon>
          </div>
          <div class="col-9 my-auto">
            If you'd like to chat, please provide your phone number and the best time to call.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row h-100 mt-4 pr-font-large" *ngIf="sent">
  <div class="col-lg-4 mx-auto">
    <app-card *ngIf="sent" [title]="'Message Received'" [alignTitle]="'center'" [outline]="true" [logo]="true">
      <p>
        We have received your message and will be in touch shortly.
        You can also contact us directly.<br>
      </p>
      <div class="text-center">
        <a class="btn btn-primary" href="/">OK</a>
      </div>
    </app-card>
  </div>
</div>
