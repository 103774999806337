<mat-card class="mt-1 mb-1" *ngIf="reloaded">
  <mat-card-title>{{op==='Add'?'Create Note':'Edit Note'}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Title</mat-label>
            <input formControlName="title" matInput placeholder="Title" appAutoFocus maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="title.hasError('required')">
              Title is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Body</mat-label>
            <textarea cdkAutosizeMaxRows="10" cdkAutosizeMinRows="3"
              cdkTextareaAutosize
              formControlName="body"
              matInput
              placeholder="Body" maxlength="{{MAX_LEN.VL_DESC}}" required>
            </textarea>
            <mat-error *ngIf="body.hasError('required')">
              Body is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button (click)="add()" *ngIf="op==='Add'" [disabled]="!form.valid" class="btn btn-lg btn-primary">Save</button>
          <button (click)="update()" *ngIf="op==='Edit'" [disabled]="!form.valid" class="ms-2 btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
