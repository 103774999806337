<div class="row bg-orange">
  <div class="col-6 d-flex">
    <div class="my-auto" [hidden]="isMobile">
      <img alt="POLOS Logo" src="assets/img/polo.png" style="max-height: 100px;">
    </div>
    <div class="my-auto" style="position: relative; left: 10px; color: black; font-size: 54px; font-weight: bold;">
      POLOS
    </div>
  </div>
  <div class="col-6">
    <div class="row">
      <div class="col-12 text-end fw-bold" style="font-size: 24px;color: white;">
        Cloud
      </div>
      <div class="col-12 text-end fw-bold" style="font-size: 24px;color: white;">
        Security
      </div>
      <div class="col-12 text-end fw-bold" style="font-size: 24px;color: white;">
        Compliance
      </div>
    </div>
  </div>
</div>
