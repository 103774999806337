<nav class="navbar navbar-expand-lg navbar-light pr-font-16" style="border-bottom: 2px solid grey; font-weight: 600">
  <div class="container-fluid">
    <div class="ms-2">
      <a aria-label="Logo Image" (click)="navigateTo($event,'/')" class="navbar-brand" href="/">
        <img alt="Logo" src='assets/img/prudentco-logo-small.png'
          style="height: 70px;width:auto;margin-left:auto;margin-right:auto;">
      </a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse me-5" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
        <li class="nav-item">
          <a (click)="navigateTo($event, '/site/contact')" class="nav-link" aria-current="page" href="#" aria-label="Contact Us">
            <span>Contact</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Mortgage Calculator
          </a>
          <ul class="dropdown-menu  pr-font-16" aria-labelledby="navbarScrollingDropdown">
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/monthly')" aria-label="Calculate Monthly Payment" href="/site/calc/monthly">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Monthly Payment</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/rate')" aria-label="Calculate Monthly Payment" href="/site/calc/rate">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Interest Rate</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/amor')" aria-label="Calculate Amortization Table" href="/site/calc/amor">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>Amortization</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="navigateTo($event,'/site/calc/apr')" aria-label="Calculate APR" href="/site/calc/apr">
                <i class="icon fa fa-calculator"></i>&nbsp;
                <span>APR</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a (click)="navigateTo($event, '/site/about/our-story')" class="nav-link" href="#" aria-label="About Us">
            <span>{{isMobile ? 'About' : 'About Us'}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a aria-label="Sign in" href="{{authServerSignInUrl}}" class="nav-link">
            <span>Sign in</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
