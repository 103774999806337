import {Injectable} from '@angular/core';

@Injectable()
export class CalculatorService {

  constructor() {
  }

  static roundTo = (value, roundToValue, precision) => {
    const decimals = precision || 2;
    return (Math.round(value / roundToValue) * roundToValue).toFixed(decimals);
  };

  // Comment: ensure that input params are all numbers
  static computePresentValue = (offsetMonths, payment, annualInterestRate, numberOfPayments) => {
    const offset = offsetMonths;
    const mir = (annualInterestRate * 0.01) / 12.0; // monthly interest rate
    const p = payment;
    let sum = 0.0;
    for (let i = offset; i < numberOfPayments; i++) {
      sum += (p / Math.pow((1 + mir), (i + 1)));
    }
    return sum;
  };

  // given the amount (borrowed money) and interest rate: calculate the monthly payment
  static computeMonthlyPayment = (amount, offset, annualInterestRate, numberOfPayments, precision?) => {
    let low = 0.0;
    let high = amount;
    const tolerance = precision ? precision / 2 : 0.01; // of a unit of currency (one dollar)

    while (high - low > tolerance) {
      const guessPayment = (high + low) / 2.0;
      const presentValue = CalculatorService.computePresentValue(offset, guessPayment, annualInterestRate, numberOfPayments);
      if (presentValue === amount) {
        return guessPayment;
      } else if (presentValue >= amount) {
        high = guessPayment;
      } else {
        low = guessPayment;
      }
    }
    return high;
  };

  // For a fixed rate loan. Given the amount (borrowed money) and the monthly payment: compute the effective interest rate
  static computeFixedRate = (offsetMonths, amount, numberOfPayments, monthlyPayment, precision?) => {
    let low = 0.0;
    let high = 100.00;
    const tolerance = precision ? (precision / 2) : 0.01; // of a percent

    while (high - low > tolerance) {
      const guessRate = (high + low) / 2.0;
      const presentValue = CalculatorService.computePresentValue(offsetMonths, monthlyPayment, guessRate, numberOfPayments);
      if (presentValue === amount) {
        return guessRate;
      } else if (presentValue >= amount) {
        low = guessRate;
      } else {
        high = guessRate;
      }
    }
    return high;
  };

  static computeFixedAmortizationTable = (offsetMonths, amount, annualInterestRate, numberOfPayments) => {
    const mir = (annualInterestRate * 0.01) / 12;
    let monthlyPayment = CalculatorService.computeMonthlyPayment(amount, offsetMonths, annualInterestRate, numberOfPayments, 0.0001);
    monthlyPayment = Math.round(Math.ceil(monthlyPayment * 100)) / 100;

    const table = new Array(numberOfPayments);
    let accumulatedEquity = 0;
    let balance = amount;
    for (let i = offsetMonths; i < numberOfPayments - offsetMonths; i++) {
      const interest = balance * mir;
      const principal = monthlyPayment - interest;
      const deltaEquity = (monthlyPayment - interest);
      accumulatedEquity += deltaEquity;
      balance = balance - deltaEquity;
      const month = '00' + +(i + 1);
      // comment: next line was changed during porting
      const year = Math.floor(i / 12) + 1;
      const monthYear = (i % 12) + 1;
      const monthYearString = 'Year ' + year + ' Month ' + monthYear;
      const monthNumber = month.substr(month.length - 3);
      table[i] = {
        month: monthNumber,
        monthYearString: monthYearString,
        balance: balance,
        accumulatedEquity: accumulatedEquity,
        interest: interest,
        principal: principal
      };
    }
    return table;
  };

  // compute APR for a fixed rate loan
  static computeFixedRateAPR = (amount, offset, charges, annualInterestRate, numberOfPayments) => {
    const monthlyPaymentAmount = CalculatorService.computeMonthlyPayment(amount, offset, annualInterestRate, numberOfPayments);
    return CalculatorService.computeFixedRate(offset, amount - charges, numberOfPayments, monthlyPaymentAmount);
  };
}
