<mat-card *ngIf="doc" class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header [enable]="false" [header]="'Finalize Loan'" [help]="true">
      <span class="fw-bold">Finalize Loan</span>
      <p>
        Run Finalize Loan when you are done with the Loan Application. The purpose of this function is to prune
        data segments that are irrelevant to the loan. For instance, you may have initially assumed that there is a co-borrower
        and provided data for them. If later on, the loan was to be submitted without a co-borrower, then their data will be removed
        forever after finalizing the loan.
      </p>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <button (click)="finalizeLoan()" class="btn btn-lg btn-primary" type="submit" type="submit">Finalize Loan</button>
    </form>
  </mat-card-content>
</mat-card>
