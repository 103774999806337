import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-polos-logo',
  templateUrl: './polos-logo.component.html',
})
export class POLOSLogoComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
