<mat-card class="mt-2 mb-1">
<mat-card class="mt-2 mb-1">
  <mat-card-title>Buydown Rate</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Buydown rate (percent)</mat-label>
            <input appDecimalThreeOnly appAutoFocus formControlName="buydown_rate" matInput placeholder="Buydown rate (percent)" type="text" maxlength="6">
            <mat-error *ngIf="buydown_rate.hasError('required')">
              Buydown rate (percent) is required
            </mat-error>
            <mat-error *ngIf="buydown_rate.hasError('pattern')">
              Buydown rate (percent) is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
