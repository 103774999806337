<div class="row h-100 mt-5">
  <div class="col-lg-4 mx-auto">
    <mat-card class="mt-1 mb-2">
      <mat-card-title>APR Computed</mat-card-title>
      <mat-card-content>
        <div class="row pt-2 pb-2 border-bottom hover-gray">
          <div class="col fw-bold">Loan amount</div>
          <div class="col">{{loan.loanAmount | currency}}</div>
        </div>

        <div class="row pt-2 pb-2 border-bottom hover-gray">
          <div class="col fw-bold">Interest rate</div>
          <div class="col">{{loan.annualInterestRate | number: '1.3-3'}}%</div>
        </div>

        <div class="row pt-2 pb-2 border-bottom hover-gray">
          <div class="col fw-bold">Number of months</div>
          <div class="col">{{loan.numberMonths}}</div>
        </div>

        <div class="row pt-2 pb-2 border-bottom hover-gray">
          <div class="col fw-bold">Finance charges</div>
          <div class="col">{{loan.financeCharge | currency}}</div>
        </div>

        <div class="row pt-2 pb-2 border-bottom hover-gray">
          <div class="col fw-bold">Computed APR</div>
          <div class="col">{{loan.apr | number: '1.3-3'}}%</div>
        </div>

        <form novalidate>
          <div class="row mt-3">
            <div class="col">
              <button (click)="edit()" class="btn btn-lg btn-primary">Edit</button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
