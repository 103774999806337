import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceService} from '../../services/device.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  public isMobile: boolean;
  public isSite: boolean;
  @HostBinding('class') class = 'w-100';

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.isMobile = DeviceService.isMobile;
    this.isSite = this.router.url.startsWith('/site') || this.router.url.startsWith('/pages');
  }

  get_page_url(section): string {
    return (this.isSite ? '/site/' : '/home/') + section;
  }
}
