<mat-card class="mt-2 mb-1">
  <mat-card-title>Previous Employments</mat-card-title>

  <mat-card-content>
    <form (ngSubmit)="update()" [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Employer name</mat-label>
            <input formControlName="employer_name" matInput placeholder="Employer name" type="text" maxlength="{{MAX_LEN.GEN_NAME}}">
            <mat-error *ngIf="employer_name.hasError('required')">
              Employer name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Address</mat-label>
            <input formControlName="address" matInput placeholder="Address" type="text" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="address.hasError('required')">
              Address is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Apt/Unit/Suite</mat-label>
            <input formControlName="address2" matInput placeholder="Apt/Unit/Suite" type="text" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="address2.hasError('required')">
              Apt/Unit/Suite is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput placeholder="City" type="text" maxlength="{{MAX_LEN.ADDR_PART}}">
            <mat-error *ngIf="city.hasError('required')">
              City is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>State</mat-label>
            <input formControlName="state" appUpperCase matInput appAlphaOnly placeholder="State" type="text" maxlength="{{MAX_LEN.STATE_ABBR}}">
            <mat-error *ngIf="state.hasError('required')">
              State is required
            </mat-error>
            <mat-error *ngIf="state.hasError('pattern')">
              State is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip</mat-label>
            <input appNumberOnly formControlName="zip" matInput placeholder="Zip" type="text" maxlength="{{MAX_LEN.ZIP}}">
            <mat-error *ngIf="zip.hasError('required')">
              Zip is required
            </mat-error>
            <mat-error *ngIf="zip.hasError('pattern')">
              Zip is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Zip four</mat-label>
            <input appNumberOnly formControlName="zip_four" matInput placeholder="Zip four" type="text" maxlength="{{MAX_LEN.ZIP_FOUR}}">
            <mat-error *ngIf="zip_four.hasError('required')">
              Zip four is required
            </mat-error>
            <mat-error *ngIf="zip.hasError('pattern')">
              Zip four is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Country code</mat-label>
            <input formControlName="country" matInput appAlphaOnly appUpperCase placeholder="Country code" type="text" maxlength="{{MAX_LEN.COUNTRY_CODE}}">
            <mat-error *ngIf="country.hasError('required')">
              Country code is required
            </mat-error>
            <mat-error *ngIf="country.hasError('pattern')">
              Country code is invalid
            </mat-error>
            <mat-hint>Country Abbreviation. Enter US for USA.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>From date</mat-label>
            <input [matDatepicker]="$any(datePicker1)" [min]="minDate" [max]="maxDate" formControlName="from_date" matInput placeholder="From date" required>
            <mat-datepicker-toggle [for]="datePicker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker1></mat-datepicker>
            <mat-error *ngIf="from_date.hasError('required')">
              From date is required
            </mat-error>
            <mat-error *ngIf="from_date.hasError('matDatepickerMin')">
              From date is unrealistic
            </mat-error>
            <mat-error *ngIf="from_date.hasError('matDatepickerMax')">
              From date is in the future
            </mat-error>
            <mat-error *ngIf="from_date.hasError('matDatepickerParse')">
              From date format is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>To date</mat-label>
            <input [matDatepicker]="$any(datePicker2)" [min]="minDate" [max]="maxDate" formControlName="to_date" matInput placeholder="To date" required>
            <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker2></mat-datepicker>
            <mat-error *ngIf="to_date.hasError('required')">
              To date is required
            </mat-error>
            <mat-error *ngIf="to_date.hasError('matDatepickerMin')">
              To date is unrealistic
            </mat-error>
            <mat-error *ngIf="to_date.hasError('matDatepickerMax')">
              To date is in the future
            </mat-error>
            <mat-error *ngIf="to_date.hasError('matDatepickerParse')">
              To date format is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Monthly income</mat-label>
            <input appDecimalOnly formControlName="monthly_income" matInput placeholder="Monthly income" type="text" maxlength="{{MAX_LEN.CURR}}">
            <mat-error *ngIf="monthly_income.hasError('required')">
              Monthly income is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Position</mat-label>
            <input formControlName="position" matInput placeholder="Position" type="text" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="position.hasError('required')">
              Position is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Are you the business owner or self-employed?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="self_employed" formControlName="self_employed">
            <mat-radio-button [checked]="self_employed.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="self_employed.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Business phone</mat-label>
            <input appPhoneOnly formControlName="business_phone" matInput placeholder="Business phone" type="text" maxlength="{{MAX_LEN.PHONE}}">
            <mat-error *ngIf="business_phone.hasError('required')">
              Business phone is required
            </mat-error>
            <mat-error *ngIf="business_phone.hasError('pattern')">
              Business phone is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Seasonal income?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Seasonal income indicator" formControlName="seasonal_income_indicator">
            <mat-radio-button [checked]="seasonal_income_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="seasonal_income_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-4 my-auto">
          Foreign income?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="Foreign income indicator" formControlName="foreign_income_indicator">
            <mat-radio-button [checked]="foreign_income_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="foreign_income_indicator.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>
