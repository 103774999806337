import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProductIdentification} from '../../../../models/product-identification';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-product-identification',
  templateUrl: './edit-segment-product-identification.component.html',
})
export class EditSegmentProductIdentificationComponent extends CompBaseComponent implements OnInit {
  public product_plan_numbers = ProductIdentification.product_plan_numbers;
  @Input() public item: ProductIdentification;

  // form and form controls
  form: FormGroup;
  product_description: FormControl;
  product_plan_number: FormControl;
  public isARMLoan: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'product_identification';
    this.section = 'lo';
    this.createFormControls(this.item);
    this.createForm();
    this.reload();
  }

  createFormControls(item): void {
    this.product_description = new FormControl(item.product_description);
    this.product_plan_number = new FormControl(item.product_plan_number);
  }

  createForm(): void {
    this.form = new FormGroup({
      product_description: this.product_description,
      product_plan_number: this.product_plan_number,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new ProductIdentification(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  reload(force?: boolean): void {
    if (this.doc) {
      this.isARMLoan = this.analyzerService.isARMLoan(this.doc.recursive_attributes);
    }
  }
}
