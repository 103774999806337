import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {Mcr} from '../../../../models/mcr';
import {FormUtil} from '../../../form-util';
import {CompBaseComponent} from '../../../comp-base.component';
import {MatDialog} from '@angular/material/dialog';
import {YearQuarterDialogComponent} from '../year-quarter-dialog/year-quarter-dialog.component';
import moment from 'moment';
import {OrganizationService} from '../../../../services/organization.service';
import {Organization} from '../../../../models/organization';

@Component({
  selector: 'app-view-loan-mcrs',
  templateUrl: './view-loan-mcrs.component.html',
})
export class ViewLoanMcrsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  static year: number;
  static quarter: number;
  public readonly quarters = Mcr.quarters;
  public readonly years = Mcr.years;
  public mcrs: Array<Mcr>;
  public year: number;
  public quarter: number;
  public orgService: OrganizationService;
  public pageTitle = 'Loan MCRS';
  @Input() public organization: Organization;
  public orgUsers: Array<any>;
  public form: FormGroup;
  public orgUser: FormControl;
  public name: FormControl;

  constructor(public injector: Injector,
              private router: Router,
              public dialog: MatDialog) {
    super(injector);
    this.orgService = this.injector.get(OrganizationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload(true);
    this.reloadOnSync();
    if (this.isTitle) {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
        });
      }, 0);
    }
  }

  getHeader(): string {
    return `Loan MCRs for Q${this.quarter} ${this.year}`;
  }

  createFormControls(): void {
    this.name = new FormControl();
    this.orgUser = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      name: this.name,
      orgUser: this.orgUser,
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Header') {
      this.openDialog();
      return;
    }
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    if (!ViewLoanMcrsComponent.year || !ViewLoanMcrsComponent.quarter) {
      const year = moment().year();
      const quarter = moment().quarter();
      if (quarter === 1) {
        ViewLoanMcrsComponent.year = year - 1;
        ViewLoanMcrsComponent.quarter = 4;
      } else {
        ViewLoanMcrsComponent.year = year;
        ViewLoanMcrsComponent.quarter = quarter - 1;
      }
    }
    this.year = ViewLoanMcrsComponent.year;
    this.quarter = ViewLoanMcrsComponent.quarter;
    let orgUsers = [];
    if (this.organization.users) {
      orgUsers = this.organization.users.map((elt) => {
        return {key: elt.id, value: `${elt.full_name}, NMLS: ${elt.nmls}`};
      });
    }
    this.orgUsers = orgUsers.sort((x, y) => {
      return (x.value < y.value) ? -1 : ((x.value > y.value) ? 1 : 0);
    });
    this.createFormControls();
    this.createForm();
    this.mode = 'View';
    this.mcrService.getTranMcrs(this.organization.id, this.year, this.quarter)
      .then((mcrs) => {
        this.mcrs = mcrs.sort((x, y) => {
          return (x.getSortValue() < y.getSortValue()) ? -1 : ((x.getSortValue() > y.getSortValue()) ? 1 : 0);
        });
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }

  cancel(): void {
    this.reload();
  }

  add(): void {
    const payload = {
      year: ViewLoanMcrsComponent.year,
      quarter: ViewLoanMcrsComponent.quarter,
      mcr_type: Mcr.TYPE.TRAN,
      organization_id: this.organization.id,
      name: this.name.value,
      user_id: this.orgUser.value
    };
    this.mcrService.createMcr(payload)
      .then((mcr) => {
        this.reload(true);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create an organization MCR', data);
        this.cancel();
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(YearQuarterDialogComponent, {
      width: '450px',
      data: {year: this.year, quarter: this.quarter}
    });

    dialogRef.afterClosed().subscribe(year_quarter => {
      if (year_quarter && Array.isArray(year_quarter)) {
        ViewLoanMcrsComponent.year = year_quarter[0];
        ViewLoanMcrsComponent.quarter = year_quarter[1];
      }
      this.reload();
      // this.animal = result;
    });
  }

  navigateToMcr(mcr: Mcr): void {
    this.mcrService.setCurrentTranMcrId(mcr.id);
    this.router.navigate(['/org/loan-mcr']);
  }
}
