<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on My Loans
  </mat-card-title>
  <mat-card-content>
    <h5>My Loan Officer MCRs</h5>

    <p>
      The Loan Officer MCR displays your input to the company MCR.
      The purpose of this view is for you to examine the validity and completeness of your loan MCRs.
    </p>
  </mat-card-content>
</mat-card>
