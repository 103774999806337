import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from "../comp-base.component";
import {EmailService} from "../../services/email.service";
import {Contact} from "../../models/contact";
import {Tran} from "../../models/tran";
import {User} from "../../models/user";
import {globals} from "../../shared/globals/globals";
import {Help} from "../../models/help";
import {FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-comm',
  template: '',
})
export class CommComponent extends CompBaseComponent implements OnInit {
  public emailService: EmailService;
  public contacts: Array<Contact>;
  public tranContacts: Array<Contact>;
  public tran: Tran;
  public user: User;
  public isContacts: boolean;
  public isTranContacts: boolean;
  public currentSeg: string;
  public pageTitles = {
    received: 'Received Messages',
    sent: 'Sent Messages',
    newUser: 'Invite User',
    newMessage: 'New Message',
    newEmail: 'Orientation Email',
    uploadPDF: 'Request Documents/Images',
  }
  public readonly seg = {
    received: 'received',
    sent: 'sent',
    newMessage: 'newMessage',
    newEmail: 'newEmail',
    newUser: 'newUser',
    uploadPDF: 'uploadPDF'
  };
  // form and form controls
  public form: FormGroup;
  contactArray: FormArray;
  tranContactArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    this.contacts = this.user.contacts;
    this.isContacts = this.contacts && this.contacts.length > 0;
    this.isTranContacts = false;
    this.emailService = this.injector.get(EmailService);
  }

  getContacts(force): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.tranService.getCurrentTran(force)
        .then((tran) => {
          this.tran = tran;
          this.tranContacts = tran ? this.tran.contacts.filter((elt) => elt.id !== this.user.id) : [];
          this.isTranContacts = this.tranContacts.length > 0;
          return resolve(true);
        })
        .catch((data) => {
          return resolve(false);
        });
    })
  }

  setSeg(event: Event, seg: string): void {
    if (event) {
      event.preventDefault();
    }
    const pageTitle = this.pageTitles[seg];
    let helpKey = null;

    if (seg === 'newUser' || seg === 'uploadPDF') {
      helpKey = Help.helpKeys.emails;
    } else {
      helpKey = Help.helpKeys.message;
    }
    if (pageTitle) {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: pageTitle,
          help: helpKey
        });
        globals.scrollTop();
      }, 0);
    }
    this.currentSeg = seg;
    this.setMenuOpen(false);
  }
}
