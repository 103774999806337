import {ModelBase} from './model-base';

export class Expense extends ModelBase {
  public static expense_indicators_ex = [
    {key: 'Present', value: 'Present Housing Expense'},
    {key: 'Proposed', value: 'Proposed Housing Expense'},
  ];
  public static expense_codes_ex = [
    {key: 'Cable', value: 'Cable'},
    {key: 'Electricity', value: 'Electricity'},
    {key: 'EscrowShortage', value: 'Escrow Shortage'},
    {key: 'FirstMortgagePITI', value: 'First Mortgage PITI'},
    {key: 'FirstMortgagePrincipalAndInterest', value: 'First Mortgage Principal and Interest'},
    {key: 'FloodInsurance', value: 'Flood Insurance'},
    {key: 'GroundRent', value: 'Ground Rent (Rent)'},
    {key: 'Heating', value: 'Heating'},
    {key: 'HomeownersAssociationDuesAndCondominiumFees', value: 'Homeowners Association Dues and Condominium Fees'},
    {key: 'HomeownersInsurance', value: 'Homeowners Insurance'},
    {key: 'LeaseholdPayments', value: 'Leasehold Payments'},
    {key: 'MaintenanceAndMiscellaneous', value: 'Maintenance and Miscellaneous'},
    {key: 'MIPremium', value: 'MI Premium'},
    {key: 'Other', value: 'Other'},
    {key: 'OtherMortgageLoanPrincipalAndInterest', value: 'Other Mortgage Loan Principal and Interest'},
    {key: 'OtherMortgageLoanPrincipalInterestTaxesAndInsurance', value: 'Other Mortgage Loan Principal Interest Taxes and Insurance'},
    {key: 'RealEstateTax', value: 'Real Estate Tax'},
    {key: 'Rent', value: 'Rent'},
    {key: 'SupplementalPropertyInsurance', value: 'Supplemental Property Insurance'},
    {key: 'Telephone', value: 'Telephone'},
    {key: 'Utilities', value: 'Utilities'},
  ];
  index: number;
  amount: string;
  expense_indicator_ex: string;
  code_ex: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'amount') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Expense> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Expense(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getExpenseType(): string {
    const found = Expense.expense_codes_ex.find((elt) => elt.key === this.code_ex);
    return found ? found['value'] : '';
  }

  getExpenseIndicator(): string {
    const found = Expense.expense_indicators_ex.find((elt) => elt.key === this.expense_indicator_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      expense_indicator_ex: this.expense_indicator_ex,
      code_ex: this.code_ex,
      amount: this.toServerDecimal(this.amount)
    };
  }
}
