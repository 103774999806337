import {Component, OnInit} from '@angular/core';
import {TimeoutService} from './services/timeout.service';
import {PingService} from './services/ping.service';
import {ConfigurationService} from './services/configuration.service';
import {DeviceService} from './services/device.service';
import {SharedService} from './layouts/shared-service';
import {globals} from "./shared/globals/globals";

@Component({
  // moduleId: module.id,
  // tslint:disable-next-line
  selector: 'app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public reloaded: boolean;
  public title = 'POLOS';
  public error = false;
  public errorData: any;
  //
  // Comment: do not use AppInjector in this component as it will create a circular dependency
  //
  constructor(private sharedService: SharedService,
              private timeoutService: TimeoutService,
              private configurationService: ConfigurationService,
              private pingService: PingService) {
  }

  ngOnInit() {
    this.reloaded = false;
    DeviceService.init();
    this.configurationService.loadApp();
    this.timeoutService.init();
    // this.pingService.init();
    this.sharedService.eventEmitted.subscribe(
      event => {
        if (event.type === 'Login') {
          this.timeoutService.startIdleWatch();
        }
        if (event.type === 'Logout') {
          this.timeoutService.stopIdleWatch();
        }
      });

    setTimeout(() => {
      this.error = false;
      this.reloaded = true;
      return Promise.resolve(true);
    }, 0)
  }
}
