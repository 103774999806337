import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {UserAccount} from "../../../../models/user-account";
import {Order} from "../../../../models/order";
import {OrderService} from "../../../../services/order.service";
import {OrderComponent} from "../order/order.component";
import {Router} from "@angular/router";
import {ModelBase} from "../../../../models/model-base";

@Component({
  selector: 'app-view-subscriptions',
  templateUrl: './view-orders.component.html',
})
export class ViewOrdersComponent extends OrderComponent implements OnInit, OnDestroy {
  public pageTitle = 'Subscriptions';
  public orders: Array<Order>;
  public userAccount: UserAccount;

  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mode = 'View';
    this.orderService = this.injector.get(OrderService);
    this.reload();
    this.reloadOnSync();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountSubscriptions
      });
    }, 0);
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(true);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.user = this.userService.getUserSync();
    this.userAccount = this.user.user_account;
    // UAB_STATUS can be in ERROR for several reasons:
    // 1. The account has just been created and the user needs to pay for the service
    // 2. For existing user, the credit card payment failed.
    if (this.userAccount.status == ModelBase.UAB_STATUS.ERROR) {
      // Difference in days since the account was created
      const diffInDays = (new Date().getTime() - this.user.created_at.getTime()) / (1000 * 3600 * 24);
      // It the account was created recently (if not in the last few minutes),
      if (diffInDays <= 1.0) {
        this.mode = 'Modify';
      } else {
        this.mode = 'Add';
      }
    } else if (this.userAccount.status == ModelBase.UAB_STATUS.PAST_DUE) {
      this.mode = 'Past-Due'
    }

    this.orderService.getAccountOrders(this.user.id)
      .then((orders) => {
        this.orders = orders;
        this.reloaded = true;
      });
  }
}
