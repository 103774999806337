// TODO: need to remove unused properties
import {ModelBase} from './model-base';

export class Invoice extends ModelBase {
  public static status_table = [
    {key: ModelBase.UAB_STATUS.OK, value: 'OK'},
    {key: ModelBase.UAB_STATUS.PAST_DUE, value: 'Past Due'},
    {key: ModelBase.UAB_STATUS.OUTSTANDING, value: 'Outstanding'},
    {key: ModelBase.UAB_STATUS.PAID, value: 'Paid'},
    {key: ModelBase.UAB_STATUS.ERROR, value: 'Error'},
    {key: ModelBase.UAB_STATUS.MARKED_PAID, value: 'Marked Paid'},
  ];

  public static month_table = [
    {key: '01', value: '01'},
    {key: '02', value: '02'},
    {key: '03', value: '03'},
    {key: '04', value: '04'},
    {key: '05', value: '05'},
    {key: '06', value: '06'},
    {key: '07', value: '07'},
    {key: '08', value: '08'},
    {key: '09', value: '09'},
    {key: '10', value: '10'},
    {key: '11', value: '11'},
    {key: '12', value: '12'},
  ];

  public static year_table = [
    {key: '21', value: '21'},
    {key: '22', value: '22'},
    {key: '23', value: '23'},
    {key: '24', value: '24'},
    {key: '25', value: '25'},
    {key: '26', value: '26'},
    {key: '27', value: '27'},
    {key: '28', value: '28'},
    {key: '29', value: '29'},
    {key: '30', value: '30'},
    {key: '31', value: '31'},
    {key: '32', value: '32'},
    {key: '33', value: '33'},
  ];

  public id: number;
  public order_id: number;
  public status: number;
  public amount: number;
  public discount: number;
  public created_at: Date;
  public updated_at: Date;
  public deleted_at: Date;
  // generated properties (by Rails serializer)
  public balance: number;
  public full_name: string;

  constructor(object: any) {
    super();
    if (!object) {
      return this;
    }
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at' || prop === 'deleted_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Invoice> {
    const result = [];
    for (const obj of arr) {
      const item = new Invoice(obj);
      result.push(item);
    }

    return result;
  }

  public getStatus(): string {
    return (ModelBase.getUABStatus(this.status) || 'N/A');
  }

  public isPaid(): boolean {
    return this.status === ModelBase.UAB_STATUS.PAID || this.status === ModelBase.UAB_STATUS.MARKED_PAID;
  }
}
