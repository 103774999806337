import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {Mcr} from '../../../../models/mcr';
import {FormUtil} from '../../../form-util';
import {OrgMcrComponent} from '../org-mcr/org-mcr.component';
import {User} from '../../../../models/user';
import {AgentService} from '../../../../services/agent.service';
import {OrganizationService} from '../../../../services/organization.service';

@Component({
  selector: 'app-view-org-mcrs',
  templateUrl: './view-org-mcrs.component.html',
})
export class ViewOrgMcrsComponent extends OrgMcrComponent implements OnInit, OnDestroy {
  public user: User;
  public agentService: AgentService;
  public orgService: OrganizationService;
  public mcrs: Array<Mcr>;
  public pageTitle = 'Organization MCRs';
  public form: FormGroup;
  public latestQuarter: FormControl;
  public latestYear: FormControl;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.agentService = this.injector.get(AgentService);
    this.orgService = this.injector.get(OrganizationService);
    if (this.isMobile) {
      this.pageTitle = 'Org MCRs';
    }
    if (this.isTitle) {
      setTimeout(() => {
        this.sharedService.emitChange({
          type: 'ChangePage',
          title: this.pageTitle,
        });
      }, 0);
    }
    this.user = this.userService.getUserSync();
    this.reload(true);
    this.reloadOnSync();
  }

  createFormControls(): void {
    this.latestYear = new FormControl();
    this.latestQuarter = new FormControl();
  }

  createForm(): void {
    this.form = new FormGroup({
      latestYear: this.latestYear,
      latestQuarter: this.latestQuarter,
    });
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.createFormControls();
    this.createForm();
    this.reloaded = false;
    this.mode = 'View';
    const service = this.user.isAgent() ? this.orgService : this.userService;
    service.getDefaultOrganization()
      .then((org) => {
        this.sharedService.eventEmitted.emit({type: 'ChangeOrg'});
        return this.mcrService.getOrgMcrs(org.id);
      }).then((mcrs) => {
      this.mcrs = mcrs;
      this.reloaded = true;
    }).catch(() => {
      this.mcrs = [];
      this.reloaded = true;
    });
  }

  cancel(): void {
    this.reload();
  }

  add(): void {
    const payload = {
      year: this.latestYear.value,
      quarter: this.latestQuarter.value,
      mcr_type: Mcr.TYPE.ORG,
      organization_id: this.user.organization_id
    };
    this.mcrService.createMcr(payload)
      .then((mcr) => {
        this.reload(true);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create an organization MCR', data);
        this.cancel();
      });
  }

  navigateToMcr(mcr: Mcr): void {
    this.mcrService.setCurrentOrgMcrId(mcr.id);
    this.router.navigate(['/org/org-mcr']);
  }
}
