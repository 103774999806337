<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Transaction Details'" [help]="true" [op]="'edit'">
      <ul>
        <li>
          <span class="fw-bold">Purchase price.</span>
          The amount of money that the property will be purchased for. This field is applicable to purchase loans.
        </li>

        <li>
          <span class="fw-bold">Repairs.</span>
          The cost of any alterations, improvements, and repairs on the property.
        </li>

        <li>
          <span class="fw-bold">Subject property payoff.</span>
          Balance of mortgage loans on the property to be paid off in the transaction.
          Required only if the loan purpose is refinance.
        </li>

        <li>
          <span class="fw-bold">Estimated prepaid items.</span>
          <mark>Conventional:</mark>
          Enter the total amount of pre-paid expenses charged.
          If any of this is to be paid by the seller, include that amount in this segment.
          If any of this is to be paid by the lender, include that amount in Credits segment.
          <mark>FHA/VA:</mark>
          This is the amount paid by the borrower.
          This amount must be positive.
        </li>

        <li>
          <span class="fw-bold">MI (Mortgage Insurance) and Funding Fee.</span>
          A fee paid to initiate mortgage insurance. For example, PMI or MIP.
        </li>

        <li>
          <span class="fw-bold">Discount.</span>
          Enter the total amount of discount points charged.
          <mark>Conventional:</mark>
          Fannie Mae does not distinguish between discount and origination points.
        </li>

        <li>
          <span class="fw-bold">Subordinate financing.</span>
          The total amount of financing by a type of debt inferior to this loan the applicant is applying for on the same property.
        </li>

        <li>
          <span class="fw-bold">PMI, MIP, Funding Fee financed.</span>
          The amount of the fee paid to initiate mortgage insurance that will be financed in the mortgage loan transaction.
        </li>

        <li>
          <span class="fw-bold">Total seller credits</span>
          <mark>Conventional:</mark>
          Include the amount of closing costs, pre-paid items, discount points and up-front PMI paid by the Seller.
          <mark>Government Loans:</mark>
          Enter the amount of Closing Costs paid by the Seller.
          Do not include seller paid prepaid items.
          For FHA loans, seller credit can be populated in this field.
        </li>

      </ul>
      The following fields, which are supplied by either the loan officer or lender, are required before final loan application submission:
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>Estimated prepaid items</li>
        <li>Estimated closing costs</li>
        <li>Discount dollar amount</li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div [hidden]="!isPurchaseLoan" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Purchase price</div>
      <div class="col-lg-6">{{item.purchase_price | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Repairs</div>
      <div class="col-lg-6">{{item.repairs | currency}}</div>
    </div>

    <div *ngIf="isRefinanceLoan">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Subject property payoff</div>
        <div class="col-lg-6">{{item.refinance | currency}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Non-property debts payoff</div>
        <div class="col-lg-6">{{item.non_property_debt_payoff | currency}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Excessive financing concessions</div>
        <div class="col-lg-6">{{item.excessive_financing | currency}}</div>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.from_borrower===false">
      <div class="col-lg-6 fw-bold">Cash to borrower</div>
      <div class="col-lg-6">{{item.cash_from_to_borrower | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.from_borrower===true">
      <div class="col-lg-6 fw-bold">Cash from borrower</div>
      <div class="col-lg-6">{{item.cash_from_to_borrower | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.from_borrower===null">
      <div class="col-lg-6 fw-bold">Cash from/to borrower</div>
      <div class="col-lg-6">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Estimated prepaid items</div>
      <div class="col-lg-6">{{item.prepaid_items | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Estimated closing costs</div>
      <div class="col-lg-6">{{item.closing_costs | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">MI and Funding Fee</div>
      <div class="col-lg-6">{{item.funding_fee | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Discount dollar amount</div>
      <div class="col-lg-6">{{item.discount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Subordinate financing</div>
      <div class="col-lg-6">{{item.subordinate_financing | currency}}</div>
    </div>

    <div *ngIf="isPurchaseLoan" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Total seller credits amount</div>
      <div class="col-lg-6">{{item.closing_costs_seller | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">MI and Funding Fee financed</div>
      <div class="col-lg-6">{{item.funding_financed | currency}}</div>
    </div>
  </mat-card-content>
</mat-card>
