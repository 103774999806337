import {Component, Injector, OnInit} from '@angular/core';
import {PermissionComponent} from '../permission/permission.component';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-view-segment-permission',
  templateUrl: './view-segment-permission.component.html',
})
export class ViewSegmentPermissionComponent extends PermissionComponent implements OnInit {
  public isDefaultLoanApp: boolean;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
    const contact = this.user.getContactById(this.item.acl_user_id);
    this.item.thumbnail = contact ? contact.getThumbnailPath() : globals.getMissingThumbnailUrl();
    this.isDefaultLoanApp = this.resource_type === 'document' && this.resource.dtype === 64 && this.resource.category === 0;
  }
}
