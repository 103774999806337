import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {DeviceService} from '../../services/device.service';
import {Event, NavigationStart, Router} from '@angular/router';
import {TranService} from '../../services/tran.service';
import {UserService} from '../../services/user.service';
import {MessageService} from '../../services/message.service';
import {NoteService} from '../../services/note.service';
import {EventService} from '../../services/event.service';
import {CacheService} from '../../services/cache.service';
import {User} from '../../models/user';

@Component({
  selector: 'app-horizontal-navbar',
  templateUrl: 'horizontal-navbar.component.html',
  styleUrls: ['horizontal-navbar.component.scss'],
})
export class HorizontalNavbarComponent implements OnInit {
  @HostBinding('class.app-navbar') appNavbarClass = 'true';
  @ViewChild('itemOverlay', {static: true}) itemsOverlay: ElementRef;
  @Input() title: string;
  @Input() subtitle: string;
  verticalNavOpen: boolean;
  isMobile: boolean;
  displaySync: boolean;
  lastUrl: string;
  reloadToolTip: string;
  reloaded = false;
  user: User;
  subscription: any;

  constructor(private tranService: TranService,
              private userService: UserService,
              private sharedService: SharedService,
              private messageService: MessageService,
              private noteService: NoteService,
              private eventService: EventService,
              private cacheService: CacheService,
              private router: Router) {
  }

  ngOnInit(): Promise<boolean> {
    this.isMobile = DeviceService.isMobile;
    this.resetVerticalNavBar();
    this.router.events
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          return this.setUpNavBar(event.url);
        }
      });
    this.reloadOnSync();
    return this.setUpNavBar(this.router.url);
  }

  reloadOnSync(): void {
    this.subscription = this.eventService.eventEmitter.subscribe((event) => {
      if (event.type === 'Reload') {
        if (event.component && (event.component == 'account' || event.component == 'tran')) {
          this.setUpNavBar(this.router.url);
        }
      }
    });
  }

  setUpNavBar(url: string): Promise<boolean> {
    this.reloaded = false;
    return new Promise((resolve, reject) => {
      this.userService.getUserFromSession()
        .then((user) => {
          this.user = this.userService.getUserSync();
          this.lastUrl = url;
          this.displaySync = !url.startsWith('/loans/new-loan') && !url.startsWith('/account/reset-password');
          if (url.startsWith('/home/') || url.startsWith('/account/')) {
            this.reloadToolTip = 'Reload account';
          } else if (url.startsWith('/loans/list')) {
            this.reloadToolTip = 'Reload loan list';
          } else if (url.startsWith('/loans/loan-officer-dashboard')) {
            this.reloadToolTip = 'Reload dashboard';
          } else if (url.startsWith('/loan/')) {
            this.reloadToolTip = 'Reload loan';
          } else {
            this.reloadToolTip = 'Reload';
          }
          this.reloaded = true;
          resolve(true);
        });
    });
  }

  toggleHelp(event): void {
    event.preventDefault();
    this.sharedService.eventEmitted.emit({type: 'ToggleHelp'});
  }

  refreshLoan(event): void {
    event.preventDefault();
    if (this.lastUrl.startsWith('/loans/loan-officer-dashboard')) {
      this.cacheService.resetSeg('dashboard');
      this.eventService.eventEmitter.emit({type: 'Reload', component: 'dashboard'});
      return;
    }

    if (this.lastUrl.startsWith('/loans/') || this.lastUrl.startsWith('/sandbox/list')) {
      this.cacheService.resetSeg('tran', 'trans');
      this.tranService.getTrans(true)
        .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'trans'});
        })
        .catch(() => {
          console.log('Failed to refresh loans!');
        });
      return;
    }

    if (this.lastUrl.startsWith('/loan/view-messages')) {
      this.cacheService.resetSeg('message');
      this.messageService.getMessages(true)
        .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'messages'});
        })
        .catch(() => {
          console.log('Failed to refresh messages!');
        });
      return;
    }

    if (this.lastUrl.startsWith('/loan/view-notes')) {
      this.cacheService.resetSeg('note');
      this.noteService.getPosts(true, this.tranService.getCurrentTranSync().id)
        .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'notes'});
        })
        .catch(() => {
          console.log('Failed to refresh posts!');
        });
      return;
    }

    if (this.lastUrl.startsWith('/loan/')) {
      this.cacheService.resetSeg('tran', 'tran');
      this.tranService.getCurrentTran(true)
        .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        })
        .catch(() => {
          console.log('Failed to refresh loan!');
        });
      return;
    }

    if (this.lastUrl.startsWith('/home/') || this.lastUrl.startsWith('/account/')) {
      this.userService.refreshUser()
        .then(() => {
          return this.setUpNavBar(this.router.url);
        })
        .then(() => {
          this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
        })
        .catch(() => {
          console.log('Failed to refresh account!');
        });
      return;
    }
    if (this.lastUrl.startsWith('/org/')) {
      this.eventService.eventEmitter.emit({type: 'Reload', component: 'account'});
      return;
    }
  }

  navigateTo(event, path): void {
    event.preventDefault();
    this.router.navigate([path]);
  }

  close(event) {
    event.preventDefault();
  }

  resetVerticalNavBar(): void {
    this.verticalNavOpen = !DeviceService.isMobile;
  }

  ToggleVerticalNavBar(): void {
    this.verticalNavOpen = !this.verticalNavOpen;
    this.sharedService.emitChange({
      type: 'ToggleVerticalNavBar',
    });
  }

  getSubtitle(): string {
    if (this.lastUrl.startsWith('/loan/') && this.subtitle) {
      return this.subtitle;
    }
    return '';
  }
}
