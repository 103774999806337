import {ModelBase} from './model-base';

export class ProductCharacteristics extends ModelBase {
  public static negative_amortization_types = [
    {key: 'PotentialNegativeAmortization', value: 'Potential Negative Amortization'},
    {key: 'ScheduledNegativeAmortization', value: 'Scheduled Negative Amortization'}
  ];

  prepayment_penalty_indicator: boolean;
  interest_only: boolean;
  negative_amortization_indicator: boolean;
  interest_only_months_no: string;
  negative_amortization_type: string;
  prepayment_months_no: string;
  fixed_period_months_no: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'prepayment_penalty_indicator' ||
        prop === 'negative_amortization_indicator' ||
        prop === 'interest_only') {
        this[prop] = super.getBooleanProp(prop, object);
      } else if (prop === 'interest_only_months_no'
        || prop === 'prepayment_months_no'
        || prop === 'fixed_period_months_no'
      ) {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getNegativeAmortizationType(): string {
    const found = ProductCharacteristics.negative_amortization_types.find((elt) => elt.key === this.negative_amortization_type);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      prepayment_penalty_indicator: ModelBase.toServerExplicitBoolean(this.prepayment_penalty_indicator),
      negative_amortization_indicator: ModelBase.toServerExplicitBoolean(this.negative_amortization_indicator),
      interest_only: ModelBase.toServerExplicitBoolean(this.interest_only),
      interest_only_months_no: this.toServerString(this.interest_only_months_no),
      negative_amortization_type: this.negative_amortization_type,
      prepayment_months_no: this.toServerString(this.prepayment_months_no),
      fixed_period_months_no: this.toServerString(this.fixed_period_months_no),
    };
  }
}
