import {Component, Injector, OnInit} from '@angular/core';
import {SharedService} from '../shared-service';
import {TranService} from '../../services/tran.service';
import {Router} from '@angular/router';
import {DeviceService} from '../../services/device.service';
import {OrganizationService} from '../../services/organization.service';

@Component({
  // moduleId: module.id,
  // tslint:disable-next-line
  selector: 'default-layout',
  templateUrl: 'default.component.html',
  styleUrls: ['../layouts.scss'],
  providers: [SharedService]
})
export class DefaultLayoutComponent implements OnInit {
  sharedService: SharedService;
  tranService: TranService;
  orgService: OrganizationService;
  router: Router;
  messages = [];
  pageTitle: any;
  pageSubtitle: string;
  verticalNavBarOpen: boolean;
  isMobile: boolean;
  reloaded: boolean;

  constructor(private injector: Injector) {
    this.verticalNavBarOpen = false;
    this.router = this.injector.get(Router);
    this.sharedService = this.injector.get(SharedService);
    this.tranService = this.injector.get(TranService);
    this.orgService = this.injector.get(OrganizationService);
    this.isMobile = DeviceService.isMobile;

    this.sharedService.eventEmitted.subscribe(
      event => {
        switch (event.type) {
          case 'ChangeOrg':
            this.setNavBarOrg();
            break;
          case 'ChangePage':
            this.setNavBarText(event.title);
            break;
          case 'ToggleVerticalNavBar':
            this.toggleHorizontalMenu();
            break;
          default:
            break;
        }
      }
    );
  }

  ngOnInit() {
    this.resetVerticalNavBar();
    this.setNavBarText(this.pageTitle);
  }

  resetVerticalNavBar(): void {
    if (DeviceService.isMobile) {
      this.verticalNavBarOpen = false;
      return;
    }
    this.verticalNavBarOpen = true;
  }

  toggleHorizontalMenu(): void {
    this.verticalNavBarOpen = !this.verticalNavBarOpen;
  }

  setNavBarOrg(): void {
    const url = this.router.url;
    if (url.startsWith('/agent')) {
      this.orgService.getDefaultOrganization()
        .then((org) => {
          if (org) {
            this.pageSubtitle = `Org (${org.id}): ${org.name}`;
          }
        });
    }
  }

  setNavBarText(title: string): void {
    this.reloaded = false;
    this.pageTitle = title;
    const url = this.router.url;
    if (!url.startsWith('/agent')) {
      const currentTran = this.tranService.getCurrentTranSync();
      if (currentTran) {
        this.pageSubtitle = `Current Loan (${currentTran.id}): ${currentTran.name}`;
      }
    }
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  closeOverlay(): void {
    this.verticalNavBarOpen = false;
  }
}
