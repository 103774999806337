import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable()
export class CacheService {
  private cache: object;

  constructor(private httpService: HttpService) {
    this.voidCache();
  }

  setSeg(seg, key, val): void {
    if (!this.cache[seg]) {
      this.cache[seg] = {};
    }
    this.cache[seg][key] = val;
  }

  getSeg(seg, key?): any {
    if (key) {
      return this.cache[seg] && this.cache[seg][key] ? this.cache[seg][key] : null;
    }
    return this.cache[seg];
  }

  resetSeg(seg, key?): void {
    if (key) {
      if (this.cache[seg]) {
        this.cache[seg][key] = null;
      }
      return;
    }
    this.cache[seg] = null;
  }

  voidCache(): void {
    this.cache = {
      compliance: {},
      condition: {},
      cfa: {},
      dashboard: {},
      invoice: {},
      mcr: {},
      message: {},
      post: {},
      sysAlert: {},
      tran: {},
      user: {},
    };
  }
}
