<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Purpose</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row mt-1 mb-3 hover-gray">
        <div class="col-lg-6 my-auto">
          <div id="construction_loan_indicator">
            Is this a a construction loan?
          </div>
        </div>

        <div class="col-lg-6 text-start">
          <mat-radio-group aria-label="construction_loan_indicator_label" formControlName="construction_loan_indicator" required>
            <mat-radio-button [checked]="construction_loan_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="construction_loan_indicator.value===false" [value]="false">No</mat-radio-button>
            <mat-error *ngIf="submitted && construction_loan_indicator.hasError('required')">
              Construction loan response is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Loan purpose</mat-label>
            <mat-select formControlName="loan_purpose_code_ex" placeholder="Select loan purpose" required>
              <mat-option *ngFor="let loan_purpose_code of loan_purpose_codes_ex" [value]="loan_purpose_code.key">{{loan_purpose_code.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="loan_purpose_code_ex.hasError('required')">
              Loan purpose is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3" [hidden]="loan_purpose_code_ex.value !== 'Other'">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>Loan purpose explain</mat-label>
            <input type="text" formControlName="loan_purpose_explain" matInput placeholder="Explain loan purpose" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="loan_purpose_explain.hasError('required')">
              Explain loan purpose is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" [hidden]="true">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Current property usage</mat-label>
            <mat-select formControlName="property_current_residence_type_ex" placeholder="Select current property usage">
              <mat-option *ngFor="let property_current_residence_type_ex of property_residence_types_ex" [value]="property_current_residence_type_ex.key">{{property_current_residence_type_ex.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="property_current_residence_type_ex.hasError('required')">
              Current property usage is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Property usage</mat-label>
            <mat-select formControlName="property_residence_type_ex" placeholder="Select property usage" required>
              <mat-option *ngFor="let property_residence_type_ex of property_residence_types_ex" [value]="property_residence_type_ex.key">{{property_residence_type_ex.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="property_residence_type_ex.hasError('required')">
              Property usage is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Ownership type</mat-label>
            <mat-select formControlName="ownership_type_ex" placeholder="Select ownership type" required>
              <mat-option *ngFor="let ownership_type of ownership_types_ex" [value]="ownership_type.key">{{ownership_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ownership_type_ex.hasError('required')">
              Ownership type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="ownership_type_ex.value==='Leasehold'" class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Leasehold expiration date</mat-label>
            <input [matDatepicker]="$any(datePicker)" [min]="minDate" [required]="ownership_type_ex.value==='Leasehold'" formControlName="leasehold_expiration_date" matInput placeholder="Leasehold expiration date">
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="leasehold_expiration_date.hasError('matDatepickerMin') || leasehold_expiration_date.hasError('matDatepickerMax')">
              Leasehold expiration date is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3 hover-gray">
        <div class="col-lg-6 my-auto">
          FHA: Will you occupy the property as a secondary residence?
        </div>
        <div class="col-lg-6">
          <mat-radio-group aria-label="fha_secondary_residence" formControlName="fha_secondary_residence">
            <mat-radio-button [checked]="fha_secondary_residence.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="fha_secondary_residence.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mb-3 hover-gray">
        <div class="col-lg-6 my-auto">
          <div id="mixed_use">
            Mixed-Use property. Will you set aside space within the property to operate your own business? *
          </div>
        </div>

        <div class="col-lg-6 text-start">
          <mat-radio-group aria-label="mixed_use_label" formControlName="mixed_use">
            <mat-radio-button [checked]="mixed_use.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="mixed_use.value===false" [value]="false">No</mat-radio-button>
            <mat-error *ngIf="submitted && mixed_use.hasError('required')">
              Mixed-use response is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>

      <div class="row hover-gray mb-3">
        <div class="col-lg-6 my-auto">
          Is the property subject to a clean energy lien (e.g., PACE)?
        </div>
        <div class="col-lg-6">
          <mat-radio-group aria-label="existing_energy_lien" formControlName="existing_energy_lien">
            <mat-radio-button [checked]="existing_energy_lien.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="existing_energy_lien.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="loan_purpose_code_ex.value==='Purchase'">
        <h5>Expected Gross and Net Rental Income</h5>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Gross monthly rental income</mat-label>
              <input appDecimalOnly formControlName="expected_rental" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Gross monthly rental income" type="text">
              <mat-error *ngIf="expected_rental.hasError('required')">
                Gross monthly rental is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Net monthly rental income</mat-label>
              <input appDecimalOnly formControlName="expected_net_rental" matInput maxlength="{{MAX_LEN.CURR}}" placeholder="Net monthly rental income" type="text">
              <mat-error *ngIf="expected_net_rental.hasError('required')">
                Expected net monthly rental is required
              </mat-error>
              <mat-hint>Lender should calculate</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row pr-mb-6">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Number of properties</mat-label>
            <input type="text" appNumberOnly formControlName="properties_count" matInput placeholder="Number of properties" maxlength="1">
            <mat-error *ngIf="properties_count.hasError('required')">
              Number of properties is required
            </mat-error>
            <mat-error *ngIf="properties_count.hasError('pattern')">
              Number must be between 1 &ndash; 4
            </mat-error>
            <mat-hint>The number of 1 &ndash; 4 unit properties that are financed and owned and/or obligated on by the borrower(s).
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
