import {Component, Injector, OnInit} from '@angular/core';
import {ErrorCheckComponent} from '../error-check/error-check.component';

@Component({
  selector: 'app-view-error-check',
  templateUrl: './view-error-check.component.html',
})
export class ViewErrorCheckComponent extends ErrorCheckComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.mode = 'View';
  }
}
