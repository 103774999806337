<mat-card class="w-100 mb-2">
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Event code</div>
        <div class="col-lg-6">{{item.code}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Description</div>
        <div class="col-lg-6">{{item.description}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Due after</div>
        <div class="col-lg-6">{{item.due_after}}</div>
      </div>

      <div *ngIf="item.code !== 'lock'" class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Due by</div>
        <div class="col-lg-6">{{item.due_by}}</div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-4">
          <mat-form-field *ngIf="item.code !== 'lock'" class="w-100">
            <mat-label>Occurred on date</mat-label>
            <input [matDatepicker]="$any(datePicker)" formControlName="occurred_on" matInput placeholder="Occurred on date">
            <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="occurred_on.hasError('matDatepickerMin') || occurred_on.hasError('matDatepickerMax')">
              Occurred on date is invalid
            </mat-error>
            <mat-error *ngIf="occurred_on.hasError('pattern')">
              Occurred on date is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-4">
          <mat-form-field *ngIf="item.code === 'lock'" class="w-100">
            <mat-label>Expires on date</mat-label>
            <input [matDatepicker]="$any(datePicker2)" formControlName="expires_on" matInput placeholder="Expires on date">
            <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker2></mat-datepicker>
            <mat-error *ngIf="expires_on.hasError('matDatepickerMin') || expires_on.hasError('matDatepickerMax')">
              Expires on date is invalid
            </mat-error>
            <mat-error *ngIf="expires_on.hasError('pattern')">
              Expires on date is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="clear()" class="ms-2 btn btn-lg btn-light" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
