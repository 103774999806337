import {Component, Injector, OnInit} from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  public pageTitle: string;

  constructor(public injector: Injector) {
  }

  ngOnInit() {
    this.pageTitle = 'Maintenance';
  }
}
