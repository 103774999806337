import {Component, Injector, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {SysAlert} from '../../../models/sys-alert';
import {SysAlertComponent} from '../sys-alert/sys-alert.component';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-view-sys-alert',
  templateUrl: './view-sys-alerts.component.html',
})
export class ViewSysAlertsComponent extends SysAlertComponent implements OnInit {
  public pageTitle: string;
  public sysAlerts: Array<SysAlert>;
  public readonly seg = {
    alerts: 'alerts',
  };

  // form and form controls
  // resolved values resolved: 1 => unresolved, resolved: 2 => resolved, resolved: 3 => all
  form: FormGroup;
  resolved: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.pageTitle = 'System Alerts';
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.reload();
  }

  createFormControls() {
    // initialize with unresolved
    this.resolved = new FormControl(1);
  }

  createForm() {
    this.form = new FormGroup({
      resolved: this.resolved,
    });
  }

  onChange(event: any) {
    this.reload();
  }

  onEvent(event: any) {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
  }

  resolve(event: any, sysAlert: SysAlert): void {
    event.preventDefault();
    this.sysAlertService.updateSysAlert(sysAlert.id, {resolved: true})
      .then(() => {
        this.reload();
      });
  }

  reload(force?: boolean): void {
    let payload: any;
    this.reloaded = false;
    switch (this.resolved.value) {
      case 1:
        payload = {resolved: false};
        break;
      case 2:
        payload = {resolved: true};
        break;
      case 3:
        payload = {};
        break;
    }
    this.sysAlertService.getSysAlerts(true, payload)
      .then((sysAlerts) => {
        this.sysAlerts = sysAlerts;
        this.reloaded = true;
      });
  }
}
