<div class="row">
  <div class="col-lg-6">
    <mat-card class="mt-1 mb-1">
      <mat-card-title>
        <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Account Settings'" [help]="false" [op]="'edit'"></app-segment-header>
      </mat-card-title>

      <mat-card-content>
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Prefix</div>
          <div class="col-lg-8">{{item.prefix}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">First name</div>
          <div class="col-lg-8">{{item.first}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Middle name</div>
          <div class="col-lg-8">{{item.middle}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Last name</div>
          <div class="col-lg-8">{{item.last}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Suffix</div>
          <div class="col-lg-8">{{item.suffix}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Email</div>
          <div class="col-lg-8">{{item.email}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Phone</div>
          <div class="col-lg-8">{{item.cell_phone}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Carrier</div>
          <div class="col-lg-8">{{item.mobileCarrier}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Roles</div>
          <div class="col-lg-8">{{item.getRolesString()}}</div>
        </div>

        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-4 fw-bold">Services</div>
          <div class="col-lg-8">{{item.getServicesString()}}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
