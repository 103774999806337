import {Component, Injector, Input, OnInit} from '@angular/core';
import {Employment} from '../../../../models/employment';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-employment',
  templateUrl: './view-employment.component.html',
})
export class ViewEmploymentComponent extends CompBaseComponent implements OnInit {
  @Input() employmentType: string;
  public item: Employment;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.loan_app = this.doc.recursive_attributes;
    const segment = this.employmentType === 'primary' ? 'employment' : 'employment2';
    this.item = new Employment(this.doc.recursive_attributes[this.applicant][segment]);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
