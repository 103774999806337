import {Component, Injector, OnInit} from '@angular/core';
import {AliasComponent} from '../alias/alias.component';

@Component({
  selector: 'app-edit-segment-alias',
  templateUrl: '../alias/edit-segment-alias.component.html',
})
export class EditSegmentAliasComponent extends AliasComponent implements OnInit {
  op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
