<div *ngIf="reloaded&&canUpdateTransaction">
  <ul class="nav nav-tabs" id="task_tabs">
    <li class="nav-item">
      <a (click)="setSection($event,section.currentTask)" [ngClass]="{active:currentSection===section.currentTask}"
        [attr.aria-selected]="currentSection===section.currentTask" class="nav-link" data-toggle="tab" id="current_task_tab">
        Current Task
      </a>
    </li>

    <li class="nav-item">
      <a (click)="setSection($event,section.tasks)" [ngClass]="{active:currentSection===section.tasks}"
        [attr.aria-selected]="currentSection===section.currentTask" class="nav-link" data-toggle="tab" id="tasks_tab">
        All Tasks
      </a>
    </li>

    <li *ngIf="canUpdateTransaction" class="nav-item">
      <a (click)="setSection($event,section.bulkUpdateTasks)" [ngClass]="{active:currentSection===section.bulkUpdateTasks}"
        [attr.aria-selected]="currentSection===section.bulkUpdateTasks" class="nav-link" data-toggle="tab" id="bulk_update_tasks_tab">
        Bulk Update
      </a>
    </li>

    <li *ngIf="canUpdateTransaction" class="nav-item">
      <a (click)="setSection($event,section.resetFutureTasks)" [ngClass]="{active:currentSection===section.resetFutureTasks}"
        [attr.aria-selected]="currentSection===section.resetFutureTasks" class="nav-link" data-toggle="tab" id="reset_future_tasks_tab">
        Reset Tasks
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div [ngClass]="{'show active':currentSection===section.currentTask}" aria-labelledby="current_task_tab" class="tab-pane fade" id="current_task" role="tabpanel">
      <app-box *ngIf="!workflow.started" class="mt-3" [type]="'warning'">
        There is no Current Task because the
        <span class="fw-bold">workflow has not yet started!</span>
        You can start the loan workflow from
        <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
      </app-box>
      <div *ngIf="workflow.started">
        <app-view-task (eventEmitted)="onEvent($event)" [title]="currentTrace.state_display" [trace]="currentTrace" [currentState]="currentTrace.state"></app-view-task>
      </div>
    </div>

    <div [ngClass]="{'show active':currentSection===section.tasks}" aria-labelledby="tasks_tab" class="tab-pane fade" id="tasks" role="tabpanel">
      <app-view-segment-tasks (eventEmitted)="onEvent($event)"></app-view-segment-tasks>
    </div>

    <div *ngIf="currentSection===section.bulkUpdateTasks" [ngClass]="{'show active':currentSection===section.bulkUpdateTasks}" aria-labelledby="tasks_tab" class="tab-pane fade" id="bulk_update_tasks" role="tabpanel">
      <app-box *ngIf="!workflow.started" class="mt-3" [type]="'warning'">
        There are no Tasks because the
        <span class="fw-bold">workflow has not yet started!</span>
        You can start the loan workflow from
        <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
      </app-box>
      <div *ngIf="workflow.started">
        <app-bulk-update-tasks (eventEmitted)="onEvent($event)" [allTraces]="allTraces" [tran]="tran" [currentState]="currentState"></app-bulk-update-tasks>
      </div>
    </div>

    <div [ngClass]="{'show active':currentSection===section.resetFutureTasks}" aria-labelledby="reset_future_tasks_tab" class="tab-pane fade" id="reset_future_tasks" role="tabpanel">
      <app-box *ngIf="!workflow.started" class="mt-3" [type]="'warning'">
        There are no Tasks because the
        <span class="fw-bold">workflow has not yet started!</span>
        You can start the loan workflow from
        <a href="" routerLink="/loan/view-workflow">Current Loan/Workflow</a>.
      </app-box>
      <div *ngIf="workflow.started">
        <app-view-segment-reset-workflow (eventEmitted)="onEvent($event)" [tran]="tran"></app-view-segment-reset-workflow>
      </div>
    </div>
  </div>
</div>
