<mat-card *ngIf="mode=='View'" class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row mb-2">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1">
      <div class="fw-bold col-lg-6">Credit type</div>
      <div class="col-lg-6">{{item.getCreditType()}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1" *ngIf="item.code_ex==='Other'">
      <div class="fw-bold col-lg-6">Explain other credit</div>
      <div class="col-lg-6">{{item.other_credit_explain}}</div>
    </div>

    <div class="row border-bottom hover-gray pt-1 pb-1">
      <div class="fw-bold col-lg-6">Value</div>
      <div class="col-lg-6">{{item.amount | currency}}</div>
    </div>
  </mat-card-content>
</mat-card>
