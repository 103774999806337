import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    let country: string;
    let area: string;
    let number1: string;
    let number2: string;

    switch (value.length) {
      case 10:
        country = '1';
        area = value.slice(0, 3);
        number1 = value.slice(3, 6);
        number2 = value.slice(6, 10);
        return `${area}-${number1}-${number2}`;

      case 11:
        country = value[0];
        area = value.slice(1, 4);
        number1 = value.slice(4, 7);
        number2 = value.slice(7, 11);
        return `${country}-${area}-${number1}-${number2}`;

      default:
        return value;
    }
  }
}
