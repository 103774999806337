<div class="site-container menu-style-4" style="display: flex;">
  <vertical-navbar *ngIf="verticalNavBarOpen"></vertical-navbar>

  <div [ngClass]="{'is-mobile':isMobile}" class="main-content" style="flex:1; padding-top: 0 !important;">
    <app-horizontal-navbar *ngIf="reloaded" [subtitle]="pageSubtitle" [title]="pageTitle"></app-horizontal-navbar>
    <div class="main-content-wrap">
      <app-socket-messenger></app-socket-messenger>
      <app-messages></app-messages>
      <app-help></app-help>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="mt-1"></div>
<app-footer></app-footer>
