import {Component, Injector, Input, OnInit} from '@angular/core';
import {Alias} from '../../../../models/alias';
import {AliasComponent} from '../alias/alias.component';

@Component({
  selector: 'app-view-segment-alias',
  templateUrl: './view-segment-alias.component.html',
})
export class ViewSegmentAliasComponent extends AliasComponent implements OnInit {
  @Input() item: Alias;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
