<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Declarations'" [help]="true" [op]="'edit'">
      <ul [ngClass]="{'is-mobile':isMobile}" class="help">
        <li>
          You must respond
          <span class="fw-bold">Yes or No</span> to each of the questions below.
        </li>
        <li>
          You must select your residency status.
        </li>
        <li>
          If you have owned or partially owned real estate in the last three years, you need to provide
          the type of property you own/owned and the manner in which you hold/held title.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <h5>About this Property and Your Money for this Loan</h5>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">Do you intend to occupy the property as your primary residence?</div>
      <div class="col-lg-2 text-end">{{item.primary_residence_ex}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">Have you had an ownership interest in a property in the last three years?</div>
      <div class="col-lg-2 text-end">{{item.ownership_interest_ex}}</div>
    </div>

    <div [hidden]="item.ownership_interest_ex!=='Yes'" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6">The type of property owned</div>
      <div class="col-lg-6 text-end">{{item.getPropertyType()}}</div>
    </div>

    <div [hidden]="item.ownership_interest_ex!=='Yes'" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6">The title to home owned</div>
      <div class="col-lg-6 text-end">{{item.getTitleType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['01']}}</div>

      <div class="col-lg-2 text-end">{{item.relationship | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['98']}}</div>

      <div class="col-lg-2 text-end">{{item.down_payment_borrowed | booleanToString}}</div>

      <div class="col-lg-6" *ngIf="item.down_payment_borrowed===true">Amount borrowed</div>

      <div class="col-lg-6 text-end" *ngIf="item.down_payment_borrowed===true">{{item.borrowed_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">
        {{declaration_text['02']}}
      </div>

      <div class="col-lg-2 text-end">{{item.new_mortgage | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['03']}}</div>

      <div class="col-lg-2 text-end">{{item.new_credit | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['05']}}</div>

      <div class="col-lg-2 text-end">{{item.priority_lien | booleanToString}}</div>
    </div>

    <h5>About Your Finances</h5>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['99']}}</div>

      <div class="col-lg-2 text-end">{{item.endorser_loans | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['91']}}</div>

      <div class="col-lg-2 text-end">{{item.judgments | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['96']}}</div>

      <div class="col-lg-2 text-end">{{item.delinquent_federal_loans | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['94']}}</div>

      <div class="col-lg-2 text-end">{{item.law_suits | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['06']}}</div>

      <div class="col-lg-2 text-end">{{item.deed_in_lieu | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['04']}}</div>

      <div class="col-lg-2 text-end">{{item.short_sale | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['93']}}</div>

      <div class="col-lg-2 text-end">{{item.foreclosures | booleanToString}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-10">{{declaration_text['92']}}</div>

      <div class="col-lg-2 text-end">{{item.bankrupt | booleanToString}}</div>

      <div class="col-lg-6" *ngIf="item.bankrupt===true">Bankruptcies declared</div>

      <div class="col-lg-6 text-end" *ngIf="item.bankrupt===true">{{item.getBankruptcies()}}</div>

    </div>

    <h5>Residence</h5>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Residence status</div>
      <div class="col-lg-6">{{item.getResidenceStatus()}}</div>
    </div>
  </mat-card-content>
</mat-card>
