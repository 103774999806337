import {ModelBase} from './model-base';
import {Trace} from './trace';
import {Permission} from './permission';

export class Workflow extends ModelBase {
  public future_traces: Array<any>;
  public currentTrace: any;
  public permissions: Array<Permission>;
  public permissibleEvents: Array<any>;
  public user_permission: number;
  public user_id: number;
  public workflow_state: string;
  public id: number;
  public tran_id: number;
  public started: boolean;
  public ended: boolean;
  public Traces: Array<Trace>;
  public futureTraces: Array<Trace>;
  public allTraces: Array<Trace>;
  public loan_disposition: string;

  constructor(workflow: any) {
    super();
    if (!workflow) {
      return;
    }
    this.Traces = Trace.deserializeArray(workflow.traces || []);
    this.future_traces = workflow.future_traces;
    this.futureTraces = Trace.deserializeArray(this.future_traces);
    this.allTraces = this.Traces.concat(this.futureTraces)
      .sort((x, y) => x['started_at'] === y['started_at'] ? 0 : (x['started_at'] < y['started_at'] ? -1 : 1));
    this.currentTrace = null;
    this.workflow_state = workflow.workflow_state;
    const wfStates = this.Traces.filter((elt) => {
      return elt.state === this.workflow_state;
    });
    if (wfStates && wfStates.length) {
      this.currentTrace = wfStates.pop();
    } else if (this.Traces.length) {
      this.currentTrace = this.Traces.slice(-1)[0];
    }

    this.started = !!this.currentTrace;
    this.ended = this.currentTrace && (this.currentTrace.state === 'end');
    this.permissibleEvents = Workflow.getPermissibleEvents(workflow);
    this.permissions = Permission.deserializeArray(workflow.permissions);
    this.user_permission = workflow.user_permission;
    this.user_id = workflow.user_id;
    this.id = workflow.id;
    this.tran_id = workflow.tran_id;
    this.loan_disposition = workflow.loan_disposition;
  }

  static getPermissibleEvents(workflow) {
    if (!workflow) {
      return [];
    }
    const events = workflow['permissible_events'];
    const result = [];

    Object.keys(events).forEach((key) => {
      result.push({key: key, value: events[key]});
    });

    return result;
  }

  getLoanAppEndDate(): Date {
    const traceLoanDisp: Trace = this.Traces.find((elt: Trace) => elt.state === 'loan_disposition');
    if (traceLoanDisp) {
      return traceLoanDisp.ended_at || traceLoanDisp.started_at;
    }
    const traceLoanEnd: Trace = this.Traces.find((elt: Trace) => elt.state === 'end');
    if (traceLoanEnd) {
      return traceLoanEnd.started_at;
    }
    return null;
  }

  getLoanAppReceivedDate(): Date {
    const trace: Trace = this.Traces.find((elt: Trace) => elt.state === 'form_1003');
    if (trace) {
      return trace.ended_at || trace.started_at;
    }
    return null;
  }

  // get max date of all traces
  getMaxDate(): Date {
    const traceDates = this.Traces.filter((elt) => elt.done && (elt.ended_at || elt.started_at)).map((elt) => (elt.ended_at || elt.started_at).toISOString());
    if (traceDates && traceDates.length) {
      return new Date(traceDates.sort().pop());
    }
    return new Date();
  }
}
