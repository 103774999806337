import {Component, Injector, Input, OnInit} from '@angular/core';
import {LoInformation} from '../../../../models/lo-information';
import {CompBaseComponent} from '../../../comp-base.component';
import moment from 'moment';

@Component({
  selector: 'app-view-segment-lo-information',
  templateUrl: './view-segment-lo-information.component.html',
})
export class ViewSegmentLoInformationComponent extends CompBaseComponent implements OnInit {
  @Input() item: LoInformation;
  public propertyState: string;
  originationInFuture: boolean;
  receivedInFuture: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    const state = this.doc.recursive_attributes['property_information']['property_state'];
    this.propertyState = state && state.length === 2 ? state : 'Not provided in Property Information';
    this.originationInFuture = moment(this.item.lo_orig_date).isAfter(moment());
    this.receivedInFuture = moment(this.item.lo_received_date).isAfter(moment());
    setTimeout(() => {
      this.reloaded = true;
    }, 0);

  }
}
