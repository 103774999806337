import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Tran} from '../models/tran';
import {Document} from '../models/document';
import {DataService} from './data.service';
import {Permission} from '../models/permission';
import {AnalyzerService} from './analyzer.service';
import {Util} from '../content/util';
import moment from 'moment';
import {CacheService} from './cache.service';
import {CacheManager} from '../models/cache-manager';
import {LoInformation} from "../models/lo-information";

@Injectable()
export class TranService {

  constructor(private httpService: HttpService,
              private cacheService: CacheService) {
  }

  public static getApplicant(loan_app: any, who: string): any {
    if (!loan_app || !loan_app['recursive_attributes']) {
      return null;
    }

    const borrowers = loan_app['recursive_attributes']['borrowers'];
    const applicant = borrowers.find((elt) => elt['role'] === who);
    if (!applicant) {
      return null;
    }

    applicant['full_name'] = TranService.getFullName(applicant['first'], applicant['middle'], applicant['last']);

    return applicant;
  }

  public static getFullName(first: string, middle: string, last: string): any {
    let temp = '';
    let fullName = '';

    temp += (first || '');
    temp += ' ';
    temp += (middle || '');
    temp += ' ';
    temp += (last || '');
    fullName = temp.trim().replace(/[\s]{2,}/g, ' ');

    if (fullName.length === 0) {
      return null;
    }

    return fullName;
  }

  public static getBorrower(tran, index): any {
    const la = tran.getDefaultLoanApp();
    return TranService.getApplicant(la, `borrower_${index}`) || {};
  }

  public getCurrentTranId(): Promise<number> {
    const tran = this.cacheService.getSeg('tran', 'tran');
    if (tran && tran.id) {
      return Promise.resolve(tran.id);
    }
    const tranId = CacheManager.getCurrentTranId();
    if (tranId) {
      return Promise.resolve(tranId);
    }
    const latestTran = this.getTransSync()[0];
    return Promise.resolve(latestTran ? latestTran.id : null);
  }

  public getTrans(force?: boolean): Promise<Array<Tran>> {
    if (!force) {
      const trans = this.cacheService.getSeg('tran', 'trans');
      if (trans) {
        return Promise.resolve(trans);
      }
    }

    return new Promise((resolve, reject) => {
      this.httpService.get('trans')
        .then((data) => {
          const trans = Tran.deserializeArray(data);
          this.cacheService.setSeg('tran', 'trans', trans);
          resolve(trans);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getCurrentTran(force?: boolean): Promise<Tran> {
    return this.getCurrentTranId()
      .then((tranId) => {
        if (tranId) {
          return this.getTran(tranId, force);
        }
        return Promise.resolve(null);
      })
      .catch(() => Promise.reject(null));
  }

  public getCurrentTranSync(): Tran {
    return this.cacheService.getSeg('tran', 'tran');
  }

  public isSandboxTran(): boolean {
    const tran = this.cacheService.getSeg('tran', 'tran');
    return tran && tran.isSandboxTran();
  }

  public isLoanOfficerTran(): boolean {
    const tran = this.cacheService.getSeg('tran', 'tran');
    return tran ? tran.isLoanOfficerTran() : true;
  }

  public isTrans(): boolean {
    const trans = this.cacheService.getSeg('tran', 'trans');
    return trans && trans.length > 0;
  }

  public getTransSync(): Array<Tran> {
    return this.cacheService.getSeg('tran', 'trans');
  }

  public getTran(tranId: number, force?: boolean): Promise<Tran> {
    const tran = this.cacheService.getSeg('tran', 'tran');

    if (!force && tran && tran.id === tranId) {
      return Promise.resolve(tran);
    }

    return new Promise((resolve, reject) => {
      this.httpService.get(`trans/${tranId}`)
        .then((data) => {
          const tran = new Tran(data);
          CacheManager.storeCurrentTranId(tran.id);
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(tran);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getTranById(tranId: number): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`trans/${tranId}`)
        .then((data) => {
          resolve(new Tran(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getTranWS(tranId: number, ws_key: string, force?: boolean): Promise<Document> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`trans/ws/${tranId}?ws_key=${ws_key}`, {}, false)
        .then((data) => {
          resolve(new Document(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateTranWS(tranId: number, worksheet_key: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`trans/ws/${tranId}?ws_key=${worksheet_key}`, payload, false)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public deleteTran(tranId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`trans/${tranId}`)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getTranDocuments(tranId: number, force?: boolean): Promise<Array<Document>> {
    return this.getTran(tranId, force)
      .then((tran) => {
        const documents = Document.deserializeArray(tran['documents']);
        return Promise.resolve(documents);
      });
  }

  public createTransaction(payload): Promise<Tran> {
    return new Promise((resolve, reject) => {
      let tran;
      this.httpService.post('trans', payload)
        .then((data) => {
          tran = new Tran(data);
          this.cacheService.setSeg('tran', 'tran', tran);
          CacheManager.storeCurrentTranId(tran.id);
        })
        .then(() => {
          return this.resetTrans();
        }).then(() => {
        resolve(tran);
      })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateTran(tranId: number, payload: any): Promise<Tran> {
    return new Promise((resolve, reject) => {
      let tran;
      this.httpService.put(`trans/${tranId}`, payload)
        .then((data) => {
          tran = new Tran(data);
        })
        .then(() => {
          return this.resetTrans();
        })
        .then(() => {
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(tran);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateTransactionItem(tranId: number, icnId: string, payload: any): Promise<Tran> {
    return new Promise((resolve, reject) => {
      let icn = null;
      this.httpService.put(`trans/${tranId}/transaction_items/${icnId}`, payload)
        .then((data) => {
          icn = data.data;
          return this.getTran(tranId, true);
        })
        .then((tran) => {
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(icn);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateTransactionItemStatus(tranId, transactionItemId, status) {
    return new Promise((resolve, reject) => {
      let icn = null;
      this.httpService.put(`trans/${tranId}/transaction_items/${transactionItemId}`, {status: status})
        .then((data) => {
          icn = data.data;
          return this.getTran(tranId, true);
        })
        .then((tran) => {
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(icn);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateTransactionItems(tranId: number, payload: any): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`trans/transaction_items_update/${tranId}`, {selected: payload})
        .then(() => {
          return this.getTran(tranId, true);
        })
        .then((tran) => {
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(tran);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createTransactionItem(tranId: number, payload: any): Promise<Tran> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`trans/${tranId}/transaction_items`, payload)
        .then(() => {
          return this.getTran(tranId, true);
        })
        .then((tran) => {
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(tran);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // TODO4
  public getTransactionSummary(options) {
    const getPropertyValue = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['transmittal_data']['property_appraised_value'];
    };

    // No down payment amount, only source
    const getDownPayment = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }
      return null;
    };

    const getIsCoBorrower = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return false;
      }

      return AnalyzerService.isCoBorrower(loan_app['recursive_attributes']);
    };

    const getApplicant = (loan_app, who) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      const borrowers = loan_app['recursive_attributes']['borrowers'];
      const applicant = borrowers.find((elt) => elt['role'] === who);
      if (!applicant) {
        return null;
      }

      applicant['full_name'] = TranService.getFullName(applicant['first'], applicant['middle'], applicant['last']);

      return applicant;
    };

    const getProperty = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      const la_property = loan_app['recursive_attributes']['property_information'];
      la_property['full_address'] = Util.formatAddress(la_property['property_address'], la_property['property_address2'], la_property['property_city'], la_property['property_state'], la_property['property_zip'], la_property['property_zip_four'], null);

      return la_property;
    };

    const getLoanPurpose = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes'] || !loan_app['recursive_attributes']['loan_purpose']) {
        return null;
      }

      const loan_purpose = loan_app['recursive_attributes']['loan_purpose'];
      loan_purpose['loan_purpose_string'] = DataService.getLoanPurposeCodes()[loan_purpose['loan_purpose_code_ex']];

      return loan_purpose;
    };

    const getLoanInformation = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['loan_information'];
    };

    const getLoanData = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['loan_data'];
    };

    const getProductData = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['product_identification'];
    };

    const getProductCharacter = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['product_characteristics'];
    };

    const getArmData = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['arm_data'];
    };

    const getIndexPlusMargin = (loan_app) => {
      let armData = getArmData(loan_app)
      if (!armData || !armData['margin']) {
        return null;
      }
      return `${armData['index'] || armData['index_type_new']} + ${armData['margin']}`;
    };

    const getConstructionData = (loan_app) => {
      if (!loan_app || !loan_app['recursive_attributes']) {
        return null;
      }

      return loan_app['recursive_attributes']['construction'];
    };

    // unused
    const getECOA = (user) => {
      if (!user['user_configuration']) {
        return null;
      }

      return {
        ecoa_address: user['user_configuration']['ecoa_address']
      };
    };

    const la = options['transaction'].getDefaultLoanApp();
    if (!la) {
      return {};
    }
    const mlo_user_config = options['transaction']['owner_user_configuration'];
    const lo_info: LoInformation = new LoInformation(la['recursive_attributes']['lo_information'] || {});
    const borrower = getApplicant(la, 'borrower_1') || {};
    const coBorrower = getApplicant(la, 'borrower_2') || {};
    const borrower3 = getApplicant(la, 'borrower_3') || {};
    const borrower4 = getApplicant(la, 'borrower_4') || {};
    const isCoBorrower = getIsCoBorrower(la);
    const property = getProperty(la) || {};
    const loanPurpose = getLoanPurpose(la) || {};
    const loanInformation = getLoanInformation(la) || {};
    const loanData = getLoanData(la) || {};
    const prodData = getProductData(la) || {};
    const prodChar = getProductCharacter(la) || {};
    const armData = getArmData(la) || {};
    const constructionData = getConstructionData(la) || {};
    const downPayment = getDownPayment(la);
    const propertyValue = getPropertyValue(la);
    const txOwner = options['transaction']['owner_full_name'];
    const txPermissions = options['transaction']['permissions'];
    const loanAppPermissions = la ? Permission.deserializeArray(la.permissions) : null;
    const noDefaultLoanApp = !la && options['transaction'].getLoanApps();
    const borrower_dob = moment(borrower['dob'], 'YYYYMMDD');
    const co_borrower_dob = moment(coBorrower['dob'], 'YYYYMMDD');

    return {
      noDefaultLoanApp: !!noDefaultLoanApp,
      errors: !!noDefaultLoanApp,
      ecoaAddress: mlo_user_config ? mlo_user_config['ecoa_address'] : null,

      mloFullName: lo_info ? lo_info['lo_name'] : null,
      mloStateLicenseNumber: lo_info && property && property['property_state'] ? lo_info['lo_state_license'] : null,
      mloNMLS: lo_info ? lo_info['lo_nmls'] : null,
      companyLicenseNumber: lo_info ? lo_info['lo_company_nmls'] : null,
      companyName: lo_info ? lo_info['lo_company_name'] : null,
      companyAddress: lo_info ? lo_info.getCompanyFullAddress() : null,

      compensationPercent: mlo_user_config ? mlo_user_config['compensation_percent'] : null,
      brokerMinCompensation: mlo_user_config ? mlo_user_config['min_compensation_amount'] : null,
      brokerMaxCompensation: mlo_user_config ? mlo_user_config['max_compensation_amount'] : null,
      borrowerFullName: borrower['full_name'] || null,
      borrowerDOB: borrower_dob.isValid() ? moment(borrower_dob).format('YYYY-MM-DD') : null,
      coBorrowerDOB: co_borrower_dob.isValid() ? moment(co_borrower_dob).format('YYYY-MM-DD') : null,
      coBorrowerFullName: coBorrower['full_name'] || null,
      borrower3FullName: borrower3['full_name'] || null,
      borrower4FullName: borrower4['full_name'] || null,
      isCoBorrower: isCoBorrower,
      propertyAddress: property['full_address'] || null,
      propertyState: property ? property['property_state'] : null,
      loanPurpose: loanPurpose['loan_purpose_string'] || null,
      lenderCaseNo: loanInformation['lender_case_no'] || null,
      loanAmount: loanInformation['loan_amount'] || null,
      propertyValue: propertyValue || null,
      downPayment: downPayment || null,
      txOwner: txOwner,
      txPermissions: txPermissions,
      loanAppPermissions: loanAppPermissions,
      periodsNo: loanInformation['periods_no'] || null,
      interestRate: loanInformation['interest_rate'] || null,
      homeImprovementCost: loanData['home_improvements_cost'] || null,
      landCost: constructionData['land_original_cost'] || null,
      loanProduct: prodData['product_description'] || null,
      interestOnlyMonths: prodChar['interest_only_months_no'] || null,
      fixedInterestMonths: prodChar['fixed_period_months_no'] || null,
      firstAdjustmentMonthsNo: armData['first_adjustment_months_no'] || null,
      subsequentAdjustmentMonthsNo: armData['subsequent_adjustment_months_no'] || null,
      indexPlusMargin: getIndexPlusMargin(la)
    };
  }

  public copySandboxTran(sourceTranId: number, targetTranId: number, docIds: Array<number>): Promise<Tran> {
    const payload = {
      source_tran_id: sourceTranId,
      target_tran_id: targetTranId,
      doc_ids: docIds
    };
    return new Promise((resolve, reject) => {
      this.httpService.put(`trans/copy_tran_docs`, payload)
        .then((data) => {
          const tran = new Tran(data);
          this.cacheService.setSeg('tran', 'tran', tran);
          resolve(tran);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  // resetTrans when any transaction was created or modified
  private resetTrans(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getTrans(true)
        .then((trans) => {
          this.cacheService.setSeg('tran', 'trans', trans);
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
