<mat-card class="mt-2 mb-1" *ngIf="reloaded">
  <mat-card-title>
    Pull Credit Report
  </mat-card-title>

  <mat-card-content>
    <form *ngIf="reloaded" [formGroup]="form" novalidate role="form">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit report type</mat-label>
            <mat-select formControlName="creditReportType" placeholder="Select credit report type" required>
              <mat-option *ngFor="let creditReportType of creditReportTypes" [value]="creditReportType.key">{{creditReportType.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditReportType.hasError('required')">
              Credit report type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3" *ngIf="creditReportType.value==='refresh'">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Reference number</mat-label>
            <input formControlName="existingOrderId" matInput placeholder="Reference number" type="text" [required]="isExistingOrderIdRequired()" maxlength="{{MAX_LEN.GEN_ID}}">
            <mat-error *ngIf="existingOrderId.hasError('required')">
              Reference number is required
            </mat-error>
            <mat-hint>
              Use the reference number of an existing credit report
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Credit report name</mat-label>
            <input formControlName="docName" matInput placeholder="Credit report name" type="text" maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="docName.hasError('required')">
              Credit report name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput placeholder="Description" type="text" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Prepared for</mat-label>
            <mat-select formControlName="who" placeholder="Select prepared for" required>
              <mat-option *ngFor="let applicant of applicants" [value]="applicant.key">{{applicant.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="who.hasError('required')">
              Borrower is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Username</mat-label>
            <input formControlName="username" matInput placeholder="Username" type="text" maxlength="{{MAX_LEN.S_DESC}}" required>
            <mat-error *ngIf="description.hasError('required')">
              Username is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput placeholder="Password" type="text" maxlength="{{MAX_LEN.S_DESC}}" required autocomplete="on">
            <mat-error *ngIf="password.hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit provider</mat-label>
            <mat-select formControlName="creditProvider" placeholder="Select credit provider" required>
              <mat-option *ngFor="let creditProvider of creditProviders" [value]="creditProvider.key">{{creditProvider.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditProvider.hasError('required')">
              Credit provider is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button *ngIf="creditReportType.value==='new'" (click)="create($event)" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="button">New Credit Report</button>
          <button *ngIf="creditReportType.value==='refresh'" (click)="refresh($event)" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="button">Refresh Credit Report</button>
          <button *ngIf="creditReportType.value===null||creditReportType.value===''" (click)="refresh($event)" [disabled]="!form.valid" class="btn btn-lg btn-primary" type="button">Create/Refresh Credit Report</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-secondary">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div >
  <mat-spinner [diameter]="68" [hidden]="reloaded" class="ms-5 mb-3"></mat-spinner>
</div>
