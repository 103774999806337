import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable()
export class PitchService {

  constructor(private httpService: HttpService) {
  }

  public getPitchReport(options: any): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`pitch/logistic`, options)
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public getConvertibleReport(options: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`pitch/convertible`, options)
        .then((report) => {
          resolve(report);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
