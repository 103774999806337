<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Loan Information'" [help]="true" [op]="'edit'">
      <ul>
        <li>
          <strong>Number of months:</strong> The loan amortization period count in months.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Mortgage type</div>
      <div class="col-lg-6">{{item.getMortgageType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Amortization type</div>
      <div class="col-lg-6">{{item.getAmortizationType()}}</div>
    </div>

    <div *ngIf="item.amortization_type_ex ==='Other'" class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Explain amortization type</div>
      <div class="col-lg-6">{{item.amortization_type_explain}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Agency case number</div>
      <div class="col-lg-6">{{item.agency_case_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Lender case number</div>
      <div class="col-lg-6">{{item.lender_case_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Base loan amount</div>
      <div class="col-lg-6">{{item.loan_amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Interest Rate</div>
      <div class="col-lg-6">{{item.getInterestRate() | percent:'1.3-3'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Amortization period</div>
      <div class="col-lg-6">{{item.amortization_period_type}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Number of periods</div>
      <div class="col-lg-6">{{item.periods_no}}</div>
    </div>

    <div *ngIf="isRefinanceLoan">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col fw-bold pr-font-large">
          Government Loan Only
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Government refinance type</div>
        <div class="col-lg-6">{{item.getGovRefinanceType()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.gov_refinance_type==='Other'">
        <div class="col-lg-6 fw-bold">Other refinance description</div>
        <div class="col-lg-6">{{item.other_description}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
