<mat-card class="mt-2 mb-1">
  <mat-card-title>Edit Declarations</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <h5>About this Property and Your Money for this Loan</h5>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="primary_residence">
            Do you intend to occupy the property as your primary residence?
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group formControlName="primary_residence_ex" aria-label="primary_residence_label">
            <mat-radio-button [checked]="primary_residence_ex.value==='Yes'" [value]="'Yes'">Yes</mat-radio-button>
            <mat-radio-button [checked]="primary_residence_ex.value==='No'" [value]="'No'">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="ownership_interest">
            Have you had an ownership interest in a property in the last three years?
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group formControlName="ownership_interest_ex" aria-label="ownership_interest_label">
            <mat-radio-button [checked]="ownership_interest_ex.value==='Yes'" [value]="'Yes'">Yes</mat-radio-button>
            <mat-radio-button [checked]="ownership_interest_ex.value==='No'" [value]="'No'">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div [hidden]="ownership_interest_ex.value!=='Yes'" class="row pt-1 pb-1">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100" class="md-icon-left w-100">
            <mat-label>Property owned type</mat-label>
            <mat-select [required]="ownership_interest_ex.value==='Yes'" formControlName="property_type_ex" placeholder="Select type of property owned">
              <mat-option *ngFor="let property_type of property_types"
                [value]="property_type.key">{{property_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="property_type_ex.hasError('required')">
              Type of property owned is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div [hidden]="ownership_interest_ex.value!=='Yes'" class="row pt-1 pb-1">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Property owned title type</mat-label>
            <mat-select [required]="ownership_interest_ex.value==='Yes'" formControlName="title_type_ex" placeholder="Select type of title to property owned">
              <mat-option *ngFor="let title_type of title_types"
                [value]="title_type.key">{{title_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="property_type_ex.hasError('required')">
              Type of title to property owned is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="relationship_label">
            {{declaration_text['01']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'relationship')" aria-label="relationship_label">
            <mat-radio-button [checked]="relationship.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="relationship.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- down payment borrowed -->
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="down_payment_borrowed_label">
            {{declaration_text['98']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'down_payment_borrowed')" aria-label="down_payment_borrowed_label">
            <mat-radio-button [checked]="down_payment_borrowed.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="down_payment_borrowed.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 text-start" [hidden]="!isBorrowedAmountRequired()">
          <mat-form-field class="w-100">
            <mat-label>Borrowed amount</mat-label>
            <input appDecimalOnly formControlName="borrowed_amount" matInput placeholder="Borrowed amount" type="text" [required]="isBorrowedAmountRequired()">
            <mat-error *ngIf="borrowed_amount.hasError('required')">
              Borrowed amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--new mortgage-->
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="new_mortgage_label">
            {{declaration_text['02']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'new_mortgage')" aria-label="relationship_label">
            <mat-radio-button [checked]="new_mortgage.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="new_mortgage.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="new_credit_label">
            {{declaration_text['03']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'new_credit')" aria-label="relationship_label">
            <mat-radio-button [checked]="new_credit.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="new_credit.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="priority_lien_label">
            {{declaration_text['05']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'priority_lien')" aria-label="priority_lien_label">
            <mat-radio-button [checked]="priority_lien.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="priority_lien.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <h5>About Your Finances</h5>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="endorser_loans_label">
            {{declaration_text['99']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'endorser_loans')" aria-label="endorser_loans_label">
            <mat-radio-button [checked]="endorser_loans.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="endorser_loans.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="judgments_label">
            {{declaration_text['91']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'judgments')" aria-label="judgments_label">
            <mat-radio-button [checked]="judgments.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="judgments.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="delinquent_federal_loans_label">
            {{declaration_text['96']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'delinquent_federal_loans')" aria-label="delinquent_federal_loans_label">
            <mat-radio-button [checked]="delinquent_federal_loans.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="delinquent_federal_loans.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="law_suits_label">
            {{declaration_text['94']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'law_suits')" aria-label="law_suits_label">
            <mat-radio-button [checked]="law_suits.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="law_suits.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="deed_in_lieu_label">
            {{declaration_text['06']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'deed_in_lieu')" aria-label="deed_in_lieu_label">
            <mat-radio-button [checked]="deed_in_lieu.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="deed_in_lieu.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="short_sale_label">
            {{declaration_text['04']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'short_sale')" aria-label="short_sale_label">
            <mat-radio-button [checked]="short_sale.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="short_sale.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="foreclosures_label">
            {{declaration_text['93']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'foreclosures')" aria-label="foreclosures_label">
            <mat-radio-button [checked]="foreclosures.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="foreclosures.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 my-auto">
          <label id="bankrupt_label">
            {{declaration_text['92']}}
          </label>
        </div>

        <div class="col-lg-6 my-auto text-end">
          <mat-radio-group (change)="onChange($event, 'bankrupt')" aria-label="bankrupt_label">
            <mat-radio-button [checked]="bankrupt.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="bankrupt.value===false" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="bankrupt.value===true">
        <!--bankrupt 7-->
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-end">
            <div style="margin-right: 8px;">
              <mat-checkbox formControlName="bankrupt_chapter_7">Chapter 7</mat-checkbox>
            </div>
          </div>
        </div>

        <!--bankrupt 11-->
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-end">
            <mat-checkbox formControlName="bankrupt_chapter_11">Chapter 11</mat-checkbox>
          </div>
        </div>

        <!--bankrupt 12-->
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-end">
            <mat-checkbox formControlName="bankrupt_chapter_12">Chapter 12</mat-checkbox>
          </div>
        </div>

        <!--bankrupt 13-->
        <div class="row pt-1 pb-1 border-bottom hover-gray">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-end">
            <mat-checkbox formControlName="bankrupt_chapter_13">Chapter 13</mat-checkbox>
          </div>
        </div>
      </div>

      <h5>Residence</h5>

      <div class="row pt-1 pb-1">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Residence status</mat-label>
            <mat-select formControlName="citizen_ex" placeholder="Select residency status">
              <mat-option *ngFor="let citizen of citizen_types"
                [value]="citizen.key">{{citizen.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-6">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
