import {Component, Injector, Input, OnInit} from '@angular/core';
import {Employment} from '../../../../models/employment';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-employment',
  templateUrl: './view-segment-employment.component.html',

})
export class ViewSegmentEmploymentComponent extends CompBaseComponent implements OnInit {
  @Input() employmentType: string;
  @Input() item: Employment;
  public header: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.employmentType === 'primary') {
      this.header = 'Primary Employment';
    } else {
      this.header = 'Secondary Employment';
    }
    this.segment = this.employmentType === 'primary' ? 'employment' : 'employment2';
    this.section = this.applicant;
  }
}
