<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>First name</mat-label>
            <input formControlName="alternate_first" matInput placeholder="First name" maxlength="{{MAX_LEN.NAME_PART}}" required>
            <mat-error *ngIf="alternate_first.hasError('required')">
              First name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Middle name</mat-label>
            <input type="text" formControlName="alternate_middle" matInput placeholder="Middle name" maxlength="{{MAX_LEN.NAME_PART}}">
            <mat-error *ngIf="alternate_middle.hasError('required')">
              Middle name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Last name</mat-label>
            <input formControlName="alternate_last" matInput placeholder="Last name" maxlength="{{MAX_LEN.NAME_PART}}" required>
            <mat-error *ngIf="alternate_last.hasError('required')">
              Last name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Suffix</mat-label>
            <input formControlName="alternate_suffix" matInput placeholder="Suffix" maxlength="{{MAX_LEN.NAME_PART}}">
            <mat-error *ngIf="alternate_suffix.hasError('required')">
              Suffix is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
