<div class="d-flex align-items-center justify-content-center mt-3">
  <div style="background:white;width:800px;">
    <form [formGroup]="form" novalidate style="border:1px solid black;" class="p-3">
      <h5>Convertible Note Assumptions</h5>
      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Total capital for Seed + Series A (in $M)</mat-label>
          <br>
        </div>
        <div class="col-6">
          <input formControlName="req_capital" matInput placeholder="Required capital">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Convertible Note nominal value (in $M)</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="note" matInput placeholder="Note nominal value">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Annual Interest (as a fraction)</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="interest" matInput placeholder="Interest">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Discount (as a fraction)</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="discount" matInput placeholder="Discount">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Valuation Cap</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="valuation_cap" matInput placeholder="Valuation cap">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Series A event (in years from note's date)</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="valuation_event_years" matInput placeholder="Valuation event in years">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6 my-auto">
          <mat-label>Series A pre-money valuation</mat-label>
          <br>
        </div>

        <div class="col-6">
          <input formControlName="valuation" matInput placeholder="Actual valuation at Series A">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="onSubmit()" class="btn btn-lg btn-primary" [disabled]="form.invalid">Submit</button>
        </div>
      </div>

    </form>

    <div *ngIf="computed" style="width: 100%; font-size: large;border:1px solid black" class="mt-3 p-3">
      <div *ngFor="let pair of assumptions" class="row border-bottom hover-gray">
        <div class="col-lg-6" [innerHTML]="pair['key']"></div>
        <div class="col-lg-6 text-start" [innerHTML]="pair['value']"></div>
      </div>
      <div class="mt-3">
      </div>
      <div *ngFor="let pair of computations" class="row border-bottom hover-gray">
        <div class="col-lg-6" [innerHTML]="pair['key']"></div>
        <div class="col-lg-6 text-start" [innerHTML]="pair['value']"></div>
      </div>
      <div class="mt-3">
      </div>
    </div>
  </div>
</div>
