import {Component, Injector, Input, OnInit} from '@angular/core';
import {Mcr} from '../../../../models/mcr';
import {LoMcrComponent} from '../lo-mcr/lo-mcr.component';
import {FormUtil} from '../../../form-util';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-manage-segment-lo-mcr',
  templateUrl: './manage-segment-lo-mcr.component.html',
})
export class ManageSegmentLoMcrComponent extends LoMcrComponent implements OnInit {
  @Input() mcr: Mcr;
  public mcrTitle: string;
  public isReport: boolean;
  public report: any;
  public reportKeys: Array<string>;
  public form: FormGroup;
  public state: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
    this.isReport = false;
    this.mcrTitle = `MCR for Q${this.mcr.quarter} ${this.mcr.year}`;
  }

  onEvent(event: any): void {
    if (event.type === 'Close') {
      this.isReport = false;
      return;
    }
    super.onEvent(event);
  }

  createFormControls(): void {
    this.state = new FormControl(null, Validators.pattern(globals.pattern.state));
  }

  createForm(): void {
    this.form = new FormGroup({
      state: this.state
    });
  }

  genReport(): void {
    const state = this.state.value;
    this.mcrService.genLOMcrReport(this.mcr.id, state)
      .then((mcr) => {
        this.mcr = mcr;
        this.isReport = true;
        FormUtil.genSuccessMessage(this.sharedService, 'MCR report successfully generated');
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'MCR report generation failed', data);
      });
  }

  deleteMcr(): void {
    this.mcrService.deleteMcr(this.mcr.id)
      .then((mcr) => {
        FormUtil.genSuccessMessage(this.sharedService, 'MCR report successfully deleted');
        this.router.navigate(['/loans/lo-mcr/list']);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to delete MCR report', data);
      });
  }
}
