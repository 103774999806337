import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';
import {globals} from '../../../../shared/globals/globals';
import {AdminService} from '../../../../services/admin.service';

@Component({
  selector: 'app-delete-users',
  templateUrl: './delete-user.component.html',
})
export class DeleteUserComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Delete User';
  adminService: AdminService;

  // form and form controls
  public form: FormGroup;
  public userId: FormControl;

  constructor(public injector: Injector,
              private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.adminService = this.injector.get(AdminService);
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.userId = new FormControl(null);
  }

  createForm() {
    this.form = new FormGroup({
      userId: this.userId,
    });
  }

  deleteUser(): void {
    if (globals.getEnv() == 'development' || globals.getEnv() == 'qa' || globals.getEnv() == 'test') {
      this.adminService.deleteUser(this.userId.value)
        .then(() => {
          alert('User completely destroyed on Authentication Server and POLOS!')
          this.router.navigate(['/admin/user-list'], {queryParams: this.form.value});
        })
        .catch(() => {
          alert('Failed to delete user!')
        })
      return;
    }
    alert('This function is only available on development, test, and QA environments!');
  }

  reset(): void {
    this.form.reset();
  }
}
