import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {Help} from '../../models/help';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  public reloaded: boolean;
  public helpTitle: string;
  public readonly helpKeys = Help.helpKeys;

  constructor(private sharedService: SharedService) {
    this.reloaded = false;
  }

  ngOnInit() {
    this.sharedService.eventEmitted.subscribe((event) => {
      if (event.type === 'ChangePage') {
        this.helpTitle = event.help;
        return;
      }
      if (event.type === 'ToggleHelp') {
        this.reloaded = !this.reloaded;
        return;
      }
    });
  }
}
