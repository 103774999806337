<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Renovation & Construction'" [help]="true" [op]="'edit'">
      <ul>
        <li>
          <span class="fw-bold">Energy-related improvements.</span>
          When true, indicates that the loan will include an amount for energy-related improvements.
        </li>
        <li>
          <span class="fw-bold">Conversion of contract for deed.</span>
          When true, indicates that the subject transaction proceeds are used to pay off the remaining balance of the land contract (also known as a contract for deed).
        </li>
        <li>
          <span class="fw-bold">Energy efficient home.</span>
          Indicates that the appraiser states that the property meets FHA /VA Energy Efficient Home guidelines. Usually applies to new construction.
        </li>
      </ul>
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="true">
      <div class="row pt-1 pb-1 border-bottom">
        <div class="col fw-bold pr-font-large">
          Renovation
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Renovation of an existing property?</div>
        <div class="col-lg-6" *ngIf="item.renovation_loan_indicator!==null">{{item.renovation_loan_indicator | booleanToString}}</div>
        <div class="col-lg-6" *ngIf="item.renovation_loan_indicator===null">(no response)</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Construction improvements cost</div>
        <div class="col-lg-6">{{item.construction_improvements_cost | currency}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Energy-related improvements?</div>
        <div class="col-lg-6" *ngIf="item.energy_improvements_indicator!==null">{{item.energy_improvements_indicator | booleanToString}}</div>
        <div class="col-lg-6" *ngIf="item.energy_improvements_indicator===null">(no response)</div>
      </div>

      <div class="row pt-1 border-bottom pb-1">
        <div class="col fw-bold pr-font-large">
          Construction
        </div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Construction loan type</div>
        <div class="col-lg-6">{{item.getConstructionLoanType()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Construction-to-permanent closing type</div>
        <div class="col-lg-6">{{item.getConstructionClosingType()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Conversion of contract for deed?</div>
        <div class="col-lg-6" *ngIf="item.contract_for_deed_indicator!==null">{{item.contract_for_deed_indicator | booleanToString}}</div>
        <div class="col-lg-6" *ngIf="item.contract_for_deed_indicator===null">(no response)</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Land original cost</div>
        <div class="col-lg-6">{{item.land_original_cost | currency}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Land appraisal type</div>
        <div class="col-lg-6">{{item.getLandAppraisalType()}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Land appraised value</div>
        <div class="col-lg-6">{{item.land_appraised_value | currency}}</div>
      </div>

      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-6 fw-bold">Energy efficient home?</div>
        <div class="col-lg-6" *ngIf="item.energy_efficient_indicator!==null">{{item.energy_efficient_indicator | booleanToString}}</div>
        <div class="col-lg-6" *ngIf="item.energy_efficient_indicator===null">(no response)</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
