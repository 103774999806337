import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-liabilities',
  templateUrl: '../liability/edit-segment-liabilities.component.html',
})
export class EditSegmentLiabilitiesComponent extends CompBaseComponent implements OnInit {
  public clearLiabilities: FormControl;
  public form: FormGroup;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'liabilities';
    this.section = this.applicant;
    this.createFormControls();
    this.createForm();
    this.reload();
  }

  createFormControls(): void {
    this.clearLiabilities = new FormControl(null);
  }

  createForm(): void {
    this.form = new FormGroup({
      clearLiabilities: this.clearLiabilities
    });
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  empty(): void {
    const clear_zero_balance = +this.clearLiabilities.value === 1;
    const clear_all = +this.clearLiabilities.value === 2;
    const data = {section: this.section, segment: this.segment, options: {clear_all: clear_all, clear_zero_balance: clear_zero_balance}};
    this.loanAppService.emptyLoanAppObj(this.doc.id, data)
      .then(() => {
        const message = `Selected liabilities successfully deleted`;
        FormUtil.genSuccessMessage(this.sharedService, message);
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
