import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Help} from '../../../../models/help';
import {Customer} from "../../../../models/customer";

@Component({
  selector: 'app-view-customers',
  templateUrl: './view-customers.component.html',
})
export class ViewCustomersComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle = 'Customers';
  public items: Array<Customer>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload(false);
    this.reloadOnSync();
    //todo:
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.accountContacts
      });
    }, 0);
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const userSync = this.userService.getUserSync();
    //todo:
    this.items = userSync.contacts;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
