import {Component, Injector, Input, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Document} from '../../../models/document';
import {Help} from '../../../models/help';
import {Mcr} from '../../../models/mcr';
import {PermissionComponent} from '../permission/permission.component';

@Component({
  selector: 'app-view-permissions',
  templateUrl: './view-permissions.component.html',
})
export class ViewPermissionsComponent extends PermissionComponent implements OnInit {
  @Input() public type: string;
  public pageTitle = 'Access Control';
  public mcrs: Array<Mcr>;
  public mcr: Mcr;
  public tran: Tran;
  public workflow: any;
  public otherDocs: Array<Document>;
  public laDocs: Array<Document>;
  public isContacts: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isContacts = this.userService.isContacts();
    this.reload(false);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.permission
      });
    }, 0);
  }

  reload(force: boolean): void {
    this.reloaded = false;
    if (this.type === 'mcr') {
      this.orgService.getDefaultOrganization()
        .then((org) => {
          this.sharedService.eventEmitted.emit({type: 'ChangeOrg'});
          return this.mcrService.getOrgMcrs(org.id);
        }).then((mcrs) => {
        this.mcrs = mcrs;
        this.reloaded = true;
      }).catch(() => {
        this.reloaded = true;
      });
      return;
    }
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        this.tran = tran;
        this.workflow = this.tran.workflow;
        const otherDocs = this.tran['documents'].filter((elt) => elt.dtype !== 64 && elt.dtype < 256);
        const laDocs = this.tran['documents'].filter((elt) => elt.dtype === 64);
        this.otherDocs = Document.deserializeArray(otherDocs);
        this.laDocs = Document.deserializeArray(laDocs);
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
