import {ModelBase} from './model-base';

export class EnvelopeHeader extends ModelBase {
  institution_id: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'date') {
        this[prop] = this.getDateProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  toServerPayload(): any {
    return {
      institution_id: this.institution_id,
    };
  }
}
