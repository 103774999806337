import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {FormUtil} from '../../form-util';
import {CompBaseComponent} from '../../comp-base.component';
import {FolderGroup} from '../../../models/folder-group';
import {Folder} from '../../../models/folder';

@Component({
  selector: 'app-edit-folder-group',
  templateUrl: './edit-folder-group.component.html',
})
export class EditFolderGroupComponent extends CompBaseComponent implements OnInit {
  @Input() folderGroup: FolderGroup;
  public items: Array<Folder>;

  // form and form controls
  public form: FormGroup;
  public itemArray: FormArray;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.items = this.folderGroup.items;
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.itemArray = new FormArray([]);
    this.items.forEach((item) => this.itemArray.push(new FormControl(item.selected)));
  }

  createForm(): void {
    this.form = new FormGroup({
      items: this.itemArray
    });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: 'View'});
  }

  update(): void {
    // update selected property of each item from form array
    this.itemArray.controls.forEach((control, index) => {
      this.items[index].selected = !!control.value;
    });

    this.tranService.updateTransactionItems(this.tran.id, this.items)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Successfully updated folder list');
        this.eventService.eventEmitter.emit({type: 'Reload', component: 'tran'});
        this.eventEmitted.emit({type: 'Reload'});
      }).catch(
      (data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update folder list', data);
        this.eventEmitted.emit({type: 'Reload'});
      }
    );
  }
}
