<mat-card class="mt-2 mb-1">
  <mat-card-title>Product Characteristics</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row pb-1 pt-1 hover-gray">
        <div class="col-lg-4 my-auto fw-bold">
          Is there a prepayment penalty?
        </div>
        <div class="col-lg-8">
          <mat-radio-group aria-label="prepayment_penalty_label" formControlName="prepayment_penalty_indicator">
            <mat-radio-button [checked]="prepayment_penalty_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="prepayment_penalty_indicator.value===false" [value]="false">No</mat-radio-button>
            <mat-error *ngIf="submitted && prepayment_penalty_indicator.hasError('required')">
              Prepayment penalty response is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-3" *ngIf="prepayment_penalty_indicator.value===true">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Prepayment penalty expiration months count</mat-label>
            <input type="text" appNumberOnly formControlName="prepayment_months_no" matInput placeholder="Prepayment penalty expiration months count" maxlength="3">
            <mat-error *ngIf="prepayment_months_no.hasError('required')">
              Prepayment penalty expiration months count is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row hover-gray pb-1 pt-1 hover-gray">
        <div class="col-lg-4 my-auto fw-bold">
          Is this an interest only loan?
        </div>
        <div class="col-lg-8 text-start">
          <mat-radio-group aria-label="interest_only_label" formControlName="interest_only">
            <mat-radio-button [checked]="interest_only.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="interest_only.value===false" [value]="false">No</mat-radio-button>
            <mat-error *ngIf="submitted && interest_only.hasError('required')">
              Interest only loan response is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-3 " *ngIf="interest_only.value===true">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Interest only term months count</mat-label>
            <input type="text" appNumberOnly formControlName="interest_only_months_no" matInput placeholder="Interest only term months count" maxlength="3">
            <mat-error *ngIf="interest_only_months_no.hasError('required')">
              Interest only term months count is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-1 pt-1 hover-gray">
        <div class="col-lg-4 my-auto fw-bold">
          Is this a negative amortization loan?
        </div>
        <div class="col-lg-8 text-start">
          <mat-radio-group aria-label="negative_amortization_indicator_label" formControlName="negative_amortization_indicator">
            <mat-radio-button [checked]="negative_amortization_indicator.value===true" [value]="true">Yes</mat-radio-button>
            <mat-radio-button [checked]="negative_amortization_indicator.value===false" [value]="false">No</mat-radio-button>
            <mat-error *ngIf="submitted && negative_amortization_indicator.hasError('required')">
              Negative amortization response is required
            </mat-error>
          </mat-radio-group>
        </div>
      </div>

      <div class="row mt-3" *ngIf="negative_amortization_indicator.value===true">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Negative amortization type</mat-label>
            <mat-select formControlName="negative_amortization_type" placeholder="Select negative amortization type">
              <mat-option *ngFor="let negative_amortization_type of negative_amortization_types" [value]="negative_amortization_type.key">{{negative_amortization_type.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="negative_amortization_type.hasError('required')">
              Negative amortization type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Initial fixed period months count</mat-label>
            <input type="text" appNumberOnly formControlName="fixed_period_months_no" matInput placeholder="Initial fixed period months count" maxlength="3">
            <mat-error *ngIf="fixed_period_months_no.hasError('required')">
              Initial fixed period month is required
            </mat-error>
            <mat-hint>(if applicable)</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
