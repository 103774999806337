import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {ConditionService} from '../../../services/condition.service';
import {Help} from '../../../models/help';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-segment-conditions',
  templateUrl: './view-segment-conditions.component.html',

})
export class ViewSegmentConditionsComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Loan Conditions';
  public conditionService: ConditionService;
  public tranId: number;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.conditionService = this.injector.get(ConditionService);
    if (this.isMobile) {
      this.pageTitle = 'Conditions';
    }
    this.reload();
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.conditions
      });
    }, 0);

  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Add') {
      this.router.navigate(['loan/add-condition']);
      return;
    }
    if (event.type === 'Reload') {
      this.reload(true);
      return;
    }
    super.onEvent(event);
  }

  reload(force?): void {
    if (typeof force === 'undefined') {
      force = true;
    }

    this.reloaded = false;
    this.mode = 'View';
    this.tranId = this.tranService.getCurrentTranSync().id;
    this.items = [];

    this.conditionService.getTranConditions(force, this.tranId)
      .then((conditions) => {
        this.items = conditions;
        this.reloaded = true;
      });
  }
}
