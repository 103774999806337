import {Component, Injector, Input, OnInit} from '@angular/core';
import {Invoice} from '../../../../models/invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceService} from '../../../../services/invoice.service';
import {Help} from '../../../../models/help';
import {AdminInvoiceComponent} from '../invoice/admin-invoice.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-admin-add-invoice',
  templateUrl: '../invoice/admin-edit-invoice.component.html',
})
export class AdminAddInvoiceComponent extends AdminInvoiceComponent implements OnInit {
  @Input() public item: Invoice;
  public invoiceId: number;
  public pageTitle = 'New Invoice';
  public userId: number;
  public fullName: string;

  constructor(public injector: Injector, private router: Router, private route: ActivatedRoute) {
    super(injector);
    this.op = 'Add';
  }

  ngOnInit() {
    super.ngOnInit();
    this.invoiceService = this.injector.get(InvoiceService);
    this.route.queryParams.subscribe((params) => {
      if (params && params['user_id']) {
        this.userId = +params['user_id'];
      }
      if (params && params['full_name']) {
        this.fullName = params['full_name'];
      }
      this.reload();
    });

    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new Invoice({user_id: this.userId});
    this.createFormControls(this.item);
    this.createForm();
    this.reloaded = true;
  }

  create(): void {
    this.invoiceService.createInvoice(this.form.value)
      .then((invoice: Invoice) => {
        FormUtil.genSuccessMessage(this.sharedService, 'Invoice successfully created');
        this.router.navigate(['/admin/view-invoice', invoice.id]);
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create invoice', data);
      });
  }

  cancel(): void {
    this.router.navigate(['/admin/invoices']);
  }
}
