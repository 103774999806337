import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {globals} from '../../../../shared/globals/globals';
import {FormUtil} from '../../../form-util';
import {ProfessionalProfile} from '../../../../models/professional-profile';

@Component({
  selector: 'app-edit-segment-profile',
  templateUrl: './edit-segment-profile.component.html',
})
export class EditSegmentProfileComponent extends CompBaseComponent implements OnInit {
  @Input() public item: ProfessionalProfile;

  // form and form controls
  form: FormGroup;
  company: FormControl;
  office: FormControl;
  phone: FormControl;
  phone_ex: FormControl; // we are not using extensions in the old app
  company_url: FormControl;
  personal_url: FormControl;
  about_me: FormControl;
  about_my_company: FormControl;
  hobbies: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(profile) {
    this.company = new FormControl(profile.company, Validators.minLength(2));
    this.office = new FormControl(profile.office, Validators.minLength(2));
    this.phone = new FormControl(profile.phone, Validators.pattern(globals.pattern.phone));
    this.company_url = new FormControl(profile.company_url, Validators.pattern(globals.pattern.url));
    this.personal_url = new FormControl(profile.personal_url, Validators.pattern(globals.pattern.url));
    this.about_me = new FormControl(profile.about_me, Validators.minLength(2));
    this.about_my_company = new FormControl(profile.about_my_company, Validators.minLength(2));
    this.hobbies = new FormControl(profile.hobbies, Validators.minLength(2));
  }

  createForm() {
    const arr = ['company', 'office', 'phone', 'company_url', 'personal_url', 'about_me', 'about_my_company', 'hobbies'];
    const controls = {};
    for (const field of arr) {
      controls[field] = this[field];
    }
    this.form = new FormGroup(controls);
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const updated = new ProfessionalProfile(this.form.value);
    updated.id = this.item.id;
    this.userService.updateUserProfile(updated)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Branding profile successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update Branding profile', data);
        this.cancel();
      });
  }
}
