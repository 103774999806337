<mat-card *ngIf="reloaded&&currentTran&&isContacts">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="false" [header]="isMobile ? 'Orientation Email' : 'Send Orientation Email'" [help]="false"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <p class="mb-3 mt-3">
        Select recipients:
      </p>

      <div *ngFor="let contact of contacts; let i = index" formArrayName="contacts">
        <mat-checkbox [formControlName]="i">
          <strong>{{contact.full_name}}</strong>
        </mat-checkbox>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="sendOrientationEmail()" [disabled]="!canSend()" class="btn btn-lg btn-primary" type="submit">Send</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="reloaded&&currentTran&&!isContacts">
  <mat-card-title>Send Orientation Email</mat-card-title>

  <app-box *ngIf="reloaded&&!isContacts" [close]="true" [type]="'warning'" class="pt-2">
    <span class="fw-bold">No contacts!</span>
    You can add contacts to your account from
    <span class="fw-bold">Account/Contacts</span>.
    You can add account contacts to your current loan from
    <span class="fw-bold">Current Loan/Access Control</span>
  </app-box>
</mat-card>

<app-box *ngIf="reloaded&&!currentTran" [close]="false" [type]="'warning'" class="pt-2">
  Orientation Email works in the context of the current loan, which you select from <a class="pr-blue-bold" routerLink="/loans/list">My Loans</a>.
</app-box>
