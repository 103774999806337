import {Component, Injector, OnInit} from '@angular/core';
import {Dependent} from '../../../../models/dependent';
import {DependentComponent} from '../dependent/dependent.component';

@Component({
  selector: 'app-add-dependent',
  templateUrl: '../dependent/edit-segment-dependent.component.html',
})
export class AddDependentComponent extends DependentComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new Dependent({}));
    this.createForm();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
