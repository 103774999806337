<div *ngIf="reloaded" class="row">
  <div *ngIf="!isMobile || isOpen" class="col-lg-2 col">
    <ul class="list-ic vertical">
      <li [ngClass]="{active:currentSeg===seg.tab3Instructions}">
        <span></span>
        <a (click)="setSeg($event,seg.tab3Instructions)" href="" id="tab3">Instructions</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.loanInformation}">
        <span></span>
        <a (click)="setSeg($event,seg.loanInformation)" href="" id="loan_information">Loan Info</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.buydown}">
        <span></span>
        <a (click)="setSeg($event,seg.buydown)" href="" id="buydown">Buydown</a>
      </li>
      <li *ngIf="isARMLoan" [ngClass]="{active:currentSeg===seg.armData}">
        <span></span>
        <a (click)="setSeg($event,seg.armData)" href="" id="arm_data">ARM Data</a>
      </li>
      <li *ngIf="isConstructionOrRefinanceLoan" [ngClass]="{active:currentSeg===seg.loanData}">
        <span></span>
        <a (click)="setSeg($event,seg.loanData)" href="" id="loan_data">Loan Data</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.loanCharacteristics}">
        <span></span>
        <a (click)="setSeg($event,seg.loanCharacteristics)" href="" id="loan_characteristics">Loan Char</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.productCharacteristics}">
        <span></span>
        <a (click)="setSeg($event,seg.productCharacteristics)" href="" id="product_characteristics">Product Char</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.productIdentification}">
        <span></span>
        <a (click)="setSeg($event,seg.productIdentification)" href="" id="product_identification">Loan Product</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.credits}">
        <span></span>
        <a (click)="setSeg($event,seg.credits)" href="" id="credits">Credits</a>
      </li>
      <li *ngIf="isPurchaseLoan" [ngClass]="{active:currentSeg===seg.concessions}">
        <span></span>
        <a (click)="setSeg($event,seg.concessions)" href="" id="concessions">Concessions</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.details}">
        <span></span>
        <a (click)="setSeg($event,seg.details)" href="" id="details">Details</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.transmittalData}">
        <span></span>
        <a (click)="setSeg($event,seg.transmittalData)" href="" id="transmittal_data">Appraisal</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.envelopeHeader}">
        <span></span>
        <a (click)="setSeg($event,seg.envelopeHeader)" href="" id="envelope_header">FNMA ID</a>
      </li>
      <li *ngIf="isConstructionLoan" [ngClass]="{active:currentSeg===seg.construction}">
        <span></span>
        <a (click)="setSeg($event,seg.construction)" href="" id="lo_construction">Construction</a>
      </li>
      <li [ngClass]="{active:currentSeg===seg.loInformation}">
        <span></span>
        <a (click)="setSeg($event,seg.loInformation)" href="" id="lo_information">Originator Info</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!isMobile || !isOpen" class="col-lg-10 col">
    <div [ngSwitch]="currentSeg">
      <app-instructions  *ngSwitchCase="seg.tab3Instructions" [tab]="'tab3'">
      </app-instructions>
      <app-view-envelope-header (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.envelopeHeader" [doc]="doc">
      </app-view-envelope-header>
      <app-view-loan-data (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.loanData" [doc]="doc">
      </app-view-loan-data>
      <app-view-loan-information (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.loanInformation" [doc]="doc">
      </app-view-loan-information>
      <app-view-buydown (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.buydown" [doc]="doc">
      </app-view-buydown>
      <app-view-segment-credits (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.credits" [doc]="doc">
      </app-view-segment-credits>
      <app-view-details (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.details" [doc]="doc">
      </app-view-details>
      <app-view-product-identification (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.productIdentification" [doc]="doc">
      </app-view-product-identification>
      <app-view-product-characteristics (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.productCharacteristics" [doc]="doc">
      </app-view-product-characteristics>
      <app-view-loan-characteristics (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.loanCharacteristics" [doc]="doc">
      </app-view-loan-characteristics>
      <app-view-arm-data (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.armData" [doc]="doc">
      </app-view-arm-data>
      <app-view-transmittal-data (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.transmittalData" [doc]="doc">
      </app-view-transmittal-data>
      <app-view-lo-information (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.loInformation" [doc]="doc">
      </app-view-lo-information>
      <app-view-segment-concessions (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.concessions" [doc]="doc">
      </app-view-segment-concessions>
      <app-view-construction (eventEmitted)="onEvent($event)" *ngSwitchCase="seg.construction" [doc]="doc">
      </app-view-construction>
    </div>

    <app-popup-compliance-container style="width:100%;" *ngIf="false"></app-popup-compliance-container>
  </div>

  <div *ngIf="isMobile&&isOpen" class="col">
    <app-box [close]="false" [type]="'success'" class="mt-2">
      Click any menu-item to view/edit it.
    </app-box>
  </div>
</div>
