import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {globals} from '../../../../shared/globals/globals';
import {Support} from '../../../../models/support';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-undrawn',
  template: '',
})
export class SupportComponent extends CompBaseComponent implements OnInit {
  @Input() item: Support;
  public support_types_ex = Support.support_types_ex;

  // form fields
  public form: FormGroup;
  public support_type_ex: FormControl;
  public support_type_description: FormControl;
  public monthly_payment_amount: FormControl;
  public months_left: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'supports';
    this.section = this.applicant;
  }

  createFormControls(item: Support): void {
    this.support_type_ex = new FormControl(item.support_type_ex, AppValidators.present);
    this.support_type_description = new FormControl(item.support_type_description);
    this.monthly_payment_amount = new FormControl(item.monthly_payment_amount,
      Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.decimalTwo)]));
    this.months_left = new FormControl(item.months_left, Validators.pattern(globals.pattern.numberOnly));
  }

  createForm(): void {
    this.form = new FormGroup({
      support_type_ex: this.support_type_ex,
      support_type_description: this.support_type_description,
      monthly_payment_amount: this.monthly_payment_amount,
      months_left: this.months_left
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Support(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Support(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
