import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Document} from '../models/document';
import {TranService} from './tran.service';

@Injectable()
export class CreditReportService {

  constructor(private httpService: HttpService, private tranService: TranService) {
  }

  refreshDocument(exiting_order_id: string, payload): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`credit_reports/refresh/${exiting_order_id}`, payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  createDocument(payload): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post('credit_reports/create', payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  updateCreditReport(creditDocId: number, loanAppDocId: number, tranId: number): Promise<Document> {
    const payload = {
      loan_app_doc_id: loanAppDocId,
      tran_id: tranId
    };
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`credit_reports/update_credit_report/${creditDocId}`, payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  generateCreditPDF(creditDocId: number, tranId: number): Promise<Document> {
    const payload = {
      tran_id: tranId
    };
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.post(`credit_reports/generate_credit_pdf/${creditDocId}`, payload, false)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((tran) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  updateDocument(docId: number, payload: any): Promise<Document> {
    let doc = null;
    return new Promise((resolve, reject) => {
      this.httpService.put('credit_reports/' + docId, payload)
        .then((data) => {
          doc = data;
          return this.tranService.getCurrentTran(true);
        })
        .then((data) => {
          resolve(new Document(doc));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  deleteDocument(documentId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('credit_reports/' + documentId)
        .then(() => {
          return this.tranService.getCurrentTran(true);
        })
        .then(() => {
          resolve(true);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
