import moment from 'moment';
import {globals} from '../shared/globals/globals';
import {DeviceService} from '../services/device.service';

export class ModelBase {
  public static urlBase = globals.getUrlBase();
  public static partyTable = {
    b1: 'Borrower',
    b2: 'Co-Borrower',
    b3: 'Borrower 3',
    b4: 'Borrower 4',
    e: 'Entity'
  };

  public static SUBSCRIPTION_TERM = {
    NONE: 0,
    MONTHLY: 1,
    QUARTERLY: 3,
    HALF_YEARLY: 6,
    YEARLY: 12
  }

  public static SERVICES = {
    NONE: 0,
    POLOS: 1,
    EZMCR: 2,
    EZUPLOADS: 4,
    BORROWER: 8,
    ANCILLARY: 16,
  };

  public static subscription_terms = [
    {value: 'Monthly', key: ModelBase.SUBSCRIPTION_TERM.MONTHLY},
    {value: 'Quarterly', key: ModelBase.SUBSCRIPTION_TERM.QUARTERLY},
    {value: 'Half Yearly', key: ModelBase.SUBSCRIPTION_TERM.HALF_YEARLY},
    {value: 'Yearly', key: ModelBase.SUBSCRIPTION_TERM.YEARLY},
  ];

  public static getServices(services: number): string {
    let servicesOut = [];
    if ((services & ModelBase.SERVICES.POLOS) > 0) {
      servicesOut.push('POLOS')
    }
    if ((services & ModelBase.SERVICES.BORROWER) > 0) {
      servicesOut.push('Borrower')
    }
    if ((services & ModelBase.SERVICES.EZMCR) > 0) {
      servicesOut.push('MCR Wizard')
    }
    if ((services & ModelBase.SERVICES.EZUPLOADS) > 0) {
      servicesOut.push('ezUploads')
    }
    return servicesOut.join(', ');
  }

  public static services = [
    {value: 'POLOS + MCR Wizard + ezUploads', key: ModelBase.SERVICES.POLOS + ModelBase.SERVICES.EZMCR + ModelBase.SERVICES.EZUPLOADS},
    {value: 'MCR Wizard + ezUploads', key: ModelBase.SERVICES.EZMCR + ModelBase.SERVICES.EZUPLOADS},
    {value: 'ezUploads', key: ModelBase.SERVICES.EZUPLOADS},
  ];

  public static borrowerServices = [
    {value: 'ezUploads', key: ModelBase.SERVICES.EZUPLOADS},
  ];

  public static ENTERED_BY = {
    SYSTEM: 'System',
  }

  public static ORDER_TYPE = {
    SUBSCRIPTION: 1,
    SERVICE: 2
  }

  public static UAB_STATUS = {
    NONE: 0,
    OK: 1,
    IN_PROCESS: 2,
    FULFILLED: 4,
    OUTSTANDING: 8,
    PAID: 16,
    PAST_DUE: 32,
    CANCELLED: 64,
    ERROR: 128,
    MARKED_PAID: 256
  }

  public static getStatus(status): string {
    switch (status) {
      case ModelBase.UAB_STATUS.NONE:
        return 'Not Set';
      case ModelBase.UAB_STATUS.OK:
        return 'OK';
      case ModelBase.UAB_STATUS.IN_PROCESS:
        return 'In Process';
      case ModelBase.UAB_STATUS.FULFILLED:
        return 'Fulfilled';
      case ModelBase.UAB_STATUS.OUTSTANDING:
        return 'Outstanding';
      case ModelBase.UAB_STATUS.ERROR:
        return 'In Error';
      case ModelBase.UAB_STATUS.PAID:
        return 'Paid';
      case ModelBase.UAB_STATUS.PAST_DUE:
        return 'Past Due';
      case ModelBase.UAB_STATUS.CANCELLED:
        return 'Cancelled';
      case ModelBase.UAB_STATUS.MARKED_PAID:
        return 'Marked Paid';
      default:
        return 'Unknown';
    }
  }

  public static UID_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
  }

  public static ROLES = {
    NONE: 0,
    ADMIN: 1,
    LOAN_OFFICER: 2 ** 4,
    PROCESSOR: 2 ** 5,
    LOAN_ASSISTANT: 2 ** 7,
    AGENT: 2 ** 6,
    PRESENTER: 2 ** 10,
    BORROWER: 2 ** 24,
    OTHER: 2 ** 25,
    AUX: 2 ** 29,
    REAL_ESTATE_AGENT: 2 ** 30,
  };

  public static ORG_ROLES = {
    NONE: 0,
    ADMIN: 1,
  };

  public static DTYPE = {
    NONE: 0,
    IMAGE: 4,
    PDF: 8,
    LOAN_APP: 64,
    GEN_PDF: 128,
    CREDIT_PDF: 129,
    MISMO_XML: 255,
    CREDIT: 256,
    CREDIT_XML: 257,
    ANON_PDF: 512,
    ANON_IMAGE: 513,
    WS: 1024
  };

  public static org_roles = [
    {
      key: ModelBase.ORG_ROLES.NONE,
      value: 'User'
    },
    {
      key: ModelBase.ORG_ROLES.ADMIN,
      value: 'Admin'
    },
  ];

  public static ORG_TYPES = {
    BROKER: 1,
    AUX: 2,
  };

  public static ALL_ROLES = Object.values(ModelBase.ROLES).reduce((mem, elt) => mem + elt, 0);

  public static timeFrames: Array<any> = [];

  public static PERMISSION = {
    MIN: 0,
    NONE: 0,
    X: 1,
    W: 2,
    R: 4,
    RW: 6,
    RWX: 7,
    DIR: 8
  };

  public static getSubscriptionTerm(subTerm: number): string {
    switch (subTerm) {
      case ModelBase.SUBSCRIPTION_TERM.NONE:
        return 'Not Set';
      case ModelBase.SUBSCRIPTION_TERM.MONTHLY:
        return 'Monthly';
      case ModelBase.SUBSCRIPTION_TERM.QUARTERLY:
        return 'Quarterly';
      case ModelBase.SUBSCRIPTION_TERM.HALF_YEARLY:
        return 'Half-Yearly';
      case ModelBase.SUBSCRIPTION_TERM.YEARLY:
        return 'Yearly';
      default:
        return 'N/A';
    }
  }

  public static getUABStatus(accountStatus: number): string {
    switch (accountStatus) {
      case ModelBase.UAB_STATUS.PAID:
        return 'Paid';
      case ModelBase.UAB_STATUS.MARKED_PAID:
        return 'Marked Paid';
      case ModelBase.UAB_STATUS.NONE:
        return 'Unknown';
      case ModelBase.UAB_STATUS.OK:
        return 'OK';
      case ModelBase.UAB_STATUS.OUTSTANDING:
        return 'Outstanding';
      case ModelBase.UAB_STATUS.PAST_DUE:
        return 'Past Due';
      case ModelBase.UAB_STATUS.ERROR:
        return 'No Subscription';
      default:
        return 'N/A';
    }
  }

  public static getOrgType(orgType: number): string {
    switch (orgType) {
      case ModelBase.ORG_TYPES.BROKER:
        return 'Broker';
      case ModelBase.ORG_TYPES.AUX:
        return 'Auxiliary';
      default:
        return 'Unknown';
    }
  }

  public static toPermissionString(permission): string {
    if (!permission) {
      return 'N/A';
    }

    const isMobile = DeviceService.isMobile;
    switch (permission & ~ModelBase.PERMISSION.DIR) {
      case 0:
        return 'None';
      case 2:
        return isMobile ? 'Up' : 'Update';
      case 4:
        return 'Read';
      case 6:
        return isMobile ? 'Rd/Up' : 'Read/Update';
      case 7:
        return 'Owner';
    }
  }

  public static getRolesString(inRoles: number): string {
    const roles = [];

    if (inRoles == ModelBase.ROLES.NONE) {
      roles.push('Not Assigned');
    }
    if (inRoles & ModelBase.ROLES.AGENT) {
      roles.push('Agent');
    }
    if (inRoles & ModelBase.ROLES.ADMIN) {
      roles.push('Admin');
    }
    if (inRoles & ModelBase.ROLES.PRESENTER) {
      roles.push('Presenter');
    }
    if (inRoles & ModelBase.ROLES.LOAN_OFFICER) {
      roles.push('Loan Officer');
    }
    if (inRoles & ModelBase.ROLES.LOAN_ASSISTANT) {
      roles.push('Loan Assistant');
    }
    if (inRoles & ModelBase.ROLES.BORROWER) {
      roles.push('Borrower');
    }
    if (inRoles & ModelBase.ROLES.PROCESSOR) {
      roles.push('Processor');
    }
    if (inRoles & ModelBase.ROLES.OTHER) {
      roles.push('Other');
    }
    if (inRoles & ModelBase.ROLES.REAL_ESTATE_AGENT) {
      roles.push('Real Estate Agent');
    }
    if (inRoles & ModelBase.ROLES.AUX) {
      roles.push('Unregistered');
    }

    return roles.join(', ');
  }

  public static getRolesArray(inRoles: number): Array<number> {
    const roles = [];

    if (inRoles & ModelBase.ROLES.AGENT) {
      roles.push(ModelBase.ROLES.AGENT);
    }
    if (inRoles & ModelBase.ROLES.ADMIN) {
      roles.push(ModelBase.ROLES.ADMIN);
    }
    if (inRoles & ModelBase.ROLES.LOAN_OFFICER) {
      roles.push(ModelBase.ROLES.LOAN_OFFICER);
    }
    if (inRoles & ModelBase.ROLES.BORROWER) {
      roles.push(ModelBase.ROLES.BORROWER);
    }
    if (inRoles & ModelBase.ROLES.PROCESSOR) {
      roles.push(ModelBase.ROLES.PROCESSOR);
    }
    if (inRoles & ModelBase.ROLES.LOAN_ASSISTANT) {
      roles.push(ModelBase.ROLES.LOAN_ASSISTANT);
    }
    if (inRoles & ModelBase.ROLES.REAL_ESTATE_AGENT) {
      roles.push(ModelBase.ROLES.REAL_ESTATE_AGENT);
    }
    if (inRoles & ModelBase.ROLES.AUX) {
      roles.push(ModelBase.ROLES.AUX);
    }
    if (inRoles & ModelBase.ROLES.PRESENTER) {
      roles.push(ModelBase.ROLES.PRESENTER);
    }
    if (inRoles == ModelBase.ROLES.OTHER) {
      roles.push(ModelBase.ROLES.OTHER);
    }
    return roles;
  }

  public static getServicesString(inServices: number): string {
    return ModelBase.getServicesArray(inServices).join(', ');
  }

  public static getServicesArray(inServices: number): Array<string> {
    const services: Array<string> = [];

    if ((inServices & ModelBase.SERVICES.BORROWER) > 0) {
      services.push('Borrower');
    }
    if ((inServices & ModelBase.SERVICES.ANCILLARY) > 0) {
      services.push('Ancillary');
    }
    if ((inServices & ModelBase.SERVICES.EZUPLOADS) > 0) {
      services.push('ezUploads');
    }
    if ((inServices & ModelBase.SERVICES.EZMCR) > 0) {
      services.push('MCR Wizard');
    }
    if ((inServices & ModelBase.SERVICES.POLOS) > 0) {
      services.push('POLOS');
    }
    if (services.length == 0) {
      services.push('None');
    }
    return services;
  }

  public static getMissingServices(inServices: number): Array<string> {
    const missingServices: Array<any> = [];

    if ((inServices & ModelBase.SERVICES.EZUPLOADS) == 0) {
      missingServices.push({
        service: ModelBase.SERVICES.EZUPLOADS,
        text: 'ezUploads'
      });
    }
    if ((inServices & ModelBase.SERVICES.EZMCR) == 0) {
      missingServices.push({
        service: ModelBase.SERVICES.EZMCR,
        text: 'MCR Wizard'
      });
    }
    if ((inServices & ModelBase.SERVICES.POLOS) == 0) {
      missingServices.push({
        service: ModelBase.SERVICES.POLOS,
        text: 'POLOS'
      });
    }
    return missingServices;
  }

  public static isLoanProfessional(roles: number): boolean {
    return ModelBase.isLoanOfficer(roles) || ModelBase.isProcessor(roles) || ModelBase.isLoanAssistant(roles) || ModelBase.isAuxiliary(roles);
  }

  public static hasMCRService(services: number): boolean {
    return ((services & ModelBase.SERVICES.EZMCR) > 0);
  }

  public static hasNoService(services): boolean {
    return !services
  }

  public static hasEzUploadsService(services: number): boolean {
    return ((services & ModelBase.SERVICES.EZUPLOADS) > 0);
  }

  public static hasAncillaryService(services: number): boolean {
    return ((services & ModelBase.SERVICES.ANCILLARY) > 0);
  }

  public static hasBorrowerService(services: number): boolean {
    return ((services & ModelBase.SERVICES.BORROWER) > 0);
  }

  public static hasPOLOSSErvice(services: number): boolean {
    return ((services & ModelBase.SERVICES.POLOS) > 0);
  }

  public static isAdmin(roles: number): boolean {
    return ((roles & ModelBase.ROLES.ADMIN) > 0);
  }

  public static isRealEstateAgent(roles: number): boolean {
    return ((roles & ModelBase.ROLES.REAL_ESTATE_AGENT) > 0);
  }

  public static isAuxiliary(roles: number): boolean {
    return ((roles & ModelBase.ROLES.AUX) > 0);
  }

  public static isAgent(roles: number): boolean {
    return ((roles & ModelBase.ROLES.AGENT) > 0);
  }

  public static isLoanOfficer(roles: number): boolean {
    return ((roles & ModelBase.ROLES.LOAN_OFFICER) > 0);
  }

  public static isLoanAssistant(roles: number): boolean {
    return ((roles & ModelBase.ROLES.LOAN_ASSISTANT) > 0);
  }

  public static isProcessor(roles: number): boolean {
    return ((roles & ModelBase.ROLES.PROCESSOR) > 0);
  }

  public static isBorrower(roles: number): boolean {
    return ((roles & ModelBase.ROLES.BORROWER) > 0);
  }

  public static isPresenter(roles: number): boolean {
    return ((roles & ModelBase.ROLES.PRESENTER) > 0);
  }

  public static isOther(roles: number): boolean {
    return ((roles & ModelBase.ROLES.OTHER) > 0);
  }

  public static initialize() {
    for (const ampm of ['AM', 'PM']) {
      let i = 0;
      while (i < 12) {
        let v: string;
        const hour = i === 0 ? 12 : i;
        for (const k of [0, 1]) {
          if (k === 0) {
            v = `${hour}:00 ${ampm}`;
          } else {
            v = `${hour}:30 ${ampm}`;
          }
          ModelBase.timeFrames.push({
            key: v,
            value: v
          });
        }
        i++;
      }
    }
  }

  constructor() {
  }

  static toNumber(val: number | string): number {
    if (typeof (val) === 'string') {
      return val.trim() === '' ? null : +val;
    }
    return val;
  }

  static isTimeValid(val: string): boolean {
    if (typeof (val) === 'undefined' || val === null || val === '') {
      return true;
    }
    const time = val.toUpperCase();
    if (time.indexOf('AM') >= 0 || time.indexOf('PM') >= 0) {
      if (!moment(time, 'hh:mm A').isValid()) {
        return false;
      }
    } else if (!moment(time, 'HH:mm').isValid()) {
      return false;
    }

    return true;
  }

  static getTimeString(val: string): string {
    if (typeof (val) === 'undefined' || val === null || val === '') {
      return '';
    }
    const time = val.toUpperCase();
    if (time.indexOf('AM') >= 0 || time.indexOf('PM') >= 0) {
      return moment(time, 'hh:mm A').format('HH:mm');
    }

    return moment(time, 'HH:mm').format('HH:mm');
  }

  static toServerDecimal(val: number | string, precision?: number): string {
    const digits = precision || 2;
    if (typeof (val) === 'string' && val.trim() !== '') {
      return (+val).toFixed(digits);
    }
    if (typeof (val) === 'number') {
      return val.toFixed(digits);
    }
    return null;
  }

  static isEmpty(value): boolean {
    return (
      // null or undefined
      (value == null) ||
      // has length and it's zero
      (value.hasOwnProperty('length') && value.length === 0) ||
      // is an Object and has no keys
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  // Important: this method return 'N' for null input
  static toServerBoolean(val: boolean | string): string {
    if (val === true || val === 'true' || val === 'True' || val === 'Yes' || val === 'yes') {
      return 'Y';
    }
    if (val === null || val === false || val === 'false' || val === 'False' || val === 'No' || val === 'no') {
      return 'N';
    }
    return null;
  }

  static toServerExplicitBoolean(val: boolean | string): string {
    if (val === true || val === 'true' || val === 'True' || val === 'Yes' || val === 'yes') {
      return 'Y';
    }
    if (val === false || val === 'false' || val === 'False' || val === 'No' || val === 'no') {
      return 'N';
    }
  }

  static getBooleanProp(val): boolean {
    if (val === 'Y' || val === 'N') {
      return val === 'Y';
    }
    if (typeof (val) === 'boolean') {
      return val;
    }
    return null;
  }

  getPhoneProp(prop: string, object: any): string {
    let val = object[prop];
    if (!val || !val.trim().length) {
      return;
    }
    val = val.replace(/\D/g, '');
    return globals.formatPhone(val);
  }

  getDateProp(prop: string, object: any): Date {
    const val = object[prop];
    if (typeof (val) === 'object') {
      return object[prop];
    } else if (typeof (val) === 'string' && val.length) {
      const dobMoment = moment(val, 'YYYYMMDD');
      return dobMoment.toDate();
    }
    return null;
  }

  getSSNProp(prop: string, object: any): string {
    const val = object[prop];
    if (typeof (val) === 'string' && val.length === 9) {
      return `${val.substr(0, 3)}-${val.substr(3, 2)}-${val.substr(5, 4)}`;
    }
    return val;
  }

  getNumberProp(prop: string, object: any): string {
    const val = object[prop];
    if (typeof (val) === 'string') {
      return val.trim() === '' ? null : val.trim();
    }
    return val;
  }

  getExplicitNumberProp(prop: string | number, object: any): number {
    const val = object[prop];
    if (typeof (val) === 'string') {
      return val.trim() === '' ? null : +val;
    }
    if (typeof (val) === 'number') {
      return val;
    }
    return val;
  }

  getBooleanProp(prop: string, object: any): boolean {
    const val = object[prop];
    if (val === 'Y' || val === 'N') {
      return val === 'Y';
    }
    if (typeof (val) === 'boolean') {
      return val;
    }
    return null;
  }

  toNumber(val: number | string): number {
    return ModelBase.toNumber(val);
  }

  toServerPercentThree(val: number | string): string {
    return ModelBase.toServerDecimal(val, 3);
  }

  toServerPercentTwo(val: number | string): string {
    return ModelBase.toServerDecimal(val, 2);
  }

  toServerDecimal(val: number | string, precision?: number): string {
    return ModelBase.toServerDecimal(val, precision);
  }

  toServerString(val: number | string): string {
    if (typeof (val) === 'string' && val.trim() !== '') {
      return val.trim();
    }
    if (typeof (val) === 'number') {
      return val.toString();
    }
    return null;
  }

  toServerDate(val: Date): string {
    return val ? moment(val).format('YYYYMMDD') : null;
  }

  toServerPhone(val: string): string {
    return val ? val.replace(/[^0-9]/g, '') : null;
  }

  toServerSSN(val: string): string {
    return val ? val.replace(/[^0-9]/g, '') : null;
  }

  toJSDate(val: string): Date {
    return val ? moment(val).toDate() : null;
  }

  // todo: do we need to add "Unit #"
  getFullAddress(address: string, address2: string, city: string, state: string, zip: string, zip_four: string, country?: string): string {
    let partAddress = (address || '');
    if (address2 && address2.trim().length) {
      partAddress += (', Unit #' + address2.trim());
    }
    let partCity = city || '';
    let partState = `${state || ''} ${zip || ''}${zip_four ? ('-' + zip_four) : ''}`;
    const parts = [];
    partAddress = partAddress.trim();
    partCity = partCity.trim();
    partState = partState.trim();
    if (partAddress) {
      parts.push(partAddress);
    }
    if (partCity) {
      parts.push(partCity);
    }
    if (partState) {
      parts.push(partState);
    }

    if (country) {
      if (country === 'US') {
        parts.push('USA');
      } else {
        parts.push(country);
      }
    }

    const fullAddress = parts.join(', ');
    return fullAddress.replace(/[ ]{2}/g, ' ');
  }

  getAbsolutePath(path): string {
    return ModelBase.urlBase + (path && path.startsWith('/') ? path : ('/' + path));
  }
}

// call the static initializer
ModelBase.initialize();
