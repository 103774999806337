import {ModelBase} from './model-base';

export class UserAccount extends ModelBase {
  id: number;
  user_id: number;
  subscription_term: number;
  status: number;
  expires_on: string;
  created_at: Date;
  updated_at: Date;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'created_at' || prop === 'updated_at') {
        this[prop] = this.toJSDate(object[prop]);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getAccountStatus(): string {
    return ModelBase.getUABStatus(this.status);
  }

  getSubscriptionTerm(): string {
    return ModelBase.getSubscriptionTerm(this.subscription_term);
  }
}
