<div *ngIf="canUpdateDocument" class="row">
  <div class="col">
    <app-line-header (eventEmitted)="onEvent($event)" [enable]="true" [op]="'edit'" class="hand-pointer mb-0" title="Edit"></app-line-header>
  </div>
</div>

<div class="row">
  <div class="col">
    <div [ngSwitch]="item.declaration_type_code">
      <div *ngSwitchCase="'01'" class="fw-bold">
        {{declaration_text['01']}}
      </div>

      <div *ngSwitchCase="'98'" class="fw-bold">
        {{declaration_text['98']}}
      </div>

      <div *ngSwitchCase="'02'" class="fw-bold">
        {{declaration_text['02']}}
      </div>

      <div *ngSwitchCase="'03'" class="fw-bold">
        {{declaration_text['03']}}
      </div>

      <div *ngSwitchCase="'05'" class="fw-bold">
        {{declaration_text['05']}}
      </div>

      <div *ngSwitchCase="'99'" class="fw-bold">
        {{declaration_text['99']}}
      </div>

      <div *ngSwitchCase="'91'" class="fw-bold">
        {{declaration_text['91']}}
      </div>

      <div *ngSwitchCase="'96'" class="fw-bold">
        {{declaration_text['96']}}
      </div>

      <div *ngSwitchCase="94" class="fw-bold">
        {{declaration_text['94']}}
      </div>

      <div *ngSwitchCase="'06'" class="fw-bold">
        {{declaration_text['06']}}
      </div>

      <div *ngSwitchCase="'04'" class="fw-bold">
        {{declaration_text['04']}}
      </div>

      <div *ngSwitchCase="'93'" class="fw-bold">
        {{declaration_text['93']}}
      </div>

      <div *ngSwitchCase="'92'" class="fw-bold">
        {{declaration_text['92']}}
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <span *ngIf="item.declaration_explanation&&item.declaration_explanation.length">{{item.declaration_explanation}}</span>
    <span *ngIf="!(item.declaration_explanation&&item.declaration_explanation.length)" class="font-italic text-muted">(need to provide explanation)</span>
  </div>
</div>
