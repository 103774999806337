<div class="pr-font-large">
  <div class="row mt-3  ms-1 me-1">
    <div class="col-lg-4 features">
      <mat-card>
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">home</i>
          </div>
          Loan Application
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS supports all mortgage-related MISMO 3.4 fields.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              For interoperability with the lender or other LOS systems, the Loan Application data can be imported from or exported to a MISMO 3.4 file.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The Loan Application displays to the borrower and loan officer only applicable and role-specific data segments.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan officer can add comments to any data segment to help the borrower with data input.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Typically, a borrower can complete the Loan Application in 15 minutes after a brief interview with the loan officer.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              An error detection facility discovers errors early.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The Loan Application streamlines data entry by removing inapplicable data segments and fields.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The Loan Application computes current or projected financial ratios.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">group_work</i>
          </div>
          Compliance & Workflow
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The Compliance Engine is integrated with the workflow.
              It prompts the loan officer with due-by dates.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The workflow is defined by tasks that are updated by the loan officer.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan officer can generate a compliance report for future dates.
              For example, will I be compliant tomorrow if I cannot finish the task today?
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Compliance durations are computed accurately based on calendar, business, or rescission days.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              When the loan officer updates the workflow, they can send progress notifications to stakeholders.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Any loan party can access the loan timeline.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">attachment</i>
          </div>
          Documents & Images
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The URLA (the "new 1003 Form") is produced accurately from the loan application.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Other loan documents can also be generated accurately from the loan data.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan's document repository is organized by default folders.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan officer can create new folders in addition to stock folders.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan officer and borrower can upload/download documents and images to/from the loan folders.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan officer can download all loan documents in a single compressed file.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row mt-3  ms-1 me-1">
    <div class="col-lg-4 features">
      <mat-card>
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">verified_user</i>
          </div>
          MCR
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The organization's MCR can be exported to NMLS-compliant XML.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The MCR's XML supports multiple states and can be uploaded to NMLS in a single operation.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS's support of MCR is extensive including changes in the loan amount when the loan
              spans multiple quarters.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS performs cross-reference verifications on the MCR report.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">sms</i>
          </div>
          Communication
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Secure system messages are available to all loan parties.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Messages are also conveniently sent as emails to the recipients.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Every user can send text messages to their contacts.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS notifies stakeholders whenever a milestone was reached or a document was uploaded.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">dashboard</i>
          </div>
          Dashboards
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan dashboard displays loan highlights including compliance status.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The pipeline dashboard displays highlights for each active loan including the current task,
              compliance status, and messages.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row mt-3  ms-1 me-1">
    <div class="col-lg-4 features">
      <mat-card>
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">help</i>
          </div>
          Easy & Helpful
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The loan application is borrower-centric. It restricts borrower access only to
              the data they need to provide.
            </li>

            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS helps the user to enter valid data by dynamically controlling and verifying data entry.
            </li>

            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Contextual help is available across the entire app.
            </li>

            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS has a clean, powerful, and wicked-fast user interface.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">stay_current_portrait</i>
          </div>
          Mobile Access
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The mobile's user interface is similar to the computer's.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The mobile user can upload document images directly from their mobile device.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              All operations that are available on the computer can be performed from the mobile device.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Both iPhones and Android devices are supported.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4 features">
      <mat-card class="h-100">
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">check_box</i>
          </div>
          Data Integrity
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              POLOS data is backed up daily.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The database is dynamically replicated. This provides up-to-date disaster recovery in the case of a system failure.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              The system has web access for providing speedy administrative responses to loan officer needs.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row mt-3 ms-1 me-1">
    <div class="col-lg-4">
    </div>

    <div class="col-lg-4 features">
      <mat-card>
        <mat-card-title>
          <div class="d-inline">
            <i class="material-icons">security</i>
          </div>
          Security
        </mat-card-title>

        <mat-card-content>
          <ul class="list-group">
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Loan officers control who has access to the loan and its documents.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Real estate agents are notified of the loan progress without giving them access to the loan data.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Security is enforced throughout the entire web stack.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Each command is checked for the appropriate authorization level.
            </li>
            <li class="list-group-item">
              <i class="fa fa-check pr-dark-blue mx-2"></i>
              Loan applications, documents and images are stored encrypted.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-4">
    </div>
  </div>

  <div class="row mt-3 ms-3 me-3">
    <div class="col-12">
      <app-site-foot-banner></app-site-foot-banner>
    </div>
  </div>
</div>
