import {Component, Injector, Input, OnInit} from '@angular/core';
import {Gift} from '../../../../models/gift';
import {GiftComponent} from '../gift/gift.component';

@Component({
  selector: 'app-view-segment-gift',
  templateUrl: './view-segment-gift.component.html',

})
export class ViewSegmentGiftComponent extends GiftComponent implements OnInit {
  @Input() item: Gift;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
