import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NoteService} from '../../../services/note.service';
import {Note} from '../../../models/note';
import {Tran} from '../../../models/tran';
import {Help} from '../../../models/help';
import {CompBaseComponent} from '../../comp-base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-notes',
  templateUrl: './view-notes.component.html',
})
export class ViewNotesComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public pageTitle: string;
  public noteService: NoteService;
  public notes: Array<Note>;

  constructor(public injector: Injector, public router: Router) {
    super(injector);
    this.pageTitle = 'Notes';
  }

  ngOnInit() {
    super.ngOnInit();
    this.noteService = this.injector.get(NoteService);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.note
      });
    }, 0);
    this.reload(true);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Add') {
      this.router.navigate(['loan/add-note', this.tran.id]);
      return;
    }
    if (event.type === 'Reload') {
      this.reload(true);
      return;
    }

    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.tranService.getCurrentTran()
      .then((tran: Tran) => {
        this.tran = tran;
        return this.noteService.getPosts(force, tran.id);
      })
      .then((posts) => {
        this.mode = 'View';
        this.notes = posts;
        this.reloaded = true;
      });
  }
}
