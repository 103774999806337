<div *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canCreateContact" [isCard]="false" [header]="'Contacts'" [help]="false" [op]="'add'"></app-segment-header>
  </mat-card-title>

  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col">
        <mat-checkbox [hidden]="!tran" (change)="onFilterChange()" formControlName="tranOnly">Only current loan contacts</mat-checkbox>
        &nbsp;
        <mat-checkbox (change)="onFilterChange()" formControlName="displayHidden">Display hidden contacts</mat-checkbox>
      </div>
    </div>
  </form>

  <app-add-contact (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'"></app-add-contact>

  <div *ngFor="let item of filteredItems">
    <app-view-contact (eventEmitted)="onEvent($event)" [item]="item"></app-view-contact>
  </div>

  <app-box *ngIf="!isContacts" [close]="false" [type]="'warning'" class="pt-2">
    <strong>No contacts!</strong>
  </app-box>
</div>
