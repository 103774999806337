<mat-card class="mt-1 mb-2">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-10">
        <app-line-header *ngIf="canUpdateTransaction" (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
        <div class="fw-bold">{{note.title}}</div>
        <div class="font-italic float-left by">By: {{note.by}}, {{note.updated_at | date:'MM/dd/yyyy'}}</div>
      </div>

      <div class="col-lg-2 text-end" *ngIf="canUpdateTransaction">
        <a (click)="delete()"><i class="pr-font-larger text-danger fas fa-trash"></i></a>
      </div>
    </div>

    <div class="row mt-2">
      <app-read-more [textLength]=128 [text]="note.body" class="col"></app-read-more>
    </div>
  </mat-card-content>
</mat-card>
