<mat-card class="mt-2 mb-1">
  <mat-card-title>Loan Product</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>Product description</mat-label>
            <input type="text" appAutoFocus formControlName="product_description" matInput placeholder="Product description" maxlength="{{MAX_LEN.S_DESC}}">
            <mat-error *ngIf="product_description.hasError('required')">
              Product description is required
            </mat-error>
            <mat-hint>Required, if applicable</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Product plan number</mat-label>
            <mat-select formControlName="product_plan_number" placeholder="Select product plan number">
              <mat-option *ngFor="let product_plan_number of product_plan_numbers" [value]="product_plan_number.key">{{product_plan_number.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="product_plan_number.hasError('required')">
              Product plan number is required for ARM loans
            </mat-error>
            <mat-hint>Product plan number is required for Fannie Mae, FHA and VA ARM loans</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
