<div *ngIf="reloaded">
  <ul class="nav nav-tabs" id="compliance_tabs">
    <li class="nav-item">
      <a (click)="setSection($event,section.complianceDash)" [ngClass]="{active:currentSection===section.complianceDash}"
        [attr.aria-selected]="currentSection===section.complianceDash" class="nav-link" data-toggle="tab" id="compliance_dash_tab">
        Compliance
      </a>
    </li>

    <li class="nav-item">
      <a (click)="setSection($event,section.workflowDash)" [ngClass]="{active:currentSection===section.workflowDash}"
        [attr.aria-selected]="currentSection===section.workflowDash" class="nav-link" data-toggle="tab" id="workflow_dash_tab">
        Workflow
      </a>
    </li>

    <li class="nav-item">
      <a (click)="setSection($event,section.messageDash)" [ngClass]="{active:currentSection===section.messageDash}"
        [attr.aria-selected]="currentSection===section.messageDash" class="nav-link" data-toggle="tab" id="message_dash_tab">
        Messages
      </a>
    </li>
  </ul>

  <div class="tab-content" id="tabs">
    <div *ngIf="currentSection===section.complianceDash" [ngClass]="{'show active':currentSection===section.complianceDash}" aria-labelledby="compliance_dash_tab" class="tab-pane fade" id="compliance_dash" role="tabpanel">
      <app-compliance-dashboard></app-compliance-dashboard>
    </div>

    <div [ngClass]="{'show active':currentSection===section.workflowDash}" aria-labelledby="workflow_dash_tab" class="tab-pane fade" id="workflow_dash" role="tabpanel">
      <app-workflow-dashboard></app-workflow-dashboard>
    </div>

    <div [ngClass]="{'show active':currentSection===section.messageDash}" aria-labelledby="message_dash_tab" class="tab-pane fade" id="message_dash" role="tabpanel">
      <app-messages-dashboard></app-messages-dashboard>
    </div>
  </div>

</div>
