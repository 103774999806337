import {Component, Injector, OnInit} from '@angular/core';
import {RealEstate} from '../../../../models/real-estate';
import {RealEstateComponent} from '../real-estate/real-estate.component';

@Component({
  selector: 'app-add-real-estate',
  templateUrl: '../real-estate/edit-segment-real-estate.component.html',
})
export class AddRealEstateComponent extends RealEstateComponent implements OnInit {
  public op = 'Add';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(new RealEstate({}));
    this.createForm();
    this.setParties();
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }
}
