<mat-card class="mt-2 mb-1">
  <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Borrower Setup'" [help]="true" [op]="'edit'">
      Borrower Setup determines:
      <ul [ngClass]="{'is-mobile': isMobile}" class="help">
        <li>The number of borrowers</li>
        <li>The default URLA form(s) used for creating PDFs</li>
      </ul>
      <h5>Grouping</h5>
      When there are two or more borrowers, the borrower grouping determines the pair of individuals who share assets and liabilities.
      The first borrower in each group receives the complete URLA form, which includes assets and liabilities information for both borrowers.
      The other borrower receives the URLA Additional form.<br/>
      <span class="fw-bold">How to group:</span> Drag-and-Drop borrowers from the borrower list into the designated groups.
      <h5>Generating Documents</h5>
      When you create documents (Repository/Generate Documents), the default borrower grouping will be displayed.
      However, you will have the option to override this default grouping.
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row hover-gray border-bottom pb-1 pt-1">
      <div class="col-lg-6 fw-bold">
        Borrower count
      </div>
      <div class="col-lg-6 " *ngIf="+item.borrower_count===4">Four borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===3">Three borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===2">Two borrowers</div>
      <div class="col-lg-6" *ngIf="+item.borrower_count===1">One borrower</div>
    </div>

    <div class="row hover-gray border-bottom pb-1 pt-1">
      <div class="col-lg-6 fw-bold">
        Borrower grouping
      </div>
      <div class="col-lg-6 ">{{item.getGroupingSummary()}}</div>
    </div>
  </mat-card-content>
</mat-card>
