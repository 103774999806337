<div *ngIf="reloaded">
  <mat-card class="mt-2 mb-1">
    <app-view-sticky-note (eventEmitted)="onEvent($event)" [doc]="doc" [section]="section" [segment]="segment"></app-view-sticky-note>

    <mat-card-title>
      <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Addresses'" [help]="true" [op]="'add'">
        <ul [ngClass]="{'is-mobile':isMobile}" class="help">
          <li>Your current address is required.</li>
          <li>You must enter two years of address history with no gaps.</li>
          <li>If you have been living in your current home for two years or longer, you do not need to enter any previous addresses.</li>
          <li>A mailing address is required only if it is different from the address of your current residence.</li>
        </ul>
      </app-segment-header>
    </mat-card-title>

    <app-add-address (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'&&canUpdateDocument" [applicant]="applicant" [doc]="doc" [items]="items"></app-add-address>

    <div *ngFor="let item of items">
      <app-view-address (eventEmitted)="onEvent($event)" [applicant]="applicant" [doc]="doc" [item]="item" [items]="items"></app-view-address>
    </div>
  </mat-card>
</div>
