import {ModelBase} from './model-base';
// created_at: "2018-11-25T11:36:14.788-07:00"
// from: "Igal Megory-Cohen"
// from_thumbnail: "/attachments/users/attached_images/000/000/001/thumb/Botticelli-Portrait_of_a_Youth_cropped.jpg"
// from_user_id: 1
// id: 503
// is_sms: false
// message_text: "Hi Sweetie"
// receiver_message_id: 504
// replied_to_message_id: null
// sms_delivered: false
// to: "Aharon Megory-Cohen"
// to_thumbnail: "/attachments/users/attached_images/000/000/030/thumb/Alex_Jacobson.jpg"
// to_user_id: 30
// tran_id: 117
// updated_at: "2018-11-25T11:36:14.794-07:00"
// user_id: 1

export class Message extends ModelBase {
  public created_at: Date;
  public from: string;
  public from_thumbnail: string;
  public from_user_id: number;
  public id: number;
  public is_sms: boolean;
  public message_text: string;
  public receiver_message_id: number;
  public replied_to_message_id: number;
  public sms_delivered: boolean;
  public to: string;
  public to_thumbnail: string;
  public to_user_id: number;
  public tran_id: number;
  public updated_at: Date;
  public user_id: number;
  // generated properties
  public caption: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'updated_at' || prop === 'created_at') {
        this[prop] = new Date(object[prop]);
      } else if (prop === 'is_sms' || prop === 'sms_delivered') {
        this[prop] = this.getBooleanProp(prop, object);
      } else if (prop === 'from_user_id'
        || prop === 'id'
        || prop === 'receiver_message_id'
        || prop === 'replied_to_message_id'
        || prop === 'tran_id'
        || prop === 'to_user_id'
        || prop === 'user_id'
      ) {
        this[prop] = this.toNumber(object[prop]);
      } else {
        this[prop] = object[prop];
      }
      this.caption = this.from_user_id === this.user_id ? this.to : this.from;
    }
  }

  static deserializeArray(arr: Array<any>): Array<Message> {
    const result = [];
    for (const obj of arr) {
      const newMessage = new Message(obj);
      result.push(newMessage);
    }

    return result;
  }

  getFromThumbnailPath(): string {
    return this.getAbsolutePath(this.from_thumbnail);
  }

  getToThumbnailPath(): string {
    return this.getAbsolutePath(this.to_thumbnail);
  }

  toServerPayload(): any {
    return {};
  }
}
