import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Gift} from '../../../../models/gift';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-gift',
  template: '',
})
export class GiftComponent extends CompBaseComponent implements OnInit {
  @Input() item: Gift;
  public gift_types = Gift.gift_types;
  public gift_sources = Gift.gift_sources;

  // form fields
  public form: FormGroup;
  gift_type: FormControl;
  gift_source: FormControl;
  market_value: FormControl;
  deposited: FormControl;
  other_description: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'gifts';
    this.section = this.applicant;
  }

  createFormControls(item: Gift): void {
    this.gift_type = new FormControl(item.gift_type, AppValidators.present);
    this.gift_source = new FormControl(item.gift_source, AppValidators.present);
    this.market_value = new FormControl(item.market_value, AppValidators.present);
    this.deposited = new FormControl(item.deposited);
    this.other_description = new FormControl(item.other_description);
  }

  createForm(): void {
    this.form = new FormGroup({
      gift_type: this.gift_type,
      gift_source: this.gift_source,
      market_value: this.market_value,
      deposited: this.deposited,
      other_description: this.other_description
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Gift(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Gift(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
