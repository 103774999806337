<div (mouseenter)="onEnter($event)" (mouseleave)="onLeave($event)" class="row hover-gray pt-2 pb-2" style="border-bottom: 1px dotted black;">
  <div class="col-lg-6 fw-bold">
      <span *ngIf="document.isPDF()" title="Click to download">
        <img (click)="downloadDocument()" class="hand-pointer doc-image" src="assets/img/adobe-pdf-icon.png">
      </span>
    <span *ngIf="document.isImage()&&!fullImage" class="hand-pointer">
        <img (click)="toggleImage()" [src]="document.thumbnail_url" class="pointer doc-image" title="Click to view image">
      </span>
    <span *ngIf="document.isXML()" title="Click to download">
        <img (click)="downloadDocument()" class="hand-pointer doc-image" src="assets/img/xml-icon.png">
      </span>&nbsp;&nbsp;
    <span (click)="edit()" title="Click to edit" class="hand-pointer fw-normal"><i class="fa fa-edit"></i>&nbsp;{{document.name}}</span>&nbsp;&nbsp;<span class="text-muted">({{document.updated_at | date:'medium'}})</span>
  </div>
  <div class="col-lg-4 pt-2 text-end">
    <div [ngStyle]="{'visibility': isCutAndPaste&&!fullImage ? 'visible' : 'hidden'}">
      <i (click)="cut($event)" class="fa fa-cut cut-and-paste hand-pointer" title="Cut"></i>
    </div>
  </div>
</div>

<!-- full image -->
<div (click)="toggleImage()" *ngIf="document.isImage()&&fullImage" class="mb-1 hover-gray pt-3 pb-3" title="Click to minimize">
  <div class="text-center pb-3">
    <strong>{{document.name}}</strong>&nbsp;&nbsp;<span class="text-muted">Uploaded: {{document.updated_at | date:'medium'}}</span>
  </div>

  <div class="text-center">
    <img [src]="document.image_url" class="pointer">
  </div>
</div>

<iframe *ngIf="downloading" [src]="urlSafe" class="d-none">
</iframe>
