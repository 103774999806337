import {ModelBase} from './model-base';

export class Party extends ModelBase {
  public static vesting_types = [
    {key: 'Individual', value: 'Individual'},
    {key: 'JointTenantsWithRightOfSurvivorship', value: 'Joint Tenants with Right of Survivorship'},
    {key: 'LifeEstate', value: 'Life Estate'},
    {key: 'Other', value: 'Other'},
    {key: 'TenantsByTheEntirety', value: 'Tenants by the Entirety'},
    {key: 'TenantsInCommon', value: 'Tenants In Common'}
  ];
  public static roles = [
    {key: 'borrower', value: 'Title Held by One or More Individuals'},
    {key: 'entity', value: 'Legal Entity (Non-Person)'},
  ];
  public static trust_types = [
    {key: 'CommunityLandTrust', value: 'Community Land Trust'},
    {key: 'IllinoisLandTrust', value: 'Illinois Land Trust'},
    {key: 'LandTrust', value: 'Land Trust'},
    {key: 'LivingTrust', value: 'Living Trust'},
    {key: 'NativeAmericanLandTrust', value: 'Native American Land Trust'},
    {key: 'Other', value: 'Other'}
  ];

  index: number;
  role: string;
  full_name: string;
  vesting_type: string;
  other_description: string;
  trust_type: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      this[prop] = object[prop];
    }
  }

  static deserializeArray(arr: Array<any>): Array<Party> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Party(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getVestingType(): string {
    const found = Party.vesting_types.find((elt) => elt.key === this.vesting_type);
    return found ? found['value'] : '';
  }

  getTrustType(): string {
    const found = Party.trust_types.find((elt) => elt.key === this.trust_type);
    return found ? found['value'] : '';
  }

  getRole(): string {
    const found = Party.roles.find((elt) => elt.key === this.role);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      role: this.role,
      full_name: this.full_name,
      vesting_type: this.vesting_type,
      other_description: this.other_description,
      trust_type: this.trust_type
    };
  }
}
