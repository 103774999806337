import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Invoice} from '../models/invoice';
import {CacheService} from './cache.service';
import {Order} from "../models/order";

@Injectable()
export class OrderService {
  constructor(private httpService: HttpService) {
  }

  public getAccountOrders(user_id: number): Promise<Array<Order>> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`orders/${user_id}/account_orders`)
        .then((data) => {
          resolve(Order.deserializeArray(data));
        })
        .catch((data) => {
          console.log('ERROR', data);
          reject(data);
        });
    });
  }

  public deleteOrder(orderId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`orders/${orderId}`, false)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  public getOrder(invoiceId: number): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`orders/${invoiceId}`, [], false)
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public updateOrder(invoiceId: number, payload): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`invoices/${invoiceId}`, payload, false)
        .then((data) => {
          resolve(new Invoice(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  public createOrder(payload): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`orders`, payload, false)
        .then((data) => {
          resolve(new Order(data));
        })
        .catch((data) => {
          reject(data);
        });
    });
  }
}
