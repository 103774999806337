import {Component, Injector, OnInit} from '@angular/core';
import {DownPaymentComponent} from '../down-payment/down-payment.component';

@Component({
  selector: 'app-view-down-payment',
  templateUrl: './view-down-payment.component.html',
})
export class ViewDownPaymentComponent extends DownPaymentComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
