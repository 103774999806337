import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';
import {Organization} from '../../../../models/organization';
import {AgentService} from '../../../../services/agent.service';

@Component({
  selector: 'app-view-agent-organization-list',
  templateUrl: './view-agent-organization-list.component.html',
})
export class ViewAgentOrganizationListComponent extends CompBaseComponent implements OnInit {
  public organizations: Array<Organization>;
  public agentService: AgentService;
  public pageTitle = 'Organization List';
  public form: FormGroup;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
    this.agentService = this.injector.get(AgentService);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe((params) => {
      this.findOrganizations(params);
    });
  }

  findOrganizations(params): void {
    this.reloaded = false;
    this.agentService.findOrganization(params['name'], params['organization_id'] || null)
      .then((orgs) => this.organizations = orgs)
      .then(() => this.reloaded = true);
  }

  viewOrganization(event: any, org: Organization): void {
    event.preventDefault();
    this.router.navigate(['/agent/view-organization', org.id]);
  }

  // comment: not used for the time being
  editOrganization(event: any, org: Organization): void {
    event.preventDefault();
    this.router.navigate(['/agent/edit-organization', org.id]);
  }
}
