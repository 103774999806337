import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../../../form-util';
import moment from 'moment';
import {globals} from '../../../../../shared/globals/globals';
import {ModelBase} from '../../../../../models/model-base';
import {GenerateDocumentBaseComponent} from "../../generate-document-base.component";

@Component({
  selector: 'app-edit-anti-doc',
  templateUrl: './edit-anti-doc.component.html',
})
export class EditAntiDocComponent extends GenerateDocumentBaseComponent implements OnInit {
  @Input() folderId: string;
  public txSummary: any;
  public submitted: boolean;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public borrowerCount: number;

  // form and form controls
  public form: FormGroup;
  public company_address: FormControl;
  public mlo_name: FormControl;
  public mlo_nmls: FormControl;
  public doc_date: FormControl;
  public company_name: FormControl;
  public company_nmls: FormControl;
  public fixed_rate: FormControl;
  public option_1_percent: FormControl;
  public option_1_cost: FormControl;
  public option_2_percent: FormControl;
  public option_2_cost: FormControl;
  public option_3_percent: FormControl;
  public option_3_cost: FormControl;
  public rate_percent: FormControl;
  public cost: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.formType = 'STEER';
    this.submitted = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.savedDoc = this.doc.content3[this.formType] || {};
    this.generating = false;
    this.borrowerCount = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.createFormControls();
    this.createForm();
  }

  createFormControls(): void {
    this.company_address = new FormControl(this.txSummary.companyAddress, AppValidators.present);
    this.mlo_name = new FormControl(this.txSummary.mloFullName, AppValidators.present);
    this.mlo_nmls = new FormControl(this.txSummary.mloNMLS, AppValidators.present);
    this.company_name = new FormControl(this.txSummary.companyName, AppValidators.present);
    this.company_nmls = new FormControl(this.txSummary.companyLicenseNumber, AppValidators.present);
    this.fixed_rate = new FormControl(this.savedDoc['fixed_rate'], AppValidators.present);
    const docDate = this.savedDoc['doc_date'] && this.savedDoc['doc_date'].length > 0 ? this.savedDoc['doc_date'] : null;
    this.doc_date = new FormControl(moment(docDate, 'MM/DD/YYYY').toDate(), AppValidators.present);
    this.option_1_percent = new FormControl(this.savedDoc['option_1_percent'], Validators.compose([Validators.min(0.0), Validators.max(99.0)]));
    this.option_1_cost = new FormControl(this.savedDoc['option_1_cost']);
    this.option_2_percent = new FormControl(this.savedDoc['option_2_percent'], Validators.compose([Validators.min(0.0), Validators.max(99.0)]));
    this.option_2_cost = new FormControl(this.savedDoc['option_2_cost']);
    this.option_3_percent = new FormControl(this.savedDoc['option_3_percent']);
    this.option_3_cost = new FormControl(this.savedDoc['option_3_cost']);
    this.rate_percent = new FormControl(this.savedDoc['rate_percent']);
    this.cost = new FormControl(this.savedDoc['cost']);
  }

  createForm(): void {
    this.form = new FormGroup({
      company_address: this.company_address,
      mlo_name: this.mlo_name,
      mlo_nmls: this.mlo_nmls,
      company_name: this.company_name,
      company_nmls: this.company_nmls,
      fixed_rate: this.fixed_rate,
      doc_date: this.doc_date,
      option_1_percent: this.option_1_percent,
      option_1_cost: this.option_1_cost,
      option_2_percent: this.option_2_percent,
      option_2_cost: this.option_2_cost,
      option_3_percent: this.option_3_percent,
      option_3_cost: this.option_3_cost,
      rate_percent: this.rate_percent,
      cost: this.cost,
    });
  }

  async genAntiSteering(): Promise<boolean> {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    const loanProgramEntered = (this.option_1_percent.value && this.option_1_cost.value) ||
      (this.option_2_percent.value && this.option_2_cost.value) ||
      (this.option_3_percent.value && this.option_3_cost.value);

    if (!loanProgramEntered) {
      FormUtil.genErrorMessage(this.sharedService, 'You must enter cost and percentage of at least one loan program', null);
      return;
    }

    this.generating = true;
    const formData = this.form.value;
    formData['borrower_count'] = this.borrowerCount.toString();
    formData['doc_date'] = moment(this.doc_date.value).format('MM/DD/YYYY');
    formData['option_1_cost'] = ModelBase.toServerDecimal(this.option_1_cost.value);
    formData['option_2_cost'] = ModelBase.toServerDecimal(this.option_2_cost.value);
    formData['option_3_cost'] = ModelBase.toServerDecimal(this.option_3_cost.value);
    formData['lender_case_no'] = this.txSummary.lenderCaseNo;
    formData['icn_id'] = this.folderId || null;
    await this.saveForm(formData)
    await this.router.navigate(['/loan/view-directory']);
    return Promise.resolve(true);
  }
}
