<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Expense type</mat-label>
            <mat-select formControlName="support_type_ex" placeholder="Select other expense type" required>
              <mat-option *ngFor="let support_type of support_types_ex" [value]="support_type.key">{{support_type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="support_type_ex.hasError('required')">
              Other expense type is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="support_type_ex.value==='Other'">
        <div class="col-lg-12">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Other expense description</mat-label>
            <input formControlName="support_type_description" matInput placeholder="Describe other expense" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="support_type_description.hasError('required')">
              Describe expense is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Monthly payment amount</mat-label>
            <input appDecimalOnly formControlName="monthly_payment_amount" matInput placeholder="Monthly payment amount" maxlength="{{MAX_LEN.CURR}}" required>
            <mat-error *ngIf="monthly_payment_amount.hasError('required')">
              Monthly payment amount is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Months left</mat-label>
            <input appNumberOnly formControlName="months_left" matInput maxlength="3" placeholder="Months left">
            <mat-error *ngIf="months_left.hasError('required')">
              Months left is required
            </mat-error>
            <mat-error *ngIf="months_left.hasError('pattern')">
              Months left is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="update()" *ngIf="op==='Edit'" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="add()" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
