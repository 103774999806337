import {Component, Injector, OnInit} from '@angular/core';
import {PartyComponent} from '../party/party.component';

@Component({
  selector: 'app-edit-segment-party',
  templateUrl: '../party/edit-segment-party.component.html',
})
export class EditSegmentPartyComponent extends PartyComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
