<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on User Organization
  </mat-card-title>

  <mat-card-content>
    <p>
      All users with the same
      <b>company NMLS ID</b> can be added to the same organization by the organization Admin.
      Therefore, you should update your company NMLS ID field in the
      <span class="fw-bold">MLO Configuration </span> facility.
      The first user to create the company organization becomes automatically the organization Admin.
    </p>

    <p>
      The list of organization users is displayed only to the organization Admin.
      The organization Admin can also modify the organization name and description.
      At any time, after the organization creation, the current Admin can reassign their Admin privileges to any other
      organization user.
    </p>
  </mat-card-content>
</mat-card>
