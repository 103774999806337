import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {Invoice} from '../../../../models/invoice';
import {Router} from '@angular/router';
import {InvoiceService} from '../../../../services/invoice.service';
import {CC} from '../../../../models/cc';

@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
})
export class PayInvoiceComponent extends CompBaseComponent implements OnInit {
  private invoiceService: InvoiceService;
  public error: boolean;
  public reloaded: boolean;
  public item: Invoice;
  public months = Invoice.month_table;
  public years = Invoice.year_table;

  // form and form controls
  form: FormGroup;

  cc_number: FormControl;
  cc_exp_month: FormControl;
  cc_exp_year: FormControl;
  cc_code: FormControl;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloaded = false;
    this.invoiceService = this.injector.get(InvoiceService);
    const userId = this.userService.getUserSync().id;
    this.invoiceService.getCurrentInvoice(userId)
      .then((invoice) => {
        this.item = invoice;
        this.createFormControls();
        this.createForm();
        this.error = false;
        this.reloaded = true;
      })
      .catch(() => {
        this.error = true;
      });
  }

  createFormControls() {
    this.cc_number = new FormControl('4242424242424242');
    this.cc_exp_month = new FormControl('06');
    this.cc_exp_year = new FormControl('24');
    this.cc_code = new FormControl('999');
  }

  createForm() {
    this.form = new FormGroup({
      cc_number: this.cc_number,
      cc_exp_month: this.cc_exp_month,
      cc_exp_year: this.cc_exp_year,
      cc_code: this.cc_code,
    });
  }

  navigateToAuthorize(): void {
    if (!this.form.valid) {
      return;
    }
    const cc = new CC({
      invoice_id: this.item.id,
      amount: this.item.amount,
      cc_number: this.cc_number.value,
      cc_code: this.cc_code.value,
      cc_exp_month: this.cc_exp_month.value,
      cc_exp_year: this.cc_exp_year.value,
    });
    this.invoiceService.setCC(cc);
    this.router.navigate(['/account/invoice/auth']);
  }

  cancel(): void {
    this.invoiceService.resetCurrentInvoice();
    this.router.navigate(['/account/invoices']);
  }
}
