<div
  [ngClass]="{'hand-pointer': item.code !== 'lock', 'fw-bold':item.is_comp, 'pr-light-gray':item.level == 0, 'pr-success':item.level == 1,'pr-light':item.level == 2,'pr-warning': item.level == 3,'pr-danger': item.level == 4,'pr-fatal': item.level == 5}"
  class="row comp-border-bottom" (click)="editCompEvent()">
  <div class="col-lg-6 comp-cell comp-border-left">{{item.description}}&nbsp;<span class="fw-normal">({{item.code}})</span>
  </div>
  <div class="col-lg-2 comp-cell comp-border-left">{{item.due_after}}</div>
  <div *ngIf="item.code !== 'lock'" class="col-lg-2 comp-cell comp-border-left hand-pointer">{{item.occurred_on}}</div>
  <div *ngIf="item.code === 'lock'" class="col-lg-2 comp-cell comp-border-left"></div>
  <div *ngIf="item.code !== 'lock'" class="col-lg-2 comp-cell comp-border-left">{{item.due_by}}</div>
  <div *ngIf="item.code === 'lock'" class="col-lg-2 comp-cell comp-border-left">{{item.due_by}}
    <span>{{isMobile ? '(Exp On)' : '(Expires On)'}}</span>
  </div>
</div>
