import {Component, Injector, OnInit} from '@angular/core';
import {NoteComponent} from '../note/note.component';
import {Nl2BrPipe} from '../../../shared/pipes/nl2br.pipe';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-segment-note',
  templateUrl: './view-segment-note.component.html',
  providers: [Nl2BrPipe]
})
export class ViewSegmentNoteComponent extends NoteComponent implements OnInit {
  constructor(public injector: Injector, public router: Router) {
    super(injector, router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  edit(): void {
    this.router.navigate(['/loan/edit-note', this.note.id]);
  }

  onEvent(event: any): void {
    if (event.type === 'ChangeMode' && event.mode === 'Edit') {
      this.router.navigate(['loan/edit-note', this.note.id]);
      return;
    }
    super.onEvent(event);
  }
}
