import {Component, OnInit} from '@angular/core';
import {TranService} from '../../services/tran.service';
import {UserService} from '../../services/user.service';
import {EventService} from '../../services/event.service';
import {OrganizationService} from "../../services/organization.service";

@Component({
  // moduleId: module.id,
  selector: 'app-logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
})
export class LogoComponent implements OnInit {
  reloaded: boolean;
  logoImageSrc: string;
  readonly PrudentCOLogo = 'assets/img/prudentco-logo-small.png';

  constructor(private eventService: EventService, private orgService: OrganizationService, private tranService: TranService, private userService: UserService) {
    this.reloaded = false;
    this.logoImageSrc = 'assets/img/prudentco-logo-small.png';
  }

  ngOnInit() {
    this.reload();
    this.eventService.eventEmitter.subscribe((event) => {
      if (event.type === 'Reload') {
        this.reload();
      }
    });
  }

  navigateToRoot(event: any): void {
    event.preventDefault();
    window.location.href = '';
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    const user = this.userService.getUserSync();
    const tran = this.tranService.getCurrentTranSync();
    const org_id = tran ? tran.organization_id :  user.organization_id;
    if (org_id) {
      this.orgService.findOrganizationById(org_id)
        .then((org) => {
          if (org.is_logo) {
            this.logoImageSrc = org.getLogoUrl();
          } else {
            this.logoImageSrc = this.PrudentCOLogo
          }
          this.reloaded = true
        }).catch(() => {
        this.reloaded = true
      })
    } else {
      setTimeout(() => {
        this.logoImageSrc = this.PrudentCOLogo;
        this.reloaded = true
      }, 0)
    }
  }
}
