import {Component, Injector, OnInit} from '@angular/core';
import {DownPayment} from '../../../../models/down-payment';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-down-payments',
  templateUrl: './view-segment-down-payments.component.html',

})
export class ViewSegmentDownPaymentsComponent extends CompBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'down_payments';
    this.section = 'loan_information';
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.items = DownPayment.deserializeArray(this.doc.recursive_attributes.down_payments);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
