<mat-card class="mt-2 mb-1">
  <mat-card-title>{{header}}</mat-card-title>

  <mat-card-content>
    <form (ngSubmit)="update()" [formGroup]="form" novalidate>
      <div class="row pb-3">
        <div class="col">
          <mat-checkbox formControlName="na">Does not apply</mat-checkbox>
        </div>
      </div>

      <div [hidden]="na.value">
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Employment status</mat-label>
              <mat-select formControlName="employment_status" placeholder="Select employment status">
                <mat-option *ngFor="let employment_status of employment_statuses" [value]="employment_status.key">{{employment_status.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="employment_status.hasError('required')">
                Employment status is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Employer name</mat-label>
              <input formControlName="employer_name" matInput maxlength="{{MAX_LEN.GEN_NAME}}" placeholder="Employer name" type="text">
              <mat-error *ngIf="employer_name.hasError('required')">
                Employer name is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Address</mat-label>
              <input type="text"formControlName="address" matInput placeholder="Address"  maxlength="{{MAX_LEN.ADDR_PART}}">
              <mat-error *ngIf="address.hasError('required')">
                Address is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Apt/Unit/Suite</mat-label>
              <input type="text" formControlName="address2" matInput placeholder="Apt/Unit/Suite" maxlength="{{MAX_LEN.ADDR_PART}}">
              <mat-error *ngIf="address2.hasError('required')">
                Apt/Unit/Suite is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>City</mat-label>
              <input type="text" formControlName="city" matInput placeholder="City" maxlength="{{MAX_LEN.ADDR_PART}}">
              <mat-error *ngIf="city.hasError('required')">
                City is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>State Abbreviation</mat-label>
              <input  type="text" formControlName="state" appUpperCase matInput appAlphaOnly placeholder="State abbreviation" maxlength="{{MAX_LEN.STATE_ABBR}}">
              <mat-error *ngIf="state.hasError('required')">
                State is required
              </mat-error>
              <mat-error *ngIf="state.hasError('pattern')">
                State is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Zip</mat-label>
              <input type="text" appNumberOnly formControlName="zip" matInput placeholder="Zip" maxlength="{{MAX_LEN.ZIP}}">
              <mat-error *ngIf="zip.hasError('required')">
                Zip is required
              </mat-error>
              <mat-error *ngIf="zip.hasError('pattern')">
                Zip is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Zip four</mat-label>
              <input type="text" appNumberOnly formControlName="zip_four" matInput placeholder="Zip four" maxlength="{{MAX_LEN.ZIP_FOUR}}">
              <mat-error *ngIf="zip_four.hasError('required')">
                Zip four is required
              </mat-error>
              <mat-error *ngIf="zip_four.hasError('pattern')">
                Zip four is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Country code</mat-label>
              <input type="text" formControlName="country" appAlphaOnly appUpperCase matInput placeholder="Country code"  maxlength="{{MAX_LEN.COUNTRY_CODE}}">
              <mat-error *ngIf="country.hasError('required')">
                Country code is required
              </mat-error>
              <mat-error *ngIf="country.hasError('pattern')">
                Country code is invalid
              </mat-error>
              <mat-hint>Country Abbreviation. Enter US for USA.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Number of years</mat-label>
              <input type="text" appNumberOnly formControlName="profession_years_no" matInput placeholder="Number of years in line of work" [required]="!na.value" maxlength="2">
              <mat-error *ngIf="profession_years_no.hasError('required')">
                Number of years is required
              </mat-error>
              <mat-error *ngIf="profession_years_no.hasError('min')">
                Number of years must be greater or equal to 0
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Number of months</mat-label>
              <input type="text" appNumberOnly formControlName="profession_months_no" matInput placeholder="Number of months (0-11)" [required]="!na.value" maxlength="2">
              <mat-error *ngIf="profession_months_no.hasError('required')">
                Number of months is required
              </mat-error>
              <mat-error *ngIf="profession_months_no.hasError('min') || profession_months_no.hasError('max')">
                Number of months must be between 0 and 11
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Position</mat-label>
              <input formControlName="position" matInput placeholder="Position" type="text" maxlength="{{MAX_LEN.S_DESC}}">
              <mat-error *ngIf="position.hasError('required')">
                Position is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Start date</mat-label>
              <input [matDatepicker]="$any(datePicker)" [max]="maxEmpStartDate" [min]="minEmpStartDate" appDateOnly formControlName="start_date" matInput placeholder="Start date">
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error *ngIf="start_date.hasError('matDatepickerMax')">
                Start date is invalid
              </mat-error>
              <mat-error *ngIf="start_date.hasError('matDatepickerMin')">
                Start date is unrealistic
              </mat-error>
              <mat-error *ngIf="start_date.hasError('matDatepickerParse')">
                Start date format is invalid
              </mat-error>
              <mat-error *ngIf="!start_date.hasError('matDatepickerParse')&&start_date.hasError('required')">
                Start date is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row hover-gray mb-3">
          <div class="col-lg-4 my-auto">
            Are you the business owner or self-employed?
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="self_employed" formControlName="self_employed">
              <mat-radio-button [checked]="self_employed.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="self_employed.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row mb-3" *ngIf="self_employed.value===true">
          <div class="col">
            <mat-radio-group aria-label="ownership" formControlName="ownership">
              <mat-radio-button [checked]="ownership.value===false" [value]="false">I have an ownership share of less than 25%</mat-radio-button>
              <br>
              <mat-radio-button [checked]="ownership.value===true" [value]="true">I have an ownership share of 25% or more</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row mb-3 hover-gray">
          <div class="col-lg-4 my-auto">
            Are you employed by a family member, property seller, real estate agent, or other party to the transaction?
          </div>
          <div class="col-lg-8 text-start">
            <mat-radio-group formControlName="employed" aria-label="Employed by a family member">
              <mat-radio-button [checked]="employed.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="employed.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row pr-mb-6">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Monthly income (or loss)</mat-label>
              <input appDecimalOnly formControlName="income_or_loss" matInput placeholder="Monthly income (or loss)" maxlength="{{MAX_LEN.CURR}}">
              <mat-error *ngIf="income_or_loss.hasError('required')">
                Income (or loss) value is required
              </mat-error>
              <mat-hint>
                The total of all primary and secondary employment income.
                A negative value is valid.
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>Business phone</mat-label>
              <input appPhoneOnly formControlName="business_phone" matInput placeholder="Business phone" type="text" maxlength="{{MAX_LEN.PHONE}}">
              <mat-error *ngIf="business_phone.hasError('required')">
                Business phone is required
              </mat-error>
              <mat-error *ngIf="business_phone.hasError('pattern')">
                Business phone is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-3 hover-gray">
          <div class="col-lg-4 my-auto">
            Seasonal income?
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="Seasonal income indicator" formControlName="seasonal_income_indicator">
              <mat-radio-button [checked]="seasonal_income_indicator.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="seasonal_income_indicator.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row mb-3 hover-gray">
          <div class="col-lg-4 my-auto">
            Foreign income?
          </div>
          <div class="col-lg-8">
            <mat-radio-group aria-label="Foreign income indicator" formControlName="foreign_income_indicator">
              <mat-radio-button [checked]="foreign_income_indicator.value===true" [value]="true">Yes</mat-radio-button>
              <mat-radio-button [checked]="foreign_income_indicator.value===false" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="update()" class="btn btn-lg btn-primary" type="submit">Update</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
          <button (click)="empty()" class="ms-2 btn btn-lg btn-warning" type="button">Clear</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
