export interface IEmploymentReport {
  gapStrings: Array<string>;
  gapMonths: number;
  empLengthMonths: number;
  empNetLengthMonths: number;
}

export class Util {
  static generateEmploymentReport(inFrames: Array<any>): IEmploymentReport {
    const verifyInput = (frames: Array<any>): boolean => {
      if (!frames) {
        return true;
      }
      return frames.every((elt) => elt[0] && elt[1] && elt[0].localeCompare(elt[1]) <= 0);
    };

    const sort = (frames: Array<any>): Array<any> => {
      return frames.sort((x, y) => x[0] - y[0]);
    };

    const max = (arr: Array<number>): number => {
      if (arr.length) {
        return Math.max(...arr);
      } else {
        return 0;
      }
    };

    const min = (arr: Array<number>): number => {
      if (arr.length) {
        return Math.min(...arr);
      } else {
        return 0;
      }
    };

    const dateToMonths = (dateString: string): number => {
      return +dateString.substr(0, 4) * 12 + (+dateString.substr(4, 2) - 1);
    };

    const monthsToDate = (months: number): string => {
      const monthIndex = months % 12;
      const year = (months - monthIndex) / 12;
      return `${monthIndex + 1}/${year}`;
    };

    const transformInput = (frames: Array<any>): Array<any> => {
      return frames.map((elt) => {
        return [dateToMonths(elt[0]), dateToMonths(elt[1])];
      });
    };

    const transformToOutput = (frames: Array<any>): Array<any> => {
      return frames.map((elt) => {
        return `${monthsToDate(elt[0])} - ${monthsToDate(elt[1])}`;
      });
    };

    const getGaps = (frames: Array<any>): Array<any> => {
      let index = 0;
      if (frames.length <= 1) {
        return [];
      }
      const computedGaps = [];
      while (index < frames.length - 1) {
        computedGaps.push([frames[index][1], frames[index + 1][0]]);
        index++;
      }
      return computedGaps;
    };

    // take the join of any two consecutive intervals if they intersect recursively
    const reduceIntervals = (frames: Array<any>): Array<any> => {
      if (frames.length <= 1) {
        return frames;
      }
      let index = 0;
      let joinIndex = -1;
      while (index < frames.length - 1) {
        if (frames[index][1] >= frames[index + 1][0]) {
          joinIndex = index;
          break;
        }
        index++;
      }
      if (joinIndex >= 0) {
        const newInterval = [frames[joinIndex][0], Math.max(frames[joinIndex][1], frames[joinIndex + 1][1])];
        frames.splice(index, 2, newInterval);
        return reduceIntervals(frames);
      }
      return frames;
    };

    if (!verifyInput(inFrames)) {
      return {
        gapStrings: [],
        gapMonths: 0,
        empLengthMonths: 0,
        empNetLengthMonths: 0
      };
    }
    // main algorithm
    // 1. transform input to month pairs. Each pair month is the count since the beginning of time
    // 2. find min of all frames
    // 3. find max of all frames
    // 4. sort frames by their starting value (going from low to high)
    // 5. reduce frames by taking the union of intersecting frames (recursive operation)
    // 6. compute gaps in frames
    // 7. transform gaps to an array of strings: [mm/yyyy, ...]
    const transformed = transformInput(inFrames);
    const maxMonth = max(transformed.map((elt) => elt[1]));
    const minMonth = min(transformed.map((elt) => elt[0]));
    const sorted = sort(transformed);
    const reduced = reduceIntervals(sorted);
    const gaps = getGaps(reduced);
    const employmentGap = gaps.reduce((mem, elt) => mem + elt[1] - elt[0], 0);
    const gapStrings = transformToOutput(gaps);
    return {
      gapStrings: gapStrings,
      gapMonths: employmentGap,
      empLengthMonths: maxMonth - minMonth,
      empNetLengthMonths: maxMonth - minMonth - employmentGap
    };
  }

  static formatAddress(address, address2, city, state, zip, zipFour, country) {
    const parts = [];
    const addressParts = [];
    if (address) {
      addressParts.push(address);
    }
    if (address2) {
      addressParts.push(address2);
    }
    const addressLong = addressParts.join(' ');
    parts.push(addressLong);
    if (city) {
      parts.push(city);
    }
    const zipParts = [];
    if (zip) {
      zipParts.push(zip);
    }
    if (zipFour) {
      zipParts.push(zipFour);
    }
    const zipZipFour = zipParts.join('-');
    const stateParts = [];
    if (state) {
      stateParts.push(state);
    }
    if (zipZipFour) {
      stateParts.push(zipZipFour);
    }
    const stateZip = stateParts.join(' ');
    if (stateZip) {
      parts.push(stateZip);
    }
    if (country) {
      parts.push(country);
    }
    return parts.length > 0 ? parts.join(', ') : '';
  }
}
