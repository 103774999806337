import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-find-trans',
  templateUrl: './find-trans.component.html',
})
export class FindTransComponent extends CompBaseComponent implements OnInit {
  public pageTitle = 'Find Transactions';
  public form: FormGroup;
  public name: FormControl;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
    this.sharedService.emitChange({
      type: 'ChangePage',
      title: this.pageTitle,
      help: Help.helpKeys.any
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl('');
  }

  createForm() {
    this.form = new FormGroup({
      name: this.name,
    });
  }

  findTrans(): void {
    this.router.navigate(['/admin/tran-list'], {queryParams: this.form.value});
  }

  reset(): void {
    this.form.reset();
  }
}
