import {ModelBase} from './model-base';

export class Dependent extends ModelBase {
  index: number;
  age: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'age') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Dependent> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Dependent(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  toServerPayload(): any {
    return {
      index: this.toNumber(this.index),
      age: this.toNumber(this.age)
    };
  }
}
