import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompBaseComponent} from '../../../comp-base.component';
import {UserConfiguration} from '../../../../models/user-configuration';
import {globals} from '../../../../shared/globals/globals';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-user-configuration',
  templateUrl: './edit-segment-user-configuration.component.html',
})
export class EditSegmentUserConfigurationComponent extends CompBaseComponent implements OnInit {
  @Input() public item: UserConfiguration;

  // form and form controls
  form: FormGroup;
  address: FormControl;
  address2: FormControl;
  city: FormControl;
  state: FormControl;
  zip: FormControl;
  zip_four: FormControl;
  company_name: FormControl;
  company_nmls: FormControl;
  nmls: FormControl;
  phone_number: FormControl;
  fnma_institution_id: FormControl;
  fnma_institution_name: FormControl;
  compensation_percent: FormControl;
  min_compensation_amount: FormControl;
  max_compensation_amount: FormControl;
  ecoa_address: FormControl;
  company_state_license_number: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(config) {
    this.address = new FormControl(config.address, Validators.minLength(2));
    this.address2 = new FormControl(config.address2, Validators.minLength(1));
    this.city = new FormControl(config.city, Validators.compose([Validators.minLength(2), Validators.maxLength(30)]));
    this.state = new FormControl(config.state, Validators.pattern(globals.pattern.state));
    this.zip = new FormControl(config.zip, Validators.pattern(globals.pattern.zip));
    this.zip_four = new FormControl(config.zip_four, Validators.pattern(globals.pattern.zip_four));
    this.company_name = new FormControl(config.company_name, Validators.minLength(2));
    this.fnma_institution_id = new FormControl(config.fnma_institution_id, Validators.minLength(2));
    this.fnma_institution_name = new FormControl(config.fnma_institution_name, Validators.minLength(2));
    this.nmls = new FormControl(config.nmls, Validators.minLength(2));
    this.company_nmls = new FormControl(config.company_nmls, Validators.minLength(2));
    this.phone_number = new FormControl(config.phone_number, Validators.pattern(globals.pattern.phone));
    this.compensation_percent = new FormControl(config.compensation_percent,
      Validators.compose([Validators.pattern(globals.pattern.percent), Validators.min(0.0), Validators.max(20.0)]));
    this.min_compensation_amount = new FormControl(config.min_compensation_amount,
      Validators.compose([Validators.min(0.0), Validators.max(100000.0)]));
    this.max_compensation_amount = new FormControl(config.max_compensation_amount,
      Validators.compose([Validators.min(0.0), Validators.max(100000.0)]));
    this.ecoa_address = new FormControl(config.ecoa_address);
    this.company_state_license_number = new FormControl(config.company_state_license_number);
  }

  createForm() {
    const controls = {};
    const arr = ['address', 'address2', 'city', 'state', 'zip', 'zip_four',
      'company_name', 'fnma_institution_id', 'fnma_institution_name', 'nmls',
      'company_nmls', 'phone_number', 'compensation_percent',
      'min_compensation_amount', 'max_compensation_amount', 'ecoa_address', 'company_state_license_number'];
    for (const field of arr) {
      controls[field] = this[field];
    }
    this.form = new FormGroup(controls);
  }

  update(): void {
    if (!this.form.valid) {
      return;
    }
    const updated = new UserConfiguration(this.form.value);
    updated.id = this.item.id;
    this.userService.updateUserConfiguration(updated)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'MLO configuration successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      }).catch((data) => {
      FormUtil.genErrorMessage(this.sharedService, 'Failed to update MLO configuration', data);
      this.cancel();
    });
  }
}
