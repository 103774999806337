<mat-card class="mt-2 mb-1">
  <mat-card-content>
    <div *ngIf="canUpdateDocument" class="row">
      <div class="col">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'edit'" title="Edit"></app-line-header>
      </div>

      <div class="col text-end">
        <app-line-header (eventEmitted)="onEvent($event)" [op]="'delete'" title="Delete"></app-line-header>
      </div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Liability type</div>
      <div class="col-lg-6">{{item.getLiabilityType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.liability_type_ex==='Other'">
      <div class="col-lg-6 fw-bold">Other liability description</div>
      <div class="col-lg-6">{{item.other_description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Creditor name</div>
      <div class="col-lg-6">{{item.creditor_name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.isMortgageLoan()">
      <div class="col-lg-6 fw-bold">Mortgage type</div>
      <div class="col-lg-6">{{item.getMortgageType()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" *ngIf="item.isMortgageLoan()">
      <div class="col-lg-6 fw-bold">Does the liability include payment for taxes and insurance?</div>
      <div class="col-lg-6" *ngIf="item.liability_includes_ti!==null">{{item.liability_includes_ti | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.liability_includes_ti===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray" [hidden]="!item.reo_asset_id||!item.reo_asset_id.length">
      <div class="col-lg-6 fw-bold">Real estate owned ID</div>
      <div class="col-lg-6">{{getREOAsset()}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Account number</div>
      <div class="col-lg-6">{{item.account_no}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Monthly payment amount</div>
      <div class="col-lg-6">{{item.monthly_payment_amount| currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Months left</div>
      <div class="col-lg-6">{{item.months_left}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Unpaid balance</div>
      <div class="col-lg-6">{{item.unpaid_balance | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Credit limit</div>
      <div class="col-lg-6">{{item.credit_limit | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Liability will be paid off prior to closing?</div>
      <div class="col-lg-6" *ngIf="item.will_be_paid!==null">{{item.will_be_paid | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.will_be_paid===null">(no response)</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Omit liability from credit report?</div>
      <div class="col-lg-6" *ngIf="item.omit_from_credit_report!==null">{{item.omit_from_credit_report | booleanToString}}</div>
      <div class="col-lg-6" *ngIf="item.omit_from_credit_report===null">(no response)</div>
    </div>
  </mat-card-content>
</mat-card>
