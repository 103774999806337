<mat-card class="mt-1 mb-1" *ngIf="reloaded&&!error">
  <mat-card-title>Pay Invoice
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice ID</div>
      <div class="col-lg-8">{{item.id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Amount</div>
      <div class="col-lg-8">{{item.amount | currency}}</div>
    </div>

    <form [formGroup]="form" novalidate class="mt-3">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit card number</mat-label>
            <input appNumberOnly formControlName="cc_number" matInput placeholder="Credit card number" minlength="13" maxlength="19" required>
            <mat-error *ngIf="cc_number.hasError('required')">
              Credit card number is required
            </mat-error>
            <mat-error *ngIf="cc_number.hasError('minlength')">
              Credit card number is too short
            </mat-error>
            <mat-error *ngIf="cc_number.hasError('maxlength')">
              Credit card number is too long
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Expiration month</mat-label>
            <mat-select formControlName="cc_exp_month" placeholder="Select month" required>
              <mat-option *ngFor="let month of months" [value]="month.key">{{month.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="cc_exp_month.hasError('required')">
              Expiration month is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Expiration year</mat-label>
            <mat-select formControlName="cc_exp_year" placeholder="Select year" required>
              <mat-option *ngFor="let year of years" [value]="year.key">{{year.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="cc_exp_year.hasError('required')">
              Expiration year is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Credit card code (CVV)</mat-label>
            <input appNumberOnly formControlName="cc_code" matInput placeholder="Credit card code (CVV)" minlength="3" maxlength="{{MAX_LEN.ZIP_FOUR}}" required>
            <mat-error *ngIf="cc_code.hasError('required')">
              Credit card code (CVV) is required
            </mat-error>
            <mat-error *ngIf="cc_code.hasError('minlength') || cc_code.hasError('maxlength')">
              Credit card code is invalid
            </mat-error>
            <mat-hint>
              (Three or four numerals)
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="navigateToAuthorize()" class="btn btn-lg btn-primary">Next&nbsp;<i class="fa fa-chevron-right" style="margin-top: -2px;"></i>
          </button>
          <button appBackButton class="ms-2 btn btn-lg btn-light">
            <i class="fa fa-chevron-left" style="margin-top: -2px;"></i>&nbsp;Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="mt-1 mb-1" *ngIf="reloaded&&error">
  <mat-card-title>Pay Invoice
  </mat-card-title>

  <mat-card-content>
    <app-box [close]="true" [hidden] [type]="'warning'" [fadeOut]="false">
      The invoice could not be found!
      <a href="/account/invoices">Go back to invoices</a>.
    </app-box>
  </mat-card-content>
</mat-card>
