<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Account Highlights
  </mat-card-title>

  <mat-card-content>
    <p>
      Account Highlights display your role and services.
    </p>
  </mat-card-content>
</mat-card>
