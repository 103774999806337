import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../comp-base.component';
import {Tran} from '../../../models/tran';
import {Document} from '../../../models/document';
import {CompEvent} from '../../../models/comp-event';
import {Workflow} from '../../../models/workflow';
import {FormUtil} from '../../form-util';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../shared/validators/app-validators.validator';
import moment from 'moment';
import {globals} from '../../../shared/globals/globals';

@Component({
  selector: 'app-segment-workflow',
  template: '',
})
export class SegmentWorkflowComponent extends CompBaseComponent implements OnInit {
  @Input() public doc: Document;
  @Input() public tran: Tran;
  @Input() public compEvents: Array<CompEvent>;
  public workflow: Workflow;
  public currentTrace: any;
  public permissibleEvents: Array<any>;
  public permissions: Array<any>;
  public submitted: boolean;
  public readonly dateFormat = 'MM/DD/YYYY';
  public simulatedDate: any;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;

  // compliance event-related variables
  public filtered: Array<CompEvent>;
  public optComps: Array<CompEvent>;
  public requiredComps: Array<CompEvent>;
  public isCompDone: boolean;
  public compMessage: string;

  // key compliance dates
  public levelCounts: Array<any>;
  public startDate: string;
  public creditAuthDate: string;
  public loanAppDate: string;
  public leDate: string;
  public cdDate: string;
  public lockDate: string;
  public startedAt: Date;

  // form and form controls
  form: FormGroup;
  event: FormControl;
  forceWorkflow: FormControl;
  permissionArray: FormArray;
  started_at: FormControl;
  started_at_time: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): Promise<boolean> {
    super.ngOnInit();
    this.simulatedDate = moment().format(this.dateFormat);
    this.mode = 'View';
    this.workflow = this.tran.workflow;
    this.permissibleEvents = this.workflow.permissibleEvents;
    this.currentTrace = this.workflow.currentTrace;

    this.optComps = [];
    this.requiredComps = [];

    return new Promise((resolve, reject) => {
      const isRefinanceLoan = this.doc ? this.analyzerService.isRefinanceLoan(this.doc['recursive_attributes']) : false;
      return this.compService.getCompReport(this.tran.id, this.dateFormat, this.simulatedDate, isRefinanceLoan, false)
        .then((response) => {
          let report;
          if (response && (report = response.report)) {
            this.compEvents = CompEvent.deserializeArray(Object.values(report));
          }
          if (!report || !this.compEvents) {
            return;
          }
          // compute required and optional comp events
          // filtered: map comp_event numbers to comp event instances while preserving same order!
          if (this.currentTrace) {
            this.filtered = this.currentTrace.comp_events
              .map((code_number) => this.compEvents.find((elt) => elt['code_number'] === code_number))
              .filter((elt) => typeof elt !== 'undefined');
          } else {
            this.filtered = [];
          }
          this.filtered.forEach((elt) => {
            if (elt['is_comp']) {
              this.requiredComps.push(elt);
            } else {
              this.optComps.push(elt);
            }
          });
          const countCompsDone = this.requiredComps.reduce((mem, elt) => {
              if (elt.occurred_on) {
                mem++;
              }
              return mem;
            }, 0
          );
          const countComps = this.requiredComps.length;
          this.isCompDone = countComps === countCompsDone;
          this.compMessage = null;
          if (!this.isCompDone) {
            const events = countComps - countCompsDone > 1 ? 'events' : 'event';
            const fields = countComps - countCompsDone > 1 ? 'fields' : 'field';
            if (countCompsDone === 0) {
              this.compMessage = `You have not updated the "occurred on" field of the required compliance ${events}. You should perform the compliance ${events} and update their "occurred on" ${fields}.`;
            } else {
              this.compMessage = `${countCompsDone} out of ${countComps} compliance events done. Please perform the remaining compliance ${events}.`;
            }
          }

          const keyEvents = CompEvent.getKeyEvents(report);
          this.levelCounts = keyEvents['levelCounts'];
          this.startDate = keyEvents['startDate'];
          this.creditAuthDate = keyEvents['creditAuthDate'];
          this.loanAppDate = keyEvents['loanAppDate'];
          this.leDate = keyEvents['leDate'];
          this.cdDate = keyEvents['cdDate'];
          this.lockDate = keyEvents['lockDate'];
        })
        .then(() => {
          return this.compService.getMaxDate(this.tran.id, isRefinanceLoan);
        })
        .then((date) => {
          this.startedAt = [date, this.workflow.getMaxDate()].sort().pop();
          resolve(true);
        })
        .catch((errorData) => {
          reject(false);
        });
    });
  }

  changeMode(mode: string): void {
    this.eventEmitted.emit({type: 'ChangeMode', mode: mode});
  }

  terminateTask(): void {
    const payload = {
      ended_at: new Date()
    };

    this.workflowService.updateWorkflow(this.tran.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Current task successfully marked completed');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to mark current task completed', data);
        this.cancel();
        return;
      });
  }

  clearTerminatedAtTask(): void {
    const payload = {
      ended_at: null
    };

    this.workflowService.updateWorkflow(this.tran.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Successfully cleared Completed at timestamp');
        this.eventEmitted.emit({type: 'Reload'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to clear Completed at timestamp', data);
        this.cancel();
      });
  }

  createFormControls(workflow) {
    this.permissions = this.tran.permissions.slice(0);
    this.permissions.forEach((permission) => permission.selected = false);
    this.permissionArray = new FormArray([]);
    this.permissions.forEach(() => this.permissionArray.push(new FormControl()));
    this.event = new FormControl('', AppValidators.present);
    this.forceWorkflow = new FormControl(null);
    this.started_at = new FormControl(this.startedAt, AppValidators.present);

    this.started_at_time = new FormControl('12:00 PM', Validators.compose([AppValidators.present, Validators.pattern(globals.pattern.time)]));
    this.submitted = false;
  }

  createForm() {
    this.form = new FormGroup({
      forceWorkflow: this.forceWorkflow,
      event: this.event,
      permissions: this.permissionArray,
      started_at: this.started_at,
      started_at_time: this.started_at_time
    });
  }

  undo(): void {
    this.workflowService.UndoLastTrace(this.tran.id)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Workflow task successfully undone');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to undo workflow task', data);
        this.cancel();
      });
  }

  update(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    const notifyContacts = [];
    this.permissionArray.controls.forEach((control, index) => {
      if (control.value) {
        notifyContacts.push(this.permissions[index].acl_user_id);
      }
    });

    // comment: coerce force to be a boolean
    const payload = {
      new_event: this.event.value,
      notify_contacts: notifyContacts,
      force: !!this.forceWorkflow.value,
      started_at: this.getJSTime(this.started_at.value, this.started_at_time.value)
    };

    this.workflowService.updateWorkflow(this.tran.id, payload)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'Workflow successfully updated');
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update workflow', data);
        this.cancel();
      });
  }
}
