import {Injectable} from '@angular/core';

@Injectable()
export class MortgageCalculatorService {

  constructor() {
  }

  static roundTo(value, roundToValue) {
    return (Math.round(value / roundToValue) * roundToValue).toFixed(2);
  }

  // given the amount (borrowed money) and interest rate: calculate the monthly payment
  static computeMonthlyPayment(amount, offset, annualInterestRate, numberOfPayments, precision?) {
    if (!precision) {
      precision = 0.001;
    }
    let low = 0.0;
    let high = amount;
    const tolerance = precision / 2 || 0.01; // of a unit of currency (one dollar)

    while (high - low > tolerance) {
      const guessPayment = (high + low) / 2.0;
      const presentValue = MortgageCalculatorService.computePresentValue(offset, guessPayment, annualInterestRate, numberOfPayments);
      if (presentValue === amount) {
        return guessPayment;
      } else if (presentValue >= amount) {
        high = guessPayment;
      } else {
        low = guessPayment;
      }
    }
    return high;
  }

  static computePresentValue(offsetMonths, payment, annualInterestRate, numberOfPayments) {
    const mir = (annualInterestRate * 0.01) / 12.0; // monthly interest rate
    const p = payment;
    let sum = 0.0;
    for (let i = offsetMonths; i < offsetMonths + numberOfPayments; i++) {
      sum += (p / Math.pow((1 + mir), (i + 1)));
    }
    return sum;
  }

  // For a fixed rate loan. Given the amount (borrowed money) and the monthly payment: compute the effective interest rate
  static computeFixedRate(amount, numberOfPayments, monthlyPayment, precision?) {
    if (!precision) {
      precision = 0.001;
    }
    let low = 0.0;
    let high = 100.00;
    const tolerance = precision / 2;

    while (high - low > tolerance) {
      const guessRate = (high + low) / 2.0;
      const presentValue = MortgageCalculatorService.computePresentValue(0, monthlyPayment, guessRate, numberOfPayments);
      if (presentValue === amount) {
        return guessRate;
      } else if (presentValue >= amount) {
        low = guessRate;
      } else {
        high = guessRate;
      }
    }
    return high;
  }

  // For an ARM loan. Given the amount (borrowed money) and the monthly payment for the initial and the following periods: compute the effective interest rate
  static computeARMRate(amount, numberOfPayments, monthlyPayment, nextNumberOfPayments, nextMonthlyAmount, precision?) {
    if (!precision) {
      precision = 0.001;
    }
    let low = 0.0;
    let high = 100.00;
    const tolerance = precision / 2 || 0.01; // of a percent

    while (high - low > tolerance) {
      const guessRate = (high + low) / 2.0;
      const presentValue1 = MortgageCalculatorService.computePresentValue(0, monthlyPayment, guessRate, numberOfPayments);
      const presentValue2 = MortgageCalculatorService.computePresentValue(numberOfPayments /*offset*/, nextMonthlyAmount, guessRate, nextNumberOfPayments);
      const presentValue = presentValue1 + presentValue2;
      if (presentValue === amount) {
        return guessRate;
      } else if (presentValue >= amount) {
        low = guessRate;
      } else {
        high = guessRate;
      }
    }
    return high;
  }

  // compute APR for a fixed rate loan
  static computeFixedRateAPR(amount, charges, annualInterestRate, numberOfPayments, _precision?) {
    const precision = _precision || 0.125;
    const monthlyPaymentAmount = MortgageCalculatorService.computeMonthlyPayment(amount, 0, annualInterestRate, numberOfPayments);
    const result = MortgageCalculatorService.computeFixedRate(amount - charges, numberOfPayments, monthlyPaymentAmount);
    return MortgageCalculatorService.roundTo(result, precision);
  }

  // compute APR for an ARM loan
  static computeARMAPR(amount, charges, annualInterestRate, numberOfPayments, nextInterestRate, nextNumberOfPayments, _precision?) {
    const precision = _precision || 0.125;
    const payments = this.computeARMPayments(amount, charges, annualInterestRate, numberOfPayments, nextInterestRate, nextNumberOfPayments, precision);
    const monthlyPayment = payments[0];
    const nextMonthlyPayment = payments[1];

    // compute the effective rate for the entire term
    const result = this.computeARMRate(amount - charges, numberOfPayments, monthlyPayment, nextNumberOfPayments, nextMonthlyPayment);
    return MortgageCalculatorService.roundTo(result, precision);
  }

  static computeARMPayments(amount, charges, annualInterestRate, numberOfPayments, nextInterestRate, nextNumberOfPayments, precision) {
    const monthlyPayment = MortgageCalculatorService.computeMonthlyPayment(amount, 0, annualInterestRate, numberOfPayments + nextNumberOfPayments, precision);
    const presentValueFixed = MortgageCalculatorService.computePresentValue(0, monthlyPayment, annualInterestRate, numberOfPayments);
    const residualAmount = amount - presentValueFixed;
    // compute the monthly payment for the next period
    const nextMonthlyPayment = MortgageCalculatorService.computeMonthlyPayment(residualAmount, numberOfPayments /* offset*/, nextInterestRate, nextNumberOfPayments, precision);
    return [monthlyPayment, nextMonthlyPayment];
  }

  static computeFixedAmortizationTable(amount, annualInterestRate, numberOfPayments) {
    const mir = (annualInterestRate * 0.01) / 12;
    let monthlyPayment = MortgageCalculatorService.computeMonthlyPayment(amount, 0, annualInterestRate, numberOfPayments, 0.0001);
    monthlyPayment = Math.round(Math.ceil(monthlyPayment * 100)) / 100;

    const table = new Array(numberOfPayments);
    let accumulatedEquity = 0;
    let balance = amount;
    for (let i = 0; i < 360; i++) {
      const interest = balance * mir;
      const principal = monthlyPayment - interest;
      const deltaEquity = (monthlyPayment - interest);
      accumulatedEquity += deltaEquity;
      balance = balance - deltaEquity;
      table[i] = {balance: balance, accumulatedEquity: accumulatedEquity, interest: interest, principal: principal};
    }
    return table;
  }

  static computeARMAmortizationTable(amount, annualInterestRate, numberOfPayments, nextInterestRate, nextNumberOfPayments) {
    const mir = (annualInterestRate * 0.01) / 12;
    const monthlyPayment = MortgageCalculatorService.computeMonthlyPayment(amount, 0, annualInterestRate, numberOfPayments, 0.0001);
    // const roundedMonthlyPayment = Math.round(monthlyPayment * 100) * 0.01;
    const arr = new Array(360);
    let equity = 0;
    let owed = amount;
    let i;
    for (i = 0; i < 360; i++) {
      const interest = owed * mir;
      const delta = (monthlyPayment - interest);
      equity += delta;
      owed = owed - delta;
      arr[i] = equity;
    }
  }
}
