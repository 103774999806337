<div class="mt-3">
  <div *ngIf="!mode">
    <div>
      <h5>Pull Credit Report from Our Integrated CRAs</h5>
      <button *ngIf="!mode" class="btn btn-lg btn-primary" (click)="UpdateMethod('Gen')" type="submit">Pull Credit</button>
    </div>
    <div>
      <h5>------ OR ------</h5>
    </div>
    <div>
      <h5>Upload Existing Credit Report in XML Format</h5>
      <button class="btn btn-lg btn-primary" (click)="UpdateMethod('Upload')" type="submit">Upload XML</button>
    </div>
  </div>
  <button *ngIf="!!mode" class="btn btn-lg btn-secondary ms-2" (click)="reset()" type="submit">Restart</button>
  <app-gen-credit-report *ngIf="mode=='Gen'"></app-gen-credit-report>
  <app-upload-credit-report *ngIf="mode=='Upload'"></app-upload-credit-report>
</div>
