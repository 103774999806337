import {Component, Injector, OnInit} from '@angular/core';
import {Credit} from '../../../../models/credit';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-segment-credits',
  templateUrl: './view-segment-credits.component.html',
})
export class ViewSegmentCreditsComponent extends CompBaseComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.items = Credit.deserializeArray(this.doc.recursive_attributes.credits);
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
