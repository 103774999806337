import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Hmda} from '../../../../models/hmda';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-edit-segment-hmda',
  templateUrl: './edit-segment-hmda.component.html',
})
export class EditSegmentHmdaComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Hmda;
  public taken_bys = Hmda.taken_bys;

  // form and form controls
  public form: FormGroup;
  public race_visual: FormControl;
  public ethnicity_visual: FormControl;
  public gender_visual: FormControl;
  public taken_by: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'hmda';
    this.section = this.applicant;
    this.createFormControls(this.item);
    this.createForm();
  }

  createFormControls(item): void {
    this.race_visual = new FormControl(item.race_visual);
    this.ethnicity_visual = new FormControl(item.ethnicity_visual);
    this.gender_visual = new FormControl(item.gender_visual);
    this.taken_by = new FormControl(item.taken_by);
  }

  createForm(): void {
    this.form = new FormGroup({
      race_visual: this.race_visual,
      ethnicity_visual: this.ethnicity_visual,
      gender_visual: this.gender_visual,
      taken_by: this.taken_by,
    });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new Hmda(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
