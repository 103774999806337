import {Component, Injector, OnInit} from '@angular/core';
import {DownPaymentComponent} from '../down-payment/down-payment.component';

@Component({
  selector: 'app-edit-segment-down-payment',
  templateUrl: '../down-payment/edit-segment-down-payment.component.html',
})
export class EditSegmentDownPaymentComponent extends DownPaymentComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
