import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem,} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dnd',
  templateUrl: './dnd.component.html',
  styleUrl: './dnd.component.css',
})
export class DNDComponent implements OnInit {
  @Input() borrowerObjTable = {};
  @Input() borrowerGroupsIn: Array<Array<string>>;
  @Output() borrowerGroupsOut = new EventEmitter();
  public borrowerNameGroups: Array<Array<string>>;
  public borrowerCount = 0;
  public borrowerList: Array<string>;
  public group1: Array<string> = [];
  public group2: Array<string> = [];
  public group3: Array<string> = [];
  public group4: Array<string> = [];
  public doneGrouping: boolean;

  constructor() {
  }

  ngOnInit() {
    this.reload();
  }

  reload(): void {
    this.doneGrouping = false;
    this.borrowerList = Object.values(this.borrowerObjTable);
    this.borrowerCount = this.borrowerList.length;
    for (let i = 1; i <= this.borrowerCount; i++) {
      this['group' + i] = []
    }
    let index = 0;
    this.borrowerGroupsIn.forEach((gp) => {
      if (gp.length > 0) {
        gp.forEach((bor) => {
          this['group' + (index + 1)].push(this.getBorrowerName(bor));
          this.borrowerList.splice(this.borrowerList.findIndex(elt => elt === bor), 1);
        });
        index += 1;
      }
    });
    if (!this.borrowerGroupsIn || this.borrowerGroupsIn.length === 0) {
      this.reset();
      return
    }
    this.onDone();
  }

  getBorrowerName(bor: string): string {
    return this.borrowerObjTable[bor];
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    }
    if (event.container.id === 'borrowerBucket' || event.container.data.length < 2) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  onDone(): void {
    const borrowerGroups = [];
    const borrowerNameGroups = []
    for (let i = 1; i <= this.borrowerCount; i++) {
      const group = this['group' + i];
      if (group.length > 0) {
        let borGroup = [];
        let borNameGroup = [];
        group.forEach((elt: string) => {
          for (const k in this.borrowerObjTable) {
            if (this.borrowerObjTable[k] === elt) {
              borGroup.push(k);
              borNameGroup.push(elt)
              break
            }
          }
        })
        borrowerGroups.push(borGroup);
        borrowerNameGroups.push(borNameGroup);
      }
    }
    this.borrowerGroupsOut.emit(borrowerGroups);
    this.borrowerNameGroups = borrowerNameGroups;
    this.doneGrouping = true;
  }

  isDone(): boolean {
    return this.borrowerList.length == 0;
  }

  getBorrowerNames(): Array<string> {
    return Object.values(this.borrowerList);
  }

  resetGrouping(): void {
    this.doneGrouping = false;
  }

  convertToRoman(i: number): string {
    return ['I', 'II', 'III', 'IV'][i]
  }

  reset(): void {
    for (let i = 1; i <= 4; i++) {
      this['group' + i] = []
    }
    this.borrowerList = Object.values(this.borrowerObjTable);
    this.doneGrouping = false;
  }
}
