import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {LoanCharacteristics} from '../../../../models/loan-characteristics';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';
import {globals} from '../../../../shared/globals/globals';

@Component({
  selector: 'app-edit-segment-loan-characteristics',
  templateUrl: './edit-segment-loan-characteristics.component.html',
})
export class EditSegmentLoanCharacteristicsComponent extends CompBaseComponent implements OnInit {
  @Input() public item: LoanCharacteristics;
  public maturity_types = LoanCharacteristics.maturity_types;
  public lien_type_codes_ex = LoanCharacteristics.lien_type_codes_ex;
  public qm_types = LoanCharacteristics.qm_types;
  public minDate = globals.date.minTranStartDate;
  public maxDate = globals.date.maxTranEndDate;
  public isFixedRateLoan: boolean;

  // form and form controls
  form: FormGroup;
  lien_type_code_ex: FormControl;
  balloon_indicator: FormControl;
  maturity_periods_no: FormControl;
  maturity_type: FormControl;
  balloon_payment_amount: FormControl;
  homebuyer_education_completion_indicator: FormControl;
  hoepa_indicator: FormControl;
  maximum_lifetime_rate_increase: FormControl;
  mi_coverage_percent: FormControl;
  apr_spread: FormControl;
  qm_type: FormControl;
  is_reverse: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'loan_characteristics';
    this.section = 'lo';
    this.isFixedRateLoan = this.analyzerService.isFixedRateLoan(this.doc['recursive_attributes']);
    this.createFormControls(this.item);
    this.createForm();
    this.reload();
  }

  createFormControls(item): void {
    this.lien_type_code_ex = new FormControl(item.lien_type_code_ex, AppValidators.present);
    this.balloon_indicator = new FormControl(item.balloon_indicator);
    this.maturity_periods_no = new FormControl(item.maturity_periods_no);
    this.maturity_type = new FormControl(item.maturity_type);
    this.balloon_payment_amount = new FormControl(item.balloon_payment_amount);
    this.homebuyer_education_completion_indicator = new FormControl(item.homebuyer_education_completion_indicator);
    this.maximum_lifetime_rate_increase = new FormControl(item.maximum_lifetime_rate_increase, Validators.pattern(globals.pattern.percentThree));
    this.mi_coverage_percent = new FormControl(item.mi_coverage_percent, Validators.pattern(globals.pattern.percentThree));
    this.apr_spread = new FormControl(item.apr_spread, Validators.pattern(globals.pattern.percentTwo));
    this.hoepa_indicator = new FormControl(item.hoepa_indicator);
    this.qm_type = new FormControl(item.qm_type, AppValidators.present);
    this.is_reverse = new FormControl(item.is_reverse);
  }

  createForm(): void {
    this.form = new FormGroup({
      lien_type_code_ex: this.lien_type_code_ex,
      balloon_indicator: this.balloon_indicator,
      maturity_periods_no: this.maturity_periods_no,
      maturity_type: this.maturity_type,
      balloon_payment_amount: this.balloon_payment_amount,
      homebuyer_education_completion_indicator: this.homebuyer_education_completion_indicator,
      hoepa_indicator: this.hoepa_indicator,
      maximum_lifetime_rate_increase: this.maximum_lifetime_rate_increase,
      mi_coverage_percent: this.mi_coverage_percent,
      apr_spread: this.apr_spread,
      qm_type: this.qm_type,
      is_reverse: this.is_reverse,
    });
  }

  reload(force?: boolean): void {
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    if (!this.form.valid) {
      return;
    }

    const updated = new LoanCharacteristics(this.form.value);
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanApp(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }
}
