<div *ngIf="error && reloaded">
  <div class="container mt-5">
    <h3>System Error: Cannot get configuration</h3>

    <div>
      <h3>Error formatted</h3>
      {{errorData | json}}
    </div>

    <div>
      <h3>Error not formatted</h3>
      {{errorData}}
    </div>
  </div>
</div>

<router-outlet *ngIf="!error && reloaded"></router-outlet>
