import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../../comp-base.component';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../../shared/validators/app-validators.validator';
import {CreditReport} from '../../../../../models/credit-report';
import {CreditReportService} from '../../../../../services/credit-report.service';
import {Document} from '../../../../../models/document';
import {FormUtil} from '../../../../form-util';
import {globals} from "../../../../../shared/globals/globals";

@Component({
  selector: 'app-gen-credit-report',
  templateUrl: './gen-credit-report.component.html',
})
export class GenCreditReportComponent extends CompBaseComponent implements OnInit {
  public readonly creditProviders = CreditReport.getCreditProviders();
  public readonly creditReportTypes = CreditReport.credit_report_types;
  public creditReportService: CreditReportService;
  public loanAppDoc: Document;
  public applicants: Array<any>;

  // form and form controls
  form: FormGroup;
  creditReportType: FormControl;
  existingOrderId: FormControl;
  docName: FormControl;
  description: FormControl;
  reference: FormControl;
  username: FormControl;
  password: FormControl;
  creditProvider: FormControl;
  who: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.creditReportService = this.injector.get(CreditReportService);
    const count = this.analyzerService.borrowerCount(this.doc.recursive_attributes);
    this.applicants = CreditReport.credit_parties.filter((elt) => {
      switch (elt.key) {
        case 'borrower_1':
          return count >= 1;
        case 'borrower_2':
          return count >= 2;
        case 'joint':
          return count >= 2;
        case 'borrower_3':
          return count >= 3;
        case 'borrower_4':
          return count >= 4;
      }
    });
    this.loanAppDoc = this.doc;
    this.reset();
  }

  reset(): void {
    this.reloaded = false;
    this.createFormControls();
    this.createForm();
    setTimeout(() => {
      this.reloaded = true;
    }, 0)
  }

  createFormControls(): void {
    this.creditReportType = new FormControl(null, AppValidators.present);
    this.existingOrderId = new FormControl(null);
    this.docName = new FormControl(null, AppValidators.present);
    this.description = new FormControl(null);
    this.username = new FormControl('', AppValidators.present);
    this.password = new FormControl('', AppValidators.present);
    this.creditProvider = new FormControl(null, AppValidators.present);
    this.who = new FormControl(null, AppValidators.present);
  }

  createForm(): void {
    this.form = new FormGroup({
      existingOrderId: this.existingOrderId,
      creditReportType: this.creditReportType,
      docName: this.docName,
      description: this.description,
      username: this.username,
      password: this.password,
      creditProvider: this.creditProvider,
      who: this.who
    });
  }

  create(event: any): void {
    event.preventDefault();
    const tranId = this.tranService.getCurrentTranSync().id;
    const payload = {
      tran_id: tranId,
      loan_app_doc_id: this.loanAppDoc.id,
      section: this.who.value,
      doc_name: this.docName.value,
      description: this.description.value,
      username: this.username.value,
      password: this.password.value,
      credit_provider: this.creditProvider.value
    };
    this.reloaded = false;
    this.creditReportService.createDocument(payload)
      .then((doc) => {
        const message = `Credit report was successfully created and a <b>request</b> was sent to the credit service provider. ` +
          `Please <b>Check Credit Report Status</b> in about a minute. The status should be <b>Completed</b> after the credit report has been generated`;
        FormUtil.genSuccessMessage(this.sharedService, message, false);
        this.navigateToSegment({section: globals.section.terms, segment: globals.segment.creditReports, sectionName: 'Tools'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create credit report request', data);
        console.log('FAILED', data);
        this.reloaded = true;
      });
  }

  refresh(event: any): void {
    event.preventDefault();
    const tranId = this.tranService.getCurrentTranSync().id;
    const existingOrderId = this.existingOrderId.value;
    const payload = {
      tran_id: tranId,
      loan_app_doc_id: this.loanAppDoc.id,
      section: this.who.value,
      doc_name: this.docName.value,
      description: this.description.value,
      username: this.username.value,
      password: this.password.value,
      credit_provider: this.creditProvider.value
    };
    this.reloaded = false;
    this.creditReportService.refreshDocument(existingOrderId, payload)
      .then((doc) => {
        const message = `Credit report <b>refresh</b> was successfully ordered and a <b>request</b> was sent to the credit service provider. ` +
          `Please <b>Check Credit Report Status</b> in about a minute. The status should be <b>Completed</b> after the credit report <b>refresh</b> has been generated`;
        FormUtil.genSuccessMessage(this.sharedService, message, false);
        this.navigateToSegment({section: globals.section.terms, segment: globals.segment.creditReports, sectionName: 'Tools'});
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to create credit report request', data);
        console.log('FAILED', data);
        this.reloaded = true;
      });
  }

  isExistingOrderIdRequired(): boolean {
    return this.creditReportType.value === 'refresh';
  }

  cancel(): void {
    this.reset();
    this.eventEmitted.emit({type: 'Reload'});
  }
}
