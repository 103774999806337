<mat-card class="mt-2 mb-1">
  <mat-card-title>

    <app-segment-header [enable]="false" [header]="'Error Checking'" [help]="true">
      <p>
        Error Checking produces error, warning, and info messages.
      </p>
      <ul>
        <li>
          <span class="fw-bold bg-danger text-white" style="padding: 1px 5px;">Error</span> messages are
          displayed in red and need your immediate attention.
        </li>
        <li>
          <span class="fw-bold bg-warning text-white" style="padding: 1px 5px;">Warning</span> messages are displayed in yellow.
          Most warnings need your attention. If in doubt, please consult your loan officer.
        </li>
        <li>
          <span class="fw-bold bg-info text-white" style="padding: 1px 5px;">Info</span> messages are displayed in blue and are not errors.
        </li>
        <li>
          If you click the segment column of any message, it will take you directly to the data segment that produced this message.
        </li>
        <li>
          You can
          <span class="fw-bold">filter</span> the displayed messages by severity by checking or unchecking
          the three checkboxes above the displayed table.
        </li>
        <li>
          You can also
          <span class="fw-bold">sort</span> the messages by each of the four columns by clicking the column header.
        </li>
      </ul>

    </app-segment-header>

  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="form">
      <button (click)="runErrorCheck($event)" class="btn btn-lg btn-primary" type="button">Run Error Checking</button>
    </form>
  </mat-card-content>
</mat-card>
