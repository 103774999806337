import {Component, Injector, OnInit} from '@angular/core';
import {McrComponent} from '../mcr/mcr.component';
import {FormUtil} from '../../../../form-util';
import {Mcr} from '../../../../../models/mcr';
import {User} from '../../../../../models/user';
import moment from 'moment';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-add-tran-mcr',
  templateUrl: './add-tran-mcr.component.html',
})
export class AddTranMcrComponent extends McrComponent implements OnInit {
  public op = 'Manage';
  public user: User;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.userService.getUserSync();
    this.createFormControls();
    this.createForm();
    this.reload();
  }

  createFormControls(): void {
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  close(event: any): void {
    event.preventDefault();
    this.eventEmitted.emit({type: 'Reload'});
  }

  create(): void {
    const quarter = moment().quarter();
    const year = moment().year();
    const payload = {
      quarter: quarter,
      year: year,
      user_id: this.user.id, // comment: not used by backend
      mcr_type: Mcr.TYPE.TRAN,
      tran_id: this.tran.id,
      organization_id: this.user.organization_id,
    };
    this.mcrService.createMcr(payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        FormUtil.genSuccessMessage(this.sharedService, 'MCR successfully created');
      })
      .catch(() => {
        console.log('BAD: add mcr failed');
      });
  }

  cancel(): void {
    this.eventEmitted.emit({type: 'Reload'});
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
