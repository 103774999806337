<mat-card *ngIf="reloaded&&canCreateTransaction">
  <mat-card-title>Create a New Loan</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="name" matInput placeholder="Name" maxlength="{{MAX_LEN.GEN_NAME}}" required>
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
              Name is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="description" matInput placeholder="Description" maxlength="{{MAX_LEN.DESC}}">
            <mat-error *ngIf="description.hasError('required')">
              Description is required
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
              Description is too short
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-4">
          <button (click)="create(true)" [disabled]="!form.valid" class="btn btn-lg btn-primary">Create with Loan Application</button><br>
          <mat-hint>This is the default. A loan application is created with the loan.</mat-hint>
        </div>
        <div class="col-lg-4">
          <button (click)="create(false)" [disabled]="!form.valid" class="btn btn-lg btn-primary">Create Empty Loan</button><br>
          <mat-hint>No loan application is created. Choose this option if you intend to import a loan application (MISMO file) into the new loan.</mat-hint>
        </div>
        <div class="col-lg-4">
          <button (click)="reset()" class="ms-2 btn btn-lg btn-secondary">Reset</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
