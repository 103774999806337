import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'page-500',
  templateUrl: './page-500.component.html',
  styleUrls: ['./page-500.component.scss']
})
export class Page500Component implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
