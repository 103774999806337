import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HomeComponent} from '../../home/home.component';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-ez-uploads-instructions',
  templateUrl: './ez-uploads-instructions.component.html',
})
export class EzUploadsInstructionsComponent extends HomeComponent implements OnInit, OnDestroy {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.pageTitle = 'ezUploads Instructions';
    super.ngOnInit();
    this.reloadOnSync();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.reloadHome()
      .then(() => {
        setTimeout(() => {
          this.sharedService.emitChange({
            type: 'ChangePage',
            title: this.pageTitle,
            help: Help.helpKeys.homeStep
          });
          this.reloaded = true;
        }, 0);
      });
  }
}
