<mat-card class="mt-1 mb-1 w-50">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [header]="'Invoice'" [help]="false"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice ID</div>
      <div class="col-lg-8">{{item.id}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice date</div>
      <div class="col-lg-8">{{item.created_at | date:'MM/dd/yyyy'}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Invoice amount</div>
      <div class="col-lg-8">{{item.amount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Discount</div>
      <div class="col-lg-8">{{item.discount | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Balance</div>
      <div class="col-lg-8">{{item.balance | currency}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Status</div>
      <div class="col-lg-8">{{item.getStatus()}}</div>
    </div>

    <form [formGroup]="form" novalidate [hidden]="item.isPaid()" class="mt-1">
      <div class="row mb-1">
        <div class="col text-primary fw-bold">
          You will not be asked to provide your credit card information!
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <button (click)="navToPay()" class="btn btn-lg btn-primary mt-2">Pay Invoice</button>
        </div>
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Coupon</mat-label>
            <input formControlName="coupon" matInput placeholder="Coupon" minlength="4" maxlength="12">
            <mat-error *ngIf="coupon.hasError('required')">
              Coupon is required
            </mat-error>
            <mat-error *ngIf="coupon.hasError('minlength')">
              Coupon is too short
            </mat-error>
            <mat-error *ngIf="coupon.hasError('maxlength')">
              Coupon is too long
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <button (click)="applyCoupon()" [disabled]="coupon.invalid" class="btn btn-lg btn-primary mt-2">Apply Coupon</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

