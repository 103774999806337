import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompBaseComponent} from '../../../../comp-base.component';
import {CfaService} from '../../../../../services/cfa.service';

@Component({
  selector: 'app-undrawn',
  template: '',
})
export class CFAComponent extends CompBaseComponent implements OnInit {
  @Input() public hash: any;
  @Input() public hashEntry: string;
  @Input() public bold: boolean;
  public cfaService: CfaService;
  public prefix: string;
  public prefixString: string;
  public currency: boolean;
  public name: boolean;
  public year: boolean;

  // form fields
  public form: FormGroup;
  public amount: FormControl;

  constructor(public injector: Injector) {
    super(injector);
    this.cfaService = this.injector.get(CfaService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.prefixString = '';
    this.currency = false;
    this.year = false;
    this.name = false;
    this.prefix = this.hash[this.hashEntry]['prefix'];
    switch (this.prefix) {
      case 'pm':
        this.prefixString = '(+/-)';
        this.currency = true;
        break;
      case 'p':
        this.prefixString = '(+)';
        this.currency = true;
        break;
      case 'm':
        this.prefixString = '(-)';
        this.currency = true;
        break;
      case 'y':
        this.prefixString = 'Year';
        this.year = true;
        break;
      case 'borrower_name':
      case 'business_name':
        this.name = true;
        break;
    }
  }
}
