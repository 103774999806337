import {ModelBase} from './model-base';

export class FormTop extends ModelBase {
  joint_credit_report: boolean;
  borrower_count: string;
  borrower_groups: Array<Array<string>>;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'joint_credit_report') {
        this[prop] = super.getBooleanProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
    if (!this.borrower_groups) {
      this.borrower_groups = [];
    }
  }

  toServerPayload(): any {
    return {
      joint_credit_report: ModelBase.toServerExplicitBoolean(this.joint_credit_report),
      borrower_count: this.borrower_count,
      borrower_groups: this.borrower_groups,
    };
  }

  getGroupingSummary(): string {
    if (!this.borrower_groups) {
      return '(Not set)';
    }
    const count = this.borrower_groups.length;
    switch (count) {
      case 1:
        return 'One group'
      case 2:
        return 'Two groups'
      case 3:
        return 'Three groups'
      case 4:
        return 'Four groups'
    }
  }
}
