import {ModelBase} from './model-base';

export class Address extends ModelBase {
  public static residence_types_ex = [
    {key: 'Current', value: 'Current Address'},
    {key: 'Mailing', value: 'Mailing Address'},
    {key: 'Prior', value: 'Prior Address'},
  ];
  public static residence_terms_ex = [
    {key: 'LivingRentFree', value: 'Living Rent Free'},
    {key: 'Own', value: 'Own'},
    {key: 'Rent', value: 'Rent'},
  ];

  index: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  zip_four: string;
  country: string;
  years_no: string;
  months_no: string;
  residence_type_ex: string;
  residence_term_ex: string;
  rent: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'years_no' || prop === 'months_no') {
        this[prop] = this.getNumberProp(prop, object);
      } else if (prop === 'rent') {
        this[prop] = this.getNumberProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  static deserializeArray(arr: Array<any>): Array<Address> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Address(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  getResidenceType(): string {
    const found = Address.residence_types_ex.find((elt) => elt.key === this.residence_type_ex);
    return found ? found['value'] : '';
  }

  getResidenceTerm(): string {
    const found = Address.residence_terms_ex.find((elt) => elt.key === this.residence_term_ex);
    return found ? found['value'] : '';
  }

  toServerPayload(): any {
    return {
      index: this.index,
      residence_type_ex: this.residence_type_ex,
      residence_term_ex: this.residence_term_ex,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      zip_four: this.zip_four,
      country: this.country,
      years_no: this.toServerString(this.years_no),
      months_no: this.toServerString(this.months_no),
      rent: this.toServerDecimal(this.rent)
    };
  }
}
