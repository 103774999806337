import {Component, Injector, OnInit} from '@angular/core';
import {AddressComponent} from '../address/address.component';

@Component({
  selector: 'app-edit-segment-address',
  templateUrl: '../address/edit-segment-address.component.html',
})
export class EditSegmentAddressComponent extends AddressComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
