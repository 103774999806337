import {Component, Injector, Input, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Invoice} from '../../../../models/invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceService} from '../../../../services/invoice.service';
import {Help} from '../../../../models/help';

@Component({
  selector: 'app-admin-view-invoice',
  templateUrl: './admin-view-invoice.component.html',
})
export class AdminViewInvoiceComponent extends CompBaseComponent implements OnInit {
  @Input() public item: Invoice;
  public invoiceId: number;
  public pageTitle = 'View Invoice';
  private invoiceService: InvoiceService;

  constructor(public injector: Injector,
              private router: Router,
              private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
    this.invoiceService = this.injector.get(InvoiceService);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.any
      });
    }, 0);

    super.ngOnInit();

    this.route.paramMap.subscribe((map) => {
      this.invoiceId = map['params']['id'];
      this.reload();
    });

  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.invoiceService.getInvoice(this.invoiceId)
      .then((invoice: Invoice) => {
        this.item = invoice;
        this.reloaded = true;
      });
  }

  navToPay(): void {
    this.invoiceService.setCurrentInvoice(this.item);
    this.router.navigate(['/account/invoice/pay']);
  }

  navToEdit(): void {
    this.invoiceService.setCurrentInvoice(this.item);
    this.router.navigate(['/admin/edit-invoice', this.item.id]);
  }
}
