<mat-card>
  <mat-card-title>Organization</mat-card-title>
  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Name</div>
      <div class="col-lg-8">{{item.name}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Description</div>
      <div class="col-lg-8">{{item.description}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">NMLS</div>
      <div class="col-lg-8">{{item.nmls}}</div>
    </div>

    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-4 fw-bold">Admin</div>
      <div class="col-lg-8" *ngIf="item.admin">Name: {{item.admin.full_name}} - Email: {{item.admin.email}} - Phone: {{item.admin.cell_phone || 'N/A'}}</div>
      <div class="col-lg-8" *ngIf="!item.admin">
        <small>(Admin not assigned)</small>
      </div>
    </div>

    <div class="row mt-3" [hidden]="!user.isOrgAdmin()">
      <div class="col">
        <button (click)="editOrg($event)" class="btn btn-lg btn-primary">Edit</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
