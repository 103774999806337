<div class="row h-100 mt-5">
  <div class="col mx-auto">
    <mat-card class="mt-1 mb-2">
      <mat-card-title>Amortization Table
        <button style="float: right;" (click)="edit()" class="btn btn-lg btn-primary">Edit</button>
      </mat-card-title>
      <mat-card-content>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th class="w-16">Month</th>
              <th class="w-16">Year/Month</th>
              <th class="w-16">Balance</th>
              <th class="w-16">Accumulated Equity</th>
              <th class="w-16">Principal</th>
              <th class="w-16">Interest</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ent of amorTable" class="hand-pointer">
              <td>{{ent.month}}</td>
              <td>{{ent.monthYearString}}</td>
              <td>{{ent.balance | number:'1.2-2'}}</td>
              <td>{{ent.accumulatedEquity | number:'1.2-2'}}</td>
              <td>{{ent.principal | number:'1.2-2'}}</td>
              <td>{{ent.interest | number:'1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
