import {Component, Injector, OnInit} from '@angular/core';
import {CompBaseComponent} from '../../../comp-base.component';
import {Mcr} from '../../../../models/mcr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-view-loan-mcr',
  templateUrl: './view-loan-mcr.component.html',
})
export class ViewLoanMcrComponent extends CompBaseComponent implements OnInit {
  public mcrId: number;
  public mcr: Mcr;

  constructor(public injector: Injector, private router: Router,) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload();
      return;
    }
    super.onEvent(event);
  }

  reload(force?: boolean): void {
    this.mode = 'View';
    this.mcrId = this.mcrService.getCurrentTranMcrId();
    this.reloaded = false;
    this.mcrService.getMcr(this.mcrId)
      .then((mcr: Mcr) => {
        this.mcr = mcr;
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      })
      .catch((data) => {
        this.mcr = null;
        this.router.navigate(['/org/loan-mcrs']);
        setTimeout(() => {
          this.reloaded = true;
        }, 0);
      });
  }
}
