<!-- 2019-08-07 reviewed this help-->
<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Notes</mat-card-title>

  <mat-card-content>

    <p>
      The Notes facility is only available to the loan owner (the loan officer who created the loan).
      Its purpose is to record noteworthy information about the loan.
    </p>

    <strong>To create a note:</strong>

    <ul>
      <li>Click the Add (plus) button and provide a title and body.</li>
      <li>Do not include HTML tags.</li>
    </ul>

    <strong>To edit a note:</strong>

    <ul>
      <li>Click the note title.</li>
    </ul>

    <strong>To delete a note</strong>

    <ul>
      <li>Click the Delete button.</li>
    </ul>

  </mat-card-content>
</mat-card>
