import {ModelBase} from './model-base';

export class Contact extends ModelBase {
  id: number;
  index: number;
  email: string;
  first: string;
  last: string;
  full_name: string;
  is_text_messaging: boolean;
  roles: number;
  thumbnail: string;
  hidden: boolean;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'hidden') {
        this[prop] = object[prop];
      } else {
        this[prop] = object[prop];
      }
    }
  }

  get fullName(): string {
    return `${this.first} ${this.last}`;
  }

  static deserializeArray(arr: Array<any>): Array<Contact> {
    const result = [];
    let index = 0;
    for (const obj of arr) {
      const item = new Contact(obj);
      item.index = index++;
      result.push(item);
    }

    return result;
  }

  isAdmin(): boolean {
    return (this.roles & Contact.ROLES.ADMIN) > 0;
  }

  getRolesString(): string {
    return ModelBase.getRolesString(this.roles);
  }

  getThumbnailPath(): string {
    return this.getAbsolutePath(this.thumbnail);
  }

  getName(): string {
    return `${this.first} ${this.last}`;
  }

  toFullNameAndEmail(): string {
    return `${this.full_name} (${this.email})`;
  }
}
