import {Component, Injector, Input, OnInit} from '@angular/core';
import {Help} from '../../../../models/help';
import {Mcr} from '../../../../models/mcr';
import {OrgMcrComponent} from '../org-mcr/org-mcr.component';
import {FormControl, FormGroup} from '@angular/forms';
import {globals} from '../../../../shared/globals/globals';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-org-mcr-xml',
  templateUrl: './org-mcr-xml.component.html',
})
export class OrgMcrXmlComponent extends OrgMcrComponent implements OnInit {
  @Input() mcr: Mcr;
  public pageTitle = 'Organization MCR';
  public mcrTitle: string;
  public urlSafe: SafeResourceUrl;
  public url: string;
  public downloading = false;
  public xmlMcrValid = false;
  public form: FormGroup;
  public states: FormControl;
  public include_serviced: FormControl;

  constructor(public injector: Injector,
              private sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.mcrTitle = `Organization MCR for Q${this.mcr.quarter} ${this.mcr.year}`;
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.loansList
      });
    }, 0);
    this.createFormControls();
    this.createForm();
  }

  onEvent(event: any) {
    super.onEvent(event);
  }

  createFormControls(): void {
    this.states = new FormControl(null, AppValidators.present);
    this.include_serviced = new FormControl(false);
  }

  createForm(): void {
    this.form = new FormGroup({
      states: this.states,
      include_serviced: this.include_serviced
    });
  }

  checkOrgXmlMcr(): void {
    const states = this.genStates();
    if (!states) {
      return;
    }
    this.mcrService.checkGenOrgXmlMcr(this.mcr.id, states)
      .then(() => {
        FormUtil.genSuccessMessage(this.sharedService, 'XML MCR report successfully generated');
        this.xmlMcrValid = true;
      })
      .catch((data) => {
        FormUtil.genErrorMessage(this.sharedService, 'XML MCR report generation failed', data);
      });
  }

  genOrgXmlMcr(): void {
    this.downloading = false;
    const states = this.genStates();
    if (!states) {
      return;
    }
    const includeServiced = this.include_serviced.value;
    this.url = `api/mcrs/${this.mcr.id}/gen_org_xml_mcr?states=${states}&include_serviced=${includeServiced}`;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    setTimeout(() => {
      this.downloading = true;
    }, 0);
  }

  genStates(): string {
    const statesCured = this.states.value.toUpperCase().trim().replace(/,/g, ' ').split(/\s+/);
    const errorMessages = [];
    statesCured.forEach((state) => {
      if (!globals.pattern.state.test(state)) {
        const errorMessage = `State ${state} is invalid`;
        errorMessages.push(errorMessage);
      }
    });
    if (errorMessages.length > 0) {
      FormUtil.genErrorMessage(this.sharedService, errorMessages.join('. '), null);
      return null;
    }
    return statesCured.join('_');
  }
}
