<mat-card>
  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Customer's email</mat-label>
            <input appAutoFocus formControlName="email" matInput placeholder="Customer's email" required maxlength="{{MAX_LEN.EMAIL}}">
            <mat-error *ngIf="email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              Email is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="add()" [disabled]="!form.valid" *ngIf="op==='Add'" class="btn btn-lg btn-primary" type="submit">Add</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
