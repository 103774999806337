<mat-card class="mt-2 mb-1">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateDocument" [header]="'Buydown'" [help]="true" [op]="'edit'">
      The final bought down interest rate. The rate that the applicant will pay after the buydown is applied.
    </app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div class="row pt-1 pb-1 border-bottom hover-gray">
      <div class="col-lg-6 fw-bold">Buydown rate</div>
      <div class="col-lg-6">{{item.getBuyDownRate() | percent:'1.3-3'}}</div>
    </div>
  </mat-card-content>
</mat-card>
