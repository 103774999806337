<mat-card *ngIf="reloaded">
  <mat-card-title>Choose Subscription & Term</mat-card-title>

  <mat-card-content>
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-lg-6">
          <app-box [close]="true" [type]="'primary'">
            <ul style="margin-bottom: 0">
              <li>You need to choose a service and subscription term.</li>
              <li>ezUploads is free</li>
            </ul>
          </app-box>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Services</mat-label>
            <mat-select formControlName="services" placeholder="Select service" required>
              <mat-option *ngFor="let service of servicesHash" [value]="service.key">{{service.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="services.hasError('required')">
              Service is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="md-icon-left w-100">
            <mat-label>Subscription Term</mat-label>
            <mat-select formControlName="subscription_term" placeholder="Select term" required>
              <mat-option *ngFor="let term of subscription_terms" [value]="term.key">{{term.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="subscription_term.hasError('required')">
              Subscription Term is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button (click)="createOrder()" [disabled]="!form.valid" *ngIf="op==='Modify'" class="btn btn-lg btn-primary">Submit</button>
          <button (click)="cancel()" class="ms-2 btn btn-lg btn-light">Cancel</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
