import {globals} from '../shared/globals/globals';
import {SharedService} from '../layouts/shared-service';

export class FormUtil {

  public static genSuccessMessage(sharedService: SharedService, message: string, fadeOut?: boolean): void {
    if (typeof fadeOut === 'undefined') {
      fadeOut = true;
    }
    const event = {messageId: sharedService.generateId(), level: globals.errorLevel.success, type: 'Message', text: `<strong>Success!</strong> ${message}!`, color: 'success', close: true, fadeOut: fadeOut};
    sharedService.emitChange(event);
  }

  public static genErrorMessage(sharedService: SharedService, errorMessage: string, data: any): void {
    const message = FormUtil.genErrorMessageOnly(errorMessage, data);
    const event = {
      messageId: sharedService.generateId(),
      type: 'Message',
      level: 3,
      text: `<strong>Error:</strong> ${message}`,
      color: 'danger',
      close: true,
      fadeOut: false
    };
    sharedService.emitChange(event);
    setTimeout(() => {
      globals.scrollTop();
    }, 0);
  }

  public static genErrorMessageOnly(errorMessage: string, data: any): string {
    let outErrorMessage = null;
    if (data && data.error && data.error.errors) {
      outErrorMessage = (data.error.errors.join('. '));
    } else if (data && data.errors) {
      outErrorMessage = (data.errors.join('. '));
    }
    outErrorMessage = outErrorMessage ? outErrorMessage : errorMessage;

    while (outErrorMessage.endsWith('.')) {
      outErrorMessage = outErrorMessage.substr(0, outErrorMessage.length - 1);
    }
    while (outErrorMessage.endsWith('!')) {
      outErrorMessage = outErrorMessage.substr(0, outErrorMessage.length - 1);
    }

    return outErrorMessage + '!';
  }

  public static genWarningMessage(sharedService: SharedService, warningMessage: string): void {
    const event = {
      messageId: sharedService.generateId(),
      type: 'Message',
      level: globals.errorLevel.warning,
      text: `<strong>Warning:</strong> ${warningMessage}!`,
      color: 'warning',
      close: true,
      fadeOut: false
    };
    sharedService.emitChange(event);
  }

  public static genInfoMessage(sharedService: SharedService, infoMessage: string): void {
    const event = {
      messageId: sharedService.generateId(),
      type: 'Message',
      level: globals.errorLevel.info,
      text: `<strong>Info:</strong> ${infoMessage}!`,
      color: 'info',
      close: true,
      fadeOut: false
    };
    sharedService.emitChange(event);
  }

  public static getFormData(tranId: number, formType: string, formData: any) {
    return {
      form_type: formType,
      dtype: 128,
      tran_id: tranId,
      icn_id: formData['folder_id'],
      form_data: formData,
    };
  }
}
