<app-box [type]="'primary'" [close]="true">
  Search for organization(s) by either ID or by a case-insensitive wild card starting with the name input.
  E.g., Searching for "pol" will find POLOS.
  <ul>
    <li>If organization ID is entered, the search will be only by ID.</li>
    <li>Otherwise, the search will be a name wild card search.</li>
  </ul>
</app-box>

<mat-card>
  <mat-card-title>Find Organizations</mat-card-title>
  <mat-card-content>
    <form (submit)="findOrganizations()" [formGroup]="form">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input formControlName="name" matInput placeholder="Organization name" autofocus>
            <mat-error *ngIf="name.hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field class="md-icon-left w-100">
            <input appNumberOnly formControlName="organization_id" matInput placeholder="Organization ID">
            <mat-error *ngIf="organization_id.hasError('required')">
              Organization ID is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <button class="btn btn-lg btn-primary" type="submit">Find Organizations</button>
      <button (click)="reset()" class="ms-2 btn btn-lg btn-light" type="button">Reset</button>
    </form>
  </mat-card-content>
</mat-card>
