import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Help} from '../../../models/help';
import {HomeComponent} from '../home.component';
import {Tran} from '../../../models/tran';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
})
export class SiteLayoutComponent extends HomeComponent implements OnInit, OnDestroy {
  public pageTitle = 'Site Map';
  public accountOverview: any;
  public activeTransactionsCount: number;
  public tran: Tran;
  public isDefaultLoanApp: boolean;
  public isCurrentTran: boolean;
  public hasMCRService: boolean;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.tran = this.tranService.getCurrentTranSync();
    this.hasMCRService = this.userService.getUserSync().hasMCRService();
    this.isCurrentTran = !!this.tran;
    this.isDefaultLoanApp = this.tran ? !!this.tran.getDefaultLoanApp() : false;
    this.reloadHome()
      .then(() => {
        setTimeout(() => {
          this.sharedService.emitChange({
            type: 'ChangePage',
            title: this.pageTitle,
            help: Help.helpKeys.homeMap
          });
          this.reloaded = true;
        }, 0, this);
      });
  }
}
