import {Component, Injector, Input, OnInit} from '@angular/core';
import {Liability} from '../../../../models/liability';
import {LiabilityComponent} from '../liability/liability.component';

@Component({
  selector: 'app-view-segment-liability',
  templateUrl: './view-segment-liability.component.html',

})
export class ViewSegmentLiabilityComponent extends LiabilityComponent implements OnInit {
  @Input() item: Liability;

  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getREOAsset(): string {
    if (!this.item.reo_asset_id) {
      return null;
    }
    const found = this.reo_assets.find((elt) => {
      return elt.key === this.item.reo_asset_id;
    });
    return found ? found.value : null;
  }
}
