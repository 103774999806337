import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Tran} from '../../../models/tran';
import {Trace} from '../../../models/trace';
import {Workflow} from '../../../models/workflow';
import {CompBaseComponent} from '../../comp-base.component';
import {globals} from '../../../shared/globals/globals';
import {Help} from '../../../models/help';

@Component({
  selector: 'app-view-tasks-tabs',
  templateUrl: './view-tasks-tabs.component.html',
})
export class ViewTasksTabsComponent extends CompBaseComponent implements OnInit, OnDestroy {
  public static currentSection: string;
  public pageTitle = 'Tasks';
  public currentSection: string;
  public tran: Tran;
  public workflow: Workflow;
  public currentTrace: Trace;
  public currentState: string;
  public traces: Array<Trace>;
  public allTraces: Array<Trace>;
  public section = globals.section;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!ViewTasksTabsComponent.currentSection) {
      ViewTasksTabsComponent.currentSection = globals.section.currentTask;
    }
    this.reload(false);
    setTimeout(() => {
      this.sharedService.emitChange({
        type: 'ChangePage',
        title: this.pageTitle,
        help: Help.helpKeys.task
      });
    }, 0);
    this.reloadOnSync();
  }

  onEvent(event: any): void {
    if (event.type === 'Reload') {
      this.reload(false);
      return;
    }

    super.onEvent(event);
  }

  reload(force: boolean): void {
    this.reloaded = false;
    this.tranService.getCurrentTran(force)
      .then((tran: Tran) => {
        this.tran = tran;
        this.workflow = tran.workflow;
        this.currentTrace = new Trace(this.workflow.currentTrace);
        this.currentState = this.currentTrace.state;
        this.traces = this.workflow.Traces;
        this.allTraces = this.workflow.allTraces;
        setTimeout(() => {
          this.reloaded = true;
          this.setSection(null, ViewTasksTabsComponent.currentSection);
        }, 0);
      });
  }

  setSection(event, section): void {
    this.currentSection = section;
    ViewTasksTabsComponent.currentSection = section;
  }
}
