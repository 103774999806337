import {Component, Injector, OnInit} from '@angular/core';
import {SecEmploymentComponent} from '../sec-employment/sec-employment.component';

@Component({
  selector: 'app-edit-segment-sec-employment',
  templateUrl: '../sec-employment/edit-segment-sec-employment.component.html',
})
export class EditSegmentSecEmploymentComponent extends SecEmploymentComponent implements OnInit {
  public op = 'Edit';

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createFormControls(this.item);
    this.createForm();
  }
}
