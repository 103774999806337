import {ModelBase} from './model-base';

export class LoInformation extends ModelBase {
  lo_name: string;
  lo_orig_date: Date;
  lo_received_date: Date;
  lo_phone_number: string;
  lo_company_name: Date;
  lo_company_address: string;
  lo_company_address2: string;
  lo_company_city: string;
  lo_company_state: string;
  lo_company_zip: string;
  lo_company_zip_four: string;
  lo_nmls: string;
  lo_state_license: string;
  lo_company_nmls: string;
  lo_company_state_license_number: string;
  lo_email: string;

  constructor(object: any) {
    super();
    for (const prop of Object.keys(object)) {
      if (prop === 'lo_orig_date' || prop === 'lo_received_date') {
        this[prop] = this.getDateProp(prop, object);
      } else if (prop === 'lo_phone_number') {
        this[prop] = this.getPhoneProp(prop, object);
      } else {
        this[prop] = object[prop];
      }
    }
  }

  getCompanyFullAddress(): string {
    return super.getFullAddress(this.lo_company_address, this.lo_company_address2, this.lo_company_city, this.lo_company_state, this.lo_company_zip, this.lo_company_zip_four);
  }

  toServerPayload(): any {
    return {
      lo_name: this.lo_name,
      lo_orig_date: this.toServerDate(this.lo_orig_date),
      lo_received_date: this.toServerDate(this.lo_received_date),
      lo_phone_number: this.toServerPhone(this.lo_phone_number),
      lo_company_name: this.lo_company_name,
      lo_company_address: this.lo_company_address,
      lo_company_address2: this.lo_company_address2,
      lo_company_city: this.lo_company_city,
      lo_company_state: this.lo_company_state,
      lo_company_zip: this.lo_company_zip,
      lo_company_zip_four: this.lo_company_zip_four,
      lo_nmls: this.lo_nmls,
      lo_state_license: this.lo_state_license,
      lo_company_nmls: this.lo_company_nmls,
      lo_company_state_license_number: this.lo_company_state_license_number,
      lo_email: this.lo_email,
    };
  }
}
