<div >
  <mat-spinner [diameter]="68" [hidden]="!generating" class="ms-5 mb-3"></mat-spinner>
</div>

<app-box *ngIf="reloaded&&!isDefaultLoanApp" [type]="'warning'" class="mt-2">
  You cannot generate the URLA document because there is
  <span class="fw-bold">no default loan application</span>. You can create a loan application and flag it as default from
  <strong>Loan Applications</strong>.
</app-box>

<div *ngIf="reloaded&&isDefaultLoanApp" [hidden]="generating" class="mb-1">
  <form *ngIf="true" [formGroup]="form" class="mt-2">
    <div class="row mt-3">
      <div class="col">
        <button (click)="genMISMO($event)" class="btn btn-lg btn-primary">Generate</button>
        <button (click)="cancelConfigDoc()" class="ms-2 btn btn-lg btn-secondary">Cancel</button>
      </div>
    </div>
  </form>
</div>
