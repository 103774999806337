<mat-card class="mb-lg-0">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canUpdateLoanRecord" [header]="'Loan Record'" [help]="false" [op]="'edit'"></app-segment-header>
  </mat-card-title>

  <mat-card-content>
    <div *ngIf="tran">
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">ID</div>
        <div class="col-lg-8">{{tran.id}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Name</div>
        <div class="col-lg-8">{{tran.name}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Organization</div>
        <div class="col-lg-8">{{tran.organization_name}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Description</div>
        <div class="col-lg-8">{{tran.description}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Owner</div>
        <div class="col-lg-8">{{tran.owner_full_name}}</div>
      </div>
      <div class="row pt-1 pb-1 border-bottom hover-gray">
        <div class="col-lg-4 fw-bold">Loan status</div>
        <div class="col-lg-8">{{tran.getStatusString()}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
