import {Component, Injector, OnInit} from '@angular/core';
import {CompEventComponent} from '../comp-event/comp-event.component';

@Component({
  selector: 'app-view-comp-event',
  templateUrl: './view-comp-event.component.html',
})
export class ViewCompEventComponent extends CompEventComponent implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
    this.mode = 'View';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // Comment: in compliance there are editable comp events and for this reason
  // for change mode event (from edit to view and conversely) do not percolate the event
  // any other event we pass to default handling by the base class
  onEvent(event: any): void {
    if (event.type === 'ChangeMode') {
      this.mode = event['mode'];
      return;
    }
    super.onEvent(event);
  }

}
