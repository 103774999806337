import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppValidators} from '../../../../shared/validators/app-validators.validator';
import {Race} from '../../../../models/race';
import {CompBaseComponent} from '../../../comp-base.component';
import {FormUtil} from '../../../form-util';

@Component({
  selector: 'app-race',
  template: '',
})
export class RaceComponent extends CompBaseComponent implements OnInit {
  // items contains the selected races
  @Input() items: Array<Race>;
  @Input() item: Race;
  public race_codes = Race.race_codes;

  // form fields
  public form: FormGroup;
  public race_ex: FormControl;
  public indian_description: FormControl;
  public asian_description: FormControl;
  public islander_description: FormControl;
  public hawaii: FormControl;
  public guam: FormControl;
  public samoa: FormControl;
  public china: FormControl;
  public india: FormControl;
  public philippines: FormControl;
  public japan: FormControl;
  public korea: FormControl;
  public vietnam: FormControl;
  public other_islander: FormControl;
  public other_asian: FormControl;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.segment = 'race_informations';
    this.section = this.applicant;
  }

  getRemainingRaceCodes(): Array<any> {
    const allCodes = this.race_codes.map((elt) => elt.key);
    const usedCodes = this.items.map((elt) => elt.race_ex);
    // compute diff between allCodes and usedCodes
    const remainingCodes = allCodes.filter(code => usedCodes.indexOf(code) < 0);
    // filter out race codes that are in the remainingCodes
    return this.race_codes.filter((elt) => remainingCodes.indexOf(elt.key) >= 0);
  }

  createFormControls(item: Race): void {
    this.race_ex = new FormControl(item.race_ex, AppValidators.present);
    this.indian_description = new FormControl(item.indian_description);
    this.asian_description = new FormControl(item.asian_description);
    this.islander_description = new FormControl(item.islander_description);
    this.guam = new FormControl(item.guam);
    this.hawaii = new FormControl(item.hawaii);
    this.samoa = new FormControl(item.samoa);
    this.india = new FormControl(item.india);
    this.china = new FormControl(item.china);
    this.philippines = new FormControl(item.philippines);
    this.japan = new FormControl(item.japan);
    this.korea = new FormControl(item.korea);
    this.vietnam = new FormControl(item.vietnam);
    this.other_asian = new FormControl(item.other_asian);
    this.other_islander = new FormControl(item.other_islander);
  }

  createForm(): void {
    this.form = new FormGroup({
      race_ex: this.race_ex,
      indian_description: this.indian_description,
      asian_description: this.asian_description,
      islander_description: this.islander_description,
      hawaii: this.hawaii,
      guam: this.guam,
      samoa: this.samoa,
      china: this.china,
      india: this.india,
      japan: this.japan,
      korea: this.korea,
      philippines: this.philippines,
      vietnam: this.vietnam,
      other_islander: this.other_islander,
      other_asian: this.other_asian,
    });
  }

  delete(): void {
    const payload = {segment: this.segment, section: this.section};
    payload[this.segment] = {index: this.item.index};

    this.loanAppService.deleteItem(this.doc.id, payload)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
      });
  }

  add(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    this.set_others();
    if (!this.form.valid) {
      return;
    }

    const updated = new Race(this.form.value);
    updated.index = -1;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.addLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  update(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
    this.set_others();
    if (!this.form.valid) {
      return;
    }

    const updated = new Race(this.form.value);
    updated.index = this.item.index;
    const data = {segment: this.segment, section: this.section};
    data[this.segment] = updated.toServerPayload();

    this.loanAppService.updateLoanAppItem(this.doc.id, data)
      .then(() => {
        this.eventEmitted.emit({type: 'Reload'});
        return;
      })
      .catch((errorData) => {
        FormUtil.genErrorMessage(this.sharedService, 'Failed to update loan application', errorData);
      });
  }

  set_others(): void {
    this.other_asian.setErrors(null);
    if (this.other_asian.value == true && !this.asian_description.value) {
      this.asian_description.setErrors({'required': true});
      return;
    }
    if (this.other_asian.value != true) {
      this.asian_description.setValue(null);
    }
    this.other_islander.setErrors(null);
    if (this.other_islander.value == true && !this.islander_description.value) {
      this.islander_description.setErrors({'required': true});
      return;
    }
    if (this.other_islander.value != true) {
      this.islander_description.setValue(null);
    }
  }
}
