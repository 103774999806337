import {Component, HostBinding, Injector, OnInit} from '@angular/core';
import {IMenuItem, IMenuItemSub} from './menu-item';
import {PermissionService} from '../../services/permission.service';
import {CompBaseComponent} from '../../content/comp-base.component';
import {User} from '../../models/user';
import {Router} from '@angular/router';
import {DeviceService} from '../../services/device.service';
import {MenuService} from '../../services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [PermissionService],
})
export class MenuComponent extends CompBaseComponent implements OnInit {
  @HostBinding('class.app-menu') appMenuClass = true;
  menuItems: IMenuItem[];
  user: User;
  lastItem: any;
  menuImageSrc: string;
  menuImageName: string;
  menuService: MenuService;

  constructor(public injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.menuService = this.injector.get(MenuService);
    this.reload();
    this.reloadOnSync();
  }

  reload(force?: boolean): void {
    this.userService.getUserFromSession()
      .then((user) => {
        this.user = this.userService.getUserSync();
      })
      .then(() => {
        return this.tranService.getCurrentTran();
      })
      .then((tran) => {
        if (tran && tran.owner_professional_profile && tran.owner_professional_profile.image_url && tran.owner_professional_profile.image_url.length) {
          this.menuImageSrc = tran.owner_professional_profile.image_url;
          this.menuImageName = tran.owner_professional_profile.full_name;
        } else {
          this.menuImageSrc = this.user.getThumbnailPath();
          this.menuImageName = this.user.full_name;
        }
        this.menuItems = this.menuService.getMenuItems().filter((elt) => {
          if (elt.permission) {
            return this.permissionService.execCan(elt.permission);
          }
          return true;
        });
        this.menuItems.forEach((item) => {
          item.disabled = item.permission && !this.permissionService.execCan(item.permission);
          if (!item.sub || item.disabled) {
            return;
          }
          item.sub.forEach((subItem) => {
            if (!subItem.permission) {
              return;
            }
            subItem.hidden = !this.permissionService.execCan(subItem.permission);
          });
        });
        // Need to set the initial values of lastItem when the menu first is reloaded for the first time.
        // Thereafter lastItem and its state are set by the user clicks.
        const url = this.router.url;
        let found = false;
        this.menuItems.forEach((mi) => {
          mi.sub.forEach((sub: IMenuItemSub) => {
            if (sub.routing == url) {
              this.lastItem = mi;
              found = true;
            }
            if (found === true) return;
          })
          if (found === true) return;
        })
        if (this.lastItem) {
          this.lastItem.active = true;
        }
        this.reloaded = true;
      });
  }

  getMenuLiClasses(item: any) {
    return {
      'active': (item === this.lastItem) && item.active,
      'has-sub': item.sub,
      'menu-item-group': item.groupTitle,
      'disabled': item.disabled
    };
  }

  getSubMenuLiClasses(item: any, isActive: any) {
    return {
      'has-sub': item.sub,
      'active': isActive,
      'menu-item-group': item.groupTitle,
      'disabled': item.disabled
    };
  }

  getStyles(item: any) {
    return {
      'background': item.bg,
      'color': item.color
    };
  }

  groupMenuClicked(event: Event, item: any) {
    item.active = !(this.lastItem === item && item.active);
    this.lastItem = item;
  }

  navigateTo($event, path: string): void {
    $event.preventDefault();
    if (DeviceService.isMobile) {
      this.sharedService.emitChange({type: 'ToggleVerticalNavBar'});
    }
  }

  doNothing(event: Event) {
    event.preventDefault();
  }
}
