import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'booleanToString'
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof (value) === 'undefined' || value === null) {
      return '';
    }
    return value === true ? 'Yes' : 'No';
  }
}
