import {Component, Injector, OnInit} from '@angular/core';
import {FormTop} from '../../../../models/form-top';
import {CompBaseComponent} from '../../../comp-base.component';

@Component({
  selector: 'app-view-form-top',
  templateUrl: './view-form-top.component.html',
})
export class ViewFormTopComponent extends CompBaseComponent implements OnInit {
  public item: FormTop;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reload();
  }

  reload(force?: boolean): void {
    this.reloaded = false;
    this.item = new FormTop(this.doc.recursive_attributes['form_top']);
    this.mode = 'View';
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }
}
