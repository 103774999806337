<div *ngIf="reloaded">
  <mat-card-title>
    <app-segment-header (eventEmitted)="onEvent($event)" [enable]="canCreateCustomer" [header]="'Customers'" [help]="false" [op]="'add'"></app-segment-header>
  </mat-card-title>

  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col">
      </div>
    </div>
  </form>

  <app-add-customer (eventEmitted)="onEvent($event)" *ngIf="mode==='Add'"></app-add-customer>

  <div *ngFor="let item of customers">
    <app-view-customer (eventEmitted)="onEvent($event)" [item]="item"></app-view-customer>
  </div>

  <app-box *ngIf="!isCustomers" [close]="false" [type]="'warning'" class="pt-2">
    <strong>No customers!</strong>
  </app-box>
</div>
