<mat-card (click)="toggleHelp()" class="mt-1 mb-2">
  <mat-card-title>Help on Invite Users
  </mat-card-title>

  <mat-card-content>
    <p>
      This page shows you
      <span class="fw-bold">two ways</span> to invite a new user to join PrudentCO
      and receive an email confirmation when the user actually creates the account.
      Please note that any user can create an account directly through the website.
      The downside of this approach is that the loan officer is not notified when the user creates the account.
    </p>
  </mat-card-content>
</mat-card>
